import { ENVIRONMENT } from 'common/helpers/check_environment'

export interface PendoUser {
  id: string
  email: string
  role: string
  type: 'CONTRACTOR' | 'VENDOR'
  account: {
    id: string
    name: string
    type?: string
  }
}

// Ignore Pendo initialization when is development
// The event tracking is raising errors while e2e is executing
let pendoInitialized = ENVIRONMENT === 'development'

export const initializePendo = (user: PendoUser) => {
  if (!pendoInitialized) {
    pendoInitialized = true
    // Set initialized to true before initializing pendo, if there is an error we prefer to only error once
    window?.pendo.initialize({
      visitor: {
        id: user.id, // Required if user is logged in
        email: user.email, // Recommended if using Pendo Feedback, or NPS Email
        // full_name:    // Recommended if using Pendo Feedback
        role: user.role, // Optional
        // You can add any additional visitor level key-values here,
        // as long as it's not one of the above reserved names.
        type: user.type,
      },
      account: {
        id: user.account.id, // Required if using Pendo Feedback
        name: user.account.name, // Optional
        // is_paying:    // Recommended if using Pendo Feedback
        // monthly_value:// Recommended if using Pendo Feedback
        // planLevel:    // Optional
        // planPrice:    // Optional
        // creationDate: // Optional
        company_type: user.account?.type,

        // You can add any additional account level key-values here,
        // as long as it's not one of the above reserved names.
      },
    })
  }
}
