import React from 'react'

import { connectInfiniteHits } from 'react-instantsearch-dom'

import _, { uniqBy } from 'lodash'

import { ProjectOutlined, ShopOutlined } from '@ant-design/icons'
import { Space, Typography } from 'antd'

import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'

import { ResponsiveTable } from 'common/components/ResponsiveTable'
import { CellDeliveryInfo, CellOrderNumber, CellOrderState } from 'common/components/table/cells'
import { useMaybeOpenIntoNewTab } from 'common/hooks/use-maybe-open-into-new-tab'
import { usePushToOrder } from 'common/hooks/use-push-to-order'
import { useRefreshInfiniteList } from 'common/hooks/use-refresh-infinite-list'
import { OrderHit } from 'common/server/orders'
import { OrderStates } from 'common/server/server_types'

import { allRefinements } from 'contractor/components/AlgoliaTagsFilter'
import { DeliveryDetail } from 'contractor/components/DeliveryDetail'
import { OrderQuickCommentModal } from 'contractor/components/OrderQuickCommentModal/order_quick_comment_modal'
import { useStores } from 'contractor/hooks/use-stores'
import Columns from 'contractor/pages/Orders/table_columns'
import { request_update } from 'contractor/server/orders'

import { useOrders } from './context'
import { StateResults } from './state_results'

interface OrderTableProps {
  hasMore: boolean
  refineNext: () => void
  hits: OrderHit[]
  refreshHits: boolean
}

const VendorName = ({ orderHit }: { orderHit: OrderHit }) => {
  if (orderHit?.is_draft) {
    return (
      <>
        {orderHit.draft_vendor_names?.map((vendor_name) => (
          <Space key={vendor_name}>
            <ShopOutlined />
            <Typography.Text>{vendor_name}</Typography.Text>
          </Space>
        ))}
      </>
    )
  }

  return (
    <Space>
      <ShopOutlined />
      <Typography.Text>{orderHit.vendor?.name || orderHit.company_vendor?.vendor_name}</Typography.Text>
    </Space>
  )
}

const OrderTable = observer<OrderTableProps>(({ hits, hasMore, refineNext }) => {
  const { orderStore, userStore, deliveryStore, notificationStore, companySettingStore } = useStores()
  const { getUrl } = usePushToOrder()
  const { maybeSetTableColumns } = useOrders()
  const { maybeOpenIntoNewTab } = useMaybeOpenIntoNewTab()

  React.useEffect(() => {
    orderStore.setHits(hits)
  }, [orderStore.searchState, hits])

  const goToOrder = ({ row }) => {
    const url = getUrl({
      orderId: row.id,
      orderPackageId: row.order_package_id,
      state: row.state,
      subState: row.sub_state,
      privateFormUrl: companySettingStore?.otherSettings?.public_order_form_url_extension,
    })
    maybeOpenIntoNewTab(url)
  }

  const handleSelectDelivery = (id = '') => {
    if (!userStore.canManageOrders) {
      return
    }
    deliveryStore.selectDelivery(id)
  }

  const data = () => {
    const isSorted = orderStore.isSorted

    let data = null

    data = uniqBy([...orderStore.ordersLastUpdated, ...orderStore.hits], 'id')

    if (isSorted) data = orderStore.hits

    if (!orderStore.selectedRibbonFilter || orderStore.selectedRibbonFilter === 'All') {
      data = data.filter((item) => allRefinements.includes(item.state) || allRefinements.includes(item.sub_state))
    }

    return data
  }

  return (
    <ResponsiveTable
      data={toJS(data())}
      columns={Columns()}
      tableName="Orders"
      hasMore={hasMore}
      loadMore={refineNext}
      groupByPath="original.order_package_id"
      onClickRow={goToOrder}
      searchClient={orderStore.searchClient}
      indexName={orderStore.searchKey.index_name}
      replicas={orderStore.replicas}
      setTableColumns={maybeSetTableColumns}
      renderMobileCard={(row: OrderHit) => {
        const notificationCount = notificationStore.notificationCountByObject(row.id)

        return (
          <Space style={{ width: '100%' }} direction="vertical">
            <CellOrderNumber
              orderName={row?.name}
              orderNumber={row?.order_number}
              orderId={row?.id}
              orderPackageId={row?.order_package_id}
              state={row?.state}
              subState={row?.sub_state}
            />
            <CellOrderState order={row} notificationsCount={notificationCount} />
            <Space>
              <ProjectOutlined />
              <Typography.Text>{row?.project_name}</Typography.Text>
            </Space>
            <VendorName orderHit={row} />
            <CellDeliveryInfo
              deliveries={row.deliveries}
              onSelectDelivery={!userStore.isMaterialRequester ? handleSelectDelivery : undefined}
            />
          </Space>
        )
      }}
    />
  )
})

const CustomHits = connectInfiniteHits(OrderTable)

const OrderTableWrapper = observer(() => {
  const { deliveryStore, orderStore } = useStores()
  useRefreshInfiniteList({
    forceRefreshHits: () => orderStore.forceRefreshHits(false),
    refreshHits: orderStore.refreshHits,
    attempts: 5,
  })

  const { deliveryDetailRef } = useOrders()

  const order = _.find(orderStore.orders, { id: orderStore.selectedOrderAction })

  const orderState = _.get(order, 'state') as OrderStates
  const vendorName = _.get(order, 'company_vendor.vendor_name')

  return (
    <>
      <StateResults>
        <CustomHits />
      </StateResults>

      <DeliveryDetail
        ref={deliveryDetailRef}
        onClose={() => {
          deliveryDetailRef.current?.close()
          deliveryStore.selectDelivery(null)
        }}
        orderDelivery={deliveryStore.selectedDelivery?.delivery}
        onUpdate={async (delivery) => {
          await deliveryStore.updateDelivery(delivery)
          deliveryDetailRef.current?.close()
          deliveryStore.selectDelivery(null)
        }}
        project_ids={[deliveryStore.selectedDelivery?.order.project_id]}
        orderInfo={deliveryStore.selectedDelivery?.order}
      />

      <OrderQuickCommentModal
        visible={orderStore.selectedOrderAction !== null}
        onCancel={() => {
          orderStore.selectedOrderAction = null
        }}
        orderId={order?.id}
        orderState={orderState}
        vendorName={vendorName}
        onSubmit={async (params) => {
          await request_update(params)
        }}
        hasDefaultMessage
      />
    </>
  )
})

export default OrderTableWrapper
