import React, { useRef } from 'react'

import { Button } from 'antd'

import { observer } from 'mobx-react-lite'

import { DrawerRef } from 'common/components/Drawer'
import { OrderDelivery } from 'common/server/deliveries'
import { OrderStates } from 'common/server/server_types'

import { DeliveryDetail } from 'contractor/components/DeliveryDetail'
import { useStores } from 'contractor/hooks/use-stores'

type DeliveryDetailsProps = {
  disabled?: boolean
  delivery: OrderDelivery
  projectIds: string[]
}

export const DeliveryDetails = observer<DeliveryDetailsProps>(({ delivery, disabled, projectIds }) => {
  const { orderStore, deliveryStore, userStore } = useStores()

  const deliveryDetailRef = useRef<DrawerRef>()

  const order = orderStore.selectedOrder

  const handleConfirm = (updatedDelivery) => {
    const deliveryIndex = orderStore.selectedOrder?.deliveries?.findIndex(
      (delivery) => delivery.id === updatedDelivery?.id,
    )
    orderStore.updateSelectedOrder(`deliveries[${deliveryIndex}]`, { ...updatedDelivery, updated: true })
    orderStore.setOrderDirty()
    deliveryDetailRef.current?.close()
  }

  const handleUpdate = async (delivery) => {
    await deliveryStore.updateDelivery(delivery)
    await orderStore.selectOrder(order?.id)
    deliveryDetailRef.current?.close()
  }

  const getActionsProps = () => {
    if (userStore.cannotSendAndUpdateOrders) {
      return { onUpdate: handleUpdate }
    }

    const isOrder = [
      OrderStates.ORDERED,
      OrderStates.PARTIALLY_SHIPPED,
      OrderStates.SHIPPED,
      OrderStates.DELIVERED,
    ].includes(order?.state)
    if (!userStore.canSendAndUpdateOrders && isOrder) {
      return { onUpdate: handleUpdate }
    }

    const isRFQ = order?.state === OrderStates.QUOTED
    if (!userStore.canSendAndUpdateRfqs && isRFQ) {
      return { onUpdate: handleUpdate }
    }

    return { onConfirm: handleConfirm }
  }

  return (
    <>
      <Button
        block
        data-cy="show_delivery_drawer"
        onClick={(e) => {
          e.stopPropagation()
          deliveryDetailRef.current?.show()
        }}
        style={{ width: '100%' }}
        disabled={disabled}
      >
        {delivery.is_pick_up ? 'Pick Up Details' : 'Delivery Details'}
      </Button>

      <DeliveryDetail
        ref={deliveryDetailRef}
        onClose={() => deliveryDetailRef.current?.close()}
        orderDelivery={delivery}
        {...getActionsProps()}
        project_ids={projectIds}
        orderInfo={{
          ...order,
          vendor_name: order?.company_vendor?.vendor?.name || order?.company_vendor?.vendor_name,
        }}
      />
    </>
  )
})
