import React, { useEffect, useState } from 'react'

import { Empty } from 'antd'

import { Box } from 'common/components/boxes'

import { useStores } from 'contractor/hooks/use-stores'
import { useCommitment } from 'contractor/pages/@v2/Commitments/Components/CommitmentContext/context'
import { IndexInvoiceRecord } from 'contractor/server/invoices/invoice'

import { Table } from './table'

export const CommitmentsInvoices = () => {
  const { commitmentStore } = useStores()
  const { commitment } = useCommitment()
  const [dataSource, setDataSource] = useState<IndexInvoiceRecord[]>([])

  useEffect(() => {
    if (commitment?.id && commitment?.invoice_count > 0) {
      commitmentStore.loadCommitmentInvoices().then((invoices) => {
        setDataSource(invoices)
      })
    }
  }, [commitment?.id, commitment?.invoice_count])

  if (commitment?.invoice_count == 0) {
    return (
      <Box p="16">
        <Empty />
      </Box>
    )
  }

  return <Table dataSource={dataSource} />
}
