import React from 'react'

const PATHS = ['cost_code', 'cost_code_phase_id'] as const
type Path = typeof PATHS[number]

// Using this alias to avoid write this large name in the hook
export type UsePopulateSameCostCodePath = Path

type BaseDataSourceItem<DataSourceItem> = DataSourceItem & { default_cost_code_to_be_applied?: boolean }

interface Props<DataSourceItem> {
  datasource?: Array<BaseDataSourceItem<DataSourceItem>>
  itemIdentifierKey?: string
}

interface HandleChangeArgs<DataSourceItem, Value = unknown> {
  triggerItem: boolean
  value: Value
  path: Path
  item: BaseDataSourceItem<DataSourceItem>
}

interface HandleChangeCheckboxArgs<DataSourceItem> {
  triggerIndex: number
  path: Path
  value: unknown
  datasource?: Array<BaseDataSourceItem<DataSourceItem>>
}

interface UsePopulateSameCostCode<DataSourceItem> {
  handleChange: <Value = unknown>(args: HandleChangeArgs<DataSourceItem, Value>) => BaseDataSourceItem<DataSourceItem>
  handleBulkChange: (args: HandleChangeCheckboxArgs<DataSourceItem>) => Array<BaseDataSourceItem<DataSourceItem>>
  addToAppliedByAll: (path: Path, key: string) => void
  removedByAppliedToAll: (path: Path, key: string) => void
  hasItemBeenChangedByAppliedToAll: (path: Path, key: string) => boolean
  defaultValues: { [key in Path]?: unknown }
}

export function usePopulateSameCostCode<DataSourceItem>(
  props: Props<DataSourceItem>,
): UsePopulateSameCostCode<DataSourceItem> {
  const { datasource: dataSourceProp = [], itemIdentifierKey } = props

  const [changedByAppliedToAll, setChangedByAppliedToAll] = React.useState<{ [key in Path]?: Array<string> }>({})
  const [defaultValues, setDefaultValues] = React.useState<{ [key in Path]?: unknown }>({})

  // Internal hook's logic and helpers
  function alreadyHasValue(path: Path, item: DataSourceItem): boolean {
    switch (path) {
      case 'cost_code':
        return !!(item['cost_code'] || item['cost_code_id']?.length)
      case 'cost_code_phase_id':
        return !!item['cost_code_phase_id']
      default:
        return !!item[path]
    }
  }

  function getPropertyByString(obj: unknown, key: string): string {
    return key.split('.').reduce((acc, prop) => {
      return acc ? acc[prop] : undefined
    }, obj) as string
  }

  // Exposed functions
  function addToAppliedByAll(path: Path, key: string) {
    if (changedByAppliedToAll[path]?.includes(key)) {
      return
    }

    setChangedByAppliedToAll((prev) => ({
      ...prev,
      [path]: [...(prev[path] || []), key],
    }))
  }

  function removedByAppliedToAll(path: Path, key: string) {
    if (!changedByAppliedToAll[path]?.includes(key)) {
      return
    }

    setChangedByAppliedToAll((prev) => ({
      ...prev,
      [path]: prev[path]?.filter((item) => item !== key),
    }))
  }

  function hasItemBeenChangedByAppliedToAll(path: Path, key: string): boolean {
    return changedByAppliedToAll[path]?.includes(key) || false
  }

  function handleChange<Value = unknown>(
    args: HandleChangeArgs<DataSourceItem, Value>,
  ): BaseDataSourceItem<DataSourceItem> {
    const { triggerItem, value, path, item } = args

    const key = getPropertyByString(item, itemIdentifierKey)
    const itemChangedByAppliedToAll = hasItemBeenChangedByAppliedToAll(path, key)
    const itemAlreadyHasValue = alreadyHasValue(path, item)

    setDefaultValues((prev) => ({ ...prev, [path]: value }))

    item.default_cost_code_to_be_applied = triggerItem

    if (triggerItem && itemChangedByAppliedToAll) {
      removedByAppliedToAll(path, key)
      item[path] = value

      return item
    }

    if ((!triggerItem && !itemAlreadyHasValue) || itemChangedByAppliedToAll) {
      item[path] = value

      if (!itemChangedByAppliedToAll) {
        addToAppliedByAll(path, key)
      }
    }

    return item
  }

  function handleBulkChange(args: HandleChangeCheckboxArgs<DataSourceItem>): Array<BaseDataSourceItem<DataSourceItem>> {
    const { triggerIndex, path, value, datasource: datasourceArgs } = args

    const dataSource = datasourceArgs || dataSourceProp

    return dataSource.map((item, index) => {
      return handleChange({
        triggerItem: triggerIndex === index,
        value,
        path,
        item,
      })
    })
  }

  return {
    handleChange,
    handleBulkChange,
    defaultValues,
    addToAppliedByAll,
    removedByAppliedToAll,
    hasItemBeenChangedByAppliedToAll,
  }
}
