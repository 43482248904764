import React from 'react'

import LockOutlined from '@ant-design/icons/LockOutlined'
import { Tooltip } from 'antd'

import { formatEnumValue } from 'common/helpers/formatters'

import { useStores } from 'contractor/hooks/use-stores'

export const LockInvoice = () => {
  const { userStore } = useStores()
  const currentUser = userStore?.currentUser

  const title = `Your current role as a ${formatEnumValue(
    currentUser?.role,
  )}, does not grant you permissions to edit posted invoices. `

  return (
    <>
      <Tooltip placement={'bottom'} title={title}>
        <LockOutlined data-cy="lock-invoice-icon" />
      </Tooltip>
    </>
  )
}
