import React, { useState } from 'react'

import { EllipsisOutlined } from '@ant-design/icons'
import { Button, Dropdown, Popconfirm } from 'antd'

type ActionsColumnProps = {
  onEdit?: () => void
  onApprove?: () => void
  onRemove: () => void
  onToggleReplace?: () => void
  onTogglePriceHistory?: () => void
  disabled?: boolean
  canEditMaterialDatabase?: boolean
  canViewHistoricalPricing?: boolean
  showDelete?: boolean
  showApprove?: boolean
  isReplacingMaterial?: boolean
}

export const ActionsColumn = ({
  onEdit,
  onApprove,
  onRemove,
  onToggleReplace,
  onTogglePriceHistory,
  disabled,
  canEditMaterialDatabase,
  canViewHistoricalPricing,
  showDelete,
  showApprove,
  isReplacingMaterial,
}: ActionsColumnProps) => {
  const [open, setOpen] = useState(false)

  const handleRemove = () => {
    onRemove()
    setOpen(false)
  }

  const handleEdit = () => {
    onEdit()
    setOpen(false)
  }

  const handleToggleReplace = () => {
    onToggleReplace()
    setOpen(false)
  }

  const handleApprove = () => {
    onApprove()
    setOpen(false)
  }

  const handleTogglePriceHistory = () => {
    onTogglePriceHistory()
    setOpen(false)
  }

  return (
    <Dropdown
      menu={{
        items: [
          ...(canEditMaterialDatabase ? [{ key: 'edit', label: 'Edit', onClick: handleEdit, disabled }] : []),
          ...(canViewHistoricalPricing
            ? [{ key: 'price_history', label: 'Price history', onClick: handleTogglePriceHistory, disabled }]
            : []),
          {
            key: 'replace',
            label: isReplacingMaterial ? 'Original' : 'Replace',
            onClick: handleToggleReplace,
            disabled,
          },
          ...(showApprove && canEditMaterialDatabase
            ? [{ key: 'approve', label: 'Approve', onClick: handleApprove, disabled }]
            : []),

          ...(showDelete
            ? [
                {
                  key: 'delete',
                  label: (
                    <Popconfirm
                      onConfirm={handleRemove}
                      onCancel={() => setOpen(false)}
                      title="Are you sure to delete this item?"
                      okText="Yes"
                      cancelText="No"
                      placement="right"
                      disabled={disabled}
                      overlayStyle={{ marginLeft: 27 }}
                    >
                      <span>Delete</span>
                    </Popconfirm>
                  ),
                  danger: true,
                  disabled,
                },
              ]
            : []),
        ],
      }}
      trigger={['click']}
      onOpenChange={setOpen}
      open={open}
      destroyPopupOnHide
    >
      <Button icon={<EllipsisOutlined />} size="small" />
    </Dropdown>
  )
}
