import { useHistory } from 'react-router-dom'

type PushParams = {
  commitmentId?: string
  isNewTab?: boolean
  basePath?: string
}

export const usePushToCommitment = () => {
  const history = useHistory()

  const getUrl = ({ commitmentId, basePath = '' }: Omit<PushParams, 'isNewTab'>) => {
    return `${basePath}/commitment/${commitmentId}`
  }

  const getCommitmentUrl = (commitmentId: string) => {
    return `/commitment/${commitmentId}`
  }

  const push = ({ isNewTab, ...props }: PushParams) => {
    const path = getUrl(props)
    isNewTab ? window.open(path, '_blank') : history.push(path)
  }

  return { push, getUrl, getCommitmentUrl }
}
