import React, { useEffect, useRef, useState } from 'react'

import { useHistory, useLocation, useRouteMatch } from 'react-router-dom'
import { useLastLocation } from 'react-router-last-location'

import { ClockCircleOutlined, DownOutlined, MoreOutlined } from '@ant-design/icons'
import { Button, Dropdown, message, Modal, notification, Popconfirm, Space, Tooltip, Typography } from 'antd'
import { MenuProps } from 'antd/lib/menu'

import { observer } from 'mobx-react-lite'

import { Box } from 'common/components/boxes'
import { DrawerRef } from 'common/components/Drawer'
import { FloatActionsWrapper } from 'common/components/FloatActionsWrapper'
import { Loading } from 'common/components/Loading'
import { Page } from 'common/components/Page'
import { UnsavedPopup } from 'common/components/Page/unsaved_popup'
import { PdfViewer } from 'common/components/PdfViewer'
import Tags from 'common/components/tags'
import { TextArea } from 'common/components/TextArea'
import { Visibility } from 'common/components/Visibility'
import { useQuery } from 'common/hooks/use-query'
import { useTheme } from 'common/hooks/use-theme'
import { InvoicesStates, RelationshipStockStatus } from 'common/server/server_types'

import { WatchersDrawer } from 'contractor/components/WatchersDrawer'
import { makeWatcherOption } from 'contractor/components/WatchersSelect'
import { useFlag } from 'contractor/hooks/use-flag'
import { useLockInvoice } from 'contractor/hooks/use-lock-invoice'
import { useStores } from 'contractor/hooks/use-stores'
import { CreateFailedInvoiceDrawer } from 'contractor/pages/Invoices/Add/create_failed_invoice_drawer'
import { ActionStatus } from 'contractor/pages/Invoices/Detail/action_status'
import { WarnNotices } from 'contractor/pages/Invoices/Detail/Header/warn_notices'
import { InvoiceDiscardDrawer } from 'contractor/pages/Invoices/Detail/invoice_discard_drawer'
import { InvoiceGone } from 'contractor/pages/Invoices/Detail/invoice_gone'
import { InvoiceHeader } from 'contractor/pages/Invoices/Detail/invoice_header'
import { InvoiceInfo } from 'contractor/pages/Invoices/Detail/invoice_info'
import { FailedInboxPdfs, InvoicePdfSelector } from 'contractor/pages/Invoices/Inbox/Action/invoice_pdf_selector'
import { InboxDetail } from 'contractor/pages/Invoices/Inbox/Detail/inbox_detail'
import { InboxRowAction } from 'contractor/pages/Invoices/Inbox/List/list_item'
import { CreateInvoice } from 'contractor/server/integrations/invoices'
import { InvoiceInboxHit } from 'contractor/server/invoices/inbox'

import { CompanyVendorSelector } from './company_vendor_selector'
import { IntegrationSyncOriginal } from './integration_sync_original'
import { InternalComments } from './internal_comments'
import { InvoiceHistory } from './invoice_history'
import { InvoicePagination } from './invoice_pagination'
import { InvoiceCard } from './InvoiceCard'
import { OrdersCard } from './OrdersCard'

export type OrderItem = {
  label: string
  key: string
  order_id?: string
}

const SAVE_AND_NEXT_INVOICE_KEY = 'saveAndNextInvoice'

const getDefaultAction = () => JSON.parse(localStorage.getItem(SAVE_AND_NEXT_INVOICE_KEY))

const defaultSearchTab = {
  label: 'Attach Order',
  key: `tab-search`,
  order_id: null,
}

const UpdateButton = ({
  disableUpdate,
  saveAndNextDefault,
  onSaveInvoice,
  onSaveInvoiceAndNext,
  isSubmitting,
  listItemsCount,
}) => {
  return (
    <Tooltip title={disableUpdate ? 'To approve the invoice you must attach at least one order' : ''}>
      {listItemsCount > 1 ? (
        <Dropdown.Button
          menu={{
            items: [
              {
                label: saveAndNextDefault ? 'Update' : 'Update & Next',
                key: 'maybe-update-next',
                onClick: () => (saveAndNextDefault ? onSaveInvoice() : onSaveInvoiceAndNext()),
              },
            ],
          }}
          placement="bottomRight"
          trigger={['click', 'hover']}
          loading={isSubmitting}
          type="primary"
          onClick={() => (saveAndNextDefault ? onSaveInvoiceAndNext() : onSaveInvoice())}
          disabled={disableUpdate}
          icon={<DownOutlined />}
          style={{ width: 'auto' }}
        >
          {saveAndNextDefault ? 'Update & Next' : 'Update'}
        </Dropdown.Button>
      ) : (
        <Button loading={isSubmitting} type="primary" onClick={onSaveInvoice} disabled={disableUpdate}>
          Update
        </Button>
      )}
    </Tooltip>
  )
}

export const InvoiceDetailOriginal = observer(() => {
  const theme = useTheme()
  const {
    invoiceStore,
    invoiceInboxStore,
    invoiceStateStore,
    integrationStore,
    userStore,
    companySettingStore,
    invoiceSettingsStore,
  } = useStores()

  const { params } = useRouteMatch()
  const history = useHistory()
  const location = useLocation()
  const lastLocation = useLastLocation()

  const usingConsolidatedInvoices = useFlag('consolidated_invoices')
  const isInvoiceLocked = useLockInvoice(invoiceStore?.invoice)

  const { invoice } = invoiceStore
  const { listStore } = invoiceStore

  const listItems = invoiceStore.hits.length > 0 ? [...invoiceStore.hits] : listStore.records

  const detailDrawerRef = useRef<DrawerRef>()
  const pdfSelectorDrawerRef = useRef<DrawerRef>()
  const createFailedInvoiceDrawerRef = useRef<DrawerRef>()
  const historyDrawerRef = useRef<DrawerRef>()

  const discardDrawerRef = useRef<DrawerRef>()

  const [saveAndNextDefault, setSaveAndNextDefault] = useState(getDefaultAction())
  const [previousListLocation] = useState(lastLocation)

  const [items, setItems] = useState<OrderItem[]>([])

  const [stateId, setStateId] = useState('')
  const [assignToId, setAssignToId] = useState(null)
  const [rejectionReason, setRejectionReason] = useState('')
  const [selectedActionHit, setSelectedActionHit] = useState<Nullable<InvoiceInboxHit>>(null)
  const [selectedPdfFiles, setSelectedPdfFiles] = useState<Nullable<FailedInboxPdfs>>(null)
  const [isReprocessing, setIsReprocessing] = useState(false)
  const [isHistoryDrawerVisible, setIsHistoryDrawerVisible] = useState(false)

  const [rejectionReasonOpen, toggleRejectionReasonModal] = useState(false)
  const [integrationModalOpen, toggleIntegrationModal] = useState(false)
  const [isSubmitting, setSubmitting] = useState(false)

  const rejectedState = invoiceStateStore.invoiceStates?.find(({ state }) => state === InvoicesStates.REJECTED)
  const approvedState = invoiceStateStore.invoiceStates?.find(({ state }) => state === InvoicesStates.APPROVED)
  const postedState = invoiceStateStore.invoiceStates?.find(({ state }) => state === InvoicesStates.POSTED)

  useQuery(invoiceSettingsStore.indexSettings)
  useQuery(invoiceStore.getInvoiceTags)
  useQuery(() => {
    if (userStore.canUseIntegrations) {
      return integrationStore.accountingInfo()
    }
  })
  const { isLoading } = useQuery(
    () =>
      invoiceStore
        .getInvoice(params['id'])
        .then((invoice) => {
          if (invoice?.orders?.length) {
            setItems(
              invoice.orders.map(({ id, order_number }) => ({
                label: order_number,
                key: id,
                order_id: id,
              })),
            )
          } else {
            setItems([defaultSearchTab])
          }
          setAssignToId(invoice.assigned_to_id)
          setStateId(invoice.state?.id)
          setRejectionReason(invoice.rejection_reason)
        })
        .catch((error) => {
          if (error?.response?.status === 404) {
            history.push('/invoices')
          } else if (error?.response?.status === 410) {
            setIsReprocessing(true)
          }
        }),
    [params['id']],
  )

  const handleHistoryClick = () => {
    setIsHistoryDrawerVisible(true)
    historyDrawerRef.current?.show()
  }

  const goBack = () => {
    if (previousListLocation) {
      history.push(previousListLocation)
    } else {
      history.push('/invoices')
    }
  }

  useEffect(() => {
    const params = new URLSearchParams(location.search)

    if (params.get('retry-sync') === 'true') {
      toggleIntegrationModal(true)
    }
  }, [location.search])

  useEffect(() => {
    if (!items.length) {
      setItems([defaultSearchTab])
    }
  }, [items.length])

  const handleNavigateAfterSave = () => {
    const currentInvoiceIndex = listItems?.findIndex(
      (listItem) => listItem.invoice_id === invoiceStore.invoice.id || listItem.id === invoiceStore.invoice.id,
    )

    if (currentInvoiceIndex < listItems.length - 1) {
      const nextInvoice = listItems[currentInvoiceIndex + 1]
      history.push(`./${nextInvoice?.['invoice_id'] || nextInvoice?.id}`)
    } else {
      goBack()
    }
  }

  // Show modal to the user select sync or not the invoice. Only show the modal if it is not synced yet, or the previous sync has failed
  const maybeShowIntegrationBeforePost = () => {
    const statusAllowToSync = [RelationshipStockStatus.NOT_SYNCED, RelationshipStockStatus.FAILED]

    // Show modal to the user select sync or not the invoice. Only show the modal if it is not synced yet, or the previous sync has failed
    if (
      stateId === postedState.id &&
      !integrationModalOpen &&
      statusAllowToSync.includes(invoiceStore.invoice?.integration?.status) &&
      integrationStore.connected &&
      userStore.canUseIntegrations &&
      integrationStore.invoiceSyncEnabled
    ) {
      toggleIntegrationModal(true)
      return true
    }

    return false
  }

  // Show modal to the user fill in the reject reason when changing to reject state
  const maybeShowRejectionModalBeforeReject = () => {
    if (stateId === rejectedState.id && !rejectionReasonOpen) {
      toggleRejectionReasonModal(true)
      return true
    }

    return false
  }

  const saveInvoice = async () => {
    return invoiceStore.save({
      order_ids: items.filter((item) => item.order_id).map((item) => item.order_id),
      state_id: stateId,
      company_vendor_id: invoice.company_vendor_id || null,
      assigned_to_id: assignToId ? assignToId : null,
      tags: invoice.tags,
      watcher_ids: invoice.watcher_ids,
      rejection_reason: stateId === rejectedState.id ? rejectionReason?.trim() : null,
      number: invoice.number,
      name: invoice.name,
      document_date: invoice.document_date,
      accounting_date: invoice.accounting_date,
      total_amount: invoice.total_amount,
      seller_name: invoice.seller_name,
    })
  }

  const handleConfirmSync = async (formValues) => {
    try {
      setSubmitting(true)
      toggleIntegrationModal(false)
      await saveInvoice()

      const { vendor, customer, ledgerAccount } = formValues
      let invoiceParams: CreateInvoice = { id: invoice?.id }
      if (!integrationStore.isProcore()) {
        invoiceParams = {
          ...invoiceParams,
          vendor_id: vendor?.value || vendor,
          customer_id: customer?.value || customer,
          ledger_account_id: ledgerAccount?.value || ledgerAccount,
          class_id: formValues?.class?.value || formValues?.class,
        }
      }
      await integrationStore.createInvoice(invoiceParams)
      notification.success({
        message: `Syncing the invoice with ${integrationStore.getIntegrationName()}`,
        description: 'The synchronization can take a time to finish.',
        placement: 'bottomLeft',
        duration: 10,
      })
      if (saveAndNextDefault) {
        handleNavigateAfterSave()
      }
    } catch (error) {
      message.error(error?.response?.data?.message || 'Unable to sync.')
    } finally {
      setSubmitting(false)
    }
  }

  const handleSaveInvoice = async () => {
    saveAndNextDefault && setSaveAndNextDefault(false)
    localStorage.setItem(SAVE_AND_NEXT_INVOICE_KEY, 'false')

    const showIntegrationModal = maybeShowIntegrationBeforePost()
    const showRejectionModal = maybeShowRejectionModalBeforeReject()

    if (showIntegrationModal || showRejectionModal) {
      return
    }

    try {
      setSubmitting(true)
      await saveInvoice()

      if (usingConsolidatedInvoices) {
        message.success('Invoice updated')

        goBack()

        return
      }

      notification.success({
        message: 'Invoice updated',
        description: 'Please allow a few seconds for your changes to appear',
        placement: 'bottomLeft',
        duration: 10,
      })

      goBack()
    } catch (error) {
      message.error(error?.response?.data?.error || 'Unable to save the invoice')
    } finally {
      setSubmitting(false)
    }
  }

  const handleSaveInvoiceAndNext = async () => {
    !saveAndNextDefault && setSaveAndNextDefault(true)
    localStorage.setItem(SAVE_AND_NEXT_INVOICE_KEY, 'true')

    const showIntegrationModal = maybeShowIntegrationBeforePost()
    const showRejectionModal = maybeShowRejectionModalBeforeReject()

    if (showIntegrationModal || showRejectionModal) {
      return
    }

    try {
      setSubmitting(true)
      await saveInvoice()

      if (usingConsolidatedInvoices) {
        message.success('Invoice updated')

        handleNavigateAfterSave()

        return
      }

      notification.success({
        message: 'Invoice updated',
        description: 'Please allow a few seconds for your changes to appear',
        placement: 'bottomLeft',
        duration: 10,
      })

      handleNavigateAfterSave()
    } catch (error) {
      message.error(error?.response?.data?.error || 'Unable to save the invoice')
    } finally {
      setSubmitting(false)
    }
  }

  const onClickNext = (selected: FailedInboxPdfs) => {
    setSelectedPdfFiles(selected)
    pdfSelectorDrawerRef.current?.close()
    createFailedInvoiceDrawerRef.current?.show()
  }

  const onSelectAction = (hit: InvoiceInboxHit, action: InboxRowAction) => {
    if (action === InboxRowAction.CREATE) {
      setSelectedActionHit(hit)
      detailDrawerRef.current?.close()
      pdfSelectorDrawerRef.current?.show()
    } else if (action === InboxRowAction.IGNORE) {
      message.loading('Ignoring inbox record errors')
      invoiceInboxStore
        .ignoreFailures(hit.id)
        .then((_) => {
          message.success('Marked as ignored')
          detailDrawerRef.current?.close()
        })
        .catch((_) => {
          message.error('Marking record as ignored')
        })
    }
  }

  const onDelete = async () => {
    discardDrawerRef.current?.show()
  }

  const onDiscard = async (discardReason: string, discardExplained?: string) => {
    try {
      await invoiceStore.delete(invoice.id, discardReason, discardExplained)

      if (usingConsolidatedInvoices) {
        message.success('Invoice deleted')

        goBack()

        return
      }

      notification.success({
        message: 'Invoice deleted',
        description: 'Please allow a few seconds for your changes to appear',
        placement: 'bottomLeft',
        duration: 10,
      })

      goBack()
    } catch {
      message.error('Unable to delete this Invoice')
    }
  }

  const extras: MenuProps['items'] = [
    {
      label: (
        <Popconfirm
          title="Are you sure? This action can’t be undone."
          onConfirm={onDelete}
          okText="Yes"
          placement="bottomRight"
          cancelText="No"
        >
          <Typography style={{ color: theme.colors['red-6'] }}>Delete Invoice</Typography>
        </Popconfirm>
      ),
      key: '1',
      onClick: (e) => {
        e.domEvent.preventDefault()
        e.domEvent.stopPropagation()
      },
    },
  ]

  if (isLoading) return <Loading />

  if (!invoice && isReprocessing) {
    // auto refresh the page after 5 seconds
    setTimeout(() => window.location.reload(), 5000)
    return <InvoiceGone goBack={goBack} />
  }

  if (!invoice) return null

  const disableUpdate = items.filter((item) => item.order_id !== null).length === 0 && stateId === approvedState?.id

  return (
    <Page>
      <Page.Header>
        <Box display="flex" alignItems="flex-start" justifyContent="space-between">
          <Box display="flex" alignItems="center">
            <Box mr={16}>
              <UnsavedPopup goBack={goBack} />
            </Box>
            <InvoiceHeader />
          </Box>

          <Box display="flex" alignItems="center" style={{ gap: 8 }}>
            <Visibility.Hidden breakpoint="lg">
              {!isInvoiceLocked && (
                <WatchersDrawer
                  disabled={isInvoiceLocked}
                  value={companySettingStore.possibleUsers
                    .filter((user) => invoice?.watcher_ids?.includes(user.id))
                    .map((user) => makeWatcherOption(user))}
                  onChange={(watchers = []) => (invoice.watcher_ids = watchers?.map((watcher) => watcher.value))}
                />
              )}
              <Box minWidth={160}>
                <Tags
                  disabled={isInvoiceLocked}
                  value={invoice.tags}
                  onChange={(tags) => (invoice.tags = tags)}
                  tags={invoiceStore.invoiceTags}
                />
              </Box>
            </Visibility.Hidden>

            <ActionStatus
              disabled={isInvoiceLocked}
              stateId={stateId}
              changeStatus={setStateId}
              toggleRejectionReasonModal={toggleRejectionReasonModal}
            />

            <Visibility.Hidden breakpoint="md">
              <InternalComments />
              <Button
                disabled={isInvoiceLocked}
                style={{ minWidth: 32 }}
                onClick={handleHistoryClick}
                icon={<ClockCircleOutlined />}
              />
              <UpdateButton
                disableUpdate={disableUpdate || isInvoiceLocked}
                listItemsCount={listItems.length}
                saveAndNextDefault={saveAndNextDefault}
                isSubmitting={isSubmitting}
                onSaveInvoice={handleSaveInvoice}
                onSaveInvoiceAndNext={handleSaveInvoiceAndNext}
              />
              {userStore.canEditAndDeleteInvoices && (
                <Dropdown.Button
                  menu={{ items: extras }}
                  autoFocus
                  trigger={['hover', 'click']}
                  icon={<MoreOutlined />}
                  onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                  }}
                  style={{ width: 'auto' }}
                  buttonsRender={([_, rightButton]) => [null, React.cloneElement(rightButton as React.ReactElement)]}
                />
              )}
            </Visibility.Hidden>
          </Box>
        </Box>
      </Page.Header>

      <WarnNotices
        showWarnNotice={invoice?.duplicated_invoices?.length > 0}
        duplicatedInvoices={invoice?.duplicated_invoices}
      />

      <InboxDetail
        invoiceInboxId={invoice?.invoice_source_id}
        invoiceInboxSourceType={invoice?.invoice_source_type}
        onSelectAction={onSelectAction}
        ref={detailDrawerRef}
      />
      <InvoicePdfSelector
        inbox_id={selectedActionHit?.id}
        files={selectedActionHit?.files}
        ref={pdfSelectorDrawerRef}
        onClickNext={onClickNext}
      />
      <CreateFailedInvoiceDrawer
        ref={createFailedInvoiceDrawerRef}
        failedInboxPdfs={selectedPdfFiles}
        onClose={() => createFailedInvoiceDrawerRef.current?.close()}
      />
      <InvoiceHistory
        isVisible={isHistoryDrawerVisible}
        ref={historyDrawerRef}
        onClose={() => historyDrawerRef.current?.close()}
      />

      <Modal
        title="Rejection Reason"
        open={rejectionReasonOpen}
        onCancel={() => toggleRejectionReasonModal(false)}
        okButtonProps={{
          onClick: () => {
            toggleRejectionReasonModal(false)
            if (saveAndNextDefault) {
              handleSaveInvoiceAndNext()
            } else {
              handleSaveInvoice()
            }
          },
        }}
      >
        <TextArea
          value={rejectionReason}
          onChange={(e) => setRejectionReason(e.target.value)}
          showCount
          maxLength={255}
          style={{ height: 120, resize: 'none' }}
        />
      </Modal>

      {integrationStore.connected &&
        userStore.canSyncWithErp &&
        userStore.canUseIntegrations &&
        !isInvoiceLocked &&
        integrationStore.invoiceSyncEnabled && (
          <IntegrationSyncOriginal
            onCancel={() => toggleIntegrationModal(false)}
            open={integrationModalOpen}
            onFinish={handleConfirmSync}
            onFinishWithoutSync={handleSaveInvoice}
            isSubmitting={isSubmitting}
          />
        )}

      {/* Desktop */}
      <Visibility.Hidden breakpoint="lg">
        <Page.Content>
          <Box display="flex" flexDirection="column" style={{ maxHeight: '100%', height: '100%' }}>
            <InvoiceInfo disabled={isInvoiceLocked} onSelectAssign={setAssignToId} assignTo={assignToId} />

            <Box display="flex" flex={1} style={{ gap: 16, overflow: 'hidden' }} width="100%" mt={8}>
              <InvoiceCard invoice={invoice} onOpenInvoiceInbox={() => detailDrawerRef.current?.show()} />
              <OrdersCard
                disabled={isInvoiceLocked}
                grandTotal={invoice?.total_amount}
                orderNumber={invoice?.extracted_order_number}
                companyVendorId={invoice?.company_vendor_id}
                orderItems={items}
                onChangeOrderItems={setItems}
              />
            </Box>
          </Box>
          <Box width="100%" display="flex" justifyContent="flex-end">
            <InvoicePagination />
          </Box>
        </Page.Content>
      </Visibility.Hidden>

      {/* Mobile */}
      <Visibility.Show breakpoint="lg">
        <Page.Tabs
          tabBarGutter={24}
          items={[
            {
              label: 'Details',
              key: 'details',
              children: (
                <Box
                  mt={24}
                  mb={100}
                  width="100%"
                  bg="white"
                  p={16}
                  display="flex"
                  flexDirection="column"
                  style={{ gap: 24 }}
                >
                  <Space direction="vertical" size="small">
                    <Typography.Text type="secondary">VENDOR</Typography.Text>
                    <CompanyVendorSelector disabled={isInvoiceLocked} />
                  </Space>
                  <InvoiceInfo onSelectAssign={setAssignToId} assignTo={assignToId} />
                </Box>
              ),
            },
            {
              label: 'PDF',
              key: 'pdf',
              children: (
                <Box pt={24} pb={100} width="100%" height="100%">
                  <PdfViewer
                    url={invoiceStore.invoice?.extracted_files[0]?.url}
                    style={{ height: '100%', minHeight: '100%', borderRadius: 4 }}
                  />
                </Box>
              ),
            },
          ]}
        />
      </Visibility.Show>

      <Visibility.Show breakpoint="md">
        <FloatActionsWrapper>
          <UpdateButton
            disableUpdate={disableUpdate || isInvoiceLocked}
            listItemsCount={listItems.length}
            saveAndNextDefault={saveAndNextDefault}
            isSubmitting={isSubmitting}
            onSaveInvoice={handleSaveInvoice}
            onSaveInvoiceAndNext={handleSaveInvoiceAndNext}
          />
          {userStore.canEditAndDeleteInvoices && (
            <Dropdown.Button
              menu={{ items: extras }}
              autoFocus
              disabled={isInvoiceLocked}
              trigger={['hover', 'click']}
              icon={<MoreOutlined />}
              onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
              }}
              style={{ width: 'auto' }}
              buttonsRender={([_, rightButton]) => [null, React.cloneElement(rightButton as React.ReactElement)]}
            />
          )}
        </FloatActionsWrapper>
      </Visibility.Show>

      <InvoiceDiscardDrawer
        onDiscard={onDiscard}
        ref={discardDrawerRef}
        onCancel={() => discardDrawerRef.current?.close()}
      />
    </Page>
  )
})
