import React from 'react'

import { useHistory, useLocation } from 'react-router-dom'

import { Box } from 'common/components/boxes'
import { Page } from 'common/components/Page'

import { useStores } from 'contractor/hooks/use-stores'
import { InvoiceTab } from 'contractor/pages/Invoices/List/invoice_tab'

import { CreateInvoiceAction } from './create_invoice_actions'
import { Inbox } from './Inbox'
import { ToReview } from './ToReview'

export const InvoicesPage = () => {
  const { userStore } = useStores()
  const history = useHistory()
  const location = useLocation()

  const tabItems = [
    {
      label: 'To Review',
      key: 'to_review',
      children: <ToReview />,
    },
    ...(userStore.canViewAllInvoices || userStore.canViewAllMyProjectInvoices
      ? [
          {
            label: 'All Invoices',
            key: 'invoices',
            children: <InvoiceTab />,
          },
        ]
      : []),
    ...(userStore.canViewInvoiceInbox
      ? [
          {
            label: 'Inbox',
            key: 'invoice_inboxes',
            children: <Inbox />,
          },
        ]
      : []),
  ]

  function handleChangeTab(key: 'invoice' | 'to_review' | 'invoice_inboxes') {
    const searchParams = new URLSearchParams(location.search)
    searchParams.set('tab', key)

    history.push({
      search: searchParams.toString(),
    })
  }

  const activeKey = new URLSearchParams(location.search).get('tab')

  return (
    <Page>
      <Page.Tabs
        destroyInactiveTabPane
        activeKey={activeKey || 'to_review'}
        items={tabItems}
        onChange={handleChangeTab}
        tabBarExtraContent={
          <Box mr={4}>
            <CreateInvoiceAction />
          </Box>
        }
      />
    </Page>
  )
}
