import React from 'react'

import { Link, useLocation } from 'react-router-dom'

import { DeliveredProcedureOutlined, PlusOutlined, SnippetsOutlined } from '@ant-design/icons'

import { useStores } from 'contractor/hooks/use-stores'

export const getFastActionMenuItem = ({ onOpen }) => {
  const { userStore, companySettingStore } = useStores()
  const location = useLocation()

  const showCreateRFQ = userStore.canUseCreateRFQ && userStore.canSendAndUpdateRfqs

  const getUrl = (url: string) => {
    return `${url}${location.search}`
  }

  return {
    key: 'fast-actions',
    icon: <PlusOutlined data-cy="extra-actions" style={{ fontSize: 20, minWidth: 24, verticalAlign: 'middle' }} />,
    theme: 'light',
    children: [
      userStore?.cannotSendAndUpdateOrders && {
        key: 'Request Material',
        label: (
          <Link to={getUrl(`/new_order/${companySettingStore?.otherSettings?.public_order_form_url_extension}`)}>
            Request Material
          </Link>
        ),
      },
      userStore.canSendAndUpdateOrders && {
        key: 'create-order',
        label: (
          <Link to={getUrl(`/orders/new_order`)}>
            <PlusOutlined style={{ fontSize: 16, marginRight: 16 }} />
            Create Order
          </Link>
        ),
      },
      showCreateRFQ && {
        key: 'create-rfq',
        label: (
          <Link to={getUrl(`/orders/new_quote`)}>
            <SnippetsOutlined style={{ fontSize: 16, marginRight: 16 }} />
            Create RFQ
          </Link>
        ),
      },
      userStore.canEditDeliveryInformation && {
        key: 'log-delivery',
        label: (
          <>
            <DeliveredProcedureOutlined style={{ fontSize: 16, marginRight: 16 }} />
            Log Delivery
          </>
        ),
        onClick: () => onOpen(),
      },
    ],
    className: 'only-icon',
  }
}
