import React from 'react'

import DeleteOutlined from '@ant-design/icons/DeleteOutlined'
import { Button, Typography, Tooltip } from 'antd'

import { FlexBoxX, Box } from 'common/components/boxes'
import { Select, Select2Props, DefaultOption } from 'common/components/Select'
import { formatName } from 'common/helpers/formatters'
import { useQuery } from 'common/hooks/use-query'

import { useStores } from 'contractor/hooks/use-stores'

export type WatchersSelectProps = Select2Props<DefaultOption, true>

export const makeWatcherOption = (user, removable = true) => {
  const { id, first_name, last_name, email } = user
  const name = formatName(first_name, last_name)
  return { label: name || email, value: id, id, removable }
}

export const WatchersSelect = ({ value = [], onChange, isDisabled, ...props }: WatchersSelectProps) => {
  const { companySettingStore } = useStores()

  const { isLoading } = useQuery(companySettingStore.maybeIndexUsers)

  const handleRemove = (id: string) => {
    onChange(
      value.filter((item) => item.value !== id),
      { action: 'deselect-option' },
    )
  }

  return (
    <Box display="flex" flexDirection="column" width="100%">
      {!isDisabled && (
        <Box width="100%" mb={16}>
          <Select
            aria-label="watcher-select"
            data-cy="select-watcher"
            placeholder="Search for user to add"
            noOptionsMessage={() => 'No users'}
            components={{ ClearIndicator: null, IndicatorSeparator: null }}
            options={companySettingStore.possibleUsers.map((user) => makeWatcherOption(user))}
            onChange={onChange}
            blurInputOnSelect={false}
            controlShouldRenderValue={false}
            closeMenuOnSelect
            hideSelectedOptions
            isMulti
            value={value}
            isLoading={isLoading}
            {...props}
          />
        </Box>
      )}

      {(value || []).map(({ label, value, removable }) => (
        <FlexBoxX key={value} justifyContent="space-between" mb={10} width="100%" flexGrow={0}>
          <Typography.Text>{label}</Typography.Text>
          {!isDisabled && removable && (
            <Tooltip title="Remove" placement="topRight">
              <Button
                style={{ marginLeft: '12px' }}
                size="small"
                type="dashed"
                icon={<DeleteOutlined />}
                onClick={() => handleRemove(value)}
              />
            </Tooltip>
          )}
        </FlexBoxX>
      ))}
    </Box>
  )
}
