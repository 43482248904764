import React, { useEffect, useRef, useState } from 'react'

import { connectRefinementList } from 'react-instantsearch-dom'

import hotkeys from 'hotkeys-js'
import { sumBy, get } from 'lodash'

import { RightOutlined, LeftOutlined } from '@ant-design/icons'
import { Button } from 'antd'

import { Box } from 'common/components/boxes'

import { LeftButton, RightButton, Scrollable, StyledTag, BadgeStyled } from './styles'

const MULTI_SELECTION_KEY = 'shift'

export const AlgoliaTagsFilter = connectRefinementList((props) => {
  const { items, currentRefinement, refine, options = [], boxProps, onChange } = props

  const scrollableRef = useRef<HTMLDivElement>(null)
  const wrapperRef = useRef<HTMLDivElement>(null)

  const [showLeftButton, setLeftButton] = useState(false)
  const [showRightButton, setRightButton] = useState(false)

  const total = sumBy(items, 'count')

  useEffect(() => {
    hotkeys(MULTI_SELECTION_KEY, function () {
      console.log('Pressed', MULTI_SELECTION_KEY)
    })
    return () => {
      hotkeys.unbind(MULTI_SELECTION_KEY)
    }
  }, [])

  const handleClickPrev = () =>
    scrollableRef.current?.scrollTo({
      left: scrollableRef.current?.scrollLeft - 100,
      behavior: 'smooth',
    })

  const handleClickNext = () =>
    scrollableRef.current?.scrollTo({
      left: scrollableRef.current?.scrollLeft + 100,
      behavior: 'smooth',
    })

  const getRefineParam = (filter: string) => {
    if (hotkeys.isPressed(MULTI_SELECTION_KEY)) {
      return [...currentRefinement, filter]
    }

    return filter
  }

  useEffect(() => {
    const onScroll = () => {
      if (scrollableRef.current?.scrollLeft > 0) {
        !showLeftButton && setLeftButton(true)
      } else {
        showLeftButton && setLeftButton(false)
      }

      if (
        scrollableRef.current?.scrollLeft + scrollableRef.current?.offsetWidth >=
        scrollableRef.current?.scrollWidth
      ) {
        showRightButton && setRightButton(false)
      } else {
        !showRightButton && setRightButton(true)
      }
    }

    scrollableRef.current?.addEventListener('scroll', onScroll)
    return () => scrollableRef.current?.removeEventListener('scroll', onScroll)
  }, [showLeftButton, showRightButton, scrollableRef.current])

  useEffect(() => {
    if (Math.round(scrollableRef.current?.scrollWidth) > Math.round(wrapperRef.current?.clientWidth)) {
      !showRightButton && setRightButton(true)
    } else {
      showRightButton && setRightButton(false)
    }
  }, [wrapperRef.current])

  useEffect(() => {
    const onWheel = (event) => {
      if (event.deltaY === 0) return
      event.preventDefault()
      const left = scrollableRef.current?.scrollLeft + event.deltaY

      scrollableRef.current?.scrollTo({
        left,
        behavior: 'smooth',
      })
    }

    scrollableRef.current?.addEventListener('wheel', onWheel)
    return () => scrollableRef.current?.removeEventListener('wheel', onWheel)
  }, [scrollableRef.current])

  return (
    <Box position="relative" maxWidth="100%" {...boxProps} ref={wrapperRef}>
      {showLeftButton && (
        <LeftButton>
          <Box bg="white" display="flex" alignItems="center">
            <Button icon={<LeftOutlined />} type="text" shape="circle" onClick={handleClickPrev} size="small" />
          </Box>
        </LeftButton>
      )}

      <Scrollable overflowX="auto" display="flex" flexWrap="nowrap" ref={scrollableRef}>
        {options?.map((option) => {
          const item = items.find((item) => item['label'] === option.filter)

          const count = option.filter === '' ? total : get(item, 'count', 0)

          const isChecked =
            currentRefinement.length > 1
              ? currentRefinement.includes(option.filter)
              : get(currentRefinement, '[0]', '') === option.filter

          return (
            <StyledTag
              data-cy={`${option.label}-filter`}
              key={option.label}
              checked={isChecked}
              onChange={() => {
                onChange?.(option.label)
                const param = getRefineParam(option.filter)
                refine(param)
              }}
            >
              {option.label}
              <BadgeStyled aria-label="counter-badge" count={count} showZero overflowCount={999} />
            </StyledTag>
          )
        })}
      </Scrollable>

      {showRightButton && (
        <RightButton>
          <Box bg="white" display="flex" alignItems="center">
            <Button icon={<RightOutlined />} type="text" shape="circle" onClick={handleClickNext} size="small" />
          </Box>
        </RightButton>
      )}
    </Box>
  )
})
