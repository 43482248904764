import React from 'react'

import { Link } from 'react-router-dom'
import { Column } from 'react-table'

import _ from 'lodash'

import { Typography, Tag } from 'antd'

import { AgaveSyncStatus } from 'common/components/AgaveSyncStatus'
import { Box } from 'common/components/boxes'
import { currencyFormatter, formatDateString } from 'common/helpers/formatters'

import { InvoiceStatusTag } from 'contractor/components/Invoices/invoice_status_tag'
import { useStores } from 'contractor/hooks/use-stores'

export const UnknownText = () => (
  <Typography.Paragraph type="secondary" style={{ marginBottom: 0 }}>
    Unknown
  </Typography.Paragraph>
)

export const NotAssignedText = () => (
  <Typography.Paragraph type="secondary" style={{ marginBottom: 0 }}>
    Not assigned
  </Typography.Paragraph>
)
// @todo: add a correct type
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getInvoiceColumns(excludeColumns: Array<string> = []): Array<Column<any>> {
  const { userStore, integrationStore, invoiceSettingsStore } = useStores()
  const accountingDateEnabled = invoiceSettingsStore?.settings?.accounting_date_enabled
  const insertIf = (condition, ...elements) => (condition ? elements : [])

  const columns = [
    {
      Header: 'Status',
      accessor: 'state',
      sortBy: 'state',
      Cell: ({ row, value }) => {
        return (
          <Box width="fit-content">
            <InvoiceStatusTag state={value} rejectionReason={row.original?.rejection_reason} />
          </Box>
        )
      },
    },
    {
      Header: 'Assigned To',
      accessor: ({ assigned_to_label }) => {
        return assigned_to_label === 'Not assigned' ? <NotAssignedText /> : assigned_to_label
      },
      width: 200,
      sortBy: 'assigned_to_label',
    },
    {
      Header: 'Vendor',
      accessor: ({ company_vendor_name }) => (company_vendor_name === null ? <UnknownText /> : company_vendor_name),
      width: 300,
      sortBy: 'company_vendor_name',
    },
    {
      Header: 'Invoice',
      width: 240,
      Cell: ({ row }) => {
        return (
          <Link onClick={(e) => e.stopPropagation()} to={`/invoice/${row?.original?.invoice_id}`}>
            {row.original.invoice_name ? `${row.original.invoice_name}: ` : ''}
            {row.original.invoice_number}
          </Link>
        )
      },
    },
    {
      Header: 'Projects',
      accessor: 'project_names',
      sortBy: 'project_names',
      width: 300,
      Cell: ({ value }) => {
        if (_.isEqual(value, ['unknown'])) return <UnknownText />
        return (
          <Box display="flex" gridGap={2}>
            {value.map((name, index) => (
              <Typography.Paragraph key={`${name}-${index}-p-key`} style={{ marginBottom: 0 }}>
                {name}
                {index + 1 < value?.length ? ', ' : ''}
              </Typography.Paragraph>
            ))}
          </Box>
        )
      },
    },
    {
      Header: 'Orders',
      accessor: 'order_names',
      sortBy: 'order_names',
      width: 300,
      Cell: ({ row }) => {
        const orders = row.original.order_names

        if (!orders) return <UnknownText />
        return <Typography.Paragraph style={{ marginBottom: 0 }}>{orders}</Typography.Paragraph>
      },
    },
    {
      Header: 'Created At',
      id: 'created_at',
      sortBy: 'created_at',
      accessor: ({ invoice_created_at }) =>
        invoice_created_at ? formatDateString(invoice_created_at) : <UnknownText />,
      width: 160,
    },
    {
      Header: 'Invoice Date',
      id: 'invoice_date',
      accessor: ({ invoice_date }) => (invoice_date ? formatDateString(invoice_date) : <UnknownText />),
      width: 160,
      sortBy: 'invoice_date',
    },
    ...insertIf(accountingDateEnabled, {
      Header: 'Accounting Date',
      id: 'accounting_date',
      accessor: ({ accounting_date }) => (accounting_date ? formatDateString(accounting_date) : <UnknownText />),
      width: 160,
      sortBy: 'accounting_date',
    }),
    {
      Header: 'Due Date',
      id: 'due_date',
      accessor: ({ due_date }) => (due_date ? formatDateString(due_date) : <UnknownText />),
      width: 160,
      sortBy: 'due_date',
    },
    {
      Header: 'Total Amount',
      id: 'total_amount',
      sortBy: 'total_amount',
      accessor: ({ total_amount }) => (total_amount ? currencyFormatter(total_amount, 2) : <UnknownText />),
      width: 150,
    },
    ...(userStore.canUseIntegrations && integrationStore.invoiceSyncEnabled
      ? [
          {
            Header: 'Sync Status',
            accessor: 'agave_sync_status',
            id: 'agave_sync_status',
            width: 150,
            Cell: ({ value }) => <AgaveSyncStatus status={value} />,
          },
        ]
      : []),
    {
      Header: 'Posted At',
      id: 'posted_at',
      sortBy: 'posted_at',
      accessor: ({ posted_at }) => (posted_at ? formatDateString(posted_at) : <UnknownText />),
      width: 160,
    },
    {
      Header: 'Tags',
      accessor: 'tags',
      id: 'tags',
      sortBy: 'tags',
      Cell: ({ value = [] }) => {
        return value?.map((tag) => <Tag key={tag}>{tag}</Tag>)
      },
      width: 150,
    },
  ]

  return columns.filter((column) => !excludeColumns.includes(column.Header))
}
