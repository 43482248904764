import React, { useEffect } from 'react'

import { ShopOutlined } from '@ant-design/icons'
import { Form, Space, Tooltip, Typography } from 'antd'

import { CustomEditableContent } from 'common/components/CustomEditableContent'
import { Events, trackEvent } from 'common/pendo/event_tracking'

import { useCommitment } from 'contractor/pages/@v2/Commitments/Components/CommitmentContext/context'

import { CompanyVendorsAutoComplete } from './company_vendor_autocomplete'

interface CompanyVendorSelectProps {
  disabled?: boolean
}

export const CompanyVendorSelect = (props: CompanyVendorSelectProps) => {
  const { setDirty, setSelectedCompanyVendor, selectedCompanyVendor, companyVendors, commitment } = useCommitment()
  const { disabled } = props
  const [vendorName, setVendorName] = React.useState<string>('Select Vendor')
  const source = window.location.pathname
  const handleFindCompanyVendor = (vendorName: string) => {
    if (!vendorName) return setSelectedCompanyVendor(null)

    const newCompanyVendor = companyVendors.find(
      (companyVendor) => companyVendor.vendor_name === vendorName || companyVendor.vendor?.name === vendorName,
    )
    setSelectedCompanyVendor(newCompanyVendor)
  }

  useEffect(() => {
    setVendorName(selectedCompanyVendor?.vendor_name || selectedCompanyVendor?.vendor?.name)
  }, [selectedCompanyVendor, companyVendors])

  if (disabled) {
    return (
      <Tooltip title="Vendor" placement="bottom">
        <Space>
          <ShopOutlined />
          <Typography.Text type="secondary">{vendorName}</Typography.Text>
        </Space>
      </Tooltip>
    )
  }

  return (
    <CustomEditableContent
      boxProps={{ mt: 0, mb: 0 }}
      showRequired={!commitment?.id}
      input={
        <Form.Item style={{ marginBottom: 0 }} name="vendorName">
          <CompanyVendorsAutoComplete
            value={vendorName || 'Select Vendor'}
            disabled={disabled}
            onChange={(value = '') => {
              trackEvent(Events.CLICK_VENDOR, source)
              handleFindCompanyVendor(value)
              setDirty(true)
            }}
          />
        </Form.Item>
      }
    >
      <Tooltip title="Vendor" placement="bottom">
        <Space>
          <ShopOutlined />
          <Typography.Text type="secondary">{vendorName || 'Select Vendor'}</Typography.Text>
        </Space>
      </Tooltip>
    </CustomEditableContent>
  )
}
