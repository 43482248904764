import React from 'react'

import Bluebird from 'bluebird'
import { downloadZip } from 'client-zip'
import { compact, flatten } from 'lodash'
import streamSaver from 'streamsaver'
import { v4 as uuidV4 } from 'uuid'

import { DownloadOutlined } from '@ant-design/icons'
import { Button, notification } from 'antd'

import { observer } from 'mobx-react-lite'

import { useStores } from 'contractor/hooks/use-stores'

const getFile = async (url) => {
  const result = await fetch(url)

  if (!result.ok) {
    throw Error(result.statusText)
  }

  const header = result.headers.get('Content-Disposition')
  const parts = header.split(';')
  const filename = parts[1]?.split('=')[1]?.replace(/"/g, '') || 'invoice.pdf'

  const blob = await result.blob()

  return new File([blob], filename, {
    type: blob.type,
    lastModified: new Date().getTime(),
  })
}

const getFileByGroup = (urls) => {
  return Bluebird.map(
    urls,
    (url) => {
      return getFile(url)
    },
    { concurrency: 5 },
  )
}

export const DownloadInvoices = observer(() => {
  const { invoiceStore } = useStores()

  const handleDownload = async () => {
    const notificationSuccessKey = uuidV4()

    try {
      notification.info({
        message: `Downloading Invoices`,
        description: `We're preparing your invoices. This can take a few minutes.`,
        placement: 'bottomLeft',
        duration: 0,
      })

      const hits = await invoiceStore.listStore.fetchAllRecords()
      const urls = compact(flatten(hits.map((hit) => hit.files?.map((file) => file.url))))

      const filesToDownload = await getFileByGroup(urls)

      const filesSize = filesToDownload.reduce((acc, curr) => acc + curr?.size, 0)

      const writeStream = streamSaver.createWriteStream('invoices.zip', { size: filesSize })
      downloadZip(filesToDownload).body.pipeTo(writeStream)
    } catch (error) {
      console.error(error)
      notification.close(notificationSuccessKey)
      notification.error({ message: 'Unable to download invoices.', placement: 'bottomLeft' })
    }
  }

  return (
    <Button onClick={handleDownload}>
      <DownloadOutlined />
    </Button>
  )
})
