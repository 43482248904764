/*
  This hook provides a state of async methods, like https://react-query.tanstack.com/.
*/

import { useEffect, useReducer } from 'react'

type Action<TData, TError> =
  | { type: 'STOP' }
  | { type: 'LOADING' }
  | { type: 'SUCCESS'; data: TData }
  | { type: 'ERROR'; error: TError }

interface State<TData, TError> {
  isLoading: boolean
  isError: boolean
  isSuccess: boolean
  data: TData
  error: TError
}

const initialState = { isLoading: true, isError: false, isSuccess: false, data: null, error: null }

const reducer = <TData, TError>(state: State<TData, TError>, action: Action<TData, TError>): State<TData, TError> => {
  switch (action.type) {
    case 'STOP':
      return initialState
    case 'LOADING':
      return { ...state, isLoading: true }
    case 'ERROR':
      return { ...state, isLoading: false, data: null, isSuccess: false, isError: true, error: action.error }
    case 'SUCCESS':
      return { ...state, isLoading: false, data: action.data, isSuccess: true, isError: false, error: null }
    default:
      throw new Error()
  }
}

export const useQuery = <TData = unknown, TError = unknown>(fn, deps = [], enabled = true) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  const fetchData = async (cancelRequest?: boolean) => {
    dispatch({ type: 'LOADING' })

    try {
      const data = await fn()

      if (cancelRequest) return

      dispatch({ type: 'SUCCESS', data })
    } catch (error) {
      if (cancelRequest) return

      dispatch({ type: 'ERROR', error })
    }
  }

  useEffect(() => {
    let cancelRequest = false

    if (!fn || !enabled) return

    fetchData(cancelRequest)

    return () => {
      cancelRequest = true
      dispatch({ type: 'STOP' })
    }
  }, [...deps])

  return { ...(state as State<TData, TError>), refetch: fetchData }
}
