/** @jsx jsx */

import * as React from 'react'

import { jsx } from '@emotion/core'

import { Typography } from 'antd'

import { observer } from 'mobx-react-lite'

import { formatDateString } from 'common/helpers/formatters'
import { useTheme } from 'common/hooks/use-theme'
import { DeliveryStates } from 'common/server/server_types'

interface DeliveryInfoProps {
  state: string
  estimated_delivered_at?: string
  carrier: string
  actual_delivered_at?: string
  marked_delivered_at?: string
}

const DeliveryInfo: React.FC<{ delivery: DeliveryInfoProps }> = observer(({ delivery }) => {
  const theme = useTheme()

  const { state, estimated_delivered_at, carrier, actual_delivered_at, marked_delivered_at } = delivery
  const formattedDeliveryDate = estimated_delivered_at && formatDateString(estimated_delivered_at)

  // TODO: ETA is the wrong word here
  let text = 'ETA: Unknown'
  const color = theme.colors['gray-7']

  if (state == DeliveryStates.DELIVERED) {
    // TODO: Include date of delivery, right now we don't always capture this
    const delivered_at = formatDateString(actual_delivered_at || marked_delivered_at)
    text = `Delivered on ${delivered_at}`
  } else if (estimated_delivered_at && carrier) {
    text = `${carrier}, ETA: ${formattedDeliveryDate}`
  } else if (estimated_delivered_at && !carrier) {
    text = `ETA: ${formattedDeliveryDate}`
  } else if (!estimated_delivered_at && carrier) {
    text = `${carrier}, ETA: Unknown`
  }

  return (
    <Typography.Text ellipsis style={{ color }}>
      {text}
    </Typography.Text>
  )
})

export default DeliveryInfo
