import React, { useEffect, useState } from 'react'

import { Typography, Progress, Alert, Divider, Button } from 'antd'

import { observer } from 'mobx-react-lite'

import { Box, FlexBoxX } from 'common/components/boxes'
import { Loading } from 'common/components/Loading'
import { useQuery } from 'common/hooks/use-query'
import { useTheme } from 'common/hooks/use-theme'

import { useStores } from 'contractor/hooks/use-stores'
import { ClientLogs } from 'contractor/pages/Integrations/Settings/client_logs'

export const Authenticated = observer(() => {
  const theme = useTheme()
  const { integrationStore } = useStores()

  const [initialSyncRunning, setInitialSyncRunning] = useState(false)
  const [showLogs, setShowLogs] = useState(false)

  useQuery(() => integrationStore.subscribe(), [integrationStore.accountingIntegration?.id])
  useQuery(() => integrationStore.subscribeClientLog())

  useEffect(() => {
    if (
      integrationStore.accountingIntegration?.syncing_init_data &&
      integrationStore.accountingIntegration?.init_data_progress < 100 &&
      !initialSyncRunning
    ) {
      setInitialSyncRunning(true)
    } else if (
      !integrationStore.syncingProgress.syncing_init_data &&
      integrationStore.syncingProgress.init_data_progress === 100 &&
      initialSyncRunning
    ) {
      setInitialSyncRunning(false)
      integrationStore.accountingInfo() // Refresh the data
    }
  }, [integrationStore.accountingIntegration, integrationStore.syncingProgress])

  return (
    <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column" height="100%" mt={-15}>
      <Box style={{ textAlign: 'center' }}>
        <Typography.Title level={5} style={{ margin: 0 }}>
          Well done! You have successfully connected your {integrationStore.getIntegrationName()} account.
        </Typography.Title>
        {initialSyncRunning && (
          <>
            <Typography.Paragraph style={{ margin: 0 }}>
              SubBase is now syncing your data from {integrationStore.getIntegrationName()}. You can follow the progress
              with the bar bellow.
            </Typography.Paragraph>
            <Box display="flex" flexDirection="row">
              <Box>
                <Divider />
                <Progress
                  percent={integrationStore.syncingProgress?.init_data_progress}
                  status="active"
                  type="circle"
                  style={{ marginTop: 15 }}
                />

                {integrationStore.syncingProgress?.step_description && (
                  <FlexBoxX>
                    <Box>
                      <Typography.Text style={{ marginLeft: 10 }}>
                        {integrationStore.syncingProgress?.step_description}
                      </Typography.Text>
                    </Box>
                    <Box style={{ marginLeft: 15 }}>
                      <Loading size="small" />
                    </Box>
                  </FlexBoxX>
                )}

                <Box display="flex" alignItems="center" justifyContent="center" mt={10}>
                  <Alert
                    message="This process can take a few minutes."
                    description="You may continue to use SubBase as usual while we process the data. You will be notified by email when the process is complete."
                    type="info"
                    style={{ margin: 10, maxWidth: 540 }}
                    showIcon
                  />
                </Box>
                <Button onClick={() => setShowLogs(!showLogs)} size="small">
                  {showLogs ? 'Hide' : 'Show'} integration logs
                </Button>
              </Box>
              {showLogs && (
                <Box>
                  <ClientLogs showUpdateButton={false} lastUpdate={integrationStore.client_log_updated_at} />
                </Box>
              )}
            </Box>
          </>
        )}
        {!initialSyncRunning && (
          <>
            <Typography.Paragraph style={{ margin: 0 }}>
              <span style={{ color: theme.colors['green-8'] }}>
                <b>All data has been synced from {integrationStore.getIntegrationName()}.</b>
              </span>{' '}
              You can now map your data between SubBase and {integrationStore.getIntegrationName()}.
            </Typography.Paragraph>
            <Typography.Paragraph style={{ margin: 0 }}>
              Doing so will allow you to create purchase orders and invoices in {integrationStore.getIntegrationName()}{' '}
              directly from SubBase.
            </Typography.Paragraph>
            <Typography.Paragraph style={{ margin: 0 }}>
              You can disable the sync feature at any time by unchecking it in the integration settings.
            </Typography.Paragraph>
            <Button onClick={() => setShowLogs(!showLogs)} size="small" style={{ marginTop: 3 }}>
              {showLogs ? 'Hide' : 'Show'} integration logs
            </Button>
            {showLogs && <ClientLogs showUpdateButton={true} lastUpdate={integrationStore.client_log_updated_at} />}
          </>
        )}
      </Box>
    </Box>
  )
})
