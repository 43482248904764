import React from 'react'

import { Row } from 'react-table'

import { Tag } from 'antd'

import { FlexBoxX } from 'common/components/boxes'
import { OrderMaterialHit } from 'common/server/order_materials'

interface CellCostCodeProps {
  row: Row
}

const style = { padding: '0 4px' }

export const CellPhaseCode = ({ row }: CellCostCodeProps) => {
  const material = row['original'] as OrderMaterialHit

  return (
    <FlexBoxX alignItems="flex-start" justifyContent="flex-start" height="22px">
      {material?.cost_code_phase && <Tag style={style}>{material?.cost_code_phase?.code}</Tag>}
    </FlexBoxX>
  )
}
