import React from 'react'

import { observer } from 'mobx-react-lite'

import { Box } from 'common/components/boxes'

import { DeliveryButton } from '../delivery_button'
import { InvoiceMaterials as InvoiceMaterialsComponent, getInvoiceMaterialsColumns } from '../InvoiceMaterials'

type InvoiceMaterialsProps = {
  visible: boolean
}

export const InvoiceMaterials = observer<InvoiceMaterialsProps>(({ visible }) => {
  const columns = getInvoiceMaterialsColumns()
    .filter((column) => !['action'].includes(column.dataIndex))
    .map((column) => {
      if (column.dataIndex === 'cost_code' || column.dataIndex === 'cost_code_phase_id') {
        return {
          ...column,
          width: 200,
          editable: () => true,
        }
      }
      return {
        ...column,
        editable: () => false,
      }
    })

  return (
    <Box display={visible ? 'block' : 'none'} width="100%" minWidth={1200}>
      <Box width="100%" display="flex" alignContent="center" justifyContent="space-between">
        <DeliveryButton />
      </Box>
      <InvoiceMaterialsComponent showFooter columns={columns} />
    </Box>
  )
})
