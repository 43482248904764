import React from 'react'

import { Dropdown, message, Popconfirm } from 'antd'

import { observer } from 'mobx-react-lite'

import { formatName } from 'common/helpers/formatters'

import { useStores } from 'contractor/hooks/use-stores'
import { ManagedUser } from 'contractor/server/company_settings/manage_users'

type TableActionsProps = ManagedUser & {
  onReplace: (user: ManagedUser) => void
}

export const TableActions = observer<TableActionsProps>((props) => {
  const { id, first_name, last_name, is_active, onReplace, role } = props

  const { companySettingStore } = useStores()

  const userName = formatName(first_name, last_name)

  const handleDeactivate = async () => {
    const operationMessage = is_active ? 'deactivated' : 're-activated'

    try {
      await companySettingStore.updateUser({
        user_id: id,
        is_active: !is_active,
        role: role,
      })
      message.success(`Successfully to ${operationMessage} ${userName}`)
    } catch (error) {
      message.error(`Unable to ${operationMessage} ${userName}`)
    }
  }

  return (
    <Dropdown.Button
      menu={{
        items: [
          {
            label: <span>Replace user</span>,
            key: '2',
            onClick: () => onReplace(props),
          },
          {
            label: (
              <Popconfirm
                title={`Are you sure you want to deactivate ${userName}?`}
                onConfirm={handleDeactivate}
                okText="Yes"
                cancelText="No"
                placement="topRight"
              >
                <span>{is_active ? 'Deactivate user' : 'Re-activate user'}</span>
              </Popconfirm>
            ),
            key: '1',
          },
        ],
      }}
      trigger={['click', 'hover']}
      buttonsRender={([_, rightButton]) => [null, React.cloneElement(rightButton as React.ReactElement)]}
    />
  )
})
