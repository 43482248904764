/** @jsx jsx */

import * as React from 'react'

import { Row } from 'react-table'

import _ from 'lodash'

import { jsx } from '@emotion/core'

import { Button } from 'antd'

import { observer } from 'mobx-react-lite'

import { OrderStates } from 'common/server/server_types'

import { UpdatesMappings } from 'contractor/components/OrderQuickCommentModal/order_quick_comment_modal'
import { useStores } from 'contractor/hooks/use-stores'

interface ActionCellProps {
  row: Row
}

const ActionCell: React.FC<ActionCellProps> = observer(({ row }) => {
  const { orderStore } = useStores()
  const orderState = _.get(row, 'original.state') as OrderStates

  return (
    <Button
      onClick={(e) => {
        e.stopPropagation()
        orderStore.selectedOrderAction = row.original['id']
      }}
    >
      {_.get(UpdatesMappings, `${orderState}.actionText`)}
    </Button>
  )
})

export default ActionCell
