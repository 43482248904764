import React from 'react'

import { Space, Typography } from 'antd'

import { Box } from 'common/components/boxes'
import { currencyFormatter } from 'common/helpers/formatters'

import { useCommitment } from 'contractor/pages/@v2/Commitments/Components/CommitmentContext/context'

export const Footer = () => {
  const { calculatedBudgetedTotal, calculatedOrdered } = useCommitment()
  const remaining = Number(calculatedBudgetedTotal) - calculatedOrdered
  const remainingColor = remaining < calculatedBudgetedTotal / 10 ? 'red' : 'green'

  return (
    <Box
      bg="white"
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      px={16}
      py={12}
      borderTop="1px solid"
      borderTopColor="gray-4"
      width="100%"
      position="fixed"
      bottom={0}
      left={0}
      style={{ zIndex: 900 }}
    >
      <Space size="large" style={{ justifyContent: 'flex-end', width: '100%' }}>
        <Box display="flex" flexDirection="column" alignItems="center">
          <Typography.Text type="secondary">Budgeted</Typography.Text>
          <Typography.Title level={5} style={{ margin: 0 }}>
            {currencyFormatter(Number(calculatedBudgetedTotal || 0), 2)}
          </Typography.Title>
        </Box>

        <Box display="flex" flexDirection="column" alignItems="center">
          <Typography.Text type="secondary">Ordered</Typography.Text>
          <Typography.Title level={5} style={{ margin: 0 }}>
            {currencyFormatter(Number(calculatedOrdered || 0), 2)}
          </Typography.Title>
        </Box>

        <Box display="flex" flexDirection="column" alignItems="center">
          <Typography.Text type="secondary">Remaining</Typography.Text>
          <Typography.Title level={5} style={{ margin: 0, color: remainingColor }}>
            {currencyFormatter(Number(remaining || 0), 2)}
          </Typography.Title>
        </Box>
      </Space>
    </Box>
  )
}
