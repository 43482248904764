import React from 'react'

import { LockOutlined } from '@ant-design/icons'
import { Comment, Typography, Tooltip } from 'antd'

import { observer } from 'mobx-react-lite'

import { Box } from 'common/components/boxes'
import { Card } from 'common/components/Card'
import { OrderStates } from 'common/server/server_types'

import { Marker, Mention, makeMentionTag, extractMentions } from 'contractor/components/InternalComments'
import { useStores } from 'contractor/hooks/use-stores'

export const InternalComments = observer(() => {
  const { publicOrderStore } = useStores()

  const getUserIdsFromMentions = (text) => {
    const mentions = extractMentions(text)
    const mentionedUsers = publicOrderStore.possibleUsers?.filter((user) => {
      const mention = makeMentionTag(user)
      return mentions.includes(mention)
    })
    return mentionedUsers.map((user) => user.id)
  }

  return (
    <Card
      title={
        <Box display="inline-flex" alignItems="center">
          <Typography.Text strong style={{ marginRight: 12 }}>
            Internal Comments
          </Typography.Text>
          <Tooltip title="All comments on this page are internal and never visible to any of your vendors.">
            <LockOutlined />
          </Tooltip>
        </Box>
      }
      mt={16}
    >
      <Comment
        avatar={<Marker orderState={OrderStates.REQUESTED} />}
        content={
          <Mention
            onChange={(text) => publicOrderStore.updateInternalComment({ text, userIds: getUserIdsFromMentions(text) })}
            value={publicOrderStore.newInternalComment.text}
            users={publicOrderStore.possibleUsers}
          />
        }
      />
    </Card>
  )
})
