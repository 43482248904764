/** @jsx jsx */

import * as React from 'react'

import { jsx } from '@emotion/core'

import { observer } from 'mobx-react-lite'

import { currencyFormatter } from 'common/helpers/formatters'

interface UnitCostCellProps {
  value: number
  maximumFractionDigits?: number
}

const UnitCostCell: React.FC<UnitCostCellProps> = observer(({ value, maximumFractionDigits }) => {
  let cost = ''
  if (value) {
    cost = currencyFormatter(value, maximumFractionDigits)
  }
  return <span>{cost == '$ 0.00' ? null : cost}</span>
})

export default UnitCostCell
