import React, { useState } from 'react'

import { useHistory } from 'react-router-dom'

import { QuestionCircleOutlined } from '@ant-design/icons'
import { Button, message, Tooltip, Steps, Popover, Divider } from 'antd'

import { observer } from 'mobx-react-lite'

import { Box } from 'common/components/boxes'

import { useStores } from 'contractor/hooks/use-stores'

import { useInvoice } from '../context'
import { Totals } from './totals'

type FooterProps = {
  activeStep: number
}

export const Footer = observer<FooterProps>(({ activeStep }) => {
  const { invoiceStore } = useStores()

  const { calculatedSubtotal, calculatedGrandTotal } = useInvoice()

  const { invoice } = invoiceStore

  const history = useHistory()

  const [markingStep, setMarkingStep] = useState(false)

  const handleMarkAsExtracted = async () => {
    try {
      setMarkingStep(true)
      await invoiceStore.markAsExtracted(invoice.id)
    } catch (error) {
      if (error?.response?.data?.message) {
        message.error(error?.response?.data?.message)
      } else {
        message.error('Unable to mark as extracted')
      }
    } finally {
      setMarkingStep(false)
    }
  }

  const handleMarkAsReconciled = async () => {
    try {
      setMarkingStep(true)
      await invoiceStore.markAsReconciled(invoice.id)
    } catch (error) {
      if (error?.response?.data?.message) {
        message.error(error?.response?.data?.message)
      } else {
        message.error('Unable to mark as extracted')
      }
    } finally {
      setMarkingStep(false)
    }
  }

  const verifyIfExtractionUncompleted = React.useCallback(() => {
    const decimalPrecision = invoice?.decimal_precision
    const extractedSubtotal = Number(invoice?.subtotal_amount).toFixed(decimalPrecision)
    const extractedGrandTotal = Number(invoice?.total_amount).toFixed(decimalPrecision)

    return (
      (calculatedGrandTotal.toFixed(decimalPrecision) !== extractedGrandTotal ||
        calculatedSubtotal.toFixed(decimalPrecision) !== extractedSubtotal) &&
      !invoice?.marked_extracted_at
    )
  }, [
    invoice?.marked_extracted_at,
    calculatedGrandTotal,
    calculatedSubtotal,
    invoice?.subtotal_amount,
    invoice?.total_amount,
    invoice?.decimal_precision,
  ])

  const verifyIfReconciliationUncompleted = React.useCallback(() => {
    return (
      invoice.invoice_materials.some((invoiceMaterial) => !invoiceMaterial.order_materials?.length) &&
      !invoice?.marked_reconciled_at
    )
  }, [invoice?.marked_reconciled_at, invoice?.invoice_materials])

  const showMarkAsExtracted = activeStep === 0 && !invoice.marked_extracted_at && verifyIfExtractionUncompleted()

  const showMarkAsReconciled = activeStep === 1 && !invoice.marked_reconciled_at && verifyIfReconciliationUncompleted()

  return (
    <Box
      bg="white"
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      px={16}
      py={12}
      borderTop="1px solid"
      borderTopColor="gray-4"
      width="100%"
    >
      <Box display="flex" alignItems="center">
        <Steps
          data-cy="steps"
          type="navigation"
          responsive={false}
          style={{ width: 500 }}
          current={activeStep}
          onChange={(step) => history.push({ search: new URLSearchParams({ step: step.toString() }).toString() })}
          items={[
            {
              title: (
                <Popover
                  arrowPointAtCenter
                  placement="topLeft"
                  content={
                    <Box>
                      Extraction is automatically marked as complete <br />
                      when there is at least 1 invoice line item and all values <br />
                      add up to a non-zero Grand Total.
                    </Box>
                  }
                  title="Extract"
                >
                  <Box position="relative" mr={12}>
                    <span>Extracted</span>
                    <QuestionCircleOutlined style={{ fontSize: 12, position: 'absolute' }} />
                  </Box>
                </Popover>
              ),
              status: verifyIfExtractionUncompleted() ? 'error' : 'finish',
            },
            {
              title: (
                <Popover
                  arrowPointAtCenter
                  placement="topLeft"
                  content={
                    <Box>
                      Reconciliation is complete when all items from <br />
                      the invoice are matched to an item from an order.
                    </Box>
                  }
                  title="Reconcile"
                >
                  <Box position="relative" mr={12}>
                    <span>Reconcile</span>
                    <QuestionCircleOutlined style={{ fontSize: 12, position: 'absolute' }} />
                  </Box>
                </Popover>
              ),
              status: verifyIfReconciliationUncompleted() ? 'error' : 'finish',
            },
            {
              title: 'Review',
              status: 'finish',
            },
          ]}
        />

        {showMarkAsExtracted && (
          <Box display="flex" alignItems="center">
            <Divider type="vertical" style={{ height: 55, margin: '0px 20px 0 0' }} />
            <Tooltip title="Manually override and mark this invoice as having been extracted properly">
              <Button type="primary" ghost onClick={handleMarkAsExtracted} loading={markingStep}>
                Mark as Extracted
              </Button>
            </Tooltip>
          </Box>
        )}
        {showMarkAsReconciled && (
          <Box display="flex" alignItems="center">
            <Divider type="vertical" style={{ height: 55, margin: '0px 20px 0 0' }} />
            <Tooltip title="Manually override and mark this invoice as having been reconciled properly">
              <Button type="primary" ghost onClick={handleMarkAsReconciled} loading={markingStep}>
                Mark as Reconciled
              </Button>
            </Tooltip>
          </Box>
        )}
      </Box>

      <Totals activeStep={activeStep} />
    </Box>
  )
})
