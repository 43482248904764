import React, { useState, useRef, useEffect, useCallback } from 'react'

import { Configure, InstantSearch, SearchBox } from 'react-instantsearch-dom'
import { useHistory, useLocation } from 'react-router-dom'

import { Typography, message } from 'antd'

import { observer } from 'mobx-react-lite'

import { FlexBoxX, Box, FlexBoxY } from 'common/components/boxes'
import { DrawerRef } from 'common/components/Drawer'
import { Loading } from 'common/components/Loading'
import { Page } from 'common/components/Page'
import { useAlgoliaSyncUrl } from 'common/hooks/use-algolia-sync-url'
import { useQuery } from 'common/hooks/use-query'

import { useStores } from 'contractor/hooks/use-stores'
import { ShowCompanyMaterialResponse } from 'contractor/server/company_materials'

import { Actions } from './Actions'
import { CreateMaterial } from './create_material'
import { Filters } from './Filters'
import { CompanyMaterialTable } from './List'
import { UpdateMaterial } from './UpdateMaterial'

export const CompanyMaterials = observer(() => {
  const { companyMaterialStore, companySettingStore, userStore, unitsStore, costCodeStore, companyVendorStore } =
    useStores()
  const { company_attributes } = companySettingStore.companyMaterialConfiguration

  const costCodeSettings = companySettingStore.otherSettings?.cost_code_settings

  const [duplicateMaterial, setDuplicateMaterial] = useState(null)
  const [tableColumns, setTableColumns] = useState([])

  const algoliaSyncUrl = useAlgoliaSyncUrl(companyMaterialStore)

  const drawerUpdateRef = useRef<DrawerRef>()
  const drawerCreateRef = useRef<DrawerRef>()

  const { costCodePhaseListStore, costCodeClassListStore, costCodeNumberListStore } = costCodeStore

  const filters = 'exclude_from_search:false'

  const location = useLocation()
  const history = useHistory()

  const { isLoading } = useQuery(() => {
    return Promise.all([companyMaterialStore.loadSearchKey(), companySettingStore.indexCompanyMaterialConfiguration()])
  })

  useQuery(() => {
    return Promise.all([
      costCodeStore.fetchAllActiveCostCodes(),
      companyVendorStore.maybeGetAllCompanyVendors(),
      costCodeClassListStore.fetchAllRecords(),
      costCodePhaseListStore.fetchAllRecords(),
      costCodeNumberListStore.fetchAllRecords(),
      companyMaterialStore.getCompanyMaterialTags(),
      unitsStore.maybeUnits(),
    ])
  })

  useEffect(() => {
    if (window.location.search == '') {
      history.push({
        search: new URLSearchParams({ 'toggle[active]': 'true' }).toString(),
      })
    }
  }, [window.location.search])

  const goBackWithRefreshTable = () => {
    const url = location.search
      ? `/company_materials${location.search}&refresh=true`
      : '/company_materials?refresh=true'
    history.push(url)
  }

  const handleDuplicateMaterial = (material: ShowCompanyMaterialResponse) => {
    const duplicateMaterial = { tags: material?.tags }
    company_attributes.forEach((columnName) => {
      duplicateMaterial[columnName] = material[columnName]
      switch (columnName) {
        case 'cost_code_id': {
          duplicateMaterial['cost_code'] = material?.cost_code
          break
        }
        case 'preferred_vendor_prices': {
          duplicateMaterial['company_material_vendor_prices_attributes'] =
            material?.company_material_vendor_prices?.map((companyMaterialVendorPrice) => ({
              price: companyMaterialVendorPrice.price,
              company_vendor_id: companyMaterialVendorPrice.company_vendor?.id,
            }))
          break
        }
        case 'unit': {
          duplicateMaterial['unit_id'] = material?.unit_id
          duplicateMaterial['unit_name'] = material?.unit_name
          break
        }
        default: {
          duplicateMaterial[columnName] = material[columnName]
          break
        }
      }
    })
    setDuplicateMaterial(duplicateMaterial)
    companyMaterialStore.selectMaterial(null)
    drawerUpdateRef.current.close()
    drawerCreateRef.current.show()
  }

  const maybeSetTableColumns = useCallback((columns = []) => {
    setTableColumns(columns)
  }, [])

  const handleClickRow = async (companyMaterialId) => {
    if (!userStore.canEditMaterialDatabase) return

    try {
      await companyMaterialStore.selectMaterial(companyMaterialId)
      drawerUpdateRef.current.show()
    } catch {
      message.error('Failed to load material')
    }
  }

  if (isLoading) {
    return <Loading />
  }

  if (!companyMaterialStore.searchKey.application_id) {
    return
  }

  return (
    <InstantSearch
      searchClient={companyMaterialStore.searchClient}
      indexName={companyMaterialStore.searchKey.index_name}
      refresh={companyMaterialStore.refreshHits}
      {...algoliaSyncUrl}
    >
      <Configure hitsPerPage={50} filters={filters} />
      <Page.Header>
        <FlexBoxY>
          <FlexBoxX flexGrow={0} width="100%" justifyContent="space-between">
            <Typography.Title style={{ marginBottom: 0 }} level={3}>
              Material Database
            </Typography.Title>

            <FlexBoxX flexGrow={0}>
              <Actions goBackWithRefreshTable={goBackWithRefreshTable} onAdd={() => drawerCreateRef.current.show()} />
            </FlexBoxX>
          </FlexBoxX>

          <Box width="100%" display="flex" mt={24} alignItems="center">
            <Box mr={16} flexGrow={1}>
              <SearchBox searchAsYouType={false} showLoadingIndicator />
            </Box>

            <Filters tableColumns={tableColumns} />
          </Box>
        </FlexBoxY>
      </Page.Header>

      <Page.Content p={0} px={{ _: 0, md: 16 }} py={16}>
        <CompanyMaterialTable onClickRow={handleClickRow} onChangeColumns={maybeSetTableColumns} />
      </Page.Content>

      <UpdateMaterial
        editable={userStore.canEditMaterialDatabase}
        onCancel={() => drawerUpdateRef.current.close()}
        onSubmit={() => {
          drawerUpdateRef.current.close()
          goBackWithRefreshTable()
        }}
        onDuplicate={handleDuplicateMaterial}
        ref={drawerUpdateRef}
      />

      <CreateMaterial
        onCancel={() => {
          drawerCreateRef.current.close()
          setDuplicateMaterial(null)
        }}
        onSubmit={() => {
          setDuplicateMaterial(null)
          drawerCreateRef.current.close()
          goBackWithRefreshTable()
        }}
        costCodeSettings={costCodeSettings}
        initialValues={duplicateMaterial}
        ref={drawerCreateRef}
        units={unitsStore.units}
      />
    </InstantSearch>
  )
})
