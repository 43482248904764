import moment from 'moment'

export const getDeliveryPickUpTitle = ({ requestedDeliveredAt, isPickUp = false, pickUpCount, deliveryCount }) => {
  if (requestedDeliveredAt) {
    return moment(requestedDeliveredAt).format('MM/DD/YYYY')
  }

  if (isPickUp) {
    return `Pick Up ${pickUpCount}`
  }

  return `Delivery ${deliveryCount}`
}
