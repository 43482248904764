import React from 'react'

import { connectStateResults } from 'react-instantsearch-dom'

import { Empty } from 'antd'

import { Box } from 'common/components/boxes'
import { Loading } from 'common/components/Loading'

type CustomStateResultsProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  searchState: Record<string, any>
  searchResults: Record<string, unknown>
  allSearchResults: Record<string, unknown>
  error: Record<string, unknown>
  searching: boolean
  searchingForFacetValues: boolean
  isSearchStalled: boolean
  children: React.ReactNode
}

const commonBoxProps = {
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
}

const CustomStateResults = ({ children, isSearchStalled, searchResults }: CustomStateResultsProps) => {
  if (searchResults?.nbHits === 0) {
    return (
      <Box {...commonBoxProps}>
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description="Congrats, you are all caught up with your invoice approvals!"
        />
      </Box>
    )
  }

  return (
    <Box position="relative" height="inherit" width="inherit">
      {isSearchStalled && <Loading boxProps={{ position: 'absolute' }} />}
      {children}
    </Box>
  )
}

export const StateResults = connectStateResults(CustomStateResults)
