import React from 'react'

import styled from '@emotion/styled'

import LoadingOutlined from '@ant-design/icons/LoadingOutlined'
import { Drawer } from 'antd'

import { observer } from 'mobx-react-lite'

import { Table } from 'contractor/pages/@v2/Commitments/Components/CommitmentMaterialsDrawer/table'
import { ConsolidatedOrdersMaterials } from 'contractor/server/order_materials'

type CommitmentMaterialsDrawerProps = {
  visible: boolean
  onClose: () => void
  materials: ConsolidatedOrdersMaterials.OrderMaterial[]
  title: string
  isOrder?: boolean
}

const StyledDrawer = styled(Drawer)`
  .ant-drawer-body {
    padding: 0 16px 16px 16px;
  }
`

export const CommitmentMaterialsDrawer = observer<CommitmentMaterialsDrawerProps>(
  (props: CommitmentMaterialsDrawerProps) => {
    const { materials, onClose, visible, title, isOrder = false } = props

    return (
      <StyledDrawer
        style={{ padding: 0, paddingLeft: 16, paddingRight: 16 }}
        title={title}
        placement="bottom"
        closable={true}
        onClose={onClose}
        open={visible}
        width={800}
      >
        {!materials?.length && <LoadingOutlined />}

        {materials?.length > 0 && <Table isOrder={isOrder} dataSource={materials} />}
      </StyledDrawer>
    )
  },
)
