import React from 'react'

import { message } from 'antd'

import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'

import { PsqlTableProvider } from 'common/components/PsqlTable/psql_table_provider'
import { PersistentFiltersProvider } from 'common/contexts/persistent_filters'
import { useQuery } from 'common/hooks/use-query'

import { useStores } from 'contractor/hooks/use-stores'
import { columnsFactory } from 'contractor/pages/@v2/Commitments/CommitmentsList/columns_definition'
import { CommitmentsContent } from 'contractor/pages/@v2/Commitments/CommitmentsList/commitments_content'
import { update } from 'contractor/server/commitments'

function Content() {
  const { userStore, projectStore, commitmentStore, notificationStore } = useStores()

  const { listStore } = commitmentStore

  async function handleChangeTags(tags: Array<string>, id: string) {
    try {
      // @ts-ignore
      await update({ id: id, tags: tags, silent_update: true })
      listStore.fetchRecords()
      message.success('Updated Tags')
    } catch {
      message.error('Failed to update tags')
    }
  }

  useQuery(() => commitmentStore.userSubscribe(userStore.currentUser?.id))

  return (
    <PersistentFiltersProvider
      ignoreOnFilterCounter={['state', 'project_id', 'view', 'tab']}
      ignoreQueryParams={['refresh']}
      listStore={listStore}
    >
      <PsqlTableProvider
        options={{
          data: toJS(listStore.records),
          sort: {
            sortFromPersistentFilter: true,
            field: listStore.searchState.sort,
            direction: listStore.searchState.sort_direction,
          },
          columns: columnsFactory({
            disableProjectName: !!projectStore.selectedProject,
            getNotificationsCount: (id) => notificationStore.notificationCountByObject(id),
            tagsColumn: {
              disabled: !userStore.canManageOrders,
              onChange: handleChangeTags,
            },
          }),
        }}
        tableName="AllCommitments"
      >
        <CommitmentsContent />
      </PsqlTableProvider>
    </PersistentFiltersProvider>
  )
}

export const CommitmentsPage = observer(Content)
