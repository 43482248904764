import { useEffect, useRef } from 'react'

export const useInterval = (callback: () => void, delay = 10000) => {
  const savedCallback = useRef(callback)

  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  useEffect(() => {
    const id = setInterval(() => savedCallback.current(), delay)

    return () => clearInterval(id)
  }, [delay, callback])
}
