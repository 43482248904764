import { BindAll } from 'lodash-decorators'

import { observable } from 'mobx'

import { ShowDeliveryResponse, update, show, OrderDelivery } from 'common/server/deliveries'

import { index as index_shipping_carriers, ShippingCarrier } from 'contractor/server/shipping_carriers'

@BindAll()
export default class DeliveryStore {
  shippingCarriers = observable.array<ShippingCarrier>([])

  @observable selectedDelivery: ShowDeliveryResponse | null = null

  async selectDelivery(id: string | null): Promise<void> {
    if (!id) {
      this.selectedDelivery = null
      return
    }
    this.selectedDelivery = (await show(id)).data
  }

  async updateDelivery(delivery: OrderDelivery): Promise<OrderDelivery> {
    return (await update(delivery)).data['delivery']
  }

  async maybeGetShippingCarriers() {
    // Doesn't change really often so don't need to reload it unless we have none
    if (this.shippingCarriers.length == 0) {
      this.shippingCarriers.replace((await index_shipping_carriers()).data['shipping_carriers'])
    }
  }
}
