import React from 'react'

import { ColumnInstance } from 'react-table'

import { Typography } from 'antd'

import { observer } from 'mobx-react-lite'

import { Box } from 'common/components/boxes'
import { DrawerTableFilters, SortSelector, ColumnsSelection } from 'common/components/DrawerTableFilters'
import { ClearFiltersButton, DateRangePicker, RefinementListDropdown } from 'common/components/table/filters'
import { Visibility } from 'common/components/Visibility'

import { useStores } from 'contractor/hooks/use-stores'

import { DownloadButton } from './download'
import { DownloadInvoices } from './download_invoices'

type ListFiltersProps = {
  filters: string
  tableColumns: ColumnInstance[]
}

export const ListFilters = observer<ListFiltersProps>(({ filters, tableColumns }) => {
  const { invoiceStore, userStore, invoiceSettingsStore } = useStores()

  const accountingDateEnabled = invoiceSettingsStore?.settings?.accounting_date_enabled

  return (
    <DrawerTableFilters title="Invoice Options">
      <Box mb={16} display="flex" justifyContent="space-between" alignItems="center">
        <Typography.Title level={5} style={{ margin: 0 }}>
          Filters
        </Typography.Title>
        <ClearFiltersButton />
      </Box>

      <Box mb={16} width="100%" display="flex" flexDirection="column">
        <Typography.Text type="secondary">Vendor</Typography.Text>
        <RefinementListDropdown attribute="company_vendor_name" placeholder="Select by vendor" isSearcheable />
      </Box>

      {userStore.canUseIntegrations && (
        <Box mb={16} width="100%" display="flex" flexDirection="column">
          <Typography.Text type="secondary">Sync Status</Typography.Text>
          <RefinementListDropdown attribute="agave_sync_status" placeholder="Select sync status" isSearcheable />
        </Box>
      )}

      <Box mb={16} width="100%" display="flex" flexDirection="column">
        <Typography.Text type="secondary">Assigned to</Typography.Text>
        <RefinementListDropdown
          attribute="assigned_to"
          placeholder="Select by assignment"
          isSearcheable
          sortItems={(items) => {
            return items.sort((a, b) => {
              const notAssinged = 'not assigned'
              const aLabel = a?.label?.toLowerCase()
              const bLabel = b?.label?.toLowerCase()
              if (aLabel === notAssinged && bLabel !== notAssinged) return -1
              if (aLabel !== notAssinged && bLabel === notAssinged) return 1
              return 0
            })
          }}
        />
      </Box>
      <Box mb={16} width="100%" display="flex" flexDirection="column">
        <Typography.Text type="secondary">Tags</Typography.Text>
        <RefinementListDropdown attribute="tags" placeholder="Select tag" isSearcheable />
      </Box>
      <Box width="100%" mb={16}>
        <DateRangePicker attribute="invoice_date_timestamp" title="Invoice Date Range" />
      </Box>

      {accountingDateEnabled && (
        <Box width="100%" mb={24}>
          <DateRangePicker attribute="accounting_date_timestamp" title="Accounting Date Range" />
        </Box>
      )}

      <Visibility.Show breakpoint="md">
        <SortSelector
          sortReplicas={[
            { value: 'due_date', label: 'Due Date' },
            { value: 'document_date', label: 'Document Date' },
            { value: 'assigned_to', label: 'Assigned To' },
          ]}
          defaultRefinement={invoiceStore.searchKey.index_name}
          items={[]}
        />
      </Visibility.Show>

      <Visibility.Hidden breakpoint="md">
        <ColumnsSelection columns={tableColumns} />
      </Visibility.Hidden>

      <Visibility.Hidden breakpoint="md">
        <Box display="flex" alignItems="center" justifyContent="space-between" mt={24}>
          <Typography.Title level={5} style={{ marginRight: '24px', marginBottom: 0 }}>
            Download Invoice Files
          </Typography.Title>
          <DownloadInvoices filters={filters} />
        </Box>

        <Box display="flex" alignItems="center" justifyContent="space-between" mt={24}>
          <Typography.Title level={5} style={{ marginRight: '24px', marginBottom: 0 }}>
            Download CSV
          </Typography.Title>
          <DownloadButton filters={filters} />
        </Box>
      </Visibility.Hidden>
    </DrawerTableFilters>
  )
})
