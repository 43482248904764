import React from 'react'

import { Button, Typography } from 'antd'

import { observer } from 'mobx-react-lite'

import { useTheme } from 'common/hooks/use-theme'
import { UserRoles } from 'common/server/server_types'

import { useStores } from 'contractor/hooks/use-stores'
import { usePublicOrderForm } from 'contractor/pages/PublicOrderForm/context'

const { Title, Text } = Typography

export const UserMessages = observer(() => {
  const theme = useTheme()
  const { publicOrderStore } = useStores()
  const { user, redirectToUserRegistration } = usePublicOrderForm()
  const { formParams } = publicOrderStore

  if (user && user.role === UserRoles.MATERIAL_REQUESTER) {
    return (
      <>
        <Title level={4} style={{ color: theme.colors.primary }}>
          Want to track all of your requested materials?
        </Title>
        <Text style={{ fontWeight: 12 }}>Create your SubBase account</Text>
        <p style={{ paddingTop: 10 }}>
          <Button type="primary" onClick={redirectToUserRegistration}>
            Register
          </Button>
        </p>
      </>
    )
  } else if (user) {
    return (
      <>
        <Title level={4} style={{ color: theme.colors.primary }}>
          Want to see all of your orders?
        </Title>
        <Text style={{ fontWeight: 12 }}>It&apos;s simple, let&apos;s login</Text>
        <p style={{ paddingTop: 10 }}>
          <Button type="primary" onClick={() => (window.location.href = '/')}>
            Login
          </Button>
        </p>
      </>
    )
  }
  return (
    <>
      <Title level={4} style={{ color: theme.colors.primary, paddingTop: 15 }}>
        First time requesting materials?
      </Title>
      <Text
        style={{ fontWeight: 12 }}
      >{`This is so easy, ${formParams.company_name} will receive your request for materials`}</Text>
    </>
  )
})
