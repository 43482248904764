import React, { useState, useCallback } from 'react'

import { Button, Checkbox, Divider, message, Radio, Space, Typography } from 'antd'

import { observer } from 'mobx-react-lite'

import { Box } from 'common/components/boxes'
import { Drawer, DrawerRef } from 'common/components/Drawer'
import { SelectIntegrationTaxBehavior } from 'common/components/SelectIntegrationTaxBehavior'

import throttle from 'contractor/helpers/throttle'
import { useStores } from 'contractor/hooks/use-stores'

type SettingsDrawerProps = {
  onClose: () => void
}

export const SettingsDrawer = observer<SettingsDrawerProps, DrawerRef>(
  ({ onClose }, ref) => {
    const { integrationStore, userStore } = useStores()

    const [isSubmitting, setSubmitting] = useState(false)

    const accountingIntegration = integrationStore.accountingIntegration

    const handleUpdateConfigs = async (fn) => {
      try {
        setSubmitting(true)
        await fn
        message.success('Successfully saved changes')
      } catch (error) {
        if (error?.response?.data?.message) {
          message.error(error?.response?.data?.message)
        } else {
          message.error('Unable to save changes')
        }
      } finally {
        setSubmitting(false)
      }
    }

    const reSyncBudgetCodes = async () => {
      try {
        await integrationStore.resyncProcoreWbs()
        message.success('Budget Codes resync has started successfully.')
      } catch (e) {
        message.error(e?.response?.data?.message || 'Unable to resync materials')
      }
    }

    const autoCreateAndMapPhaseCodes = async () => {
      try {
        await integrationStore.autoCreateAndMapPhaseCodes()
        message.success('Auto create phase codes and integrations mappings has started successfully.')
      } catch (e) {
        message.error(e?.response?.data?.message || 'Unable to start auto create and mapping phase codes')
      }
    }

    // the worker to auto create and auto map cost codes takes about 20 seconds to perform
    const throttledAutoCreateAndMapPhaseCodes = useCallback(throttle(autoCreateAndMapPhaseCodes, 20000), [])

    const handleUpdateOrderConfig = (purchaseOrderSync) => {
      handleUpdateConfigs(
        integrationStore.updateAccountingConfig({ purchase_order_sync: purchaseOrderSync ? 'enabled' : 'disabled' }),
      )
    }

    const handleUpdateInvoiceConfig = (invoiceSync) => {
      handleUpdateConfigs(
        integrationStore.updateAccountingConfig({ invoice_sync: invoiceSync ? 'enabled' : 'disabled' }),
      )
    }

    const handleUpdateCostCodeAsMaterial = (asItems) => {
      handleUpdateConfigs(
        integrationStore.updateAccountingConfig({ map_cost_codes_as_items: asItems ? 'enabled' : 'disabled' }),
      )
    }

    const handleUpdateOrderTaxBehavior = (orderTaxBehavior) => {
      handleUpdateConfigs(integrationStore.updateAccountingConfig({ order_tax_behavior: orderTaxBehavior }))
    }

    const handleUpdateInvoiceTaxBehavior = (invoiceTaxBehavior) => {
      handleUpdateConfigs(integrationStore.updateAccountingConfig({ invoice_tax_behavior: invoiceTaxBehavior }))
    }

    const handleUpdateInvoiceSyncType = (type) => {
      handleUpdateConfigs(integrationStore.updateAccountingConfig({ invoice_sync_type: type }))
    }

    return (
      <Drawer bgGray title="Integration Settings" ref={ref} width={378} onClose={onClose}>
        <Box display="flex" flexDirection="column" p={16} width="100%" height="100%" overflowY="auto">
          <Box display="flex" flexDirection="row">
            <Typography.Paragraph strong>Sync Settings</Typography.Paragraph>
          </Box>
          <Box display="flex" flexDirection="row">
            <Checkbox
              onChange={(e) => {
                handleUpdateOrderConfig(e.target.checked)
              }}
              disabled={isSubmitting || !userStore.canSyncWithErp}
              checked={accountingIntegration?.purchase_order_sync === 'enabled'}
            >
              Sync Purchase Orders
            </Checkbox>
          </Box>
          <Box display="flex" flexDirection="row">
            <Checkbox
              disabled={isSubmitting || !userStore.canSyncWithErp}
              onChange={(e) => {
                handleUpdateInvoiceConfig(e.target.checked)
              }}
              checked={accountingIntegration?.invoice_sync === 'enabled'}
            >
              Sync Invoices
            </Checkbox>
          </Box>
          {integrationStore.isProcore() && (
            <>
              <Divider />
              <Box display="flex" flexDirection="row">
                <Typography.Paragraph strong>PO Sync Status</Typography.Paragraph>
              </Box>
              <Box display="flex" flexDirection="row">
                <Radio.Group
                  onChange={(e) => {
                    handleUpdateConfigs(integrationStore.updateAccountingConfig({ po_sync_status: e.target.value }))
                  }}
                  value={accountingIntegration?.po_sync_status}
                >
                  <Space direction="vertical">
                    <Radio value="Draft" disabled={isSubmitting || !userStore.canSyncWithErp}>
                      Draft
                    </Radio>
                    <Radio value="Approved" disabled={isSubmitting || !userStore.canSyncWithErp}>
                      Approved
                    </Radio>
                  </Space>
                </Radio.Group>
              </Box>
            </>
          )}
          <Divider />
          <Box display="flex" flexDirection="row">
            <Typography.Paragraph strong>Mapping Settings</Typography.Paragraph>
          </Box>
          <Box display="flex" flexDirection="row">
            <Checkbox disabled checked={integrationStore.enabledProjectsMapping()}>
              Projects
            </Checkbox>
          </Box>
          <Box display="flex" flexDirection="row">
            <Checkbox disabled checked={integrationStore.enabledVendorsMapping()}>
              Vendors
            </Checkbox>
          </Box>
          <Box display="flex" flexDirection="row">
            <Checkbox disabled checked={integrationStore.enabledMaterialsMapping()}>
              Materials
            </Checkbox>
          </Box>
          <Box display="flex" flexDirection="row">
            <Checkbox disabled checked={integrationStore.enabledCostPhaseMapping()}>
              Cost Phases
            </Checkbox>
          </Box>
          <Box display="flex" flexDirection="row">
            <Checkbox disabled checked={integrationStore.enabledCostCodesMapping()}>
              Cost Codes
            </Checkbox>
          </Box>
          <Box display="flex" flexDirection="row">
            <Checkbox disabled checked={integrationStore.enabledCostClassesMapping()}>
              Cost Classes
            </Checkbox>
          </Box>
          {integrationStore.isQBD() && (
            <>
              <Divider />
              <Box display="flex" flexDirection="row">
                <Typography.Paragraph strong>Other Settings</Typography.Paragraph>
              </Box>
              <Box display="flex" flexDirection="row">
                <Checkbox
                  onChange={(e) => {
                    handleUpdateCostCodeAsMaterial(e.target.checked)
                  }}
                  disabled={isSubmitting || !userStore.canSyncWithErp}
                  checked={accountingIntegration?.map_cost_codes_as_items}
                >
                  Map cost codes as materials
                </Checkbox>
              </Box>
              <Box display="flex" flexDirection="row" mt={10}>
                <Radio.Group
                  onChange={(e) => {
                    handleUpdateInvoiceSyncType(e.target.value)
                  }}
                  value={accountingIntegration?.invoice_sync_type}
                >
                  <Space direction="vertical">
                    <Radio value="expense" disabled={isSubmitting || !userStore.canSyncWithErp}>
                      Invoice Sync Type Expense
                    </Radio>
                    <Radio value="line_item" disabled={isSubmitting || !userStore.canSyncWithErp}>
                      Invoice Sync Type Line Item
                    </Radio>
                  </Space>
                </Radio.Group>
              </Box>
            </>
          )}
          {integrationStore.isProcore() && (
            <>
              <Divider />
              <Box display="flex" flexDirection="row">
                <Typography.Paragraph strong>Multi mapping entities</Typography.Paragraph>
              </Box>
              <Box display="flex" width="100%">
                <ul>
                  {accountingIntegration?.multi_mapping_entities?.map((entity) => (
                    // Convert entity string from snake_case to Title Case
                    <li key={entity}>
                      {entity.replace(/_/g, ' ').replace(/\w\S*/g, (w) => w.replace(/^\w/, (c) => c.toUpperCase()))}
                    </li>
                  ))}
                </ul>
              </Box>
              <Divider />
              <Box display="flex" flexDirection="row">
                <Typography.Paragraph strong>Budget Codes</Typography.Paragraph>
              </Box>
              <Box display="flex" width="100%">
                <Button type="primary" onClick={reSyncBudgetCodes}>
                  Resync Budget Codes
                </Button>
              </Box>
            </>
          )}

          {integrationStore.isAcumatica() && (
            <>
              <Divider />
              <Box display="flex" width="100%">
                <Button type="primary" onClick={throttledAutoCreateAndMapPhaseCodes}>
                  Create and map phase codes
                </Button>
              </Box>
            </>
          )}

          <Divider />
          <Box display="flex" flexDirection="row">
            <Typography.Paragraph strong>Order tax split mode</Typography.Paragraph>
          </Box>
          <SelectIntegrationTaxBehavior
            handleChange={handleUpdateOrderTaxBehavior}
            defaultValue={accountingIntegration?.order_tax_behavior}
          />

          <Box display="flex" flexDirection="row" style={{ marginTop: '20px' }}>
            <Typography.Paragraph strong>Invoice tax split mode</Typography.Paragraph>
          </Box>
          <SelectIntegrationTaxBehavior
            handleChange={handleUpdateInvoiceTaxBehavior}
            defaultValue={accountingIntegration?.invoice_tax_behavior}
          />
        </Box>
      </Drawer>
    )
  },
  { forwardRef: true },
)
