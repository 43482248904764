import React from 'react'

import { useDrag } from 'react-dnd'

type DraggableBodyRowProps<Item> = React.HTMLAttributes<HTMLTableRowElement> & {
  index: number
  item: Item
  disabled?: boolean
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const DraggableBodyRow = <Item extends Record<string, any>>({
  index,
  item: itemProp,
  style,
  disabled,
  ...restProps
}: DraggableBodyRowProps<Item>) => {
  const [, drag] = useDrag(
    () => ({
      type: 'DraggableBodyRow',
      item: { index, ...itemProp },
      canDrag: !!itemProp?.company_material?.id && !disabled,
    }),
    [itemProp, index],
  )

  return (
    <tr ref={drag} style={{ cursor: itemProp?.company_material?.id ? 'move' : 'default', ...style }} {...restProps} />
  )
}
