import React, { useRef, useState } from 'react'

import { Dropdown, Popconfirm, Tooltip, Typography } from 'antd'

import { observer } from 'mobx-react-lite'

import { DrawerRef } from 'common/components/Drawer'
import { FloatActionsWrapper } from 'common/components/FloatActionsWrapper'
import { useTheme } from 'common/hooks/use-theme'

import { WatchersDrawer } from 'contractor/components/WatchersDrawer'
import { makeWatcherOption } from 'contractor/components/WatchersSelect'
import { useStores } from 'contractor/hooks/use-stores'

import { useInvoice } from '../context'
import { insertIf } from '../invoice_detail_extract'
import { InvoiceHistory } from '../invoice_history'
import { HeaderProps } from './header'
import { UpdateButton } from './update_button'

type FloatActionsProps = Pick<
  HeaderProps,
  | 'isCorrelating'
  | 'onDeleteInvoice'
  | 'onClickReportIssue'
  | 'disableUpdate'
  | 'isSubmitting'
  | 'saveAndNextDefault'
  | 'onSaveInvoice'
  | 'onSaveInvoiceAndNext'
>

export const FloatActions = observer<FloatActionsProps>((props) => {
  const {
    isCorrelating,
    onDeleteInvoice,
    onClickReportIssue,
    disableUpdate,
    isSubmitting,
    saveAndNextDefault,
    onSaveInvoice,
    onSaveInvoiceAndNext,
  } = props

  const theme = useTheme()

  const { invoiceStore, userStore, companySettingStore } = useStores()

  const { setSelectedInvoiceDirty } = useInvoice()

  const historyDrawerRef = useRef<DrawerRef>()

  const [isHistoryDrawerVisible, setIsHistoryDrawerVisible] = useState(false)

  const { invoice } = invoiceStore

  const handleHistoryClick = () => {
    setIsHistoryDrawerVisible(true)
    historyDrawerRef.current?.show()
  }

  return (
    <FloatActionsWrapper>
      <InvoiceHistory
        isVisible={isHistoryDrawerVisible}
        ref={historyDrawerRef}
        onClose={() => historyDrawerRef.current?.close()}
      />

      <Tooltip title={disableUpdate ? 'To approve the invoice you must attach at least one order' : ''}>
        <UpdateButton
          isCorrelating={isCorrelating}
          disableUpdate={disableUpdate}
          onSaveInvoice={onSaveInvoice}
          onSaveInvoiceAndNext={onSaveInvoiceAndNext}
          isSubmitting={isSubmitting}
          saveAndNextDefault={saveAndNextDefault}
        />
      </Tooltip>

      {!isCorrelating && userStore.canEditAndDeleteInvoices && (
        <Dropdown.Button
          menu={{
            items: [
              {
                label: (
                  <WatchersDrawer
                    boxProps={{
                      width: 'auto',
                      p: 0,
                      height: 'auto',
                      border: 'none',
                      display: 'block',
                    }}
                    value={companySettingStore.possibleUsers
                      .filter((user) => invoice?.watcher_ids?.includes(user.id))
                      .map((user) => makeWatcherOption(user))}
                    onChange={(watchers = []) => {
                      invoice.watcher_ids = watchers?.map((watcher) => watcher.value)
                      setSelectedInvoiceDirty(true)
                    }}
                  >
                    Watchers
                  </WatchersDrawer>
                ),
                key: '1',
              },
              {
                label: 'History',
                key: '2',
                onClick: () => handleHistoryClick,
              },
              {
                label: 'Report Issue',
                key: '3',
                onClick: onClickReportIssue,
              },
              ...insertIf(userStore.canEditAndDeleteInvoices, {
                label: (
                  <Popconfirm
                    title="Are you sure? This action can't be undone."
                    onConfirm={onDeleteInvoice}
                    okText="Yes"
                    placement="bottomRight"
                    cancelText="No"
                  >
                    <Typography style={{ color: theme.colors['red-6'] }}>Delete Invoice</Typography>
                  </Popconfirm>
                ),
                key: '4',
              }),
            ],
          }}
          trigger={['hover', 'click']}
          buttonsRender={([_, rightButton]) => [null, React.cloneElement(rightButton as React.ReactElement)]}
          style={{ width: 'auto' }}
        />
      )}
    </FloatActionsWrapper>
  )
})
