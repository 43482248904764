import React, { useRef, useState } from 'react'

import styled from '@emotion/styled'

import { SearchOutlined, QuestionCircleOutlined } from '@ant-design/icons'
import { Divider, Typography, List, Button, message, Tabs, Tooltip, Spin } from 'antd'

import { observer } from 'mobx-react-lite'

import { FlexBoxY, Box } from 'common/components/boxes'
import { Drawer, DrawerRef } from 'common/components/Drawer'
import { SearchInput } from 'common/components/SearchInput'
import { useQuery } from 'common/hooks/use-query'

import { filterTextByFields } from 'contractor/helpers/filter-text-by-fields'
import { useStores } from 'contractor/hooks/use-stores'
import { CreateCompanyVendorContact } from 'contractor/pages/CompanyVendorContacts/MyVendors/Create'
import { CompanyVendorContact } from 'contractor/server/company_vendor_contacts'
import { VendorUser } from 'contractor/server/vendors'

import { MyContacts } from './my_contacts'
import { VendorCard } from './vendor_card'
import { VendorUserCard } from './vendor_user_card'

export type VendorProfileProps = {
  vendorId: string
  companyVendorId?: string
  hideCreate?: boolean
  onSubmitAndAdd?: (newCompanyVendorContact: CompanyVendorContact) => void
  orderType?: OrderType
}

const Wrapper = styled(FlexBoxY)`
  & .ant-tabs {
    width: 100%;
    height: 100%;
  }
  & .ant-tabs-content {
    height: 100%;
  }
  & .ant-tabs-nav {
    padding: 10px 24px 0 24px;
  }
  & .ant-tabs-tabpane {
    display: flex;
    flex-direction: column;
  }

  & .ant-list-loading .ant-spin-nested-loading {
    height: 100%;
  }
  & .ant-spin-spinning {
    max-height: 100% !important;
  }
  & .ant-spin-blur {
    height: 100%;
  }

  & .ant-spin-nested-loading {
    width: 100%;
    height: 100%;

    & .ant-spin-container {
      width: inherit;
      height: inherit;
    }
  }
`

export const VendorProfile = observer<VendorProfileProps, DrawerRef>(
  (props, ref) => {
    const { vendorId, hideCreate, onSubmitAndAdd, companyVendorId: companyVendorIdProp, orderType } = props
    const { vendorStore, companyVendorStore } = useStores()

    const vendor = vendorStore.selectedVendor
    const companyVendorId = companyVendorIdProp || vendorStore.selectedVendor?.company_vendor_id

    const { isLoading } = useQuery(() => {
      if (!vendorId) return
      return vendorStore.getVendorById(vendorId)
    }, [vendorId])

    const drawerCreateRef = useRef<DrawerRef>()
    const [isSubmitting, setSubmitting] = useState(false)
    const [searchText, setSearchText] = useState('')

    const [activeKey, setActiveKey] = useState('sales-representatives')

    const handleConnect = async (vendorId: string, vendorUserId: string, isConnectAndAdd = false) => {
      setSubmitting(true)

      try {
        const newVendorContact = await companyVendorStore.connectToVendor({ vendorId, vendorUserId })
        vendorStore.getVendorById(vendorId)
        message.success('Successfully to connect')

        if (isConnectAndAdd && !!onSubmitAndAdd) {
          onSubmitAndAdd(newVendorContact)
        }
      } catch (err) {
        message.error(err?.response?.data?.error || 'Unable to connect')
      } finally {
        setSubmitting(false)
      }
    }

    const handleCreateCompanyVendorContact = () => {
      setActiveKey('my-contacts')
      vendorStore.getVendorById(vendorId)
    }

    const handleSearchText = (text = '') => setSearchText(text.trim())

    const handeFilterTextVendorUser = (vendorUser: VendorUser) => {
      return filterTextByFields({ fields: ['full_name', 'email', 'headline'], obj: vendorUser, searchText })
    }

    const getCompanyVendorContact = (companyVendorId: string) => {
      setSubmitting(true)

      return companyVendorStore
        .getCompanyVendorContactsByCompanyVendorId(companyVendorId)
        .then((response) => {
          setSubmitting(false)
          return response
        })
        .catch((err) => {
          setSubmitting(false)
          message.error(err?.response?.data?.error || 'Unable to add')
          throw err
        })
    }

    const handleConnectOrAddByVendor = async (vendorId: string, vendorUserId: string, isConnected = false) => {
      if (isConnected) {
        const vendorContacts = await getCompanyVendorContact(companyVendorId)
        onSubmitAndAdd(vendorContacts.find(({ contact }) => contact?.vendor_user?.id === vendorUserId))
      } else {
        handleConnect(vendorId, vendorUserId, true)
      }
    }

    const vendorUsers =
      vendor?.vendor_users.filter((vendorUser) => {
        return vendorUser?.is_public || vendor?.connected_vendor_user_ids.includes(vendorUser.id)
      }) || []

    return (
      <Drawer ref={ref} bgGray destroyOnClose closable={false}>
        <Wrapper justifyContent="flex-start" width="100%" height="100%" overflowY="auto">
          <Spin spinning={isLoading} tip="Loading...">
            <FlexBoxY alignItems="flex-start" width="100%" flexGrow={0} p={{ _: 12, xs: 24 }}>
              <VendorCard
                name={vendor?.name}
                domain={vendor?.domain}
                logoUrl={vendor?.logo?.url}
                description={vendor?.description}
              />
            </FlexBoxY>

            <Tabs
              activeKey={activeKey}
              onChange={setActiveKey}
              destroyInactiveTabPane
              items={[
                {
                  label: (
                    <>
                      <Typography.Text>Sales Representatives</Typography.Text>
                      <Tooltip placement="bottomLeft" title="All contacts for this vendor on the SubBase Network">
                        <QuestionCircleOutlined style={{ marginLeft: 4 }} />
                      </Tooltip>
                    </>
                  ),
                  key: 'sales-representatives',
                  children: (
                    <>
                      <Box mx={{ _: 12, xs: 24 }}>
                        <SearchInput
                          onSearch={handleSearchText}
                          placeholder="Search for sales representatives..."
                          style={{ width: '100%' }}
                        />
                      </Box>

                      <Divider style={{ margin: '16px 0' }} />

                      <FlexBoxY
                        width="100%"
                        justifyContent="flex-start"
                        alignItems="stretch"
                        flexGrow={0}
                        px={{ _: 12, xs: 24 }}
                      >
                        {!!vendorUsers?.length && (
                          <List
                            grid={{
                              gutter: 10,
                              column: 1,
                            }}
                            style={{ width: '100%' }}
                            dataSource={vendorUsers.filter(handeFilterTextVendorUser)}
                            renderItem={(vendorUser) => {
                              const isConnected = vendor?.connected_vendor_user_ids.includes(vendorUser.id)
                              return (
                                <List.Item>
                                  <VendorUserCard
                                    name={vendorUser.full_name}
                                    email={vendorUser.email}
                                    headline={vendorUser?.headline}
                                    onConnect={() => handleConnect(vendor?.id, vendorUser.id)}
                                    onConnectAndAdd={
                                      !!onSubmitAndAdd &&
                                      (() => handleConnectOrAddByVendor(vendor?.id, vendorUser.id, isConnected))
                                    }
                                    isConnected={isConnected}
                                    orderType={orderType}
                                    isLoading={isSubmitting}
                                  />
                                </List.Item>
                              )
                            }}
                          />
                        )}

                        {!hideCreate && (
                          <FlexBoxY flexGrow={0} mb="16px">
                            <SearchOutlined style={{ fontSize: 45 }} />
                            <Typography.Text type="secondary" strong style={{ margin: '12px 0', fontSize: 16 }}>
                              Don&apos;t see who you are looking for?
                            </Typography.Text>
                            <Button type="primary" onClick={() => drawerCreateRef.current.show()}>
                              Add New Vendor Contact
                            </Button>
                          </FlexBoxY>
                        )}
                      </FlexBoxY>
                    </>
                  ),
                },
                {
                  label: (
                    <>
                      <Typography.Text>My Contacts</Typography.Text>
                      <Tooltip placement="bottomLeft" title="Your contacts for this vendor">
                        <QuestionCircleOutlined style={{ marginLeft: 4 }} />
                      </Tooltip>
                    </>
                  ),
                  key: 'my-contacts',
                  children: (
                    <MyContacts companyVendorId={companyVendorId} orderType={orderType} onAdd={onSubmitAndAdd} />
                  ),
                },
              ]}
            />
          </Spin>
        </Wrapper>

        <CreateCompanyVendorContact
          hideNotification
          hideShowSaveAndAddAnotherButton
          selectedCompanyVendorId={companyVendorId}
          selectedVendorId={vendorId}
          onCancel={() => drawerCreateRef.current.close()}
          onFinish={handleCreateCompanyVendorContact}
          ref={drawerCreateRef}
        />
      </Drawer>
    )
  },
  { forwardRef: true },
)
