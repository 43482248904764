import React from 'react'

import { Row } from 'react-table'

import { message, Popover, Tag } from 'antd'

import { observer } from 'mobx-react-lite'

import { FlexBoxX } from 'common/components/boxes'
import Tags from 'common/components/tags'
import { OrderHit } from 'common/server/orders'

import { useStores } from 'contractor/hooks/use-stores'
import { update } from 'contractor/server/orders'

interface TagCellProps {
  row: Row
  value: string[]
}

// HACK: The way we are doing editing here is super hacky but Baker Concrete requested it
// and want to test the waters with table level editing. Will need to revisit if we want to do it
// More often. Last time I tried this biggest obstacle was actually table rendering table
const TagCell = observer<TagCellProps>(({ row }) => {
  const { orderStore } = useStores()
  const order = row['original'] as OrderHit

  const handleChange = async (tags) => {
    const currentHit = orderStore.hits[row.index]
    if (currentHit?.id === order.id) {
      currentHit['tags'] = tags
    }

    try {
      // @ts-ignore
      await update({ id: row.original.id, tags: tags, silent_update: true })
      message.success('Updated Tags')
    } catch {
      message.error('Failed to update tags')
    }
  }

  const content = (
    <FlexBoxX
      flexGrow={0}
      onClick={(e) => {
        e.stopPropagation()
        e.preventDefault()
      }}
      minWidth="200px"
    >
      <Tags value={order?.tags} onChange={handleChange} tags={orderStore.orderTags} />
    </FlexBoxX>
  )

  return (
    <Popover content={content}>
      <FlexBoxX alignItems="flex-start" justifyContent="flex-start" height="30px">
        {order?.tags?.map((tag, index) => (
          <Tag key={`${tag}-${index}`}>{tag}</Tag>
        ))}
      </FlexBoxX>
    </Popover>
  )
})

export default TagCell
