import _, { isEqual } from 'lodash'
import { BindAll } from 'lodash-decorators'

import { action, observable } from 'mobx'

import { subscribeCompanyChannel, TYPE_CONTRACTOR } from 'common/data-access/company_websocket'
import { Comment } from 'common/server/comment'
import { WebSocketMessage } from 'common/server/server_types'
import ListBaseStore from 'common/stores/ListBaseStore'

import {
  CommitmentMaterial,
  create,
  destroy,
  facets,
  index,
  loadCommitmentMaterials,
  loadInvoices,
  loadOrderMaterials,
  loadOrders,
  newInternalComment,
  search_key,
  show,
  update,
} from 'contractor/server/commitments'
import { Commitment } from 'contractor/server/commitments/commitment'
import { show as showOrder } from 'contractor/server/orders'

class CommitmentListStore extends ListBaseStore<Commitment> {
  index = index
  getFacets = facets
}

@BindAll()
export default class CommitmentStore {
  listStore: CommitmentListStore
  getSearchKey = search_key
  @observable subscriptions = []
  @observable selectedCommitment: Nullable<Commitment> = null
  @observable totalMaterialsCount = 0
  commitmentTags = observable.array<string>([])
  changedModal = null
  isUploadingMaterials = false
  // Internal Comments
  @observable newComment = ''
  @observable internalComments = observable.array<Comment>([])
  @observable newInternalComment = {
    text: '',
    userIds: [],
  }

  constructor() {
    this.listStore = new CommitmentListStore()
  }

  @action
  updateMaterial(material: CommitmentMaterial, changes: CommitmentMaterial) {
    this.selectedCommitment.commitment_materials = this.selectedCommitment?.commitment_materials.map((m) => {
      if (isEqual(m, material)) {
        return changes
      }
      return m
    })
    this.totalMaterialsCount = this.selectedCommitment?.commitment_materials?.length
  }

  @action
  addMaterial(material: CommitmentMaterial) {
    this.selectedCommitment.commitment_materials = [...this.selectedCommitment?.commitment_materials, material]
    this.totalMaterialsCount = this.selectedCommitment?.commitment_materials?.length
  }

  @action
  removeMaterial(material: CommitmentMaterial) {
    this.selectedCommitment.commitment_materials = this.selectedCommitment?.commitment_materials.filter(
      (m) => !isEqual(m, material),
    )
    this.totalMaterialsCount = this.selectedCommitment?.commitment_materials?.length
  }

  @action
  async loadCommitment(id: string) {
    this.selectedCommitment = (await show(id)).data
    this.totalMaterialsCount = this.selectedCommitment?.commitment_materials?.length

    return this.selectedCommitment
  }

  @action
  async updateCommitment(params: Commitment) {
    this.selectedCommitment = (await update(params)).data

    return this.selectedCommitment
  }

  @action
  updateSelectedCommitment<Value = string | number | string[]>(path: string, value: Value) {
    _.set(this.selectedCommitment, path, value)
  }

  @action async loadOrder(orderId: string) {
    return (await showOrder(orderId)).data
  }

  @action
  async deleteSelectedCommitment() {
    await destroy(this.selectedCommitment.id)
  }

  @action
  async createCommitment(params: Commitment) {
    return (await create(params)).data
  }

  @action
  updateInternalComment(params: { text: string; userIds: string[] }) {
    this.newInternalComment = params
  }

  @action
  async sendInternalComment(params: { text: string; userIds: string[] }) {
    const newComment = (await newInternalComment({ ...params, commitment_id: this.selectedCommitment.id })).data
    this.selectedCommitment.internal_comments = [...this.selectedCommitment.internal_comments, newComment]
  }

  // Notifications
  commitmentSubscribe(id, userId) {
    if (this.subscriptions.includes(id)) {
      // Do not subscribe twice to the same entity
      return
    }
    this.subscriptions.push(id)
    return subscribeCompanyChannel(this.handleWebSocketMessage, TYPE_CONTRACTOR, id, userId)
  }

  userSubscribe(userId) {
    if (!userId) return
    if (this.subscriptions.includes(userId)) {
      // Do not subscribe twice to the same entity
      return
    }
    this.subscriptions.push(userId)
    return subscribeCompanyChannel(this.handleWebSocketMessage, TYPE_CONTRACTOR, userId, userId)
  }

  @action
  async handleWebSocketMessage(message: WebSocketMessage, user_id: string) {
    if (message.type === 'materials_submit' && message.entity_id == user_id) {
      this.selectedCommitment.commitment_materials = [
        ...this.selectedCommitment.commitment_materials,
        ...message.data.commitment_materials,
      ]
      this.isUploadingMaterials = false
      return
    }

    if (message.type === 'resume_pdf_available' && message.entity_id == this.selectedCommitment?.id) {
      if (message.data['user_id'] === user_id) {
        await this.loadCommitment(this.selectedCommitment.id)
      }
    }

    if (message.type === 'refresh' && message.entity_id == this.selectedCommitment?.id) {
      if (message.data['user_id'] === user_id) {
        await this.loadCommitment(this.selectedCommitment.id)
      }
    }

    if (message.entity_name === 'Commitment' && message.entity_id === this.selectedCommitment?.id) {
      if (message.type === 'refresh') {
        if (message.data['user_id'] === user_id) {
          await this.loadCommitment(this.selectedCommitment.id)
        } else {
          this.changedModal?.()
        }
      }
    }
  }

  @action
  async loadCommitmentOrders(ordersToImport: string[] = []) {
    return (await loadOrders(this.selectedCommitment?.id, ordersToImport)).data
  }

  @action
  async loadCommitmentMaterials(materialId: string) {
    return (await loadCommitmentMaterials({ id: this.selectedCommitment?.id, material_id: materialId })).data
  }

  @action
  async loadOrderMaterials(orderId: string) {
    return (await loadOrderMaterials({ id: this.selectedCommitment?.id, order_id: orderId })).data
  }

  @action
  async loadCommitmentInvoices() {
    return (await loadInvoices(this.selectedCommitment.id)).data
  }
}
