import React from 'react'

import { observer } from 'mobx-react-lite'

import { Box } from 'common/components/boxes'
import { Drawer, DrawerRef } from 'common/components/Drawer'
import { ErrorBoundary } from 'common/components/ErrorBoundary/error_boundary'
import { useQuery } from 'common/hooks/use-query'

import { InvoiceHistory as InvoiceHistoryComponent } from 'contractor/components/InvoiceHistory/history'
import { useStores } from 'contractor/hooks/use-stores'

type InvoiceHistoryProps = {
  onClose: () => void
  isVisible: boolean
}

export const InvoiceHistory = observer<InvoiceHistoryProps, DrawerRef>(
  ({ onClose, isVisible }, ref) => {
    const { invoiceStore } = useStores()

    const { isLoading } = useQuery(() => {
      if (invoiceStore.invoice?.id && isVisible) {
        invoiceStore.getInvoiceHistory(invoiceStore.invoice?.id)
      }
    }, [invoiceStore.invoice?.id, isVisible])

    return (
      <Drawer
        closable
        onClose={onClose}
        ref={ref}
        width={480}
        title={`Invoice #${invoiceStore.invoice?.number} History`}
      >
        <Box display="flex" flexDirection="column" width="100%">
          <ErrorBoundary isFull={false}>
            <Box display="flex" flexDirection="column" p={16} width="100%" overflowY="auto" flexGrow={1}>
              <InvoiceHistoryComponent isLoading={isLoading} data={invoiceStore.invoiceHistory} />
            </Box>
          </ErrorBoundary>
        </Box>
      </Drawer>
    )
  },
  { forwardRef: true },
)
