import { findIndex } from 'lodash'
import { BindAll } from 'lodash-decorators'

import { action, observable } from 'mobx'

import {
  get_cost_code_class,
  get_all_cost_code_classes,
  create_cost_code_class,
  update_cost_code_class,
  CreateCostCodeClass,
  UpdateCostCodeClass,
  CostCodeClass,
} from 'common/server/cost_codes/cost_code_classes'
import {
  get_cost_code_number,
  get_all_cost_code_numbers,
  create_cost_code_number,
  update_cost_code_number,
  CreateCostCodeNumber,
  UpdateCostCodeNumber,
  CostCodeNumber,
} from 'common/server/cost_codes/cost_code_numbers'
import {
  get_cost_code_phase,
  get_all_cost_code_phases,
  create_cost_code_phase,
  update_cost_code_phase,
  facets as phase_code_facets,
  CreateCostCodePhase,
  UpdateCostCodePhase,
  CostCodePhase,
} from 'common/server/cost_codes/cost_code_phases'
import {
  index,
  facets as cost_code_facets,
  upsert,
  CostCode,
  UpsertCostCodeParam,
} from 'common/server/cost_codes/cost_codes'
import ListBaseStore from 'common/stores/ListBaseStore'

class CostCodePhaseListStore extends ListBaseStore<CostCodePhase> {
  index = get_all_cost_code_phases
  getFacets = phase_code_facets
}

class CostCodeNumberListStore extends ListBaseStore<CostCodeNumber> {
  index = get_all_cost_code_numbers
}

class CostCodeClassListStore extends ListBaseStore<CostCodeClass> {
  index = get_all_cost_code_classes
}

class CostCodeListStore extends ListBaseStore<CostCode> {
  index = index
  getFacets = cost_code_facets
}

@BindAll()
export default class CostCodeStore {
  @observable selectedCostCode: Nullable<CostCode> = null
  @observable selectedPhaseCode: Nullable<CostCodePhase> = null

  bulkEditedCostCodes = observable.array<CostCode>([], { deep: true })

  costCodePhaseListStore: CostCodePhaseListStore
  costCodeNumberListStore: CostCodeNumberListStore
  costCodeClassListStore: CostCodeClassListStore
  costCodeListStore: CostCodeListStore
  constructor() {
    this.costCodePhaseListStore = new CostCodePhaseListStore()
    this.costCodeNumberListStore = new CostCodeNumberListStore()
    this.costCodeClassListStore = new CostCodeClassListStore()
    this.costCodeListStore = new CostCodeListStore()
  }

  fetchAllActiveCostCodes() {
    this.costCodeListStore.setFilter('active', true, true, true)
    return this.costCodeListStore.fetchAllRecords()
  }

  fetchAllActivePhaseCodes() {
    this.costCodePhaseListStore.setFilter('active', true, true, true)
    return this.costCodePhaseListStore.fetchAllRecords()
  }

  @action
  selectFromList(id: Nullable<string>) {
    if (!id) {
      this.selectedCostCode = null
    } else {
      this.selectedCostCode = this.costCodeListStore.records.find((costCode) => costCode.id === id)
    }

    return this.selectedCostCode
  }

  @action
  selectPhaseFromList(id: Nullable<string>) {
    if (!id) {
      this.selectedPhaseCode = null
    } else {
      this.selectedPhaseCode = this.costCodePhaseListStore.records.find((phaseCode) => phaseCode.id === id)
    }

    return this.selectedPhaseCode
  }

  async upsert(costCode: UpsertCostCodeParam) {
    const { data } = await upsert([costCode])
    return data?.cost_codes
  }

  updateBulk() {
    return upsert(
      this.bulkEditedCostCodes.map((costCode) => ({
        id: costCode.id,
        active: costCode['active'],
        cost_code_number: costCode.code,
        cost_code_number_description: costCode.description,
        cost_code_phase: costCode.phase_code,
        cost_code_phase_description: costCode.phase_code_description,
        cost_code_class: costCode.clazz,
        cost_code_class_description: costCode.clazz_description,
        project_ids_relation: costCode.project_ids,
      })),
    )
  }

  startBulkEdit() {
    this.bulkEditedCostCodes.replace([])
  }

  endBulkEdit() {
    this.bulkEditedCostCodes.replace([])
  }

  onChangeBulkEdited(costCode) {
    const existingIndex = findIndex(this.bulkEditedCostCodes, { id: costCode.id })
    if (existingIndex >= 0) {
      this.bulkEditedCostCodes[existingIndex] = costCode
    } else {
      this.bulkEditedCostCodes.push(costCode)
    }
  }

  validateBulkEdit(phaseCodeEnabled: boolean, classEnabled: boolean) {
    for (const { code, phase_code, clazz } of this.bulkEditedCostCodes) {
      if (!code) {
        throw new Error('Please make sure all Cost Codes have Code')
      }
      if (phaseCodeEnabled && !phase_code) {
        throw new Error('Please make sure all Cost Codes have Phase Code')
      }
      if (classEnabled && !clazz) {
        throw new Error('Please make sure all Cost Codes have Class')
      }
    }
  }

  // Cost Code
  async getCostCodeNumber(id: string) {
    const { data } = await get_cost_code_number(id)
    return data
  }
  async createCostCodeNumber(costCodeNumber: CreateCostCodeNumber) {
    const { data } = await create_cost_code_number(costCodeNumber)
    return data
  }
  async updateCostCodeNumber(costCodeNumber: UpdateCostCodeNumber) {
    const { data } = await update_cost_code_number(costCodeNumber)
    return data
  }

  // Phase Code
  async getCostCodePhase(id: string) {
    const { data } = await get_cost_code_phase(id)
    return data
  }
  async createCostCodePhase(costCodePhase: CreateCostCodePhase) {
    const { data } = await create_cost_code_phase(costCodePhase)
    return data
  }
  async updateCostCodePhase(costCodePhase: UpdateCostCodePhase) {
    const { data } = await update_cost_code_phase(costCodePhase)
    return data
  }

  // Class Code
  async getCostCodeClass(id: string) {
    const { data } = await get_cost_code_class(id)
    return data
  }
  async createCostCodeClass(costCodeClass: CreateCostCodeClass) {
    const { data } = await create_cost_code_class(costCodeClass)
    return data
  }
  async updateCostCodeClass(costCodeClass: UpdateCostCodeClass) {
    const { data } = await update_cost_code_class(costCodeClass)
    return data
  }
}
