import React from 'react'

import { CheckCircleOutlined, WarningOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import { Popover, Table, Typography } from 'antd'

import { Box } from 'common/components/boxes'

import { insertIf } from 'contractor/pages/Invoices/Detail/invoice_detail_extract'

type LineItemStatusProps = {
  status: string
  quoteQuantity: number
  orderQuantity: number
  unquoted: boolean
}

export const LineItemStatus = (props: LineItemStatusProps) => {
  const { status, quoteQuantity, orderQuantity, unquoted } = props
  const getAttributes = () => {
    const quantityMatch = quoteQuantity == orderQuantity

    const dataSource = [
      ...insertIf(!quantityMatch, { field: 'Qty', order: orderQuantity, quote: Number(quoteQuantity || 0) }),
    ]

    if (unquoted) {
      return {
        color: 'error',
        title: 'Unquoted Material',
        description: 'The vendor did not quote this material.',
        icon: <ExclamationCircleOutlined />,
      }
    }

    if (status === 'no-matches') {
      return {
        color: 'error',
        title: 'Discrepancies',
        dataSource,
        icon: <ExclamationCircleOutlined />,
      }
    }

    if (status === 'matches') {
      return {
        color: 'success',
        title: 'Perfect match!',
        description: 'All fields are matching',
        icon: <CheckCircleOutlined />,
      }
    }

    return {
      color: 'warning',
      title: 'Discrepancies',
      dataSource,
      icon: <WarningOutlined />,
    }
  }

  const attributes = getAttributes()

  return (
    <Box color={attributes.color} mx="8" display="flex" alignItems="center" justifyContent="center">
      <Popover
        title={attributes.title}
        content={
          attributes.description ? (
            attributes.description
          ) : (
            <Table
              pagination={false}
              size="small"
              columns={[
                { dataIndex: 'field' },
                { title: 'Quote', dataIndex: 'quote', render: (value) => <Typography.Text>{value}</Typography.Text> },
                { title: 'Order', dataIndex: 'order', render: (value) => <Typography.Text>{value}</Typography.Text> },
              ]}
              dataSource={attributes.dataSource}
              style={{ minWidth: 200 }}
            />
          )
        }
        placement="topRight"
        arrowPointAtCenter
      >
        {attributes.icon}
      </Popover>
    </Box>
  )
}
