import React from 'react'

import { observer } from 'mobx-react-lite'

import Download from 'common/components/table/download'
import { formatDateStringShort } from 'common/helpers/formatters'

import { useFlag } from 'contractor/hooks/use-flag'
import { useStores } from 'contractor/hooks/use-stores'

type DownloadButtonProps = {
  filters: string
}

export const DownloadButton = observer<DownloadButtonProps>(({ filters }) => {
  const { invoiceStore, invoiceSettingsStore } = useStores()

  const insertIf = (condition, ...elements) => (condition ? elements : [])
  const externalVendorIdEnabled = useFlag('external_vendor_id')
  const accountingDateEnabled = invoiceSettingsStore?.settings?.accounting_date_enabled

  const headers = [
    { label: 'Status', key: 'state_label' },
    { label: 'Assigned To', key: 'assigned_to' },
    { label: 'Vendor', key: 'vendor_name' },
    { label: 'Invoice Number', key: 'number' },
    { label: 'Projects', key: 'project_names' },
    { label: 'PO', key: 'pos' },
    { label: 'Order Names', key: 'order_names' },
    { label: 'Created At', key: 'created_at' },
    { label: 'Invoice Date', key: 'document_date' },
    { label: 'Due Date', key: 'due_date' },
    { label: 'Total Amount', key: 'total_amount' },
    { label: 'Posted At', key: 'posted_at' },
    { label: 'Tags', key: 'tags' },
    ...insertIf(accountingDateEnabled, { label: 'Accounting Date', key: 'accounting_date' }),
  ]

  const transformData = (hit) => {
    const orders = hit['orders'] || []
    hit['pos'] = orders.map((order) => order?.order_number).join(', ')
    hit['order_names'] = orders.map((order) => order?.order_package_name).join(', ')
    hit['tags'] = hit['tags']?.join(', ')
    hit['document_date'] = formatDateStringShort(hit['document_date'])
    hit['due_date'] = formatDateStringShort(hit['due_date'])
    hit['posted_at'] = formatDateStringShort(hit['posted_at'])
    hit['created_at'] = formatDateStringShort(hit['created_at'])
    hit['state_label'] = hit['state']?.['label']
    hit['accounting_date'] = formatDateStringShort(hit['accounting_date'])

    const vendorName = hit['company_vendor']?.['safe_globalized_vendor_name']

    if (externalVendorIdEnabled) {
      hit['vendor_name'] = hit['company_vendor']?.['external_vendor_id'] || vendorName
    } else {
      hit['vendor_name'] = vendorName
    }
  }

  return (
    <Download
      filters={filters}
      store={invoiceStore}
      indexName={invoiceStore.searchState['sortBy'] || invoiceStore.searchKey.index_name}
      headers={headers}
      transformData={transformData}
      title={'Invoices'}
      file_name={'invoices'}
    />
  )
})

export default DownloadButton
