/* eslint-disable react/no-unescaped-entities */
import React, { useRef } from 'react'

import { Button, Table, Typography, message } from 'antd'
import { ColumnsType } from 'antd/lib/table'

import { observer } from 'mobx-react-lite'

import { FlexBoxX, FlexBoxY } from 'common/components/boxes'
import { Page } from 'common/components/Page'
import { Visibility } from 'common/components/Visibility'
import { useQuery } from 'common/hooks/use-query'

import {
  ManageGroupDrawer,
  useManageGroupDrawer,
  ManageGroupDrawerForm,
  ManageGroupDrawerRef,
} from 'contractor/components/ManageGroupDrawer'
import { useStores } from 'contractor/hooks/use-stores'
import { ProjectGroupsList } from 'contractor/server/project_groups'

const columns: ColumnsType<ProjectGroupsList> = [
  {
    title: 'Group Name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: '# Projects',
    dataIndex: 'projects_count',
    key: 'projects_count',
    align: 'right',
  },
  {
    title: '# Users',
    dataIndex: 'project_group_members_count',
    key: 'project_group_members_count',
    align: 'right',
  },
]

export const ManageGroups = observer(() => {
  const { userStore, projectGroupsStore, companySettingStore, projectStore } = useStores()

  const drawerCreateGroupRef = useRef<ManageGroupDrawerRef>()
  const drawerUpdateGroupRef = useRef<ManageGroupDrawerRef>()
  const manageGroupDrawer = useManageGroupDrawer()

  const { isLoading } = useQuery(() => {
    return Promise.all([
      projectGroupsStore.indexProjectGroups(),
      manageGroupDrawer.dataLoaders.projects(),
      manageGroupDrawer.dataLoaders.users(),
      companySettingStore.maybeIndexUsers(),
    ])
  })

  function handleCloseModal() {
    projectGroupsStore.indexProjectGroups()
    projectStore.indexAllProjects()
    companySettingStore.indexUsers()
    drawerCreateGroupRef.current?.close()
    drawerUpdateGroupRef.current?.close()
  }

  async function handleEdit(id: string) {
    const group = await manageGroupDrawer.defaultValuesFactory(id)
    drawerUpdateGroupRef.current?.show(group)
  }

  async function handleCreateGroup(values: ManageGroupDrawerForm) {
    try {
      await projectGroupsStore.createProjectGroups({
        name: values.name,
        project_ids: values.selectedProjectsIds,
        user_ids: values.selectedUsersIds,
      })

      message.success('Successfully added new group')

      if (values.selectedUsersIds.includes(userStore.currentUser?.id)) {
        userStore.showUser()
      }

      handleCloseModal()
    } catch (error) {
      message.error(`Unable to create project group`)
    }
  }

  async function handleUpdateGroup(values: ManageGroupDrawerForm) {
    try {
      await projectGroupsStore.updateProjectGroups(values.id, {
        name: values.name,
        project_ids: values.selectedProjectsIds,
        user_ids: values.selectedUsersIds,
      })

      message.success('Successfully updated group')

      if (values.selectedUsersIds.includes(userStore.currentUser?.id)) {
        userStore.showUser()
      }

      handleCloseModal()
    } catch (error) {
      message.error(`Unable to update project group`)
    }
  }

  return (
    <>
      <Page.Header>
        <FlexBoxY alignItems="flex-start">
          <FlexBoxX width="100%" mb={{ _: 0, xs: 10 }} justifyContent="space-between">
            <Typography.Title level={4} style={{ margin: 0 }}>
              Project Groups
            </Typography.Title>

            {userStore.canManageUsers && (
              <Button type="primary" onClick={() => drawerCreateGroupRef.current?.show()}>
                Add new group
              </Button>
            )}
          </FlexBoxX>

          <Visibility.Hidden>
            <Typography.Paragraph style={{ margin: 0 }}>
              Groups allow you to limit what your users see by region, office, etc. Admins have access to view all
              orders and invoices for groups they are in. A user can be part of multiple groups but projects can only
              belong to one group. Users outside that project group won't see those projects or orders anywhere on
              SubBase, but if you send then a direct link to an order they can still open it.
            </Typography.Paragraph>
          </Visibility.Hidden>
        </FlexBoxY>
      </Page.Header>

      <Page.Content>
        <Table
          loading={isLoading}
          style={{ width: '100%' }}
          rowKey="id"
          dataSource={[...projectGroupsStore.projectGroups]}
          columns={columns}
          pagination={false}
          onRow={(record) => ({
            onClick: () => {
              if (!userStore.canManageUsers) return

              handleEdit(record?.id)
            },
          })}
        />
      </Page.Content>

      <ManageGroupDrawer
        ref={drawerCreateGroupRef}
        onClose={handleCloseModal}
        projects={manageGroupDrawer.projects}
        users={manageGroupDrawer.users}
        onSubmit={handleCreateGroup}
      />

      <ManageGroupDrawer
        ref={drawerUpdateGroupRef}
        onClose={handleCloseModal}
        projects={manageGroupDrawer.projects}
        users={manageGroupDrawer.users}
        onSubmit={handleUpdateGroup}
      />
    </>
  )
})
