import React, { createContext, useContext, useState } from 'react'

import { observer } from 'mobx-react-lite'

import { useLocalStorage } from 'common/hooks/use-local-storage'

const MyVendorsContext = createContext<MyVendorsContextProps>({} as MyVendorsContextProps)

export const useMyVendors = () => useContext(MyVendorsContext)

type MyVendorsContextProps = {
  onlyActives: boolean
  handleToggleOnlyActives: (checked: boolean) => void
  searchText: string
  handleSearchText: (text: string) => void
}

export const MyVendorsProvider = observer(({ children }) => {
  const [localStorage, setLocalStorage] = useLocalStorage<boolean>('@subbase/my-vendors/only-actives', true)

  const [onlyActives, toggleOnlyActives] = useState(localStorage)
  const [searchText, setSearchText] = useState('')

  const handleToggleOnlyActives = (checked) => {
    setLocalStorage(checked)
    toggleOnlyActives(checked)
  }

  const handleSearchText = (text = '') => setSearchText(text?.trim())

  return (
    <MyVendorsContext.Provider value={{ onlyActives, handleToggleOnlyActives, searchText, handleSearchText }}>
      {children}
    </MyVendorsContext.Provider>
  )
})

export const withMyVendorsProvider = (Component) => (props) => {
  return (
    <MyVendorsProvider>
      <Component {...props} />
    </MyVendorsProvider>
  )
}
