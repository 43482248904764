import React, { useRef, useState, useEffect } from 'react'

import { CSVLink } from 'react-csv'

import { get, set } from 'lodash'

import DownloadOutlined from '@ant-design/icons/DownloadOutlined'
import { Button, Tooltip, message } from 'antd'

import { observer } from 'mobx-react-lite'

import { formatForCSVExport } from 'common/helpers/formatters'
import { noticeError } from 'common/helpers/new_relic'
import { SUPPORT_EMAIL_ADDRESS } from 'common/helpers/other'
import AlgoliaBaseStore from 'common/stores/AlgoliaBaseStore'

interface DownloadProps {
  filters: string
  indexName: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  store: AlgoliaBaseStore<any>
  headers: { label: string; key: string }[]
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  transformData: (algoliaHit: any) => void
  title: string
  file_name: string
}

const DownloadButton = observer<DownloadProps>(
  ({ filters, indexName, store, headers, transformData, title, file_name }) => {
    const csvInstance = useRef(null)

    const [data, setData] = useState([])

    const downloadData = async () => {
      try {
        const requestOptions = {
          query: store.searchState['query'],
          filters,
          facetFilters: store.convertRefinementList(store.searchState),
          numericFilters: store.convertRange(store.searchState['range']),
        }

        const hits = await store.getAllIndexData(indexName, requestOptions)

        hits.forEach((hit) => {
          transformData(hit)
          headers.forEach(({ key }) => {
            const currentValue = get(hit, key)
            const newValue = formatForCSVExport(currentValue)
            set(hit, key, newValue)
          })
        })
        setData(hits)
      } catch (error) {
        noticeError(error, { entry: 'download-materials' })
        message.error(`Unable to download file. Please contact ${SUPPORT_EMAIL_ADDRESS} for further assistance.`)
      }
    }

    useEffect(() => {
      if (data && csvInstance?.current?.link) {
        setTimeout(() => {
          csvInstance.current.link.click()
          setData([])
        })
      }
    }, [data])

    return (
      <Tooltip title={`Download ${title}`} placement="bottom">
        <>
          <Button data-cy="download-button" onClick={downloadData}>
            <DownloadOutlined />
          </Button>
          {data.length > 0 && <CSVLink data={data} filename={`${file_name}.csv`} headers={headers} ref={csvInstance} />}
        </>
      </Tooltip>
    )
  },
)

export default DownloadButton
