import React from 'react'

import { Typography } from 'antd'

import { Box } from 'common/components/boxes'

import { useFlag } from 'contractor/hooks/use-flag'
import DownloadMenu from 'contractor/pages/Orders/download'
import { DownloadFoundation } from 'contractor/pages/Orders/download_foundation'
import { DownloadTCM } from 'contractor/pages/Orders/download_tcm'

interface OrdersDownloadProps {
  filters: string
}
export const OrdersDownload = (props: OrdersDownloadProps) => {
  const { filters } = props
  const tcmCsvDownloadEnabled = useFlag('tcm_csv_download')

  const foundationCsvDownloadEnabled = useFlag('foundation_csv_download')
  return (
    <Box p={12} display="flex" flexDirection="column">
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography.Title level={5} style={{ marginRight: '24px', marginBottom: 0 }}>
          Download
        </Typography.Title>
        <DownloadMenu filters={filters} />
      </Box>

      {tcmCsvDownloadEnabled && (
        <Box display="flex" alignItems="center" justifyContent="space-between" mt={24}>
          <Typography.Title level={5} style={{ marginRight: '24px', marginBottom: 0 }}>
            Download TCM
          </Typography.Title>
          <DownloadTCM filters={filters} />
        </Box>
      )}

      {foundationCsvDownloadEnabled && (
        <Box display="flex" alignItems="center" justifyContent="space-between" mt={24}>
          <Typography.Title level={5} style={{ marginRight: '24px', marginBottom: 0 }}>
            Download Foundation
          </Typography.Title>
          <DownloadFoundation filters={filters} />
        </Box>
      )}
    </Box>
  )
}
