import React from 'react'

import ProjectSelector, { ProjectOption } from 'contractor/components/ProjectSelector/project_selector'
import { ShowProjectResponse, unknownProject } from 'contractor/server/projects'

interface Props {
  onChange?: (project: ShowProjectResponse) => void
}

export const InvoiceProjectSelector = (props: Props) => {
  const projectOptions: ProjectOption[] = [
    {
      id: 'all',
      name: 'All Projects',
      project_id: null,
      inventory: false,
    },
    {
      id: unknownProject.id,
      name: unknownProject.name,
      project_id: null,
      inventory: unknownProject.inventory,
    },
  ]

  return <ProjectSelector projectOptions={projectOptions} {...props} />
}
