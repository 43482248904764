import React, { useRef } from 'react'

import { UploadOutlined, PlusOutlined } from '@ant-design/icons'
import { Space, Button, Tooltip } from 'antd'

import { observer } from 'mobx-react-lite'

import { DrawerRef } from 'common/components/Drawer'
import { Visibility } from 'common/components/Visibility'

import { CreateInvoiceDrawer } from 'contractor/pages/Invoices/Add/create_invoice_drawer'
import { InvoiceUpload } from 'contractor/pages/Invoices/Upload/invoice_upload'

export const CreateInvoiceAction = observer(() => {
  const drawerCreateRef = useRef<DrawerRef>()
  const drawerUploadRef = useRef<DrawerRef>()

  return (
    <>
      <Space>
        <Tooltip title="Manually Add Invoices" placement="bottom">
          <Button
            type="default"
            htmlType="submit"
            data-cy="add-invoice"
            onClick={() => drawerCreateRef.current?.show()}
            style={{ display: 'flex', alignItems: 'center' }}
            icon={<PlusOutlined />}
          >
            Create
          </Button>
        </Tooltip>

        <Visibility.Hidden>
          <Tooltip title="Manually Upload Invoices" placement="bottom">
            <Button
              type="primary"
              htmlType="submit"
              data-cy="upload-invoice"
              onClick={() => drawerUploadRef.current?.show()}
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <UploadOutlined />
              Upload
            </Button>
          </Tooltip>
        </Visibility.Hidden>
      </Space>

      <InvoiceUpload ref={drawerUploadRef} onClose={() => drawerUploadRef.current.close()} />
      <CreateInvoiceDrawer ref={drawerCreateRef} onClose={() => drawerCreateRef.current.close()} />
    </>
  )
})
