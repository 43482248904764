import React, { useEffect, useState } from 'react'

import moment from 'moment'

import styled from '@emotion/styled'

import { LockOutlined, MessageOutlined } from '@ant-design/icons'
import { Badge, Button, Comment, Timeline, Tooltip, Typography } from 'antd'

import { observer } from 'mobx-react-lite'

import { Box } from 'common/components/boxes'
import { Drawer } from 'common/components/Drawer'
import { formatName } from 'common/helpers/formatters'
import { useQuery } from 'common/hooks/use-query'
import { Comment as IComment } from 'common/server/comment'

import { useStores } from 'contractor/hooks/use-stores'

import { Marker, MarkerProps } from './marker'
import { makeMentionTag, Mention } from './mention'

const BadgeStyled = styled(Badge)`
  .ant-badge-dot {
    width: 10px;
    min-width: 10px;
    height: 10px;
  }
`
const CommentStyled = styled(Comment)`
  .ant-comment-inner {
    padding: 0;
  }

  .mention {
    color: ${({ theme }) => theme.colors.highlightBlue};
    font-weight: bold;
  }
`

export type InternalCommentsProps = {
  comments?: IComment[]
  value?: string
  onChange?: (params: { text: string; userIds: string[] }) => void
  onSubmit?: (params: { text: string; userIds: string[] }) => void
  loading?: boolean
  isDraftOrder?: boolean
  isDraftCommitment?: boolean
} & Omit<MarkerProps, 'nameInitials'>

const commonStyle = { width: '100%', padding: '0 12px' }

const initialValueState = { text: '', userIds: [] }

const mentionRegex = /@.*?(\s|$)/g

export const extractMentions = (text) => {
  const mentions = text.match(mentionRegex)
  return mentions ? mentions.map((mention) => mention.slice(1).trim()) : []
}

export const InternalComments = observer<InternalCommentsProps>(
  ({
    comments = [],
    onSubmit,
    loading,
    isDraftOrder,
    value: valueExternal,
    onChange: onChangeExternal,
    orderState,
    orderSubState,
    invoiceState,
    isDraftCommitment,
  }) => {
    const { companySettingStore } = useStores()

    const [visible, toggleVisible] = useState(false)
    const [valueIntennal, onChangeInternal] = useState(initialValueState)

    useQuery(companySettingStore.maybeIndexUsers)

    const value = valueExternal || valueIntennal.text
    const onChange = onChangeExternal || onChangeInternal

    const getUserIdsFromMentions = (text) => {
      const mentions = extractMentions(text)
      const mentionedUsers = companySettingStore.possibleUsers.filter((user) => {
        const mention = makeMentionTag(user)
        return mentions.includes(mention)
      })
      return mentionedUsers.map((user) => user.id)
    }

    const handleSubmit = (text) => {
      const userIds = getUserIdsFromMentions(text)
      onSubmit?.({ text, userIds })
      setTimeout(() => onChange(initialValueState), 100)
    }

    const handlePressEnter = (event) => {
      if (!event.shiftKey && !!value.trim().length) {
        handleSubmit(value)
      }
    }

    const scrollCommentsToDown = React.useCallback(() => {
      const scrollableDiv = document.getElementById('comments-list')
      const lasttCommentElement = document.querySelector('#comments-list .ant-timeline-item:last-child') as HTMLElement

      if (lasttCommentElement && scrollableDiv) {
        scrollableDiv.scrollTo({
          top: lasttCommentElement.offsetTop,
          behavior: 'smooth',
        })
      }
    }, [])

    useEffect(() => {
      scrollCommentsToDown()
    }, [comments.length, scrollCommentsToDown])

    // Open the drawer automatically when having the internal_message param in the URL
    useEffect(() => {
      const internalMessageParam = new URLSearchParams(location.search).get('internal_message')

      if (internalMessageParam) {
        toggleVisible(true)
        setTimeout(() => scrollCommentsToDown(), 0)
      }
    }, [scrollCommentsToDown])

    return (
      <>
        <BadgeStyled dot={!!comments.length} status="default">
          <Button
            data-cy="internal-comments-button"
            icon={<MessageOutlined />}
            onClick={() => {
              toggleVisible(true)
              setTimeout(() => scrollCommentsToDown(), 0)
            }}
          />
        </BadgeStyled>

        <Drawer
          forceRender
          open={visible}
          onClose={() => toggleVisible(false)}
          width={378}
          title={
            <Box display="inline-flex" alignItems="center">
              <Typography.Text strong style={{ marginRight: 12 }}>
                Internal Chat
              </Typography.Text>

              <Tooltip
                title="All comments on this page are internal and never visible to any of your vendors."
                arrowPointAtCenter
              >
                <LockOutlined />
              </Tooltip>
            </Box>
          }
          closable={false}
        >
          <Box display="flex" flexDirection="column" alignItems="flex-end" width="100%" height="100%">
            <Box width="100%" overflowY="auto" px={24} pt={24} id="comments-list">
              <Timeline>
                {comments?.map((comment) => {
                  const commentatorName = formatName(comment?.commentator?.first_name, comment?.commentator?.last_name)
                  return (
                    <Timeline.Item
                      key={comment.id}
                      dot={
                        <Marker
                          invoiceState={comment?.invoice_state}
                          orderState={comment?.order_state}
                          orderSubState={comment?.order_sub_state}
                          commitmentState={comment?.commitment_state}
                        />
                      }
                    >
                      <CommentStyled
                        style={commonStyle}
                        author={commentatorName}
                        content={
                          <p>
                            {comment.comment?.split(/(@\w+)/g)?.map((segment, index) => {
                              if (segment.startsWith('@')) {
                                return (
                                  <Typography.Link key={index} style={{ cursor: 'default' }}>
                                    {segment}
                                  </Typography.Link>
                                )
                              } else {
                                return <React.Fragment key={index}>{segment}</React.Fragment>
                              }
                            })}
                          </p>
                        }
                        datetime={<span>{moment(comment?.created_at).format('MM/DD/YYYY hh:mm a')}</span>}
                      />
                    </Timeline.Item>
                  )
                })}
              </Timeline>
            </Box>

            <CommentStyled
              data-cy="internal-comments-input"
              style={commonStyle}
              avatar={<Marker orderState={orderState} orderSubState={orderSubState} invoiceState={invoiceState} />}
              content={
                <Mention
                  onChange={(text) => onChange({ text, userIds: getUserIdsFromMentions(text) })}
                  onPressEnter={handlePressEnter}
                  value={value}
                  users={companySettingStore.possibleUsers}
                />
              }
            />

            {isDraftOrder ||
              (isDraftCommitment && (
                <Typography.Text type="secondary" style={{ padding: '0 12px 12px' }}>
                  Your comment will be sent once the {isDraftCommitment ? 'commitment' : 'order'} is saved.
                </Typography.Text>
              ))}

            <Button
              onClick={() => handleSubmit(value)}
              type="primary"
              disabled={!value.trim().length || isDraftOrder}
              loading={loading}
              style={{ margin: 12 }}
              data-cy="internal-comments-submit-button"
            >
              Send
            </Button>
          </Box>
        </Drawer>
      </>
    )
  },
)
