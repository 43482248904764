import React, { useRef } from 'react'

import PlusOutlined from '@ant-design/icons/PlusOutlined'
import { Button, Tooltip, Select } from 'antd'
import { SelectProps } from 'antd/lib/select'

import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'

import { FlexBoxX, BoxProps } from 'common/components/boxes'
import { DrawerRef } from 'common/components/Drawer'
import { useQuery } from 'common/hooks/use-query'

import { useStores } from 'contractor/hooks/use-stores'
import { CreateCompanyVendorContact } from 'contractor/pages/CompanyVendorContacts/MyVendors/Create'
import { CompanyVendorContact } from 'contractor/server/company_vendor_contacts'
import { IndexCompanyVendor } from 'contractor/server/company_vendors'

type OptionType = {
  label: string
  value: string
  original: IndexCompanyVendor
}

export type SelectCompanyVendorProps = {
  isCreate?: boolean
  wrapperProps?: BoxProps
  onMakeOption?: (companyVendor: IndexCompanyVendor) => OptionType
} & SelectProps<string, OptionType>

const makeOption = (companyVendor: IndexCompanyVendor) => ({
  label: companyVendor?.vendor?.name || companyVendor?.vendor_name,
  value: companyVendor.id,
  original: toJS(companyVendor),
})

export const SelectCompanyVendor = observer<SelectCompanyVendorProps>(
  ({ isCreate, wrapperProps, onMakeOption = makeOption, ...props }) => {
    const { companyVendorStore, userStore } = useStores()

    const { isLoading } = useQuery(companyVendorStore.maybeGetAllCompanyVendorsToSelect)

    const drawerCreateRef = useRef<DrawerRef>()

    const handleAfterCreate = (newContact: CompanyVendorContact) => {
      props?.onChange(newContact.company_vendor?.id, {
        label: newContact.company_vendor?.vendor_name,
        value: newContact.company_vendor?.id,
        original: {
          id: newContact.company_vendor?.id,
          vendor_name: newContact.company_vendor?.vendor_name,
        },
      })
    }

    const options = companyVendorStore.companyVendorsSelect.map(onMakeOption)

    return (
      <FlexBoxX width="100%" justifyContent="flex-start" {...wrapperProps}>
        <Select<string, OptionType>
          loading={isLoading}
          style={{ flex: '1 1 auto' }}
          showSearch
          aria-autocomplete="none"
          filterOption={(input, option) => {
            const inputValue = input.toLowerCase()
            const optionValue = String(option?.label)
            return optionValue.toLowerCase().includes(inputValue)
          }}
          options={options}
          {...props}
        />

        {isCreate && !userStore.isMaterialRequester && (
          <>
            <Tooltip title="Add New Vendor Contact">
              <Button
                style={{ marginLeft: 10 }}
                onClick={() => drawerCreateRef.current.show()}
                icon={<PlusOutlined />}
                disabled={props?.disabled}
              />
            </Tooltip>

            <CreateCompanyVendorContact
              hideNotification
              hideShowSaveAndAddAnotherButton
              onCancel={() => drawerCreateRef.current.close()}
              onFinish={handleAfterCreate}
              ref={drawerCreateRef}
            />
          </>
        )}
      </FlexBoxX>
    )
  },
)
