import { filter, flatten } from 'lodash'
import { BindAll } from 'lodash-decorators'

import { action, computed, observable } from 'mobx'

import {
  canCreateNewCompanyMaterial,
  makeCostCode,
  makeOrderMaterial,
  resetCompanyMaterialId,
} from 'common/helpers/order'
import { emptyOrderDelivery, OrderDelivery } from 'common/server/deliveries'
import { OrderMaterial } from 'common/server/orders'
import { Flags } from 'common/server/user'

import { emptyOrderMaterial } from 'contractor/server/order_materials'
import { create, CreateOrderRequest, index, OrderFormParams } from 'contractor/server/public_order_form'

@BindAll()
export default class PublicOrderStore {
  @observable newInternalComment = {
    text: '',
    userIds: [],
  }
  @observable formParams: OrderFormParams
  @observable feature_flags: Flags

  @observable orderMaterials: { [deliveryId: string]: OrderMaterial[] } = {}
  deliveries = observable.array<OrderDelivery>([emptyOrderDelivery()])

  @computed
  get companyAttributes() {
    const costCodeEnabled = this.formParams?.company_attributes.includes('cost_code_id')
    const independentPhaseCodesEnabled = this.formParams?.cost_code_settings?.independent_phase_codes_enabled

    if (costCodeEnabled && independentPhaseCodesEnabled) {
      return [...this.formParams?.company_attributes, 'cost_code_phase_id']
    }

    return this.formParams?.company_attributes
  }

  @computed
  get possibleUsers() {
    return filter(
      this.formParams?.users,
      (user) => (!!user.invitation_accepted_at || user.sign_in_count > 0) && user.is_active && !user.deactivated_at,
    )
  }

  getOrderMaterialsByDeliveryId(deliveryId: string) {
    return this.orderMaterials[deliveryId] || []
  }

  @action
  updateOrderMaterialsByDeliveryId(deliveryId: string, newOrderMaterials = []) {
    this.orderMaterials[deliveryId] = newOrderMaterials
  }

  getPlainOrderMaterials() {
    const orderMaterialsMatrix = Object.keys(this.orderMaterials).map((key) => {
      return this.orderMaterials[key]
    }, [])

    return flatten(orderMaterialsMatrix)
  }

  @action
  async index() {
    const { data } = await index(window.location.pathname.split('/')[2])
    this.formParams = data
    this.feature_flags = this.formParams.feature_flags
    return data
  }

  @action
  resetOrderRequest() {
    this.newInternalComment = {
      text: '',
      userIds: [],
    }
    this.orderMaterials = {}
    const delivery = emptyOrderDelivery()
    this.deliveries.replace([delivery])
    this.orderMaterials = {}
    this.addEmptyOrderMaterials(10, delivery.id)
  }

  validateRequiredFields = (orderMaterials = [], requiredFields) => {
    const errors = []
    const empty = [null, undefined, '']

    for (const field of requiredFields) {
      if (
        orderMaterials.some((material) => {
          if (field.key === 'unit') {
            return (
              !material.unit &&
              empty.includes(material?.company_material.unit_name) &&
              empty.includes(material?.company_material.unit_id)
            )
          }
          if (field.key === 'cost_code_id') {
            return !material.cost_code
          }
          return empty.includes(material[field.key]) && empty.includes(material?.company_material[field.key])
        })
      ) {
        errors.push(field.label)
      }
    }
    return errors
  }

  async create(payload: WithOptional<CreateOrderRequest, 'deliveries' | 'order_materials'>) {
    const public_order_form_url_extension = window.location.pathname.split('/')[2]

    const { data } = await create(public_order_form_url_extension, {
      ...payload,
      deliveries: this.deliveries,
      order_materials: this.filteredOrderedMaterials()
        .filter((orderMaterial) => canCreateNewCompanyMaterial(orderMaterial, false))
        .map(resetCompanyMaterialId)
        .map(makeOrderMaterial)
        .map(makeCostCode),
      internal_comment: this.newInternalComment.text,
      watcher_ids: this.newInternalComment?.userIds,
    })

    return data?.orders
  }

  // Filter out any materials which don't have a company material set or are missing their description
  filteredOrderedMaterials(): OrderMaterial[] {
    const orderMaterials = this.getPlainOrderMaterials()
    return filter(orderMaterials, (om) => !['', undefined, null].includes(om.company_material?.description))
  }

  @action
  addEmptyOrderMaterials(size = 5, deliveryId: string) {
    const newMaterials = Array(size)
      .fill(undefined)
      .map(() => ({ ...emptyOrderMaterial, delivery_id: deliveryId }))

    if (this.orderMaterials[deliveryId]) {
      this.orderMaterials[deliveryId] = [...this.orderMaterials[deliveryId], ...newMaterials]
    } else {
      this.orderMaterials[deliveryId] = newMaterials
    }
  }

  deliveryMaterials(deliveryId): OrderMaterial[] {
    return this.getOrderMaterialsByDeliveryId(deliveryId)
  }

  @action
  updateInternalComment(params: { text: string; userIds: string[] }) {
    this.newInternalComment = params
  }
}
