import React from 'react'

import styled from '@emotion/styled'

import { Typography, Popover, Divider } from 'antd'

import { FlexBoxX, FlexBoxY } from 'common/components/boxes'
import { MaterialItemTags } from 'common/components/MaterialItemTags'
import { formatDateString } from 'common/helpers/formatters'
import { useMediaQuery } from 'common/hooks/use-media-query'

import { CommonMaterial } from './materials_autocomplete'

const WrapperAttributes = styled(FlexBoxX)`
  & > div:last-child .ant-divider {
    display: none;
  }
`

const ItemValue = ({ label = '', item, attribute }) => {
  if (!item || !item[attribute]) {
    return null
  }

  return (
    <FlexBoxX justifyContent="flex-start" flexGrow={0}>
      {label && (
        <Typography.Text type="secondary" style={{ marginRight: 4, fontSize: 12 }}>
          {label}:
        </Typography.Text>
      )}

      <Typography.Text style={{ fontSize: 12, color: '#000000' }}>{item[attribute]}</Typography.Text>

      <Divider type="vertical" />
    </FlexBoxX>
  )
}

const Image = (props) => (
  <img
    {...props}
    onClick={(e) => e.stopPropagation()}
    style={{ objectFit: 'contain', border: '1px solid #f0f0f0', borderRadius: 4, backgroundColor: '#FFFF', padding: 2 }}
  />
)

type MaterialItemProps = {
  item: CommonMaterial
  onSelect?: (id: string) => void
}

export const MaterialItem = ({ item, onSelect }: MaterialItemProps) => {
  const isMdScreen = useMediaQuery('md')

  const imageSrc = item?.image_url

  const image = isMdScreen ? (
    <Image src={imageSrc} height={64} width={64} />
  ) : (
    <Popover content={<Image src={imageSrc} height={220} width={220} />} overlayStyle={{ zIndex: 9999 }}>
      <Image src={imageSrc} height={64} width={64} />
    </Popover>
  )

  return (
    <FlexBoxX
      data-cy={`search-material-item-${item.id}`}
      py="6px"
      justifyContent="space-between"
      onClick={() => onSelect?.(item?.id)}
    >
      <FlexBoxY alignItems="flex-start" mr="12px">
        <Typography.Text style={{ color: '#000000' }}>{item?.description}</Typography.Text>
        <WrapperAttributes justifyContent="flex-start" flexWrap="wrap">
          <ItemValue item={item} attribute="manufacturer" />
          <ItemValue item={item} attribute="manufacturer_name" />
          <ItemValue label="ID" item={item} attribute="product_identifier" />
          <ItemValue label="SIZE" item={item} attribute="size" />
          <ItemValue label="UNIT" item={item} attribute="unit_name" />
          <ItemValue label="UNIT" item={item} attribute="unit_name" />
          <ItemValue label="UNIT" item={item['unit']} attribute="unit_name_with_increment_label" />
          <ItemValue label="MATERIAL" item={item} attribute="material" />
        </WrapperAttributes>

        <MaterialItemTags label="TAGS" value={item?.['tags']} />

        {item['last_ordered'] && (
          <Typography.Text type="secondary" style={{ fontSize: 12, fontStyle: 'italic' }}>
            Last Ordered: {formatDateString(item['last_ordered'], 'unknown')}
          </Typography.Text>
        )}
      </FlexBoxY>

      {imageSrc && image}
    </FlexBoxX>
  )
}
