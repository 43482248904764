import React from 'react'

import { Space } from 'antd'

import { Box } from 'common/components/boxes'

type FloatActionsWrapperProps = {
  children: React.ReactNode
}

export const FloatActionsWrapper = ({ children }: FloatActionsWrapperProps) => {
  return (
    <Box
      position="fixed"
      bottom={32}
      right="50%"
      style={{ transform: 'translateX(50%)' }}
      boxShadow="0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%)"
    >
      <Space.Compact block>{children}</Space.Compact>
    </Box>
  )
}
