import React, { useRef, useState } from 'react'

import InfiniteScroll from 'react-infinite-scroll-component'
import { useHistory } from 'react-router-dom'

import moment from 'moment'

import styled from '@emotion/styled'

import { BellOutlined, LoadingOutlined, SettingOutlined } from '@ant-design/icons'
import { Badge, Button, Divider, message, Tabs, Tooltip, Typography } from 'antd'

import { observer } from 'mobx-react-lite'

import { Box, FlexBoxX, FlexBoxY } from 'common/components/boxes'
import { Drawer, DrawerRef } from 'common/components/Drawer'
import { NotificationsContent } from 'common/components/Notifications/notification_content'
import { SearchInput } from 'common/components/SearchInput'
import { useDebounce } from 'common/hooks/use-debounce'
import { useTheme } from 'common/hooks/use-theme'
import { NotificationData, ServerNotificationGroup } from 'common/server/user'

export type NotificationsProps = {
  notificationsGroups: ServerNotificationGroup[]
  onDismissNotificationGroup: (id: string) => Promise<void>
  dismissMessage: (id: string) => Promise<void>
  onDismissAllNotifications: () => Promise<void>
  onLoadMore: (value: boolean) => Promise<void>
  loadMessages: (groupId: string) => Promise<NotificationData[]>
  settingsPath?: string
  unreadNotificationsCount: number
  totalUnreadCount: number
}

const filterNotifications = (notifications: ServerNotificationGroup[], search: string) => {
  if (!search) return notifications

  return notifications.filter((notification) => notification.search?.includes(search?.toLowerCase()))
}

const BadgeStyled = styled(Badge)`
  color: inherit;
`

export const Notifications = observer(
  ({
    notificationsGroups,
    onDismissAllNotifications,
    onDismissNotificationGroup,
    onLoadMore,
    loadMessages,
    settingsPath = '/user_settings',
    unreadNotificationsCount,
    dismissMessage,
    totalUnreadCount,
  }: NotificationsProps) => {
    const drawerUpdateRef = useRef<DrawerRef>()
    const history = useHistory()
    const theme = useTheme()
    const [isSubmitting, setSubmitting] = useState(false)
    const [inboxSearch, setInboxSearch] = useState('')
    const [archivedSearch, setArchivedSearch] = useState('')
    const debouncedInboxSearch = useDebounce<string>(inboxSearch, 150)
    const debouncedArchivedSearch = useDebounce<string>(archivedSearch, 150)

    const readNotifications = notificationsGroups.filter((notification) => !notification.unread)
    const unreadNotifications = notificationsGroups.filter((notification) => notification.unread)

    const filterByDate = (notifications, period) =>
      notifications.filter((notification) => moment(notification.updated_at).isSame(new Date(), period))

    const todayUnreadNotifications = filterByDate(unreadNotifications, 'day')
    const thisWeekUnreadNotifications = filterByDate(unreadNotifications, 'week').filter(
      (notification) => !todayUnreadNotifications.includes(notification),
    )

    const thisMonthUnreadNotifications = filterByDate(unreadNotifications, 'month').filter(
      (notification) => ![...todayUnreadNotifications, ...thisWeekUnreadNotifications].includes(notification),
    )
    const olderUnreadNotifications = unreadNotifications.filter(
      (notification) =>
        ![...todayUnreadNotifications, ...thisWeekUnreadNotifications, ...thisMonthUnreadNotifications].includes(
          notification,
        ),
    )

    const todayReadNotifications = filterByDate(readNotifications, 'day')
    const thisWeekReadNotifications = filterByDate(readNotifications, 'week').filter(
      (notification) => !todayReadNotifications.includes(notification),
    )
    const thisMonthReadNotifications = filterByDate(readNotifications, 'month').filter(
      (notification) => ![...todayReadNotifications, ...thisWeekReadNotifications].includes(notification),
    )
    const latestReadNotifications = readNotifications.filter(
      (notification) =>
        ![...todayReadNotifications, ...thisWeekReadNotifications, ...thisMonthReadNotifications].includes(
          notification,
        ),
    )

    const handleDismisAll = async () => {
      setSubmitting(true)
      try {
        await onDismissAllNotifications()
      } catch {
        message.error('Unable to dismiss all notifications')
      } finally {
        setSubmitting(false)
      }
    }

    return (
      <>
        <BadgeStyled count={unreadNotificationsCount} offset={[16, 4]} style={{ boxShadow: 'none' }}>
          <Tooltip title="Notifications">
            <BellOutlined
              onClick={() => drawerUpdateRef.current.show()}
              data-cy="notifications"
              style={{ fontSize: 18 }}
            />
          </Tooltip>
        </BadgeStyled>

        <Drawer ref={drawerUpdateRef} closable={false} width={600}>
          <FlexBoxY justifyContent="flex-start" width="100%" height="100%">
            <Box width="100%" display="flex" flexDirection="column">
              <FlexBoxX p="8" paddingLeft="16" alignItems="center" width="100%" justifyContent="space-between">
                <Typography.Title level={5} style={{ margin: 0 }}>
                  Notifications
                </Typography.Title>

                <FlexBoxX display="flex" justifyContent="flex-end">
                  {!!unreadNotifications.length && (
                    <Button
                      type="text"
                      loading={isSubmitting}
                      onClick={handleDismisAll}
                      style={{ color: theme.colors['blue-6'] }}
                    >
                      Mark all as read
                    </Button>
                  )}
                  <Button
                    type="text"
                    icon={<SettingOutlined />}
                    size="small"
                    shape="circle"
                    onClick={() => {
                      history.push(`${settingsPath}?tab=settings`)
                      drawerUpdateRef.current.close()
                    }}
                  />
                </FlexBoxX>
              </FlexBoxX>
              <Divider style={{ margin: 0 }} />
            </Box>

            <Box display="flex" flexDirection="column" width="100%" overflow="auto">
              <Tabs
                tabBarStyle={{ marginBottom: 8 }}
                items={[
                  {
                    label: (
                      <Badge
                        count={unreadNotificationsCount}
                        offset={[15, 0]}
                        size="small"
                        style={{ backgroundColor: theme.colors['blue-1'], color: theme.colors['blue-6'] }}
                      >
                        <span style={{ paddingLeft: 16, paddingRight: 8 }}>Inbox</span>
                      </Badge>
                    ),
                    key: 'inbox',
                    children: (
                      <Box id="scrollableInbox" width="100%" style={{ overflowY: 'auto' }}>
                        <SearchInput
                          style={{ flexGrow: 1, padding: 16, paddingTop: 6, paddingBottom: 6 }}
                          onChange={(search) => setInboxSearch(search?.target?.value)}
                        />
                        <InfiniteScroll
                          scrollThreshold={0.7}
                          height="85vh"
                          scrollableTarget="scrollableInbox"
                          dataLength={unreadNotifications.length}
                          next={() => onLoadMore(true)}
                          style={{ display: 'flex', flexDirection: 'column' }}
                          hasMore={unreadNotificationsCount < totalUnreadCount}
                          loader={<LoadingOutlined />}
                          endMessage={
                            <p style={{ textAlign: 'center' }}>
                              <b>Yay! You have seen it all</b>
                            </p>
                          }
                        >
                          <NotificationsContent
                            dismissGroup={onDismissNotificationGroup}
                            title="Today"
                            notifications={filterNotifications(todayUnreadNotifications, debouncedInboxSearch)}
                            dismissMessage={dismissMessage}
                            onDismissNotificationGroup={onDismissNotificationGroup}
                            closeDrawer={drawerUpdateRef.current?.close}
                            loadMessages={loadMessages}
                          />
                          <NotificationsContent
                            dismissGroup={onDismissNotificationGroup}
                            title="This Week"
                            notifications={filterNotifications(thisWeekUnreadNotifications, debouncedInboxSearch)}
                            dismissMessage={dismissMessage}
                            onDismissNotificationGroup={onDismissNotificationGroup}
                            closeDrawer={drawerUpdateRef.current?.close}
                            loadMessages={loadMessages}
                          />
                          <NotificationsContent
                            dismissGroup={onDismissNotificationGroup}
                            title="This Month"
                            notifications={filterNotifications(thisMonthUnreadNotifications, debouncedInboxSearch)}
                            dismissMessage={dismissMessage}
                            onDismissNotificationGroup={onDismissNotificationGroup}
                            closeDrawer={drawerUpdateRef.current?.close}
                            loadMessages={loadMessages}
                          />
                          <NotificationsContent
                            dismissGroup={onDismissNotificationGroup}
                            title="Older"
                            notifications={filterNotifications(olderUnreadNotifications, debouncedInboxSearch)}
                            dismissMessage={dismissMessage}
                            onDismissNotificationGroup={onDismissNotificationGroup}
                            closeDrawer={drawerUpdateRef.current?.close}
                            loadMessages={loadMessages}
                          />
                        </InfiniteScroll>
                      </Box>
                    ),
                  },
                  {
                    label: 'Archived',
                    key: 'archived',
                    children: (
                      <Box width="100%">
                        <SearchInput
                          style={{ flexGrow: 1, padding: 16, paddingTop: 6, paddingBottom: 6 }}
                          onChange={(search) => setArchivedSearch(search?.target?.value)}
                        />
                        <InfiniteScroll
                          scrollThreshold={0.7}
                          height="85vh"
                          dataLength={readNotifications.length}
                          next={() => onLoadMore(false)}
                          style={{ display: 'flex', flexDirection: 'column' }}
                          hasMore={true}
                          loader={<LoadingOutlined />}
                          endMessage={
                            <p style={{ textAlign: 'center' }}>
                              <b>Yay! You have seen it all</b>
                            </p>
                          }
                        >
                          <NotificationsContent
                            dismissGroup={onDismissNotificationGroup}
                            title="Today"
                            notifications={filterNotifications(todayReadNotifications, debouncedArchivedSearch)}
                            dismissMessage={dismissMessage}
                            onDismissNotificationGroup={onDismissNotificationGroup}
                            closeDrawer={drawerUpdateRef.current?.close}
                            loadMessages={loadMessages}
                          />
                          <NotificationsContent
                            dismissGroup={onDismissNotificationGroup}
                            title="This Week"
                            notifications={filterNotifications(thisWeekReadNotifications, debouncedArchivedSearch)}
                            dismissMessage={dismissMessage}
                            onDismissNotificationGroup={onDismissNotificationGroup}
                            closeDrawer={drawerUpdateRef.current?.close}
                            loadMessages={loadMessages}
                          />
                          <NotificationsContent
                            dismissGroup={onDismissNotificationGroup}
                            title="This Month"
                            notifications={filterNotifications(thisMonthReadNotifications, debouncedArchivedSearch)}
                            dismissMessage={dismissMessage}
                            onDismissNotificationGroup={onDismissNotificationGroup}
                            closeDrawer={drawerUpdateRef.current?.close}
                            loadMessages={loadMessages}
                          />
                          <NotificationsContent
                            dismissGroup={onDismissNotificationGroup}
                            title="Older"
                            notifications={filterNotifications(latestReadNotifications, debouncedArchivedSearch)}
                            dismissMessage={dismissMessage}
                            onDismissNotificationGroup={onDismissNotificationGroup}
                            closeDrawer={drawerUpdateRef.current?.close}
                            loadMessages={loadMessages}
                          />
                        </InfiniteScroll>
                      </Box>
                    ),
                  },
                ]}
              />
            </Box>
          </FlexBoxY>
        </Drawer>
      </>
    )
  },
)
