import React from 'react'

import { ArrowLeftOutlined } from '@ant-design/icons'
import { Form, Input, Space, Tooltip, Typography } from 'antd'

import { Box } from 'common/components/boxes'
import { CustomEditableContent } from 'common/components/CustomEditableContent'
import { UnsavedPopup } from 'common/components/Page/unsaved_popup'
import { Events, trackEvent } from 'common/pendo/event_tracking'

import { useCommitment } from 'contractor/pages/@v2/Commitments/Components/CommitmentContext/context'
import { CellStatus } from 'contractor/pages/@v2/Commitments/Components/CommitmentListCells/cell_status'

type LeftActionsProps = {
  goBack: () => void
}

export const LeftActions = (props: LeftActionsProps) => {
  const { goBack } = props
  const { isDirty, commitment, commitmentName } = useCommitment()
  const source = window.location.pathname

  return (
    <Box display="flex" alignItems="center">
      <Box mr={16}>{isDirty ? <UnsavedPopup goBack={goBack} /> : <ArrowLeftOutlined onClick={() => goBack()} />}</Box>

      <Box
        onClick={() => trackEvent(Events.CLICK_COMMITMENT_NAME, source)}
        display="flex"
        alignItems="center"
        style={{ gap: 8 }}
      >
        <CustomEditableContent
          showRequired={!commitment?.id}
          boxProps={{ mt: 0 }}
          input={
            <Form.Item style={{ marginBottom: 0 }} name="commitmentName">
              <Input data-cy="commitment_name" style={{ width: 180, marginBottom: 0 }} />
            </Form.Item>
          }
        >
          <Tooltip title="Commitment Name" placement="bottom">
            <Space>
              <Typography.Title level={5} style={{ margin: 0, fontSize: 20 }}>
                {commitmentName || 'Commitment name'}
              </Typography.Title>
            </Space>
          </Tooltip>
        </CustomEditableContent>

        {commitment.status && (
          <Box display="inline-flex" style={{ gap: 8 }}>
            <CellStatus status={commitment?.status} />
          </Box>
        )}
      </Box>
    </Box>
  )
}
