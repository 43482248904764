import React from 'react'

import { Typography } from 'antd'

import { Box } from 'common/components/boxes'

import { useFlag } from 'contractor/hooks/use-flag'
import { useStores } from 'contractor/hooks/use-stores'

import { Download } from './common_download'
import { DownloadFoundation } from './download_foundation'
import { DownloadTCM } from './download_tcm'

export const OrderDownload = () => {
  const tcmCsvV2DownloadEnabled = useFlag('tcm_csv_v2_download')
  const foundationCsvDownloadEnabled = useFlag('foundation_csv_download')
  const {
    orderStore: { listStore },
  } = useStores()

  return (
    <Box p={12} display="flex" flexDirection="column">
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography.Title level={5}>Download Orders</Typography.Title>
        <Download onLoadData={listStore.fetchAllRecords} />
      </Box>

      {tcmCsvV2DownloadEnabled && (
        <Box mt="16" display="flex" alignItems="center" justifyContent="space-between">
          <Typography.Title level={5}>Download TCM</Typography.Title>

          <DownloadTCM />
        </Box>
      )}

      {foundationCsvDownloadEnabled && (
        <Box mt="16" display="flex" alignItems="center" justifyContent="space-between">
          <Typography.Title level={5}>Download Foundation</Typography.Title>

          <DownloadFoundation />
        </Box>
      )}
    </Box>
  )
}
