import React, { useEffect } from 'react'

import { Divider, Form, message, Button, Input } from 'antd'

import { observer } from 'mobx-react-lite'

import { Box } from 'common/components/boxes'
import { Drawer, DrawerRef } from 'common/components/Drawer'
import { AddressTo } from 'common/server/addressesTo'

type AddressToProps = {
  onClose: () => void
  onSubmit: (Address: AddressTo) => Promise<void>
  initialValues?: AddressTo
}

const AddressToDrawer = observer<AddressToProps, DrawerRef>(
  (props, ref) => {
    const { onClose, onSubmit, initialValues } = props

    const [form] = Form.useForm()

    const [isSubmitting, setSubmitting] = React.useState(false)

    useEffect(() => {
      form.setFieldsValue(initialValues)
    }, [initialValues])

    const handleFinish = async (formValues) => {
      setSubmitting(true)

      const operation = formValues?.id ? 'updated' : 'created'

      try {
        await onSubmit(formValues)
        form.resetFields()
        message.success(`Successfully ${operation} attention to`)
      } catch (error) {
        message.error(error?.response?.data?.error || `Unable to ${operation} attention to`)
      } finally {
        setSubmitting(false)
      }
    }

    return (
      <Drawer
        width={375}
        bgGray
        title={initialValues ? 'Edit Attention to' : 'Create a new Attention to'}
        ref={ref}
        onClose={() => {
          form.resetFields()
          onClose()
        }}
      >
        <Form
          layout="vertical"
          onFinish={handleFinish}
          form={form}
          style={{
            width: '100%',
            height: '100%',
            overflowY: 'auto',
            padding: 16,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Box flexGrow={1}>
            <Form.Item name="id" hidden>
              <Input />
            </Form.Item>
            <Form.Item required requiredMark name="name" label="Name">
              <Input style={{ width: '100%' }} />
            </Form.Item>
            <Form.Item name="phone_number" label="Phone Number">
              <Input style={{ width: '100%' }} />
            </Form.Item>
            <Form.Item name="email" rules={[{ type: 'email' }]} label="Email">
              <Input style={{ width: '100%' }} />
            </Form.Item>
          </Box>

          <Box>
            <Divider />

            <Box display="flex" justifyContent="space-between">
              <Button onClick={onClose} disabled={isSubmitting} style={{ width: 100 }}>
                Cancel
              </Button>

              <Button type="primary" htmlType="submit" loading={isSubmitting} style={{ width: 100 }}>
                Save
              </Button>
            </Box>
          </Box>
        </Form>
      </Drawer>
    )
  },
  { forwardRef: true },
)

export default AddressToDrawer
