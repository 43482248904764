import './style.css'

import React from 'react'

import { CloseOutlined } from '@ant-design/icons'
import { Button, notification } from 'antd'

import { usePushToCommitment } from 'common/hooks/use-push-to-commitment'
import theme from 'common/styles/theme'

import { Box } from '../../components/boxes'

type Index = {
  basePath?: string
}

type NotifyCommitmentParams = {
  message: string
  commitmentId?: string
  isNewTab?: boolean
}

export const useNotifyCommitment = ({ basePath }: Index = {}) => {
  const { push } = usePushToCommitment()
  const key = `commitment-notify-${new Date().getTime()}`

  const handleOpenClick = (params) => {
    notification.close(key)
    push({
      ...params,
      basePath,
    })
  }

  const notifyCommitment = ({ message, commitmentId = '', isNewTab }: NotifyCommitmentParams) => {
    const closeIcon = (
      <Box display="flex" gridGap={1}>
        <Button
          type="primary"
          size="small"
          onClick={() => {
            handleOpenClick({ commitmentId, isNewTab })
          }}
        >
          View Commitment
        </Button>
        <Button
          type="text"
          icon={<CloseOutlined style={{ color: theme.colors['gray-7'] }} />}
          size="small"
          onClick={() => {
            notification.close(key)
          }}
        />
      </Box>
    )

    notification.success({
      message,
      duration: 10,
      placement: 'bottomLeft',
      key,
      className: 'use-notify-commitment',
      closeIcon,
    })
  }

  return { notifyCommitment }
}
