import React, { useEffect } from 'react'

import { connectInfiniteHits } from 'react-instantsearch-dom'
import { Column } from 'react-table'

import { Typography, Space, Checkbox } from 'antd'

import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'

import { Box } from 'common/components/boxes'
import { ResponsiveTable } from 'common/components/ResponsiveTable'
import { useRefreshInfiniteList } from 'common/hooks/use-refresh-infinite-list'
import { CompanyMaterialHit } from 'common/server/company_materials'

import { useStores } from 'contractor/hooks/use-stores'

import Columns from './table_columns'

type CompanyMaterialTableConnectProps = {
  hasMore: boolean
  refineNext: () => void
  hits: CompanyMaterialHit[]
} & CompanyMaterialTableProps

const tableName = 'CompanyMaterials'

const style = { fontSize: 12, whiteSpace: 'nowrap' } as React.CSSProperties

const CompanyMaterialTableConnect = observer<CompanyMaterialTableConnectProps>(
  ({ hits, hasMore, refineNext, onChangeColumns, onClickRow }) => {
    const { companyMaterialStore, companySettingStore } = useStores()

    const { company_attributes } = companySettingStore.companyMaterialConfiguration

    useEffect(() => {
      companyMaterialStore.setHits(hits)
    }, [companyMaterialStore.searchState, hits])

    return (
      <ResponsiveTable
        data={toJS(companyMaterialStore.hits)}
        columns={Columns()}
        hasMore={hasMore}
        loadMore={refineNext}
        tableName={tableName}
        groupByPath="original.company_material"
        onClickRow={({ row }) => onClickRow(row.id)}
        setTableColumns={onChangeColumns}
        renderMobileCard={(row) => {
          const unit = row?.unit?.unit_name_with_increment_label || row?.unit_name
          return (
            <Space style={{ width: '100%' }} direction="vertical">
              <Typography.Text>{row?.description}</Typography.Text>

              <Box display="flex" alignItems="center" flexWrap="wrap">
                {company_attributes.includes('cost_code_id') && (
                  <Typography.Text type="secondary" style={style}>
                    {`COST CODE: ${row?.cost_code?.code || 'N/A'}`}
                  </Typography.Text>
                )}

                {company_attributes.includes('product_identifier') && (
                  <Typography.Text type="secondary" style={style}>
                    &nbsp;{`| PROD. ID: ${row?.product_identifier || 'N/A'}`}
                  </Typography.Text>
                )}

                {company_attributes.includes('unit') && (
                  <Typography.Text type="secondary" style={style}>
                    &nbsp;{`| UNIT: ${unit || 'N/A'}`}
                  </Typography.Text>
                )}

                {company_attributes.includes('material') && (
                  <Typography.Text type="secondary" style={style}>
                    &nbsp;{`| MATERIAL: ${row?.material || 'N/A'}`}
                  </Typography.Text>
                )}
              </Box>

              <Checkbox checked={row?.active}>Active</Checkbox>
            </Space>
          )
        }}
      />
    )
  },
)

const CustomHits = connectInfiniteHits(CompanyMaterialTableConnect)

type CompanyMaterialTableProps = {
  onChangeColumns: (columns: Column[]) => void
  onClickRow: (companyMaterialId: string) => void
}

export const CompanyMaterialTable = observer<CompanyMaterialTableProps>((props) => {
  const { companyMaterialStore } = useStores()
  useRefreshInfiniteList({
    forceRefreshHits: companyMaterialStore.forceRefreshHits,
    refreshHits: companyMaterialStore.refreshHits,
    attempts: 5,
  })

  return <CustomHits {...props} />
})
