import React from 'react'

import { Select } from 'antd'

import { IntegrationTaxBehavior } from 'common/server/server_types'

const { Option } = Select

export const SelectIntegrationTaxBehavior = ({ handleChange, defaultValue }) => {
  return (
    <Select style={{ width: 200 }} onChange={handleChange} value={IntegrationTaxBehavior[defaultValue]}>
      <Option key={IntegrationTaxBehavior.LINE_ITEM_TAX_AMOUNT} value={IntegrationTaxBehavior.LINE_ITEM_TAX_AMOUNT}>
        Line item tax amount
      </Option>
      <Option key={IntegrationTaxBehavior.TAX_AS_LINE_ITEM} value={IntegrationTaxBehavior.TAX_AS_LINE_ITEM}>
        Tax as a line item
      </Option>
      <Option key={IntegrationTaxBehavior.GENERAL_TAX} value={IntegrationTaxBehavior.GENERAL_TAX}>
        General tax
      </Option>
    </Select>
  )
}
