import React from 'react'

import moment from 'moment'

import styled from '@emotion/styled'

import { Button, DatePicker } from 'antd'
import type { RangePickerProps } from 'antd/es/date-picker'

import { Box, FlexBoxY, BoxProps } from 'common/components/boxes'
import { useMediaQuery } from 'common/hooks/use-media-query'

type Props = BoxProps & {
  attribute: string
  placeholderStart?: string
  placeholderEnd?: string
  value: { startDate: string; endDate: string }
  onChange: (value: { startDate: string; endDate: string }) => void
}

const WrapperPanel = styled.div`
  @media (max-width: ${({ theme }) => theme.breakpoints[0]}) {
    .ant-picker-panel {
      &:last-child {
        width: 0;
        .ant-picker-header {
          position: absolute;
          left: 114px;
          .ant-picker-header-prev-btn,
          .ant-picker-header-view {
            visibility: hidden;
          }
        }

        .ant-picker-body {
          display: none;
        }
      }
    }
  }
`

const format = 'MM/DD/YYYY'

export const DateRangePicker = (props: Props) => {
  const { placeholderStart = 'Start date', placeholderEnd = 'End date', onChange, value, ...restProps } = props

  const isMobileScreen = useMediaQuery('md')

  const handleChange = (value: RangePickerProps['value']) => {
    const [startDate, endDate] = value || []

    onChange({ startDate: startDate?.format('YYYY-MM-DD'), endDate: endDate?.format('YYYY-MM-DD') })
  }

  const formattedValue: [moment.Moment, moment.Moment] | null =
    value.startDate || value.startDate ? [moment(value.startDate), moment(value.endDate)] : null

  const handleSelectLastQuarter = () => {
    const currentDate = moment()
    const lastQuarterStart = currentDate.clone().subtract(3, 'months').startOf('quarter').format('YYYY-MM-DD')
    const lastQuarterEnd = currentDate.clone().subtract(3, 'months').endOf('quarter').format('YYYY-MM-DD')
    onChange({ startDate: lastQuarterStart, endDate: lastQuarterEnd })
  }

  const handleSelectLastMonth = () => {
    const currentDate = moment()
    const lastMonthStart = currentDate.clone().subtract(1, 'months').startOf('month').format('YYYY-MM-DD')
    const lastMonthEnd = currentDate.clone().subtract(1, 'months').endOf('month').format('YYYY-MM-DD')
    onChange({ startDate: lastMonthStart, endDate: lastMonthEnd })
  }

  const handleSelectLastWeek = () => {
    const currentDate = moment()
    const lastWeekStart = currentDate.clone().subtract(1, 'weeks').startOf('isoWeek').format('YYYY-MM-DD')
    const lastWeekEnd = currentDate.clone().subtract(1, 'weeks').endOf('isoWeek').format('YYYY-MM-DD')
    onChange({ startDate: lastWeekStart, endDate: lastWeekEnd })
  }

  const handleSelectThisQuarter = () => {
    const currentDate = moment()
    const currentQuarterStart = currentDate.clone().startOf('quarter').format('YYYY-MM-DD')
    const currentQuarterEnd = currentDate.clone().endOf('quarter').format('YYYY-MM-DD')
    onChange({ startDate: currentQuarterStart, endDate: currentQuarterEnd })
  }

  const handleSelectThisMonth = () => {
    const currentDate = moment()
    const currentMonthStart = currentDate.clone().startOf('month').format('YYYY-MM-DD')
    const currentMonthEnd = currentDate.clone().endOf('month').format('YYYY-MM-DD')
    onChange({ startDate: currentMonthStart, endDate: currentMonthEnd })
  }

  const handleSelectThisWeek = () => {
    const currentDate = moment()
    const currentWeekStart = currentDate.clone().startOf('isoWeek').format('YYYY-MM-DD')
    const currentWeekEnd = currentDate.clone().endOf('isoWeek').format('YYYY-MM-DD')
    onChange({ startDate: currentWeekStart, endDate: currentWeekEnd })
  }

  return (
    <FlexBoxY width="100%" alignItems="flex-start" {...restProps}>
      <DatePicker.RangePicker
        onChange={handleChange}
        defaultValue={formattedValue}
        value={formattedValue}
        style={{ width: '100%' }}
        format={format}
        panelRender={(panelNode) => <WrapperPanel>{panelNode}</WrapperPanel>}
        placeholder={[placeholderStart, placeholderEnd]}
        // Disable show keyboard when is mobile
        inputReadOnly={isMobileScreen}
        renderExtraFooter={() => (
          <Box display="flex" flexWrap="wrap" gridGap={4} flexGrow={1} py={8}>
            <Button size="small" onClick={handleSelectLastQuarter}>
              Last quarter
            </Button>
            <Button size="small" onClick={handleSelectLastMonth}>
              Last month
            </Button>
            <Button size="small" onClick={handleSelectLastWeek}>
              Last week
            </Button>
            <Button size="small" onClick={handleSelectThisQuarter}>
              This quarter
            </Button>
            <Button size="small" onClick={handleSelectThisMonth}>
              This month
            </Button>
            <Button size="small" onClick={handleSelectThisWeek}>
              This week
            </Button>
          </Box>
        )}
      />
    </FlexBoxY>
  )
}
