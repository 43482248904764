import React, { forwardRef } from 'react'

import { Checkbox, Divider, Select, Typography } from 'antd'
import { CheckboxChangeEvent } from 'antd/lib/checkbox'
import { RefSelectProps, SelectProps } from 'antd/lib/select'

import { Box } from 'common/components/boxes'
import { CostCodePhase } from 'common/server/cost_codes/cost_code_phases'

interface OnChangeApplyToAllProps {
  path: string
  value: string
  index?: number
}

export type SelectPhaseCodeProps = {
  phaseCodes: CostCodePhase[]
  isLoading?: boolean
  projectId?: string
  isCommitment?: boolean
  costCodeSettings?: {
    required: boolean
    project_filtering_enabled: boolean
    class_enabled: boolean
    phase_code_enabled: boolean
    independent_phase_codes_enabled: boolean
    project_specific_phase_codes_enabled: boolean
  }
  onChange?: (value: string, shouldPropagateValues?: boolean) => void
  onBlur?: (event: React.FocusEvent, applyToAllChecked: boolean) => void
  onChangeApplyToAll?: (props: OnChangeApplyToAllProps) => void
  defaultApplyToAllChecked?: boolean
  value?: string
} & Omit<SelectProps<string>, 'onChange' | 'onBlur'>

const getSearchable = (phaseCode) => {
  const phaseCodeString = `${phaseCode.code || ''}:${phaseCode.description || ''}`

  return phaseCodeString
}

const filterByProject = (phaseCode: CostCodePhase, projectId: string) =>
  !phaseCode.project_ids?.length || (!!projectId && phaseCode.project_ids?.includes(projectId))

export const SelectPhaseCode = forwardRef<RefSelectProps, SelectPhaseCodeProps>(
  (
    {
      phaseCodes: phaseCodesProps = [],
      value,
      onChange,
      isCommitment,
      isLoading,
      style,
      disabled,
      autoFocus,
      placeholder = 'Select Phase Code',
      onBlur,
      onClick,
      projectId,
      costCodeSettings,
      onChangeApplyToAll,
      defaultApplyToAllChecked,
    },
    ref,
  ) => {
    const phaseCodes = costCodeSettings?.project_specific_phase_codes_enabled
      ? phaseCodesProps.filter((costCode) => filterByProject(costCode, projectId))
      : phaseCodesProps

    const projectRequired = costCodeSettings?.project_specific_phase_codes_enabled && !projectId

    const [applyToAllChecked, setApplyToAllChecked] = React.useState(defaultApplyToAllChecked)

    React.useEffect(() => {
      setApplyToAllChecked(defaultApplyToAllChecked)

      return () => {
        setApplyToAllChecked(false)
      }
    }, [defaultApplyToAllChecked])

    function handleChangeApplyForAllEmptyPhaseCodes(e: CheckboxChangeEvent) {
      const shouldApplyForAllEmptyCostCodes = e.target.checked

      // it should apply only when is in the first step and the value is not empty
      // otherwhise, it'll trigger a re-render
      // when we don't apply here, it'll be applied when the customer finishes the selection
      if (!!value && shouldApplyForAllEmptyCostCodes) {
        onChangeApplyToAll({
          path: 'cost_code_phase_id',
          value: value,
        })
      }

      setApplyToAllChecked(shouldApplyForAllEmptyCostCodes)
    }

    return (
      <Select
        data-cy="select-phase-code"
        onBlur={(event) => onBlur?.(event, applyToAllChecked)}
        onClick={onClick}
        ref={ref}
        loading={isLoading}
        style={{ width: '100%', ...style }}
        dropdownMatchSelectWidth={false}
        showSearch
        filterOption={(input, option) => {
          const inputValue = input.toLowerCase()
          return option?.searchable?.toLowerCase().includes(inputValue)
        }}
        autoFocus={autoFocus}
        disabled={isCommitment || disabled || projectRequired}
        placeholder={placeholder}
        allowClear
        optionLabelProp="label"
        showAction={['click', 'focus']}
        onChange={(value) => onChange(value, applyToAllChecked)}
        value={value}
        dropdownRender={(menu) => (
          <>
            {menu}
            {!!onChangeApplyToAll && (
              <div
                onMouseDown={(e) => {
                  e.preventDefault()
                  e.stopPropagation()
                }}
                onClick={(e) => {
                  e.preventDefault()
                  e.stopPropagation()

                  if (disabled) {
                    return
                  }

                  handleChangeApplyForAllEmptyPhaseCodes({
                    target: {
                      checked: !applyToAllChecked,
                    },
                  } as CheckboxChangeEvent)
                }}
              >
                <Divider style={{ margin: '8px 0' }} />

                <Box display="flex" px={8} pb={4}>
                  <Checkbox disabled={disabled} checked={applyToAllChecked}>
                    Apply to all empty cost codes
                  </Checkbox>
                </Box>
              </div>
            )}
          </>
        )}
      >
        {phaseCodes.map((phaseCode) => {
          return (
            <Select.Option
              aria-autocomplete="none"
              key={phaseCode.id}
              value={phaseCode.id}
              label={phaseCode.code}
              searchable={getSearchable(phaseCode)}
            >
              <Box display="flex" flexDirection="column" style={{ whiteSpace: 'normal' }}>
                <Typography.Text>{phaseCode.code}</Typography.Text>
                <Typography.Text type="secondary" style={{ fontSize: 12 }}>
                  {phaseCode?.description}
                </Typography.Text>
              </Box>
            </Select.Option>
          )
        })}
      </Select>
    )
  },
)
