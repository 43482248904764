import React, { createElement, Fragment, useEffect, useRef, useState } from 'react'

import { render } from 'react-dom'
import { usePopper } from 'react-popper'
import { Portal } from 'react-portal'

import { BaseItem } from '@algolia/autocomplete-core'
import { autocomplete, AutocompleteOptions } from '@algolia/autocomplete-js'
import classNames from 'classnames'

export type AlgoliaAutocompleteProps<TItem extends BaseItem> = Omit<
  AutocompleteOptions<TItem>,
  'container' | 'renderer'
> & {
  disabled?: boolean
  portal?: boolean
  offset?: [number, number]
}

// https://www.algolia.com/doc/ui-libraries/autocomplete/guides/debouncing-sources/
const debouncePromise = (fn, time) => {
  let timerId = undefined

  return function debounced(...args) {
    if (timerId) {
      clearTimeout(timerId)
    }

    return new Promise((resolve) => {
      timerId = setTimeout(() => resolve(fn(...args)), time)
    })
  }
}
export const debounced = debouncePromise((items) => Promise.resolve(items), 300)

// Reference: https://www.algolia.com/doc/ui-libraries/autocomplete/integrations/using-react/
export const AlgoliaAutocomplete = <TItem extends {}>({
  disabled,
  offset,
  portal = true,
  ...props
}: AlgoliaAutocompleteProps<TItem>) => {
  const containerRef = useRef(null)

  const [offsetWidth, setOffsetWidth] = useState(0)

  const [referenceElement, setReferenceElement] = useState(null)
  const [popperElement, setPopperElement] = useState(null)

  const { styles, attributes, update } = usePopper(referenceElement, popperElement, {
    placement: 'bottom-start',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: offset || [0, 4],
        },
      },
    ],
  })

  useEffect(() => {
    if (!containerRef.current) {
      return undefined
    }

    const search = autocomplete({
      container: containerRef.current,
      renderer: { createElement, Fragment, render },
      panelContainer: popperElement,
      ...props,
    })

    const items = document.getElementsByClassName('aa-SubmitButton')
    Array.from(items).forEach((item) => {
      item.setAttribute('tabindex', '-1')
    })

    return () => search.destroy()
  }, [popperElement, containerRef.current, props?.getSources])

  // This code makes sure the dropdown width is the same as the input
  useEffect(() => {
    if (containerRef.current) {
      const newOffsetWidth = containerRef.current?.offsetWidth
      setOffsetWidth(newOffsetWidth)
    }
  }, [])

  useEffect(() => {
    const timeoutId = setTimeout(update, 500)
    return () => clearTimeout(timeoutId)
  }, [update])

  const commonProps = {
    ref: setPopperElement,
    style: { ...styles.popper, minWidth: offsetWidth, zIndex: 1001 },
    ...attributes.popper,
  }

  return (
    <>
      <div ref={setReferenceElement} style={{ flexGrow: 1, width: '100%' }}>
        <div ref={containerRef} style={{ width: 'inherit' }} className={classNames({ 'aa-Disabled': disabled })} />
      </div>

      {portal ? (
        <Portal>
          <div {...commonProps} />
        </Portal>
      ) : (
        <div {...commonProps} />
      )}
    </>
  )
}
