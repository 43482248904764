import axios from 'axios'

import { OrderStates, OrderSubStates, StandardIndexRequest, StandardIndexResponse } from 'common/server/server_types'

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace ConsolidatedOrdersMaterials {
  export interface OrderMaterial {
    id: string
    order_material_id: string
    quantity: string
    missing_quantity: string
    broken_quantity: string
    back_ordered_quantity: string
    incorrect_quantity: string
    unit_cost: string
    ext_cost: string
    created_at: string
    vendor_note: Nullable<string>
    vendor_response: string[]
    company_note: Nullable<string>
    cost_code_id: string
    cost_code_class: string
    cost_code_code: string
    cost_code_phase_id: Nullable<string>
    cost_code_phase_code: Nullable<string>
    order_id: string
    order_name: string
    order_number: string
    order_state: OrderStates
    order_sub_state: OrderSubStates
    order_package_id: string
    order_ordered_by: Nullable<string>
    order_is_quick_created: boolean
    order_ordered_at: Nullable<string>
    order_is_draft: boolean
    order_draft_vendor_names: string[]
    delivery_issue_type: Nullable<string>
    delivery_has_open_issue: boolean
    company_material_description: string
    company_material_unit_name_with_increment_label: string
    company_material_unit_id: string
    company_material_unit_name: Nullable<string>
    company_material_connection_type: string
    company_material_group: string
    company_material_manufacturer: string
    company_material_material: string
    company_material_product_identifier: string
    company_material_project_ids: string[]
    company_material_size: string
    company_material_sub_group: string
    project_name: string
    project_identifier: string
    company_vendor_name: string
    company_material_preferred_vendor_prices: Array<PreferredVendorPrice>
    quantity_delivered: string
    remaining_quantity: string
  }

  interface PreferredVendorPrice {
    vendor_name: string
    price: string
  }

  export interface Facets {
    [key: string]: string | Array<string>
  }

  export type StateCounts = {
    [stateName: string]: number
  }

  export type Response = StandardIndexResponse<Array<OrderMaterial>> & {
    state_counts: StateCounts
  }
}

async function index(request: StandardIndexRequest) {
  const { filters, ...restRequest } = request
  const url = `/consolidated_order_materials`

  return axios.get<ConsolidatedOrdersMaterials.Response>(url, {
    params: {
      ...filters,
      ...restRequest,
    },
  })
}

function facets() {
  const url = `/consolidated_order_materials/facets`

  return axios.get<ConsolidatedOrdersMaterials.Facets>(url)
}

export const consolidatedOrderMaterialsServer = {
  requests: {
    index,
    facets,
  },
}
