import React from 'react'

import { useHistory, useLocation } from 'react-router-dom'

import { DrawerTableFilters } from 'common/components/DrawerTableFilters'
import { Page } from 'common/components/Page'
import { usePersistentFilters } from 'common/contexts/persistent_filters'

import { CommitmentColumns } from 'contractor/pages/@v2/Commitments/Components/CommitmentListFilters/commitment_columns'
import { CommitmentDownload } from 'contractor/pages/@v2/Commitments/Components/CommitmentListFilters/commitment_download'
import { CommitmentFilters } from 'contractor/pages/@v2/Commitments/Components/CommitmentListFilters/commitment_filters'

export function OrdersFilters() {
  const history = useHistory()
  const location = useLocation()

  const persistentFilters = usePersistentFilters()
  const activeKey = new URLSearchParams(location.search).get('tab')

  const tabItems = [
    { label: 'Filters', key: 'filters', children: <CommitmentFilters /> },
    { label: 'Columns', key: 'columns', children: <CommitmentColumns /> },
    { label: 'Download', key: 'download', children: <CommitmentDownload /> },
  ]

  return (
    <DrawerTableFilters
      title="Table Options"
      useAlgolia={false}
      appliedFiltersCounter={persistentFilters.appliedFiltersCount}
      padding={0}
    >
      <Page>
        <Page.Tabs
          activeKey={activeKey || 'filters'}
          onChange={(key) => {
            const searchParams = new URLSearchParams(history.location.search)
            searchParams.set('tab', key)
            history.push({ search: searchParams.toString() })
          }}
          items={tabItems}
        />
      </Page>
    </DrawerTableFilters>
  )
}
