import React from 'react'

import { Typography } from 'antd'

import { Box } from 'common/components/boxes'
import { DateRangePicker, RefinementListDropdown } from 'common/components/table/filters'

import { useStores } from 'contractor/hooks/use-stores'
import { ClearAllButton } from 'contractor/pages/Materials/Filters/clear_all_button'

export const MaterialsFilters = () => {
  const { companySettingStore } = useStores()
  const costCodeSettings = companySettingStore.otherSettings?.cost_code_settings
  const { companyMaterialConfiguration } = companySettingStore
  const hasShowCostCodeFilter = companyMaterialConfiguration?.company_attributes?.includes('cost_code_id')
  const sortCostCodeFilter = (items = [], parameter = '') => {
    return items.sort((a, b) => {
      const aLabel = a?.label?.toLowerCase()
      const bLabel = b?.label?.toLowerCase()
      if (aLabel === parameter && bLabel !== parameter) return -1
      if (aLabel !== parameter && bLabel === parameter) return 1
      return 0
    })
  }

  return (
    <Box p={12} display="flex" flexDirection="column">
      <Box mb={16} width="100%" display="flex" flexDirection="column">
        <Typography.Text type="secondary">Vendor</Typography.Text>
        <RefinementListDropdown attribute="vendor_name" placeholder="Select vendor" isSearcheable />
      </Box>
      <Box mb={16} width="100%" display="flex" flexDirection="column">
        <Typography.Text type="secondary">Ordered by</Typography.Text>
        <RefinementListDropdown attribute="ordered_by_column" placeholder="Select user" isSearcheable />
      </Box>

      {hasShowCostCodeFilter && costCodeSettings?.phase_code_enabled && (
        <Box mb={16} width="100%" display="flex" flexDirection="column">
          <Typography.Text type="secondary">Phase Code</Typography.Text>
          <RefinementListDropdown
            attribute="phase_code_filter"
            placeholder="Select phase code"
            isSearcheable
            formatter={(label) => label}
            sortItems={(items) => sortCostCodeFilter(items, 'missing phase code')}
          />
        </Box>
      )}
      {hasShowCostCodeFilter && (
        <Box mb={16} width="100%" display="flex" flexDirection="column">
          <Typography.Text type="secondary">Cost Code</Typography.Text>
          <RefinementListDropdown
            attribute="cost_code_filter"
            placeholder="Select cost code"
            isSearcheable
            formatter={(label) => label}
            sortItems={(items) => sortCostCodeFilter(items, 'missing cost code')}
          />
        </Box>
      )}
      {hasShowCostCodeFilter && costCodeSettings?.class_enabled && (
        <Box mb={16} width="100%" display="flex" flexDirection="column">
          <Typography.Text type="secondary">Class</Typography.Text>
          <RefinementListDropdown
            attribute="clazz_code_filter"
            placeholder="Select class"
            isSearcheable
            formatter={(label) => label}
            sortItems={(items) => sortCostCodeFilter(items, 'missing class')}
          />
        </Box>
      )}

      <Box mb={16} display="flex" justifyContent="space-between" alignItems="center">
        <Box width="100%" display="flex" flexDirection="column">
          <DateRangePicker attribute="ordered_at_timestamp" title="Ordered At" />
        </Box>
      </Box>

      <ClearAllButton />
    </Box>
  )
}
