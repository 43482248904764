import React, { useState } from 'react'

import { Divider, List } from 'antd'

import { observer } from 'mobx-react-lite'

import { FlexBoxY, Box } from 'common/components/boxes'
import { SearchInput } from 'common/components/SearchInput'
import { useQuery } from 'common/hooks/use-query'

import { filterTextByFields } from 'contractor/helpers/filter-text-by-fields'
import { useStores } from 'contractor/hooks/use-stores'
import { CompanyVendorContact } from 'contractor/server/company_vendor_contacts'

import { VendorUserCard } from './vendor_user_card'

export type MyContactsProps = {
  companyVendorId: string
  orderType: OrderType
  onAdd?: (newCompanyVendorContact: CompanyVendorContact) => void
}

export const MyContacts = observer<MyContactsProps>(({ companyVendorId, orderType, onAdd }) => {
  const { companyVendorStore } = useStores()

  const { isLoading } = useQuery(
    () => companyVendorStore.getCompanyVendorContactsByCompanyVendorId(companyVendorId),
    [companyVendorId],
  )

  const [searchText, setSearchText] = useState('')

  const companyVendorContacts = companyVendorStore.companyVendorContacts

  const handleSearchText = (text = '') => setSearchText(text?.trim())

  const handeFilterText = (companyVendorContact: CompanyVendorContact) => {
    return filterTextByFields({ fields: ['full_name', 'email'], obj: companyVendorContact.contact, searchText })
  }

  return (
    <>
      <Box mx={{ _: 12, xs: 24 }}>
        <SearchInput onSearch={handleSearchText} placeholder="Search for my contacts..." style={{ width: '100%' }} />
      </Box>

      <Divider style={{ margin: '16px 0' }} />

      <FlexBoxY width="100%" justifyContent="flex-start" overflowY="auto" px={{ _: 12, xs: 24 }}>
        <List
          grid={{
            gutter: 20,
            column: 1,
          }}
          loading={isLoading}
          style={{ width: '100%', height: '100%' }}
          dataSource={companyVendorContacts.filter(handeFilterText)}
          renderItem={(companyVendorContact) => {
            const contact = companyVendorContact.contact
            return (
              <List.Item>
                <VendorUserCard
                  name={contact.vendor_user ? contact.vendor_user.full_name : contact.full_name}
                  email={contact.vendor_user ? contact.vendor_user.email : contact.email}
                  headline={contact?.vendor_user?.headline}
                  onConnectAndAdd={onAdd && (() => onAdd(companyVendorContact))}
                  orderType={orderType}
                  isConnected
                />
              </List.Item>
            )
          }}
        />
      </FlexBoxY>
    </>
  )
})
