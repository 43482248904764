import React from 'react'

import { flatten } from 'lodash'
import * as XLSX from 'xlsx'

import DownloadOutlined from '@ant-design/icons/DownloadOutlined'
import { Button, message } from 'antd'

import { observer } from 'mobx-react-lite'

import { SUPPORT_EMAIL_ADDRESS } from 'common/helpers/other'
import { OrderStates, OrderSubStates } from 'common/server/server_types'

import {
  makeFoundationHeaderFile,
  makeFoundationDetailFileItems,
} from 'contractor/components/ManualSyncStatusDetailed/helpers'
import { useFlag } from 'contractor/hooks/use-flag'
import { useStores } from 'contractor/hooks/use-stores'

type DownloadFoundationProps = {
  filters: string
}

const allowedOrderStatesToDownload = [OrderSubStates.ORDERED_CONFIRMED, OrderStates.SHIPPED, OrderStates.DELIVERED]

export const DownloadFoundation = observer<DownloadFoundationProps>(({ filters }) => {
  const { orderStore } = useStores()

  const externalVendorIdEnabled = useFlag('external_vendor_id')

  const downloadData = async () => {
    try {
      const requestOptions = {
        query: orderStore.searchState['query'],
        filters,
        facetFilters: orderStore.convertRefinementList(orderStore.searchState),
        numericFilters: orderStore.convertRange(orderStore.searchState['range']),
      }

      const indexName = orderStore.searchState['sortBy'] || orderStore.searchKey.index_name

      const orderHits = await orderStore.getAllIndexData(indexName, requestOptions)

      if (!orderHits.length) {
        return
      }

      const ordersToDownload = orderHits.filter((orderHit) =>
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        allowedOrderStatesToDownload.includes(orderHit.contractor_orders_filter as any),
      )

      const downloadOrders = ordersToDownload.map((orderHit) => {
        let companyVendorName = orderHit?.company_vendor?.safe_globalized_vendor_name
        if (externalVendorIdEnabled) {
          companyVendorName = orderHit?.company_vendor?.external_vendor_id || companyVendorName
        }
        const header = [makeFoundationHeaderFile({ orderHit: { ...orderHit, company_vendor_name: companyVendorName } })]
        const detail = makeFoundationDetailFileItems({ orderHit })
        return {
          header,
          detail: [...detail].filter(Boolean),
        }
      })

      const headersFileData = flatten(downloadOrders.map((data) => data.header))
      const detailsFileData = flatten(downloadOrders.map((data) => data.detail))

      const wsHeaders = XLSX.utils.json_to_sheet(headersFileData)
      const wsDetails = XLSX.utils.json_to_sheet(detailsFileData)

      const wbHeaders = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wbHeaders, wsHeaders, 'Data')
      const wbDetails = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wbDetails, wsDetails, 'Data')

      XLSX.writeFile(wbHeaders, 'TCMPOHeaderImp.csv')
      XLSX.writeFile(wbDetails, 'TCMPODetailImp.csv')

      orderStore.manual_export(ordersToDownload.map((orderHit) => ({ id: orderHit.id })))
    } catch {
      message.error(`Unable to download files. Please contact ${SUPPORT_EMAIL_ADDRESS} for further assistance.`)
    }
  }

  return (
    <Button onClick={downloadData}>
      <DownloadOutlined />
    </Button>
  )
})
