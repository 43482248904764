import React from 'react'

import { connectCurrentRefinements } from 'react-instantsearch-dom'

import FilterOutlined from '@ant-design/icons/FilterOutlined'
import { Badge, Tooltip, Button } from 'antd'

import { useTheme } from 'common/hooks/use-theme'

interface ShowFiltersButtonProps {
  items: object[]
  filtersVisible: boolean
  setFiltersVisible: (v: boolean) => void
}

const ShowFiltersButton: React.FC<ShowFiltersButtonProps> = ({ items, filtersVisible, setFiltersVisible }) => {
  const theme = useTheme()
  return (
    <Tooltip title="Filters" placement="bottom">
      <Button data-cy="filters" onClick={() => setFiltersVisible(!filtersVisible)}>
        <FilterOutlined />
        {items.length > 0 && (
          <Badge
            count={items.length}
            style={{ marginLeft: '5px', background: theme.colors['gray-7'], color: theme.colors.white }}
          />
        )}
      </Button>
    </Tooltip>
  )
}

const CustomCurrentRefinements = connectCurrentRefinements(ShowFiltersButton)

export default CustomCurrentRefinements
