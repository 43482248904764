import React from 'react'

import { Typography, Button } from 'antd'

import { Box } from 'common/components/boxes'
import OrderStateTag from 'common/components/statuses/order_state'
import { formatDateString } from 'common/helpers/formatters'
import { usePushToOrder } from 'common/hooks/use-push-to-order'
import { OrderHit } from 'common/server/orders'
import theme from 'common/styles/theme'

type OrderItemProps = {
  item: OrderHit
  showLink?: boolean
}

export const OrderItem = ({ item, showLink }: OrderItemProps) => {
  const { push } = usePushToOrder()

  const handlePush = () => {
    push({
      orderId: item?.id,
      orderPackageId: item?.order_package_id,
      state: item?.state,
      subState: item?.sub_state,
      isNewTab: true,
    })
  }

  return (
    <Box display="flex" alignItems="center" width="100%">
      <OrderStateTag state={item.state} sub_state={item.sub_state} theme={theme} fontSize={12} />

      <Box display="flex" flexDirection="column" flexGrow={1}>
        <Box display="flex" justifyContent="space-between" width="100%">
          <Typography.Text type="secondary">{item.project_name}</Typography.Text>
          <Typography.Text type="secondary">{formatDateString(item.created_at)}</Typography.Text>
        </Box>

        <Box display="flex" justifyContent="space-between" width="100%">
          {showLink ? (
            <Button
              style={{ padding: 0 }}
              type="link"
              onClick={(e) => {
                handlePush()
                e.stopPropagation()
              }}
            >
              {item.order_number}: {item.name}
            </Button>
          ) : (
            <Typography.Text type="secondary">
              {item.order_number}: {item.name}
            </Typography.Text>
          )}

          <Typography.Text type="secondary">{item?.company_vendor_name}</Typography.Text>
        </Box>
      </Box>
    </Box>
  )
}
