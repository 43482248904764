import React from 'react'

import { Button } from 'antd'

import { useStores } from 'contractor/hooks/use-stores'

type PlaceOrderButtonProps = {
  isLoading: boolean
  emptyCompanyVendors: boolean
  orderType: OrderType
  handlePlaceOrder: () => void
}

export const PlaceOrderButton = (props: PlaceOrderButtonProps) => {
  const { isLoading, emptyCompanyVendors, orderType, handlePlaceOrder } = props
  const { userStore } = useStores()

  if (orderType == 'Order' && userStore.canSendAndUpdateOrders) {
    return (
      <Button
        data-cy="place-order"
        type="primary"
        onClick={handlePlaceOrder}
        loading={isLoading}
        disabled={emptyCompanyVendors}
        style={{ marginLeft: 8 }}
      >
        Place Order
      </Button>
    )
  }

  return null
}
