import React, { useRef } from 'react'

import { Button, Divider, Input, message, Switch, Typography, Row, Col, Form } from 'antd'

import { observer } from 'mobx-react-lite'

import { FlexBoxX, Box } from 'common/components/boxes'
import { DrawerRef } from 'common/components/Drawer'
import { Loading } from 'common/components/Loading'
import { useQuery } from 'common/hooks/use-query'
import { Address } from 'common/server/addresses'

import { AddressDrawer, AddressTable } from 'contractor/components/Addresses'
import { SelectProjectGroup } from 'contractor/components/SelectProjectGroup/select_project_group'
import { WatchersSelect, makeWatcherOption } from 'contractor/components/WatchersSelect'
import { useStores } from 'contractor/hooks/use-stores'

import { InvoiceAssignees } from './invoice_assignees'

const Settings = observer(() => {
  const { projectStore, companySettingStore, userStore, addressStore } = useStores()

  const { isLoading } = useQuery(companySettingStore.maybeIndexUsers)

  const drawerCreateAddressRef = useRef<DrawerRef>()

  const project = projectStore.selectedProject

  const handlSubmit = async (formValues) => {
    try {
      await projectStore.updateProject({
        id: project?.id,
        name: formValues?.name,
        active: formValues?.active,
        default_watcher_user_ids: formValues?.defaultWatcherUserIds?.map(({ value }) => value),
        project_group_id: formValues?.projectGroupId,
        project_id: formValues?.projectId,
        project_invoice_assignees: formValues?.projectInvoiceAssignees?.map((projectInvoiceAssignee) => ({
          id: projectInvoiceAssignee?.id,
          user_id: projectInvoiceAssignee?.userId || null,
        })),
        tax_line_items: {
          enabled: formValues?.taxLineItemsEnabled,
        },
      })
      message.success(`Successfully saved project`)
    } catch (error) {
      message.error(error?.response?.data?.error || 'Unable to save changes')
    }
  }

  const handleAddressSaveAsNew = async (address: Omit<Address, 'id'>, currentAddress: Address) => {
    await Promise.all([
      projectStore.updateAddress({ ...currentAddress, active: false }),
      projectStore.addAddress(projectStore.selectedProject.id, address),
    ])

    addressStore.indexAddresses()
  }

  const handleCreateProjectAddress = async (address: Address) => {
    await projectStore.addAddress(projectStore.selectedProject.id, address)
    addressStore.indexAddresses()
  }

  if (isLoading) {
    return <Loading />
  }

  if (!projectStore.selectedProject) {
    return null
  }

  return (
    <Form
      layout="vertical"
      onFinish={handlSubmit}
      initialValues={{
        active: project?.active,
        name: project?.name,
        projectId: project?.project_id,
        projectGroupId: project?.project_group_id,
        defaultWatcherUserIds: companySettingStore.possibleUsers
          .filter((user) => project?.default_watcher_user_ids?.includes(user.id))
          .map((user) => makeWatcherOption(user)),
        projectInvoiceAssignees: project?.project_assignees?.map((projectAssignee) => ({
          userId: projectAssignee?.user_id,
          id: projectAssignee?.project_invoice_assignee?.id,
        })),
        taxLineItemsEnabled: project?.tax_line_items?.enabled,
      }}
    >
      <FlexBoxX mb={20} width="100%" justifyContent="flex-end">
        <Box>
          Active:
          <Form.Item valuePropName="checked" name="active" noStyle>
            <Switch style={{ marginRight: 15, marginLeft: 8 }} />
          </Form.Item>
        </Box>

        <Button data-cy="save-project" htmlType="submit" type="primary">
          Save Changes
        </Button>
      </FlexBoxX>

      <Row gutter={[20, 20]}>
        <Col xs={12} sm={12} md={8} lg={8} xl={7}>
          <Form.Item name="name" label="Project Name" rules={[{ required: true, message: 'Name is required' }]}>
            <Input data-cy="project-name" />
          </Form.Item>
        </Col>

        <Col xs={12} sm={12} md={6} lg={4} xl={3}>
          <Form.Item name="projectId" label="Project ID">
            <Input data-cy="project-id" />
          </Form.Item>
        </Col>

        <Col xs={24} sm={12} md={6} lg={6} xl={4}>
          <Form.Item name="projectGroupId" label="Project Group">
            <SelectProjectGroup allowClear />
          </Form.Item>
        </Col>

        <Col xs={24} sm={12} md={4} lg={6} xl={4}>
          <Form.Item valuePropName="checked" name="taxLineItemsEnabled" label="Distribute Tax to Line Items">
            <Switch />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={[20, 20]}>
        <Col xs={24} sm={12} lg={8} xl={6} xxl={4}>
          <Form.Item
            name="defaultWatcherUserIds"
            label="Default Order Watchers"
            tooltip="Any time a request, quote, or order is created for this project you will automatically be added as a watcher to receive updates."
          >
            <WatchersSelect />
          </Form.Item>
        </Col>

        {userStore.canUseInvoices && <InvoiceAssignees />}
      </Row>

      <Divider />

      <FlexBoxX width="100%" justifyContent="space-between">
        <Typography.Title level={4}>Addresses</Typography.Title>
        <Button data-cy="add-project-address" onClick={() => drawerCreateAddressRef.current?.show()}>
          Add Project Address
        </Button>
      </FlexBoxX>

      <AddressTable
        addresses={projectStore.selectedProject?.addresses}
        onSubmit={projectStore.updateAddress}
        onSaveAsNew={handleAddressSaveAsNew}
        hideSelect
      />

      <AddressDrawer
        ref={drawerCreateAddressRef}
        onClose={() => drawerCreateAddressRef.current?.close()}
        onSubmit={handleCreateProjectAddress}
        hideSelect
      />
    </Form>
  )
})

export default Settings
