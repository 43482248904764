import React from 'react'

import { currencyFormatter } from 'common/helpers/formatters'

interface CellUnitCostProps {
  value: string
}

export const CellUnitCost = ({ value }: CellUnitCostProps) => {
  let cost = ''

  if (value) {
    cost = currencyFormatter(value)
  }

  return <span>{cost == '$ 0.00' ? null : cost}</span>
}
