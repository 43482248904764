import React from 'react'

import { connectRefinementList } from 'react-instantsearch-dom'
import { useLocation } from 'react-router-dom'

import _ from 'lodash'

import { Badge, Select } from 'antd'

import { formatEnumValue } from 'common/helpers/formatters'
import { useTheme } from 'common/hooks/use-theme'
import { trackTableFiltering } from 'common/pendo/event_tracking'

const RefinementListDropdown = ({
  items,
  currentRefinement,
  refine,
  searchForItems,
  placeholder,
  isSearcheable,
  attribute,
  formatter = formatEnumValue,
  sortItems,
}) => {
  const location = useLocation()
  const source = location.pathname.substr(1)
  const theme = useTheme()

  // Put items that starts with _ to first in the list
  const firstItems = items.filter((item) => item.label.startsWith('_'))
  const lastItems = items.filter((item) => !item.label.startsWith('_'))
  const orderedLastItems = _.sortBy(lastItems, 'label')
  const allItems = sortItems ? sortItems([...firstItems, ...orderedLastItems]) : [...firstItems, ...orderedLastItems]

  return (
    <Select
      mode="multiple"
      allowClear
      aria-label={placeholder.toLowerCase().replace(/\s+/g, '-')}
      placeholder={placeholder}
      // HACK: default value = [], because algolia dispatch error in simple select
      onChange={(value = []) => {
        refine(value)
        trackTableFiltering(source, attribute)
      }}
      style={{ width: '100%' }}
      value={currentRefinement}
      optionLabelProp="label"
      showArrow
      onSearch={(searchVal) => isSearcheable && searchForItems(searchVal)}
    >
      {allItems.map((item) => (
        <Select.Option key={item.label} value={item.label} label={formatter(item.label)} style={{ minHeight: 35 }}>
          {formatter(item.label)}
          <Badge
            count={item.count}
            style={{ marginLeft: '10px', background: theme.colors['gray-7'], color: theme.colors.white }}
          />
        </Select.Option>
      ))}
    </Select>
  )
}

const CustomRefinementList = connectRefinementList(RefinementListDropdown)
export default CustomRefinementList
