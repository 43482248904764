import { InvoiceResponse } from 'common/server/invoice'

import { useStores } from 'contractor/hooks/use-stores'

export const useLockInvoice = (invoice: InvoiceResponse) => {
  const { userStore } = useStores()
  const currentUser = userStore?.currentUser
  const postedId = invoice?.posted_state?.id
  const canEditPostedInvoice = userStore?.canEditAndDeleteInvoices
  const isInvoicePosted = invoice?.state?.id == postedId
  const isUserAssigned = invoice?.posted_state?.assigned_to_id == currentUser?.id

  // We should allow anyone to edit invoices until they are posted
  // Once posted only the assigned user or users with the right permissions can edit them
  return isInvoicePosted && !canEditPostedInvoice && !isUserAssigned
}
