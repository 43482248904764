import React, { forwardRef, useEffect, useState } from 'react'

import { uniqBy } from 'lodash'
import { v4 as uuid_v4 } from 'uuid'

import { Checkbox, Divider, Select, Tooltip, Typography } from 'antd'
import { CheckboxChangeEvent } from 'antd/lib/checkbox'
import { RefSelectProps, SelectProps } from 'antd/lib/select'

import { Box } from 'common/components/boxes'
import { CostCode } from 'common/server/cost_codes/cost_codes'

import { Dropdown } from './dropdown'
import { Label } from './label'

export type Option = { value: string; label: React.ReactNode; costCode?: CostCode }

interface OnChangeApplyToAllProps {
  path: string
  value: CostCode
  index?: number
}

export type SelectCostCodeProps = {
  costCodes: CostCode[]
  projectId?: string
  costCodeSettings: {
    required: boolean
    project_filtering_enabled: boolean
    class_enabled: boolean
    phase_code_enabled: boolean
    independent_phase_codes_enabled: boolean
  }
  isLoading?: boolean
  isCommitment?: boolean
  onChange?: (value: Option, shouldPropagateValue?: boolean) => void
  onBlur?: (event: React.FocusEvent, applyToAllChecked: boolean) => void
  onChangeApplyToAll?: (props: OnChangeApplyToAllProps) => void
  defaultApplyToAllChecked?: boolean
} & Omit<SelectProps<Option>, 'onChange' | 'onBlur'>

type Step = 'phaseCodes' | 'costCodes' | 'clazzCodes' | 'finished'

const filterByProject = (costCode: CostCode, projectId: string) => {
  return !costCode.project_ids.length || (!!projectId && costCode.project_ids.includes(projectId))
}

const getSearchable = (costCode: CostCode) => {
  const costCodeString = `${costCode.code}:${costCode?.description || ''}`
  const phaseCodeString = `${costCode.phase_code || ''}:${costCode.phase_code_description || ''}`
  const classCodeString = `${costCode.clazz || ''}:${costCode.clazz_description || ''}`

  return `${costCodeString}:${phaseCodeString}:${classCodeString}`
}

export const makeCostCodeValue = (costCode, costCodeSettings) => {
  if (!costCode) {
    return undefined
  }

  return {
    costCode,
    value: costCode.id,
    label: <Label costCode={costCode} costCodeSettings={costCodeSettings} />,
  }
}

export const SelectCostCode = forwardRef<RefSelectProps, SelectCostCodeProps>(
  (
    {
      costCodes: costCodesProp = [],
      projectId,
      value: valueProp,
      onChange,
      costCodeSettings,
      isLoading,
      style,
      disabled,
      autoFocus,
      placeholder,
      onBlur,
      onClick,
      onChangeApplyToAll,
      defaultApplyToAllChecked = false,
      isCommitment,
    },
    ref,
  ) => {
    const initialStep =
      costCodeSettings?.phase_code_enabled && !costCodeSettings.independent_phase_codes_enabled
        ? 'phaseCodes'
        : 'costCodes'

    const selectId = React.useRef(uuid_v4())
    const [currentStep, setCurrentStep] = useState<Step>(() => {
      if (valueProp) {
        return 'finished'
      }

      return costCodeSettings?.phase_code_enabled && !costCodeSettings.independent_phase_codes_enabled
        ? 'phaseCodes'
        : 'costCodes'
    })
    const [value, setValue] = useState<Option>(valueProp)
    const [costCodes, setCostCodes] = useState([])
    const [applyToAllChecked, setApplyToAllChecked] = useState(defaultApplyToAllChecked)

    const [{ isPhaseCodeSelected, isCostCodeSelected, isClazzCodeSelected }, seSelectedSteps] = useState({
      isPhaseCodeSelected: !!valueProp,
      isCostCodeSelected: !!valueProp,
      isClazzCodeSelected: !!valueProp,
    })

    const [options, setOptions] = useState<{ costCodes: CostCode[]; phaseCodes: CostCode[]; clazzCodes: CostCode[] }>({
      costCodes: [],
      phaseCodes: [],
      clazzCodes: [],
    })

    useEffect(() => {
      setValue(valueProp)
      setCurrentStep('finished')
    }, [valueProp])

    useEffect(() => {
      const maybeFilteredByProjects = costCodeSettings?.project_filtering_enabled
        ? costCodesProp.filter((costCode) => filterByProject(costCode, projectId))
        : costCodesProp

      setCostCodes(maybeFilteredByProjects)
    }, [costCodesProp.length, costCodeSettings, projectId])

    useEffect(() => {
      if (costCodeSettings?.phase_code_enabled && !costCodeSettings.independent_phase_codes_enabled) {
        setOptions((prevState) => ({
          ...prevState,
          phaseCodes: uniqBy(costCodes, 'phase_code'),
        }))
      } else {
        setOptions((prevState) => ({
          ...prevState,
          costCodes: uniqBy(costCodes, 'code'),
        }))
      }
    }, [projectId, costCodes.length, costCodeSettings])

    useEffect(() => {
      switch (currentStep) {
        case 'phaseCodes':
          seSelectedSteps({
            isPhaseCodeSelected: false,
            isCostCodeSelected: false,
            isClazzCodeSelected: false,
          })
          break
        case 'costCodes': {
          seSelectedSteps({
            isPhaseCodeSelected: true,
            isCostCodeSelected: false,
            isClazzCodeSelected: false,
          })
          break
        }
        case 'clazzCodes': {
          seSelectedSteps({
            isPhaseCodeSelected: true,
            isCostCodeSelected: true,
            isClazzCodeSelected: false,
          })
          break
        }
        case 'finished': {
          seSelectedSteps({
            isPhaseCodeSelected: true,
            isCostCodeSelected: true,
            isClazzCodeSelected: true,
          })
          break
        }
      }
    }, [currentStep])

    const currentOptions = options[currentStep] || options[initialStep]

    const getPlaceholder = () => {
      if ((!costCodeSettings?.class_enabled && !costCodeSettings?.phase_code_enabled) || currentStep === 'costCodes') {
        return 'Select Cost Code'
      }

      if (currentStep === 'phaseCodes') {
        return 'Select Phase Code'
      }

      if (currentStep === 'clazzCodes') {
        return 'Select Class Code'
      }
    }

    const projectRequired = costCodeSettings?.project_filtering_enabled && !projectId

    const setSelectFocus = () => {
      const selectElement = document.getElementById(selectId.current)
      setTimeout(() => {
        selectElement.dispatchEvent(new FocusEvent('focus'))
      }, 0)
    }

    const handleRemove = (step: Step) => {
      switch (step) {
        case 'phaseCodes':
          setValue(null)
          setCurrentStep('phaseCodes')
          seSelectedSteps({
            isPhaseCodeSelected: false,
            isCostCodeSelected: false,
            isClazzCodeSelected: false,
          })
          break
        case 'costCodes': {
          setCurrentStep('costCodes')
          seSelectedSteps({
            isPhaseCodeSelected: true,
            isCostCodeSelected: false,
            isClazzCodeSelected: false,
          })
          break
        }
      }
    }

    const handleClear = () => {
      setValue(null)
      setCurrentStep(initialStep)
      onChange(null, applyToAllChecked)
    }

    React.useEffect(() => {
      setApplyToAllChecked(defaultApplyToAllChecked)

      return () => {
        setApplyToAllChecked(false)
      }
    }, [defaultApplyToAllChecked])

    function handleChangeApplyForAllEmptyCostCodes(e: CheckboxChangeEvent) {
      const shouldApplyForAllEmptyCostCodes = e.target.checked

      // it should apply only when is in the first step and the value is not empty
      // otherwhise, it'll trigger a re-render
      // when we don't apply here, it'll be applied when the customer finishes the selection
      if (value && currentStep === 'finished' && shouldApplyForAllEmptyCostCodes) {
        onChangeApplyToAll({
          path: 'cost_code',
          value: value.costCode,
        })
      }

      setApplyToAllChecked(shouldApplyForAllEmptyCostCodes)
    }

    return (
      <Tooltip title={projectRequired ? 'Please select one Project' : ''}>
        <Select<Option>
          data-cy="select-cost-code"
          onBlur={(event) => onBlur?.(event, applyToAllChecked)}
          onClick={onClick}
          ref={ref}
          id={selectId.current}
          loading={isLoading}
          style={{ width: '100%', ...style }}
          showSearch
          filterOption={(input, option) => {
            const inputValue = input.toLowerCase()
            return option?.searchable?.toLowerCase().includes(inputValue)
          }}
          autoFocus={autoFocus}
          disabled={disabled || projectRequired || isCommitment}
          placeholder={placeholder ?? getPlaceholder()}
          allowClear
          aria-autocomplete="none"
          showAction={['click', 'focus']}
          dropdownMatchSelectWidth={false}
          dropdownRender={(menu) => {
            const showPreview =
              ((costCodeSettings?.class_enabled && !costCodeSettings.independent_phase_codes_enabled) ||
                costCodeSettings?.phase_code_enabled) &&
              value &&
              currentStep !== 'finished'

            return (
              <>
                <Dropdown
                  menu={menu}
                  handleRemove={handleRemove}
                  showPreview={showPreview}
                  costCodeSettings={costCodeSettings}
                  costCode={value?.costCode}
                  isPhaseCodeSelected={isPhaseCodeSelected}
                  isCostCodeSelected={isCostCodeSelected}
                  isClazzCodeSelected={isClazzCodeSelected}
                />

                {!!onChangeApplyToAll && (
                  <div
                    onMouseDown={(e) => {
                      e.preventDefault()
                      e.stopPropagation()
                    }}
                    onClick={(e) => {
                      e.preventDefault()
                      e.stopPropagation()

                      if (disabled) {
                        return
                      }

                      handleChangeApplyForAllEmptyCostCodes({
                        target: {
                          checked: !applyToAllChecked,
                        },
                      } as CheckboxChangeEvent)
                    }}
                  >
                    <Divider style={{ margin: '8px 0' }} />

                    <Box display="flex" px={8} pb={4}>
                      <Checkbox disabled={disabled} checked={applyToAllChecked}>
                        Apply to all empty cost codes
                      </Checkbox>
                    </Box>
                  </div>
                )}
              </>
            )
          }}
          value={currentStep === 'finished' && value ? value : null}
          onClear={handleClear}
          onSelect={(_, option: Option) => {
            // If the user does not have phase and class enabled he only can select cost code
            const isClassAndPhaseDisabled = !costCodeSettings?.class_enabled && !costCodeSettings?.phase_code_enabled
            const isClassAndPhaseIndependentDisabled =
              !costCodeSettings?.class_enabled &&
              costCodeSettings?.phase_code_enabled &&
              costCodeSettings?.independent_phase_codes_enabled
            if (isClassAndPhaseDisabled || isClassAndPhaseIndependentDisabled) {
              onChange(option, applyToAllChecked)
              setValue(option)
              setCurrentStep('finished')
              return
            }

            const costCodeSelected = option?.costCode

            switch (currentStep) {
              case 'phaseCodes': {
                const filtereds = costCodes.filter(
                  (currentCostCode) =>
                    currentCostCode.id === option?.value || currentCostCode.phase_code === costCodeSelected?.phase_code,
                )
                setOptions((prevState) => ({
                  ...prevState,
                  costCodes: uniqBy(filtereds, 'code'),
                }))

                setCurrentStep('costCodes')
                setSelectFocus()
                break
              }
              case 'costCodes': {
                // If the class is not enabled cost code is the last step, the user finished the selection
                if (!costCodeSettings?.class_enabled) {
                  setCurrentStep('finished')
                  onChange(option, applyToAllChecked)
                } else {
                  setOptions((prevState) => ({
                    ...prevState,
                    clazzCodes: costCodes.filter(
                      (currentCostCode) =>
                        currentCostCode.id === option?.value ||
                        (currentCostCode.code === costCodeSelected?.code &&
                          currentCostCode.phase_code === costCodeSelected?.phase_code),
                    ),
                  }))
                  setCurrentStep('clazzCodes')
                  setSelectFocus()
                }
                break
              }
              case 'clazzCodes': {
                setCurrentStep('finished')
                // How class is the last step means the user finished selection
                onChange(option, applyToAllChecked)
                break
              }
              case 'finished': {
                const filteredsCostCodes = costCodes.filter(
                  (currentCostCode) =>
                    currentCostCode.id === option?.value || currentCostCode.phase_code === costCodeSelected?.phase_code,
                )
                setOptions((prevState) => ({
                  ...prevState,
                  costCodes: uniqBy(filteredsCostCodes, 'code'),
                  clazzCodes: costCodes.filter(
                    (currentCostCode) =>
                      currentCostCode.id === option?.value ||
                      (currentCostCode.code === costCodeSelected?.code &&
                        currentCostCode.phase_code === costCodeSelected?.phase_code),
                  ),
                }))
                setCurrentStep(
                  costCodeSettings?.phase_code_enabled && !costCodeSettings?.independent_phase_codes_enabled
                    ? 'costCodes'
                    : 'clazzCodes',
                )
                setSelectFocus()
                break
              }
              default: {
                setCurrentStep('finished')
              }
            }

            setValue(option)
          }}
        >
          {currentOptions.map((costCode) => {
            let title = costCode.code
            let description = costCode.description

            if (currentStep === 'phaseCodes') {
              title = costCode.phase_code
              description = costCode.phase_code_description
            } else if (currentStep === 'clazzCodes') {
              title = costCode.clazz
              description = costCode.clazz_description
            } else if (
              currentStep === 'finished' &&
              costCodeSettings?.phase_code_enabled &&
              !costCodeSettings?.independent_phase_codes_enabled
            ) {
              title = costCode.phase_code
              description = costCode.phase_code_description
            }

            if (!title) {
              return null
            }

            const costCodeValue = makeCostCodeValue(costCode, costCodeSettings)

            return (
              <Select.Option
                key={costCode.id}
                value={costCode.id}
                searchable={getSearchable(costCode)}
                costCode={costCode}
                label={costCodeValue?.label}
              >
                <Box display="flex" flexDirection="column" style={{ whiteSpace: 'normal' }}>
                  <Typography.Text>{title}</Typography.Text>
                  <Typography.Text type="secondary" style={{ fontSize: 12 }}>
                    {description}
                  </Typography.Text>
                </Box>
              </Select.Option>
            )
          })}
        </Select>
      </Tooltip>
    )
  },
)
