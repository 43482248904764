import React from 'react'

import { useHistory, useLocation } from 'react-router-dom'

import { Box } from 'common/components/boxes'
import { Page } from 'common/components/Page'
import { PersistentFiltersProvider } from 'common/contexts/persistent_filters'

import { useStores } from 'contractor/hooks/use-stores'
import { CreateInvoiceAction } from 'contractor/pages/Invoices/create_invoice_actions'

import { InvoiceInboxes } from './tabs/Inboxes/invoice_inboxes_page'
import { InvoiceTab } from './tabs/List/invoice_tab'
import { ToReview } from './tabs/ToReview'

export function InvoicesPageV2() {
  const { userStore, invoiceStore } = useStores()
  const history = useHistory()
  const location = useLocation()

  const tabItems = React.useMemo(() => {
    const items = [
      {
        label: 'To Review',
        key: 'to_review',
        children: <ToReview />,
      },
    ]

    if (userStore.canViewAllInvoices || userStore.canViewAllMyProjectInvoices) {
      items.push({
        label: 'All Invoices',
        key: 'invoices',
        children: <InvoiceTab />,
      })
    }

    if (userStore.canViewInvoiceInbox) {
      items.push({
        label: 'Inbox',
        key: 'invoice_inboxes',
        children: <InvoiceInboxes />,
      })
    }

    return items
  }, [userStore.canViewAllInvoices, userStore.canViewInvoiceInbox, userStore.canViewAllMyProjectInvoices])

  function handleChangeTab(key: 'invoice' | 'to_review' | 'invoice_inboxes') {
    const searchParams = new URLSearchParams(location.search)
    searchParams.set('tab', key)

    history.push({
      search: searchParams.toString(),
    })
  }

  const activeKey = new URLSearchParams(location.search).get('tab')

  return (
    <PersistentFiltersProvider
      ignoreOnFilterCounter={['tab']}
      ignoreQueryParams={['tab']}
      listStore={invoiceStore.listStore}
    >
      <Page>
        <Page.Tabs
          destroyInactiveTabPane
          activeKey={activeKey || 'to_review'}
          items={tabItems}
          onChange={handleChangeTab}
          tabBarExtraContent={
            <Box mr={4}>
              <CreateInvoiceAction />
            </Box>
          }
        />
      </Page>
    </PersistentFiltersProvider>
  )
}
