import React, { useRef, useState } from 'react'

import { flatten } from 'lodash'

import styled from '@emotion/styled'

import { Button, Typography } from 'antd'

import { observer } from 'mobx-react-lite'

import { Box } from 'common/components/boxes'
import { Drawer, DrawerRef } from 'common/components/Drawer'
import DeliveryState from 'common/components/statuses/delivery_state'
import { formatDateStringShort } from 'common/helpers/formatters'

import { DeliveryDetail } from 'contractor/components/DeliveryDetail'
import { useStores } from 'contractor/hooks/use-stores'

const ListItem = styled(Box)`
  cursor: pointer;
  transition: background 0.3s;

  &:hover {
    background-color: ${({ theme }) => theme.colors['gray-2']};
  }

  &:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.colors['gray-4']};
  }
`

const DeliveriesDrawer = () => {
  const { invoiceStore, deliveryStore } = useStores()

  const [currentDeliveryId, setCurrentDeliveryId] = useState(null)

  const deliveriesRef = useRef<DrawerRef>()
  const deliveryDetailRef = useRef<DrawerRef>()

  const handleUpdateDelivery = async (delivery) => {
    await deliveryStore.updateDelivery(delivery)
    invoiceStore.selectOrderIgnoringCache(delivery.order.id)
  }

  const allDeliveries = flatten(
    invoiceStore.selectedOrders?.map(({ order }) => order.deliveries.map((delivery) => ({ ...delivery, order }))),
  )

  const currentDelivery = allDeliveries.find((delivery) => delivery.id === currentDeliveryId)

  return (
    <>
      <Button data-cy="show_delivery_drawer" onClick={() => deliveriesRef.current?.show()}>
        Delivery Details
      </Button>

      <Drawer title="Deliveries" ref={deliveriesRef} width={378} closable={false}>
        <Box width="100%" overflowY="auto" p={12}>
          {allDeliveries.map((delivery) => {
            const date = formatDateStringShort(delivery.best_guess_delivered_at)

            return (
              <ListItem
                key={delivery.id}
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                onClick={() => {
                  deliveryDetailRef.current?.show()
                  setCurrentDeliveryId(delivery.id)
                }}
                px={8}
                py={12}
              >
                <Typography.Link>
                  {date ? `${delivery.order.order_number}:${date}` : delivery.order.order_number}
                </Typography.Link>
                <DeliveryState state={delivery.state} style={{ margin: 0 }} />
              </ListItem>
            )
          })}
        </Box>

        <DeliveryDetail
          ref={deliveryDetailRef}
          onClose={() => setCurrentDeliveryId(null)}
          orderDelivery={currentDelivery}
          project_ids={[currentDelivery?.order?.project_id]}
          orderInfo={currentDelivery?.order}
          onUpdate={handleUpdateDelivery}
        />
      </Drawer>
    </>
  )
}

export const DeliveryButton = observer(() => {
  const { invoiceStore, deliveryStore, userStore } = useStores()

  const deliveryDetailRef = useRef<DrawerRef>()

  const firstInvoiceOrder = [...invoiceStore.selectedOrders]?.[0]?.order

  const handleUpdateDelivery = async (delivery) => {
    await deliveryStore.updateDelivery(delivery)
    invoiceStore.selectOrderIgnoringCache(firstInvoiceOrder.id)
  }

  const hasOnlyOneOrderAttached = invoiceStore.selectedOrders?.length === 1
  const hasOnlyOneDeliveryAttached = firstInvoiceOrder?.deliveries?.length === 1

  const hasMoreThanOneOrderAttached = invoiceStore.selectedOrders?.length > 1
  const hasMoreThanOneDeliveryAttached = firstInvoiceOrder?.deliveries?.length > 1

  const delivery = firstInvoiceOrder?.deliveries[0]

  if (hasOnlyOneOrderAttached && hasOnlyOneDeliveryAttached && userStore.canEditDeliveryInformation) {
    return (
      <>
        <Button onClick={() => deliveryDetailRef.current?.show()}>
          1 {delivery.is_pick_up ? 'Pick Up' : 'Delivery'}
        </Button>
        <DeliveryDetail
          ref={deliveryDetailRef}
          onClose={() => deliveryDetailRef.current?.close()}
          orderDelivery={delivery}
          project_ids={[firstInvoiceOrder.project_id]}
          orderInfo={firstInvoiceOrder}
          onUpdate={handleUpdateDelivery}
        />
      </>
    )
  }

  if ((hasMoreThanOneOrderAttached || hasMoreThanOneDeliveryAttached) && userStore.canEditDeliveryInformation) {
    return <DeliveriesDrawer />
  }

  return null
})
