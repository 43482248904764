import React, { useState } from 'react'

import { EllipsisOutlined } from '@ant-design/icons'
import { Button, Dropdown, Popconfirm } from 'antd'

import { Box } from 'common/components/boxes'
import { Events, trackEvent } from 'common/pendo/event_tracking'

import { useStores } from 'contractor/hooks/use-stores'
import { insertIf } from 'contractor/pages/Invoices/Detail/invoice_detail_extract'
import { CommitmentMaterial } from 'contractor/server/commitments'

interface ActionsColumnProps {
  disabled?: boolean
  commitmentSubmitted?: boolean
  commitmentMaterial: CommitmentMaterial
  onRemove: (material: CommitmentMaterial) => void
  onChange: (value: CommitmentMaterial, changes: CommitmentMaterial) => void
}

export const ActionsColumn = (props: ActionsColumnProps) => {
  const [open, setOpen] = useState(false)
  const { commitmentMaterial, disabled, onRemove, onChange } = props
  const { userStore } = useStores()
  const source = window.location.pathname

  const handleRemove = () => {
    onRemove(commitmentMaterial)
    setOpen(false)
  }

  const handleUnlock = () => {
    onChange(commitmentMaterial, { ...commitmentMaterial, locked: false })
  }

  if (disabled || !userStore?.canEditAndDeleteCommitments) return null

  return (
    <Dropdown
      menu={{
        items: [
          {
            key: 'delete',
            label: (
              <Popconfirm
                onConfirm={() => {
                  handleRemove()
                  trackEvent(Events.CLICK_COMMITMENT_MATERIAL_DELETE, source)
                }}
                onCancel={() => setOpen(false)}
                title="Are you sure to delete this item?"
                okText="Yes"
                cancelText="No"
                placement="right"
                overlayStyle={{ marginLeft: 27 }}
              >
                <span>Delete</span>
              </Popconfirm>
            ),
            danger: true,
          },
          ...insertIf(commitmentMaterial.locked, {
            key: 'unlock',
            label: (
              <Box
                data-cy="unlock-material"
                onClick={() => {
                  trackEvent(Events.CLICK_COMMITMENT_MATERIAL_UNLOCK, source)
                  handleUnlock()
                }}
              >
                Unlock
              </Box>
            ),
          }),
        ],
      }}
      trigger={['click']}
      onOpenChange={setOpen}
      open={open}
      destroyPopupOnHide
    >
      <Button icon={<EllipsisOutlined />} size="small" />
    </Dropdown>
  )
}
