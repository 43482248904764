import React from 'react'

import moment from 'moment'

import { WarningOutlined } from '@ant-design/icons'
import { Popover, Typography } from 'antd'

import { OrderMaterial } from 'common/server/orders'

import { Box, FlexBoxX, FlexBoxY } from '../../boxes'

type DeliveryIssuesProps = {
  orderMaterial: OrderMaterial
}

export const DeliveryIssues = ({ orderMaterial }: DeliveryIssuesProps) => {
  return (
    <FlexBoxX style={{ minHeight: 32, marginLeft: 8 }}>
      <Popover
        title="Reported"
        content={
          <FlexBoxY>
            {orderMaterial.missing_quantity > 0 && (
              <Box width="100%">
                <Typography.Text>Missing quantity: {Number(orderMaterial.missing_quantity)}</Typography.Text>
              </Box>
            )}
            {orderMaterial.broken_quantity > 0 && (
              <Box width="100%">
                <Typography.Text>Broken quantity: {Number(orderMaterial.broken_quantity)}</Typography.Text>
              </Box>
            )}
            {orderMaterial.incorrect_quantity > 0 && (
              <Box width="100%">
                <Typography.Text>Incorrect quantity: {Number(orderMaterial.incorrect_quantity)}</Typography.Text>
              </Box>
            )}
            {orderMaterial.back_ordered_quantity > 0 && (
              <Box width="100%">
                <Typography.Text>Backordered quantity: {Number(orderMaterial.back_ordered_quantity)}</Typography.Text>
              </Box>
            )}
            {orderMaterial.expected_back_ordered_date && (
              <Box width="100%">
                <Typography.Text>
                  Expected backordered date: {moment(orderMaterial.expected_back_ordered_date).format('YYYY-MM-DD')}
                </Typography.Text>
              </Box>
            )}
          </FlexBoxY>
        }
      >
        <WarningOutlined style={{ color: 'red' }} />
      </Popover>
    </FlexBoxX>
  )
}
