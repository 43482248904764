import React, { useState } from 'react'

import { LockOutlined } from '@ant-design/icons'
import { Comment, Typography, Tooltip, Tabs, message } from 'antd'

import { observer } from 'mobx-react-lite'

import { Box } from 'common/components/boxes'
import { Card } from 'common/components/Card'
import { ErrorBoundary } from 'common/components/ErrorBoundary/error_boundary'
import { OrderHistory } from 'common/components/OrderHistory'
import { noticeError } from 'common/helpers/new_relic'
import { SUPPORT_EMAIL_ADDRESS } from 'common/helpers/other'
import { useQuery } from 'common/hooks/use-query'
import { OrderStates } from 'common/server/server_types'

import { Marker, Mention, makeMentionTag, extractMentions } from 'contractor/components/InternalComments'
import { useStores } from 'contractor/hooks/use-stores'

const InternalCommentTitle = () => (
  <Box display="inline-flex" alignItems="center">
    <Typography.Text strong style={{ marginRight: 12 }}>
      Internal Comments
    </Typography.Text>
    <Tooltip title="All comments on this page are internal and never visible to any of your vendors.">
      <LockOutlined />
    </Tooltip>
  </Box>
)

export const InternalComments = observer(() => {
  const { orderStore, companySettingStore, userStore } = useStores()

  useQuery(companySettingStore.maybeIndexUsers)

  const [loading, setLoading] = useState(false)
  const [activeKey, setActiveKey] = useState('attachments')

  const orderPackageIdParam = new URLSearchParams(location.search).get('order_package_id')

  const getUserIdsFromMentions = (text) => {
    const mentions = extractMentions(text)
    const mentionedUsers = companySettingStore.possibleUsers.filter((user) => {
      const mention = makeMentionTag(user)
      return mentions.includes(mention)
    })
    return mentionedUsers.map((user) => user.id)
  }

  const handleLoadHistory = async () => {
    try {
      setLoading(true)
      await orderStore.getOrderHistory(orderStore.selectedOrder?.id)
    } catch (error) {
      noticeError(error, { entry: 'contractor-draft-history' })
      message.error(`Unable to load history. Please contact ${SUPPORT_EMAIL_ADDRESS} for further assistance.`)
    } finally {
      setLoading(false)
    }
  }

  const commentComponent = (
    <Comment
      avatar={<Marker orderState={OrderStates.REQUESTED} />}
      content={
        <Mention
          onChange={(text) => orderStore.updateInternalComment({ text, userIds: getUserIdsFromMentions(text) })}
          value={orderStore.newInternalComment.text}
          users={companySettingStore.possibleUsers}
        />
      }
    />
  )

  if (!orderPackageIdParam) {
    return (
      <Card title={<InternalCommentTitle />} mt={16}>
        {commentComponent}
      </Card>
    )
  }

  return (
    <Card contentProps={{ p: '0 16px 16px 16px' }} mt={16}>
      <Tabs
        activeKey={activeKey}
        onChange={(key) => {
          setActiveKey(key)
          if (key === 'history') {
            handleLoadHistory()
          }
        }}
        items={[
          {
            label: <InternalCommentTitle />,
            key: 'attachments',
            children: commentComponent,
          },
          {
            label: 'History',
            key: 'history',
            children: (
              <ErrorBoundary isFull={false}>
                <OrderHistory
                  isLoading={loading}
                  data={orderStore.orderHistory}
                  companyAttributes={companySettingStore.companyMaterialConfiguration.company_attributes}
                  canUseIntegrations={userStore.canUseIntegrations}
                />
              </ErrorBoundary>
            ),
          },
        ]}
      />
    </Card>
  )
})
