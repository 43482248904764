import React from 'react'

import { Typography } from 'antd'

import { Box } from 'common/components/boxes'

import { useStores } from 'contractor/hooks/use-stores'

import { Download } from './download'

export const CommitmentDownload = () => {
  const {
    commitmentStore: { listStore },
  } = useStores()

  return (
    <Box p={12} display="flex" flexDirection="column">
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography.Title level={5}>Download Orders</Typography.Title>
        <Download onLoadData={listStore.fetchAllRecords} />
      </Box>
    </Box>
  )
}
