import React, { useEffect, useState } from 'react'

import { useLocation } from 'react-router-dom'

import { get } from 'lodash'

import { Input, message, Modal, Form } from 'antd'

import { Box } from 'common/components/boxes'
import { Events, trackEvent } from 'common/pendo/event_tracking'
import { OrderStates } from 'common/server/server_types'

export const UpdatesMappings = {}
UpdatesMappings[OrderStates.QUOTED] = {
  actionText: 'Quick Comment',
  messageText: 'Hey, I just wanted to check in on my RFQ. When do you think you can get back to me?',
}
UpdatesMappings[OrderStates.ORDERED] = {
  actionText: 'Quick Comment',
  messageText: 'Hey, I just wanted to check in on my order to see if you have any updates?',
}
UpdatesMappings[OrderStates.SHIPPED] = {
  actionText: 'Quick Comment',
  messageText: 'Hey, I just wanted to check in about my shipments to see if you have any updates?',
}
UpdatesMappings[OrderStates.PARTIALLY_SHIPPED] = {
  actionText: 'Quick Comment',
  messageText: 'Hey, I just wanted to check in about my shipments to see if you have any updates?',
}
UpdatesMappings[OrderStates.DELIVERED] = {
  actionText: 'Quick Comment',
  messageText: '',
}
UpdatesMappings[OrderStates.CANCELLED] = {
  actionText: 'Quick Comment',
  messageText: '',
}

interface SubmitParams {
  id: string
  comment: string
}

type OrderQuickCommentModalProps = {
  orderState: OrderStates
  vendorName: string
  orderId: string
  onSubmit: (params: SubmitParams) => Promise<void>
  onCancel: () => void
  visible: boolean
  hasDefaultMessage?: boolean
}

export const OrderQuickCommentModal = ({
  orderState,
  vendorName,
  orderId,
  onSubmit,
  onCancel,
  visible,
  hasDefaultMessage = false,
}: OrderQuickCommentModalProps) => {
  const location = useLocation()
  const source = location.pathname.substr(1)
  const [form] = Form.useForm()
  const [isSubmitting, setSubmitting] = useState(false)

  useEffect(() => {
    if (hasDefaultMessage) {
      const defaultComment = get(UpdatesMappings, `${orderState}.messageText`, '')
      form.setFieldsValue({ comment: defaultComment })
    }
  }, [orderId, hasDefaultMessage])

  useEffect(() => {
    if (!visible) {
      form.resetFields()
    }
  }, [visible])

  const handleOk = () => {
    form.submit()
  }

  const handleFinish = async ({ comment }) => {
    setSubmitting(true)
    try {
      await onSubmit({ id: orderId, comment })
      message.success(`Requested update from ${vendorName}`)
    } catch (error) {
      console.error('Error requesting update from vendor', error)
      message.error('Error - Unable to request update')
    } finally {
      setSubmitting(false)
      trackEvent(Events.QUICK_COMMENT, source)
      onCancel()
    }
  }

  return (
    <Modal
      title={`Request update from ${vendorName}`}
      open={visible}
      onOk={handleOk}
      onCancel={onCancel}
      okButtonProps={{
        loading: isSubmitting,
      }}
      cancelButtonProps={{
        loading: isSubmitting,
      }}
      forceRender
    >
      <Box width="100%" height="100%">
        <Form onFinish={handleFinish} form={form}>
          <Form.Item name="comment" rules={[{ required: true, message: 'Please input a message!' }]}>
            <Input.TextArea rows={6} placeholder="Add a message for your vendor" style={{ resize: 'none' }} />
          </Form.Item>
        </Form>
      </Box>
    </Modal>
  )
}
