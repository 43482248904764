import React, { useState } from 'react'

import styled from '@emotion/styled'

import { InfoCircleOutlined } from '@ant-design/icons'
import { Typography, Tooltip, notification, Modal, Input } from 'antd'

import { useStores } from 'contractor/hooks/use-stores'

const Source = styled(Typography.Paragraph)`
  transition: color 0.3s;
  &:hover {
    color: ${({ theme }) => theme.colors.highlightBlue};
  }
`

type IssueActionProps = { invoiceId: string }

export const IssueAction = ({ invoiceId }: IssueActionProps) => {
  const { invoiceStore } = useStores()

  const [openModal, setOpenModal] = useState(false)
  const [issueMessage, setIssueMessage] = useState('')

  const handleSendIssue = async () => {
    try {
      await invoiceStore.reportIssue(invoiceId, { issue_message: issueMessage })
      notification.success({
        message: 'Thanks for your issue report!\n Our team will take care of it ASAP.',
        placement: 'bottomLeft',
      })
      setIssueMessage('')
    } catch {
      notification.error({
        message: 'Error while sending the issue, try again later',
        placement: 'bottomLeft',
      })
    } finally {
      setOpenModal(false)
    }
  }

  return (
    <>
      <Tooltip title="Click here and let us know!">
        <Source style={{ margin: 0 }} onClick={() => setOpenModal(true)}>
          Something extracted incorrectly?&nbsp;
          <InfoCircleOutlined />
        </Source>
      </Tooltip>

      <Modal
        title="Describe the issue here and hit OK"
        open={openModal}
        onCancel={() => setOpenModal(false)}
        okButtonProps={{ onClick: () => handleSendIssue(), disabled: issueMessage.trim().length === 0 }}
      >
        <Input.TextArea
          value={issueMessage}
          onChange={(e) => setIssueMessage(e.target.value)}
          showCount
          maxLength={255}
          style={{ height: 120, resize: 'none' }}
        />
      </Modal>
    </>
  )
}
