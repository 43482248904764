import React from 'react'

import { Button } from 'antd'
import { ButtonProps } from 'antd/lib/button'

import { Events, trackEvent } from 'common/pendo/event_tracking'

type ButtonToggleSpreadsheetProps = { isSpreadsheetMode: boolean; disabled?: boolean } & ButtonProps

export const ButtonToggleSpreadsheet = ({
  isSpreadsheetMode,
  onClick,
  disabled,
  ...props
}: ButtonToggleSpreadsheetProps) => {
  const handleClick = (e) => {
    trackEvent(Events.TOGGLE_SPREADSHEET, window.location.pathname, {
      is_spreadsheet_mode: isSpreadsheetMode,
    })
    onClick?.(e)
  }

  return (
    <Button disabled={disabled} type="dashed" style={{ marginTop: 24 }} onClick={handleClick} {...props}>
      {isSpreadsheetMode ? 'Exit Spreadsheet Mode' : 'Spreadsheet Mode'}
    </Button>
  )
}
