import React from 'react'

import { observer } from 'mobx-react-lite'

import Download from 'common/components/table/download'
import { formatDateStringShort } from 'common/helpers/formatters'

import { useFlag } from 'contractor/hooks/use-flag'
import { useStores } from 'contractor/hooks/use-stores'

interface DownloadProps {
  filters: string
}

const DownloadButton = observer<DownloadProps>(({ filters }) => {
  const { orderStore } = useStores()

  const externalVendorIdEnabled = useFlag('external_vendor_id')

  const headers = [
    { label: 'Project Name', key: 'project_name' },
    { label: 'Project ID', key: 'project_identifier' },
    { label: 'Order Number', key: 'order_number' },
    { label: 'Order Name', key: 'name' },
    { label: 'Created At', key: 'created_at' },
    { label: 'Updated At', key: 'updated_at' },
    { label: 'Delivery State', key: 'delivery_state' },
    { label: 'Delivery Date', key: 'delivery_date' },
    { label: 'Vendor', key: 'vendor_name' },
    { label: 'Status', key: 'state' },
    { label: 'Sub Status', key: 'sub_state' },
    { label: 'Ordered By', key: 'ordered_by_column' },
    { label: 'Requested by', key: 'requested_by' },
    { label: 'Ordered At', key: 'ordered_at' },
    { label: 'Grand Total', key: 'grand_total' },
  ]

  const transformData = (hit) => {
    hit['created_at'] = formatDateStringShort(hit['created_at'])
    hit['updated_at'] = formatDateStringShort(hit['updated_at'])
    hit['ordered_at'] = formatDateStringShort(hit['ordered_at'])
    hit['delivery_state'] = hit['deliveries'].map((delivery) => delivery.state)
    hit['delivery_date'] = hit['deliveries'].map((delivery) => formatDateStringShort(delivery.requested_delivered_at))
    hit['deliveries'].map((delivery) => {
      delivery['requested_delivered_at'] = formatDateStringShort(delivery['requested_delivered_at'])
    })
    hit['company_vendor'] = hit['company_vendor'] || {}

    const vendorName = hit['company_vendor']?.['safe_globalized_vendor_name']

    if (externalVendorIdEnabled) {
      hit['vendor_name'] = hit['company_vendor']?.['external_vendor_id'] || vendorName
    } else {
      hit['vendor_name'] = vendorName
    }
  }

  return (
    <Download
      filters={filters}
      store={orderStore}
      indexName={orderStore.searchState['sortBy'] || orderStore.searchKey.index_name}
      headers={headers}
      transformData={transformData}
      title={'Orders'}
      file_name={'orders'}
    />
  )
})

export default DownloadButton
