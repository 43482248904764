import React from 'react'

import { Button } from 'antd'

import { Events, trackEvent } from 'common/pendo/event_tracking'

type PlaceOrderButtonProps = {
  isLoading: boolean
  disabled: boolean
  handleSubmit: () => void
}

export const SubmitCommitmentButton = (props: PlaceOrderButtonProps) => {
  const { isLoading, disabled, handleSubmit } = props
  const source = window.location.pathname

  return (
    <Button
      data-cy="submit-commitment"
      type="primary"
      onClick={() => {
        handleSubmit()
        trackEvent(Events.CLICK_SUBMIT_COMMITMENT, source)
      }}
      loading={isLoading}
      disabled={disabled}
      style={{ marginLeft: 8 }}
    >
      Submit
    </Button>
  )
}
