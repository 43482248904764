import React, { useEffect } from 'react'

import { Redirect, Route, Switch } from 'react-router-dom'

import { observer } from 'mobx-react-lite'

import { Layout } from 'common/components/Layout'
import { Loading } from 'common/components/Loading'
import { useQuery } from 'common/hooks/use-query'
import { useReloadIdleUser } from 'common/hooks/use-reload-idle-user'

import MainMenu from 'contractor/components/MainMenu/index'
import { useFlag } from 'contractor/hooks/use-flag'
import { useStores } from 'contractor/hooks/use-stores'
import { CommitmentDetails } from 'contractor/pages/@v2/Commitments/CommitmentDetail/commitment_detail'
import { CommitmentsPage } from 'contractor/pages/@v2/Commitments/CommitmentsList/commitments_page'
import { New } from 'contractor/pages/@v2/Commitments/NewCommitment/new_commitment'
import { InvoicesPageV2 } from 'contractor/pages/@v2/Invoices/invoices'
import { OrdersMaterialPageV2 } from 'contractor/pages/@v2/OrderMaterials/order_materials_page'
import { OrdersV2 } from 'contractor/pages/@v2/Orders'
import Calendar from 'contractor/pages/Calendar/index'
import CompanyMaterial from 'contractor/pages/CompanyMaterials/index'
import CompanySettings from 'contractor/pages/CompanySettings/index'
import CompanyVendor from 'contractor/pages/CompanyVendorContacts/index'
import CompareOrders from 'contractor/pages/CompareOrders/compare_orders'
import Dashboard from 'contractor/pages/Dashboard/index'
import Integrations from 'contractor/pages/Integrations'
import InvoicesPage from 'contractor/pages/Invoices'
import InvoiceDetail from 'contractor/pages/Invoices/Detail'
import Materials from 'contractor/pages/Materials/index'
import NewQuote from 'contractor/pages/NewQuote/index'
import OrderDetail from 'contractor/pages/OrderDetail/index'
import Orders from 'contractor/pages/Orders/index'
import PrivateOrderForm from 'contractor/pages/PrivateOrderForm/index'
import ProjectsPage from 'contractor/pages/Projects'
import ProjectSettings from 'contractor/pages/ProjectSettings/index'
import SubscriptionPricingTable from 'contractor/pages/SubscriptionPricingTable/index'
import ContractorUserSettings from 'contractor/pages/UserSettings/index'
import { AllProviders } from 'contractor/providers'

// Disabled antd form console warning
window['ASYNC_VALIDATOR_NO_WARNING'] = 1

export const MaterialRequesterRoutes = () => {
  const { companySettingStore, userStore } = useStores()

  useQuery(companySettingStore.indexPermissions)

  const invoicePermissions = companySettingStore?.permissions?.invoices_permissions?.MATERIAL_REQUESTER

  const canUseConsolidatedOrders = useFlag('consolidated_orders')
  const canUseConsolidatedInvoices = useFlag('consolidated_invoices')
  const canUseCommitments = useFlag('commitments')

  const canUseInvoices = () => {
    if (invoicePermissions) {
      return Object.values(invoicePermissions).some((permission) => permission === true)
    }
  }

  return (
    <Switch>
      <Route path="/order/:id">
        <OrderDetail />
      </Route>
      {canUseCommitments && (
        <Route path="/commitment/:id">
          <CommitmentDetails />
        </Route>
      )}
      {canUseCommitments && (
        <Route path="/commitments/new">
          <New />
        </Route>
      )}
      <Route path="/orders/new_order">
        <NewQuote orderType="Order" />
      </Route>
      {userStore?.canUseCreateRFQ && (
        <Route path="/orders/new_quote">
          <NewQuote orderType="RFQ" />
        </Route>
      )}
      {canUseInvoices() && (
        <Route path="/invoices">
          <Route path="/invoices">{canUseConsolidatedInvoices ? <InvoicesPageV2 /> : <InvoicesPage />}</Route>
        </Route>
      )}
      {(userStore.canCreateNewMaterial || userStore.canEditMaterialDatabase) && (
        <Route path="/company_materials">
          <CompanyMaterial />
        </Route>
      )}
      {userStore.canManageVendors && (
        <Route path="/company_vendors">
          <CompanyVendor />
        </Route>
      )}
      <Route path="/new_order/*">
        <PrivateOrderForm />
      </Route>
      <Route path="/orders">{canUseConsolidatedOrders ? <OrdersV2 /> : <Orders />}</Route>
      <Route path="/user_settings">
        <ContractorUserSettings />
      </Route>
      <Route path="*">
        <Redirect to="/orders" />
      </Route>
    </Switch>
  )
}

const Routes = observer(() => {
  useReloadIdleUser()

  const { userStore, companySettingStore, notificationStore } = useStores()
  const canUseConsolidatedInvoices = useFlag('consolidated_invoices')
  const canUseConsolidatedOrders = useFlag('consolidated_orders')
  const canUseConsolidatedOrdersMaterials = useFlag('consolidated_orders_materials')

  const canUseCommitments = useFlag('commitments') && userStore.canSeeAndCreateCommitments

  const { isLoading } = useQuery(userStore.showUser)
  const { isLoading: isLoadingSettings } = useQuery(companySettingStore.indexOtherSettings)

  useEffect(() => {
    if (userStore.currentUser?.id) {
      notificationStore.getIndexNotifications()
      notificationStore.subscribe(userStore.currentUser?.id)
    }
  }, [userStore.currentUser?.id])

  if (isLoading || isLoadingSettings) {
    return <Loading />
  }

  if (!userStore.currentUser) {
    return
  }

  if (userStore.isMaterialRequester) {
    return <MaterialRequesterRoutes />
  }

  return (
    <Switch>
      <Route path="/dashboard">
        <Dashboard />
      </Route>
      <Route path="/projects">
        <ProjectsPage />
      </Route>
      <Route path="/order/:id">
        <OrderDetail />
      </Route>
      {canUseCommitments && (
        <Route path="/commitment/:id">
          <CommitmentDetails />
        </Route>
      )}
      {canUseCommitments && (
        <Route path="/commitments/new">
          <New />
        </Route>
      )}
      {userStore.cannotSendAndUpdateOrders && (
        <Route path="/new_order/*">
          <PrivateOrderForm />
        </Route>
      )}
      {userStore.canUseRfqLeveling && (
        <Route path="/order_package/:id">
          <CompareOrders />
        </Route>
      )}
      {userStore.canUseCreateRFQ && (
        <Route path="/orders/new_quote">
          <NewQuote orderType="RFQ" />
        </Route>
      )}
      {userStore.canUseMaterialsList && (
        <Route path="/materials">{canUseConsolidatedOrdersMaterials ? <OrdersMaterialPageV2 /> : <Materials />}</Route>
      )}
      <Route path="/orders/new_order">
        <NewQuote orderType="Order" />
      </Route>
      <Route path="/orders">{canUseConsolidatedOrders ? <OrdersV2 /> : <Orders />}</Route>
      <Route path="/calendar">
        <Calendar />
      </Route>
      <Route path="/company_materials">
        <CompanyMaterial />
      </Route>
      <Route path="/company_vendors">
        <CompanyVendor />
      </Route>
      <Route path="/company_settings">
        <CompanySettings />
      </Route>
      {userStore.canUseIntegrations && (
        <Route path="/integrations">
          <Integrations />
        </Route>
      )}
      <Route path="/user_settings">
        <ContractorUserSettings />
      </Route>
      <Route path="/billing/pricing_table">
        <SubscriptionPricingTable />
      </Route>
      <Route path="/project/:id">
        <ProjectSettings />
      </Route>
      {userStore.canUseInvoices && (
        <Route path="/invoices">{canUseConsolidatedInvoices ? <InvoicesPageV2 /> : <InvoicesPage />}</Route>
      )}
      {userStore.canUseInvoices && (
        <Route path="/invoice/:id">
          <InvoiceDetail />
        </Route>
      )}
      {canUseCommitments && (
        <Route path="/commitments">
          <CommitmentsPage />
        </Route>
      )}
      <Route path="/" exact>
        <Dashboard />
      </Route>
    </Switch>
  )
})

const Application = () => {
  return (
    <AllProviders>
      <Layout header={<MainMenu />}>
        <Routes />
      </Layout>
    </AllProviders>
  )
}

export default Application
