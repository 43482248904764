import React from 'react'

import styled from '@emotion/styled'

import { Table, Tooltip, Typography } from 'antd'

import { currencyFormatter } from 'common/helpers/formatters'

import { ExpandButton } from 'contractor/pages/Invoices/Detail/InvoiceMaterials/expand_button'
import { ShowOrderResponse } from 'contractor/server/orders'

type TableCostsProps = {
  expandedRowKeys: React.Key[]
  onToggleExpandRowKeys: () => void
  setExpandedRowKeys: (keys: React.Key[]) => void
  order: ShowOrderResponse
}

const getDeliveriesCostByField = (deliveries = [], field) => {
  const value = deliveries.reduce((acc, delivery) => acc + Number(delivery[field]), 0)

  return value
}

const TableStyled = styled(Table)`
  .ant-table-cell {
    background: #fdfdfd;
  }
`

const getInvoices = (invoices, field) => {
  return invoices
    .map((invoice) => {
      const value = Number(invoice[field])
      if (!value) {
        return null
      }

      const invoiceNumber = invoice?.number

      return {
        invoiceId: invoice.id,
        invoiceNumber,
        invoicedCost: value,
      }
    })
    ?.filter(Boolean)
}

export const TableCosts = (props: TableCostsProps) => {
  const { expandedRowKeys, onToggleExpandRowKeys, setExpandedRowKeys, order } = props

  const deliveries = order?.deliveries || []
  const invoices = order?.invoices || []

  const taxValue = getDeliveriesCostByField(deliveries, 'tax_value')
  const shippingValue = getDeliveriesCostByField(deliveries, 'shipping_value')
  const discountValue = getDeliveriesCostByField(deliveries, 'discount_value')
  const otherValue = getDeliveriesCostByField(deliveries, 'other_value')

  const invoiceCosts = invoices?.reduce(
    (acc, invoice) => {
      acc.discountValue += Number(invoice.discount_amount || 0)
      acc.shippingValue += Number(invoice.shipping_cost || 0)
      acc.otherValue += Number(invoice.other_costs || 0)
      acc.taxValue += Number(invoice.tax_amount || 0)
      return acc
    },
    {
      discountValue: 0,
      shippingValue: 0,
      otherValue: 0,
      taxValue: 0,
    },
  )

  const dataSource = [
    ...(Number(invoiceCosts.taxValue + taxValue) !== 0
      ? [
          {
            description: 'Tax',
            invoicedCost: invoiceCosts.taxValue,
            orderedCost: taxValue,
            invoices: getInvoices(invoices, 'tax_amount'),
          },
        ]
      : []),
    ...(Number(invoiceCosts.shippingValue + shippingValue) !== 0
      ? [
          {
            description: 'Shipping',
            invoicedCost: invoiceCosts.shippingValue,
            orderedCost: shippingValue,
            invoices: getInvoices(invoices, 'shipping_cost'),
          },
        ]
      : []),
    ...(Number(invoiceCosts.discountValue + discountValue) !== 0
      ? [
          {
            description: 'Discount',
            invoicedCost: invoiceCosts.discountValue,
            orderedCost: discountValue,
            invoices: getInvoices(invoices, 'discount_amount'),
          },
        ]
      : []),
    ...(Number(invoiceCosts.otherValue + otherValue) !== 0
      ? [
          {
            description: 'Other',
            invoicedCost: invoiceCosts.otherValue,
            orderedCost: otherValue,
            invoices: getInvoices(invoices, 'other_costs'),
          },
        ]
      : []),
  ]

  type RowType = typeof dataSource[0]

  if (!dataSource?.length) {
    return null
  }

  return (
    <TableStyled
      rowKey="description"
      columns={[
        {
          dataIndex: 'description',
        },
        {
          dataIndex: 'orderedCost',
          width: 100,
          align: 'right',
          render: (orderedCost = 0) => currencyFormatter(orderedCost, 2),
        },
        {
          dataIndex: 'invoicedCost',
          width: 150,
          align: 'right',
          render: (invoicedCost = 0) => currencyFormatter(invoicedCost, 2),
        },
        {
          dataIndex: 'space',
          width: 100,
        },
        Table.EXPAND_COLUMN,
      ]}
      dataSource={dataSource}
      showHeader={false}
      pagination={false}
      size="small"
      expandable={{
        columnTitle: () => {
          if (dataSource?.some((data) => data?.invoices?.length)) {
            return (
              <ExpandButton
                onToggleExpandRowKeys={onToggleExpandRowKeys}
                hasExpandedRowKeys={!!expandedRowKeys?.length}
              />
            )
          }
        },
        onExpandedRowsChange: (expandedRows) => setExpandedRowKeys(expandedRows as React.Key[]),
        expandedRowKeys,
        expandRowByClick: true,
        expandedRowRender: (row: RowType) => (
          <Table
            showHeader={false}
            pagination={false}
            size="small"
            dataSource={row?.invoices}
            rowKey={(row) => `${row?.invoiceId}-${row?.invoiceNumber}`}
            columns={[
              {
                dataIndex: 'invoice',
                key: 'invoice',
                render: (_, row) => {
                  return (
                    <Tooltip title="Invoice number">
                      <Typography.Link
                        href={`/invoice/${row?.invoiceId}`}
                        target="blank"
                        style={{ textDecoration: 'underline' }}
                      >
                        #{row?.invoiceNumber}
                      </Typography.Link>
                    </Tooltip>
                  )
                },
              },
              {
                dataIndex: 'invoicedCost',
                key: 'invoicedCost',
                width: 150,
                align: 'right',
                render: (invoicedCost = 0) => currencyFormatter(invoicedCost, 2),
              },
              {
                dataIndex: 'space',
                key: 'space',
                width: 148,
              },
            ]}
          />
        ),
        rowExpandable: (row: RowType) => !!row?.invoices?.length,
      }}
      style={{ minWidth: 850 }}
    />
  )
}
