import axios from 'axios'

import { InvoicesStates } from 'common/server/server_types'

export interface InvoiceStates {
  id: string
  label: string
  color: string
  assigned_to_id?: string
  allowed_next_state_ids: string[]
  not_change_assignee: boolean
  project_invoice_assignee_id?: string
  position: number
  state?: InvoicesStates
}

export function index() {
  return axios.get<{ invoice_states: InvoiceStates[] }>('/invoice_states')
}

export function upsert(invoice_states: InvoiceStates[]) {
  return axios.post('/invoice_states/bulk_upsert', { invoice_states })
}
