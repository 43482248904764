import React from 'react'

import { v4 as uuidV4 } from 'uuid'

import styled from '@emotion/styled'

import { Table } from 'antd'

import { formatEnumValue } from 'common/helpers/formatters'
import { OrderHistoryResponse } from 'common/server/order_history'

import { FallbackCellItem } from './fallback_cell_item'

const Wrapper = styled.div`
  width: 100%;
  margin-top: 8px;
  .ant-table-row.update {
    background-color: #fefbe6;
  }
  .ant-table-row.create {
    background-color: #f6ffed;
  }
  .ant-table-row.delete {
    background-color: #fff1f0;
    text-decoration: line-through;
  }

  .ant-table-row:hover td {
    background: none !important;
  }
`

type OrderMaterialsProps = {
  orderMaterials: OrderHistoryResponse['order_materials']
  isOrderCreate?: boolean
  companyAttributes?: string[]
}

export const ALLOW_ORDER_MATERIALS_FIELDS = [
  'Company material',
  'Cost code',
  'Product identifier',
  'Manufacturer',
  'Unit',
  'Size',
  'Connection type',
  'Material',

  'Quantity',
  'Quantity delivered',
  'Unit cost',
  'Vendor note',
  'Deleted at',

  'Vendor response',
]

export const OrderMaterials = ({ orderMaterials, isOrderCreate, companyAttributes }: OrderMaterialsProps) => {
  const dataSource = orderMaterials
    .filter((orderMaterial) => {
      const hasChanges = Object.keys(orderMaterial?.changes).some((key) => ALLOW_ORDER_MATERIALS_FIELDS.includes(key))
      return hasChanges
    })
    .filter((orderMaterial) => {
      /*
      When an order is created we receive order materials created and receive the order materials updated,
      I think it's related to inserting automatically values like cost code and default price.
      So if is created order event we remove updated events in order materials
    */
      if (isOrderCreate) {
        return orderMaterial.event === 'create'
      }
      return true
    })
    .map((orderMaterial) => {
      const prevCompanyMaterial = orderMaterial?.previous_company_material
      return {
        companyMaterialPrev: prevCompanyMaterial?.['Description'],
        companyMaterialUnchanged: orderMaterial?.snapshot['Description'],
        companyMaterial: orderMaterial?.changes?.['Company material'],

        costCodePrev: prevCompanyMaterial?.['Cost code'],
        costCodeUnchanged: orderMaterial?.snapshot['Cost code'],
        costCode: orderMaterial?.changes?.['Cost code'],

        productIdPrev: prevCompanyMaterial?.['Product identifier'],
        productIdUnchanged: orderMaterial?.snapshot['Product identifier'],
        productId: orderMaterial?.changes?.['Product identifier'],

        manufacturerPrev: prevCompanyMaterial?.['Manufacturer'],
        manufacturerUnchanged: orderMaterial?.snapshot['Manufacturer'],
        manufacturer: orderMaterial?.changes?.['Manufacturer'],

        unitPrev: prevCompanyMaterial?.['Unit'] || prevCompanyMaterial?.['Unit name'],
        unitUnchanged: orderMaterial?.snapshot['Unit'] || orderMaterial?.snapshot['Unit name'],
        unit: orderMaterial?.changes?.['Unit'] || orderMaterial?.changes?.['Unit name'],

        sizePrev: prevCompanyMaterial?.['Size'],
        sizeUnchanged: orderMaterial?.snapshot['Size'],
        size: orderMaterial?.changes?.['Size'],

        connectionTypePrev: prevCompanyMaterial?.['Connection type'],
        connectionTypeUnchanged: orderMaterial?.snapshot['Connection type'],
        connectionType: orderMaterial?.changes?.['Connection type'],

        materialPrev: prevCompanyMaterial?.['Material'],
        material: orderMaterial?.changes?.['Material'],
        materialUnchanged: orderMaterial?.snapshot['Material'],

        quantityUnchanged: orderMaterial?.snapshot['Quantity'],
        quantity: orderMaterial?.changes?.['Quantity'],

        quantityDeliveredUnchanged: orderMaterial?.snapshot['Quantity delivered'],
        quantityDelivered: orderMaterial?.changes?.['Quantity delivered'],

        unitCostUnchanged: orderMaterial?.snapshot['Unit cost'],
        unitCost: orderMaterial?.changes?.['Unit cost'],
        vendorNoteUnchanged: orderMaterial?.snapshot['Vendor note'],
        vendorNote: orderMaterial?.changes?.['Vendor note'],
        vendorResponseUnchanged: orderMaterial?.snapshot['Vendor response'],
        vendorResponse: orderMaterial?.changes?.['Vendor response'],
        deletedAt: orderMaterial?.changes?.['Deleted at'],
        event: orderMaterial?.event,

        key: uuidV4(),
      }
    })

  if (!dataSource.length) {
    return null
  }

  return (
    <Wrapper>
      <Table
        tableLayout="auto"
        style={{ width: '100%' }}
        rowClassName={(record) => {
          if (record?.deletedAt) {
            return 'delete'
          }
          return record.event
        }}
        columns={[
          {
            title: 'Description',
            dataIndex: 'description',
            width: 200,
            render: (_, record) => {
              const nextValue = record?.companyMaterial?.[1]
              const isUnchanged =
                !record?.companyMaterial?.length || (!!nextValue && nextValue === record?.companyMaterialPrev)
              return (
                <FallbackCellItem
                  event={record.event}
                  isUnchanged={isUnchanged}
                  prev={record?.companyMaterial?.[0] || record?.companyMaterialPrev}
                  next={record?.companyMaterial?.[1] || record?.companyMaterialUnchanged}
                  unchangedValue={record?.companyMaterialUnchanged}
                />
              )
            },
          },
          {
            title: 'Quantity',
            width: 85,
            render: (_, record) => {
              return (
                <FallbackCellItem
                  minWidth={100}
                  event={record.event}
                  isUnchanged={!record?.quantity?.length}
                  prev={record?.quantity?.[0]}
                  next={record?.quantity?.[1]}
                  unchangedValue={record?.quantityUnchanged}
                />
              )
            },
          },
          {
            title: 'Quantity Delivered',
            width: 85,
            render: (_, record) => {
              return (
                <FallbackCellItem
                  minWidth={100}
                  event={record.event}
                  isUnchanged={!record?.quantityDelivered?.length}
                  prev={record?.quantityDelivered?.[0]}
                  next={record?.quantityDelivered?.[1]}
                  unchangedValue={record?.quantityDeliveredUnchanged}
                />
              )
            },
          },
          {
            title: 'Cost Code',
            dataIndex: 'cost_code_id',
            width: 100,
            render: (_, record) => {
              return (
                <FallbackCellItem
                  minWidth={115}
                  event={record.event}
                  isUnchanged={!record?.costCode?.length}
                  prev={record?.costCode?.[0]}
                  next={record?.costCode?.[1]}
                  unchangedValue={record?.costCodeUnchanged}
                />
              )
            },
          },
          {
            title: 'Product Id',
            dataIndex: 'product_identifier',
            width: 100,
            render: (_, record) => {
              const nextValue = record?.productId?.[1]
              const isUnchanged = !record?.productId?.length || (!!nextValue && nextValue === record?.productIdPrev)
              return (
                <FallbackCellItem
                  minWidth={115}
                  event={record.event}
                  isUnchanged={isUnchanged}
                  prev={record?.productId?.[0] || record?.productIdPrev}
                  next={record?.productId?.[1] || record?.productIdUnchanged}
                  unchangedValue={record?.productIdUnchanged}
                />
              )
            },
          },
          {
            title: 'Manufacturer',
            dataIndex: 'manufacturer',
            width: 110,
            render: (_, record) => {
              const nextValue = record?.manufacturer?.[1]
              const isUnchanged =
                !record?.manufacturer?.length || (!!nextValue && nextValue === record?.manufacturerPrev)
              return (
                <FallbackCellItem
                  minWidth={125}
                  event={record.event}
                  isUnchanged={isUnchanged}
                  prev={record?.manufacturer?.[0] || record?.manufacturerPrev}
                  next={record?.manufacturer?.[1] || record?.manufacturerUnchanged}
                  unchangedValue={record?.manufacturerUnchanged}
                />
              )
            },
          },
          {
            title: 'Unit',
            dataIndex: 'unit',
            width: 85,
            render: (_, record) => {
              const nextValue = record?.unit?.[1]
              const isUnchanged = !record?.unit?.length || (!!nextValue && nextValue === record?.unitPrev)
              return (
                <FallbackCellItem
                  event={record.event}
                  minWidth={100}
                  isUnchanged={isUnchanged}
                  prev={record?.unit?.[0] || record?.unitPrev}
                  next={record?.unit?.[1] || record?.unitUnchanged}
                  unchangedValue={record?.unitUnchanged}
                />
              )
            },
          },
          {
            title: 'Size',
            dataIndex: 'size',
            width: 100,
            render: (_, record) => {
              const nextValue = record?.size?.[1]
              const isUnchanged = !record?.size?.length || (!!nextValue && nextValue === record?.sizePrev)
              return (
                <FallbackCellItem
                  minWidth={115}
                  event={record.event}
                  isUnchanged={isUnchanged}
                  prev={record?.size?.[0] || record?.sizePrev}
                  next={record?.size?.[1] || record?.sizeUnchanged}
                  unchangedValue={record?.sizeUnchanged}
                />
              )
            },
          },
          {
            title: 'Connection Type',
            dataIndex: 'connection_type',
            width: 130,
            render: (_, record) => {
              const nextValue = record?.connectionType?.[1]
              const isUnchanged =
                !record?.connectionType?.length || (!!nextValue && nextValue === record?.connectionTypePrev)
              return (
                <FallbackCellItem
                  minWidth={145}
                  event={record.event}
                  isUnchanged={isUnchanged}
                  prev={record?.connectionType?.[0] || record?.connectionTypePrev}
                  next={record?.connectionType?.[1] || record?.connectionTypeUnchanged}
                  unchangedValue={record?.connectionTypeUnchanged}
                />
              )
            },
          },
          {
            title: 'Material',
            dataIndex: 'material',
            width: 100,
            render: (_, record) => {
              const nextValue = record?.material?.[1]
              const isUnchanged = !record?.material?.length || (!!nextValue && nextValue === record?.materialPrev)
              return (
                <FallbackCellItem
                  minWidth={115}
                  event={record.event}
                  isUnchanged={isUnchanged}
                  prev={record?.material?.[0] || record?.materialPrev}
                  next={record?.material?.[1] || record?.materialUnchanged}
                  unchangedValue={record?.materialUnchanged}
                />
              )
            },
          },
          {
            title: 'Unit Cost',
            width: 100,
            render: (_, record) => {
              return (
                <FallbackCellItem
                  minWidth={115}
                  event={record.event}
                  isUnchanged={!record?.unitCost?.length}
                  prev={record?.unitCost?.[0]}
                  next={record?.unitCost?.[1]}
                  unchangedValue={record?.unitCostUnchanged}
                />
              )
            },
          },
          {
            title: 'Vendor Response',
            width: 150,
            render: (_, record) => {
              return (
                <FallbackCellItem
                  minWidth={165}
                  event={record.event}
                  isUnchanged={!record?.vendorResponse?.length}
                  prev={record?.vendorResponse?.[0]?.map(formatEnumValue)?.join(', ')}
                  next={record?.vendorResponse?.[1]?.map(formatEnumValue).join(', ')}
                  unchangedValue={record?.vendorResponseUnchanged?.map(formatEnumValue)?.join(', ')}
                />
              )
            },
          },
          {
            title: 'Vendor Note',
            width: 150,
            render: (_, record) => {
              return (
                <FallbackCellItem
                  minWidth={165}
                  event={record.event}
                  isUnchanged={!record?.vendorNote?.length}
                  prev={record?.vendorNote?.[0]}
                  next={record?.vendorNote?.[1]}
                  unchangedValue={record?.vendorNoteUnchanged}
                />
              )
            },
          },
        ].filter((column) => {
          return !column?.dataIndex || companyAttributes.concat('description').includes(column?.dataIndex)
        })}
        dataSource={dataSource}
        pagination={false}
        size="small"
        scroll={{ x: 1500 }}
      />
    </Wrapper>
  )
}
