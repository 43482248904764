import React, { useRef, useState } from 'react'

import { message } from 'antd'

import { observer } from 'mobx-react-lite'

import { DrawerRef } from 'common/components/Drawer'

import { useStores } from 'contractor/hooks/use-stores'
import { CreateFailedInvoiceDrawer } from 'contractor/pages/Invoices/Add/create_failed_invoice_drawer'
import { FailedInboxPdfs, InvoicePdfSelector } from 'contractor/pages/Invoices/Inbox/Action/invoice_pdf_selector'
import { InboxDetail } from 'contractor/pages/Invoices/Inbox/Detail/inbox_detail'
import { InboxRowAction } from 'contractor/pages/Invoices/Inbox/List/list_item'
import { InvoiceInboxHit } from 'contractor/server/invoices/inbox'

export const SourceFrom = observer(() => {
  const { invoiceStore, invoiceInboxStore } = useStores()

  const detailDrawerRef = useRef<DrawerRef>()
  const pdfSelectorDrawerRef = useRef<DrawerRef>()
  const createFailedInvoiceDrawerRef = useRef<DrawerRef>()

  const [selectedActionHit, setSelectedActionHit] = useState<InvoiceInboxHit>(null)
  const [selectedPdfFiles, setSelectedPdfFiles] = useState<FailedInboxPdfs>(null)

  const invoice = invoiceStore.invoice

  const handleSelectAction = (hit: InvoiceInboxHit, action: InboxRowAction) => {
    if (action === InboxRowAction.CREATE) {
      setSelectedActionHit(hit)
      detailDrawerRef.current?.close()
      pdfSelectorDrawerRef.current?.show()
    } else if (action === InboxRowAction.IGNORE) {
      message.loading('Ignoring inbox record errors')
      invoiceInboxStore
        .ignoreFailures(hit.id)
        .then(() => {
          message.success('Marked as ignored')
          detailDrawerRef.current?.close()
        })
        .catch(() => {
          message.error('Marking record as ignored')
        })
    }
  }

  const handleClickNext = (selected: FailedInboxPdfs) => {
    setSelectedPdfFiles(selected)
    pdfSelectorDrawerRef.current?.close()
    createFailedInvoiceDrawerRef.current?.show()
  }

  return (
    <>
      {!!invoice?.invoice_inbox_id && (
        <>
          <InboxDetail
            invoiceInboxId={invoice?.invoice_source_id}
            invoiceInboxSourceType={invoice?.invoice_source_type}
            onSelectAction={handleSelectAction}
            ref={detailDrawerRef}
          />
          <InvoicePdfSelector
            inbox_id={selectedActionHit?.id}
            files={selectedActionHit?.files}
            ref={pdfSelectorDrawerRef}
            onClickNext={handleClickNext}
          />
          <CreateFailedInvoiceDrawer
            ref={createFailedInvoiceDrawerRef}
            failedInboxPdfs={selectedPdfFiles}
            onClose={() => createFailedInvoiceDrawerRef.current?.close()}
          />
        </>
      )}

      <span
        onClick={() => {
          if (!invoice?.invoice_inbox_id) return
          detailDrawerRef.current?.show()
        }}
      >
        Source From
      </span>
    </>
  )
})
