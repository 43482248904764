import React, { useState } from 'react'

import { noop } from 'lodash'

import { ArrowLeftOutlined, ProjectOutlined } from '@ant-design/icons'
import { PageHeader, Typography, Tooltip, message, Space } from 'antd'

import { observer } from 'mobx-react-lite'

import { RfqsBlockedHeader } from 'common/components/RfqsBlockedHeader'
import { getDataTestId } from 'common/test-utils'

import { InternalComments, InternalCommentsProps } from 'contractor/components/InternalComments'
import { useStores } from 'contractor/hooks/use-stores'

import { useLeveling } from '../context'
import { ExtraLeveling } from './extra_leveling'
import { ExtraSplitting } from './extra_splitting'
import { Print } from './print'

export type HeaderProps = {
  goBack: (isRefresh?: boolean) => void
}

export const Header = observer(() => {
  const { orderPackageStore, userStore } = useStores()

  const { isSplitting, ordersSession, goBack } = useLeveling()

  const [isSubmitting, setSubmitting] = useState(false)

  const { orderPackage } = orderPackageStore

  const ordersLocked = ordersSession.isBlocked

  const handleUpdatePackageName = async (name: string) => {
    if (!userStore.canManageOrders) return

    if (orderPackage?.name === name) return

    try {
      setSubmitting(true)
      await orderPackageStore.updateOrderPackage({
        id: orderPackage.id,
        name,
      })
      message.success('Order updated')
    } catch (error) {
      message.error('Unable update order')
    } finally {
      setSubmitting(false)
    }
  }

  const handleSendInternalComment = async ({ text, userIds }) => {
    setSubmitting(true)
    try {
      await orderPackageStore.sendInternalComment({ text, userIds: [...userIds, userStore.currentUser?.id] })
    } catch (error) {
      message.error('Unable to send comment')
    } finally {
      setSubmitting(false)
    }
  }

  const commentProps = {
    comments: orderPackage?.comments as InternalCommentsProps['comments'],
    onSubmit: handleSendInternalComment,
    loading: isSubmitting,
  }

  return (
    <>
      <PageHeader
        backIcon={<ArrowLeftOutlined onClick={() => goBack()} {...getDataTestId('go-back')} />}
        title={
          <Typography.Paragraph
            style={{ margin: '0 10px 0 15px' }}
            editable={
              !ordersLocked && {
                onChange: handleUpdatePackageName,
              }
            }
            {...getDataTestId('package-name')}
          >
            {orderPackage?.name}
          </Typography.Paragraph>
        }
        onBack={noop}
        extra={
          isSplitting
            ? [
                <Print key="print" />,
                <InternalComments {...commentProps} key="comment" />,
                <ExtraSplitting goBack={goBack} key="split" />,
              ]
            : [
                <Print key="print" />,
                <InternalComments {...commentProps} key="comment" />,
                <ExtraLeveling goBack={goBack} key="level" />,
              ]
        }
        style={{ padding: 0, width: '100%' }}
      />

      <Tooltip title="Project">
        <Space size="small">
          <ProjectOutlined />
          <Typography.Paragraph style={{ marginBottom: 0 }}>
            {orderPackage.orders[0]?.project?.name}
          </Typography.Paragraph>
        </Space>
      </Tooltip>

      <RfqsBlockedHeader {...ordersSession} />
    </>
  )
})
