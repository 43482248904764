import React, { useState } from 'react'

import { Button, Select, Typography } from 'antd'

import { observer } from 'mobx-react-lite'

import { Box } from 'common/components/boxes'
import { OrderMaterial } from 'common/server/orders'

import { IncompleteItemsTable } from 'contractor/components/DeliveryDetail/IncompleteItems/incomplete_items_table'

type MissingMaterialsProps = {
  onChange?: (orderMaterials: OrderMaterial[]) => void
  orderMaterials: OrderMaterial[]
  disableEditing?: boolean
}
export const IncompleteItems = observer<MissingMaterialsProps>(({ orderMaterials, onChange, disableEditing }) => {
  const makePayload = orderMaterials?.filter((orderMaterial) => orderMaterial.has_open_issue)
  const [selectedIncompleteItems, setSelectedIncompleteItems] = useState<OrderMaterial[]>(makePayload)
  const isAllSelected = orderMaterials?.length === selectedIncompleteItems?.length

  const updateIncompleteItems = (id: string, value: number, type: string) => {
    const newSelectedIncompleteItems = selectedIncompleteItems?.map((orderMaterial) => {
      if (orderMaterial.id === id) {
        return { ...orderMaterial, [type]: value }
      }
      return orderMaterial
    })
    onChange(newSelectedIncompleteItems)
    setSelectedIncompleteItems(newSelectedIncompleteItems)
  }

  const markItemAsResolved = (id: string) => {
    const newSelectedIncompleteItems = selectedIncompleteItems?.map((orderMaterial) => {
      if (orderMaterial.id === id) {
        return { ...orderMaterial, resolved: true }
      }
      return orderMaterial
    })
    onChange(newSelectedIncompleteItems)
    setSelectedIncompleteItems(newSelectedIncompleteItems)
  }

  const markAllItemsAsResolved = () => {
    const newOrderMaterials = orderMaterials.map((orderMaterial) => {
      return { ...orderMaterial, resolved: true }
    })
    onChange(newOrderMaterials)
    setSelectedIncompleteItems([])
  }

  const selectAllItems = () => {
    setSelectedIncompleteItems(orderMaterials)
    onChange(orderMaterials)
  }

  const unResolvedItems = selectedIncompleteItems?.filter((orderMaterial) => !orderMaterial.resolved)

  return (
    <>
      <Box display="flex" alignItems="center">
        <Select
          mode="multiple"
          value={unResolvedItems?.map((orderMaterial) => orderMaterial.id)}
          showSearch
          filterOption={(input, option) => {
            const inputValue = input.toLowerCase()
            return option?.searchable?.toLowerCase().includes(inputValue)
          }}
          data-cy="incomplete-items-select"
          style={{ flex: 1 }}
          placeholder="Select which items have issues"
          maxTagCount="responsive"
          allowClear
          onClear={() => {
            markAllItemsAsResolved()
          }}
          onDeselect={(value) => {
            const removedItemId = selectedIncompleteItems.find((item) => !value.includes(item.id))?.id
            const newSelectedItems = selectedIncompleteItems.filter((item) => item.id !== removedItemId)
            setSelectedIncompleteItems(newSelectedItems)
            onChange(newSelectedItems)
          }}
          onSelect={(value) => {
            const addedItem = orderMaterials.find((item) => item.id === value)
            const newSelectedItems = [...selectedIncompleteItems, { ...addedItem, has_open_issue: true }]
            setSelectedIncompleteItems(newSelectedItems)
            onChange(newSelectedItems)
          }}
        >
          {orderMaterials?.map((orderMaterial) => (
            <Select.Option
              searchable={`${orderMaterial?.company_material?.description}-${orderMaterial?.quantity}-${orderMaterial.company_note}-${orderMaterial?.company_material?.manufacturer}`}
              data-cy={orderMaterial.id}
              key={orderMaterial.id}
              value={orderMaterial.id}
            >
              <Typography.Text>{orderMaterial.company_material.description} </Typography.Text>
              {orderMaterial.company_note && (
                <Typography.Text type={'secondary'}> - {orderMaterial.company_note}</Typography.Text>
              )}
            </Select.Option>
          ))}
        </Select>
        <Button
          type="primary"
          style={{ marginLeft: '10px' }}
          onClick={isAllSelected ? markAllItemsAsResolved : selectAllItems}
        >
          {isAllSelected ? 'Clear All' : 'Select All'}
        </Button>
      </Box>
      {unResolvedItems?.length > 0 && (
        <IncompleteItemsTable
          orderMaterials={unResolvedItems}
          disableEditing={disableEditing}
          updateIncompleteItems={updateIncompleteItems}
          markItemAsResolved={markItemAsResolved}
        />
      )}
    </>
  )
})

export default IncompleteItems
