import React from 'react'

import { Modal, ModalProps } from 'antd'

import { ModalBody } from 'contractor/pages/Invoices/Detail/CorrelatorModal/modal_body'

type InvoiceCorrelateModalProps = {
  saveAndNextDefault: boolean
  isCorrelating: boolean
  onAutoMatch: () => Promise<void>
} & ModalProps

export const InvoiceCorrelateModal = (props: InvoiceCorrelateModalProps) => {
  const { isCorrelating, saveAndNextDefault, open, onCancel, onAutoMatch } = props
  return (
    <Modal
      title="Initiate Auto-Reconciliation"
      okText={saveAndNextDefault ? 'Auto-reconcile and next' : 'Update and auto-reconcile'}
      open={open}
      cancelText="Manually reconcile"
      onOk={onAutoMatch}
      confirmLoading={isCorrelating}
      cancelButtonProps={{ disabled: isCorrelating }}
      onCancel={onCancel}
    >
      <ModalBody isCorrelating={isCorrelating} />
    </Modal>
  )
}
