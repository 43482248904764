import React, { useCallback, useEffect, useRef, useState } from 'react'

import { connectRefinementList } from 'react-instantsearch-dom'

import hotkeys from 'hotkeys-js'
import { get, sumBy } from 'lodash'

import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import { Button, Divider } from 'antd'

import { BadgeStyled, LeftButton, RightButton, Scrollable, StyledTag } from 'common/components/AlgoliaTagsFilter/styles'
import { Box } from 'common/components/boxes'
import { OrderStatesFilter, OrderSubStates } from 'common/server/server_types'

import { useStores } from 'contractor/hooks/use-stores'

const MULTI_SELECTION_KEY = 'shift'

export const allRefinements = [
  OrderStatesFilter.DRAFT,
  OrderStatesFilter.APPROVED,
  OrderStatesFilter.REQUESTED,
  OrderStatesFilter.DELIVERED,
  OrderStatesFilter.PARTIALLY_SHIPPED,
  OrderStatesFilter.SHIPPED,
  OrderSubStates.QUOTED_REQUESTED,
  OrderSubStates.QUOTED_RECEIVED,
  OrderSubStates.ORDERED_REQUESTED,
  OrderSubStates.ORDERED_CONFIRMED,
]

export const AlgoliaTagsFilter = connectRefinementList((props) => {
  const { items, currentRefinement, refine, options = [], boxProps, onChange, isOrderTable = false } = props
  const { orderStore, orderMaterialStore } = useStores()

  const scrollableRef = useRef<HTMLDivElement>(null)
  const wrapperRef = useRef<HTMLDivElement>(null)

  const [showLeftButton, setLeftButton] = useState(false)
  const [showRightButton, setRightButton] = useState(false)

  const total = sumBy(items, 'count')
  let totalCancelled = 0

  const cancelledItems = items.find((item) => item['label'] === 'CANCELLED')
  if (cancelledItems) {
    totalCancelled = get(cancelledItems, 'count', 0)
  }

  useEffect(() => {
    hotkeys(MULTI_SELECTION_KEY, function () {
      console.log('Pressed', MULTI_SELECTION_KEY)
    })
    return () => {
      hotkeys.unbind(MULTI_SELECTION_KEY)
    }
  }, [])

  const handleClickPrev = () =>
    scrollableRef.current?.scrollTo({
      left: scrollableRef.current?.scrollLeft - 100,
      behavior: 'smooth',
    })

  const handleClickNext = () =>
    scrollableRef.current?.scrollTo({
      left: scrollableRef.current?.scrollLeft + 100,
      behavior: 'smooth',
    })

  const getRefineParam = (filter: string) => {
    if (hotkeys.isPressed(MULTI_SELECTION_KEY)) {
      return [...currentRefinement, filter]
    }

    return filter
  }

  useEffect(() => {
    const onScroll = () => {
      if (scrollableRef.current?.scrollLeft > 0) {
        !showLeftButton && setLeftButton(true)
      } else {
        showLeftButton && setLeftButton(false)
      }

      if (
        scrollableRef.current?.scrollLeft + scrollableRef.current?.offsetWidth >=
        scrollableRef.current?.scrollWidth
      ) {
        showRightButton && setRightButton(false)
      } else {
        !showRightButton && setRightButton(true)
      }
    }

    scrollableRef.current?.addEventListener('scroll', onScroll)
    return () => scrollableRef.current?.removeEventListener('scroll', onScroll)
  }, [showLeftButton, showRightButton, scrollableRef.current])

  useEffect(() => {
    if (Math.round(scrollableRef.current?.scrollWidth) > Math.round(wrapperRef.current?.clientWidth)) {
      !showRightButton && setRightButton(true)
    } else {
      showRightButton && setRightButton(false)
    }
  }, [wrapperRef.current])

  useEffect(() => {
    const onWheel = (event) => {
      if (event.deltaY === 0) return
      event.preventDefault()
      const left = scrollableRef.current?.scrollLeft + event.deltaY

      scrollableRef.current?.scrollTo({
        left,
        behavior: 'smooth',
      })
    }

    scrollableRef.current?.addEventListener('wheel', onWheel)
    return () => scrollableRef.current?.removeEventListener('wheel', onWheel)
  }, [scrollableRef.current])

  const renderOption = useCallback(
    (option) => {
      const item = items.find((item) => item['label'] === option.filter)

      const isAllChecked = isOrderTable
        ? !orderStore.selectedRibbonFilter || orderStore.selectedRibbonFilter === 'All'
        : !orderMaterialStore.selectedRibbonFilter || orderMaterialStore.selectedRibbonFilter === 'All'

      const selectAll = isAllChecked && option.label === 'All'

      let count = get(item, 'count', 0)

      if (option.label == 'All') {
        count = total - totalCancelled
      }

      const isChecked =
        currentRefinement.length > 1
          ? currentRefinement.includes(option.filter)
          : get(currentRefinement, '[0]', option.filter) === option.filter && !isAllChecked

      return (
        <>
          {option.label == 'Cancelled' && (
            <Divider
              style={{ borderColor: 'lightslategray', height: '2em', marginLeft: 8, marginRight: 12 }}
              type="vertical"
            />
          )}
          <StyledTag
            data-cy={`${option.label}-filter`}
            key={option.label}
            checked={isAllChecked ? selectAll : isChecked}
            onChange={() => {
              onChange?.(option.label)
              const param = getRefineParam(option.filter)
              refine(param)
            }}
          >
            {option.label}
            <BadgeStyled aria-label="counter-badge" count={count} showZero overflowCount={999} />
          </StyledTag>
        </>
      )
    },
    [items, currentRefinement, isOrderTable],
  )

  return (
    <Box position="relative" maxWidth="100%" {...boxProps} ref={wrapperRef}>
      {showLeftButton && (
        <LeftButton>
          <Box bg="white" display="flex" alignItems="center">
            <Button icon={<LeftOutlined />} type="text" shape="circle" onClick={handleClickPrev} size="small" />
          </Box>
        </LeftButton>
      )}

      <Scrollable alignItems="center" overflowX="auto" display="flex" flexWrap="nowrap" ref={scrollableRef}>
        {options?.map(renderOption)}
      </Scrollable>

      {showRightButton && (
        <RightButton>
          <Box bg="white" display="flex" alignItems="center">
            <Button icon={<RightOutlined />} type="text" shape="circle" onClick={handleClickNext} size="small" />
          </Box>
        </RightButton>
      )}
    </Box>
  )
})
