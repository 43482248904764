import React from 'react'

import { DataViewerProps } from 'react-spreadsheet'

import { Box } from 'common/components/boxes'
import { CostCode } from 'common/server/cost_codes/cost_codes'

type PhaseCodeViewerCellProps = {
  phaseCode?: CostCode
} & DataViewerProps

export const PhaseCodeViewerCell = ({ phaseCode }: PhaseCodeViewerCellProps) => {
  return <Box ml={4}>{phaseCode?.code}</Box>
}
