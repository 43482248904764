import React from 'react'

import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'

import { Box } from 'common/components/boxes'
import { Loading } from 'common/components/Loading'
import { Page } from 'common/components/Page'
import { PsqlTableProvider } from 'common/components/PsqlTable/psql_table_provider'
import { PsqlTable } from 'common/components/PsqlTable/table'
import { ResponsiveTable } from 'common/components/ResponsiveTable'
import { RibbonFilter } from 'common/components/RibbonFilter'
import { SearchInput } from 'common/components/SearchInput'
import { usePersistentFilters } from 'common/contexts/persistent_filters'
import { useMaybeOpenIntoNewTab } from 'common/hooks/use-maybe-open-into-new-tab'
import { useQuery } from 'common/hooks/use-query'

import { useStores } from 'contractor/hooks/use-stores'
import { StateResults } from 'contractor/pages/@v2/Invoices/state_results'
import InvoiceMobileCard from 'contractor/pages/@v2/Invoices/tabs/List/invoice_mobile_card'
import { getInvoiceColumns } from 'contractor/pages/@v2/Invoices/tabs/List/invoice_table_columns'
import { ShowProjectResponse } from 'contractor/server/projects'

import { InvoiceProjectSelector } from '../../../../Invoices/project_selector'
import { ListFilters } from '../../Filters'

interface InvoiceTabProps {
  EmptyState?: React.ElementType
  ribbonFilterExcludedStates?: string
  excludedColumns?: string[]
}

export const InvoiceTab = observer((props: InvoiceTabProps) => {
  const { EmptyState = StateResults, ribbonFilterExcludedStates, excludedColumns = [] } = props

  const { invoiceStore, projectStore, invoiceStateStore, integrationStore, invoiceSettingsStore } = useStores()
  const { listStore } = invoiceStore

  const persistentFilters = usePersistentFilters()
  const { maybeOpenIntoNewTab } = useMaybeOpenIntoNewTab()

  function handleChangeProject(project: ShowProjectResponse) {
    persistentFilters.handleChangeFilters({ project_id: project?.id })
  }

  async function init() {
    const initialProjectId =
      new URLSearchParams(location.search).get('project_id') || sessionStorage['selectedProjectId']

    if (initialProjectId && initialProjectId.length > 0) {
      listStore.setFilter('project_id', initialProjectId, true, true)
    }

    return persistentFilters.init()
  }

  useQuery(listStore.fetchFacets)
  // Maybe index in case we are creating a new order from a fresh load
  const { isLoading } = useQuery(
    () =>
      Promise.all([
        projectStore.maybeIndexProjects(),
        invoiceStateStore.getAllInvoiceStates(),
        integrationStore.accountingInfo(),
        invoiceSettingsStore.indexSettings(),
        init(),
      ]),
    [],
  )

  function handleSearch(value: string) {
    persistentFilters.handleChangeFilters({ search: value }, false)
  }

  if (isLoading) return <Loading />

  return (
    <PsqlTableProvider
      tableName="invoices"
      options={{
        data: toJS(listStore.records),
        columns: getInvoiceColumns(excludedColumns),
        sort: {
          onChange: persistentFilters.handleChangeSort,
          field: listStore.searchState.sort,
          direction: listStore.searchState.sort_direction,
        },
      }}
    >
      <Page.Header>
        <Box width="100%" display="flex" alignItems="initial" flexDirection={{ _: 'column', md: 'row' }}>
          <Box mb={{ _: 8, md: 0 }} mr={8}>
            <InvoiceProjectSelector onChange={handleChangeProject} />
          </Box>

          <Box width="100%" display="flex" alignItems="center" flexGrow={1}>
            <Box mr={8} flexGrow={1}>
              <SearchInput value={listStore.searchState.search} onSearch={handleSearch} style={{ width: '100%' }} />
            </Box>

            <ListFilters />
          </Box>
        </Box>

        <Box mt={16} width="100%">
          <RibbonFilter
            showCounts
            boxProps={{
              mt: { _: 8, lg: 0 },
            }}
            value={listStore.searchState.filters['state_name'] || []}
            counts={listStore.stateCounts || {}}
            onChange={(value) => listStore.setFilter('state_name', value)}
            options={[
              { label: 'All', filter: '' },
              ...(invoiceStateStore.invoiceStates || [])
                .filter((state) => state.state !== ribbonFilterExcludedStates)
                .map((setting) => ({
                  label: setting?.label,
                  filter: setting?.label,
                })),
            ]}
          />
        </Box>
      </Page.Header>

      <Page.Content p={0} px={{ _: 0, md: 16 }} py={16} height="100%">
        {!listStore.isFetching && listStore.records.length === 0 ? (
          <EmptyState />
        ) : (
          <ResponsiveTable
            Table={PsqlTable}
            data={toJS(listStore.records)}
            tableName="AllInvoices"
            hasMore={listStore.hasMore}
            loadMore={listStore.fetchNextPage}
            onLoadMore={listStore.fetchNextPage}
            onClickRow={({ row }) => maybeOpenIntoNewTab(`/invoice/${row.invoice_id}`)}
            renderMobileCard={InvoiceMobileCard}
          />
        )}
      </Page.Content>
    </PsqlTableProvider>
  )
})
