/** @jsx jsx */

import * as React from 'react'

import { Row } from 'react-table'

import moment from 'moment'

import { jsx } from '@emotion/core'

import { observer } from 'mobx-react-lite'

import { OrderDelivery, DeliveryHit } from 'common/server/deliveries'

const DeliveryInfoCell: React.FC<{ row?: Row; orderDelivery?: OrderDelivery | DeliveryHit }> = observer(
  ({ row, orderDelivery }) => {
    // TODO: Want to make this even better, i.e. provide even more information about the deliver
    // TODO: Add a "late" tag, concerned we need to be comparing times and timezones will make this get confusing

    const delivery = orderDelivery ? orderDelivery : row['original']
    const estimated_delivered_at = delivery['estimated_delivered_at']
    const carrier = delivery['carrier']

    const formattedDeliveryDate = estimated_delivered_at && moment(estimated_delivered_at).format('ddd. MMM Do, YYYY')

    // TODO: ETA is the wrong word here
    let text = 'ETA: Unknown'
    if (estimated_delivered_at && carrier) {
      text = `${carrier}, ETA: ${formattedDeliveryDate}`
    } else if (estimated_delivered_at && !carrier) {
      text = `ETA: ${formattedDeliveryDate}`
    } else if (!estimated_delivered_at && carrier) {
      text = `${carrier}, ETA: Unknown`
    }

    return <span>{text}</span>
  },
)

export default DeliveryInfoCell
