import React, { useState, useRef } from 'react'

import { observer } from 'mobx-react-lite'

import { DrawerRef } from 'common/components/Drawer'
import { Page } from 'common/components/Page'

import { VendorProfile } from 'contractor/components/VendorProfile'

import { MyVendorsProvider } from './context'
import { CreateCompanyVendorContact } from './Create'
import { Header } from './Header'
import { List } from './List'
import { UpdateCompanyVendorContact, UpdateCompanyVendor } from './Update'

type MyVendorsProps = {
  goToNetWork: () => void
}

export const MyVendors = observer<MyVendorsProps>(({ goToNetWork }) => {
  const drawerVendorRef = useRef<DrawerRef>()
  const drawerUpdateRef = useRef<DrawerRef>()
  const drawerCreateRef = useRef<DrawerRef>()
  const drawerUpdateCompanyVendorRef = useRef<DrawerRef>()

  const [selectedVendorId, setSelectedVendorId] = useState(null)
  const [selectedCompanyVendorId, setSelectedCompanyVendorId] = useState(null)
  const [selectedCompanyVendorContactId, setSelectedCompanyVendorContactId] = useState(null)

  const handleOpenVendorProfile = (vendorId: string, companyVendorId: string) => {
    setSelectedVendorId(vendorId)
    setSelectedCompanyVendorId(companyVendorId)
    drawerVendorRef.current?.show()
  }

  const handleOpenCompanyVendorContactUpdate = (companyVendorContactId: string) => {
    setSelectedCompanyVendorContactId(companyVendorContactId)
    drawerUpdateRef.current?.show()
  }

  const handleOpenCompanyVendorUpdate = (companyVendorId: string) => {
    setSelectedCompanyVendorId(companyVendorId)
    drawerUpdateCompanyVendorRef.current?.show()
  }

  const handleCreate = () => drawerCreateRef.current?.show()

  const handleCancelCreateCompanyVendorContact = () => drawerCreateRef.current?.close()

  const handleCancelUpdateCompanyVendorContact = () => {
    setSelectedCompanyVendorContactId(null)
    drawerUpdateRef.current?.close()
  }

  const handleCancelUpdateCompanyVendor = () => {
    setSelectedCompanyVendorId(null)
    drawerUpdateCompanyVendorRef.current?.close()
  }

  return (
    <MyVendorsProvider>
      <Header onCreate={handleCreate} />

      <Page.Content>
        <List
          onCreate={handleCreate}
          goToNetWork={goToNetWork}
          onOpenVendorProfile={handleOpenVendorProfile}
          onOpenCompanyVendorContactUpdate={handleOpenCompanyVendorContactUpdate}
          onOpenCompanyVendorUpdate={handleOpenCompanyVendorUpdate}
        />
      </Page.Content>

      <UpdateCompanyVendor
        companyVendorId={selectedCompanyVendorId}
        onCancel={handleCancelUpdateCompanyVendor}
        ref={drawerUpdateCompanyVendorRef}
      />

      <VendorProfile vendorId={selectedVendorId} companyVendorId={selectedCompanyVendorId} ref={drawerVendorRef} />

      <UpdateCompanyVendorContact
        companyVendorContactId={selectedCompanyVendorContactId}
        onCancel={handleCancelUpdateCompanyVendorContact}
        ref={drawerUpdateRef}
      />

      <CreateCompanyVendorContact onCancel={handleCancelCreateCompanyVendorContact} ref={drawerCreateRef} />
    </MyVendorsProvider>
  )
})
