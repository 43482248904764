import React, { useState, useEffect } from 'react'

import { Button, Form, Row, Col, Checkbox, Space, Typography, Input, Alert } from 'antd'

import { observer } from 'mobx-react-lite'

import { Box } from 'common/components/boxes'
import { Drawer, DrawerProps } from 'common/components/Drawer'
import { useQuery } from 'common/hooks/use-query'
import { RelationshipStockStatus } from 'common/server/server_types'

import { SelectAgaveProjects } from 'contractor/components/SelectAgaveProjects'
import { SelectAgaveVendors } from 'contractor/components/SelectAgaveVendors'
import { useStores } from 'contractor/hooks/use-stores'

const SYNC_INVOICE_WITH_INTEGRATION_LOCAL_KEY = 'checkbox-sync-invoice-with-integration'

type Option = {
  value: string
  label: string
}

export type IntegrationSyncProps = {
  isSubmitting?: boolean
  onFinish: (formValues: { vendor: Option | string; customer: Option | string }) => void
  onFinishWithoutSync: () => void
} & Partial<DrawerProps>

const makeOption = (option) =>
  option
    ? {
        value: option?.external_id,
        label: option?.external_name,
      }
    : undefined

export const IntegrationSync = observer<IntegrationSyncProps>(
  ({ isSubmitting, onFinish, onFinishWithoutSync, ...props }) => {
    const { integrationStore, invoiceStore, companySettingStore, userStore } = useStores()

    const costCodeSettings = companySettingStore.otherSettings?.cost_code_settings

    const invoice = invoiceStore.invoice
    const integration = invoiceStore.invoice.integration
    const integrationName = integrationStore.getIntegrationName(integration?.source)

    const [form] = Form.useForm()

    const [syncInvoiceWithIntegration, setSyncInvoiceWithIntegration] = useState(
      localStorage.getItem(SYNC_INVOICE_WITH_INTEGRATION_LOCAL_KEY) === 'true',
    )

    const { isLoading } = useQuery(() => {
      if (!integrationStore.isProcore()) {
        return Promise.all([
          integrationStore.getProjectsRelationships(),
          integrationStore.getCompanyVendorsRelationships(),
        ])
      }
    })
    useQuery(() => {
      if (integrationStore.invoiceSyncTypeExpense() && !integrationStore.isProcore()) {
        return integrationStore.getCostCodeNumbersRelationships()
      }
    })
    useQuery(() => {
      if (userStore.canUseCostCode && costCodeSettings?.class_enabled && integrationStore.isQBO()) {
        return integrationStore.getCostCodeClassesRelationships()
      }
    }, [userStore.canUseCostCode, costCodeSettings?.class_enabled])

    const { isLoading: isLoadingSuggestions } = useQuery(() => {
      if (!integrationStore.isProcore() && !integrationStore.isFoundationHosted()) {
        return integrationStore.getInvoiceSuggestions(invoiceStore.invoice?.id)
      }
    }, [invoiceStore.invoice?.id])

    useEffect(() => {
      if (integrationStore.invoiceSuggestions) {
        const suggestions = integrationStore.invoiceSuggestions
        form.setFieldsValue({
          vendor: makeOption(suggestions?.vendor),
          customer: makeOption(suggestions?.customer),
        })
      }
    }, [integrationStore.invoiceSuggestions])

    useEffect(() => {
      const invoiceOrder = invoiceStore.getInvoiceOrder([...invoice?.orders][0]?.id)
      if (invoiceOrder) {
        form.setFieldValue('internalProject', invoiceOrder?.order?.project?.name)
      }
    }, [integrationStore.invoiceSuggestions, invoiceStore.selectedOrders?.length])

    if (isLoading || isLoadingSuggestions) {
      return null
    }

    return (
      <Drawer
        title={
          <Space>
            <Typography.Text style={{ fontSize: 14 }}>Sync Invoice with {integrationName}</Typography.Text>
            <Checkbox
              onChange={(e) => {
                localStorage.setItem(SYNC_INVOICE_WITH_INTEGRATION_LOCAL_KEY, `${e.target.checked}`)
                setSyncInvoiceWithIntegration(e.target.checked)
              }}
              checked={syncInvoiceWithIntegration}
            />
          </Space>
        }
        {...props}
      >
        <Box display="flex" flexDirection="column" overflowY="auto" width="100%" height="100%" p={16}>
          {integration.status === RelationshipStockStatus.FAILED && (
            <Space direction="vertical" style={{ marginBottom: 16, width: '100%' }}>
              {integration?.errors?.map((error, index) => (
                <Alert type="error" message={error.fail_message} key={`error-${index}`} closable />
              ))}
            </Space>
          )}

          <Form
            form={form}
            layout="vertical"
            initialValues={{ internalVendor: invoice?.seller_name || invoice.orders[0]?.vendor_name }}
            onFinish={onFinish}
          >
            {/* We may need to revisit this if suggestions needs multi mapping in the future */}
            {!integrationStore.isProcore() && (
              <Row gutter={20}>
                <Col xs={24} sm={12}>
                  <Form.Item label="SubBase Vendor" name="internalVendor">
                    <Input disabled bordered={false} />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={12}>
                  <Form.Item
                    label={`${integrationName} Vendor`}
                    name="vendor"
                    rules={[{ required: true, message: 'Vendor is required.' }]}
                  >
                    <SelectAgaveVendors disabled={!syncInvoiceWithIntegration} canDisableSelected={false} />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={12}>
                  <Form.Item label="SubBase Project" name="internalProject">
                    <Input disabled bordered={false} />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={12}>
                  <Form.Item
                    label={`${integrationName} Project`}
                    name="customer"
                    rules={[{ required: true, message: 'Project is required.' }]}
                  >
                    <SelectAgaveProjects disabled={!syncInvoiceWithIntegration} canDisableSelected={false} />
                  </Form.Item>
                </Col>
              </Row>
            )}
            <Box display="flex" justifyContent="flex-end">
              {syncInvoiceWithIntegration ? (
                <Button type="primary" htmlType="submit" loading={isSubmitting}>
                  Submit
                </Button>
              ) : (
                <Button type="primary" onClick={onFinishWithoutSync} loading={isSubmitting}>
                  Submit without sync
                </Button>
              )}
            </Box>
          </Form>
        </Box>
      </Drawer>
    )
  },
)
