import { useEffect, useMemo } from 'react'

import { useHistory, useLocation } from 'react-router-dom'

import { refreshWithAttempts } from 'common/helpers/refresh_with_attempts'

type UseRefreshInfiniteList = {
  forceRefreshHits: () => void
  refreshHits: boolean
  attempts?: number
}

export const useRefreshInfiniteList = ({ forceRefreshHits, refreshHits, attempts = 2 }: UseRefreshInfiniteList) => {
  const history = useHistory()
  const location = useLocation()

  useEffect(() => {
    const params = new URLSearchParams(location.search)
    const refresh = params.get('refresh')

    if (refresh === 'true') {
      refreshWithAttempts(forceRefreshHits, attempts)

      params.delete('refresh')
      history.replace({
        search: params.toString(),
      })
    }
  }, [location.search])

  // Need to unmount Infinite hits to update
  const customHitsKey = useMemo(() => {
    return new Date().getTime()
  }, [refreshHits])

  return { customHitsKey }
}
