import React, { useState } from 'react'

import { useHistory } from 'react-router-dom'

import styled from '@emotion/styled'

import { CloseOutlined, ProjectOutlined, WarningOutlined } from '@ant-design/icons'
import { Button, Checkbox, message, Modal, Radio, Tooltip, Typography } from 'antd'

import { Box, FlexBoxX, FlexBoxY } from 'common/components/boxes'
import OrderStateTag from 'common/components/statuses/order_state'
import { usePushToOrder } from 'common/hooks/use-push-to-order'
import { OrderHit } from 'common/server/orders'
import theme from 'common/styles/theme'

import { OrdersAutocomplete } from 'contractor/components/OrdersAutocomplete'
import { useStores } from 'contractor/hooks/use-stores'
import { CreateMergeOrderRequest, DeliveryPreference, IndexOrderRecord } from 'contractor/server/orders'

type MergeOrdersProps = {
  isQuote: boolean
}

const options = [
  { label: 'Add to existing delivery', value: 'existing' },
  { label: 'Create new deliveries', value: 'new' },
]

const StdOrderNumberNameButton = styled(Button)`
  width: 100%;
  padding: 0;

  > span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: calc(100% - 1px);
    text-align: left;
  }
`

export const MergeOrders = ({ isQuote }: MergeOrdersProps) => {
  const { orderStore } = useStores()
  const history = useHistory()
  const { push } = usePushToOrder()

  const [showModal, setShowModal] = useState(false)
  const [notifyVendor, setNotifyVendor] = useState(false)
  const [selectedOrder, setSelectedOrder] = useState<OrderHit>(null)
  const [loading, setLoading] = useState(false)
  const [deliveryPreference, setDeliveryPreference] = useState<DeliveryPreference>('existing')

  const showNotify = selectedOrder && (selectedOrder.ordered_at || selectedOrder.quoted_at)

  const mergeOrders = async () => {
    const payload: CreateMergeOrderRequest = {
      order_id: selectedOrder.id,
      draft_id: orderStore.selectedOrder.id,
      preference: deliveryPreference,
      send_notification: notifyVendor ? true : null,
    }

    setLoading(true)

    try {
      await orderStore.mergeOrder(payload)
      setShowModal(false)
      history.push(`/order/${orderStore.selectedOrder.id}`)
    } catch (e) {
      message.error(e?.response?.data?.error || 'Unable to merge the order')
    } finally {
      setLoading(false)
    }
  }

  function handleClickOrderLink() {
    push({
      orderId: selectedOrder?.id,
      orderPackageId: selectedOrder?.order_package_id,
      state: selectedOrder?.state,
      subState: selectedOrder?.sub_state,
      isNewTab: true,
    })
  }

  return (
    <>
      <Modal
        open={showModal}
        title={`Merge ${isQuote ? 'Quotes' : 'Orders'}`}
        onCancel={() => setShowModal(false)}
        closable
        footer={
          <FlexBoxX justifyContent="space-between">
            <Button disabled={loading} onClick={() => setShowModal(false)}>
              Cancel
            </Button>
            <Button loading={loading} disabled={!selectedOrder || loading} type="primary" onClick={() => mergeOrders()}>
              Confirm
            </Button>
          </FlexBoxX>
        }
      >
        <FlexBoxY>
          <FlexBoxX mb="12px">
            <WarningOutlined style={{ fontSize: 22, color: '#faad14', marginRight: 24 }} />
            <Typography.Text strong>
              Merging an order will take all the materials from this order and place them in the new order that you
              select. This order will be marked as cancelled.
            </Typography.Text>
          </FlexBoxX>
          <FlexBoxY alignContent="flex-start" width="100%">
            {selectedOrder ? (
              <Box
                bg={theme.colors['gray-3']}
                borderRadius={theme.radii.md}
                style={{ cursor: 'default' }}
                width="100%"
                p={8}
                gridGap={8}
                display="flex"
                flexDirection="column"
              >
                <Box display="flex" justifyContent="space-between" width="100%">
                  <StdOrderNumberNameButton
                    size="small"
                    style={{ padding: 0 }}
                    type="link"
                    onClick={handleClickOrderLink}
                  >
                    {selectedOrder.order_number}: {selectedOrder.name}
                  </StdOrderNumberNameButton>

                  <Tooltip title="Remove order">
                    <CloseOutlined onClick={() => setSelectedOrder(null)} style={{ marginLeft: 16 }} />
                  </Tooltip>
                </Box>

                <Box display="flex" alignItems="center">
                  <ProjectOutlined style={{ color: theme.colors['gray-7'] }} />
                  <Typography.Text style={{ margin: '0 4px' }} ellipsis={{ tooltip: selectedOrder?.project_name }}>
                    {`${
                      (selectedOrder as unknown as IndexOrderRecord).project_number || selectedOrder.project_identifier
                    }: `}
                    {selectedOrder?.project_name}
                  </Typography.Text>
                </Box>

                <OrderStateTag
                  state={selectedOrder.state}
                  sub_state={selectedOrder.sub_state}
                  theme={theme}
                  fontSize={12}
                />
              </Box>
            ) : (
              <FlexBoxX style={{ width: '100%' }}>
                <OrdersAutocomplete
                  filters={{ ignore_commitments: true }}
                  onSelect={(order) => setSelectedOrder(order)}
                />
              </FlexBoxX>
            )}
            <Radio.Group
              style={{ marginTop: 24 }}
              options={options}
              onChange={(e) => setDeliveryPreference(e.target.value)}
              value={deliveryPreference}
              optionType="button"
              buttonStyle="solid"
            />
            {showNotify && (
              <Checkbox
                style={{ marginTop: 16 }}
                checked={notifyVendor}
                onChange={() => setNotifyVendor(!notifyVendor)}
              >
                Notify my vendor of updates to my order
              </Checkbox>
            )}
          </FlexBoxY>
        </FlexBoxY>
      </Modal>
      <Tooltip
        title={
          orderStore.anyRequestedMaterial
            ? 'You cannot merge until resolve the pending the requested materials.'
            : undefined
        }
        placement="left"
      >
        <div
          onClick={() => {
            if (!orderStore.anyRequestedMaterial) {
              setShowModal(true)
            }
          }}
        >
          Merge {isQuote ? 'Quotes' : 'Orders'}
        </div>
      </Tooltip>
    </>
  )
}
