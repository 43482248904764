import React from 'react'

import { Link } from 'react-router-dom'

import { Typography, Tag } from 'antd'

import { AgaveSyncStatus } from 'common/components/AgaveSyncStatus'
import { currencyFormatter, formatDateString } from 'common/helpers/formatters'

import { InvoiceStatusTag } from 'contractor/components/Invoices/invoice_status_tag'
import { useStores } from 'contractor/hooks/use-stores'

export const UnknownText = () => (
  <Typography.Paragraph type="secondary" style={{ marginBottom: 0 }}>
    Unknown
  </Typography.Paragraph>
)

export const NotAssignedText = () => (
  <Typography.Paragraph type="secondary" style={{ marginBottom: 0 }}>
    Not assigned
  </Typography.Paragraph>
)

export const getInvoiceColumns = () => {
  const { userStore, integrationStore, invoiceSettingsStore } = useStores()

  const accountingDateEnabled = invoiceSettingsStore?.settings?.accounting_date_enabled
  const insertIf = (condition, ...elements) => (condition ? elements : [])

  return [
    {
      Header: 'Status',
      accessor: 'status',
      width: '5%',
      Cell: ({ row }) => {
        return <InvoiceStatusTag state={row.original?.state} rejectionReason={row.original?.rejection_reason} />
      },
    },
    {
      Header: 'Assigned To',
      accessor: ({ assigned_to }) => (assigned_to === 'Not assigned' ? <NotAssignedText /> : assigned_to),
      minWidth: 120,
      sort_replica_name: 'assigned_to',
    },
    {
      Header: 'Vendor',
      accessor: ({ company_vendor_name }) => (company_vendor_name === null ? <UnknownText /> : company_vendor_name),
      width: '8%',
      minWidth: 120,
    },
    {
      Header: 'Invoice',
      accessor: 'number',
      width: '5%',
      minWidth: 120,
      Cell: ({ value, row }) => {
        return (
          <Link onClick={(e) => e.stopPropagation()} to={`/invoice/${row?.original?.id}`}>
            {value}
          </Link>
        )
      },
    },
    {
      Header: 'Projects',
      accessor: 'projects',
      width: '10%',
      minWidth: 150,
      Cell: ({ row }) => {
        const projects = row.original.projects.filter(({ id }) => id !== 'unknown')
        if (projects.length === 0) return <UnknownText />
        return (
          <>
            {projects.map(({ name }, index) => (
              <Typography.Paragraph key={`${row.original.id}-${index}-p-key`} style={{ marginBottom: 0 }}>
                {name}
              </Typography.Paragraph>
            ))}
          </>
        )
      },
    },
    {
      Header: 'Orders',
      accessor: 'orders',
      width: '20%',
      minWidth: 200,
      Cell: ({ row }) => {
        if (!row.original.orders || row.original.orders.length === 0) return <UnknownText />
        return (
          <>
            {row.original.orders.map(({ id, name }) => (
              <Typography.Paragraph key={`${id}-o-key`} style={{ marginBottom: 0 }}>
                {name}
              </Typography.Paragraph>
            ))}
          </>
        )
      },
    },
    {
      Header: 'Created At',
      id: 'created_at',
      accessor: ({ created_at }) => (created_at ? formatDateString(created_at) : <UnknownText />),
      width: '5%',
      minWidth: 160,
    },
    {
      Header: 'Invoice Date',
      id: 'document_date',
      accessor: ({ document_date }) => (document_date ? formatDateString(document_date) : <UnknownText />),
      width: '5%',
      minWidth: 160,
      sort_replica_name: 'document_date',
    },
    ...insertIf(accountingDateEnabled, {
      Header: 'Accounting Date',
      id: 'accounting_date',
      accessor: ({ document_date }) => (document_date ? formatDateString(document_date) : <UnknownText />),
      width: '5%',
      minWidth: 160,
      sort_replica_name: 'accounting_date',
    }),
    {
      Header: 'Due Date',
      id: 'due_date',
      accessor: ({ due_date }) => (due_date ? formatDateString(due_date) : <UnknownText />),
      width: '5%',
      minWidth: 160,
      sort_replica_name: 'due_date',
    },
    {
      Header: 'Total Amount',
      id: 'total_amount',
      accessor: ({ total_amount }) => (total_amount ? currencyFormatter(total_amount, 2) : <UnknownText />),
      width: '5%',
      minWidth: '100px',
    },
    ...(userStore.canUseIntegrations && integrationStore.invoiceSyncEnabled
      ? [
          {
            Header: 'Sync Status',
            accessor: 'agave_sync_status',
            id: 'agave_sync_status',
            minWidth: 150,
            Cell: ({ value }) => <AgaveSyncStatus status={value} />,
          },
        ]
      : []),
    {
      Header: 'Posted At',
      id: 'posted_at',
      accessor: ({ posted_at }) => (posted_at ? formatDateString(posted_at) : <UnknownText />),
      width: '5%',
      minWidth: 160,
    },
    {
      Header: 'Tags',
      accessor: 'tags',
      id: 'tags',
      Cell: ({ value = [] }) => {
        return value?.map((tag) => <Tag key={tag}>{tag}</Tag>)
      },
      width: '10%',
      minWidth: 150,
    },
  ]
}
