import * as React from 'react'

import { Button, Form, Input, message } from 'antd'

import { observer } from 'mobx-react-lite'

import { FlexBoxY } from 'common/components/boxes'
import { Page } from 'common/components/Page'

interface UserPasswordChangePayload {
  current_password?: string
  password?: string
  password_confirmation: string
}

export interface PasswordChangeProps {
  onSubmitPasswordChange: (values: UserPasswordChangePayload) => Promise<void>
}

const EditUserPassword: React.FC<PasswordChangeProps> = observer(({ onSubmitPasswordChange }) => {
  const [form] = Form.useForm()

  const onSubmit = async (values: UserPasswordChangePayload): Promise<void> => {
    try {
      await onSubmitPasswordChange(values)
      form.resetFields()
      message.success('Password updated successfully')
    } catch (err) {
      console.error('Unable to update', err)
      message.error(
        (err.response.data?.errors?.current_password && 'Your old password does not match') ||
          'Unable to update your password',
      )
    }
  }

  return (
    <>
      <Page.Header p="8px" />

      <Page.Content>
        <FlexBoxY alignItems="flex-start">
          <Form layout="vertical" form={form} name="edit-user-password" onFinish={onSubmit}>
            <Form.Item
              name="current_password"
              label="Old password"
              rules={[{ required: true, message: 'Old password is required' }]}
            >
              <Input.Password style={{ width: '100%' }} />
            </Form.Item>
            <Form.Item
              name="password"
              label="New password"
              rules={[
                { required: true, message: 'New password is required' },
                { min: 6, message: 'Password must be at least 6 characters long' },
              ]}
            >
              <Input.Password allowClear style={{ width: '100%' }} />
            </Form.Item>
            <Form.Item
              name="password_confirmation"
              label="Confirm new password"
              rules={[
                { required: true, message: 'Confirm new password is required' },
                ({ getFieldValue }) => ({
                  validator(_rule, value): Promise<void> {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve()
                    }
                    return Promise.reject('The two passwords that you entered do not match!')
                  },
                }),
              ]}
            >
              <Input.Password allowClear style={{ width: '100%' }} />
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit">
                Update
              </Button>
            </Form.Item>
          </Form>
        </FlexBoxY>
      </Page.Content>
    </>
  )
})

export default EditUserPassword
