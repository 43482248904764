import React from 'react'

import { Tag, Popover, Typography } from 'antd'

import { observer } from 'mobx-react-lite'

import { FlexBoxX, Box } from 'common/components/boxes'
import { CompanyMaterialHit } from 'common/server/company_materials'

import { useStores } from 'contractor/hooks/use-stores'

const style = { padding: '0 4px' }

const fontSize = 12

type CostCodeCellProps = {
  costCode?: CompanyMaterialHit['cost_code']
}

export const CostCodeCell = observer<CostCodeCellProps>(({ costCode }) => {
  const { companySettingStore } = useStores()

  const costCodeSettings = companySettingStore.otherSettings?.cost_code_settings

  const phaseEnabled = costCodeSettings?.phase_code_enabled && !costCodeSettings?.independent_phase_codes_enabled
  const classEnabled = costCodeSettings?.class_enabled

  return (
    <Popover
      content={
        <Box width="fit-content">
          {phaseEnabled && !!costCode ? (
            <Box width="100%" display="flex" flexDirection="column" mb={12}>
              <Typography.Text type="secondary" style={{ fontSize }}>
                PHASE: <Typography.Text strong>{costCode?.phase_code || 'N/A'}</Typography.Text>
              </Typography.Text>
              {costCode?.phase_code_description && (
                <Typography.Text type="secondary" style={{ fontSize: 12 }}>
                  {costCode?.phase_code_description}
                </Typography.Text>
              )}
            </Box>
          ) : (
            ''
          )}
          {costCode?.code && (
            <Box width="100%" display="flex" flexDirection="column">
              <Typography.Text type="secondary" style={{ fontSize }}>
                COST CODE: <Typography.Text strong>{costCode?.code || 'N/A'}</Typography.Text>
              </Typography.Text>
              {costCode?.description && (
                <Typography.Text type="secondary" style={{ fontSize }}>
                  {costCode?.description}
                </Typography.Text>
              )}
            </Box>
          )}
          {classEnabled && !!costCode ? (
            <Box width="100%" display="flex" flexDirection="column" mt={12}>
              <Typography.Text type="secondary" style={{ fontSize }}>
                CLASS: <Typography.Text strong>{costCode?.clazz || 'N/A'}</Typography.Text>
              </Typography.Text>
              {costCode?.clazz_description && (
                <Typography.Text type="secondary" style={{ fontSize }}>
                  {costCode?.clazz_description}
                </Typography.Text>
              )}
            </Box>
          ) : (
            ''
          )}
        </Box>
      }
    >
      <FlexBoxX alignItems="flex-start" justifyContent="flex-start">
        {phaseEnabled && !!costCode ? <Tag style={style}>{costCode?.phase_code || 'N/A'}</Tag> : ''}
        {costCode?.code && <Tag style={style}>{costCode?.code}</Tag>}
        {classEnabled && !!costCode ? <Tag style={style}>{costCode?.clazz || 'N/A'}</Tag> : ''}
      </FlexBoxX>
    </Popover>
  )
})
