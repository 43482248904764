const wait = (ms) =>
  new Promise((resolve) => {
    setTimeout(resolve, ms)
  })

export const refreshWithAttempts = async (fn, retries = 0, delay = 3000) => {
  if (!retries) return

  try {
    await wait(delay)
    await fn()
  } finally {
    refreshWithAttempts(fn, retries - 1, delay)
  }
}
