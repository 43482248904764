import React from 'react'

import { EditOutlined } from '@ant-design/icons'
import { Space } from 'antd'

import { Box, BoxProps } from 'common/components/boxes'
import { useClickOutside } from 'common/hooks/use-click-outside'

type CustomEditableContentProps = {
  children: React.ReactNode
  input: React.ReactNode
  boxProps: BoxProps
  showRequired?: boolean
  refs?: React.MutableRefObject<undefined>[]
}

export const CustomEditableContent: React.FC<CustomEditableContentProps> = ({
  children,
  boxProps,
  showRequired = false,
  input: inputProp,
  refs = [],
}) => {
  const [isEditting, setEditting] = React.useState(false)
  const wrapperInputRef = React.useRef()

  useClickOutside([wrapperInputRef, ...refs], () => {
    if (isEditting) {
      setEditting(false)
    }
  })

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const input = React.cloneElement(inputProp as any, {
    onKeyDown: (event) => {
      if (event.key === 'Enter') {
        setEditting(false)
      }
    },
  })

  return (
    <Box ref={wrapperInputRef}>
      <Box style={{ display: isEditting ? 'block' : 'none' }} mt="-5px" {...boxProps}>
        {input}
      </Box>
      <Box style={{ display: isEditting ? 'none' : 'block' }} mt="-5px" {...boxProps}>
        <Space onClick={() => setEditting(true)} style={{ cursor: 'pointer' }}>
          {children}
          <>
            <EditOutlined />
            {showRequired && <span style={{ color: 'red' }}>*</span>}
          </>
        </Space>
      </Box>
    </Box>
  )
}
