import React, { useState } from 'react'

import { useHistory, useLocation } from 'react-router-dom'

import { Button, message, Typography } from 'antd'

import { Box } from 'common/components/boxes'
import { Drawer, DrawerRef } from 'common/components/Drawer'
import { InvoiceMailDetail } from 'common/components/InvoiceMail/invoice_mail_detail'
import { Loading } from 'common/components/Loading'
import { formatDateString } from 'common/helpers/formatters'
import { ShowInvoiceMailResponse, ShowInvoiceUploadResponse } from 'common/server/invoice'
import { InvoiceInboxSubStates, InvoiceSourceType } from 'common/server/server_types'

import { InvoiceInboxDetailsDrawerErrorAlert } from 'contractor/pages/@v2/Invoices/components/inbox_details_drawer/alert_failed'
import { InvoiceInboxDetailsDrawerWarnAlert } from 'contractor/pages/@v2/Invoices/components/inbox_details_drawer/alert_warn'
import { InboxAttachments } from 'contractor/pages/@v2/Invoices/components/inbox_details_drawer/inbox_attachments'
import { InvoiceInboxDetailIssueModal } from 'contractor/pages/@v2/Invoices/components/inbox_details_drawer/issue_modal'
import { InboxState } from 'contractor/pages/@v2/Invoices/components/inbox_state'
import { InvoicePdfSelector } from 'contractor/pages/@v2/Invoices/components/invoice_pdf_selector'
import { ConsolidatedInvoiceInboxes } from 'contractor/server/invoices/inbox/consolidated_invoice_inboxes'

interface Props {
  // @todo: add return type to "onLoadInvoiceInbox"
  onLoadInvoiceInbox: (
    sourceId: string,
    sourceType: InvoiceSourceType,
  ) => Promise<[ConsolidatedInvoiceInboxes.InvoiceInbox, ShowInvoiceUploadResponse | ShowInvoiceMailResponse]>

  onSendIssue: (invoiceInboxId: string, text: string) => Promise<void>
  onSendTip: (invoiceInboxId: string, text: string) => Promise<void>
  onIgnore: (invoiceInboxId: string) => Promise<void>
}

function isUpload(
  inboxSource: ShowInvoiceUploadResponse | ShowInvoiceMailResponse,
): inboxSource is ShowInvoiceUploadResponse {
  return 'uploaded_by' in inboxSource
}

function Component(props: Props, ref: React.RefObject<DrawerRef>) {
  const { onLoadInvoiceInbox, onSendIssue, onSendTip, onIgnore } = props

  const pdfSelectorDrawerRef = React.useRef<DrawerRef>()

  const location = useLocation()
  const history = useHistory()
  const queryParams = new URLSearchParams(location.search)
  const invoicesInboxSourceId: string = queryParams.get('invoices_inbox_source_id')
  const invoiceInboxType: InvoiceSourceType = queryParams.get('invoices_inbox_source_type') as InvoiceSourceType

  const [invoiceInbox, setInvoiceInbox] = React.useState<ConsolidatedInvoiceInboxes.InvoiceInbox>(null)
  const [inboxSource, setInboxSource] = React.useState<ShowInvoiceUploadResponse | ShowInvoiceMailResponse>(null)
  const [invoiceInboxLoading, setInvoiceInboxLoading] = React.useState(false)

  const [openIssueModal, setOpenIssueModal] = useState(false)

  React.useEffect(() => {
    async function loadInvoiceInbox() {
      try {
        if (!invoicesInboxSourceId || invoiceInboxLoading) {
          return
        }

        setInvoiceInboxLoading(true)

        const [invoiceInbox, inboxSource] = await onLoadInvoiceInbox(invoicesInboxSourceId, invoiceInboxType)

        setInvoiceInbox(invoiceInbox)
        setInboxSource(inboxSource)
      } catch (error) {
        message.error('Failed to load invoice inbox')
      } finally {
        setInvoiceInboxLoading(false)
      }
    }

    loadInvoiceInbox()
  }, [onLoadInvoiceInbox, invoicesInboxSourceId, invoiceInboxType])

  React.useEffect(() => {
    if (invoicesInboxSourceId) {
      ref.current?.show()
    }
  }, [invoicesInboxSourceId])

  function handleClose() {
    ref.current?.close()
    const params = new URLSearchParams(location.search)

    params.delete('invoices_inbox_source_id')
    params.delete('invoices_inbox_source_type')

    setInvoiceInbox(null)
    setInboxSource(null)

    history.replace({
      search: params.toString(),
    })
  }

  function renderContent() {
    if (invoiceInboxLoading || !invoiceInbox) {
      return (
        <Box display="flex" justifyContent="center">
          <Loading />
        </Box>
      )
    }

    if (invoiceInboxType === 'InvoiceUpload' && inboxSource && isUpload(inboxSource)) {
      return (
        <>
          <Box alignItems="flex-start" width="100%">
            <Typography.Text strong>Uploaded By</Typography.Text>
            <Typography.Paragraph style={{ margin: 0 }}>{inboxSource.uploaded_by?.name}</Typography.Paragraph>
          </Box>
          <Box alignItems="flex-start" width="100%">
            <Typography.Text strong>Uploaded At</Typography.Text>
            <Typography.Paragraph style={{ margin: 0 }}>
              {formatDateString(inboxSource.created_at)}
            </Typography.Paragraph>
          </Box>
        </>
      )
    }

    return (
      <Box>
        <InvoiceMailDetail invoiceMail={inboxSource as ShowInvoiceMailResponse} />
      </Box>
    )
  }

  return (
    <>
      <InvoiceInboxDetailIssueModal
        invoiceInboxId={invoiceInbox?.id}
        openIssueModal={openIssueModal}
        onSendIssue={onSendIssue}
        onCancelIssue={() => setOpenIssueModal(false)}
      />
      <Drawer
        ref={ref}
        onClose={handleClose}
        title={
          <Box display="flex" justifyContent="space-between">
            <Typography.Text>Inbox Detail</Typography.Text>

            {invoiceInbox && (
              <Box display="inline-flex" justifyContent="flex-end">
                {[InvoiceInboxSubStates.FINISHED, InvoiceInboxSubStates.FAILED].includes(invoiceInbox.sub_state) && (
                  <Button
                    type="default"
                    style={{ minWidth: 120, marginRight: 10 }}
                    size="small"
                    onClick={() => {
                      setOpenIssueModal(true)
                    }}
                  >
                    Something is wrong?
                  </Button>
                )}
                <InboxState
                  state={invoiceInbox.state}
                  sub_state={invoiceInbox.sub_state}
                  count={invoiceInbox.invoice_count}
                />
              </Box>
            )}
          </Box>
        }
      >
        {!invoiceInbox || !inboxSource ? (
          <Loading />
        ) : (
          <Box overflowY="auto" display="flex" flexDirection="column" gridGap={16} flexGrow={1} width="100%" p={24}>
            <InvoiceInboxDetailsDrawerWarnAlert
              onSendIssue={(text) => onSendIssue(invoiceInbox.invoice_inbox_id, text)}
              onSendTip={(text) => onSendTip(invoiceInbox.invoice_inbox_id, text)}
              invoiceInbox={invoiceInbox}
            />

            <InvoiceInboxDetailsDrawerErrorAlert
              onIgnore={() => onIgnore(invoiceInbox.invoice_inbox_id)}
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              onCreateInvoices={() => pdfSelectorDrawerRef.current?.show() as any}
              invoiceInbox={invoiceInbox}
            />

            <InboxAttachments consolidatedInbox={invoiceInbox} />

            {renderContent()}
          </Box>
        )}

        <InvoicePdfSelector
          inbox_id={invoiceInbox?.id}
          files={inboxSource?.pdf_files}
          ref={pdfSelectorDrawerRef}
          onClose={handleClose}
        />
      </Drawer>
    </>
  )
}

export const InvoiceInboxDetailsDrawer = React.forwardRef(Component)
