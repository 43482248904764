import React, { useState } from 'react'

import { sortBy, uniqBy } from 'lodash'

import { AutoComplete, AutoCompleteProps } from 'antd'

import { observer } from 'mobx-react-lite'

import { useQuery } from 'common/hooks/use-query'

import { useStores } from 'contractor/hooks/use-stores'

interface CompanyVendorsAutoCompleteProps {
  disabled?: boolean
}

const makeOption = (companyVendor) => {
  const vendorName = companyVendor?.vendor?.name || companyVendor.vendor_name
  return { label: vendorName, value: vendorName }
}

export const CompanyVendorsAutoComplete = observer<AutoCompleteProps>((props) => {
  const { companyVendorStore } = useStores()

  useQuery(companyVendorStore.maybeGetAllCompanyVendors)

  const [searchText, setSearchText] = useState(null)

  const filteredOptions = searchText
    ? companyVendorStore.companyVendors?.filter((companyVendor) => {
        const vendorName = companyVendor?.vendor?.name || companyVendor.vendor_name
        return vendorName.toLocaleLowerCase().includes(searchText.toLocaleLowerCase())
      })
    : companyVendorStore.companyVendors

  const sortedOption = sortBy(filteredOptions.map(makeOption), 'label')
  const uniqOptions = uniqBy(sortedOption, 'label')

  return (
    <AutoComplete
      disabled={props?.disabled}
      onSearch={setSearchText}
      options={uniqOptions}
      style={{ width: '100%', minWidth: 180 }}
      autoFocus
      defaultOpen
      {...props}
    />
  )
})
