import React from 'react'

import { useLocation } from 'react-router-dom'

import { Empty, Typography } from 'antd'

import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'

import { Box, FlexBoxY } from 'common/components/boxes'
import { Loading } from 'common/components/Loading'
import { OrdersList } from 'common/components/OrdersList'
import { Page } from 'common/components/Page'
import { PsqlTable } from 'common/components/PsqlTable'
import { ResponsiveTable } from 'common/components/ResponsiveTable'
import { RibbonFilter } from 'common/components/RibbonFilter'
import { SearchInput } from 'common/components/SearchInput'
import { Visibility } from 'common/components/Visibility'
import { usePersistentFilters } from 'common/contexts/persistent_filters'
import { useMaybeOpenIntoNewTab } from 'common/hooks/use-maybe-open-into-new-tab'
import { useQuery } from 'common/hooks/use-query'
import { Events, trackEvent } from 'common/pendo/event_tracking'
import { CommitmentStatuses } from 'common/server/server_types'

import ProjectSelector from 'contractor/components/ProjectSelector'
import { useStores } from 'contractor/hooks/use-stores'
import { Actions } from 'contractor/pages/@v2/Commitments/Components/CommitmentListActions/actions'
import { OrdersFilters } from 'contractor/pages/@v2/Commitments/Components/CommitmentListFilters/filters'
import { ConsolidatedOrders } from 'contractor/server/orders'
import { ShowProjectResponse } from 'contractor/server/projects'

interface Props {
  handleSelectDelivery?: (id?: string) => void
  canSelectDelivery?: boolean
}

function Content(props: Props) {
  const {
    notificationStore,
    commitmentStore: { listStore },
    projectStore,
  } = useStores()

  const source = window.location.pathname
  const location = useLocation()
  const persistentFilters = usePersistentFilters()
  const { maybeOpenIntoNewTab } = useMaybeOpenIntoNewTab()
  function handleChangeProject(project: ShowProjectResponse) {
    persistentFilters.handleChangeFilters({ project_id: project?.id })
  }

  async function init() {
    const initialProjectId =
      new URLSearchParams(location.search).get('project_id') || sessionStorage['selectedProjectId']

    if (initialProjectId && initialProjectId.length > 0) {
      listStore.setFilter('project_id', initialProjectId, true, true)
    }

    return persistentFilters.init(new URLSearchParams())
  }

  useQuery(listStore.fetchFacets)
  const { isLoading = true } = useQuery(() => init(), [])

  const renderMobileCard = React.useCallback((item: ConsolidatedOrders.Order) => {
    const notificationCount = notificationStore.notificationCountByObject(item.id)

    return (
      <OrdersList.MobileCard
        notificationCount={notificationCount}
        item={item}
        onSelectDelivery={props.handleSelectDelivery}
        canSelectDelivery={props.canSelectDelivery}
        showVendor
      />
    )
  }, [])

  const ribbonOptions = React.useMemo(
    () => [
      { label: 'All', filter: '' },
      { label: 'Drafted', filter: CommitmentStatuses.DRAFT },
      { label: 'Confirmed', filter: CommitmentStatuses.CONFIRMED },
      { label: 'Partially delivered', filter: CommitmentStatuses.PARTIALLY_DELIVERED },
      { label: 'Delivered', filter: CommitmentStatuses.DELIVERED },
      { label: 'Cancelled', filter: CommitmentStatuses.CANCELLED, ignoreWhenCountingAll: true },
    ],
    [],
  )

  function handleSearch(value: string) {
    persistentFilters.handleChangeFilters({ search: value }, false)
  }

  function pushToCommitment({ row }) {
    const url = `/commitment/${row.commitment_id}`

    maybeOpenIntoNewTab(url)
  }

  if (isLoading) return <Loading />

  return (
    <Page>
      <Page.Header display="grid" gridGap={16}>
        <Visibility.Show breakpoint="md">
          <FlexBoxY width="100%">
            <Box display="flex" gridGap={8} alignItems="center" justifyContent="space-between" width="100%">
              <ProjectSelector showSettings showAddProject onChange={handleChangeProject} />
              <Actions projectId={projectStore?.selectedProject?.id} />
            </Box>
            <Box
              onClick={() => trackEvent(Events.CLICK_COMMITMENTS_FILTER, source)}
              mt="8"
              display="flex"
              gridGap={8}
              alignItems="center"
              justifyContent="space-between"
              width="100%"
            >
              <SearchInput value={listStore.searchState.search} onSearch={handleSearch} />
              <OrdersFilters />
            </Box>
          </FlexBoxY>
        </Visibility.Show>

        <Visibility.Hidden breakpoint="md">
          <Box display="flex" gridGap={8} alignItems="center" justifyContent="space-between">
            <ProjectSelector showSettings showAddProject onChange={handleChangeProject} />
            <SearchInput value={listStore.searchState.search} onSearch={handleSearch} />
            <OrdersFilters />
            <Actions projectId={projectStore?.selectedProject?.id} />
          </Box>
        </Visibility.Hidden>

        <Box width="100%" style={{ overflow: 'auto' }}>
          <RibbonFilter
            showCounts
            value={listStore.searchState.filters['status'] || []}
            counts={listStore.stateCounts || {}}
            onChange={(value) => persistentFilters.handleChangeFilters({ status: value })}
            options={ribbonOptions}
            boxProps={{ overflowX: 'auto' }}
          />
        </Box>
      </Page.Header>
      <Page.Content p={0} px={{ _: 0, md: 12, lg: 16 }} pb={24} pt={{ _: 0, md: 16 }} height="100%">
        {listStore.isFetching && listStore.records.length === 0 && listStore.searchState.page === 1 ? (
          <Loading />
        ) : listStore.records.length === 0 ? (
          <Box
            p={{ _: 12, lg: 24 }}
            height="100%"
            width="100%"
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
          >
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={
                <Typography.Title level={5} style={{ textAlign: 'center' }}>
                  Sorry! No Items were found with these search criteria.
                </Typography.Title>
              }
            />
          </Box>
        ) : (
          <ResponsiveTable
            Table={PsqlTable}
            data={toJS(listStore.records) ?? []}
            tableName="AllCommitments"
            hasMore={listStore.hasMore}
            loadMore={listStore.fetchNextPage}
            onLoadMore={listStore.fetchNextPage}
            renderMobileCard={renderMobileCard}
            onClickRow={pushToCommitment}
          />
        )}
      </Page.Content>
    </Page>
  )
}

export const CommitmentsContent = observer(Content)
