import React from 'react'

import styled from '@emotion/styled/dist/emotion-styled.cjs'

import { Divider, Typography } from 'antd'

import { Box } from 'common/components/boxes'
import OrderStateTag from 'common/components/statuses/order_state'
import theme from 'common/styles/theme'

const WrapperAttributes = styled(Box)`
  & > div:last-child .ant-divider {
    display: none;
  }
`

const textStyle = { fontSize: 12, color: '#000000' }
const textLabelStyle = { marginRight: 4, fontSize: 12 }

export const OrderItemSm = ({ components, item }) => (
  <Box flexDirection="row" py="6px" justifyContent="space-between">
    <Box flexDirection="row" alignItems="flex-start">
      <Typography.Text style={textStyle}>
        <components.Highlight hit={item} attribute="order_number" />:{' '}
        <components.Highlight hit={item} attribute="name" />
      </Typography.Text>
    </Box>
    <WrapperAttributes flexDirection="column" justifyContent="flex-start" flexWrap="wrap">
      <OrderStateTag state={item.state} sub_state={item.sub_state} theme={theme} fontSize={12} />

      <Divider type="vertical" />

      <Typography.Text type="secondary" style={textLabelStyle}>
        PROJECT:
      </Typography.Text>
      <Typography.Text style={textStyle}>
        <components.Highlight hit={item} attribute="project_name" />
      </Typography.Text>
    </WrapperAttributes>
  </Box>
)
