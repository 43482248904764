import React from 'react'

import { ForwardOutlined } from '@ant-design/icons'
import { Typography, Tooltip } from 'antd'

import { OrderHit } from 'common/server/orders'
import { OrderStates } from 'common/server/server_types'

type OrderedByProps = {
  orderHit: OrderHit
}

const OPACITY_STATES = [OrderStates.CANCELLED, OrderStates.DRAFT, OrderStates.REQUESTED, OrderStates.QUOTED]

export const OrderedBy = ({ orderHit }: OrderedByProps) => {
  if (!orderHit.ordered_by_column) {
    return null
  }

  const opacity = OPACITY_STATES.includes(orderHit.state) ? { opacity: 0.3 } : {}

  return (
    <Typography.Text
      style={{
        display: 'flex',
        alignItems: 'center',
        ...opacity,
      }}
    >
      {orderHit.is_quick_created && (
        <Tooltip title="This PO was Quick Created during Invoice Approval">
          <ForwardOutlined style={{ fontSize: 20, marginRight: 4 }} />
        </Tooltip>
      )}
      <span>{orderHit.ordered_by_column}</span>
    </Typography.Text>
  )
}
