import React from 'react'

import moment from 'moment'

import { Col, DatePicker, Divider, InputNumber, Row, Space, Typography } from 'antd'

import { Box, FlexBoxX, FlexBoxY } from 'common/components/boxes'
import { useMediaQuery } from 'common/hooks/use-media-query'
import { OrderMaterial } from 'common/server/orders'

import ItemTitle from 'contractor/components/DeliveryDetail/item_title'

interface MissingMaterialsProps {
  orderMaterials: OrderMaterial[]
  disableEditing?: boolean
  updateIncompleteItems: (id: string, value: number | string, type: string) => void
  markItemAsResolved: (id: string) => void
}

export const IncompleteItemsTable = (props: MissingMaterialsProps) => {
  const { orderMaterials, disableEditing, updateIncompleteItems, markItemAsResolved } = props
  const isMobileScreen = useMediaQuery('md')

  if (isMobileScreen) {
    return (
      <Box>
        <Divider type="horizontal" style={{ width: '95%', marginBottom: 8 }} />
        <ItemTitle text="Items" />
        {orderMaterials.map((orderMaterial) => (
          <React.Fragment key={orderMaterial.id}>
            <Row gutter={[16, 16]} style={{ marginBottom: 8 }}>
              <Col span={24}>
                <FlexBoxY alignItems="start" minHeight={32} width="100%">
                  <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
                    <Typography.Text>{orderMaterial?.company_material?.description}</Typography.Text>
                    <Typography.Link type="secondary" onClick={() => markItemAsResolved(orderMaterial.id)}>
                      {orderMaterial?.has_open_issue ? 'Mark as resolved' : 'Hide'}
                    </Typography.Link>
                  </Box>
                  <Typography.Text type="secondary">Quantity: {orderMaterial?.quantity}</Typography.Text>
                  {orderMaterial.company_note && (
                    <Space>
                      <Typography.Text type="secondary">{orderMaterial.company_note}</Typography.Text>
                    </Space>
                  )}
                </FlexBoxY>
              </Col>

              <Col span={24}>
                <FlexBoxX>
                  <FlexBoxY alignItems="flex-start" width="33%" mr={8}>
                    <Typography.Text type="secondary">Missing</Typography.Text>
                    <InputNumber
                      min={0}
                      max={orderMaterial.quantity}
                      value={orderMaterial.missing_quantity}
                      onChange={(value) => updateIncompleteItems(orderMaterial.id, value, 'missing_quantity')}
                      disabled={disableEditing}
                      style={{ width: '100%' }}
                    />
                  </FlexBoxY>

                  <FlexBoxY alignItems="flex-start" width="33%" mr={8}>
                    <Typography.Text type="secondary">Incorrect</Typography.Text>
                    <InputNumber
                      min={0}
                      max={orderMaterial.quantity}
                      value={orderMaterial.incorrect_quantity}
                      onChange={(value) => updateIncompleteItems(orderMaterial.id, value, 'incorrect_quantity')}
                      disabled={disableEditing}
                      style={{ width: '100%' }}
                    />
                  </FlexBoxY>

                  <FlexBoxY alignItems="flex-start" width="33%" mr={8}>
                    <Typography.Text type="secondary">Broken</Typography.Text>
                    <InputNumber
                      min={0}
                      max={orderMaterial.quantity}
                      value={orderMaterial.broken_quantity}
                      onChange={(value) => updateIncompleteItems(orderMaterial.id, value, 'broken_quantity')}
                      disabled={disableEditing}
                      style={{ width: '100%' }}
                    />
                  </FlexBoxY>
                </FlexBoxX>
              </Col>

              <Col span={24}>
                <FlexBoxY alignItems="flex-start" width="100%" mr={16}>
                  <Typography.Text type="secondary">Backordered</Typography.Text>
                  <Space.Compact block>
                    <InputNumber
                      min={0}
                      max={orderMaterial.quantity}
                      value={orderMaterial.back_ordered_quantity}
                      onChange={(value) => updateIncompleteItems(orderMaterial.id, value, 'back_ordered_quantity')}
                      disabled={disableEditing}
                      style={{ width: '30%' }}
                    />
                    <DatePicker
                      value={
                        orderMaterial.expected_back_ordered_date
                          ? moment(orderMaterial.expected_back_ordered_date)
                          : undefined
                      }
                      onChange={(value) =>
                        updateIncompleteItems(
                          orderMaterial.id,
                          value?.toISOString() || null,
                          'expected_back_ordered_date',
                        )
                      }
                      disabled={disableEditing}
                      style={{ width: '70%' }}
                    />
                  </Space.Compact>
                </FlexBoxY>
              </Col>
            </Row>
            <Divider type="horizontal" style={{ width: '95%' }} />
          </React.Fragment>
        ))}
      </Box>
    )
  }

  return (
    <Box>
      <Divider type="horizontal" style={{ width: '95%', marginBottom: 8 }} />
      {orderMaterials.map((orderMaterial) => (
        <React.Fragment key={orderMaterial.id}>
          <Row gutter={[16, 16]} style={{ marginBottom: 8 }}>
            <Col span={24}>
              <FlexBoxY alignItems="start" minHeight={32} width="100%">
                <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
                  <Typography.Text>{orderMaterial?.company_material?.description}</Typography.Text>

                  <Typography.Link type="secondary" onClick={() => markItemAsResolved(orderMaterial.id)}>
                    Mark as resolved
                  </Typography.Link>
                </Box>
                <Typography.Text type="secondary">Quantity: {orderMaterial?.quantity}</Typography.Text>
                {orderMaterial.company_note && (
                  <Space>
                    <Typography.Text type="secondary">{orderMaterial.company_note}</Typography.Text>
                  </Space>
                )}
              </FlexBoxY>
            </Col>

            <Col span={24}>
              <FlexBoxX>
                <FlexBoxY alignItems="flex-start" width="25%" mr={8}>
                  <Typography.Text type="secondary">Missing</Typography.Text>
                  <InputNumber
                    data-cy="missing-quantity"
                    min={0}
                    max={orderMaterial.quantity}
                    value={orderMaterial.missing_quantity}
                    onChange={(value) => updateIncompleteItems(orderMaterial.id, value, 'missing_quantity')}
                    disabled={disableEditing}
                    style={{ width: '100%' }}
                  />
                </FlexBoxY>

                <FlexBoxY alignItems="flex-start" width="25%" mr={8}>
                  <Typography.Text type="secondary">Incorrect</Typography.Text>
                  <InputNumber
                    data-cy="incorrect-quantity"
                    min={0}
                    max={orderMaterial.quantity}
                    value={orderMaterial.incorrect_quantity}
                    onChange={(value) => updateIncompleteItems(orderMaterial.id, value, 'incorrect_quantity')}
                    disabled={disableEditing}
                    style={{ width: '100%' }}
                  />
                </FlexBoxY>

                <FlexBoxY alignItems="flex-start" width="25%" mr={8}>
                  <Typography.Text type="secondary">Broken</Typography.Text>
                  <InputNumber
                    data-cy="broken-quantity"
                    min={0}
                    max={orderMaterial.quantity}
                    value={orderMaterial.broken_quantity}
                    onChange={(value) => updateIncompleteItems(orderMaterial.id, value, 'broken_quantity')}
                    disabled={disableEditing}
                    style={{ width: '100%' }}
                  />
                </FlexBoxY>

                <FlexBoxY alignItems="flex-start" width="25%" mr={16}>
                  <Typography.Text type="secondary">Backordered</Typography.Text>
                  <Space.Compact block>
                    <InputNumber
                      data-cy="back-ordered-quantity"
                      min={0}
                      max={orderMaterial.quantity}
                      value={orderMaterial.back_ordered_quantity}
                      onChange={(value) => updateIncompleteItems(orderMaterial.id, value, 'back_ordered_quantity')}
                      disabled={disableEditing}
                      style={{ width: '30%' }}
                    />
                    <DatePicker
                      data-cy="expected-back-ordered-date"
                      value={
                        orderMaterial.expected_back_ordered_date
                          ? moment(orderMaterial.expected_back_ordered_date)
                          : undefined
                      }
                      onChange={(value) =>
                        updateIncompleteItems(
                          orderMaterial.id,
                          value?.toISOString() || null,
                          'expected_back_ordered_date',
                        )
                      }
                      disabled={disableEditing}
                      style={{ width: '70%' }}
                    />
                  </Space.Compact>
                </FlexBoxY>
              </FlexBoxX>
            </Col>
          </Row>
          <Divider type="horizontal" style={{ width: '95%' }} />
        </React.Fragment>
      ))}
    </Box>
  )
}
