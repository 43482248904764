import React from 'react'

import { IntegrationsSourceSystem } from 'common/server/server_types'

type IntegrationIconsProps = {
  type: IntegrationsSourceSystem
  integrationName?: string
} & React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>

export const IntegrationIcons = ({ type, integrationName, ...props }: IntegrationIconsProps) => {
  const imgUrl = {
    [IntegrationsSourceSystem.QBO]: 'https://www.agaveapi.com/assets/img/link/logo-square-quickbooks.png',
    [IntegrationsSourceSystem.QBD]: 'https://www.agaveapi.com/assets/img/link/logo-square-quickbooks.png',
    [IntegrationsSourceSystem.PROCORE]: 'https://www.agaveapi.com/assets/img/link/logo-square-procore.png',
    [IntegrationsSourceSystem.FOUNDATION_HOSTED]: 'https://www.agaveapi.com/assets/img/link/logo-square-foundation.png',
    [IntegrationsSourceSystem.CMIC]: 'https://www.agaveapi.com/assets/img/link/logo-square-cmic.png',
    [IntegrationsSourceSystem.SPECTRUM]: 'https://www.agaveapi.com/assets/img/link/logo-square-viewpoint-spectrum.png',
    [IntegrationsSourceSystem.ACUMATICA]: 'https://www.agaveapi.com/assets/img/link/logo-square-acumatica.png',
  }

  let imgSrc = imgUrl[type]

  if (!imgSrc && integrationName) {
    imgSrc = imgUrl[IntegrationsSourceSystem[integrationName]]

    if (!imgSrc) imgSrc = imgUrl[IntegrationsSourceSystem[integrationName.toUpperCase()]]
  }

  return <img src={imgSrc} width={48} height={48} style={{ marginRight: 3, marginLeft: 5 }} {...props} />
}
