import React, { useRef, useState } from 'react'

import { useHistory } from 'react-router-dom'

import { groupBy } from 'lodash'

import { UnorderedListOutlined, AppstoreOutlined } from '@ant-design/icons'
import { Button, Col, Row, Select, Typography, Segmented, message } from 'antd'

import { observer } from 'mobx-react-lite'

import { Box } from 'common/components/boxes'
import { DrawerRef } from 'common/components/Drawer'
import { Loading } from 'common/components/Loading'
import { Page } from 'common/components/Page'
import { SearchInput } from 'common/components/SearchInput'
import { Visibility } from 'common/components/Visibility'
import { useQuery } from 'common/hooks/use-query'

import { Flatfile } from 'contractor/components/Flatfile'
import { ManageGroupDrawer, useManageGroupDrawer, ManageGroupDrawerForm } from 'contractor/components/ManageGroupDrawer'
import { filterTextByFields } from 'contractor/helpers/filter-text-by-fields'
import { useStores } from 'contractor/hooks/use-stores'
import { IndexProject } from 'contractor/server/projects'

import { CreateProjectModal } from './create_project_modal'
import { GridView } from './grid_view'
import { ListView } from './list_view'

enum ViewType {
  GRID = 'GRID',
  LIST = 'LIST',
}

const PROJECTS_VIEW_TYPE_KEY = 'projectViewTypeKey'

const getStorageView = () => (localStorage.getItem(PROJECTS_VIEW_TYPE_KEY) as ViewType) || ViewType.GRID

const Projects = observer(() => {
  const { dashboardStore, projectStore, projectGroupsStore, userStore, companySettingStore, flatfileStore } =
    useStores()

  const createGroupRef = useRef<DrawerRef>()
  const manageGroupDrawer = useManageGroupDrawer()

  const [active, setActive] = useState('active')
  const [searchText, setSearchText] = useState('')
  const [viewType, setViewType] = useState<ViewType>(getStorageView())

  const drawerCreateProjectRef = useRef<DrawerRef>()

  const history = useHistory()

  // Load the projects on load
  const { isLoading } = useQuery(() => {
    return Promise.all([
      dashboardStore.getOrderCountsByProject(),
      projectGroupsStore.indexProjectGroups(),
      companySettingStore.maybeIndexUsers(),
      manageGroupDrawer.dataLoaders.projects(),
      manageGroupDrawer.dataLoaders.users(),
    ])
  })

  const handleSearchText = (text = '') => setSearchText(text?.toLocaleLowerCase().trim())

  const handleFilterTextProject = (project: IndexProject) => {
    return filterTextByFields({ fields: ['name', 'project_group_name', 'project_id'], obj: project, searchText })
  }

  const handleFilterTextGroup = (projects: IndexProject[]) => {
    return projects.some(handleFilterTextProject)
  }

  const handleChangeViewType = (type: ViewType) => {
    setViewType(type as ViewType)
    localStorage.setItem(PROJECTS_VIEW_TYPE_KEY, type)
  }

  const projectList = active === 'active' ? projectStore.projects : projectStore.inactiveProjects

  const projects = projectList.map((project) => {
    const projectGroup = projectGroupsStore.projectGroups.find((group) => group?.id === project?.project_group_id)
    return {
      ...project,
      project_group_name: projectGroup?.name || 'Ungrouped projects',
    }
  })

  const projectsGroupedByGroup = groupBy(projects, 'project_group_name')

  const projectGroups = Object.keys(projectsGroupedByGroup)
    .sort()
    .filter((projectGroupName) => handleFilterTextGroup(projectsGroupedByGroup[projectGroupName]))

  if (isLoading) {
    return <Loading />
  }

  const refreshTable = () => {
    return Promise.all([projectStore.indexProjects(), projectGroupsStore.indexProjectGroups()])
  }

  function handleOpenCreateGroup() {
    createGroupRef.current?.show()
  }

  function handleCloseCreateGroup() {
    createGroupRef.current?.close()
  }

  async function handleSubmitNewGroup(values: ManageGroupDrawerForm) {
    try {
      await projectGroupsStore.createProjectGroups({
        name: values.name,
        project_ids: values.selectedProjectsIds,
        user_ids: values.selectedUsersIds,
      })

      message.success('Successfully added new group')

      if (values.selectedUsersIds.includes(userStore.currentUser?.id)) {
        userStore.showUser()
      }

      handleCloseCreateGroup()

      manageGroupDrawer.dataLoaders.projects()
    } catch (error) {
      console.error('Project group creation error', error)
      message.error(`Unable to create project group`)
    }
  }

  return (
    <Page>
      <Page.Header>
        <Row gutter={[20, 20]}>
          <Col span={24}>
            <Box display="flex" alignItems="center" justifyContent="space-between">
              <Typography.Title level={3} style={{ margin: 0 }}>
                Projects
              </Typography.Title>

              <Box display="flex" alignItems="flex-start">
                <Visibility.Hidden>
                  <Select
                    aria-label="select-active-inactive-projects"
                    data-cy="select-active-inactive-projects"
                    style={{ width: '150px', marginRight: 20 }}
                    onChange={setActive}
                    value={active}
                  >
                    <Select.Option value="active">Active projects</Select.Option>
                    <Select.Option value="inactive">Inactive projects</Select.Option>
                  </Select>
                </Visibility.Hidden>

                {userStore.canManageProjects && (
                  <>
                    <Flatfile
                      onSuccess={projectStore.indexProjects}
                      openUpload={flatfileStore.openUploadProjects}
                      buttonProps={{ style: { margin: '0 10px' } }}
                    />

                    <Button data-cy="add-project" onClick={() => drawerCreateProjectRef.current?.show()} type="primary">
                      Add Project
                    </Button>

                    <Button
                      data-cy="add-group"
                      style={{ marginLeft: 10 }}
                      onClick={handleOpenCreateGroup}
                      type="primary"
                    >
                      Add Group
                    </Button>
                  </>
                )}

                <CreateProjectModal
                  onCancel={() => drawerCreateProjectRef.current?.close()}
                  onSubmit={(projectId) => {
                    // Only send to orders if the user has access to the created project
                    if (projectStore.projects.some((project) => project.id === projectId)) {
                      history.push(`/orders?project_id=${projectId}`)
                    }
                  }}
                  ref={drawerCreateProjectRef}
                />
              </Box>
            </Box>
          </Col>

          <Col span={24}>
            <Box width="100%" display="inline-flex" alignItems="center" style={{ gap: 16 }}>
              <SearchInput data-cy="search-projects-input" onSearch={handleSearchText} />
              <Segmented
                value={viewType}
                onChange={handleChangeViewType}
                options={[
                  {
                    value: ViewType.GRID,
                    icon: <AppstoreOutlined />,
                  },
                  {
                    value: ViewType.LIST,
                    icon: <UnorderedListOutlined />,
                  },
                ]}
              />
            </Box>
          </Col>
        </Row>
      </Page.Header>

      <Page.Content p={0} display="flex" flexDirection="column">
        {viewType === ViewType.GRID ? (
          <GridView
            userId={userStore.currentUser?.id}
            projectGroups={projectGroups}
            projectsGroupedByGroup={projectsGroupedByGroup}
            onFilterTextProject={handleFilterTextProject}
          />
        ) : (
          <ListView
            userId={userStore.currentUser?.id}
            projectGroups={projectGroups}
            projectsGroupedByGroup={projectsGroupedByGroup}
            onFilterTextProject={handleFilterTextProject}
          />
        )}
      </Page.Content>

      <ManageGroupDrawer
        ref={createGroupRef}
        onClose={refreshTable}
        projects={manageGroupDrawer.projects}
        users={manageGroupDrawer.users}
        onSubmit={handleSubmitNewGroup}
      />
    </Page>
  )
})

export default Projects
