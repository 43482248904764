import React, { useEffect } from 'react'

import _ from 'lodash'

import { Form } from 'antd'

import { Visibility } from 'common/components/Visibility'

import { WatchersDrawer } from 'contractor/components/WatchersDrawer'
import { makeWatcherOption } from 'contractor/components/WatchersSelect'
import { useStores } from 'contractor/hooks/use-stores'

import { usePrivateOrderForm } from './context'

export const Watchers = () => {
  const { companySettingStore, projectStore, orderStore, userStore } = useStores()
  const { form } = usePrivateOrderForm()
  const projectId = Form.useWatch('projectId', form)

  useEffect(() => {
    const hasOnlyMaterialRequesterPermissions = !userStore.canSendAndUpdateOrders && !userStore.canSendAndUpdateRfqs

    const selectedProject = projectStore.projects.find((project) => project.id === projectId)
    const defaultWatchers = orderStore.selectedOrder?.watcher_ids || selectedProject?.default_watcher_user_ids || []
    const watcherIds = _.uniq([userStore.currentUser?.id, ...defaultWatchers])

    const watchers = companySettingStore.possibleUsers
      .filter((user) => watcherIds.includes(user.id))
      .map((user) => makeWatcherOption(user, !watcherIds.includes(user.id) || !hasOnlyMaterialRequesterPermissions))
    form.setFieldsValue({ watchers })
  }, [
    companySettingStore?.possibleUsers,
    orderStore.selectedOrder,
    projectId,
    projectStore.projects,
    userStore.currentUser?.id,
    userStore.canSendAndUpdateOrders,
    userStore.canSendAndUpdateRfqs,
  ])

  return (
    <Visibility.Hidden>
      <Form.Item name="watchers" style={{ margin: 0 }}>
        <WatchersDrawer />
      </Form.Item>
    </Visibility.Hidden>
  )
}
