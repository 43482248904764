import React from 'react'

import { Button, Typography, Switch, Space } from 'antd'

import { observer } from 'mobx-react-lite'

import { Box } from 'common/components/boxes'
import { SelectFilter } from 'common/components/table/postgres_filters'
import { usePersistentFilters } from 'common/contexts/persistent_filters'

import { useStores } from 'contractor/hooks/use-stores'

export const CompanyMaterialsFilters = observer(() => {
  const {
    companyMaterialStore: { listStore },
    companySettingStore,
  } = useStores()

  const { company_attributes } = companySettingStore.companyMaterialConfiguration
  const costCodeSettings = companySettingStore.otherSettings?.cost_code_settings

  const values = listStore.searchState.filters
  const facets = listStore.facets

  const showCostCodeFilter = company_attributes?.includes('cost_code_id')
  const showPhaseCodeFilter =
    showCostCodeFilter && costCodeSettings?.phase_code_enabled && !costCodeSettings?.independent_phase_codes_enabled
  const showClassCodeFilter = showCostCodeFilter && costCodeSettings?.class_enabled
  const showProjectsFilter = companySettingStore.companyMaterialConfiguration.company_attributes.includes('project_ids')

  const optionsFactory = React.useCallback(
    (key: string) => {
      return (facets[key] || []).map((x: { [key: string]: string }) => ({
        label: x.label,
        value: x.value,
      }))
    },
    [facets],
  )

  const persistentFilters = usePersistentFilters()

  return (
    <>
      <Box display="flex" flexDirection="column" p="12" gridGap={16}>
        <Box width="100%" display="flex" flexDirection="column">
          <Typography.Text type="secondary">Group</Typography.Text>

          <SelectFilter
            items={optionsFactory('group')}
            selected={values['group']}
            onChange={(value) => persistentFilters.handleChangeFilters({ group: value })}
            attribute="group"
            placeholder="Select group"
            isSearchable
          />
        </Box>

        <Box width="100%" display="flex" flexDirection="column">
          <Typography.Text type="secondary">Sub group</Typography.Text>

          <SelectFilter
            items={optionsFactory('sub_group')}
            selected={values['sub_group']}
            onChange={(value) => persistentFilters.handleChangeFilters({ sub_group: value })}
            attribute="sub_group"
            placeholder="Select sub group"
            isSearchable
          />
        </Box>

        {showProjectsFilter && (
          <Box width="100%" display="flex" flexDirection="column">
            <Typography.Text type="secondary">Project</Typography.Text>

            <SelectFilter
              items={optionsFactory('projects')}
              selected={values['projects']}
              onChange={(value) => persistentFilters.handleChangeFilters({ projects: value })}
              attribute="projects"
              placeholder="Select projects"
              isSearchable
            />
          </Box>
        )}

        {showPhaseCodeFilter && (
          <Box width="100%" display="flex" flexDirection="column">
            <Typography.Text type="secondary">Phase Code</Typography.Text>

            <SelectFilter
              items={optionsFactory('cost_phase')}
              selected={values['cost_phase']}
              onChange={(value) => persistentFilters.handleChangeFilters({ cost_phase: value })}
              attribute="cost_phase"
              placeholder="Select phase code"
              isSearchable
              formatter={(value) => value}
            />
          </Box>
        )}

        {showCostCodeFilter && (
          <Box width="100%" display="flex" flexDirection="column">
            <Typography.Text type="secondary">Cost Code</Typography.Text>

            <SelectFilter
              items={optionsFactory('cost_code')}
              selected={values['cost_code']}
              onChange={(value) => persistentFilters.handleChangeFilters({ cost_code: value })}
              attribute="cost_code"
              placeholder="Selec cost code"
              isSearchable
              formatter={(value) => value}
            />
          </Box>
        )}

        {showClassCodeFilter && (
          <Box width="100%" display="flex" flexDirection="column">
            <Typography.Text type="secondary">Cost Class</Typography.Text>

            <SelectFilter
              items={optionsFactory('cost_clazz')}
              selected={values['cost_clazz']}
              onChange={(value) => persistentFilters.handleChangeFilters({ cost_clazz: value })}
              attribute="cost_clazz"
              placeholder="Select cost class"
              isSearchable
              formatter={(value) => value}
            />
          </Box>
        )}

        <Box width="100%" display="flex" flexDirection="column">
          <Typography.Text type="secondary">Tags</Typography.Text>

          <SelectFilter
            items={optionsFactory('tags')}
            selected={values['tags']}
            onChange={(value) => persistentFilters.handleChangeFilters({ tags: value })}
            attribute="tags"
            placeholder="Select tag"
            isSearchable
            formatter={(value) => value}
          />
        </Box>

        <Space>
          <Switch checked={values['active']} onChange={(active) => persistentFilters.handleChangeFilters({ active })} />
          <Typography.Text>Actives only</Typography.Text>
        </Space>

        <Button
          style={{ marginTop: 16, width: '100%' }}
          type="primary"
          data-cy="clear-filters"
          onClick={() => persistentFilters.handleClearFilters(['active'])}
          disabled={persistentFilters.appliedFiltersCount === 0}
        >
          Clear All
        </Button>
      </Box>
    </>
  )
})
