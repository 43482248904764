import React from 'react'

import { connectInfiniteHits } from 'react-instantsearch-dom'

import { ProjectOutlined } from '@ant-design/icons'
import { Space, Typography } from 'antd'

import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'

import { Box } from 'common/components/boxes'
import { ResponsiveTable } from 'common/components/ResponsiveTable'
import { CellOrderNumber, CellOrderState } from 'common/components/table/cells'
import { useMaybeOpenIntoNewTab } from 'common/hooks/use-maybe-open-into-new-tab'
import { usePushToOrder } from 'common/hooks/use-push-to-order'
import { useRefreshInfiniteList } from 'common/hooks/use-refresh-infinite-list'

import { allRefinements } from 'contractor/components/AlgoliaTagsFilter'
import { useStores } from 'contractor/hooks/use-stores'
import Columns from 'contractor/pages/Materials/table_columns'
import { StateResults } from 'contractor/pages/Orders/state_results'
import { OrderMaterialHit } from 'contractor/server/order_materials'

import { useOrderMaterials } from './context'

interface OrderMaterialTableProps {
  hasMore: boolean
  refineNext: () => void
  hits: OrderMaterialHit[]
}

const OrderMaterialTable = observer<OrderMaterialTableProps>(({ hits, hasMore, refineNext }) => {
  const { orderMaterialStore, notificationStore } = useStores()
  const { getUrl } = usePushToOrder()

  const { maybeSetTableColumns } = useOrderMaterials()
  const { maybeOpenIntoNewTab } = useMaybeOpenIntoNewTab()

  const goToOrder = ({ row }) => {
    const url = getUrl({
      orderId: row.order.id,
      orderPackageId: row.order_package_id,
      state: row.order.state,
      subState: row.order.sub_state,
    })
    maybeOpenIntoNewTab(url)
  }

  React.useEffect(() => {
    orderMaterialStore.setHits(hits)
  }, [orderMaterialStore.searchState, hits])

  const data = () => {
    let data: OrderMaterialHit[] = orderMaterialStore?.hits

    if (!orderMaterialStore.selectedRibbonFilter || orderMaterialStore.selectedRibbonFilter === 'All') {
      data = data.filter(
        (item) => allRefinements.includes(item?.order?.state) || allRefinements.includes(item?.order?.sub_state),
      )
    }

    return toJS(data)
  }

  return (
    <ResponsiveTable
      data={data()}
      columns={Columns()}
      tableName="OrderMaterials"
      hasMore={hasMore}
      onClickRow={goToOrder}
      loadMore={refineNext}
      index_name={orderMaterialStore.searchKey.index_name}
      replicas={orderMaterialStore.replicas}
      setTableColumns={maybeSetTableColumns}
      renderMobileCard={(row) => {
        const order = row?.order
        const notificationCount = notificationStore.notificationCountByObject(order?.id)
        const companyMaterial = row?.company_material

        return (
          <Space style={{ width: '100%' }} direction="vertical">
            <Typography.Text>{companyMaterial?.description}</Typography.Text>
            <Box display="flex" alignItems="center">
              <Typography.Text type="secondary" style={{ fontSize: 12 }}>{`QTY: ${
                companyMaterial?.quantity || 'N/A'
              }`}</Typography.Text>
              {!!companyMaterial?.product_identifier && (
                <Typography.Text type="secondary" style={{ fontSize: 12 }}>
                  &nbsp;{`| ID: ${companyMaterial?.product_identifier}`}
                </Typography.Text>
              )}
              <Typography.Text type="secondary" style={{ fontSize: 12 }}>
                &nbsp;{`| SIZE: ${companyMaterial?.size || 'N/A'}`}
              </Typography.Text>
            </Box>

            <CellOrderNumber
              orderName={row?.order_name}
              orderNumber={row?.order?.order_number}
              orderId={row?.order?.id}
              orderPackageId={row?.order_package_id}
              state={row?.order?.state}
              subState={row?.order?.sub_state}
            />
            <CellOrderState order={order} notificationsCount={notificationCount} />

            <Space>
              <ProjectOutlined />
              <Typography.Text>{row?.project_name}</Typography.Text>
            </Space>
          </Space>
        )
      }}
    />
  )
})

const CustomHits = connectInfiniteHits(OrderMaterialTable)

const OrderMaterialTableWrapper = observer(() => {
  const { projectStore, orderMaterialStore } = useStores()
  const { customHitsKey } = useRefreshInfiniteList({
    forceRefreshHits: orderMaterialStore.forceRefreshHits,
    refreshHits: orderMaterialStore.refreshHits,
    attempts: 5,
  })

  // HACK: Need the selectedProject to at least be null, otherwise we may not have loaded the appropriate project
  // yet and need to do a second algolia query
  if (projectStore.selectedProject === undefined) {
    return null
  }

  return (
    <StateResults>
      <CustomHits key={customHitsKey} />
    </StateResults>
  )
})

export default OrderMaterialTableWrapper
