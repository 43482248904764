import React, { createContext, useContext, useCallback } from 'react'

import { ColumnInstance } from 'react-table'

import { observer } from 'mobx-react-lite'

import { Loading } from 'common/components/Loading'
import { useQuery } from 'common/hooks/use-query'

import { useStores } from 'contractor/hooks/use-stores'

const OrderMaterialsContext = createContext<OrderMaterialsContextProps>({} as OrderMaterialsContextProps)

export const useOrderMaterials = () => useContext(OrderMaterialsContext)

type OrderMaterialsContextProps = {
  tableColumns: ColumnInstance[]
  maybeSetTableColumns: (columns: ColumnInstance) => void
}

export const OrderMaterialsProvider = observer(({ children }) => {
  const { projectStore, orderMaterialStore, companySettingStore } = useStores()

  const { isLoading } = useQuery(() => {
    return Promise.all([
      projectStore.maybeIndexProjects(),
      orderMaterialStore.loadSearchKey(),
      companySettingStore.indexCompanyMaterialConfiguration(),
    ])
  })

  const [tableColumns, setTableColumns] = React.useState([])

  const maybeSetTableColumns = useCallback((columns = []) => {
    setTableColumns(columns)
  }, [])

  if (isLoading) {
    return <Loading />
  }

  if (!orderMaterialStore.searchKey.application_id) {
    return null
  }

  return (
    <OrderMaterialsContext.Provider
      value={{
        maybeSetTableColumns,
        tableColumns,
      }}
    >
      {children}
    </OrderMaterialsContext.Provider>
  )
})

export const withOrderMaterialsProvider = (Component) => (props) => {
  return (
    <OrderMaterialsProvider>
      <Component {...props} />
    </OrderMaterialsProvider>
  )
}
