import React from 'react'

import { Input, Modal, Space, Typography } from 'antd'

import { observer } from 'mobx-react-lite'

import { FlexBoxY } from 'common/components/boxes'

import { useCommitment } from 'contractor/pages/@v2/Commitments/Components/CommitmentContext/context'

type ModalConfirmSubmitProps = {
  isLoading?: boolean
}

export const ModalConfirmSubmit = observer<ModalConfirmSubmitProps>((props: ModalConfirmSubmitProps) => {
  const { isLoading } = props
  const {
    commitmentNumber,
    form,
    handleSubmitCommitment,
    confirmSubmitVisible,
    setShowConfirmSubmitVisible,
    commitment,
    handleCreateSubmittedCommitment,
  } = useCommitment()
  const action = commitment.created_at ? handleSubmitCommitment : handleCreateSubmittedCommitment

  return (
    <Modal
      title="Confirm Submit Commitment"
      open={confirmSubmitVisible}
      onOk={() => action()}
      okText="Submit"
      okButtonProps={{ loading: isLoading, 'data-cy': 'confirm-submit' }}
      onCancel={() => setShowConfirmSubmitVisible(false)}
    >
      <FlexBoxY alignItems="flex-start" justifyContent="flex-start">
        <Typography.Text type="secondary">Commitment number (optional)</Typography.Text>
        <Space>
          <Input
            data-cy="commitment-number"
            style={{ width: '250px' }}
            placeholder="Commitment number (optional)"
            onChange={(e) => form.setFieldsValue({ commitmentName: e.target.value })}
            value={commitmentNumber}
          />
        </Space>
      </FlexBoxY>
      <br />
      <Typography.Text type="secondary" italic style={{ fontSize: 12 }}>
        PDF of the commitment will be automatically created
      </Typography.Text>
    </Modal>
  )
})
