import React from 'react'

import { Col, Row } from 'antd'

import { Box } from 'common/components/boxes'
import { Page } from 'common/components/Page'
import UnloadWarning from 'common/components/unload_warning'
import { Visibility } from 'common/components/Visibility'

import { Footer } from 'contractor/pages/@v2/Commitments/Components/CommitmentFooter/footer'
import { CommitmentHeader } from 'contractor/pages/@v2/Commitments/Components/CommitmentHeader/commitment_header'
import { MaterialsAndOrders } from 'contractor/pages/@v2/Commitments/Components/CommitmentMaterials/materials_and_orders'

import { Actions } from '.././Components/./CommitmentActions'
import { useCommitment, withCommitmentProvider } from '../Components/CommitmentContext/context'
import { ModalConfirmSubmit } from '../Components/ConfirmSubmitModal/modal_confirm_submit'

const Details = () => {
  const { isSubmitting, goBack, handleUpdateCommitment, isDirty, setShowConfirmSubmitVisible, isDraft } =
    useCommitment()
  const disabled = false

  return (
    <Page>
      <CommitmentHeader disabled={disabled || !isDraft} goBack={goBack} isSubmitting={isSubmitting} />

      <Page.Content p={0} px={{ _: 0, sm: 16 }} mb={64} py={16}>
        <Row gutter={[24, 24]}>
          <Col span={24}>
            <Row gutter={[24, 24]}>
              <Col span={24}>
                <MaterialsAndOrders disabled={disabled} />
              </Col>
            </Row>
          </Col>

          <Footer />

          <Visibility.Show>
            <Col span={24}>
              <Box display="flex" justifyContent="flex-end" width="100%" pr={16}>
                <Actions
                  key="actions"
                  isLoading={isSubmitting}
                  showConfirmSubmit={() => setShowConfirmSubmitVisible(true)}
                  disabled={disabled || !isDirty}
                />
              </Box>
            </Col>
          </Visibility.Show>
        </Row>

        <UnloadWarning
          showWarning={() => true}
          onSaveAndLeave={disabled ? undefined : () => handleUpdateCommitment()}
          isSubmitting={isSubmitting}
        />
      </Page.Content>

      <ModalConfirmSubmit isLoading={isSubmitting} />
    </Page>
  )
}

export const CommitmentDetails = withCommitmentProvider(Details)
