import { BindAll } from 'lodash-decorators'

import { action, observable } from 'mobx'

import { ShowInvoiceMailResponse, ShowInvoiceUploadResponse } from 'common/server/invoice'
import { InvoiceInboxResponse } from 'common/server/invoice_inbox'
import AlgoliaBaseStore from 'common/stores/AlgoliaBaseStore'
import ListBaseStore from 'common/stores/ListBaseStore'

import {
  InvoiceInboxHit,
  search_key,
  show,
  showInvoiceMail,
  upload,
  showInvoiceUpload,
  ignore,
  ignore_all,
  issue,
  tip,
} from 'contractor/server/invoices/inbox'
import {
  ConsolidatedInvoiceInboxes,
  consolidatedInvoiceInboxesRequests,
} from 'contractor/server/invoices/inbox/consolidated_invoice_inboxes'
import { ReportInvoiceInboxIssuePayload, ReportInvoiceInboxTipPayload } from 'contractor/server/invoices/invoice'

class InvoiceInboxListStore extends ListBaseStore<ConsolidatedInvoiceInboxes.InvoiceInbox> {
  index = consolidatedInvoiceInboxesRequests.index
  getFacets = consolidatedInvoiceInboxesRequests.facets
}

@BindAll()
export default class InvoiceInboxStore extends AlgoliaBaseStore<InvoiceInboxHit> {
  listStore: InvoiceInboxListStore

  constructor() {
    super()
    this.listStore = new InvoiceInboxListStore()
  }

  getSearchKey = search_key

  @observable invoiceInbox: Nullable<InvoiceInboxResponse> = null
  @observable selectedInvoiceMail: Nullable<ShowInvoiceMailResponse> = null
  @observable selectedInvoiceUpload: Nullable<ShowInvoiceUploadResponse> = null

  @action
  async getInvoiceInbox(id: string) {
    const { data } = await show(id)
    this.invoiceInbox = data
    return this.invoiceInbox
  }

  @action
  async selectInvoiceMail(id: string) {
    const { data } = await showInvoiceMail(id)
    this.selectedInvoiceMail = data
    return this.selectedInvoiceMail
  }

  @action
  async selectInvoiceUpload(id: string) {
    const { data } = await showInvoiceUpload(id)
    this.selectedInvoiceUpload = data
    return this.selectedInvoiceUpload
  }

  uploadInvoices(file_signed_ids: string[]) {
    return upload(file_signed_ids)
  }

  async ignoreFailures(id: string) {
    const { data } = await ignore(id)
    return data
  }

  async ignoreAllFailures() {
    const { data } = await ignore_all()
    return data
  }

  async reportIssue(id: string, payload: ReportInvoiceInboxIssuePayload) {
    await issue(id, payload)
  }

  async reportTip(id: string, payload: ReportInvoiceInboxTipPayload) {
    await tip(id, payload)
  }
}
