import React from 'react'

import moment from 'moment'

import ArrowRightOutlined from '@ant-design/icons/ArrowRightOutlined'
import PaperClipOutlined from '@ant-design/icons/PaperClipOutlined'
import { Typography, Tag } from 'antd'

import { FlexBoxX } from 'common/components/boxes'
import { currencyFormatter, formatEnumValue } from 'common/helpers/formatters'

export type ChangeType = 'text' | 'tag' | 'date' | 'file' | 'currency' | 'enum'

type ChangedItemProps = {
  prev?: React.ReactNode
  next?: React.ReactNode
  label: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  changes?: [any, any]
  type?: ChangeType
  isCreate?: boolean
  href?: string
}

const formatDateChange = (date: string) => moment(date).format('YYYY-MM-DD')

export const LinkPrevious = (props) => (
  <Typography.Link
    delete
    {...props}
    style={{
      padding: '0 7px',
      backgroundColor: '#fff1f0',
      color: '#f5222d',
      minWidth: 33,
      textAlign: 'center',
      ...props?.style,
    }}
  />
)
export const LinkNext = (props) => (
  <Typography.Link
    {...props}
    style={{
      padding: '0 7px',
      backgroundColor: '#f6ffed',
      color: '#52c41a',
      minWidth: 33,
      textAlign: 'center',
      ...props?.style,
    }}
  />
)

export const TextPrevious = (props) => (
  <Typography.Text
    delete
    {...props}
    style={{
      padding: '0 7px',
      backgroundColor: '#fff1f0',
      color: '#f5222d',
      minWidth: 33,
      textAlign: 'center',
      ...props?.style,
    }}
  />
)
export const TextNext = (props) => (
  <Typography.Text
    {...props}
    style={{
      padding: '0 7px',
      backgroundColor: '#f6ffed',
      color: '#52c41a',
      minWidth: 33,
      textAlign: 'center',
      ...props?.style,
    }}
  />
)

type TagChangedProps = {
  nextValue: string[]
  previousValue: string[]
} & Pick<ChangedItemProps, 'label' | 'isCreate'>

const TagChanged = ({ label, isCreate, previousValue, nextValue }: TagChangedProps) => (
  <FlexBoxX alignItems="center" justifyContent="flex-start" mb="8px">
    <Typography.Text style={{ marginRight: 8, whiteSpace: 'nowrap' }}>{label}:</Typography.Text>
    {!isCreate && (
      <>
        {previousValue.length ? (
          previousValue.map((value) => (
            <Tag key={value} color="red" style={{ textDecoration: 'line-through' }}>
              {value}
            </Tag>
          ))
        ) : (
          <TextPrevious>N/A</TextPrevious>
        )}
        <ArrowRightOutlined style={{ margin: '0 8px' }} />
      </>
    )}
    {nextValue.length ? (
      nextValue.map((value) => (
        <Tag key={value} color="green">
          {value}
        </Tag>
      ))
    ) : (
      <TextNext>N/A</TextNext>
    )}
  </FlexBoxX>
)

type DateChangedProps = {
  nextValue: string
  previousValue: string
} & Pick<ChangedItemProps, 'label' | 'isCreate'>

const DateChanged = ({ label, isCreate, previousValue, nextValue }: DateChangedProps) => (
  <FlexBoxX alignItems="center" justifyContent="flex-start" mb="8px">
    <Typography.Text style={{ marginRight: 8, whiteSpace: 'nowrap' }}>{label}:</Typography.Text>
    {!isCreate && (
      <>
        <TextPrevious>{previousValue ? formatDateChange(previousValue) : 'N/A'}</TextPrevious>
        <ArrowRightOutlined style={{ margin: '0 8px' }} />
      </>
    )}
    <TextNext>{nextValue ? formatDateChange(nextValue) : 'N/A'}</TextNext>
  </FlexBoxX>
)

type FileChangedProps = {
  nextValue: string
  previousValue: string
} & Pick<ChangedItemProps, 'label' | 'isCreate' | 'href'>

const FileChanged = ({ label, isCreate, previousValue, nextValue, href }: FileChangedProps) => (
  <FlexBoxX alignItems="center" justifyContent="flex-start" mb="8px">
    <Typography.Text style={{ marginRight: 8, whiteSpace: 'nowrap' }}>{label}:</Typography.Text>
    {!isCreate && (
      <>
        <LinkPrevious target="_blank" href={href}>
          {previousValue ? (
            <>
              <PaperClipOutlined style={{ marginRight: 4 }} />
              {previousValue}
            </>
          ) : (
            'N/A'
          )}
        </LinkPrevious>
        <ArrowRightOutlined style={{ margin: '0 8px' }} />
      </>
    )}
    <LinkNext target="_blank" href={href}>
      {nextValue ? (
        <>
          <PaperClipOutlined style={{ marginRight: 4 }} />
          {nextValue}
        </>
      ) : (
        'N/A'
      )}
    </LinkNext>
  </FlexBoxX>
)

type DefaultChangedProps = {
  nextValue: string
  previousValue: string
  formatter?: (value: string) => string
} & Pick<ChangedItemProps, 'label' | 'isCreate'>

const DefaultChanged = ({ label, isCreate, previousValue, nextValue, formatter }: DefaultChangedProps) => {
  const formattedPreviousValue = formatter && previousValue ? formatter(previousValue) : previousValue
  const formattedPNextValue = formatter && nextValue ? formatter(nextValue) : nextValue

  return (
    <FlexBoxX alignItems="center" justifyContent="flex-start" mb="8px">
      <Typography.Text style={{ marginRight: 8, whiteSpace: 'nowrap' }}>{label}:</Typography.Text>
      {!isCreate && (
        <>
          <TextPrevious>{previousValue ? formattedPreviousValue : 'N/A'}</TextPrevious>
          <ArrowRightOutlined style={{ margin: '0 8px' }} />
        </>
      )}
      <TextNext>{nextValue ? formattedPNextValue : 'N/A'}</TextNext>
    </FlexBoxX>
  )
}

export const ChangedItem = ({ label, changes, type = 'text', isCreate = false, href }: ChangedItemProps) => {
  const [previousValue, nextValue] = changes || []

  if (!previousValue && !nextValue) {
    return null
  }

  if (type === 'tag') {
    return <TagChanged label={label} isCreate={isCreate} previousValue={previousValue} nextValue={nextValue} />
  }

  if (type === 'date') {
    return <DateChanged label={label} isCreate={isCreate} previousValue={previousValue} nextValue={nextValue} />
  }

  if (type === 'file') {
    return (
      <FileChanged label={label} isCreate={isCreate} previousValue={previousValue} nextValue={nextValue} href={href} />
    )
  }

  if (type === 'enum') {
    return (
      <DefaultChanged
        label={label}
        isCreate={isCreate}
        previousValue={previousValue}
        nextValue={nextValue}
        formatter={formatEnumValue}
      />
    )
  }

  if (type === 'currency') {
    return (
      <DefaultChanged
        label={label}
        isCreate={isCreate}
        previousValue={previousValue}
        nextValue={nextValue}
        formatter={currencyFormatter}
      />
    )
  }

  return <DefaultChanged label={label} isCreate={isCreate} previousValue={previousValue} nextValue={nextValue} />
}
