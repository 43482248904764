import React, { useState, useEffect, useRef } from 'react'

import styled from '@emotion/styled'

import { Button, Alert, Divider, Form, Input, Typography, message, Tabs, Spin } from 'antd'

import { observer } from 'mobx-react-lite'

import { FlexBoxY, FlexBoxX, Box } from 'common/components/boxes'
import { Drawer, DrawerRef } from 'common/components/Drawer'
import { useQuery } from 'common/hooks/use-query'
import { Address } from 'common/server/addresses'

import { AddressTable, AddressDrawer } from 'contractor/components/Addresses'
import { useFlag } from 'contractor/hooks/use-flag'
import { useStores } from 'contractor/hooks/use-stores'

import { useMyVendors } from '../context'

type UpdateCompanyVendorProps = {
  companyVendorId: string
  onFinish?: () => void
  onCancel: () => void
}

const Wrapper = styled.div`
  width: 100%;
  height: 100%;

  & .ant-spin-nested-loading {
    width: 100%;
    height: 100%;

    & .ant-spin-container {
      width: inherit;
      height: inherit;
    }
  }
`

export const UpdateCompanyVendor = observer<UpdateCompanyVendorProps, DrawerRef>(
  (props, ref) => {
    const { companyVendorId, onFinish, onCancel } = props
    const { companyVendorStore } = useStores()

    const { onlyActives } = useMyVendors()
    const externalVendorIdEnabled = useFlag('external_vendor_id')

    const { isLoading } = useQuery(() => {
      if (!companyVendorId) return
      return companyVendorStore.getCompanyVendorById(companyVendorId)
    }, [companyVendorId])

    const [form] = Form.useForm()

    const drawerCreateAddressRef = useRef<DrawerRef>()

    const [alertMessage, setAlertMessage] = useState('')
    const [isSubmitting, setSubmitting] = useState(false)

    const handleCancel = () => {
      companyVendorStore.selectedCompanyVendor = null
      alertMessage && setAlertMessage('')
      onCancel()
    }

    const handleSubmit = async (formValues) => {
      setSubmitting(true)

      try {
        await companyVendorStore.updateCompanyVendor({
          id: companyVendorId,
          vendor_name: formValues.vendor_name,
          external_vendor_id: formValues.external_vendor_id,
        })
        companyVendorStore.getAllCompanyVendors(onlyActives)
        message.success('Successfully updated vendor')

        handleCancel()
        onFinish?.()
      } catch (err) {
        setAlertMessage(err.response.data['error'] || 'Unable to update vendor')
      } finally {
        setSubmitting(false)
      }
    }

    const handleAddressSaveAsNew = (address: Omit<Address, 'id'>, currentAddress: Address) => {
      return Promise.all([
        companyVendorStore.updateAddress({ ...currentAddress, active: false }),
        companyVendorStore.createAddress(companyVendorStore.selectedCompanyVendor?.id, address),
        companyVendorStore.getCompanyVendorById(companyVendorId),
      ])
    }

    const handleCreateAddress = (address) => {
      setSubmitting(true)

      return companyVendorStore.createAddress(companyVendorStore.selectedCompanyVendor?.id, address).then(() => {
        companyVendorStore.getCompanyVendorById(companyVendorId)
        drawerCreateAddressRef.current?.close()
        setSubmitting(false)
      })
    }

    const handleUpdateAddress = async (address) => {
      setSubmitting(true)

      await companyVendorStore.updateAddress(address).then(() => {
        companyVendorStore.getCompanyVendorById(companyVendorId)
        drawerCreateAddressRef.current?.close()
        setSubmitting(false)
      })
    }

    useEffect(() => {
      if (companyVendorStore.selectedCompanyVendor) {
        const companyVendor = companyVendorStore.selectedCompanyVendor

        form.setFieldsValue({
          vendor_name: companyVendor.vendor_name,
          external_vendor_id: companyVendor?.external_vendor_id,
        })
      } else {
        form.resetFields()
      }
    }, [companyVendorStore.selectedCompanyVendor])

    return (
      <Drawer
        ref={ref}
        bgGray
        destroyOnClose
        title={
          <Typography.Title level={5} style={{ margin: 0 }}>
            Vendor Information
          </Typography.Title>
        }
      >
        <Wrapper>
          <Spin spinning={isLoading} tip="Loading...">
            <Box p={16} display="flex" height="100%" width="100%" flexDirection="column">
              {alertMessage && (
                <Alert type="error" message={alertMessage} style={{ marginBottom: 16, width: '100%' }} />
              )}

              <Form
                form={form}
                layout="vertical"
                autoComplete="off"
                onFinish={handleSubmit}
                style={{ width: '100%', flexGrow: 1, display: 'flex', flexDirection: 'column' }}
              >
                <FlexBoxY width="100%" alignItems="inherit" justifyContent="flex-start">
                  <Form.Item
                    name="vendor_name"
                    label="Vendor Name"
                    rules={[{ required: true, message: 'Vendor name is required!' }]}
                  >
                    <Input />
                  </Form.Item>

                  {externalVendorIdEnabled && (
                    <Form.Item
                      name="external_vendor_id"
                      label="Vendor ID"
                      tooltip="Enter the name as it appears in your accounting system."
                    >
                      <Input />
                    </Form.Item>
                  )}

                  <Tabs
                    defaultActiveKey="1"
                    items={[
                      {
                        label: 'Vendor Pickup Addresses',
                        key: '1',
                        children: (
                          <>
                            <AddressTable
                              addresses={companyVendorStore.selectedCompanyVendor?.addresses}
                              onSubmit={handleUpdateAddress}
                              onSaveAsNew={handleAddressSaveAsNew}
                              hideSelect
                              isDrawer
                            />
                            <AddressDrawer
                              ref={drawerCreateAddressRef}
                              onClose={() => drawerCreateAddressRef.current?.close()}
                              onSubmit={handleCreateAddress}
                              hideSelect
                            />
                            <Button
                              block
                              type="primary"
                              onClick={() => drawerCreateAddressRef.current?.show()}
                              style={{ marginTop: 12 }}
                            >
                              Add Address
                            </Button>
                          </>
                        ),
                      },
                    ]}
                  />
                </FlexBoxY>

                <Divider />

                <FlexBoxX justifyContent="space-between" flexGrow={0}>
                  <Button onClick={handleCancel} loading={isSubmitting || isLoading} style={{ width: 100 }}>
                    Cancel
                  </Button>
                  <Button type="primary" htmlType="submit" loading={isSubmitting || isLoading} style={{ width: 100 }}>
                    Save
                  </Button>
                </FlexBoxX>
              </Form>
            </Box>
          </Spin>
        </Wrapper>
      </Drawer>
    )
  },
  { forwardRef: true },
)
