import React from 'react'

import { CellBase, DataEditorProps } from 'react-spreadsheet'

import { SelectPhaseCode, SelectPhaseCodeProps } from 'common/components/SelectPhaseCode'

type PhaseCodeEditorCellProps = SelectPhaseCodeProps &
  DataEditorProps & {
    onChange: (cell: CellBase, shouldPropagateValue?: boolean) => void
  }

export const PhaseCodeEditorCell = ({ cell, onChange, ...props }: PhaseCodeEditorCellProps) => {
  return (
    <SelectPhaseCode
      {...props}
      value={cell.value}
      onChange={(value, shouldPropagateValues) => {
        onChange({ ...cell, value }, shouldPropagateValues)
      }}
      autoFocus
    />
  )
}
