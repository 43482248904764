import React from 'react'

import { connectStateResults } from 'react-instantsearch-dom'

import { Typography, Empty } from 'antd'

import { observer } from 'mobx-react-lite'

import { Box } from 'common/components/boxes'

import { CreateOrderActions } from 'contractor/components/CreateOrderActions'
import { useStores } from 'contractor/hooks/use-stores'

type CustomStateResultsProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  searchState: Record<string, any>
  searchResults: Record<string, unknown>
  allSearchResults: Record<string, unknown>
  error: Record<string, unknown>
  searching: boolean
  searchingForFacetValues: boolean
  isSearchStalled: boolean
}

const commonBoxProps = {
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
}

const CustomStateResults = observer<CustomStateResultsProps>(({ children, searchResults, searchState }) => {
  const { projectStore, userStore } = useStores()

  const hasQuerySearch = !!(searchState['query'] || '').trim()

  const hasRefinementSearch = Object.entries(searchState['refinementList'] || {}).map(([_, value = '']) => {
    if (Array.isArray(value)) {
      return value.some((item = '') => !!item?.trim())
    }

    return !!(value as string)?.trim()
  })

  // if have is no one project selected and no query and no filters applied and no results,
  // then the user has no orders and we inform you to create
  if (
    !searchState['project_id'] &&
    !hasQuerySearch &&
    hasRefinementSearch.every((item) => !item) &&
    searchResults?.nbHits === 0
  ) {
    return (
      <Box {...commonBoxProps}>
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description={
            <Typography.Title level={5} style={{ textAlign: 'center' }}>
              {userStore.isMaterialRequester
                ? "Looks like you haven't created any requests yet. Get started!"
                : "Looks like you haven't created any RFQs or orders yet. Get started!"}
            </Typography.Title>
          }
        >
          <CreateOrderActions justifyContent="center" />
        </Empty>
      </Box>
    )
  }

  // if have a project selected and no query and no filters applied and no results,
  // then the user has no orders for the selected project and we inform you to create
  if (
    !!searchState['project_id'] &&
    !hasQuerySearch &&
    hasRefinementSearch.every((item) => !item) &&
    searchResults?.nbHits === 0
  ) {
    return (
      <Box {...commonBoxProps}>
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description={
            <Typography.Title level={5} style={{ textAlign: 'center', maxWidth: 500 }}>
              Looks like you haven&apos;t created any RFQs or orders yet for {projectStore.selectedProject?.name}. Get
              started!
            </Typography.Title>
          }
        >
          <CreateOrderActions justifyContent="center" />
        </Empty>
      </Box>
    )
  }

  if (searchResults?.nbHits === 0) {
    return (
      <Box {...commonBoxProps}>
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description={
            <Typography.Title level={5} style={{ textAlign: 'center' }}>
              Sorry! No Items were found with these search criteria.
            </Typography.Title>
          }
        />
      </Box>
    )
  }

  return (
    <Box position="relative" height="inherit" width="inherit">
      {children}
    </Box>
  )
})

export const StateResults = connectStateResults(CustomStateResults)
