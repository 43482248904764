import React from 'react'

import { PhaseCodeViewerCell } from 'common/components/OrderMaterialsSpreadsheet'

import { useStores } from 'contractor/hooks/use-stores'

export const CustomPhaseCodeViewerCell = (props) => {
  const { costCodeStore } = useStores()

  const phaseCode = costCodeStore?.costCodePhaseListStore.records?.find((costCode) => costCode.id === props?.cell.value)

  return <PhaseCodeViewerCell {...props} phaseCode={phaseCode} />
}
