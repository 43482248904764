import React, { useEffect, useState } from 'react'

import { Empty } from 'antd'

import { Box } from 'common/components/boxes'

import { useStores } from 'contractor/hooks/use-stores'
import { useCommitment } from 'contractor/pages/@v2/Commitments/Components/CommitmentContext/context'
import { CommitmentMaterialsDrawer } from 'contractor/pages/@v2/Commitments/Components/CommitmentMaterialsDrawer/commitment_materials_drawer'
import { CommitmentOrderDrawer } from 'contractor/pages/@v2/Commitments/Components/CommitmentOrderDrawer/commitment_order_drawer'
import { ConsolidatedOrdersMaterials } from 'contractor/server/order_materials'
import { ConsolidatedOrders } from 'contractor/server/orders'

import { Table } from './table'

type CommitmentsOrdersProps = {
  isOrderDrawerVisible: boolean
  closeOrderDrawer: () => void
}

export const CommitmentsOrders = (props: CommitmentsOrdersProps) => {
  const { isOrderDrawerVisible, closeOrderDrawer } = props
  const { commitmentStore, companySettingStore } = useStores()
  const { commitment, ordersToImport } = useCommitment()
  const [dataSource, setDataSource] = useState<ConsolidatedOrders.Order[]>([])
  const [orderMaterials, setOrderMaterials] = useState<ConsolidatedOrdersMaterials.OrderMaterial[]>([])
  const costCodeSettings = companySettingStore?.otherSettings?.cost_code_settings
  const [isMaterialDrawerVisible, setIsMaterialDrawerVisible] = useState(false)

  useEffect(() => {
    if (commitment?.id && commitment?.order_count > 0) {
      commitmentStore.loadCommitmentOrders(ordersToImport).then((orders) => {
        setDataSource(orders)
      })
    }
  }, [commitment?.id, commitment?.order_count, ordersToImport])

  const handleLoadMaterials = (orderId: string) => {
    setIsMaterialDrawerVisible(true)
    commitmentStore?.loadOrderMaterials(orderId).then((materials) => {
      setOrderMaterials(materials)
    })
  }

  if (commitment?.order_count == 0) {
    return (
      <Box p="16">
        <Empty />
        <CommitmentOrderDrawer
          visible={isOrderDrawerVisible}
          onClose={closeOrderDrawer}
          title="Select Order"
          phaseCodeEnabled={costCodeSettings?.independent_phase_codes_enabled}
        />
      </Box>
    )
  }

  return (
    <>
      <Table handleLoadOrderMaterials={handleLoadMaterials} dataSource={dataSource} />
      <CommitmentMaterialsDrawer
        title="Order Materials"
        visible={isMaterialDrawerVisible}
        isOrder
        materials={orderMaterials}
        onClose={() => setIsMaterialDrawerVisible(false)}
      />
      <CommitmentOrderDrawer
        visible={isOrderDrawerVisible}
        onClose={closeOrderDrawer}
        title="Select Order"
        phaseCodeEnabled={costCodeSettings?.independent_phase_codes_enabled}
        costCodeEnabled={companySettingStore?.companyMaterialConfiguration?.company_attributes.includes('cost_code_id')}
      />
    </>
  )
}
