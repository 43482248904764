import React, { useRef, useState } from 'react'

import { Configure, InstantSearch, SearchBox } from 'react-instantsearch-dom'

import styled from '@emotion/styled'

import { MoreOutlined } from '@ant-design/icons'
import { Dropdown, message, Popconfirm, Typography } from 'antd'
import { MenuProps } from 'antd/lib/menu'

import { observer } from 'mobx-react-lite'

import { AlgoliaTagsFilter } from 'common/components/AlgoliaTagsFilter'
import { Box } from 'common/components/boxes'
import { DrawerRef } from 'common/components/Drawer'
import { DrawerTableFilters } from 'common/components/DrawerTableFilters'
import { Page } from 'common/components/Page'
import { ClearFiltersButton, RefinementListDropdown, DateRangePicker } from 'common/components/table/filters'
import { useAlgoliaSyncUrl } from 'common/hooks/use-algolia-sync-url'
import { useInterval } from 'common/hooks/use-interval'
import { useQuery } from 'common/hooks/use-query'
import { InvoiceInboxRibbonStates } from 'common/server/server_types'

import { useStores } from 'contractor/hooks/use-stores'
import { CreateFailedInvoiceDrawer } from 'contractor/pages/Invoices/Add/create_failed_invoice_drawer'
import { FailedInboxPdfs, InvoicePdfSelector } from 'contractor/pages/Invoices/Inbox/Action/invoice_pdf_selector'
import { InboxDetail } from 'contractor/pages/Invoices/Inbox/Detail/inbox_detail'
import { InboxRowAction } from 'contractor/pages/Invoices/Inbox/List/list_item'
import { InvoiceInboxHit } from 'contractor/server/invoices/inbox'

import { InboxList } from './List'

const StyledText = styled(Typography.Text)`
  & .ant-typography-copy {
    color: #5ac2e7 !important;
  }
`

const DropdownStyled = styled(Dropdown.Button)`
  .ant-btn-compact-item.ant-btn.ant-btn-compact-last-item:not(.ant-btn-compact-first-item):not(.ant-btn-compact-item-rtl) {
    border: 1px solid #d9d9d9;
    border-radius: 2px;
  }
  .ant-btn-compact-item.ant-btn.ant-btn-compact-first-item:not(.ant-btn-compact-last-item):not(.ant-btn-compact-item-rtl) {
    padding: 0;
  }
`

export const Inbox = observer(() => {
  const { invoiceInboxStore, companySettingStore } = useStores()

  // Reload invoices every 10 seconds
  useInterval(() => invoiceInboxStore.forceRefreshHits(false))

  useQuery(invoiceInboxStore.loadSearchKey)
  const { data: invoicingMail } = useQuery(companySettingStore.invoicingMail)

  const algoliaSyncUrl = useAlgoliaSyncUrl(invoiceInboxStore)

  const detailDrawerRef = useRef<DrawerRef>()
  const pdfSelectorDrawerRef = useRef<DrawerRef>()
  const createFailedInvoiceDrawerRef = useRef<DrawerRef>()

  const [selectedHit, setSelectedHit] = useState<InvoiceInboxHit | null>(null)
  const [selectedActionHit, setSelectedActionHit] = useState<InvoiceInboxHit | null>(null)
  const [selectedPdfFiles, setSelectedPdfFiles] = useState<Nullable<FailedInboxPdfs>>(null)
  const [openPopup, setOpenPopup] = useState(false)
  const [isSubmitting, setSubmitting] = useState(false)

  const showPopupConfirm = () => {
    setOpenPopup(true)
  }

  const handlePopupConfirm = async () => {
    setSubmitting(true)
    try {
      await invoiceInboxStore.ignoreAllFailures()
      message.success('All FAILED records are now IGNORED')
    } catch (e) {
      message.error('Failed to mark all records as ignored')
    } finally {
      setOpenPopup(false)
      setSubmitting(false)
    }
  }

  const handlePopupCancel = () => {
    setOpenPopup(false)
  }

  const onSelectRow = (hit) => {
    detailDrawerRef.current?.show()
    setSelectedHit(hit)
  }

  const onSelectAction = (hit, action) => {
    if (action === InboxRowAction.CREATE) {
      setSelectedActionHit(hit)
      detailDrawerRef.current?.close()
      pdfSelectorDrawerRef.current?.show()
    } else if (action === InboxRowAction.IGNORE) {
      message.loading('Ignoring inbox record errors')
      invoiceInboxStore
        .ignoreFailures(hit.id)
        .then((_) => {
          invoiceInboxStore.forceRefreshHits(false)
          message.success('Marked as ignored')
          detailDrawerRef.current?.close()
        })
        .catch((_) => {
          message.error('Marking record as ignored')
        })
    }
  }

  const onClickNext = (selected: FailedInboxPdfs) => {
    setSelectedPdfFiles(selected)
    pdfSelectorDrawerRef.current?.close()
    setSelectedActionHit(null)
    createFailedInvoiceDrawerRef.current?.show()
  }

  const items: MenuProps['items'] = [
    {
      label: (
        <Popconfirm
          title="This action will set every FAILED inbox record as IGNORED. Are you sure?"
          okText="Yes"
          cancelText="No"
          placement="leftBottom"
          open={openPopup}
          onConfirm={handlePopupConfirm}
          okButtonProps={{ loading: isSubmitting }}
          onCancel={handlePopupCancel}
        >
          <Typography.Text style={{ color: '#8c8c8c' }}>Mark all as ignored</Typography.Text>
        </Popconfirm>
      ),
      key: '1',
      onClick: (e) => {
        e.domEvent.preventDefault()
        e.domEvent.stopPropagation()
        showPopupConfirm()
      },
    },
  ]

  const menuProps = {
    items,
  }

  if (!invoiceInboxStore.searchKey.application_id) {
    return
  }

  return (
    <>
      <InboxDetail
        invoiceInboxId={selectedHit?.invoice_source_id}
        invoiceInboxSourceType={selectedHit?.invoice_source_type}
        onSelectAction={onSelectAction}
        ref={detailDrawerRef}
      />
      <InvoicePdfSelector
        inbox_id={selectedActionHit?.id}
        files={selectedActionHit?.files}
        ref={pdfSelectorDrawerRef}
        onClickNext={onClickNext}
      />
      <CreateFailedInvoiceDrawer
        ref={createFailedInvoiceDrawerRef}
        failedInboxPdfs={selectedPdfFiles}
        onClose={() => createFailedInvoiceDrawerRef.current?.close()}
      />
      <InstantSearch
        searchClient={invoiceInboxStore.searchClient}
        indexName={invoiceInboxStore.searchKey.index_name}
        refresh={invoiceInboxStore.refreshHits}
        {...algoliaSyncUrl}
      >
        <Configure hitsPerPage={50} />
        <Page.Header>
          <Box display="flex" alignItems="center" width="100%">
            <Box mr={8} flexGrow={1}>
              <SearchBox searchAsYouType={false} showLoadingIndicator />
            </Box>

            <DrawerTableFilters title="Invoice Inbox Options">
              <Box mb={16} display="flex" justifyContent="space-between" alignItems="center">
                <Typography.Title level={5} style={{ margin: 0 }}>
                  Filters
                </Typography.Title>
                <ClearFiltersButton />
              </Box>
              <Box mb={16} width="100%" display="flex" flexDirection="column">
                <Typography.Text type="secondary">From</Typography.Text>
                <RefinementListDropdown attribute="from" placeholder="Email from" isSearcheable />
              </Box>
              <Box mb={16} width="100%" display="flex" flexDirection="column">
                <Typography.Text type="secondary">Uploaded by</Typography.Text>
                <RefinementListDropdown attribute="uploaded_by" placeholder="Manually uploaded by" isSearcheable />
              </Box>
              <Box mb={24} width="100%" display="flex" flexDirection="column">
                <Typography.Text type="secondary">Origin</Typography.Text>
                <RefinementListDropdown attribute="origin" placeholder="Select by origin" isSearcheable />
              </Box>
              <Box width="100%" mb={24}>
                <DateRangePicker attribute="created_at_timestamp" title="Created At" />
              </Box>
            </DrawerTableFilters>

            <Box flexGrow={0} justifyContent="flex-end" alignItems="flex-end" pl={8}>
              <DropdownStyled
                menu={menuProps}
                autoFocus
                trigger={['hover', 'click']}
                type="link"
                icon={<MoreOutlined />}
                onClick={(e) => {
                  e.preventDefault()
                  e.stopPropagation()
                }}
              />
            </Box>
          </Box>

          <Box
            mt={16}
            display="flex"
            flexDirection={{ _: 'column', md: 'row' }}
            alignItems={{ _: 'flex-start', md: 'center' }}
            justifyContent="space-between"
            width="100%"
          >
            <AlgoliaTagsFilter
              boxProps={{
                mb: { _: 8, md: 0 },
              }}
              attribute="state_ribbon"
              options={[
                { label: 'All', filter: '' },
                { label: 'Extracting', filter: InvoiceInboxRibbonStates.EXTRACTING },
                { label: 'Ignored', filter: InvoiceInboxRibbonStates.IGNORED },
                { label: 'Failed', filter: InvoiceInboxRibbonStates.FAILED },
                { label: 'Created', filter: InvoiceInboxRibbonStates.CREATED },
              ]}
            />

            <StyledText copyable type="secondary">
              {invoicingMail}
            </StyledText>
          </Box>
        </Page.Header>

        <Page.Content p={0} px={{ _: 0, md: 16 }} py={16} height="100%">
          <InboxList onSelectRow={onSelectRow} onSelectAction={onSelectAction} />
        </Page.Content>
      </InstantSearch>
    </>
  )
})
