import React from 'react'

import { connectRefinementList } from 'react-instantsearch-dom'

import { sortBy } from 'lodash'

import { Select, Badge } from 'antd'

import { useTheme } from 'common/hooks/use-theme'

const RefinementListDropdownWatcher = ({ items = [], currentRefinement, refine, searchForItems, isSearcheable }) => {
  const theme = useTheme()

  return (
    <Select
      mode="multiple"
      aria-label="select-watcher"
      allowClear
      placeholder="Select watchers"
      onChange={refine}
      style={{ width: '100%' }}
      value={currentRefinement}
      optionLabelProp="label"
      showArrow
      onSearch={(searchVal) => isSearcheable && searchForItems(searchVal)}
    >
      {sortBy(items, 'label').map((item) => {
        const label = item?.label
        return (
          <Select.Option key={label} value={label} label={label} style={{ minHeight: 35 }}>
            {label}
            <Badge
              count={item?.count}
              style={{ marginLeft: '10px', background: theme.colors['gray-7'], color: theme.colors.white }}
            />
          </Select.Option>
        )
      })}
    </Select>
  )
}

export default connectRefinementList(RefinementListDropdownWatcher)
