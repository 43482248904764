import React, { useState, useEffect } from 'react'

import { useHistory, Link } from 'react-router-dom'

import { LocalShipping, RequestQuote } from '@styled-icons/material'
import { Dictionary, sortBy } from 'lodash'

import { ShoppingOutlined, BuildOutlined } from '@ant-design/icons'
import { Table, Tabs, Typography } from 'antd'

import { observer } from 'mobx-react-lite'

import { Box } from 'common/components/boxes'
import { formatName } from 'common/helpers/formatters'
import { OrderStates } from 'common/server/server_types'

import { useStores } from 'contractor/hooks/use-stores'
import { IndexProject } from 'contractor/server/projects'

import { EmptySearch } from './empty_search'
import { Actions, ItemCount } from './project_card'

type ListViewProps = {
  userId: string
  projectGroups: string[]
  projectsGroupedByGroup: Dictionary<IndexProject[]>
  onFilterTextProject: (project: IndexProject) => void
}

const PROJECT_GROUP_TAB_KEY = 'projectGroupTabKey'

const getStorageProjectGroupTab = () => localStorage.getItem(PROJECT_GROUP_TAB_KEY)

const iconStyle = { marginRight: 10 }

export const ListView = observer<ListViewProps>((props) => {
  const { userId, projectsGroupedByGroup, projectGroups, onFilterTextProject } = props

  const { projectStore, companySettingStore } = useStores()

  const history = useHistory()

  const [activeKey, setActiveKey] = useState(getStorageProjectGroupTab())

  // When the user searches it set the first group as the active tab
  useEffect(() => {
    if (!projectGroups.some((projectGroupName) => projectGroupName === activeKey)) {
      setActiveKey(projectGroups[0])
    }
  }, [projectGroups.length])

  const firstProjectGroup = projectGroups[0]

  return (
    <Box p={16} flexGrow={1}>
      {!projectGroups.length && <EmptySearch />}

      {!!projectGroups.length && (
        <Tabs
          activeKey={activeKey || firstProjectGroup}
          onChange={(projectGroupName) => {
            setActiveKey(projectGroupName)
            localStorage.setItem(PROJECT_GROUP_TAB_KEY, projectGroupName)
          }}
          items={projectGroups.map((projectGroupName) => {
            const projects = sortBy(
              projectsGroupedByGroup[projectGroupName],
              ({ default_watcher_user_ids }) => !default_watcher_user_ids?.includes(userId),
            )

            return {
              label: projectGroupName,
              key: projectGroupName,
              children: (
                <Table
                  rowKey="id"
                  onRow={(record) => ({
                    onClick: () => {
                      projectStore.showProject(record?.id)
                      history.push(`/orders?project_id=${record?.id}`)
                    },
                  })}
                  style={{ width: '100%' }}
                  scroll={{ x: 1200 }}
                  columns={[
                    {
                      title: 'Name',
                      dataIndex: 'name',
                      render: (name, projectRow) => (
                        <Link onClick={(e) => e.stopPropagation()} to={`/orders?project_id=${projectRow?.id}`}>
                          {name}
                        </Link>
                      ),
                    },
                    {
                      title: 'Project ID',
                      dataIndex: 'project_id',
                      width: 150,
                    },
                    {
                      title: 'Watchers',
                      width: 250,
                      render: (_, projectRow) => {
                        const watcherNames = companySettingStore.possibleUsers
                          .filter((user) => projectRow?.default_watcher_user_ids?.includes(user?.id))
                          .map((user) => formatName(user?.first_name, user?.last_name))
                          .join(', ')
                        return <Typography.Text>{watcherNames}</Typography.Text>
                      },
                    },
                    {
                      title: 'Quoted',
                      width: 107,
                      render: (_, projectRow) => (
                        <ItemCount
                          id={projectRow.id}
                          states={[OrderStates.QUOTED]}
                          icon={<RequestQuote size="20" style={iconStyle} />}
                        />
                      ),
                    },
                    {
                      title: 'Ordered',
                      width: 107,
                      render: (_, projectRow) => (
                        <ItemCount
                          id={projectRow.id}
                          states={[OrderStates.ORDERED]}
                          icon={<ShoppingOutlined style={iconStyle} />}
                        />
                      ),
                    },
                    {
                      title: 'Shipped',
                      width: 107,
                      render: (_, projectRow) => (
                        <ItemCount
                          id={projectRow.id}
                          states={[OrderStates.SHIPPED, OrderStates.PARTIALLY_SHIPPED]}
                          icon={<LocalShipping size="20" style={iconStyle} />}
                        />
                      ),
                    },
                    {
                      title: 'Delivered',
                      width: 107,
                      render: (_, projectRow) => (
                        <ItemCount
                          id={projectRow.id}
                          states={[OrderStates.DELIVERED]}
                          icon={<BuildOutlined style={iconStyle} />}
                        />
                      ),
                    },
                    {
                      align: 'right',
                      width: 88,
                      render: (_, projectRow) => <Actions {...projectRow} project_id={null} placement="topRight" />,
                    },
                  ]}
                  pagination={false}
                  dataSource={projects.filter(onFilterTextProject)}
                />
              ),
            }
          })}
        />
      )}
    </Box>
  )
})
