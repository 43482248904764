import React from 'react'

import { Column, Row } from 'react-table'

import { Tooltip, Typography } from 'antd'

interface CellMaxWidthProps {
  row: Row
  column: Column
  value: string
}

export const CellMaxWidth = ({ value }: CellMaxWidthProps) => {
  return (
    <Tooltip title={value}>
      <Typography.Text style={{ maxWidth: 300 }} ellipsis>
        {value}
      </Typography.Text>
    </Tooltip>
  )
}
