import React from 'react'

import { useFlag } from 'contractor/hooks/use-flag'

import { OrdersAutocompleteProps, OrdersAutocomplete as AlgoliaOrdersAutocomplete } from './Algolia/orders_autocomplete'
import { OrdersPsqlAutocomplete, OrdersPsqlAutocompleteProps } from './Psql/orders_autocomplete'

export function OrdersAutocomplete(props: OrdersPsqlAutocompleteProps | OrdersAutocompleteProps): JSX.Element {
  const usingConsolidatedOrders = useFlag('consolidated_orders')

  if (usingConsolidatedOrders) {
    return <OrdersPsqlAutocomplete {...(props as OrdersPsqlAutocompleteProps)} />
  }

  return <AlgoliaOrdersAutocomplete {...(props as OrdersAutocompleteProps)} />
}
