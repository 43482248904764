import React from 'react'

import { BorderlessTableOutlined } from '@ant-design/icons'
import { Input, Space, Tooltip, Typography } from 'antd'

import { observer } from 'mobx-react-lite'

import { CustomEditableContent } from 'common/components/CustomEditableContent'

import { useStores } from 'contractor/hooks/use-stores'

import { useInvoice } from '../context'

interface InvoiceNumberProps {
  disabled?: boolean
}

export const InvoiceNumber = observer(({ disabled }: InvoiceNumberProps) => {
  const { invoiceStore } = useStores()

  const { setSelectedInvoiceDirty } = useInvoice()

  const { invoice } = invoiceStore

  return (
    <CustomEditableContent
      boxProps={{ mt: 0 }}
      input={
        <Input
          disabled={disabled}
          value={invoice?.number}
          onChange={(e) => {
            setSelectedInvoiceDirty(true)
            invoiceStore.updateSelectedInvoice('number', e.target.value)
          }}
          style={{ width: 180 }}
        />
      }
    >
      <Tooltip title="Invoice Number" placement="bottom">
        <Space>
          <BorderlessTableOutlined />
          <Typography.Text type="secondary">{invoice.number || 'Invoice Number'}</Typography.Text>
        </Space>
      </Tooltip>
    </CustomEditableContent>
  )
})
