import React from 'react'

import { Button, Typography } from 'antd'

import { Box } from 'common/components/boxes'
import { DateRangePicker } from 'common/components/DateRangePicker'
import { SelectFilter } from 'common/components/table/postgres_filters'
import { usePersistentFilters } from 'common/contexts/persistent_filters'

import { useFlag } from 'contractor/hooks/use-flag'
import { useStores } from 'contractor/hooks/use-stores'

export const OrderFilters = () => {
  const { userStore, integrationStore } = useStores()
  const {
    orderStore: { listStore },
  } = useStores()
  const values = listStore.searchState.filters
  const facets = listStore.facets

  const extractedInvoiceDataEnabled = useFlag('extracted_invoice_data')
  const tcmCsvV2DownloadEnabled = useFlag('tcm_csv_v2_download')
  const foundationCsvDownloadEnabled = useFlag('foundation_csv_download')
  const commitmentsEnabled = useFlag('commitments')
  const optionsFactory = React.useCallback(
    (key: string) => {
      return (facets[key] || []).map((x: { [key: string]: string }) => ({
        label: x.label,
        value: x.value,
      }))
    },
    [facets],
  )

  const persistentFilters = usePersistentFilters()

  return (
    <>
      <Box display="flex" flexDirection="column" p="12" gridGap={16}>
        <Box width="100%" display="flex" flexDirection="column">
          <Typography.Text type="secondary">Vendor</Typography.Text>

          <SelectFilter
            items={optionsFactory('vendor_names')}
            selected={values['vendor_name']}
            onChange={(value) => persistentFilters.handleChangeFilters({ vendor_name: value })}
            attribute="vendor_name"
            placeholder="Select vendor"
            isSearchable
          />
        </Box>

        <Box width="100%" display="flex" flexDirection="column">
          <Typography.Text type="secondary">Ordered by</Typography.Text>

          <SelectFilter
            items={optionsFactory('ordered_by')}
            selected={values['ordered_by']}
            onChange={(value) => persistentFilters.handleChangeFilters({ ordered_by: value })}
            attribute="ordered_by"
            placeholder="Select ordered by"
            isSearchable
          />
        </Box>

        <Box width="100%" display="flex" flexDirection="column">
          <Typography.Text type="secondary">Tags</Typography.Text>

          <SelectFilter
            items={optionsFactory('tags')}
            selected={values['tags']}
            onChange={(value) => persistentFilters.handleChangeFilters({ tags: value })}
            attribute="tags"
            placeholder="Select tag"
            isSearchable
          />
        </Box>

        <Box width="100%" display="flex" flexDirection="column">
          <Typography.Text type="secondary">Delivery issue</Typography.Text>

          <SelectFilter
            items={optionsFactory('delivery_issues')}
            selected={values['delivery_issue_type']}
            onChange={(value) => persistentFilters.handleChangeFilters({ delivery_issue_type: value })}
            attribute="delivery_issue_type"
            placeholder="Select delivery issue"
            isSearchable
          />
        </Box>

        {(tcmCsvV2DownloadEnabled ||
          foundationCsvDownloadEnabled ||
          (userStore.canUseIntegrations && integrationStore.purchaseOrderSyncEnabled)) && (
          <Box width="100%" display="flex" flexDirection="column">
            <Typography.Text type="secondary">Sync status</Typography.Text>

            <SelectFilter
              items={optionsFactory('integration_sync_status')}
              selected={values['sync_status']}
              onChange={(value) => persistentFilters.handleChangeFilters({ integration_sync_status: value })}
              attribute="integration_sync_status"
              placeholder="Selec sync status"
              isSearchable
            />
          </Box>
        )}

        {userStore.canUseInvoices && !extractedInvoiceDataEnabled && (
          <Box width="100%" display="flex" flexDirection="column">
            <Typography.Text type="secondary">Invoices</Typography.Text>

            <SelectFilter
              items={optionsFactory('invoiced')}
              selected={values['invoiced']}
              onChange={(value) => persistentFilters.handleChangeFilters({ invoiced: value })}
              attribute="invoiced"
              placeholder="Select invoice"
              isSearchable
            />
          </Box>
        )}

        {userStore.canUseInvoices && extractedInvoiceDataEnabled && (
          <Box width="100%" display="flex" flexDirection="column">
            <Typography.Text type="secondary">Invoices</Typography.Text>

            <SelectFilter
              items={optionsFactory('invoices')}
              selected={values['invoices']}
              onChange={(value) => persistentFilters.handleChangeFilters({ invoices: value })}
              attribute="invoices"
              placeholder="Select invoice"
              isSearchable
              formatter={(value) => value}
            />
          </Box>
        )}

        {commitmentsEnabled && (
          <Box width="100%" display="flex" flexDirection="column">
            <Typography.Text type="secondary">Commitments</Typography.Text>

            <SelectFilter
              items={optionsFactory('commitment_names')}
              selected={values['commitment_names']}
              onChange={(value) => persistentFilters.handleChangeFilters({ commitment_names: value })}
              attribute="commitment_names"
              placeholder="Select commitment"
              isSearchable
            />
          </Box>
        )}

        <Box width="100%" display="flex" flexDirection="column">
          <Typography.Text type="secondary">Requested by</Typography.Text>

          <SelectFilter
            items={optionsFactory('requested_by')}
            selected={values['requested_by']}
            onChange={(value) => persistentFilters.handleChangeFilters({ requested_by_name: value })}
            attribute="requested_by"
            placeholder="Select requested by"
            isSearchable
          />
        </Box>

        <Box width="100%">
          <Typography.Text type="secondary">Created at</Typography.Text>

          <DateRangePicker
            placeholderStart="Start date"
            placeholderEnd="End date"
            attribute="created_at"
            value={{
              startDate: values['created_at_start'],
              endDate: values['created_at_end'],
            }}
            onChange={({ startDate, endDate }) => {
              persistentFilters.handleChangeFilters({
                created_at_start: startDate,
                created_at_end: endDate,
              })
            }}
          />
        </Box>

        <Box width="100%">
          <Typography.Text type="secondary">Ordered at</Typography.Text>

          <DateRangePicker
            placeholderStart="Start date"
            placeholderEnd="End date"
            attribute="ordered_at_timestamp"
            value={{
              startDate: values['ordered_at_start'],
              endDate: values['ordered_at_end'],
            }}
            onChange={({ startDate, endDate }) => {
              persistentFilters.handleChangeFilters({
                ordered_at_start: startDate,
                ordered_at_end: endDate,
              })
            }}
          />
        </Box>

        <Box width="100%">
          <Typography.Text type="secondary">Delivery expected at</Typography.Text>

          <DateRangePicker
            placeholderStart="Start date"
            placeholderEnd="End date"
            attribute="delivery_best_guess_develivered_at_timestamp"
            value={{
              startDate: values['delivery_best_guess_develivered_at_start'],
              endDate: values['delivery_best_guess_develivered_at_end'],
            }}
            onChange={({ startDate, endDate }) => {
              persistentFilters.handleChangeFilters({
                delivery_best_guess_develivered_at_start: startDate,
                delivery_best_guess_develivered_at_end: endDate,
              })
            }}
          />
        </Box>

        <Box>
          <Typography.Text type="secondary">Quick create PO</Typography.Text>

          <SelectFilter
            items={[
              { label: 'All Orders', value: 'false' },
              { label: 'Only quick POs', value: 'true' },
            ]}
            selected={values['requested_by']}
            onChange={(value) => persistentFilters.handleChangeFilters({ is_quick_created: value })}
            attribute="quick_po"
            placeholder="Select quick PO option"
            isSearchable
          />
        </Box>

        <Button
          style={{ marginTop: 16, width: '100%' }}
          type="primary"
          data-cy="clear-filters"
          onClick={() => persistentFilters.handleClearFilters()}
          disabled={persistentFilters.appliedFiltersCount === 0}
        >
          Clear All
        </Button>
      </Box>
    </>
  )
}
