import React, { useState, useRef } from 'react'

import { Typography, Button, Modal, List } from 'antd'

import { observer } from 'mobx-react-lite'

import { FlexBoxY, FlexBoxX } from 'common/components/boxes'
import { DrawerRef } from 'common/components/Drawer'
import { Loading } from 'common/components/Loading'
import { Page } from 'common/components/Page'
import { SearchInput } from 'common/components/SearchInput'
import { useQuery } from 'common/hooks/use-query'

import { VendorProfile } from 'contractor/components/VendorProfile'
import { filterTextByFields } from 'contractor/helpers/filter-text-by-fields'
import { useStores } from 'contractor/hooks/use-stores'
import { Vendor } from 'contractor/server/vendors'

import { VendorCard } from './vendor_card'

export const SubBaseNetwork = observer(() => {
  const { vendorStore, companyVendorStore } = useStores()

  const drawerVendorRef = useRef<DrawerRef>()

  const { isLoading } = useQuery(vendorStore.getAllVendors)
  useQuery(() => companyVendorStore.getAllCompanyVendors(true))

  const [searchText, setSearchText] = useState('')
  const [selectedVendorId, setSelectedVendorId] = useState(null)

  const handleShowInfo = () => {
    Modal.info({
      title: 'SubBase Network',
      content: (
        <div>
          <Typography.Paragraph>
            Every week, we are onboarding new Vendors who start receiving orders through SubBase.
          </Typography.Paragraph>
          <Typography.Paragraph style={{ margin: 0 }}>
            Don&apos;t see one of your vendors in our network? Don&apos;t worry, they will immediately receive all your
            orders via email.
          </Typography.Paragraph>
        </div>
      ),
    })
  }

  const handleSearchText = (text = '') => setSearchText(text?.trim())

  const handeFilterText = (vendor: Vendor) => {
    return filterTextByFields({ fields: ['name', 'description', 'domain'], obj: vendor, searchText })
  }

  const handleOpenVendorProfile = (vendorId: string) => {
    drawerVendorRef.current?.show()
    setSelectedVendorId(vendorId)
  }

  return (
    <>
      <Page.Header>
        <FlexBoxY width="100%" alignItems="flex-start" justifyContent="flex-start" flexGrow={0}>
          <FlexBoxX alignItems="baseline" mb="12px">
            <Typography.Title style={{ marginBottom: 0 }} level={3}>
              SubBase Network
            </Typography.Title>
            <Button type="link" onClick={() => handleShowInfo()}>
              Learn more
            </Button>
          </FlexBoxX>

          <SearchInput placeholder="Search to find new vendors" onSearch={handleSearchText} style={{ width: '100%' }} />
        </FlexBoxY>
      </Page.Header>

      <Page.Content>
        {isLoading ? (
          <Loading />
        ) : (
          <List
            grid={{
              gutter: 20,
              xs: 1,
              sm: 1,
              md: 2,
              lg: 2,
              xl: 3,
              xxl: 4,
            }}
            dataSource={vendorStore.vendorsWithContacts.filter(handeFilterText)}
            renderItem={(vendor) => (
              <List.Item>
                <VendorCard
                  name={vendor.name}
                  description={vendor.description}
                  logo={vendor?.logo?.url}
                  onClick={() => handleOpenVendorProfile(vendor.id)}
                  contactsCount={vendor?.vendor_users_count}
                />
              </List.Item>
            )}
          />
        )}
      </Page.Content>
      <VendorProfile vendorId={selectedVendorId} ref={drawerVendorRef} />
    </>
  )
})
