import axios from 'axios'

import { SearchKeysResponse, StandardIndexRequest, StandardIndexResponse } from 'common/server/server_types'

import { Commitment } from 'contractor/server/commitments/commitment'

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace ConsolidatedCommitments {
  export interface Facets {
    [key: string]: string | Array<string>
  }

  export type StateCounts = {
    [stateName: string]: number
  }

  export type Response = StandardIndexResponse<Array<Commitment>> & {
    state_counts: StateCounts
  }
}

export async function index(request: StandardIndexRequest) {
  const { filters, ...restRequest } = request
  const url = `/consolidated_commitments`

  return axios.get<ConsolidatedCommitments.Response>(url, {
    params: {
      ...filters,
      ...restRequest,
    },
  })
}

export function facets() {
  const url = `/consolidated_commitments/facets`

  return axios.get<ConsolidatedCommitments.Facets>(url)
}

export function search_key() {
  return axios.get<SearchKeysResponse>('/consolidated_commitments/search_key')
}
