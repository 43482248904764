import React from 'react'

import { render } from 'common/test-utils/index'

import { Table } from '../table'
import * as mockOrderPackageJson from './order_package.json'

jest.mock('contractor/hooks/use-stores', () => ({
  useStores: () => ({
    orderPackageStore: {
      orderPackage: mockOrderPackageJson,
    },
  }),
}))

describe('CompareOrders > table', () => {
  it('should render correct', () => {
    const { asFragment } = render(<Table />)

    expect(asFragment()).toMatchSnapshot()
  })
})
