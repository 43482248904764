import { DirectUpload } from 'activestorage'
import _ from 'lodash'
import { BindAll } from 'lodash-decorators'

import { RcFile, UploadProps } from 'antd/lib/upload'

import { computed, observable, action } from 'mobx'

import { File } from 'common/server/server_types'

import { getUrlBySignedId } from 'contractor/server/files'

interface Upload {
  directUpload?: DirectUpload
  originFileObj?: RcFile
  file?: File
  name: string
  uid: string
  status: string
  percent: number
  signedId?: string
  attachmentId?: string
  url?: string
}

// Tried doing all of this inside of the component but ran into a bunch of issues with the state updating
// This seemed easier

@BindAll()
export default class UploaderStore {
  @observable uploads: { [key: string]: { [key: string]: Upload } } = {}
  @observable deleteAttachmentIds: { [key: string]: string[] } = {}
  @observable redirectUrls: { [key: string]: string } = {}

  @action
  resetUploads(uploadKey: string) {
    this.uploads[uploadKey] = {}
  }

  @action
  resetAllUploads() {
    this.uploads = {}
  }

  @action
  maybeInitializeUploadKey(uploadKey: string) {
    if (!(uploadKey in this.uploads)) {
      this.uploads[uploadKey] = {}
    }
  }

  @action
  addNewUpload(uploadKey: string, directUpload, file) {
    this.maybeInitializeUploadKey(uploadKey)

    // Need to update the whole object to trigger a re-render
    this.uploads = {
      ...this.uploads,
      [uploadKey]: {
        ...this.uploads[uploadKey],
        [file.uid]: {
          directUpload,
          originFileObj: file,
          file,
          name: file.name,
          uid: file.uid,
          status: 'uploading',
          percent: 0,
        },
      },
    }
  }

  @action
  addExistingFiles(uploadKey: string, files: File | File[]) {
    this.maybeInitializeUploadKey(uploadKey)
    if (!files) {
      return
    }
    if (Array.isArray(files)) {
      files.forEach((file) => {
        this.uploads[uploadKey][file.key] = {
          name: file.filename,
          uid: file.key,
          url: file.url,
          status: 'done',
          percent: 100,
          signedId: file.signed_id,
          attachmentId: file.attachment_id,
        }
      })
    } else {
      this.uploads[uploadKey][files.key] = {
        name: files.filename,
        uid: files.key,
        url: files.url,
        status: 'done',
        percent: 100,
        signedId: files.signed_id,
        attachmentId: files.attachment_id,
      }
    }
  }

  @action
  removeUpload(uploadKey: string, uid) {
    if (this.uploads[uploadKey][uid]?.attachmentId) {
      if (!(uploadKey in this.deleteAttachmentIds)) {
        this.deleteAttachmentIds[uploadKey] = []
      }
      this.deleteAttachmentIds[uploadKey].push(this.uploads[uploadKey][uid].attachmentId)
    }
    delete this.uploads[uploadKey][uid]
  }

  @action
  updateUpload(uploadKey: string, uid: string, percent: number, signed_id: string) {
    /*
      We got an error on this assignment saying it's undefined.
      Added this validation to avoid trying to set data on undefined upload.
    */
    if (!this.uploads[uploadKey][uid]) {
      console.warn(`Missing upload: [${uploadKey}][${uid}]`)
      return
    }

    // Need to update the whole object to trigger a re-render
    this.uploads = {
      ...this.uploads,
      [uploadKey]: {
        ...this.uploads[uploadKey],
        [uid]: {
          ...this.uploads[uploadKey][uid],
          status: percent >= 100 ? 'finished' : 'uploading',
          percent: percent,
          signedId: signed_id,
        },
      },
    }
  }

  @action
  setUploadError(uploadKey, uid) {
    this.uploads[uploadKey][uid]['status'] = 'error'
  }

  mapFileList(upload) {
    const partial = _.pick(upload, 'uid', 'status', 'name', 'percent', 'url', 'originFileObj')

    if (!partial.url) {
      const url = URL.createObjectURL(upload.originFileObj)
      return {
        ...partial,
        url,
      } as UploadProps['fileList'][0]
    }

    return partial as UploadProps['fileList'][0]
  }

  fileList(uploadKey: string): UploadProps['fileList'] {
    return _.values(this.uploads[uploadKey]).map(this.mapFileList) as UploadProps['fileList']
  }

  pdfUploadFileList(uploadKey: string): UploadProps['fileList'] {
    return computed(() => {
      const files = _.values(this.uploads[uploadKey]).map((u) => {
        const fileData = _.pick(u, 'uid', 'status', 'name', 'percent', 'url', 'originFileObj', 'signedId')
        if (fileData.signedId) {
          return fileData
        }
      })

      return files.filter(Boolean) as UploadProps['fileList']
    }).get()
  }

  signedIds(uploadKey: string): string[] {
    return _.values(this.uploads[uploadKey])?.map((u) => u['signedId'])
  }

  // If user tries to save but the upload hasn't completed yet they will get an error
  // we just want to make sure that's not the case
  checkIfAllUploadsCompleted() {
    let isCompleted = true
    for (const key in this.uploads) {
      const files = _.values(this.uploads[key])
      files.forEach((file) => {
        if (!file?.signedId) {
          isCompleted = false
        }
      })
    }

    return isCompleted
  }

  async getFinalUrlBySignedId(signedId: string) {
    const response = await getUrlBySignedId(signedId)
    return response.data
  }
}
