import React, { createContext, useContext, useRef, useCallback } from 'react'

import { ColumnInstance } from 'react-table'

import { observer } from 'mobx-react-lite'

import { DrawerRef } from 'common/components/Drawer'
import { Loading } from 'common/components/Loading'
import { useQuery } from 'common/hooks/use-query'

import { useStores } from 'contractor/hooks/use-stores'

const OrdersContext = createContext<OrdersContextProps>({} as OrdersContextProps)

export const useOrders = () => useContext(OrdersContext)

type OrdersContextProps = {
  deliveryDetailRef: React.MutableRefObject<DrawerRef>
  tableColumns: ColumnInstance[]
  maybeSetTableColumns: (columns: ColumnInstance) => void
}

export const OrdersProvider = observer(({ children }) => {
  const { projectStore, orderStore, integrationStore, userStore, companySettingStore } = useStores()

  const deliveryDetailRef = useRef<DrawerRef>()

  // Maybe index in case we are creating a new order from a fresh load
  const { isLoading } = useQuery(() => {
    return Promise.all([
      projectStore.maybeIndexProjects(),
      orderStore.loadSearchKey(),
      companySettingStore.indexOtherSettings(),
    ])
  })
  useQuery(() => {
    if (userStore.canUseIntegrations) {
      return integrationStore.accountingInfo()
    }
  })
  useQuery(() => {
    return Promise.all([
      orderStore.getOrderSettings(), // For Tags
      orderStore.selectOrder(null), // Make sure selected order is null, so we don't load incorrect data after click
    ])
  }, [projectStore.selectedProject?.id])

  useQuery(() => {
    return Promise.all([
      orderStore.getOrdersLastUpdated(
        projectStore.selectedProject?.id,
        orderStore.selectedRibbonFilter,
        projectStore.projects.map((p) => p.id),
      ),
    ])
  }, [projectStore.selectedProject?.id, orderStore.selectedRibbonFilter, projectStore.projects?.length])

  const [tableColumns, setTableColumns] = React.useState([])

  const maybeSetTableColumns = useCallback((columns = []) => {
    setTableColumns(columns)
  }, [])

  if (isLoading) {
    return <Loading />
  }

  if (!orderStore.searchKey.application_id) {
    return null
  }

  return (
    <OrdersContext.Provider
      value={{
        deliveryDetailRef,
        maybeSetTableColumns,
        tableColumns,
      }}
    >
      {children}
    </OrdersContext.Provider>
  )
})

export const withOrdersProvider = (Component) => (props) => {
  return (
    <OrdersProvider>
      <Component {...props} />
    </OrdersProvider>
  )
}
