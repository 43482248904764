import React from 'react'

import { CellProps } from 'react-table'

import { observer } from 'mobx-react-lite'

import { OrderedBy } from 'common/components/OrderedBy'
import { CellMaxWidth, CellQuantity, CellUnitCost } from 'common/components/OrderMaterialsTable'
import { CellOrderNumber, CellOrderState } from 'common/components/table/cells'
import { formatDateString, formatEnumValue } from 'common/helpers/formatters'
import { OrderMaterialHit } from 'common/server/order_materials'
import { OrderHit } from 'common/server/orders'

import { OrderDetailPopover } from 'contractor/components/OrderDetailPopover'
import { useStores } from 'contractor/hooks/use-stores'

import { CellCostCode } from './cell_cost_code'
import { CellPhaseCode } from './cell_phase_code'
import { CellVendorNote } from './cell_vendor_note'

const CellStatusObserver = observer<CellProps<OrderMaterialHit>>(({ row }) => {
  const { notificationStore } = useStores()

  const notificationCount = notificationStore.notificationCountByObject(row?.original?.order?.id)

  return (
    <CellOrderState order={row?.original?.order as OrderHit} notificationsCount={notificationCount} showDeliveryIssue />
  )
})

export default function Columns() {
  const { projectStore, companySettingStore } = useStores()

  // Until we have company_attributes populated just return empty array
  if (companySettingStore.companyAttributes.length === 0) {
    return []
  }

  // Order/Status Columns
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const columns: any[] = [
    // TODO: Switch to delivery status or some combination thereof? E.g. no partially delivered of a single
    // Order material, at that point we consider them two different materials
    {
      Header: 'Status',
      accessor: 'order_state',
      id: 'order_state',
      width: '120px',
      Cell: CellStatusObserver,
    },
    {
      Header: 'Description',
      accessor: 'company_material.description',
      id: 'description',
      Cell: CellMaxWidth,
    },
    {
      Header: 'Order',
      accessor: 'order.order_number',
      id: 'order_number',
      width: '25%',
      minWidth: 250,
      Cell: ({ row }) => {
        return (
          <OrderDetailPopover orderId={row.original?.order?.id}>
            <CellOrderNumber
              orderName={row?.original?.order_name}
              orderNumber={row?.original?.order?.order_number}
              orderId={row?.original?.order?.id}
              orderPackageId={row?.original?.order_package_id}
              state={row?.original?.order?.state}
              subState={row?.original?.order?.sub_state}
            />
          </OrderDetailPopover>
        )
      },
    },
    {
      Header: 'Project',
      accessor: 'project_name',
      width: '10%',
      minWidth: 200,
      Cell: ({ row }) => {
        return `${row?.original?.project_identifier ? `${row?.original?.project_identifier}: ` : ''} ${
          row?.original?.project_name
        }`
      },
    },
    {
      Header: 'Vendor',
      accessor: 'vendor',
      id: 'vendor',
      minWidth: 150,
      Cell: ({ row }) => {
        const { original } = row
        if (original?.is_draft) {
          return original.draft_vendor_names?.map((vendor_name) => (
            <div key={vendor_name} style={{ width: '100%' }}>
              {vendor_name}
            </div>
          ))
        }

        return original.company_vendor?.safe_globalized_vendor_name || ''
      },
    },
    {
      Header: 'Qty',
      accessor: 'quantity',
      id: 'quantity',
      width: '5%',
      Cell: CellQuantity,
    },
    {
      Header: 'Qty Deliv.',
      accessor: 'quantity_delivered',
      id: 'quantity_delivered',
      minWidth: 80,
      Cell: CellQuantity,
    },
    {
      Header: 'Qty Missing',
      accessor: 'missing_quantity',
      id: 'missing_quantity',
      minWidth: 100,
      Cell: CellQuantity,
    },
    {
      Header: 'Qty Incorrect',
      accessor: 'incorrect_quantity',
      id: 'incorrect_quantity',
      minWidth: 110,
      Cell: CellQuantity,
    },
    {
      Header: 'Qty Broken',
      accessor: 'broken_quantity',
      id: 'broken_quantity',
      minWidth: 100,
      Cell: CellQuantity,
    },
    {
      Header: 'Qty BO',
      accessor: 'back_ordered_quantity',
      id: 'back_ordered_quantity',
      minWidth: 80,
      Cell: CellQuantity,
    },
    {
      Header: 'UOM',
      accessor: 'unit',
      id: 'unit',
      minWidth: 100,
      Cell: ({ row }) => {
        const companyMaterial = row.original?.company_material
        if (companyMaterial?.unit_id) {
          return companyMaterial?.unit?.unit_name_with_increment_label
        }

        if (companyMaterial?.unit_name) {
          return companyMaterial?.unit_name
        }

        return null
      },
    },
    {
      Header: 'Unit Cost',
      accessor: 'unit_cost',
      id: 'unit_cost',
      minWidth: 100,
      Cell: CellUnitCost,
    },
    {
      Header: 'Ext. Cost',
      accessor: 'ext_cost',
      id: 'ext_cost',
      minWidth: 100,
      Cell: CellUnitCost,
    },
    {
      Header: 'Created At',
      accessor: 'created_at',
      id: 'created_at',
      minWidth: 160,
      Cell: ({ row }) => (row.original.order_created_at ? formatDateString(row.original.order_created_at) : ''),
    },
    {
      Header: 'Ordered By',
      accessor: 'ordered_by',
      id: 'ordered_by',
      minWidth: 150,
      Cell: ({ row }) => <OrderedBy orderHit={{ ...row.original?.order, ...row.original }} />,
    },
    {
      Header: 'Ordered At',
      accessor: 'ordered_at',
      id: 'ordered_at',
      minWidth: 160,
      Cell: ({ row }) => (row.original.ordered_at ? formatDateString(row.original.ordered_at) : ''),
    },
  ]

  // Add in the company material columns now
  companySettingStore.companyAttributes.forEach((columnName) => {
    switch (columnName) {
      case 'cost_code_id':
        columns.push({
          Header: 'Cost Code',
          accessor: 'cost_code.code',
          id: 'cost_code.code',
          Cell: CellCostCode,
          minWidth: 100,
        })
        break
      case 'cost_code_phase_id':
        columns.push({
          Header: 'Phase Code',
          accessor: 'cost_code_phase_id.code',
          id: 'cost_code_phase_id.code',
          Cell: CellPhaseCode,
          minWidth: 100,
        })
        break
      // Default price and default vendor don't show here
      case 'preferred_vendor_prices':
      case 'project_ids':
      case 'unit':
      case 'description':
        break
      default:
        columns.push({
          id: columnName,
          Header: formatEnumValue(columnName),
          accessor: `company_material.${columnName}`,
          Cell: CellMaxWidth,
        })
    }
  })

  // Adding notes at the end of the table
  columns.push(
    {
      Header: 'Company Note',
      accessor: 'company_note',
      id: 'company_note',
      width: '30%',
      minWidth: 150,
      maxWidth: 450,
    },
    {
      Header: 'Vendor Note',
      accessor: 'vendor_note',
      id: 'vendor_note',
      width: '30%',
      minWidth: 150,
      maxWidth: 450,
      Cell: ({ row }) => (
        <CellVendorNote vendorNote={row.original.vendor_note} vendorResponse={row.original.vendor_response} />
      ),
    },
  )

  // If a project was selected then don't show the project name
  if (projectStore.selectedProject) {
    columns.splice(2, 2)
  }

  return columns
}
