import { orderBy } from 'lodash'

import { makeTableData } from '../make_table_data'
import * as mockOrderPackageJson from './order_package.json'

describe('CompareOrders > make_table_data', () => {
  it('should return correct rows and vendor columns', () => {
    const orders = orderBy(mockOrderPackageJson.orders, 'vendor.vendor_name')

    const tableData = makeTableData(orders)

    expect(tableData.length).toBe(10)
    tableData.forEach((item) => {
      expect(item.vendor_items.length).toBe(4)
    })
  })

  it('should return 1 row if material is present more than 1 order material and quantity is equals', () => {
    const orders = orderBy(
      [
        {
          id: 'order_1',
          vendor: {
            vendor_name: 'vendor_1',
          },
          order_materials: [
            {
              delivery_id: 'delivery_1',
              quantity: 10,
              unit_cost: 10,
              company_material: {
                id: 'company_material_1',
              },
            },
          ],
          deliveries: [
            {
              id: 'delivery_1',
            },
          ],
        },
        {
          id: 'order_2',
          vendor: {
            vendor_name: 'vendor_2',
          },
          order_materials: [
            {
              delivery_id: 'delivery_2',
              quantity: 10,
              unit_cost: 10,
              company_material: {
                id: 'company_material_1',
              },
            },
          ],
          deliveries: [
            {
              id: 'delivery_2',
            },
          ],
        },
      ],
      'vendor.vendor_name',
    )

    const tableData = makeTableData(orders)

    expect(tableData.length).toBe(1)
    tableData.forEach((item) => {
      expect(item.vendor_items.length).toBe(2)
    })
  })

  it('should return 1 row if material is present more than 1 order material and quantity is different', () => {
    const orders = orderBy(
      [
        {
          id: 'order_1',
          vendor: {
            vendor_name: 'vendor_1',
          },
          order_materials: [
            {
              delivery_id: 'delivery_1',
              quantity: 10,
              unit_cost: 10,
              company_material: {
                id: 'company_material_1',
              },
            },
          ],
          deliveries: [
            {
              id: 'delivery_1',
            },
          ],
        },
        {
          id: 'order_2',
          vendor: {
            vendor_name: 'vendor_2',
          },
          order_materials: [
            {
              delivery_id: 'delivery_2',
              quantity: 5,
              unit_cost: 10,
              company_material: {
                id: 'company_material_1',
              },
            },
          ],
          deliveries: [
            {
              id: 'delivery_2',
            },
          ],
        },
      ],
      'vendor.vendor_name',
    )

    const tableData = makeTableData(orders)

    expect(tableData.length).toBe(2)
    tableData.forEach((item) => {
      expect(item.vendor_items.length).toBe(2)
    })
  })

  it('should return empty cell if material row is not present in order material', () => {
    const orders = orderBy(
      [
        {
          id: 'order_1',
          vendor: {
            vendor_name: 'vendor_1',
          },
          order_materials: [
            {
              delivery_id: 'delivery_1',
              quantity: 10,
              unit_cost: 10,
              company_material: {
                id: 'company_material_1',
              },
            },
          ],
          deliveries: [
            {
              id: 'delivery_1',
            },
          ],
        },
        {
          id: 'order_2',
          vendor: {
            vendor_name: 'vendor_2',
          },
          order_materials: [
            {
              delivery_id: 'delivery_2',
              quantity: 5,
              unit_cost: 10,
              company_material: {
                id: 'company_material_2',
              },
            },
          ],
          deliveries: [
            {
              id: 'delivery_2',
            },
          ],
        },
      ],
      'vendor.vendor_name',
    )

    const tableData = makeTableData(orders)

    expect(tableData.length).toBe(2)
    tableData.forEach((item) => {
      expect(item.vendor_items.length).toBe(2)
    })

    expect(tableData[0].vendor_items[1]?.delivery_id).toBeUndefined()
    expect(tableData[1].vendor_items[0]?.delivery_id).toBeUndefined()
  })
})
