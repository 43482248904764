import React from 'react'

import { Button } from 'antd'

import { Events, trackEvent } from 'common/pendo/event_tracking'
import { CommitmentStatuses } from 'common/server/server_types'

type SaveDraftButtonProps = {
  isLoading: boolean
  onSaveDraft: () => void
  disabled?: boolean
  status: CommitmentStatuses
}

export const SaveDraftButton = (props: SaveDraftButtonProps) => {
  const { isLoading, onSaveDraft, disabled, status } = props
  const source = window.location.pathname

  if (status !== CommitmentStatuses.DRAFT) return null

  return (
    <Button
      data-cy="save-draft"
      type="default"
      loading={isLoading}
      disabled={disabled}
      onClick={() => {
        trackEvent(Events.CLICK_SAVE_DRAFT, source)
        onSaveDraft()
      }}
    >
      Save
    </Button>
  )
}
