import React from 'react'

import { connectCurrentRefinements } from 'react-instantsearch-dom'

import { Button } from 'antd'

const ClearButton = ({ items, refine }) => {
  return (
    <Button type="primary" width="100%" data-cy="clear-filters" onClick={() => refine(items)} disabled={!items.length}>
      Clear All
    </Button>
  )
}

export const ClearAllButton = connectCurrentRefinements(ClearButton)
