import React, { useState, createContext, useContext } from 'react'

import { observer } from 'mobx-react-lite'

import { PersistentFiltersProvider } from 'common/contexts/persistent_filters'
import { useQuery } from 'common/hooks/use-query'

import { useStores } from 'contractor/hooks/use-stores'

const CostCodeContext = createContext<CostCodeContextProps>({} as CostCodeContextProps)

type CostCodeContextProps = {
  isBulkEditMode: boolean
  startBulkEditMode: () => void
  endBulkEditMode: () => void
}

type CostCodeProviderProps = {
  children: React.ReactNode
}

export const useCostCode = () => useContext(CostCodeContext)

export const CostCodeProvider = observer(({ children }: CostCodeProviderProps) => {
  const { costCodeStore, projectStore, companySettingStore } = useStores()

  const { costCodeListStore } = costCodeStore

  const [isBulkEditMode, setBulkEditMode] = useState(false)

  useQuery(companySettingStore.indexCompanyMaterialConfiguration)
  useQuery(projectStore.maybeIndexProjects)

  const startBulkEditMode = () => {
    setBulkEditMode(true)
    costCodeStore.startBulkEdit()
  }

  const endBulkEditMode = () => {
    setBulkEditMode(false)
    costCodeStore.endBulkEdit()
  }

  return (
    <CostCodeContext.Provider
      value={{
        isBulkEditMode,
        startBulkEditMode,
        endBulkEditMode,
      }}
    >
      <PersistentFiltersProvider ignoreQueryParams={['tab']} listStore={costCodeListStore}>
        {children}
      </PersistentFiltersProvider>
    </CostCodeContext.Provider>
  )
})
