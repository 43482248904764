import React, { useEffect, useMemo } from 'react'

import { sortBy, uniqBy } from 'lodash'

import { observer } from 'mobx-react-lite'

import { Select, Select2Props } from 'common/components/Select'

import { useStores } from 'contractor/hooks/use-stores'

export type Option = {
  value: string
  label: string
  vendorId: string
  isGlobal: boolean
  domain?: string
  ownedByContractor: boolean
}

type CompanyVendorSelectorProps = {
  domain?: string
  onMatchDomain?: (vendor: Option) => void
  defaultCompanyVendorId?: string
  defaultVendorId?: string
} & Select2Props<Option>

export const CompanyVendorSelector = observer<CompanyVendorSelectorProps>(
  ({ domain, onMatchDomain, defaultCompanyVendorId, defaultVendorId, ...props }) => {
    const { vendorStore, companyVendorStore } = useStores()

    const groupedVendors = useMemo(() => {
      const companyVendorsSelect = companyVendorStore.companyVendorsSelect
      const companyVendorWithVendor = companyVendorsSelect.filter((companyVendor) => !!companyVendor?.vendor)
      const companyVendorWithoutVendor = companyVendorsSelect.filter((companyVendor) => !companyVendor?.vendor)
      const uniqCompanyVendorWithVendor = uniqBy(companyVendorWithVendor, (companyVendor) => companyVendor?.vendor?.id)

      const companyVendors = [...companyVendorWithoutVendor, ...uniqCompanyVendorWithVendor].map((companyVendor) => {
        const vendor = companyVendor?.vendor

        return {
          value: companyVendor.id,
          label: vendor ? companyVendor.vendor.name : companyVendor.vendor_name,
          vendorId: vendor?.id,
          isGlobal: !!vendor,
          domain: vendor?.domain,
          ownedByContractor: true,
        }
      })

      const companyVendorsVendorsIds = companyVendorsSelect.map((companyVendor) => companyVendor?.vendor?.id)

      const vendors = vendorStore.vendors
        // Remove the vendor that the current contract already added to your list
        .filter((vendor) => !companyVendorsVendorsIds.includes(vendor.id))
        .map((vendor) => {
          return {
            value: vendor.id,
            label: vendor.name,
            vendorId: vendor?.id,
            isGlobal: true,
            domain: vendor?.domain,
            ownedByContractor: false,
          }
        })

      return [
        {
          label: 'Your Existing Vendors',
          options: sortBy(companyVendors, 'label'),
        },
        { label: 'Vendors from SubBase Network', options: sortBy(vendors, 'label') },
      ]
    }, [companyVendorStore.companyVendorsSelect.length, vendorStore.vendors.length])

    useEffect(() => {
      if (defaultCompanyVendorId || defaultVendorId) {
        const vendor = [...groupedVendors[0].options, ...groupedVendors[1].options].find(
          (vendor) => vendor.value === defaultCompanyVendorId || vendor.value === defaultVendorId,
        )

        if (vendor) {
          props?.onChange(vendor, { action: 'select-option' })
        }
      } else if (domain) {
        const vendor = [...groupedVendors[0].options, ...groupedVendors[1].options].find(
          (vendor) => vendor.domain === domain,
        )

        if (vendor) {
          props?.onChange(vendor, { action: 'select-option' })
          onMatchDomain(vendor)
        }
      }
    }, [defaultCompanyVendorId, defaultVendorId, groupedVendors.length, onMatchDomain, domain])

    return (
      <Select
        {...props}
        isDisabled={!!defaultCompanyVendorId || !!defaultVendorId}
        placeholder=""
        options={groupedVendors}
        styles={{
          menuList: (provided) => ({
            ...provided,
            maxHeight: 200,
          }),
        }}
      />
    )
  },
)
