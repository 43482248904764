import axios from 'axios'

import { Relationship } from 'contractor/server/integrations/integration'

export interface ProjectRelationship {
  id: string
  name: string
  relationship: Relationship
}

export interface CompanyVendorRelationship {
  id: string
  vendor_name: string
  relationship: Relationship
}

export interface CostCodeNumberRelationship {
  id: string
  code: string
  description: string
  relationship: Relationship
}

export interface CompanyMaterialRelationship {
  id: string
  description: string
  relationship: Relationship
}

export type CostCodePhaseRelationship = CostCodeNumberRelationship
export type CostCodeClassRelationship = CostCodeNumberRelationship

export interface OrderRelationships {
  project?: ProjectRelationship
  company_vendor?: CompanyVendorRelationship
  cost_code_numbers?: CostCodeNumberRelationship[]
  cost_code_phases?: CostCodePhaseRelationship[]
  cost_code_classes?: CostCodeClassRelationship[]
  company_materials?: CompanyMaterialRelationship[]
}

export function create_order(order_id: string) {
  return axios.post(`/integrations/accounting/orders/${order_id}`)
}

export function order_relationships(order_id: string) {
  return axios.get<OrderRelationships>(`/integrations/accounting/orders/${order_id}/relationships`)
}
