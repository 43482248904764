import React from 'react'

import { Column, Row } from 'react-table'

import OrderState, { OrderStateTagProps } from 'common/components/statuses/order_state'
import { OrderStates } from 'common/server/server_types'

import { OrderMaterialHit } from 'contractor/server/order_materials'

type CellOrderStateProps = {
  row: Row
  column: Column
} & OrderStateTagProps

export const CellOrderState = ({ row, texts }: CellOrderStateProps) => {
  const { state, sub_state } = (row['original'] as OrderMaterialHit)['order']

  // TODO: Currently the sub state just reflects whichever item algolia decided to throw at us
  // We are grouping quoted materials together so things can get weird
  return <OrderState state={state} sub_state={state == OrderStates.QUOTED ? null : sub_state} texts={texts} />
}
