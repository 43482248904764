import React from 'react'

import { connectCurrentRefinements } from 'react-instantsearch-dom'

import { Button } from 'antd'

type ClearFiltersButtonProps = {
  items: object[]
  refine: (items: object[]) => void
}

const ClearFiltersButton = ({ items, refine }: ClearFiltersButtonProps) => {
  return (
    <Button type="link" data-cy="clear-filters" onClick={() => refine(items)} disabled={!items.length}>
      Clear All
    </Button>
  )
}

const CustomClearRefinements = connectCurrentRefinements(ClearFiltersButton)

export default CustomClearRefinements
