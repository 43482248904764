/** @jsx jsx */

import * as React from 'react'

import { jsx } from '@emotion/core'

import { observer } from 'mobx-react-lite'

import Download from 'common/components/table/download'
import { formatDateStringShort } from 'common/helpers/formatters'
import { formatEnumValue } from 'common/helpers/formatters'

import { useFlag } from 'contractor/hooks/use-flag'
import { useStores } from 'contractor/hooks/use-stores'

interface DownloadProps {
  filters: string
}

const DownloadButton: React.FC<DownloadProps> = observer(({ filters }) => {
  const { orderMaterialStore, companySettingStore } = useStores()

  const externalVendorIdEnabled = useFlag('external_vendor_id')

  // By the point we have the download button, should have already loaded the company attributes
  const { company_attributes } = companySettingStore.companyMaterialConfiguration

  const headers = [
    { label: 'Project Name', key: 'project_name' },
    { label: 'Project ID', key: 'project_identifier' },
    { label: 'Order Number', key: 'order.order_number' },
    { label: 'Order Name', key: 'order_name' },
    { label: 'Created At', key: 'created_at' },
    { label: 'Updated At', key: 'updated_at' },
    { label: 'Vendor', key: 'vendor_name' },
    { label: 'Status', key: 'order_state' },
    { label: 'Sub Status', key: 'order_sub_state' },
    { label: 'Vendor Note', key: 'vendor_note' },
    { label: 'Company Note', key: 'vendor_note' },
    { label: 'Vendor Response', key: 'company_note' },
  ]
  company_attributes
    .filter((column) => !['project_ids'].includes(column))
    .forEach((column) => {
      if (column === 'cost_code_id') {
        headers.push({ label: 'Cost Code', key: 'cost_code.code' })
      } else {
        headers.push({ label: formatEnumValue(column), key: `company_material.${column}` })
      }
    })

  headers.push(
    { label: 'Quantity', key: 'quantity' },
    { label: 'Unit Cost', key: 'unit_cost' },
    { label: 'Extended Cost', key: 'ext_cost' },
  )

  const transformData = (hit) => {
    hit['created_at'] = formatDateStringShort(hit['created_at'])
    hit['updated_at'] = formatDateStringShort(hit['updated_at'])
    // Sometimes these can be null (i.e. draft)
    hit['company_vendor'] = hit['company_vendor'] || {}
    hit['company_material'] = hit['company_material'] || {}
    hit['company_material']['unit'] =
      hit?.company_material?.unit?.unit_name_with_increment_label || hit?.company_material?.unit_name

    const vendorName = hit['company_vendor']?.['safe_globalized_vendor_name']

    if (externalVendorIdEnabled) {
      hit['vendor_name'] = hit['company_vendor']?.['external_vendor_id'] || vendorName
    } else {
      hit['vendor_name'] = vendorName
    }
  }

  return (
    <Download
      filters={filters}
      store={orderMaterialStore}
      indexName={orderMaterialStore.searchState['sortBy'] || orderMaterialStore.searchKey.index_name}
      headers={headers}
      transformData={transformData}
      title={'Order Materials'}
      file_name={'order_materials'}
    />
  )
})

export default DownloadButton
