/* eslint-disable no-undef */
import { ENVIRONMENT } from 'common/helpers/check_environment'

const userInfo = {
  user_id: '',
  company_id: '',
  company_name: '',
}

export const setEventsUserInfo = (user_id: string, company_id: string, company_name: string) => {
  userInfo.user_id = user_id
  userInfo.company_id = company_id
  userInfo.company_name = company_name
}

export const trackEvent = (name: string, location: string, data = {}) => {
  // The event tracking is raising errors while e2e is executing
  if (ENVIRONMENT === 'development') return
  const properties = {
    ...data,
    ...userInfo,
    location,
    environment: ENVIRONMENT,
  }
  if (window?.pendo?.isReady?.()) {
    return window?.pendo?.track(name, properties)
  }
  setTimeout(function () {
    trackEvent(name, location, data)
  }, 500)
}

export enum Events {
  CLOSE_VENDOR_BASIC_ALERT = 'close_vendor_basic_alert',
  TABLE_SORTING = 'table_sorting',
  TABLE_FILTERING = 'table_filtering',
  ADD_VENDORS = 'add_vendors',
  ADD_ADDRESS = 'add_address',
  ADD_MATERIAL = 'add_material',
  QUICK_COMMENT = 'quick_comment',
  ORDER_HISTORY = 'order_history',
  ERROR_BOUNDARY = 'error_boundary',
  ORDER_DETAIL_HOVER = 'order_detail_hover',
  DELIVERY_INFO = 'delivery_info',
  TOGGLE_SPREADSHEET = 'toggle_spreadsheet',
  DOWNLOAD_PO = 'download_po',
  HOVER_PRICING_HISTORY = 'hover_pricing_history',
  SELECT_PRICE_FROM_PRICING_HISTORY = 'select_price_from_pricing_history',
  ORDER_NOTIFICATION_CLICKED_VIEW_ORDER = 'order_notification_clicked_view_order',
  ORDER_NOTIFICATION_CLICKED_DOWNLOAD_PO = 'order_notification_clicked_download_po',

  // Commitments
  CLICK_COMMITMENTS_TAB = 'click_commitments_tab',
  CLICK_ORDERS_TAB = 'click_orders_tab',
  CLICK_INVOICES_TAB = 'click_invoices_tab',
  CLICK_COMMITMENTS_FILTER = 'click_commitments_filter',
  CLICK_COMMITMENTS_FILTER_CLEAR = 'click_commitments_filter_clear',
  CLICK_PLACE_ORDER = 'click_place_order',
  CLICK_SAVE_DRAFT = 'click_save_draft',
  CLICK_SUBMIT_COMMITMENT = 'click_submit_commitment',
  CLICK_UPDATE_COMMITMENT = 'click_update_commitment',
  CLICK_CANCEL_COMMITMENT = 'click_cancel_commitment',
  CLICK_OPEN_ATTACHMENT = 'click_open_attachment',
  CLICK_EXPIRATION_DATE = 'click_expiration_date',
  CLICK_VENDOR = 'click_vendor',
  CLICK_PROJECT = 'click_project',
  CLICK_COMMITMENT_NUMBER = 'click_commitment_number',
  CLICK_COMMITMENT_NAME = 'click_commitment_name',
  CLICK_COMMITMENT_UPLOAD = 'click_commitment_upload',
  CLICK_COMMITMENT_MATERIAL_UNLOCK = 'click_commitment_material_unlock',
  CLICK_COMMITMENT_MATERIAL_DELETE = 'click_commitment_material_delete',

  VENDOR_SAVE_OLD_ORDER_VERSION = 'vendor_save_old_order_version',

  // Order Detail
  CHANGE_ORDER_DETAIL_TAB = 'change_order_detail_tab',

  // Landing
  SELECT_ACCESS_TYPE = 'select_access_type',
  CLICK_LOGIN = 'click_login',
  CLICK_CONTACT_US = 'click_contact_us',
  CLICK_LOGIN_AS = 'click_login_as',
  CLICK_REGISTER = 'click_register',
  CLICK_LOGIN_CONTRACTOR_REGISTER = 'click_vendor_register',

  // Invoice
  CLICK_INVOICE_INBOX = 'click_invoice_inbox',
  CHANGE_INVOICE_VISUALIZATION = 'change_invoice_visualization',

  RELOAD_IDLE_USER = 'reload_idle_user',
}

export const trackTableFiltering = (location: string, field: string) => {
  trackEvent(Events.TABLE_FILTERING, location, {
    field,
  })
}
