import React from 'react'

import { Col, Form, Input, ColProps } from 'antd'

import { observer } from 'mobx-react-lite'

import { useQuery } from 'common/hooks/use-query'

import { SelectUser } from 'contractor/components/SelectUser'
import { useStores } from 'contractor/hooks/use-stores'

type InvoiceAssigneesProps = {
  colProps?: ColProps
}

export const InvoiceAssignees = observer<InvoiceAssigneesProps>(({ colProps }) => {
  const { projectInvoiceAssigneeStore } = useStores()

  useQuery(projectInvoiceAssigneeStore.getAllProjectInvoiceAssignees)

  return (
    <>
      {projectInvoiceAssigneeStore.projectInvoiceAssignees?.map((projectInvoiceAssignee, index) => (
        <Col xs={24} sm={12} lg={8} xl={6} xxl={4} {...colProps} key={projectInvoiceAssignee.id}>
          <Form.Item
            name={['projectInvoiceAssignees', index, 'userId']}
            label={`Invoice assignee - ${projectInvoiceAssignee.name}`}
          >
            <SelectUser placeholder="Search for user" />
          </Form.Item>
          <Form.Item name={['projectInvoiceAssignees', index, 'id']} hidden initialValue={projectInvoiceAssignee.id}>
            <Input />
          </Form.Item>
        </Col>
      ))}
    </>
  )
})
