import React, { useEffect, useState } from 'react'

import { ExternalLink } from '@styled-icons/evaicons-solid'
import moment from 'moment'

import { Button, Checkbox, Divider, Form, Input, message, Modal, Select, Space, Typography } from 'antd'

import { observer } from 'mobx-react-lite'

import { Box } from 'common/components/boxes'
import { Drawer, DrawerRef } from 'common/components/Drawer'
import DeliveryState from 'common/components/statuses/delivery_state'
import Uploader from 'common/components/uploader'
import { formatDateString } from 'common/helpers/formatters'
import { OrderDelivery } from 'common/server/deliveries'
import { OrderHit } from 'common/server/orders'
import { DeliveryStatuses, OrderStates } from 'common/server/server_types'

import IncompleteItems from 'contractor/components/DeliveryDetail/IncompleteItems/incomplete_items'
import { OrdersAutocomplete } from 'contractor/components/OrdersAutocomplete'
import { SelectDeliveryIssues } from 'contractor/components/SelectDeliveryIssues'
import { useStores } from 'contractor/hooks/use-stores'

type LogDeliveryProps = {
  onClose: () => void
}

const LOG_DELIVERY_FILES_KEY = 'log_delivery_files'

export const LogDelivery = observer<LogDeliveryProps, DrawerRef>(
  (props, ref) => {
    const { onClose } = props
    const { uploaderStore, deliveryStore, userStore } = useStores()

    const selectedDelivery = deliveryStore.selectedDelivery?.delivery
    const selectedOrderMaterials = deliveryStore.selectedDelivery?.order?.order_materials

    const [order, setOrder] = useState<OrderHit>()
    const [isSubmitting, setSubmitting] = useState(false)

    const [form] = Form.useForm()
    const deliveryIssueTypeValue = Form.useWatch('deliveryIssueType', form)
    const deliveryIssuesValue = Form.useWatch('deliveryIssues', form)
    const incompleteItemsValue = Form.useWatch('incompleteItems', form) || []
    const resolvedValue = Form.useWatch('resolved', form)

    // Create a local version of the deliver
    useEffect(() => {
      uploaderStore.resetUploads(LOG_DELIVERY_FILES_KEY)
    }, [])

    useEffect(() => {
      if (selectedDelivery) {
        form.setFieldValue('deliveryIssueType', selectedDelivery.delivery_issue_type)
        form.setFieldValue('deliveryIssues', selectedDelivery.delivery_issues)
        form.setFieldValue('resolved', !!selectedDelivery.delivery_issue_resolved_at)
      }
    }, [selectedDelivery?.id])

    const handleClose = () => {
      deliveryStore.selectDelivery(null)
      uploaderStore.resetUploads(LOG_DELIVERY_FILES_KEY)
      setOrder(null)
      form.resetFields()
      onClose()
    }

    const handleFinish = async (formValues) => {
      if (!uploaderStore.checkIfAllUploadsCompleted()) {
        Modal.error({ title: 'Uploads have not completed yet, please try again' })
        return
      }

      try {
        setSubmitting(true)
        // Don't show the existing files to the user, but we need to send them to the backend so as not to overwrite them with new ones
        const existentDeliveryFiles = selectedDelivery?.delivery_files?.map((file) => file?.signed_id)
        const newDeliveryFiles = uploaderStore.signedIds(LOG_DELIVERY_FILES_KEY)

        await deliveryStore.updateDelivery({
          id: selectedDelivery.id,
          delivery_files_signed_ids: [...existentDeliveryFiles, ...newDeliveryFiles],
          delivery_issues: formValues.deliveryIssues || null,
          delivery_issue_type: formValues.deliveryIssueType || null,
          delivery_issue_resolved_at: formValues.resolved ? moment().toISOString() : null,
          delivery_issue_resolved_by: formValues.resolved ? { id: userStore.userId } : null,
          incomplete_items: formValues.incompleteItems || null,
          // Keep the same rules from the delivery modal
          actual_delivered_at: moment().toISOString(),
          marked_delivered_at: moment().toISOString(),
          marked_delivered_by: { id: userStore.userId },
        } as OrderDelivery)

        handleClose()
        message.success('Delivery updated')
      } catch (error) {
        message.error(error?.response?.data?.error || 'Unable to update delivery')
      } finally {
        setSubmitting(false)
      }
    }

    return (
      <Drawer ref={ref} title="Log Delivery" onClose={handleClose} closable={false} width={800} destroyOnClose>
        <Box p={12} width="100%" overflowY="auto">
          <Form
            form={form}
            style={{
              width: '100%',
              minHeight: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}
            layout="vertical"
            onFinish={handleFinish}
          >
            <Box>
              <Form.Item label="Files" name="files">
                <Uploader
                  component="Upload"
                  hasCamera
                  showPreviewModal
                  multiple
                  uploadKey={LOG_DELIVERY_FILES_KEY}
                  listType="picture-card"
                  showDownload
                  noResetUploads
                  hasFileNameTimeStamp
                  fileList={uploaderStore.fileList(LOG_DELIVERY_FILES_KEY)}
                  onRemoveUpload={uploaderStore.removeUpload}
                  onAddNewUpload={uploaderStore.addNewUpload}
                  onResetUploads={uploaderStore.resetUploads}
                  onSetUploadError={uploaderStore.setUploadError}
                  onUpdateUpload={uploaderStore.updateUpload}
                />
              </Form.Item>

              <Form.Item
                label={
                  <>
                    {!order ? (
                      <Typography.Text>Order</Typography.Text>
                    ) : (
                      <Typography.Link target="_blank" href={`/order/${order.id}`}>
                        <Box display="inline-flex">
                          {`${order.order_number}:${order.name}`}
                          <ExternalLink size="13" />
                        </Box>
                      </Typography.Link>
                    )}
                  </>
                }
                name="orderId"
                rules={[{ required: true, message: 'Order is required!' }]}
              >
                <OrdersAutocomplete
                  aria-label="order-autocomplete-log-delivery"
                  portal={false}
                  detachedMediaQuery="none"
                  size="small"
                  onSelect={(selectedOrder) => {
                    form.setFieldsValue({ orderId: selectedOrder.id })

                    if (selectedOrder?.deliveries.length === 1) {
                      deliveryStore.selectDelivery(selectedOrder?.deliveries[0].id)
                    }

                    setOrder(selectedOrder)
                  }}
                  filters={{
                    state: [
                      OrderStates.DELIVERED,
                      OrderStates.ORDERED,
                      OrderStates.SHIPPED,
                      OrderStates.PARTIALLY_SHIPPED,
                    ],
                  }}
                />
              </Form.Item>

              {order?.deliveries.length > 1 && (
                <Form.Item
                  label="Delivery"
                  name="deliveryId"
                  rules={[{ required: true, message: 'Delivery is required!' }]}
                >
                  <Select
                    options={order?.deliveries.map((delivery) => ({
                      value: delivery.id,
                      label: (
                        <Space>
                          <DeliveryState state={delivery.state} style={{ margin: 0 }} />
                          <Typography.Text type="secondary">
                            {formatDateString(
                              delivery.requested_delivered_at ||
                                delivery.estimated_delivered_at ||
                                delivery.best_guess_delivered_at,
                            )}
                          </Typography.Text>
                        </Space>
                      ),
                    }))}
                    onChange={(deliveryId) => {
                      deliveryStore.selectDelivery(deliveryId)
                    }}
                  />
                </Form.Item>
              )}

              <Form.Item label="Issues" name="deliveryIssueType">
                <SelectDeliveryIssues />
              </Form.Item>
              {deliveryIssueTypeValue == DeliveryStatuses.INCOMPLETE_DELIVERY && selectedDelivery && (
                <Form.Item name="incompleteItems">
                  <IncompleteItems orderMaterials={selectedOrderMaterials} />
                </Form.Item>
              )}

              {deliveryIssueTypeValue && (
                <>
                  <Form.Item
                    style={{ paddingTop: 8 }}
                    name="deliveryIssues"
                    data-cy="delivery-issues"
                    label="Provide details on the problem for your vendor"
                  >
                    <Input.TextArea placeholder="Describe issues with delivery here" style={{ resize: 'none' }} />
                  </Form.Item>

                  <Form.Item name="resolved" valuePropName="checked">
                    <Checkbox>Mark as Resolved</Checkbox>
                  </Form.Item>
                </>
              )}
            </Box>

            <Box>
              <Divider />

              <Box display="flex" justifyContent="space-between">
                <Button onClick={handleClose} loading={isSubmitting} style={{ width: 100 }}>
                  Cancel
                </Button>

                {/*Require delivery issue to be filled or items to be select if the delivery issue type is incomplete*/}
                <Button
                  disabled={
                    !incompleteItemsValue?.filter((item) => !item?.resolved)?.length &&
                    deliveryIssueTypeValue == DeliveryStatuses.INCOMPLETE_DELIVERY &&
                    !deliveryIssuesValue &&
                    !resolvedValue
                  }
                  data-cy="save_delivery"
                  type="primary"
                  loading={isSubmitting}
                  htmlType="submit"
                  style={{ width: 100 }}
                >
                  Save
                </Button>
              </Box>
            </Box>
          </Form>
        </Box>
      </Drawer>
    )
  },
  { forwardRef: true },
)
