import React from 'react'

import { Table } from 'antd'

import { observer } from 'mobx-react-lite'

import { makeOption, ValueType } from 'common/components/DebounceSelect'
import { MappingDirections } from 'common/server/server_types'

import { SelectAgaveCostCodes } from 'contractor/components/SelectAgaveCostCodes'
import {
  hasSuggestions,
  maybeAddApproveRejectColumn,
} from 'contractor/components/SelectExternalMappings/select_external_mappings'
import { SelectIntegrationEntity } from 'contractor/components/SelectIntegrationEntity/select_integration_entity'
import { useStores } from 'contractor/hooks/use-stores'
import { CostCodeNumbersMultiSelect } from 'contractor/pages/Integrations/Mappings/CostCodeNumbers/cost_code_numbers_multiselect'
import { ProcoreMultiSelect } from 'contractor/pages/Integrations/Mappings/ProcoreMultiSelect'
import RelationshipsTable from 'contractor/pages/Integrations/Mappings/Relationships/relationships_table'
import { SuggestionAlert } from 'contractor/pages/Integrations/Mappings/suggestion_alert'
import { useUpdateMappingsHook } from 'contractor/pages/Integrations/Mappings/use_update_mappings_hook'
import { ListParams } from 'contractor/server/integrations'
import { CostCodeNumber } from 'contractor/server/integrations/cost_code_numbers'

interface CostCodeNumbersTableProps {
  filter?: ListParams
  setFilter?: React.Dispatch<React.SetStateAction<ListParams>>
  isLoading: boolean
}

const CostCodeNumbersTable = observer<CostCodeNumbersTableProps>(({ isLoading, filter, setFilter }) => {
  const { integrationStore, userStore } = useStores()

  const reloadCallback = (fetchRelationships = true) => {
    if (fetchRelationships) integrationStore.getCostCodeNumbersRelationships(filter)
    integrationStore.getCostCodeNumbers(filter)
    integrationStore.getCostCodeNumbersCount({ search: filter?.search })
  }

  const { handleUpdate, handleDirectUpdate, handleSuggestionsApproval, handleSuggestionsRejection, updating } =
    useUpdateMappingsHook({
      update: integrationStore.updateCostCodeNumber,
      reloadCallback,
    })

  if (integrationStore.mappingDirection === MappingDirections.INSIDE_OUT) {
    const columns = [
      {
        title: 'SubBase Code',
        dataIndex: 'code',
        width: '20%',
      },
      {
        title: 'SubBase Description',
        dataIndex: 'description',
        width: '20%',
      },
      {
        title: `${integrationStore.title()} Cost Codes`,
        width: integrationStore.showCostCodeNumbersSuggestions() ? '53%' : '60%',
        dataIndex: 'external_relationships',
        render: (_, row) => {
          const selected = []
          const suggestions = hasSuggestions(row)
          const externalItems = []
          if (suggestions) {
            externalItems.push(...row.mapping_suggestions)
          } else {
            externalItems.push(...row.external_relationships)
          }
          selected.push(...externalItems.map((item) => makeOption(item)))
          if (integrationStore.isProcore()) {
            return (
              <ProcoreMultiSelect
                hasSuggestions={suggestions}
                selected={selected}
                values={externalItems}
                row={row}
                handleUpdate={handleUpdate}
              >
                <SelectAgaveCostCodes
                  selected={selected}
                  loading={updating}
                  showArrow={true}
                  disabled={!userStore.canSyncWithErp}
                  onChange={(value) => handleUpdate({ option: value, entity: row })}
                  status={suggestions ? 'warning' : undefined}
                  autoClearSearchValue={false}
                  tagRender={() => <></>}
                />
              </ProcoreMultiSelect>
            )
          }
          if (integrationStore.isViewpointSpectrum()) {
            return (
              <CostCodeNumbersMultiSelect
                hasSuggestions={suggestions}
                selected={selected}
                values={externalItems}
                row={row}
                handleUpdate={handleUpdate}
              >
                <SelectAgaveCostCodes
                  selected={selected}
                  loading={updating}
                  showArrow={true}
                  disabled={!userStore.canSyncWithErp}
                  onChange={(value) => handleUpdate({ option: value, entity: row })}
                  status={suggestions ? 'warning' : undefined}
                />
              </CostCodeNumbersMultiSelect>
            )
          }
          return (
            <SelectAgaveCostCodes
              selected={selected}
              loading={updating}
              showArrow={true}
              disabled={!userStore.canSyncWithErp}
              onChange={(value) => handleUpdate({ option: value, entity: row })}
              status={suggestions ? 'warning' : undefined}
            />
          )
        },
      },
    ]
    maybeAddApproveRejectColumn(
      'costCodeNumbers',
      integrationStore.costCodeNumbers?.data,
      integrationStore.showCostCodeNumbersSuggestions,
      columns,
      handleSuggestionsApproval,
      handleSuggestionsRejection,
    )
    return (
      <>
        <SuggestionAlert showSuggestions={integrationStore.showCostCodeNumbersSuggestions()} />
        <Table<CostCodeNumber>
          pagination={{
            total: integrationStore.costCodeNumbers?.totalCount,
            current: integrationStore.costCodeNumbers?.currentPage,
            pageSize: integrationStore.costCodeNumbers?.pageItems,
            onChange: (page) => {
              setFilter((prev) => ({ ...prev, page }))
            },
            showSizeChanger: false,
          }}
          loading={isLoading}
          rowKey="id"
          style={{ width: '100%' }}
          columns={columns}
          dataSource={integrationStore.costCodeNumbers?.data}
        />
      </>
    )
  }

  const disabledOption = (item: CostCodeNumber) =>
    integrationStore.enabledCostCodesMultiMapping() ? false : item.external_relationships.length > 0

  const makeInternalOption = (item: CostCodeNumber) =>
    ({
      value: item.id,
      label: item.code,
      disabled: disabledOption(item),
    } as ValueType)

  return (
    <RelationshipsTable
      isLoading={isLoading}
      filter={filter}
      setFilter={setFilter}
      showSuggestions={integrationStore.showCostCodeNumbersSuggestions()}
      handleSuggestionsApproval={handleSuggestionsApproval}
      handleSuggestionsRejection={handleSuggestionsRejection}
      relationships={integrationStore.costCodeNumbersRelationships}
      renderRowTitle="Cost Codes"
      renderRow={(row) => (
        <SelectIntegrationEntity
          loading={updating}
          row={row}
          disabled={!userStore.canSyncWithErp}
          optionApi={integrationStore.getCostCodeNumbers}
          optionData={integrationStore.costCodeNumbers}
          makeInternalOption={makeInternalOption}
          handleUpdate={handleDirectUpdate}
          status={row.mapping_suggestions && row.mapping_suggestions.length > 0 ? 'warning' : undefined}
        />
      )}
    />
  )
})

export default CostCodeNumbersTable
