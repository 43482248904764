import _ from 'lodash'
import { BindAll } from 'lodash-decorators'
import moment from 'moment'

import { computed, observable, action } from 'mobx'

import { Address } from 'common/server/addresses'
import { UserRoles } from 'common/server/server_types'

import { create as createAddress, update as updateAddress } from 'contractor/server/addresses'
import { index as indexAddresses } from 'contractor/server/company_settings/addresses'
import {
  CompanyMaterialConfiguration,
  index as indexCompanyMaterialConfiguration,
  update as updateCompanyMaterialConfiguration,
} from 'contractor/server/company_settings/company_material_configuration'
import {
  index as indexUsers,
  invite_user,
  ManagedUser,
  update_user,
  UpdateRequest,
  IndexUserFilter,
} from 'contractor/server/company_settings/manage_users'
import {
  index as indexOtherSettings,
  OtherSettings,
  update as updateOtherSettings,
} from 'contractor/server/company_settings/other_settings'
import {
  index as indexPermissions,
  update as updatePermissions,
  Permissions,
} from 'contractor/server/company_settings/permissions'

// ID implies it is one of our default columns, and you can't change
// Name of our default columns
@BindAll()
export default class CompanySettingStore {
  @observable indexUserFilter: IndexUserFilter = { ungrouped: false, activated: true, deactivated: false }
  // *************************************** USERS ***************************************
  users = observable.array<ManagedUser>([])

  maybeIndexUsers() {
    if (!this.users.length) {
      return this.indexUsers()
    }
  }

  @action
  async indexUsers(): Promise<void> {
    this.users.replace((await indexUsers()).data.users)
  }

  @computed
  get possibleUsers(): ManagedUser[] {
    return _.filter(
      this.users,
      (user) => (!!user.invitation_accepted_at || user.sign_in_count > 0) && user.is_active && !user.deactivated_at,
    )
  }

  @computed
  get filteredUsers(): ManagedUser[] {
    let users = this.users as ManagedUser[]
    if (this.indexUserFilter.ungrouped) {
      users = _.filter(this.users, (user) => user.project_group_ids.length == 0)
    }
    if (this.indexUserFilter.activated) {
      users = _.filter(users, (user) => user.is_active && !user.deactivated_at)
    }
    if (this.indexUserFilter.deactivated) {
      users = _.filter(users, (user) => !user.is_active || !!user.deactivated_at)
    }
    return users
  }

  filterUsers(ids: string[] = []): ManagedUser[] {
    return _.filter(this.users, (user) => ids.includes(user.id))
  }

  @action
  async updateUser(params: UpdateRequest): Promise<void> {
    await update_user(params)
    const users = [...this.users]
    const index = _.findIndex(this.users, { id: params?.user_id })

    const { role, project_group_ids, is_active } = params

    if (role) users[index].role = role
    if (project_group_ids && project_group_ids.length !== users[index].project_group_ids.length) {
      users[index].project_group_ids = project_group_ids
    }
    if (![null, undefined].includes(is_active)) {
      users[index].is_active = is_active
      users[index].deactivated_at = new Date().toISOString()
    }

    // For some reason the full replace is triggering a re-render but not the individual replace. It's as if
    // The observable isn't deep although Mobx documentation says it should be. Could also be an issue
    // with the Table component
    this.users.replace(users)
  }

  @action
  async resendInvitation(user_id: string): Promise<void> {
    await update_user({ user_id, resend_invitation: true })
    const users = [...this.users]
    const index = _.findIndex(this.users, { id: user_id })
    users[index].invitation_sent_at = moment().toISOString()
    // For some reason the full replace is triggering a re-render but not the individual replace. It's as if
    // The observable isn't deep although Mobx documentation says it should be. Could also be an issue
    // with the Table component
    this.users.replace(users)
  }

  async inviteUsers(
    emails: string,
    role: UserRoles,
    projectGroupIds?: string[],
    replacingUserId?: string,
  ): Promise<void> {
    // HACK: Want to catch errors so await invitations serially
    for (const email of emails.split(',')) {
      await invite_user({
        email: email.trim(),
        role,
        project_group_ids: projectGroupIds,
        replacing_user_id: replacingUserId,
      })
    }
  }

  // ****************************** Other Settings ***************************************
  @observable otherSettings: OtherSettings

  @action
  async indexOtherSettings() {
    const response = await indexOtherSettings()
    this.otherSettings = response?.data
    return this.otherSettings
  }

  @observable permissions: Permissions
  async indexPermissions() {
    const response = await indexPermissions()
    this.permissions = response?.data
    return this.permissions
  }

  async invoicingMail(): Promise<string> {
    const mailSuffix = '@invoices.subbase.io'
    if (this.otherSettings?.invoicing_email) return `${this.otherSettings.invoicing_email}${mailSuffix}`
    await this.indexOtherSettings()
    return `${this.otherSettings.invoicing_email}${mailSuffix}`
  }

  @action
  async updateOtherSettings(settings: OtherSettings) {
    this.otherSettings = (await updateOtherSettings(settings))?.data
  }

  async updatePermissions(permissions: Permissions) {
    this.permissions = (await updatePermissions(permissions))?.data
  }

  // ****************************** Company Material Configuration ***************************************
  @observable companyMaterialConfiguration: CompanyMaterialConfiguration = {
    all_attributes: [],
    company_attributes: [],
    required_attributes: [],
  }

  @computed
  get companyAttributes() {
    const costCodeEnabled = this.companyMaterialConfiguration?.company_attributes.includes('cost_code_id')
    const independentPhaseCodesEnabled = this.otherSettings?.cost_code_settings?.independent_phase_codes_enabled

    // When the user uses cost code and enabled independent_phase_codes_enabled
    // we need to append on attributes to show a new phase code column on the spreadsheet
    if (costCodeEnabled && independentPhaseCodesEnabled) {
      return [...this.companyMaterialConfiguration?.company_attributes, 'cost_code_phase_id']
    }

    // Sort the array to keep the cost code and phase code together
    const order = ['cost_code_id', 'cost_code_phase_id']
    return this.companyMaterialConfiguration?.company_attributes.sort((a, b) => {
      const indexA = order.indexOf(a)
      const indexB = order.indexOf(b)

      if (indexA !== -1 && indexB !== -1) {
        return indexA - indexB
      }

      if (indexA !== -1) {
        return -1
      }
      if (indexB !== -1) {
        return 1
      }

      return 0
    })
  }

  @computed
  get activeTermsAndConditions() {
    return this.otherSettings?.terms_and_conditions?.filter((termsAndCondition) => !termsAndCondition.discarded_at)
  }

  @action
  async indexCompanyMaterialConfiguration(): Promise<void> {
    this.companyMaterialConfiguration = (await indexCompanyMaterialConfiguration()).data
  }

  @action
  async updateCompanyMaterialConfiguration(): Promise<void> {
    const { company_attributes } = this.companyMaterialConfiguration
    updateCompanyMaterialConfiguration(company_attributes)
  }

  @action
  updateCompanyMaterialAttributes(newAttributes: string[]): void {
    this.companyMaterialConfiguration.company_attributes = newAttributes
  }

  @action
  shiftCompanyMaterialAttributes(fromIndex: number, direction: 'UP' | 'DOWN') {
    // Mobx doesn't love splicing, small array easier to just replace it
    const { company_attributes } = this.companyMaterialConfiguration
    const newArray = [...company_attributes]
    const element = newArray[fromIndex]
    const toIndex = fromIndex + (direction === 'UP' ? -1 : 1)
    if (toIndex < 0 || toIndex >= company_attributes.length) {
      console.error("Can't shift attribute index any further")
      return
    }
    newArray.splice(fromIndex, 1)
    newArray.splice(toIndex, 0, element)
    this.companyMaterialConfiguration.company_attributes = newArray
  }

  // **************************** Addresses ************************************
  addresses = observable.array<Address>([])

  @action
  async indexAddresses(): Promise<void> {
    this.addresses.replace((await indexAddresses()).data.addresses)
  }

  async addAddress(company_id: string, address: Address): Promise<void> {
    await createAddress({ company_id, address })
    this.indexAddresses()
  }

  async updateAddress(address: Address): Promise<void> {
    await updateAddress({ id: address.id, address })
    this.indexAddresses()
  }
}
