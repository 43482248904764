import React from 'react'

import * as XLSX from 'xlsx'

import DownloadOutlined from '@ant-design/icons/DownloadOutlined'
import { Button, message } from 'antd'

import { SUPPORT_EMAIL_ADDRESS } from 'common/helpers/other'

import { useStores } from 'contractor/hooks/use-stores'

import { makeDownloadCostCodeFile } from '../helpers'

export const DownloadCostCodes = () => {
  const { costCodeStore, companySettingStore } = useStores()

  const { costCodeListStore } = costCodeStore

  const downloadData = async () => {
    const costCodes = await costCodeListStore.fetchAllRecords()

    if (!costCodes?.length) {
      return
    }

    const costCodeFileProps = {
      costCodes: costCodes,
      settings: companySettingStore?.otherSettings?.cost_code_settings,
      codeEnabled: companySettingStore?.companyMaterialConfiguration?.all_attributes.includes('cost_code_id'),
    }

    try {
      const costCodesMapped = makeDownloadCostCodeFile(costCodeFileProps)
      const costCodeMap = XLSX.utils.json_to_sheet(costCodesMapped)

      const costCodeFile = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(costCodeFile, costCodeMap, 'Data')

      XLSX.writeFile(costCodeFile, 'CostCodes.xlsx')
    } catch (error) {
      console.log(error)
      message.error(`Unable to download files. Please contact ${SUPPORT_EMAIL_ADDRESS} for further assistance.`)
    }
  }

  return (
    <Button style={{ marginTop: 16 }} onClick={downloadData}>
      <DownloadOutlined /> Download Cost Codes
    </Button>
  )
}
