import React, { useEffect, useState } from 'react'

import { useLocation } from 'react-router-dom'

import { Tabs, message } from 'antd'

import { observer } from 'mobx-react-lite'

import { Attachments as AttachmentsComponent } from 'common/components/Attachments'
import { Card } from 'common/components/Card'
import { ErrorBoundary } from 'common/components/ErrorBoundary/error_boundary'
import { OrderHistory } from 'common/components/OrderHistory'
import { noticeError } from 'common/helpers/new_relic'
import { SUPPORT_EMAIL_ADDRESS } from 'common/helpers/other'

import { useStores } from 'contractor/hooks/use-stores'

type AttachmentsProps = {
  disabled?: boolean
}

export const Attachments = observer(({ disabled }: AttachmentsProps) => {
  const { orderStore, uploaderStore, companySettingStore, userStore } = useStores()

  const location = useLocation()

  const [loading, setLoading] = useState(false)
  const [activeKey, setActiveKey] = useState('attachments')

  const orderPackageIdParam = new URLSearchParams(location.search).get('order_package_id')

  useEffect(() => {
    uploaderStore.resetAllUploads()
  }, [])

  const handleLoadHistory = async () => {
    try {
      setLoading(true)
      await orderStore.getOrderHistory(orderStore.selectedOrder?.id)
    } catch (error) {
      noticeError(error, { entry: 'contractor-draft-history' })
      message.error(`Unable to load history. Please contact ${SUPPORT_EMAIL_ADDRESS} for further assistance.`)
    } finally {
      setLoading(false)
    }
  }

  const attachmentComponent = (
    <AttachmentsComponent
      data-cy="uploader"
      disabled={orderStore.isSplitting || disabled}
      multiple={true}
      uploadKey="purchaser_files"
      noResetUploads
      fileList={uploaderStore.fileList('purchaser_files')}
      onRemoveUpload={uploaderStore.removeUpload}
      onAddNewUpload={uploaderStore.addNewUpload}
      onResetUploads={uploaderStore.resetUploads}
      onSetUploadError={uploaderStore.setUploadError}
      onUpdateUpload={uploaderStore.updateUpload}
    />
  )

  if (!orderPackageIdParam) {
    return <Card title="Attachments">{attachmentComponent}</Card>
  }

  return (
    <Card contentProps={{ p: '0 16px 16px 16px' }}>
      <Tabs
        activeKey={activeKey}
        onChange={(key) => {
          setActiveKey(key)
          if (key === 'history') {
            handleLoadHistory()
          }
        }}
        items={[
          {
            label: 'Attachments',
            key: 'attachments',
            children: attachmentComponent,
          },
          {
            label: 'History',
            key: 'history',
            children: (
              <ErrorBoundary isFull={false}>
                <OrderHistory
                  isLoading={loading}
                  data={orderStore.orderHistory}
                  companyAttributes={companySettingStore.companyMaterialConfiguration.company_attributes}
                  canUseIntegrations={userStore.canUseIntegrations}
                />
              </ErrorBoundary>
            ),
          },
        ]}
      />
    </Card>
  )
})
