import React, { useRef, useState } from 'react'

import { Dropdown, Tooltip, Typography } from 'antd'

import { observer } from 'mobx-react-lite'

import { Box } from 'common/components/boxes'
import { DrawerRef } from 'common/components/Drawer'
import Tags from 'common/components/tags'
import { Visibility } from 'common/components/Visibility'
import { useTheme } from 'common/hooks/use-theme'

import { SelectUser } from 'contractor/components/SelectUser'
import { WatchersDrawer } from 'contractor/components/WatchersDrawer'
import { makeWatcherOption } from 'contractor/components/WatchersSelect'
import { useStores } from 'contractor/hooks/use-stores'
import { ActionStatus } from 'contractor/pages/Invoices/Detail/action_status'

import { useInvoice } from '../context'
import { InternalComments } from '../internal_comments'
import { insertIf } from '../invoice_detail_extract'
import { InvoiceHistory } from '../invoice_history'
import { HeaderProps } from './header'
import { SourceFrom } from './source_from'
import { UpdateButton } from './update_button'

type RightActionsProps = Pick<
  HeaderProps,
  | 'isCorrelating'
  | 'onDeleteInvoice'
  | 'onClickReportIssue'
  | 'toggleRejectionReasonModal'
  | 'disableUpdate'
  | 'disabled'
  | 'isSubmitting'
  | 'saveAndNextDefault'
  | 'onSaveInvoice'
  | 'onSaveInvoiceAndNext'
  | 'assignToId'
  | 'assignToId'
  | 'onChangeAssignToId'
  | 'stateId'
  | 'onChangeStateId'
  | 'attachedOrderIds'
>

export const RightActions = observer<RightActionsProps>((props) => {
  const {
    isCorrelating,
    onDeleteInvoice,
    onClickReportIssue,
    toggleRejectionReasonModal,
    disableUpdate,
    disabled,
    isSubmitting,
    saveAndNextDefault,
    onSaveInvoice,
    onSaveInvoiceAndNext,

    assignToId,
    onChangeAssignToId,

    stateId,
    onChangeStateId,
  } = props

  const theme = useTheme()
  const { invoiceStore, userStore, companySettingStore } = useStores()

  const { setSelectedInvoiceDirty } = useInvoice()

  const historyDrawerRef = useRef<DrawerRef>()
  const [isHistoryDrawerVisible, setIsHistoryDrawerVisible] = useState(false)

  const { invoice } = invoiceStore
  const canViewSource =
    userStore.canViewAllInvoices || userStore.canViewInvoiceInbox || userStore.canViewAllMyProjectInvoices
  const hasPermissions = userStore.canEditAndDeleteInvoices || canViewSource

  const handleHistoryClick = () => {
    setIsHistoryDrawerVisible(true)
    historyDrawerRef.current?.show()
  }

  return (
    <>
      <InvoiceHistory
        ref={historyDrawerRef}
        onClose={() => {
          historyDrawerRef.current?.close()
          setIsHistoryDrawerVisible(false)
        }}
        isVisible={isHistoryDrawerVisible}
      />

      <Box display="flex" alignItems="flex-start" style={{ gap: 8 }}>
        {!isCorrelating && (
          <>
            <Visibility.Hidden breakpoint="lg">
              <Box minWidth={150}>
                <Tags
                  disabled={disabled}
                  value={invoice.tags}
                  onChange={(tags) => {
                    invoice.tags = tags
                    setSelectedInvoiceDirty(true)
                  }}
                  tags={invoiceStore.invoiceTags}
                />
              </Box>

              <SelectUser
                onlyActives
                disabled={disabled}
                onChange={(value) => {
                  onChangeAssignToId(value)
                  setSelectedInvoiceDirty(true)
                }}
                value={assignToId}
                placeholder="Assignee"
                boxProps={{ width: 150 }}
                showAssignToMe={false}
                showAssignToMeOnDropdown
              />
            </Visibility.Hidden>

            <ActionStatus
              disabled={disabled}
              stateId={stateId}
              changeStatus={(value) => {
                onChangeStateId(value)
                setSelectedInvoiceDirty(true)
              }}
              toggleRejectionReasonModal={toggleRejectionReasonModal}
            />
          </>
        )}

        <Visibility.Hidden breakpoint="md">
          <InternalComments />

          <Tooltip title={disableUpdate ? 'To approve the invoice you must attach at least one order' : ''}>
            <UpdateButton
              saveAndNextDefault={saveAndNextDefault}
              isCorrelating={isCorrelating}
              disableUpdate={disableUpdate || disabled}
              isSubmitting={isSubmitting}
              onSaveInvoice={onSaveInvoice}
              onSaveInvoiceAndNext={onSaveInvoiceAndNext}
            />
          </Tooltip>

          {!isCorrelating && hasPermissions && (
            <Dropdown.Button
              menu={{
                items: [
                  ...insertIf(userStore.canEditAndDeleteInvoices, {
                    label: (
                      <WatchersDrawer
                        boxProps={{
                          width: 'auto',
                          p: 0,
                          height: 'auto',
                          border: 'none',
                          display: 'block',
                        }}
                        disabled={disabled}
                        value={companySettingStore.possibleUsers
                          .filter((user) => invoice?.watcher_ids?.includes(user.id))
                          .map((user) => makeWatcherOption(user))}
                        onChange={(watchers = []) => {
                          invoice.watcher_ids = watchers?.map((watcher) => watcher.value)
                          setSelectedInvoiceDirty(true)
                        }}
                      >
                        Watchers
                      </WatchersDrawer>
                    ),
                    key: '1',
                  }),
                  {
                    label: 'History',
                    key: '2',
                    onClick: handleHistoryClick,
                  },
                  ...insertIf(canViewSource, {
                    label: <SourceFrom />,
                    key: '3',
                  }),
                  {
                    label: 'Report Issue',
                    key: '4',
                    onClick: onClickReportIssue,
                  },
                  ...insertIf(userStore.canEditAndDeleteInvoices, {
                    label: (
                      <Typography style={{ color: theme.colors['red-6'] }} onClick={onDeleteInvoice}>
                        Delete Invoice
                      </Typography>
                    ),
                    key: '5',
                  }),
                ],
              }}
              trigger={['hover', 'click']}
              buttonsRender={([_, rightButton]) => [null, React.cloneElement(rightButton as React.ReactElement)]}
              style={{ width: 'auto' }}
            />
          )}
        </Visibility.Hidden>
      </Box>
    </>
  )
})
