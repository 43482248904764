import React, { useCallback } from 'react'

import { getAlgoliaResults } from '@algolia/autocomplete-js'
import { SearchOptions } from '@algolia/client-search'

import { Skeleton } from 'antd'

import { observer } from 'mobx-react-lite'

import { AlgoliaAutocomplete, debounced } from 'common/components/AlgoliaAutocomplete'
import { FlexBoxX, FlexBoxY } from 'common/components/boxes'
import { useQuery } from 'common/hooks/use-query'
import { useTheme } from 'common/hooks/use-theme'
import { OrderHit } from 'common/server/orders'

import { OrderItemSm } from 'contractor/components/OrdersAutocomplete/Algolia/order_item_sm'
import { useStores } from 'contractor/hooks/use-stores'

import { OrderItem } from './order_item'

export type OrdersAutocompleteProps = {
  onSelect: (order: OrderHit) => void
  size?: 'default' | 'small'
  offset?: [number, number]
  params?: SearchOptions
  renderItem?: (orderHit: OrderHit) => React.ReactNode
  detachedMediaQuery?: string
  footerComponent?: React.ReactNode
  portal?: boolean
}

export const OrdersAutocomplete = observer<OrdersAutocompleteProps>((props) => {
  const { onSelect, size, offset, params, renderItem, detachedMediaQuery, footerComponent, portal } = props

  const { orderStore } = useStores()

  const theme = useTheme()

  const { isLoading } = useQuery(orderStore.maybeLoadSearchKey)

  const itemSize = size || 'default'

  const getSources = useCallback(
    ({ query, setIsOpen }) => {
      return [
        {
          sourceId: 'orders',
          getItems() {
            const queries = [
              {
                indexName: orderStore.searchKey.index_name,
                query,
                params: {
                  hitsPerPage: 10,
                  responseFields: ['*'],
                  facets: ['*'],
                  maxValuesPerFacet: 100,
                  ...params,
                },
              },
            ]

            return getAlgoliaResults({
              searchClient: orderStore.searchClient,
              queries,
            })
          },
          onSelect: ({ item }) => onSelect(item),
          templates: {
            item({ components, item }) {
              if (renderItem) {
                return renderItem(item)
              }

              return itemSize === 'default' ? (
                <OrderItem item={item} />
              ) : (
                <OrderItemSm item={item} components={components} />
              )
            },
            footer() {
              return footerComponent ? React.cloneElement(footerComponent as React.ReactElement, { setIsOpen }) : null
            },
            noResults({ state }) {
              return (
                <FlexBoxX justifyContent="space-between" style={{ minHeight: '30px' }}>
                  <FlexBoxY>No results for &quot;{state.query}&quot;</FlexBoxY>
                </FlexBoxX>
              )
            },
          },
        },
      ]
    },
    [orderStore.searchKey.index_name, orderStore.searchClient, params],
  )

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const debouncedGetSources = useCallback((params) => debounced(getSources(params)) as any, [getSources])

  if (isLoading) {
    return <Skeleton.Input block active />
  }

  if (!orderStore.searchKey.application_id) {
    return null
  }

  return (
    <AlgoliaAutocomplete<OrderHit>
      defaultActiveItemId={0}
      detachedMediaQuery={detachedMediaQuery || `(max-width: ${theme.breakpoints.md})`}
      placeholder="Search Orders..."
      data-cy="orders-autocomplete"
      portal={portal}
      offset={offset}
      getSources={debouncedGetSources}
    />
  )
})
