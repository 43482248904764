import axios from 'axios'

import { Address } from 'common/server/addresses'

import { Vendor } from 'contractor/server/vendors'
export interface IndexCompanyVendorsResponse {
  company_vendors: IndexCompanyVendor[]
}

export interface CompanyVendorContact {
  id: string
  full_name: string
  email: string
  active: boolean
  phone_number?: string
  nickname?: string
  vendor_user?: { id: string; full_name: string; email: string; headline?: string }
}

export interface IndexCompanyVendor {
  id: string
  vendor_name: string
  vendor?: Vendor
  domain?: string
  company_vendor_contacts?: CompanyVendorContact[]
  external_vendor_id?: string
  addresses?: Address[]
}

export interface VendorContact {
  id?: string
  first_name: string
  last_name: string
  email: string
  phone_number: string
}

export interface CreateVendorRequest {
  vendor_name: string
  vendor_contacts: VendorContact[]
}

export interface CreateVendorResponse {
  id: string
  contact_ids: string[]
}

export function index(isActive?: boolean) {
  const params = {
    ...(isActive !== undefined && { is_active: isActive }),
  }
  return axios.get<IndexCompanyVendorsResponse>('/company_vendors', {
    params,
  })
}

export interface UpdateVendorRequest {
  id: string
  vendor_name: string
  external_vendor_id?: string
}

export function update(payload: UpdateVendorRequest) {
  return axios.patch(`/company_vendor/${payload.id}`, payload)
}

export function show(id: string) {
  return axios.get<{ company_vendor: IndexCompanyVendor }>(`/company_vendor/${id}`)
}
