import React from 'react'

import { useHistory, Link } from 'react-router-dom'

import { LocalShipping, RequestQuote } from '@styled-icons/material'
import _ from 'lodash'

import BuildOutlined from '@ant-design/icons/BuildOutlined'
import EyeInvisibleOutlined from '@ant-design/icons/EyeInvisibleOutlined'
import EyeTwoTone from '@ant-design/icons/EyeTwoTone'
import SettingOutlined from '@ant-design/icons/SettingOutlined'
import ShoppingOutlined from '@ant-design/icons/ShoppingOutlined'
import { Button, message, Card, Tooltip, Space } from 'antd'
import { TooltipProps } from 'antd/lib/tooltip'

import { observer } from 'mobx-react-lite'

import { FlexBoxX, Box } from 'common/components/boxes'
import { OrderStates } from 'common/server/server_types'

import { useStores } from 'contractor/hooks/use-stores'
import { IndexProject } from 'contractor/server/projects'

type ItemCountProps = {
  id: string
  states: OrderStates[]
  helper?: string
  icon: React.ReactNode
}

export const ItemCount = observer<ItemCountProps>(({ id, states, helper, icon }) => {
  const { dashboardStore } = useStores()

  let count = 0
  _.each(states, (state) => {
    count += _.get(dashboardStore.orderCountsByProject, `${id}.${state}`, 0)
  })

  return (
    <Tooltip title={helper}>
      <FlexBoxX width="75px" border="1px solid" borderColor="gray-2" height="35px" p={10} style={{ opacity: 0.6 }}>
        {icon}
        {count}
      </FlexBoxX>
    </Tooltip>
  )
})

type ActionsProps = IndexProject & Pick<TooltipProps, 'placement'>

export const Actions = observer<ActionsProps>(({ id, name, default_watcher_user_ids, project_id, placement }) => {
  const { userStore, projectStore } = useStores()

  const history = useHistory()

  const userId = userStore.currentUser?.id

  if (!userId) {
    return null
  }

  const isWatching = default_watcher_user_ids?.includes(userId)

  const goToEditProject = () => history.push(`/project/${id}`)

  if (!userStore.canReadProjects) return null

  return (
    <Space>
      {!!project_id && (
        <Tooltip title="Project Identifier">
          <Box
            data-cy={`project-id-badge-${name.toLowerCase()}`}
            fontWeight="200"
            border="1px solid"
            borderColor="gray-5"
            px={4}
            borderRadius="sm"
          >
            {project_id}
          </Box>
        </Tooltip>
      )}

      {isWatching ? (
        <Tooltip title="Stop watching project" placement={placement}>
          <Button
            icon={<EyeTwoTone />}
            size="small"
            data-cy="stop-watching-project"
            onClick={async (e) => {
              e.preventDefault()
              e.stopPropagation()
              try {
                await projectStore.updateWatchers(id, _.without(default_watcher_user_ids, userId))
                // Once we had a successful request manually pull from the array (easier than updating the entire array)
                _.pull(default_watcher_user_ids, userId)
                message.success(`Stopped watching ${name}`)
              } catch (err) {
                message.error('Unable to watch project')
                console.error('Unable to watch project', err?.response)
              }
            }}
          />
        </Tooltip>
      ) : (
        <Tooltip title="Watch project" placement={placement}>
          <Button
            icon={<EyeInvisibleOutlined />}
            size="small"
            onClick={async (e) => {
              e.preventDefault()
              e.stopPropagation()
              try {
                await projectStore.updateWatchers(id, [...default_watcher_user_ids, userId])
                // Once we had a successful request manually add to the array (easier than updating the entire array)
                default_watcher_user_ids.push(userId)
                message.success(`Now watching ${name}`)
              } catch (err) {
                message.error('Unable to watch project')
                console.error('Unable to watch project', err?.response)
              }
            }}
          />
        </Tooltip>
      )}

      <Tooltip title="Edit project settings" placement={placement}>
        <Button
          data-cy={`edit-project-${name.toLowerCase()}`}
          icon={<SettingOutlined />}
          onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()
            goToEditProject()
          }}
          size="small"
        />
      </Tooltip>
    </Space>
  )
})

const ProjectCard = (props: IndexProject) => {
  const { id, name } = props
  const { projectStore } = useStores()

  return (
    <Link to={`/orders?project_id=${id}`} onClick={() => projectStore.showProject(id)}>
      <Card
        id={id}
        title={name}
        aria-label={`project-card-${name.toLowerCase()}`}
        hoverable
        extra={<Actions {...props} />}
        bodyStyle={{ padding: '0' }}
        data-cy={`project-card-${name.toLowerCase()}`}
      >
        <FlexBoxX>
          <ItemCount
            id={id}
            helper="Quoted"
            states={[OrderStates.QUOTED]}
            icon={<RequestQuote size="20" style={{ marginRight: '10px' }} />}
          />
          <ItemCount
            id={id}
            helper="Ordered"
            states={[OrderStates.ORDERED]}
            icon={<ShoppingOutlined style={{ marginRight: '10px' }} />}
          />
          <ItemCount
            id={id}
            helper="Shipped & Partially Shipped"
            states={[OrderStates.SHIPPED, OrderStates.PARTIALLY_SHIPPED]}
            icon={<LocalShipping size="20" style={{ marginRight: '10px' }} />}
          />
          <ItemCount
            id={id}
            helper="Delivered"
            states={[OrderStates.DELIVERED]}
            icon={<BuildOutlined style={{ marginRight: '10px' }} />}
          />
        </FlexBoxX>
      </Card>
    </Link>
  )
}

export default ProjectCard
