import React from 'react'

import { Button, Typography, Space, Switch } from 'antd'

import { observer } from 'mobx-react-lite'

import { FlexBoxX, FlexBoxY, Box } from 'common/components/boxes'
import { SearchInput } from 'common/components/SearchInput'
import { Visibility } from 'common/components/Visibility'
import { usePersistentFilters } from 'common/contexts/persistent_filters'

import { Flatfile } from 'contractor/components/Flatfile'
import { useStores } from 'contractor/hooks/use-stores'

import { useCostCode } from '../context'
import { BulkEditSave } from './bulk_edit_save'
import { Filters } from './filters'

type HeaderProps = {
  onAdd: () => void
}

type ExtraActionspProps = HeaderProps

const ExtraActions = observer<ExtraActionspProps>(({ onAdd }) => {
  const { costCodeStore, flatfileStore } = useStores()

  const { costCodeListStore } = costCodeStore

  const { startBulkEditMode } = useCostCode()

  return (
    <Space size="middle">
      <Visibility.Hidden breakpoint="md">
        <Space size="middle">
          <Button type="primary" onClick={() => startBulkEditMode()} disabled={!costCodeListStore.records.length}>
            Bulk Edit
          </Button>

          <Flatfile onSuccess={() => costCodeListStore.fetchRecords()} openUpload={flatfileStore.openUploadCostCodes} />
        </Space>
      </Visibility.Hidden>

      <Button type="primary" onClick={onAdd}>
        Add
      </Button>
    </Space>
  )
})

export const Header = observer<HeaderProps>(({ onAdd }) => {
  const { costCodeStore } = useStores()
  const persistentFilters = usePersistentFilters()

  const { isBulkEditMode, endBulkEditMode } = useCostCode()

  const { costCodeListStore } = costCodeStore

  return (
    <FlexBoxY>
      <FlexBoxX justifyContent="space-between" width="100%" mb={{ _: 12, xs: 24 }} alignItems="flex-start">
        <Typography.Title level={3}>Cost Codes</Typography.Title>

        <FlexBoxX flexGrow={0}>
          {isBulkEditMode ? (
            <Space size="middle">
              <Button type="primary" onClick={() => endBulkEditMode()}>
                Cancel
              </Button>
              <BulkEditSave />
            </Space>
          ) : (
            <ExtraActions onAdd={onAdd} />
          )}
        </FlexBoxX>
      </FlexBoxX>

      {!isBulkEditMode && (
        <FlexBoxX width="100%" flexDirection={{ _: 'column', xs: 'row' }}>
          <Box mr={{ _: 0, xs: 16 }} mb={{ _: 12, xs: 0 }} width="100%">
            <SearchInput
              value={costCodeListStore.searchState.search}
              onSearch={(search: string) => persistentFilters.handleChangeFilters({ search })}
              style={{ width: '100%' }}
            />
          </Box>

          <Space>
            <FlexBoxX
              width={{ _: '100%', xs: 'auto' }}
              border="1px solid"
              borderColor="gray-2"
              borderRadius="md"
              py="6px"
              px={12}
              flexGrow={0}
            >
              <Typography.Text style={{ whiteSpace: 'nowrap', marginRight: 12 }} strong>
                Actives only
              </Typography.Text>
              <Switch
                checked={costCodeListStore.searchState.filters['active']}
                onChange={(active) => persistentFilters.handleChangeFilters({ active })}
              />
            </FlexBoxX>

            <Filters />
          </Space>
        </FlexBoxX>
      )}
    </FlexBoxY>
  )
})
