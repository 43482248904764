import React, { useState, useEffect } from 'react'

import { Button, message, Typography, Form, notification } from 'antd'

import { observer } from 'mobx-react-lite'

import { Box } from 'common/components/boxes'
import { Page } from 'common/components/Page'
import { InvoicesStates } from 'common/server/server_types'

import { useFlag } from 'contractor/hooks/use-flag'
import { useStores } from 'contractor/hooks/use-stores'
import { OtherSettings } from 'contractor/pages/CompanySettings/Invoices/OtherSettings/other_settings'

import { withInvoiceSettingsProvider, useInvoiceSettings } from './context'
import { ProjectAssignees } from './project_assignees'
import { Workflow } from './workflow'

const InvoicesSettings = observer(() => {
  const { invoiceStateStore, projectInvoiceAssigneeStore, invoiceSettingsStore } = useStores()

  const { form } = useInvoiceSettings()
  const [invoiceOtherSettings, setInvoiceOtherSettings] = useState(null)

  const [isSubmitting, setSubmitting] = useState(false)
  const [projectAssigneeDirty, setProjectAssigneeDirtyg] = useState(false)

  const usingConsolidatedInvoices = useFlag('consolidated_invoices')

  useEffect(() => {
    invoiceSettingsStore.indexSettings().then((response) => {
      setInvoiceOtherSettings(response)
    })
  }, [])

  const handleSave = async (formValues) => {
    try {
      setSubmitting(true)

      const allInvoiceStates = formValues?.invoiceStates || []

      const projectInvoiceAssigneeIds = projectInvoiceAssigneeStore.projectInvoiceAssignees?.map(
        (projectInvoiceAssignee) => projectInvoiceAssignee.id,
      )

      const invoiceStates = allInvoiceStates?.map((setting) => {
        if (setting?.assigned_to_id === 'not_change_assignee') {
          return {
            ...setting,
            assigned_to_id: null,
            not_change_assignee: true,
            project_invoice_assignee_id: null,
          }
        }

        if (projectInvoiceAssigneeIds.includes(setting?.assigned_to_id)) {
          return {
            ...setting,
            assigned_to_id: null,
            not_change_assignee: false,
            project_invoice_assignee_id: setting?.assigned_to_id,
          }
        }

        return {
          ...setting,
          not_change_assignee: false,
          project_invoice_assignee_id: null,
        }
      })

      const initialState = invoiceStates.find((state) => state?.state === InvoicesStates.UNREVIEWED)
      const finalState = invoiceStates.find((state) => state?.state === InvoicesStates.POSTED)
      const middleStates = invoiceStates
        .filter((state) => state?.state !== InvoicesStates.UNREVIEWED && state?.state !== InvoicesStates.POSTED)
        .map((state, index) => ({ ...state, position: index + 1 }))

      await Promise.all([
        invoiceStateStore.upsert([initialState, ...middleStates, finalState]),
        projectInvoiceAssigneeStore.upsert(formValues?.projectAssignees),
        invoiceSettingsStore.update(invoiceOtherSettings),
      ])

      setProjectAssigneeDirtyg(false)

      if (usingConsolidatedInvoices) {
        message.success('Successfully changed settings')

        return
      }

      notification.success({
        message: 'Successfully changed settings',
        description: 'Please allow a few seconds for your changes to appear on the invoices page.',
        placement: 'bottomLeft',
        duration: 8,
      })
    } catch (error) {
      if (error?.response?.data?.error) {
        message.error(<span dangerouslySetInnerHTML={{ __html: error.response.data.error }} />, 10)
      } else {
        message.error('Unable to save changes')
      }
    } finally {
      setSubmitting(false)
    }
  }

  return (
    <Page>
      <Form
        form={form}
        onFinish={handleSave}
        autoComplete="off"
        layout="vertical"
        style={{ width: '100%', flexGrow: 1, display: 'flex', flexDirection: 'column' }}
        onValuesChange={(changes) => {
          if (Object.prototype.hasOwnProperty.call(changes, 'projectAssignees')) {
            setProjectAssigneeDirtyg(true)
          }
        }}
      >
        <Page.Header>
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Box display="flex" flexDirection="column">
              <Typography.Title level={4} style={{ margin: 0 }}>
                Invoices
              </Typography.Title>
            </Box>

            <Button type="primary" htmlType="submit" loading={isSubmitting}>
              Save Changes
            </Button>
          </Box>
        </Page.Header>

        <Page.Content>
          <ProjectAssignees projectAssigneeDirty={projectAssigneeDirty} />

          <Workflow />

          <OtherSettings
            onChange={(changes) => setInvoiceOtherSettings(changes)}
            otherSettings={invoiceOtherSettings}
          />
        </Page.Content>
      </Form>
    </Page>
  )
})

export const Invoices = withInvoiceSettingsProvider(InvoicesSettings)
