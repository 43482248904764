import React from 'react'

import { Configure, InstantSearch, SearchBox } from 'react-instantsearch-dom'

import styled from '@emotion/styled'

import { observer } from 'mobx-react-lite'

import { Box, FlexBoxX, FlexBoxY } from 'common/components/boxes'
import { Page } from 'common/components/Page'
import { Visibility } from 'common/components/Visibility'
import { useAlgoliaSyncUrl } from 'common/hooks/use-algolia-sync-url'
import { OrderStatesFilter, OrderSubStates } from 'common/server/server_types'

import { AlgoliaTagsFilter } from 'contractor/components/AlgoliaTagsFilter'
import ProjectSelector from 'contractor/components/ProjectSelector'
import { useStores } from 'contractor/hooks/use-stores'
import { Filters } from 'contractor/pages/Materials/Filters/filters'
import MaterialTable from 'contractor/pages/Materials/table'

import { withOrderMaterialsProvider } from './context'

const CustomSearchBox = styled(SearchBox)`
  input {
    height: 32px;
  }
`

const OrderMaterialsWrapper = observer(() => {
  const { projectStore, orderMaterialStore, userStore } = useStores()

  const algoliaSyncUrl = useAlgoliaSyncUrl(orderMaterialStore)

  const projectId = projectStore.selectedProject?.id
  let filters = 'project_active:true AND exclude_from_search:false'
  if (projectId) {
    filters += ` AND project_id:${projectId}`
  }

  return (
    <InstantSearch
      searchClient={orderMaterialStore.searchClient}
      indexName={orderMaterialStore.searchKey.index_name}
      refresh={orderMaterialStore.refreshHits}
      {...algoliaSyncUrl}
    >
      <Configure filters={filters} hitsPerPage={50} distinct facetingAfterDistinct />
      <Page>
        <Page.Header>
          <FlexBoxY>
            <Visibility.Show breakpoint="md">
              <ProjectSelector showSettings showAddProject />
              <Box mt="8" display="flex" gridGap={8} alignItems="center" justifyContent="space-between" width="100%">
                <Box flex="1">
                  <CustomSearchBox searchAsYouType={false} showLoadingIndicator width="100%" />
                </Box>
                <Filters filters={filters} />
              </Box>
            </Visibility.Show>

            <Visibility.Hidden breakpoint="md">
              <Box mt="8" display="flex" gridGap={8} alignItems="center" justifyContent="space-between" width="100%">
                <ProjectSelector showSettings showAddProject />
                <Box flex="1">
                  <CustomSearchBox searchAsYouType={false} showLoadingIndicator width="100%" />
                </Box>
                <Filters filters={filters} />
              </Box>
            </Visibility.Hidden>

            <FlexBoxX mt={16} justifyContent="space-between" flexGrow={0} width="100%" alignItems="flex-start">
              <AlgoliaTagsFilter
                onChange={(filter) => (orderMaterialStore.selectedRibbonFilter = filter)}
                attribute="contractor_material_filter"
                options={[
                  { label: 'All', filter: '' },
                  { label: 'Requested', filter: OrderStatesFilter.REQUESTED },
                  { label: 'Drafted', filter: OrderStatesFilter.DRAFT },
                  ...(userStore.canUseCreateRFQ
                    ? [{ label: 'Quote Requested', filter: OrderSubStates.QUOTED_REQUESTED }]
                    : []),
                  ...(userStore.canUseCreateRFQ
                    ? [{ label: 'Quote Provided', filter: OrderSubStates.QUOTED_RECEIVED }]
                    : []),
                  { label: 'Order Placed', filter: OrderSubStates.ORDERED_REQUESTED },
                  { label: 'Order Confirmed', filter: OrderSubStates.ORDERED_CONFIRMED },
                  {
                    label: 'Shipped',
                    filter: OrderStatesFilter.SHIPPED,
                  },
                  { label: 'Delivered', filter: OrderStatesFilter.DELIVERED },
                  { label: 'Cancelled', filter: OrderStatesFilter.CANCELLED },
                ]}
              />
            </FlexBoxX>
          </FlexBoxY>
        </Page.Header>

        <Page.Content p={0} px={{ _: 0, md: 16 }} py={16} height="inherit">
          <MaterialTable />
        </Page.Content>
      </Page>
    </InstantSearch>
  )
})

export default withOrderMaterialsProvider(OrderMaterialsWrapper)
