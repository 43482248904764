import React, { useState } from 'react'

import styled from '@emotion/styled'

import { Switch, Table, Tooltip } from 'antd'
import { ColumnsType } from 'antd/lib/table'

import { UserRoles } from 'common/server/server_types'

import { InvoicePermissions } from 'contractor/server/company_settings/permissions'

const TableStyled = styled(Table)`
  width: 100%;

  tr.disabled-table-row:hover > td,
  .locked-table-row {
    background-color: ${({ theme }) => theme.colors['gray-3']};
    cursor: not-allowed;
  }
`

type invoiceTableProps = {
  setInvoicePermissions: (data: InvoicePermissions) => void
  invoicesPermissions: InvoicePermissions
}

const InvoicesTable = ({ setInvoicePermissions, invoicesPermissions }: invoiceTableProps) => {
  const [data, setData] = useState([
    {
      id: 'i1', // ID used for antd table to set the row key
      permission: 'Can view all invoices',
      tooltip: 'Can see all invoices for projects in the group regardless of state or assignment',
      admin: invoicesPermissions?.ADMIN?.can_view_all_invoices,
      purchaser: invoicesPermissions?.PURCHASER?.can_view_all_invoices,
      user: invoicesPermissions?.USER?.can_view_all_invoices,
      material_requester: invoicesPermissions?.MATERIAL_REQUESTER?.can_view_all_invoices,
    },
    {
      id: 'i2',
      permission: 'Can view invoice inbox',
      tooltip: 'Can see inbox with emailed and uploaded invoices',
      admin: invoicesPermissions?.ADMIN?.can_view_invoice_inbox,
      purchaser: invoicesPermissions?.PURCHASER?.can_view_invoice_inbox,
      user: invoicesPermissions?.USER?.can_view_invoice_inbox,
      material_requester: invoicesPermissions?.MATERIAL_REQUESTER?.can_view_invoice_inbox,
    },
    {
      id: 'i3',
      permission: 'Can create Quick PO',
      tooltip:
        'Can create a PO during the invoice reconciliation process intended for record keeping purposes. ' +
        'This type of PO will NOT notify the vendor of the order and is assumed to be already delivered',
      admin: invoicesPermissions?.ADMIN?.can_create_quick_po,
      purchaser: invoicesPermissions?.PURCHASER?.can_create_quick_po,
      user: invoicesPermissions?.USER?.can_create_quick_po,
      material_requester: invoicesPermissions?.MATERIAL_REQUESTER?.can_create_quick_po,
    },
    {
      id: 'i4',
      permission: 'Can edit and delete invoices',
      tooltip: 'Can edit all invoices and delete an invoice, once deleted, there is no way to undo this action',
      admin: invoicesPermissions?.ADMIN?.can_edit_and_delete_invoices,
      purchaser: invoicesPermissions?.PURCHASER?.can_edit_and_delete_invoices,
      user: invoicesPermissions?.USER?.can_edit_and_delete_invoices,
      material_requester: invoicesPermissions?.MATERIAL_REQUESTER?.can_edit_and_delete_invoices,
    },
    {
      id: 'i5',
      permission: 'Can view all my project invoices',
      tooltip:
        'Users are always able to see invoices they are assigned to, this allows them to view' +
        'invoices that have been or are being reviewed for projects they have access to. ',
      admin: invoicesPermissions?.ADMIN?.can_view_all_my_project_invoices,
      purchaser: invoicesPermissions?.PURCHASER?.can_view_all_my_project_invoices,
      user: invoicesPermissions?.USER?.can_view_all_my_project_invoices,
      material_requester: invoicesPermissions?.MATERIAL_REQUESTER?.can_view_all_my_project_invoices,
    },
  ])

  const handleSwitchChange = (permission: string, role: string) => {
    const newData = [...data]
    newData.map((item) => {
      if (item.permission === permission) {
        item[role.toLowerCase()] = !item[role.toLowerCase()]
      }
      return item
    })

    const updatedInvoicePermissions = {
      ADMIN: {},
      PURCHASER: {},
      USER: {},
      MATERIAL_REQUESTER: {},
    } as InvoicePermissions

    for (const key in updatedInvoicePermissions) {
      if (Object.prototype.hasOwnProperty.call(updatedInvoicePermissions, key)) {
        const rolePermissions = updatedInvoicePermissions[key]
        for (const dataItem of newData) {
          const permission = dataItem.permission.toLowerCase().replace(/\s+/g, '_')
          rolePermissions[permission] = dataItem[key.toLowerCase()] || false
        }
      }
    }

    setData(newData)
    setInvoicePermissions(updatedInvoicePermissions)
  }

  const columns: ColumnsType<{
    permission: string
    tooltip: string
    admin: boolean
    purchaser: boolean
    user: boolean
    material_requester: boolean
  }> = [
    {
      title: 'Invoices Permissions',
      dataIndex: 'permission',
      width: 200,
      render: (permission, record) => (
        <Tooltip title={record.tooltip}>
          <span>{permission}</span>
        </Tooltip>
      ),
    },
    {
      title: 'Admin',
      dataIndex: 'admin',
      key: 'invoice_2',
      width: 150,
      render: (enabled, record) => (
        <Switch checked={record.admin} onChange={() => handleSwitchChange(record.permission, UserRoles.ADMIN)} />
      ),
    },
    {
      title: 'Purchaser',
      dataIndex: 'purchaser',
      width: 150,
      render: (enabled, record) => (
        <Switch
          checked={record.purchaser}
          onChange={() => handleSwitchChange(record.permission, UserRoles.PURCHASER)}
        />
      ),
    },
    {
      title: 'User',
      dataIndex: 'user',
      width: 150,
      render: (enabled, record) => (
        <Switch checked={record.user} onChange={() => handleSwitchChange(record.permission, UserRoles.USER)} />
      ),
    },
    {
      title: 'Material Requester',
      dataIndex: 'material_requester',
      width: 150,
      render: (enabled, record) => (
        <Switch
          checked={record.material_requester}
          onChange={() => handleSwitchChange(record.permission, UserRoles.MATERIAL_REQUESTER)}
        />
      ),
    },
  ]

  if (!invoicesPermissions) {
    return null
  }

  return <TableStyled rowKey="id" dataSource={data} columns={columns} pagination={false} />
}

export default InvoicesTable
