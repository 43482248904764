import React, { useState } from 'react'

import { useParams } from 'react-router-dom'

import { groupBy } from 'lodash'
import { get } from 'lodash'
import moment from 'moment'
import * as XLSX from 'xlsx'

import DownOutlined from '@ant-design/icons/DownOutlined'
import { Space, Button, Dropdown, message } from 'antd'

import { observer } from 'mobx-react-lite'

import { useNotifyOrder } from 'common/hooks/use-notify-order'
import { OrderStates } from 'common/server/server_types'

import { ModalConfirmPurchase } from 'contractor/components/ModalConfirmPurchase'
import { useFlag } from 'contractor/hooks/use-flag'
import { useStores } from 'contractor/hooks/use-stores'
import { SYNC_ORDER_WITH_INTEGRATION_LOCAL_KEY } from 'contractor/pages/NewQuote/context'

import { useLeveling } from '../context'
import { HeaderProps } from './header'

type ExtraLevelingProps = HeaderProps

// Reference: https://github.com/SheetJS/sheetjs/issues/352
const ec = (r, c) => {
  return XLSX.utils.encode_cell({ r: r, c: c })
}
const deleteRow = (ws, row_index) => {
  const range = XLSX.utils.decode_range(ws['!ref'])

  for (let R = row_index; R < range.e.r; ++R) {
    for (let C = range.s.c; C <= range.e.c; ++C) {
      ws[ec(R, C)] = ws[ec(R + 1, C)]
    }
  }
  range.e.r--
  ws['!ref'] = XLSX.utils.encode_range(range.s, range.e)
}

export const ExtraLeveling = observer<ExtraLevelingProps>(({ goBack }) => {
  const { orderPackageStore, orderStore, uploaderStore, integrationStore, userStore } = useStores()

  const { notifyOrder } = useNotifyOrder({ operation: 'update' })

  const params = useParams()

  const {
    handleCancelSplitting,
    handleStartSplitting,
    handleSetPlaceOrder,
    getOriginalAvgSubTotal,
    getOriginalAvgGrandTotal,
    getSubtotalAvgAndMinMaxSavedAmount,
    getGrandTotalSavedAmount,
    getGrandTotalMinMaxSavedAmount,
    ordersSession,
  } = useLeveling()
  const rfqLevelingComboEnabled = useFlag('rfq_leveling_combo')

  const [purchaseOrderModalVisible, setPurchaseOrderModalVisible] = useState(false)
  const [syncOrderWithIntegration, setSyncOrderWithIntegration] = useState(
    localStorage.getItem(SYNC_ORDER_WITH_INTEGRATION_LOCAL_KEY) === 'true',
  )

  const [isSubmitting, setSubmitting] = useState(false)

  const { orderPackage } = orderPackageStore

  const ordersStatus = orderPackage?.orders?.map((order) => order.state) || []

  const originalAvgSubTotal = getOriginalAvgSubTotal()
  const originalAvgGrandTotal = getOriginalAvgGrandTotal()

  const orderedGrandTotal = Number(orderStore.selectedOrder?.grand_total)

  const costSavingValue = originalAvgGrandTotal - orderedGrandTotal

  const ordersLocked = ordersSession.isBlocked

  const handlePlaceOrder = async () => {
    setSubmitting(true)
    setPurchaseOrderModalVisible(false)

    try {
      const order = orderStore.selectedOrder
      const orderId = order.id

      const subtotalAvgAndMinMaxSavedAmount = getSubtotalAvgAndMinMaxSavedAmount()
      const gradTotalSavedAmount = getGrandTotalSavedAmount()
      const gradTotalMinMaxSavedAmount = getGrandTotalMinMaxSavedAmount()

      await orderStore.updateOrder(
        {
          state_changes: { cancelled_at: null, ordered_at: moment().toISOString() },
          purchaser_files_signed_ids: [],
          purchaser_files_delete_ids: [],
          quote_signed_id: get(uploaderStore.signedIds('quote'), '[0]'),
        },
        userStore.canCreateNewMaterial,
      )
      orderStore.createOrderSaving({
        order_package_id: params['id'],
        original_avg_grand_total: originalAvgGrandTotal.toFixed(3),
        original_avg_sub_total: originalAvgSubTotal.toFixed(3),
        ordered_grand_total: order?.grand_total,
        ordered_sub_total: order?.sub_total,
        num_quotes_requested: orderPackage.orders.length,
        num_orders_placed: 1,
        order_breakdown: orderPackage.orders.map((order) => ({
          order_id: order.id,
          original_grand_total: order.grand_total,
          original_sub_total: order.sub_total,
        })),

        sub_total_avg_savings: subtotalAvgAndMinMaxSavedAmount.subtotalSavedAmount.toFixed(3),
        grand_total_avg_savings: gradTotalSavedAmount.toFixed(3),
        sub_total_total_min_max_savings: subtotalAvgAndMinMaxSavedAmount.subtotalMinMaxSavedAmount.toFixed(3),
        grand_total_min_max_savings: gradTotalMinMaxSavedAmount.toFixed(3),
        avg_material_savings_breakdown: subtotalAvgAndMinMaxSavedAmount.avgMaterialSavingsBreakdown.map(
          (avgMaterialSavingBreakdown) => ({
            company_material_id: avgMaterialSavingBreakdown.companyMaterialId,
            qty: avgMaterialSavingBreakdown.quantity,
            savings: avgMaterialSavingBreakdown.savings.toFixed(3),
          }),
        ),
        min_max_material_savings_breakdown: subtotalAvgAndMinMaxSavedAmount.minMaxMaterialSavingsBreakdown.map(
          (minMaxMaterialSavingBreakdown) => ({
            company_material_id: minMaxMaterialSavingBreakdown.companyMaterialId,
            qty: minMaxMaterialSavingBreakdown.quantity,
            savings: minMaxMaterialSavingBreakdown.savings.toFixed(3),
          }),
        ),
      })

      goBack(true)
      notifyOrder({
        orderId,
        message: 'Placed Order',
        fileUrl: uploaderStore.fileList('purchase_order')[0]?.url,
      })

      if (syncOrderWithIntegration) {
        integrationStore.createOrder(orderId)
      }
    } catch (err) {
      console.error('Unable to save changes to order', err)
      if (err.response.data.error) {
        message.error(err.response.data.error)
      } else {
        message.error('Unable to save updates to the order')
      }
    } finally {
      setSubmitting(false)
    }
  }

  const handleSelectOrder = (order) => {
    setPurchaseOrderModalVisible(true)
    orderStore.selectedOrder = order
    const orderMaterials = groupBy(order.order_materials, 'delivery_id')
    orderStore.updateAllOrderMaterials(orderMaterials)
    handleSetPlaceOrder(order.id)
  }

  const handleDownload = () => {
    const tableElt = document.getElementById('RFQComparisonTable')
    // Sheet names cannot exceed 31 chars
    const sheetName = orderPackage.name
      // eslint-disable-next-line no-useless-escape
      .replace(/[\\\/\?\*\[\]]/g, '')
      .trim()
      .substring(0, 28)
      .trim()
      .concat('...')

    const workbook = XLSX.utils.table_to_book(tableElt, { sheet: sheetName })

    try {
      // Remove the last 2 rows of the table (attachments)
      const worksheet = workbook?.Sheets[sheetName]
      const lastRowNumber = Number(worksheet['!ref'].split(':')[1].match(/(\d+)/)[0])
      // Vendor file row
      deleteRow(worksheet, lastRowNumber)
      // Quote file row
      deleteRow(worksheet, lastRowNumber - 1)
    } catch (error) {
      console.error(error)
    } finally {
      XLSX.writeFile(workbook, `${sheetName}.xlsx`)
    }
  }

  return (
    <>
      <ModalConfirmPurchase
        open={purchaseOrderModalVisible}
        onFinish={handlePlaceOrder}
        onClose={() => {
          handleCancelSplitting()
          setPurchaseOrderModalVisible(false)
        }}
        onChangeSyncOrderWithIntegration={setSyncOrderWithIntegration}
        syncOrderWithIntegration={syncOrderWithIntegration}
        costSavingValue={costSavingValue}
        orderState={OrderStates.ORDERED}
        isLoading={isSubmitting}
      />
      <Space size="middle">
        <Button onClick={handleDownload}>Download</Button>
        <Dropdown
          disabled={
            ordersStatus.every((status) => status !== OrderStates.QUOTED) ||
            !userStore.canSendOrderFromQuoteLeveling ||
            ordersLocked
          }
          menu={{
            items: [
              ...orderPackage?.orders?.map((order) => ({
                label: order.company_vendor?.vendor?.name || order.company_vendor?.vendor_name,
                key: order.id,
                onClick: () => handleSelectOrder(order),
                disabled: order.state === OrderStates.CANCELLED,
              })),
              rfqLevelingComboEnabled && { type: 'divider' },
              rfqLevelingComboEnabled && {
                label: 'Split Up RFQ',
                key: 'split',
                onClick: () => handleStartSplitting(),
              },
            ],
          }}
          placement="bottomRight"
        >
          <Button
            disabled={!userStore.canSendOrderFromQuoteLeveling || ordersLocked}
            type="primary"
            loading={isSubmitting}
          >
            Place Order <DownOutlined />
          </Button>
        </Dropdown>
      </Space>
    </>
  )
})
