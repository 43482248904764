import React from 'react'

import { Typography } from 'antd'

import { Box } from 'common/components/boxes'
import { DateRangePicker, RefinementListDropdown, ToggleRefinement } from 'common/components/table/filters'

import { useFlag } from 'contractor/hooks/use-flag'
import { useStores } from 'contractor/hooks/use-stores'
import { ClearAllButton } from 'contractor/pages/Materials/Filters/clear_all_button'

export const OrdersFilters = () => {
  const { userStore, orderStore } = useStores()
  const tcmCsvDownloadEnabled = useFlag('tcm_csv_download')

  return (
    <Box p={12} display="flex" flexDirection="column">
      <Box mb={16} width="100%" display="flex" flexDirection="column">
        <Typography.Text type="secondary">Vendor</Typography.Text>
        <RefinementListDropdown
          attribute="company_vendor_name"
          placeholder="Select vendor"
          isSearcheable
          onClick={() => orderStore.setIsSorted(true)}
        />
      </Box>
      <Box mb={16} width="100%" display="flex" flexDirection="column">
        <Typography.Text type="secondary">Ordered by</Typography.Text>
        <RefinementListDropdown attribute="ordered_by_column" placeholder="Select user" isSearcheable />
      </Box>
      <Box mb={16} width="100%" display="flex" flexDirection="column">
        <Typography.Text type="secondary">Tags</Typography.Text>
        <RefinementListDropdown attribute="tags" placeholder="Select tag" isSearcheable />
      </Box>
      <Box mb={16} width="100%" display="flex" flexDirection="column">
        <Typography.Text type="secondary">Delivery Issue</Typography.Text>
        <RefinementListDropdown attribute="all_delivery_issues" placeholder="Select delivery issue" isSearcheable />
      </Box>
      {userStore.canUseInvoices && (
        <Box mb={16} width="100%" display="flex" flexDirection="column">
          <Typography.Text type="secondary">Invoices</Typography.Text>
          <RefinementListDropdown attribute="invoices_filter" placeholder="Select invoice" isSearcheable />
        </Box>
      )}
      {userStore.canUseIntegrations && (
        <Box mb={16} width="100%" display="flex" flexDirection="column">
          <Typography.Text type="secondary">Sync Status</Typography.Text>
          <RefinementListDropdown attribute="agave_sync_status" placeholder="Select sync status" isSearcheable />
        </Box>
      )}
      {tcmCsvDownloadEnabled && (
        <Box mb={16} width="100%" display="flex" flexDirection="column">
          <Typography.Text type="secondary">Sync Status</Typography.Text>
          <RefinementListDropdown attribute="manual_export_status" placeholder="Select sync status" isSearcheable />
        </Box>
      )}
      <Box mb={16} width="100%" display="flex" flexDirection="column">
        <Typography.Text type="secondary">Requested By</Typography.Text>
        <RefinementListDropdown attribute="requested_by" placeholder="Select requester" isSearcheable />
      </Box>
      <Box width="100%" mb={16}>
        <DateRangePicker attribute="created_at_timestamp" title="Created At" />
      </Box>
      <Box width="100%" mb={24}>
        <DateRangePicker attribute="ordered_at_timestamp" title="Ordered At" />
      </Box>

      <Box width="100%" mb={24}>
        <ToggleRefinement attribute="is_quick_created" label="Quick Create PO" value={true} />
      </Box>

      <ClearAllButton />
    </Box>
  )
}
