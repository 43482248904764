import _ from 'lodash'
import moment from 'moment'
import pluralize from 'pluralize'

import { Address } from 'common/server/addresses'

export const UUID_REGEXP = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i

// Extracted from https://html.spec.whatwg.org/multipage/input.html#valid-e-mail-address
// Usage example: EMAIL_REGEXP.test(email)
export const EMAIL_REGEXP =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/

export const currencyFormatter = (value, maximumFractionDigits = 20, minimumFractionDigits = 2): string => {
  if ([null, undefined].includes(value)) return 'N/A'
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits,
    minimumFractionDigits,
  }).format(value)
}
export const currencyParser = (value): number => Number(value.replace(/[^0-9.-]+/g, ''))

/*
  Separates and integer and fractional.
  Example:
    10.123
    integer = 10
    fractinal = 123
*/
export const getSpreadValueByDot = (value: string) => {
  const { 0: integer, 1: fractional } = value?.split('.')

  return { integer, fractional }
}

/*
  Format value with comma at each three digits, before a dot.
  Example:
    10.12345 => 10.12345
    100.12345 => 100.12345
    1000.12345 => 1,000.12345
    10000.12345 => 10,000.12345
    100000.12345 => 100,000.12345
*/
export const inputCurrencyFormatter = (value, showCurrencySign = true): string => {
  const { integer, fractional } = getSpreadValueByDot(value.toString())
  const formattedDecimal = integer.replace(/\B(?=(\d{3})+(?!\d))/g, ',')

  if (fractional !== undefined) {
    return `${showCurrencySign ? '$' : ''} ${formattedDecimal.concat(`.${fractional}`)}`
  }

  return `${showCurrencySign ? '$' : ''} ${formattedDecimal}`
}
export const inputCurrencyParser = (value): number => value.replace(/\$\s?|(,*)/g, '')

// Formatter for our enums (removes underscores, capitalizes first letter)
export const formatEnumValue = (val: string): string => {
  if (val?.includes('@')) return val?.toLowerCase()
  else return _.startCase(_.toLower((val || '').replace(/_/g, ' ')))
}

/**
 * Utility function to add CSS in multiple passes.
 * @param {string} styleString
 * https://stackoverflow.com/questions/15505225/inject-css-stylesheet-as-string-using-javascript
 */
export function addStyle(styleString) {
  const style = document.createElement('style')

  style.textContent = styleString
  document.head.append(style)
}

export function formatName(firstName, lastName) {
  return `${firstName ? `${firstName} ` : ''}${lastName ? lastName : ''}`
}

export const formatUserLabel = (user) => {
  if (user.first_name || user.last_name) {
    return [user.first_name || '', user.last_name || ''].join(' ')
  } else {
    return user.email
  }
}

// Takes in two date strings
export function formatDateDiff(d1, d2 = undefined) {
  if (!d1) {
    return null
  }
  d1 = moment(d1)
  d2 = d2 ? moment(d2) : moment()

  const minutes_diff = d2.diff(d1, 'minutes')
  const hours_diff = d2.diff(d1, 'hours')
  const days_diff = d2.diff(d1, 'days')
  const weeks_diff = d2.diff(d1, 'weeks')

  if (minutes_diff <= 60) {
    return pluralize('minute', minutes_diff, true) + ' ago'
  } else if (hours_diff <= 24) {
    return pluralize('hour', hours_diff, true) + ' ago'
  } else if (days_diff < 14) {
    return pluralize('day', days_diff, true) + ' ago'
  } else {
    return pluralize('week', weeks_diff, true) + ' ago'
  }
}

export function formatDateString(d1: string | null, nullReturn = '') {
  if (!d1) {
    return nullReturn
  }
  const currentYear = moment().year()
  const dateYear = moment(d1).year()

  return moment(d1).format(dateYear !== currentYear ? 'ddd MMM Do, YYYY' : 'ddd MMM Do')
}

export function formatDateStringShort(d1: string | Date | null, nullReturn = '') {
  if (!d1) {
    return nullReturn
  }
  return moment(d1).format('MM/DD/YYYY')
}

// Convert days to weeks or days
export function formatNumDays(numDays: number | null, nullReturn = null) {
  if (!numDays) {
    return nullReturn
  } else if (numDays < 7) {
    return pluralize('day', numDays, true)
  } else {
    return pluralize('week', numDays / 7, true)
  }
}

export function formatFullAddress(address: Address) {
  const addressLine2 = address?.address_line_2 ? ` - ${address?.address_line_2}` : ''

  return `${address?.address_line_1}${addressLine2} - ${address?.city}, ${address?.state}, ${address?.zip_code}`
}

// Unfortunately we need to do some formatting for our CSV download. Eventually might need more but for now
// Only thing reported has been the double quote problems but should also consider alternatives like download straight to excel if more issues come up
// https://www.npmjs.com/package/@ibrahimrahmani/react-export-excel
export const formatForCSVExport = (val) => {
  if (typeof val === 'string') {
    return val.replace(/"/g, '""')
  }

  return val
}

export const splitFullName = (fullName = '') => {
  const [firstName, ...lastName] = fullName.split(' ')

  return {
    firstName,
    lastName: Object.values(lastName).join(' '),
  }
}
