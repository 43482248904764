import React, { useCallback, useMemo, useRef, useState } from 'react'

import { Link } from 'react-router-dom'

import {
  BarChartOutlined,
  CalendarOutlined,
  ProfileOutlined,
  ProjectOutlined,
  ScheduleOutlined,
  WalletOutlined,
} from '@ant-design/icons'
import BuildOutlined from '@ant-design/icons/BuildOutlined'

import { observer } from 'mobx-react-lite'

import { DrawerRef } from 'common/components/Drawer'
import { NavBar } from 'common/components/MainMenu/NavBar'
import { Notifications } from 'common/components/Notifications'
import { formatName } from 'common/helpers/formatters'
import { useIntercom } from 'common/hooks/use-intercom'

import { LogDelivery } from 'contractor/components/LogDelivery'
import { useFlag } from 'contractor/hooks/use-flag'
import { useStores } from 'contractor/hooks/use-stores'
import { sign_out } from 'contractor/server/user'

import { FastAction, getFastActionMenuItem } from './FastActions'
import { Help } from './help'
import Masquerade from './masquerade'
import TermsAndConditions from './terms_and_conditions'

const insertIf = (condition, ...elements) => (condition ? elements : [])

const MainMenu = observer(() => {
  const { userStore, projectStore, companySettingStore, notificationStore } = useStores()

  const intercom = useIntercom()

  const billingEnabled = useFlag('billing_pages')
  const canUseCommitments = useFlag('commitments') && userStore.canSeeAndCreateCommitments

  const logDeliveryRef = useRef<DrawerRef>()

  const [masqueradeModalOpen, setMasqueradeModal] = useState(false)

  const invoicePermissions = companySettingStore?.permissions?.invoices_permissions?.MATERIAL_REQUESTER

  const canUseInvoices = useCallback(() => {
    if (invoicePermissions) {
      return Object.values(invoicePermissions).some((permission) => permission === true)
    }
  }, [invoicePermissions])

  const handleSignOutAndReload = useCallback(async () => {
    await sign_out()
    intercom.shutdown()
    window.location.assign('/')
  }, [intercom.shutdown])

  const userName = formatName(userStore.currentUser?.first_name, userStore.currentUser?.last_name)

  const navItems = useMemo(() => {
    if (!userStore.currentUser) {
      return []
    }

    if (userStore.isMaterialRequester) {
      return [
        { key: 'order', match: /^\/(order|orders)/, href: '/orders', label: 'Orders', icon: <ScheduleOutlined /> },
        ...insertIf(canUseInvoices(), {
          key: 'invoices',
          match: /^\/(invoices|invoice)/,
          href: '/invoices',
          label: 'Invoices',
          icon: <WalletOutlined />,
        }),
      ]
    }

    const projectId = projectStore.selectedProject?.id

    return [
      ...insertIf(userStore.canReadProjects, {
        key: 'projects',
        match: /^\/(projects)/,
        href: '/projects',
        label: 'Projects',
        icon: <ProjectOutlined />,
      }),
      ...insertIf(userStore.canReadOrders, {
        key: 'dashboard',
        match: /^\/(dashboard)/,
        href: projectId ? `/dashboard?project_id=${projectId}` : '/dashboard',
        label: 'Dashboard',
        icon: <BarChartOutlined />,
      }),
      ...insertIf(userStore.canReadOrders, {
        key: 'calendar',
        match: /^\/(calendar)/,
        href: projectId ? `/calendar?project_id=${projectId}` : '/calendar',
        label: 'Calendar',
        icon: <CalendarOutlined />,
      }),
      ...insertIf(userStore.canReadOrders, {
        key: 'order',
        match: /^\/(order|orders)/,
        href: projectId ? `/orders?project_id=${projectId}` : '/orders',
        label: 'Orders',
        icon: <ScheduleOutlined />,
      }),
      ...insertIf(userStore.canReadOrders && userStore.canUseMaterialsList, {
        key: 'order_materials',
        match: /^\/(materials)/,
        href: projectId ? `/materials?project_id=${projectId}` : '/materials',
        label: 'Materials',
        icon: <ProfileOutlined />,
      }),
      ...insertIf(canUseCommitments, {
        key: 'Commitments',
        match: /^\/(commitments|commitment)/,
        href: '/commitments',
        label: 'Commitments',
        icon: <BuildOutlined />,
      }),
      ...insertIf(userStore.canUseInvoices, {
        key: 'invoices',
        match: /^\/(invoices|invoice)/,
        href: '/invoices',
        label: 'Invoices',
        icon: <WalletOutlined />,
      }),
    ]
  }, [
    userStore.currentUser,
    userStore.canReadOrders,
    userStore.canReadProjects,
    userStore.isMaterialRequester,
    projectStore.selectedProject?.id,
    userStore.canUseInvoices,
    canUseInvoices,
    canUseCommitments,
  ])

  const adminExtraItems = userStore.currentUser?.is_subbase
    ? [
        { label: <a href="/admin">Admin</a>, key: 'admin' },
        {
          label: (
            <>
              <div onClick={() => setMasqueradeModal(true)}>Login As User</div>
              <Masquerade setMasqueradeModal={setMasqueradeModal} masqueradeModalOpen={masqueradeModalOpen} />
            </>
          ),
          key: 'masquerade',
        },
      ]
    : []

  const userExtraItems = useMemo(() => {
    if (!userStore.currentUser) {
      return []
    }

    // TODO: If the user has a customer ID but is not currently subscribed to anything (e.g. downgraded) what do we show?
    // Currently we just assume that if you don't have a customer ID you should see the pricing table and otherwise, view the
    // the customer portal
    const hasCustomerId = !!userStore.currentUser?.billing?.stripe_customer_id

    const showUpgrade = billingEnabled && userStore.canManageBilling && !hasCustomerId
    const showBilling = billingEnabled && userStore.canManageBilling && hasCustomerId

    return [
      { label: <Link to="/user_settings">User Settings</Link>, key: 'user_settings' },
      ...insertIf(showUpgrade, {
        label: <Link to="/billing/pricing_table">Upgrade</Link>,
        key: 'pricing_table',
      }),
      ...insertIf(showBilling, {
        label: <a href="/billing/customer_portal">Billing</a>,
        key: 'customer_portal',
      }),
      {
        label: <Help />,
        key: 'help',
      },
      { label: <div onClick={handleSignOutAndReload}>Sign Out</div>, key: 'sign_out' },
    ]
  }, [userStore.currentUser, userStore.feature_flags])

  const extraItems = useMemo(() => {
    if (!userStore.currentUser) {
      return []
    }

    return [
      ...insertIf(userStore.canReadVendors || userStore.canManageVendors, {
        label: <Link to="/company_vendors">Vendors</Link>,
        key: 'company_vendors',
      }),
      ...insertIf(userStore.canEditMaterialDatabase, {
        label: <Link to="/company_materials">Material Database</Link>,
        key: 'company_materials',
      }),
      ...insertIf(userStore.canReadCompanySettings, {
        label: <Link to="/company_settings">Company Settings</Link>,
        key: 'company_settings',
      }),
      ...insertIf(userStore.canUseIntegrations, {
        label: <Link to="/integrations">Integrations</Link>,
        key: 'integrations',
      }),
    ]
  }, [
    userStore.currentUser,
    userStore.canReadOrders,
    userStore.canReadProjects,
    userStore.isMaterialRequester,
    projectStore.selectedProject?.id,
    userStore.canUseInvoices,
  ])

  const handleOpenLogDelivery = () => logDeliveryRef.current?.show()

  return (
    <>
      <LogDelivery ref={logDeliveryRef} onClose={() => logDeliveryRef.current?.close()} />
      <NavBar
        basePath="/"
        navItems={navItems}
        extraItems={extraItems}
        userExtraItems={userExtraItems}
        adminExtraItems={adminExtraItems}
        notification={
          <Notifications
            onLoadMore={notificationStore.loadMoreNotifications}
            notificationsGroups={notificationStore.notificationGroups.slice()}
            onDismissNotificationGroup={notificationStore.dismissGroup}
            onDismissAllNotifications={notificationStore.dismissAll}
            loadMessages={notificationStore.loadMessages}
            dismissMessage={notificationStore.dismiss}
            showLoadMore={notificationStore.showLoadMore}
            unreadNotificationsCount={notificationStore.unreadNotificationsCount}
            totalUnreadCount={notificationStore.totalUnreadNotificationsCount}
          />
        }
        userName={userName}
        extraActionMenuItem={getFastActionMenuItem({
          onOpen: handleOpenLogDelivery,
        })}
        extraActionComponent={<FastAction onOpen={handleOpenLogDelivery} />}
      />
      <TermsAndConditions />
    </>
  )
})

export default MainMenu
