import React from 'react'

import { Col, Form, Input, Row } from 'antd'
import { FormInstance } from 'antd/lib/form'

import { observer } from 'mobx-react-lite'

import { Card } from 'common/components/Card'
import Tags from 'common/components/tags'
import { Visibility } from 'common/components/Visibility'
import { getPONumberTitle } from 'common/helpers/get-po-number-title'

import { SelectProject } from 'contractor/components/SelectProject'
import { useFlag } from 'contractor/hooks/use-flag'
import { useStores } from 'contractor/hooks/use-stores'
import { SelectCommitment } from 'contractor/pages/@v2/Commitments/Components/SelectCommitment/select_commitment'
import { useNewQuote } from 'contractor/pages/NewQuote/context'

type OrderFieldsProps = {
  orderType: OrderType
  form: FormInstance
  disabled?: boolean
  deliveryId?: string
}

export const OrderFields = observer<OrderFieldsProps>(({ orderType, form, disabled, deliveryId }) => {
  const { orderStore, userStore } = useStores()
  const { commitment, projectId } = useNewQuote()
  const canUseCommitment = useFlag('commitments')
  const cannotEditPoNumber = orderStore.isSplitting || disabled || userStore.cannotSendAndUpdateOrders
  const materialsSelected = orderStore.orderMaterialsLengthByDeliveryId(deliveryId) > 0 && !commitment

  const quoteNumberEnabled = useFlag('quote_number')

  const poNumberTitle = orderType === 'Order' ? 'PO Number' : getPONumberTitle(orderStore.selectedOrder?.state)

  return (
    <Card>
      <Row gutter={16}>
        <Col xs={24} sm={12} xl={canUseCommitment ? 4 : 6}>
          <Form.Item
            label="Project"
            name="projectId"
            rules={[{ required: true, message: 'Must assign the order to a project' }]}
            style={{ width: '100%' }}
          >
            <SelectProject disabled={orderStore.isSplitting || disabled || !!commitment} />
          </Form.Item>
        </Col>

        <Col xs={24} sm={12} xl={canUseCommitment ? 4 : 6}>
          <Form.Item
            label="Order Name"
            name="orderPackageName"
            rules={[{ required: true, message: 'Please add a name to the order' }]}
            style={{ width: '100%' }}
          >
            <Input
              data-cy="order-name"
              style={{ width: '100%' }}
              placeholder="Order Name e.g. Fixtures, Tile, etc."
              autoFocus={!!form.getFieldValue('projectId')}
              disabled={orderStore.isSplitting || disabled}
            />
          </Form.Item>
        </Col>

        {canUseCommitment && (
          <Col xs={24} sm={12} xl={4}>
            <Form.Item label="Commitment" name="commitmentId" style={{ width: '100%' }}>
              <SelectCommitment
                projectId={projectId}
                disabled={orderStore.isSplitting || disabled || !projectId || materialsSelected}
              />
            </Form.Item>
          </Col>
        )}

        <Visibility.Hidden>
          {orderType == 'Order' && (
            <Col xs={24} sm={quoteNumberEnabled ? 6 : 12} xl={quoteNumberEnabled ? 4 : 6}>
              <Form.Item label={poNumberTitle} name="orderNumber" style={{ width: '100%' }}>
                <Input
                  data-cy="po-number"
                  style={{ width: '100%' }}
                  placeholder={`${poNumberTitle} # (optional)`}
                  disabled={cannotEditPoNumber}
                />
              </Form.Item>
            </Col>
          )}

          {quoteNumberEnabled && (
            <Col xs={24} sm={quoteNumberEnabled ? 6 : 12} xl={quoteNumberEnabled ? 4 : 6}>
              <Form.Item label="Quote Number" name="quoteNumber" style={{ width: '100%' }}>
                <Input
                  style={{ width: '100%' }}
                  placeholder="Quote # (optional)"
                  disabled={orderStore.isSplitting || disabled || !!commitment}
                />
              </Form.Item>
            </Col>
          )}

          {!userStore.isMaterialRequester && (
            <Col xs={24} sm={12} xl={quoteNumberEnabled ? 4 : 6}>
              <Form.Item label="Tags" name="tags" style={{ width: '100%' }}>
                <Tags disabled={orderStore.isSplitting || disabled} tags={orderStore.orderTags} />
              </Form.Item>
            </Col>
          )}
        </Visibility.Hidden>
      </Row>
    </Card>
  )
})
