import React, { useEffect } from 'react'

import { components } from 'react-select'

import { CheckOutlined, ThunderboltFilled } from '@ant-design/icons'
import { Button } from 'antd'

import { observer } from 'mobx-react-lite'

import { FlexBoxX } from 'common/components/boxes'
import { Select, Select2Props } from 'common/components/Select'
import { formatName } from 'common/helpers/formatters'
import { useQuery } from 'common/hooks/use-query'

import { useStores } from 'contractor/hooks/use-stores'
import { CompanyVendorContact } from 'contractor/server/company_vendor_contacts'

type OptionType = {
  label: string
  value: string
  contact: CompanyVendorContact
}

type SelectCompanyVendorContactProps = {
  onClickDiscover?: () => void
  hideDiscoverVendors?: boolean
  isCommitment?: boolean
  filterVendorName?: string
} & Select2Props<OptionType, boolean>

// Takes in a contact and turns it into an option we can select
export const makeContactOption = (vendorContact: CompanyVendorContact) => {
  const { contact, company_vendor } = vendorContact
  const { first_name, last_name, vendor_user } = contact

  const safeGlobalVendorName = company_vendor?.vendor?.name || company_vendor?.vendor_name
  const safeGlobalVendorContactName = vendor_user
    ? formatName(vendor_user?.first_name, vendor_user?.last_name)
    : formatName(first_name, last_name)

  return {
    label: `${safeGlobalVendorName}: ${safeGlobalVendorContactName}${
      contact?.nickname ? ` (${contact?.nickname})` : ''
    }`,
    value: contact.id,
    contact: vendorContact,
    companyVendor: company_vendor,
    companyVendorContact: contact,
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const CustomMenuList = ({ onClickDiscover, hideDiscoverVendors, ...props }: any) => {
  return (
    <components.MenuList {...props}>
      {props.children}
      {!hideDiscoverVendors && (
        <Button
          style={{ margin: 8, width: 'calc(100% - 16px)' }}
          onClick={onClickDiscover}
          icon={<ThunderboltFilled style={{ color: '#0192CF' }} />}
        >
          Discover more vendors
        </Button>
      )}
    </components.MenuList>
  )
}

export const SelectCompanyVendorContact = observer<SelectCompanyVendorContactProps>(
  ({ onClickDiscover, hideDiscoverVendors, filterVendorName, isCommitment, ...props }) => {
    const { companyVendorStore } = useStores()
    const [options, setOptions] = React.useState<OptionType[]>(companyVendorStore.vendorContacts.map(makeContactOption))

    const { isLoading } = useQuery(companyVendorStore.maybeGetAllCompanyVendorContacts)

    useEffect(() => {
      if (filterVendorName) {
        const newOptions = companyVendorStore.vendorContacts?.filter(
          (contact) =>
            contact?.company_vendor?.vendor_name === filterVendorName ||
            contact?.company_vendor?.vendor?.name === filterVendorName,
        )
        setOptions(newOptions.map(makeContactOption))
      }
    }, [filterVendorName])

    const renderMenuList = React.useCallback((menuProps) => {
      return (
        <CustomMenuList
          {...menuProps}
          key={1}
          hideDiscoverVendors={isCommitment || hideDiscoverVendors}
          onClickDiscover={onClickDiscover}
        />
      )
    }, [])

    return (
      <Select
        aria-label="vendor-select"
        noOptionsMessage={() => 'No remaining vendors'}
        components={{
          ClearIndicator: null,
          IndicatorSeparator: null,
          MenuList: renderMenuList,
          Option: (optionProps) => (
            <components.Option {...optionProps}>
              <FlexBoxX justifyContent="space-between">
                {optionProps.data.label}
                {optionProps?.isSelected && <CheckOutlined />}
              </FlexBoxX>
            </components.Option>
          ),
        }}
        options={options}
        styles={{
          menu: (provided) => ({ ...provided, zIndex: 3 }),
          control: (provided) => ({ ...provided, height: 32 }),
          container: (provided) => ({ ...provided, position: 'initial', width: '100%' }),
        }}
        onKeyDown={(event) => {
          /*
            We were facing a bug where the user was getting the selected items disappearing.
            We discovered that the problem was occurring when the user searched a term, selected an item,
            and pressed backspace to clear the search when the user still pressed backspace
            after having no search text left the component cleaned the selected items. 
          */
          if (event.key === 'Backspace' && !props?.inputValue) {
            event.preventDefault()
          }
        }}
        isLoading={isLoading}
        {...props}
      />
    )
  },
)
