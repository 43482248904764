import React from 'react'

import { render, screen } from 'common/test-utils/index'

import { TableHeader, TableHeaderProps } from '../table_header'

describe('CompareOrders > table_header', () => {
  it('should render all orders headers', () => {
    render(
      <table>
        <TableHeader
          orders={
            [
              {
                id: '1',
                state: 'QUOTED',
                sub_state: 'QUOTED_REQUESTED',
                order_number: '13245',
                company_vendor: {
                  vendor_name: 'Foo',
                },
                deliveries: [{ delivery_issue_type: null }],
              },
              {
                id: '2',
                state: 'QUOTED',
                sub_state: 'QUOTED_REQUESTED',
                order_number: '13245',
                company_vendor: {
                  vendor_name: 'Bar',
                },
                deliveries: [{ delivery_issue_type: null }],
              },
            ] as TableHeaderProps['orders']
          }
        />
      </table>,
    )

    expect(screen.getAllByTestId('orders').length).toBe(2)
    expect(screen.getAllByTestId('lead-time').length).toBe(2)
    expect(screen.getAllByTestId('unit-cost').length).toBe(2)
    expect(screen.getAllByTestId('ex-cost').length).toBe(2)
  })

  it('should redirect to /order/:id on click order number', () => {
    render(
      <table>
        <TableHeader
          orders={
            [
              {
                id: '1',
                state: 'QUOTED',
                sub_state: 'QUOTED_REQUESTED',
                order_number: '13245',
                company_vendor: {
                  vendor_name: 'Foo',
                },
                deliveries: [{ delivery_issue_type: null }],
              },
              {
                id: '2',
                state: 'QUOTED',
                sub_state: 'QUOTED_REQUESTED',
                order_number: '54321',
                company_vendor: {
                  vendor_name: 'Bar',
                },
                deliveries: [{ delivery_issue_type: null }],
              },
            ] as TableHeaderProps['orders']
          }
        />
      </table>,
    )

    expect(screen.getByTestId('13245').closest('a').href).toContain('/order/1')
  })
})
