import React from 'react'

import { CellProps } from 'react-table'

import { DownloadOutlined } from '@ant-design/icons'
import { Typography } from 'antd'

import { observer } from 'mobx-react-lite'

import { AgaveSyncStatus } from 'common/components/AgaveSyncStatus'
import { Box } from 'common/components/boxes'
import { OrderedBy } from 'common/components/OrderedBy'
import {
  CellDeliveryInfo,
  CellExpirationQuote,
  CellLastAction,
  CellOrderNumber,
  CellOrderState,
} from 'common/components/table/cells'
import { currencyFormatter, formatDateString } from 'common/helpers/formatters'
import { OrderHit } from 'common/server/orders'

import { OrderDetailPopover } from 'contractor/components/OrderDetailPopover'
import { useFlag } from 'contractor/hooks/use-flag'
import { useStores } from 'contractor/hooks/use-stores'
import CellActions from 'contractor/pages/Orders/cell_actions'
import { CellLeveling } from 'contractor/pages/Orders/cell_leveling'
import CellTag from 'contractor/pages/Orders/cell_tags'

import { useOrders } from './context'

/*
  Common component can't access store, to apply store changes we create
  wrappers to access the store and re-render if has change
*/
const CellStatusObserver = observer<CellProps<OrderHit>>((props) => {
  const { notificationStore } = useStores()

  const notificationCount = notificationStore.notificationCountByObject(props?.row?.original?.id)

  return <CellOrderState order={props?.row?.original} notificationsCount={notificationCount} style={{ margin: 0 }} />
})

const CellDeliveryObserver = observer<CellProps<OrderHit>>(({ row }) => {
  const { deliveryStore, userStore } = useStores()

  const { deliveryDetailRef } = useOrders()

  const handleSelectDelivery = (id = '') => {
    deliveryStore.selectDelivery(id).then(() => deliveryDetailRef.current?.show())
  }

  return (
    <CellDeliveryInfo
      deliveries={row?.original?.deliveries}
      onSelectDelivery={userStore.canEditDeliveryInformation ? handleSelectDelivery : undefined}
    />
  )
})

export default function Columns() {
  const { projectStore, userStore, orderStore, integrationStore, companySettingStore } = useStores()

  const tcmCsvDownloadEnabled = useFlag('tcm_csv_download')
  const foundationCsvDownloadEnabled = useFlag('foundation_csv_download')
  const refinementList = orderStore.searchState['refinementList']
  const showExpirationQuote =
    !refinementList?.['contractor_orders_filter'] || refinementList?.['contractor_orders_filter'].includes('QUOTED')

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const columns: any[] = [
    // Show column leveling only if feature is enabled or user is not a material requester
    ...(userStore.canUseRfqLeveling && !userStore.isMaterialRequester
      ? [
          {
            accessor: 'leveling',
            Cell: CellLeveling,
            width: 30,
          },
        ]
      : []),
    {
      Header: 'Status',
      accessor: 'state',
      Cell: CellStatusObserver,
      width: 140,
      sort_replica_name: 'state',
    },
    // If a project was selected then don't show the project name
    ...(!projectStore.selectedProject
      ? [
          {
            Header: 'Project',
            accessor: 'project_name',
            minWidth: 150,
            ellipsis: true,
            Cell: ({ row }) => {
              return `${row?.original?.project_identifier ? `${row?.original?.project_identifier}: ` : ''} ${
                row?.original?.project_name
              }`
            },
          },
        ]
      : []),
    {
      Header: 'Order',
      accessor: 'order_number',
      Cell: ({ row }) => {
        return (
          <OrderDetailPopover orderId={row.original?.id}>
            <CellOrderNumber
              orderName={row?.original?.name}
              orderNumber={row?.original?.order_number}
              orderId={row?.original?.id}
              orderPackageId={row?.original?.order_package_id}
              state={row?.original?.state}
              subState={row?.original?.sub_state}
            />
          </OrderDetailPopover>
        )
      },
      width: 'auto',
      minWidth: 150,
      sort_replica_name: 'order_number',
    },
    {
      Header: 'Vendor',
      sort_replica_name: 'vendor_name',
      minWidth: 150,
      Cell: ({ row }) => {
        const { original } = row

        if (original?.is_draft) {
          return original.draft_vendor_names?.map((vendor_name) => (
            <div key={vendor_name} style={{ width: '100%' }}>
              {vendor_name}
            </div>
          ))
        }

        return original.company_vendor_name || ''
      },
    },
    ...(showExpirationQuote
      ? [
          {
            Header: 'Expiration Date',
            accessor: 'quote_scheduled_to_expire_at',
            minWidth: 160,
            Cell: ({ value, row }) => <CellExpirationQuote date={value} state={row?.original?.state} />,
          },
        ]
      : []),
    {
      Header: 'Created at',
      id: 'created_at',
      accessor: ({ created_at }) => formatDateString(created_at),
      minWidth: 160,
      sort_replica_name: 'created_at',
    },

    {
      Header: 'Grand Total',
      accessor: ({ grand_total = 0 }) =>
        currencyFormatter(
          grand_total,
          companySettingStore?.otherSettings?.rounding_precision_settings?.order_precision || 2,
        ),
      minWidth: 120,
    },

    {
      Header: 'Delivery Info',
      id: 'delivery_info',
      Cell: CellDeliveryObserver,
      minWidth: 200,
    },
    ...(tcmCsvDownloadEnabled || foundationCsvDownloadEnabled
      ? [
          {
            Header: tcmCsvDownloadEnabled ? 'TCM Sync Status' : 'Foundation Sync Status',
            accessor: 'manual_export_status',
            id: 'manual_export',
            minWidth: 150,
            Cell: ({ value }) => {
              const isSynced = value === 'SYNCED'
              return (
                <div>
                  <DownloadOutlined style={{ marginRight: 8 }} />
                  {isSynced ? (
                    <Typography.Text type="secondary" style={{ fontSize: 14, fontWeight: 400 }}>
                      Synced
                    </Typography.Text>
                  ) : (
                    <Typography.Text type="danger" style={{ fontSize: 14, fontWeight: 400 }}>
                      Not Synced
                    </Typography.Text>
                  )}
                </div>
              )
            },
          },
        ]
      : []),
    ...(userStore.canUseIntegrations && integrationStore.purchaseOrderSyncEnabled
      ? [
          {
            Header: 'Sync Status',
            accessor: 'agave_sync_status',
            id: 'agave_sync_status',
            minWidth: 150,
            Cell: ({ value }) => <AgaveSyncStatus status={value} />,
          },
        ]
      : []),
    ...(userStore.canUseInvoices
      ? [
          {
            Header: 'Invoices',
            accessor: 'invoices',
            id: 'invoices',
            minWidth: 150,
            Cell: ({ value = [] }) => (
              <Box>
                {value.slice(0, 3).map((invoice, index) => (
                  <Typography.Text key={invoice.id}>
                    {(index && index <= 2 ? ', ' : '') + `#${invoice.number}`}
                  </Typography.Text>
                ))}
                {value.length > 3 && ` and ${value.length - 3} more.`}
              </Box>
            ),
          },
        ]
      : []),
    ...(userStore.canManageOrders
      ? [
          {
            Header: 'Tags',
            accessor: 'tags',
            id: 'tags',
            Cell: CellTag,
            minWidth: 150,
          },
        ]
      : []),
    {
      Header: 'Last Action',
      id: 'last_action',
      accessor: 'last_action',
      minWidth: 180,
      Cell: (props) => <CellLastAction {...props} currentType="User" />,
    },
    {
      Header: 'Ordered By',
      id: 'ordered_by',
      minWidth: 150,
      Cell: ({ row }) => <OrderedBy orderHit={row.original} />,
    },
    {
      Header: 'Ordered At',
      accessor: 'ordered_at',
      minWidth: 160,
      Cell: ({ value }) => (value ? formatDateString(value) : ''),
    },
    {
      Header: 'Requested By',
      accessor: 'requested_by',
      minWidth: 110,
    },
    ...(userStore.canManageOrders || userStore.canSendAndUpdateOrders
      ? [
          {
            id: 'actions',
            width: '5%',
            Cell: CellActions,
            minWidth: 150,
          },
        ]
      : []),
  ]

  return columns
}
