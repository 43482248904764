import React from 'react'

import { useHistory, useLocation } from 'react-router-dom'

import { DrawerTableFilters } from 'common/components/DrawerTableFilters'
import { Page } from 'common/components/Page'
import { usePersistentFilters } from 'common/contexts/persistent_filters'

import { CompanyMaterialsColumns } from './company_material_columns'
import { CompanyMaterialsDownload } from './company_material_download'
import { CompanyMaterialsFilters } from './company_material_filters'

export function CompanyMaterialsOptions() {
  const history = useHistory()
  const location = useLocation()

  const persistentFilters = usePersistentFilters()
  const activeKey = new URLSearchParams(location.search).get('filter_tab')

  const tabItems = [
    { label: 'Filters', key: 'filters', children: <CompanyMaterialsFilters /> },
    { label: 'Columns', key: 'columns', children: <CompanyMaterialsColumns /> },
    { label: 'Download', key: 'download', children: <CompanyMaterialsDownload /> },
  ]

  return (
    <DrawerTableFilters
      title="Material Database Options"
      useAlgolia={false}
      appliedFiltersCounter={persistentFilters.appliedFiltersCount}
      padding={0}
    >
      <Page>
        <Page.Tabs
          activeKey={activeKey || 'filters'}
          onChange={(key) => {
            const searchParams = new URLSearchParams(history.location.search)
            searchParams.set('filter_tab', key)
            history.push({ search: searchParams.toString() })
          }}
          items={tabItems}
        />
      </Page>
    </DrawerTableFilters>
  )
}
