import React, { forwardRef } from 'react'

import styled from '@emotion/styled'

import { SettingOutlined } from '@ant-design/icons'
import { Typography, Popover, Table, TableProps } from 'antd'

import { observer } from 'mobx-react-lite'

import { Box } from 'common/components/boxes'
import { InputCurrency, InputCurrencyProps } from 'common/components/InputCurrency'
import { InvoiceMaterial } from 'common/server/invoice'

import { useStores } from 'contractor/hooks/use-stores'

import { useInvoice } from '../context'

type TaxInputProps = InputCurrencyProps

const TableWithRef = forwardRef<HTMLDivElement, TableProps<InvoiceMaterial>>((props, ref) => (
  <Table {...props} ref={ref} />
))

const TableStyled = styled(TableWithRef)`
  .ant-table-tbody > tr.ant-table-row-selected > td {
    background: ${({ theme }) => theme.colors['blue-1']};
  }
`

export const TaxInput = observer((props: TaxInputProps) => {
  const { invoiceStore } = useStores()
  const { currencyFormatter, calcExtCost, taxLineItemsEnabled } = useInvoice()

  const handleChangeSelection = (selectedRowKeys) => {
    const invoiceMaterials = invoiceStore.invoice?.invoice_materials.map((invoiceMaterial) => {
      if (selectedRowKeys.includes(invoiceMaterial.id)) {
        return { ...invoiceMaterial, accepts_tax_split: true }
      }

      return { ...invoiceMaterial, accepts_tax_split: false }
    })
    invoiceStore.updateSelectedInvoice('invoice_materials', invoiceMaterials)
  }

  if (taxLineItemsEnabled) {
    return (
      <InputCurrency
        addonAfter={
          <Popover
            title="Tax Split"
            trigger="click"
            placement="topLeft"
            overlayStyle={{ color: 'red' }}
            content={
              <Box maxWidth={480}>
                <Typography.Paragraph>
                  Select which items will receive the split tax. The unselected items ext. cost won&apos;t be considered
                  for the split calculation.
                </Typography.Paragraph>
                <TableStyled
                  size="small"
                  rowKey="id"
                  rowSelection={{
                    onChange: handleChangeSelection,
                    selectedRowKeys: invoiceStore.invoice?.invoice_materials
                      ?.filter((invoiceMaterial) => invoiceMaterial.accepts_tax_split)
                      ?.map((invoiceMaterial) => invoiceMaterial.id),
                  }}
                  pagination={false}
                  dataSource={invoiceStore.invoice?.invoice_materials}
                  columns={[
                    { title: 'Invoice Description', dataIndex: 'description', ellipsis: true },
                    { title: 'Qty', dataIndex: 'quantity_shipped', width: 50, align: 'right' },
                    {
                      title: 'UOM',
                      dataIndex: 'uom',
                      width: 100,
                      ellipsis: true,
                      render: (_, record) => record?.unit?.name || record?.uom,
                    },
                    {
                      title: 'Unit Cost',
                      dataIndex: 'unit_price',
                      align: 'right',
                      width: 75,
                      render: (value = 0) => (
                        <Typography.Text style={{ whiteSpace: 'nowrap' }}>{currencyFormatter(value)}</Typography.Text>
                      ),
                    },
                    {
                      title: 'Ext. Cost',
                      dataIndex: 'extended_price',
                      align: 'right',
                      width: 75,
                      render: (_, record) => {
                        const extCost = calcExtCost({
                          unitCost: record?.unit_price,
                          quantity: record?.quantity_shipped,
                          multiplier: record?.unit?.multiplier,
                          qtyIncrement: record?.unit?.qty_increment,
                        })

                        return (
                          <Typography.Text style={{ whiteSpace: 'nowrap' }}>
                            {currencyFormatter(extCost)}
                          </Typography.Text>
                        )
                      },
                    },
                  ]}
                />
              </Box>
            }
          >
            <SettingOutlined />
          </Popover>
        }
        {...props}
      />
    )
  }

  return <InputCurrency {...props} />
})
