import React from 'react'

import * as XLSX from 'xlsx'

import { render, screen, fireEvent } from 'common/test-utils/index'

import { Header } from '../Header'
import * as mockOrderPackageJson from './order_package.json'

const mockOrderPackageStore = {
  orderPackage: mockOrderPackageJson,
  updateOrderPackage: jest.fn(),
}

const mockUserStore = {
  canManageOrders: true,
}

jest.mock('contractor/hooks/use-stores', () => ({
  useStores: () => ({
    orderPackageStore: mockOrderPackageStore,
    userStore: mockUserStore,
  }),
}))

jest.mock('xlsx', () => ({
  utils: {
    ...jest.requireActual('xlsx').utils,
    table_to_book: jest.fn().mockImplementation(() => ({
      Sheets: {
        Compare: {
          '!ref': 'A1:S22',
        },
      },
    })),
  },
  writeFile: jest.fn(),
}))

afterEach(() => {
  jest.resetAllMocks()
})

describe('CompareOrders > header', () => {
  it('should call goBack when click back icon', () => {
    mockUserStore.canManageOrders = true
    const goBack = jest.fn()
    render(<Header goBack={goBack} />)

    fireEvent.click(screen.getByTestId('go-back'))

    expect(goBack).toBeCalledWith()
  })

  it('should call updateOrderPackage', () => {
    mockUserStore.canManageOrders = true
    const { container } = render(<Header goBack={jest.fn} />)

    fireEvent.click(container.querySelector('.ant-typography-edit'))

    const textarea = container.querySelector('.ant-typography-edit-content textarea')

    fireEvent.change(textarea, {
      target: { value: 'New name' },
    })

    fireEvent.keyDown(textarea, {
      keyCode: 13,
    })
    fireEvent.keyUp(textarea, {
      keyCode: 13,
    })

    expect(mockOrderPackageStore.updateOrderPackage).toBeCalledWith({
      id: '73ac6c4d-7d2e-43b6-9b1a-98da446fb886',
      name: 'New name',
    })
  })

  it('should not change package name if new change is equal previous', () => {
    mockUserStore.canManageOrders = true
    const { container } = render(<Header goBack={jest.fn} />)

    fireEvent.click(container.querySelector('.ant-typography-edit'))

    const textarea = container.querySelector('.ant-typography-edit-content textarea')

    fireEvent.change(textarea, {
      target: { value: 'Compare' },
    })

    fireEvent.keyDown(textarea, {
      keyCode: 13,
    })
    fireEvent.keyUp(textarea, {
      keyCode: 13,
    })

    expect(mockOrderPackageStore.updateOrderPackage).not.toBeCalled()
  })

  it('should not change package name if user not have permission', () => {
    mockUserStore.canManageOrders = false
    const { container } = render(<Header goBack={jest.fn} />)

    fireEvent.click(container.querySelector('.ant-typography-edit'))

    const textarea = container.querySelector('.ant-typography-edit-content textarea')

    fireEvent.change(textarea, {
      target: { value: 'New name' },
    })

    fireEvent.keyDown(textarea, {
      keyCode: 13,
    })
    fireEvent.keyUp(textarea, {
      keyCode: 13,
    })

    expect(mockOrderPackageStore.updateOrderPackage).not.toBeCalled()
  })

  it('should call download table', () => {
    mockUserStore.canManageOrders = false
    render(<Header goBack={jest.fn} />)

    fireEvent.click(screen.getByText('Download'))

    expect(XLSX.utils.table_to_book).toBeCalled()
    expect(XLSX.writeFile).toBeCalled()
  })
})
