import React, { useState } from 'react'

import _ from 'lodash'

import { Form, Input, message, Divider, Select, Button, Alert } from 'antd'

import { observer } from 'mobx-react-lite'

import { Box } from 'common/components/boxes'
import { Drawer, DrawerRef } from 'common/components/Drawer'
import { formatEnumValue } from 'common/helpers/formatters'
import { UserRoles } from 'common/server/server_types'

import { SelectProjectGroup } from 'contractor/components/SelectProjectGroup/select_project_group'
import { useStores } from 'contractor/hooks/use-stores'
import { ManagedUser } from 'contractor/server/company_settings/manage_users'

const roleOptions = Object.values(UserRoles)

type CreateUserProps = {
  onCancel: () => void
}

export type CreateUserRef = DrawerRef & {
  openForReplace: (defaultValues: Partial<ManagedUser>) => void
}

export const CreateUser = observer<CreateUserProps, CreateUserRef>(
  ({ onCancel }, ref) => {
    const { companySettingStore, projectGroupsStore } = useStores()
    const [form] = Form.useForm()

    const [isSubmitting, setSubmitting] = useState(false)

    const drawerRef = React.useRef<DrawerRef>()

    const handleClose = () => (onCancel ? onCancel() : drawerRef.current.close())

    const inviteUsers = async (formValues) => {
      setSubmitting(true)

      try {
        const { emails, role, projectGroupIds, replacingUserId } = formValues

        await companySettingStore.inviteUsers(emails, role, projectGroupIds, replacingUserId)

        message.success('Successfully added new users')
        companySettingStore.indexUsers()
        handleClose()
        form.resetFields()
      } catch (err) {
        console.error(`Unable to invite users: `, err?.response?.data)
        message.error(`Unable to invite users: ${_.get(err, 'response.data.error', '')}`, 3)
      } finally {
        setSubmitting(false)
      }
    }

    const [adviceWhenReplacingUser, setAdviceWhenReplacingUser] = useState<Nullable<string>>(null)
    React.useImperativeHandle(
      ref,
      () => {
        return {
          close: drawerRef.current.close,
          toggle: drawerRef.current.toggle,
          show: drawerRef.current.show,
          openForReplace: (defaultValues: Partial<ManagedUser>) => {
            setAdviceWhenReplacingUser(
              `You are about to replace the user ${defaultValues.first_name} ${defaultValues.last_name}.`,
            )

            form.setFieldsValue({
              role: defaultValues.role,
              projectGroupIds: defaultValues.project_group_ids,
              replacingUserId: defaultValues.id,
            })

            drawerRef.current.show()
          },
        }
      },
      [],
    )

    return (
      <Drawer bgGray title="Add New Users" ref={drawerRef} onClose={handleClose}>
        <Box p={16} width="100%" overflowY="auto">
          {adviceWhenReplacingUser && (
            <Box mb={16}>
              <Alert
                message={adviceWhenReplacingUser}
                description="The replaced user will be deactivated and places like watching orders, assigned orders and invoices might be affected and changed to the new user."
                type="warning"
                showIcon
              />
            </Box>
          )}
          <Form
            layout="vertical"
            form={form}
            initialValues={{ role: UserRoles.USER }}
            onValuesChange={(v) => {
              // Better UX if we trim the emails for you, otherwise you might not know what's wrong
              if (v?.emails) {
                form.setFieldsValue({ emails: _.trim(v.emails.toLowerCase()) })
              }
            }}
            onFinish={inviteUsers}
            style={{ width: '100%' }}
          >
            <Form.Item hidden name="replacingUserId">
              <input />
            </Form.Item>

            <Form.Item
              name="emails"
              label="Email(s)"
              rules={[{ required: true, message: 'Please enter one or more emails!' }]}
              extra="Comma separated list of emails to invite"
            >
              <Input placeholder="user1@mycompany.com,user2@mycompany.com" />
            </Form.Item>

            <Form.Item name="role" label="Role">
              <Select>
                {roleOptions.map((role) => (
                  <Select.Option key={role} value={role}>
                    {formatEnumValue(role)}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              label="Project groups"
              name="projectGroupIds"
              rules={[
                projectGroupsStore.projectGroups.length
                  ? { required: true, message: 'Please select one or several groups!' }
                  : {},
              ]}
            >
              <SelectProjectGroup mode="multiple" style={{ width: '100%' }} />
            </Form.Item>

            <Divider />

            <Box display="flex" justifyContent="space-between">
              <Button onClick={handleClose} loading={isSubmitting} style={{ width: 100 }}>
                Cancel
              </Button>

              <Button type="primary" htmlType="submit" loading={isSubmitting} style={{ width: 100 }}>
                Create
              </Button>
            </Box>
          </Form>
        </Box>
      </Drawer>
    )
  },
  { forwardRef: true },
)
