import React from 'react'

import { Dropdown, Form, Popconfirm, Tooltip, Typography } from 'antd'

import { observer } from 'mobx-react-lite'

import { Box } from 'common/components/boxes'
import Tags from 'common/components/tags'
import { Visibility } from 'common/components/Visibility'
import { useTheme } from 'common/hooks/use-theme'
import { Events, trackEvent } from 'common/pendo/event_tracking'
import { CommitmentStatuses } from 'common/server/server_types'

import { InternalComments } from 'contractor/components/InternalComments'
import { WatchersDrawer } from 'contractor/components/WatchersDrawer'
import { makeWatcherOption } from 'contractor/components/WatchersSelect'
import { useStores } from 'contractor/hooks/use-stores'
import { Actions } from 'contractor/pages/@v2/Commitments/Components/CommitmentActions'
import { AttachmentsDrawer } from 'contractor/pages/@v2/Commitments/Components/CommitmentAttachments/attachments_drawer'
import { useCommitment } from 'contractor/pages/@v2/Commitments/Components/CommitmentContext/context'
import { insertIf } from 'contractor/pages/Invoices/Detail/invoice_detail_extract'

export const RightActions = observer(() => {
  const theme = useTheme()
  const { companySettingStore, commitmentStore, userStore } = useStores()
  const { isDirty, setDirty, setShowConfirmSubmitVisible, isSubmitting, isDraft, commitment, handleDeleteCommitment } =
    useCommitment()

  const isNewCommitment = !commitment?.id
  const isCanceled = commitment.status === CommitmentStatuses.CANCELLED
  const source = window.location.pathname

  return (
    <Box display="flex" alignItems="flex-start" style={{ gap: 8 }}>
      <Visibility.Hidden breakpoint="lg">
        <Box minWidth={150}>
          <Form.Item style={{ marginBottom: 0 }} name="tags">
            <Tags data-cy="tags" tags={commitmentStore.commitmentTags} />
          </Form.Item>
        </Box>
      </Visibility.Hidden>

      <Visibility.Hidden breakpoint="md">
        <InternalComments
          onSubmit={commitmentStore.selectedCommitment && commitmentStore.sendInternalComment}
          loading={isSubmitting}
          value={!commitmentStore.selectedCommitment && commitmentStore.newInternalComment?.text}
          onChange={!commitmentStore.selectedCommitment && commitmentStore.updateInternalComment}
          comments={commitmentStore.selectedCommitment?.internal_comments}
          isDraftCommitment={!commitmentStore.selectedCommitment}
          commitmentState={commitmentStore?.selectedCommitment?.status || CommitmentStatuses.DRAFT}
        />

        {userStore?.canEditAndDeleteCommitments && (
          <>
            <Tooltip title={isDraft ? 'Submitting this commitment will lock project and vendor.' : ''}>
              <Actions
                isLoading={isSubmitting}
                showConfirmSubmit={() => setShowConfirmSubmitVisible(true)}
                disabled={!isDirty}
              />
            </Tooltip>

            <Dropdown.Button
              data-cy="commitment-extra-actions"
              menu={{
                items: [
                  {
                    label: (
                      <WatchersDrawer
                        boxProps={{
                          width: 'auto',
                          p: 0,
                          height: 'auto',
                          border: 'none',
                          display: 'block',
                        }}
                        value={companySettingStore.possibleUsers
                          .filter((user) => commitment?.watcher_ids?.includes(user.id))
                          .map((user) => makeWatcherOption(user))}
                        onChange={(watchers = []) => {
                          commitment.watcher_ids = watchers?.map((watcher) => watcher.value)
                          setDirty(true)
                        }}
                      >
                        Watchers
                      </WatchersDrawer>
                    ),
                    key: '1',
                  },
                  {
                    label: (
                      <Box
                        onClick={() => {
                          trackEvent(Events.CLICK_OPEN_ATTACHMENT, source)
                        }}
                      >
                        <AttachmentsDrawer />
                      </Box>
                    ),
                    key: '3',
                  },
                  ...insertIf(!isNewCommitment, {
                    label: (
                      <Popconfirm
                        title={`Are you sure you want to ${isCanceled ? 'delete' : 'cancel'} this commitment?`}
                        onConfirm={() => {
                          handleDeleteCommitment()
                          trackEvent(Events.CLICK_CANCEL_COMMITMENT, source)
                        }}
                      >
                        <Typography style={{ color: theme.colors['red-6'] }}>
                          {isCanceled ? 'Delete Commitment' : 'Cancel Commitment'}
                        </Typography>
                      </Popconfirm>
                    ),
                    key: '5',
                  }),
                ],
              }}
              trigger={['hover', 'click']}
              buttonsRender={([_, rightButton]) => [null, React.cloneElement(rightButton as React.ReactElement)]}
              style={{ width: 'auto' }}
            />
          </>
        )}
      </Visibility.Hidden>
    </Box>
  )
})
