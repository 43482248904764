import React from 'react'

import { DragIndicator } from '@styled-icons/material/DragIndicator'

import styled from '@emotion/styled'

import { Empty, Popover, Table, TableProps, Typography } from 'antd'

import { observer } from 'mobx-react-lite'

import { FlexBoxY } from 'common/components/boxes'
import { Loading } from 'common/components/Loading'
import { currencyFormatter } from 'common/helpers/formatters'
import { calcExtCost } from 'common/helpers/order'
import { OrderMaterial as IOrderMaterial } from 'common/server/orders'

import { useLockInvoice } from 'contractor/hooks/use-lock-invoice'
import { useStores } from 'contractor/hooks/use-stores'

import { useInvoice } from '../../context'
import { DraggableBodyRow } from './draggable_body_row'
import { DroppableUnmatch } from './droppable_unmatch'

const Wrapper = styled.div`
  height: 100%;
  .ant-table-summary .ant-table-cell {
    background-color: ${({ theme }) => theme.colors['gray-2']};
  }

  .ant-table-summary tr:last-child td {
    border-bottom: 0;
  }
`
type OrderMaterialProps = {
  isLoading?: boolean
  onFilterTable?: (dataSource: IOrderMaterial) => void
}

export const OrderMaterial = observer<OrderMaterialProps>(({ isLoading, onFilterTable }) => {
  const { invoiceStore } = useStores()
  const isInvoiceLocked = useLockInvoice(invoiceStore?.invoice)

  const { handleUnmatch } = useInvoice()

  const columns: TableProps<IOrderMaterial>['columns'] = [
    {
      dataIndex: 'action',
      render: (_, row) => {
        if (!row.company_material?.id) return null
        return <DragIndicator size="20" />
      },
      width: 36,
    },
    {
      title: 'Order Description',
      dataIndex: ['company_material', 'quantity', 'unit_cost'],
      render: (_, row) => (
        <Popover
          content={
            <Table
              size="small"
              columns={[
                { title: 'Qty', dataIndex: 'quantity' },
                { title: 'UOM', dataIndex: 'uom' },
                { title: 'Unit Cost', dataIndex: 'unit_cost' },
                { title: 'Ext. Cost', dataIndex: 'extended_price' },
              ]}
              dataSource={[
                {
                  key: 1,
                  quantity: row.quantity,
                  uom: row.company_material?.unit?.name || row.company_material?.unit_name,
                  unit_cost: currencyFormatter(row?.unit_cost),
                  extended_price: currencyFormatter(
                    calcExtCost({
                      unitCost: row?.unit_cost as number,
                      quantity: row?.quantity,
                      multiplier: row.company_material?.unit?.multiplier as unknown as number,
                      qtyIncrement: row.company_material?.unit?.qty_increment as unknown as number,
                    }),
                  ),
                },
              ]}
              pagination={false}
            />
          }
        >
          <FlexBoxY justifyContent="flex-start" alignItems="start">
            <Typography.Text>{row.company_material?.description}</Typography.Text>
            {row.company_note && (
              <Typography.Text style={{ fontSize: 10 }} type="secondary">
                {row.company_note}
              </Typography.Text>
            )}
          </FlexBoxY>
        </Popover>
      ),
    },
  ]

  const noMatches = invoiceStore.selectedOrdersMaterials.filter((orderMaterial) => {
    return !invoiceStore.invoice?.invoice_materials?.some((invoiceMaterial) =>
      invoiceMaterial?.order_materials?.some((om) => om.id === orderMaterial.id),
    )
  })

  if (isLoading) {
    return <Loading />
  }

  return (
    <Wrapper>
      <DroppableUnmatch onUnmatch={handleUnmatch}>
        <Table<IOrderMaterial>
          rowKey="id"
          components={{
            body: {
              row: (item, i) => {
                return <DraggableBodyRow index={i} {...item} disabled={isInvoiceLocked} />
              },
            },
          }}
          onRow={(item) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            return { item } as React.HTMLAttributes<any>
          }}
          columns={columns}
          dataSource={onFilterTable ? noMatches.filter(onFilterTable) : noMatches}
          pagination={false}
          size="small"
          locale={{
            emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="All items matched." />,
          }}
        />
      </DroppableUnmatch>
    </Wrapper>
  )
})
