import { v4 as uuid_v4 } from 'uuid'

import { OrderDelivery } from './deliveries'

export const emptyOrderDelivery: () => OrderDelivery = () => ({
  id: uuid_v4(),
  state: null,
  is_pick_up: false,
  // Request
  address: null,
  address_to: null,
  requested_delivered_at: undefined,
  instructions: '',
  auto_follow_up_days: null,
  lead_time_days: null,
  // Shipping
  marked_shipped_at: null,
  shipped_at: undefined,
  carrier: '',
  shipping_carrier_id: '',
  tracking_number: '',
  estimated_delivered_at: '',
  actual_delivered_at: undefined,
  // Receiving
  marked_delivered_at: null,
  marked_delivered_by: null,
  delivery_issues: '',
  delivery_issue_type: null,
  delivery_issue_resolved_at: '',
  delivery_issue_resolved_by: null,
  has_open_issue: false,
  delivery_files: [],
  delivery_files_signed_ids: [],
  delivery_files_delete_ids: [],
  // values
  tax_value: null,
  discount_value: null,
  shipping_value: null,
  other_value: null,
})
