import React from 'react'

import { useLocation } from 'react-router-dom'

import { LocalShipping } from '@styled-icons/material-outlined'

import { PaperClipOutlined, ShopFilled } from '@ant-design/icons'
import { Typography, Space } from 'antd'

import { FlexBoxY } from 'common/components/boxes'
import { formatDateString } from 'common/helpers/formatters'
import { useTheme } from 'common/hooks/use-theme'
import { Events, trackEvent } from 'common/pendo/event_tracking'
import { OrderHitDelivery } from 'common/server/orders'
import { DeliveryStates } from 'common/server/server_types'

// Replace any to interface that's receive order list
export type CellDeliveryInfoProps = {
  onSelectDelivery?: (id: string) => void
  deliveries: OrderHitDelivery[]
}

export const CellDeliveryInfo = ({ deliveries, onSelectDelivery }: CellDeliveryInfoProps) => {
  // TODO: Want to make this even better, i.e. provide even more information about the deliver
  // TODO: Add a "late" tag, concerned we need to be comparing times and timezones will make this get confusing
  const theme = useTheme()

  const location = useLocation()
  const source = location.pathname.substr(1)

  const handleClick = (id, e) => {
    e.stopPropagation()
    onSelectDelivery?.(id)
    trackEvent(Events.DELIVERY_INFO, source)
  }

  const Component = onSelectDelivery ? Typography.Link : Typography.Text

  return (
    <FlexBoxY alignItems="flex-start">
      {deliveries.map((delivery, index) => {
        const {
          id,
          state,
          requested_delivered_at,
          best_guess_delivered_at,
          address,
          estimated_delivered_at,
          carrier,
          delivery_files = [],
        } = delivery as OrderHitDelivery

        const addressText = address ? address.nickname || address.address_line_1 : ''

        const formattedRequestedAt = formatDateString(requested_delivered_at)
        const formattedETA = formatDateString(estimated_delivered_at)
        const formattedDeliveryDate = formatDateString(best_guess_delivered_at)

        const style = {}
        let text = ''
        switch (state) {
          case DeliveryStates.UNSCHEDULED:
            style['color'] = theme.colors['gray-7']
            text = 'Unscheduled'
            break
          case DeliveryStates.REQUESTED:
            text = `Requested - ${formattedRequestedAt} to ${addressText}`
            break
          case DeliveryStates.SCHEDULED:
            text = `Scheduled - ${formattedRequestedAt} to ${addressText}`
            break
          case DeliveryStates.SHIPPED:
            if (estimated_delivered_at && carrier) {
              text = `${carrier}, ETA: ${formattedETA}`
            } else if (estimated_delivered_at && !carrier) {
              text = `ETA: ${formattedETA}`
            } else if (!estimated_delivered_at && carrier) {
              text = `${carrier}, ETA: Unknown`
            } else {
              text = 'ETA: Unknown'
            }
            text = 'Shipped - ' + text
            break
          case DeliveryStates.DELIVERED:
            text = `Delivered - ${formattedDeliveryDate}`
            break
        }

        return (
          <div onClick={(e) => handleClick(id, e)} key={index}>
            <Space align="center">
              {delivery.is_pick_up ? (
                <ShopFilled style={{ color: theme.colors['gray-7'] }} />
              ) : (
                <LocalShipping size="15" color={theme.colors['gray-7']} />
              )}
              <Component ellipsis style={style}>
                {text}
              </Component>
              {!!delivery_files.length && <PaperClipOutlined />}
            </Space>
          </div>
        )
      })}
    </FlexBoxY>
  )
}
