import React from 'react'

import { useStores } from 'contractor/hooks/use-stores'
import { ManagedUser } from 'contractor/server/company_settings/manage_users'
import { IndexProject } from 'contractor/server/projects'

import { ManageGroupDrawerForm } from './manage_group_drawer'

interface UseManageGroupDrawer {
  projects: Array<IndexProject>
  users: Array<ManagedUser>
  defaultValuesFactory: (groupId: string) => Promise<ManageGroupDrawerForm & { id: string }>
  dataLoaders: {
    projects: () => void
    users: () => void
  }
}

export function makeProjectName(project: IndexProject) {
  if (!project.active) {
    return {
      ...project,
      name: `[Inactive] - ${project.name}`,
    }
  }

  return project
}

export function useManageGroupDrawer(): UseManageGroupDrawer {
  const { projectStore, projectGroupsStore, companySettingStore } = useStores()

  const [projects, setProjects] = React.useState<Array<IndexProject>>([])
  const [users, setUsers] = React.useState<Array<ManagedUser>>([])

  async function loadProjects() {
    await Promise.all([projectStore.indexProjects(), projectStore.indexAllProjects()])

    const managedProjects = [...projectStore.allProjects, ...projectStore.allInactiveProjects].map(makeProjectName)

    setProjects(managedProjects)
  }

  async function loadUsers() {
    await companySettingStore.indexUsers()
    setUsers(companySettingStore.users)
  }

  async function defaultValuesFactory(groupId: string) {
    const group = await projectGroupsStore.showProjectGroups(groupId)

    return {
      id: groupId,
      name: group.name,
      selectedProjectsIds: group.project_ids,
      selectedUsersIds: group.user_ids,
    }
  }

  return {
    projects,
    users,
    defaultValuesFactory,
    dataLoaders: {
      projects: loadProjects,
      users: loadUsers,
    },
  }
}
