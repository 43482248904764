import React from 'react'

import { flatten } from 'lodash'

import { observer } from 'mobx-react-lite'

import Download from 'common/components/table/download'
import { formatEnumValue } from 'common/helpers/formatters'

import { useStores } from 'contractor/hooks/use-stores'

type DownloadButtonProps = {
  activeColumns?: string[]
}

const sortColumns = (a, b) => {
  if (a.key === 'project_ids') {
    return -1
  } else if (b.key === 'project_ids') {
    return 1
  } else {
    return 0
  }
}

const DownloadButton = observer<DownloadButtonProps>(({ activeColumns = [] }) => {
  const { companyMaterialStore, companySettingStore } = useStores()

  const { company_attributes } = companySettingStore.companyMaterialConfiguration

  const costCodeSettings = companySettingStore.otherSettings?.cost_code_settings

  const phaseEnabled = costCodeSettings?.phase_code_enabled && !costCodeSettings?.independent_phase_codes_enabled
  const classEnabled = costCodeSettings?.class_enabled

  // Start with the ID
  const headers = []
  company_attributes.forEach((column_name) => {
    switch (column_name) {
      case 'project_ids':
        headers.push({ label: 'Project Ids', key: 'project_ids' })
        break
      case 'cost_code_id':
        headers.push({ label: 'Cost Code', key: 'cost_code.code' })

        if (phaseEnabled) {
          headers.push({ label: 'Phase Code ', key: 'cost_code.phase_code' })
        }
        if (classEnabled) {
          headers.push({ label: 'Class Code', key: 'cost_code.clazz' })
        }
        break
      case 'preferred_vendor_prices':
        new Array(5).fill(0).forEach((_, index) => {
          const fieldNumber = index + 1
          headers.push({ label: `Preferred vendor ${fieldNumber}`, key: `preferred_vendor_${fieldNumber}` })
          headers.push({ label: `Preferred price ${fieldNumber}`, key: `preferred_price_${fieldNumber}` })
        })
        break
      default:
        headers.push({ label: formatEnumValue(column_name), key: column_name })
    }
  })

  // Add all the other columns the user cares about
  headers.push(
    { label: 'Active', key: 'active' },
    { label: 'Last Price', key: 'cached_last_price' },
    { label: 'Average Price', key: 'cached_average_price' },
    { label: 'Lowest Price', key: 'cached_lowest_price' },
    { label: 'SubBase Id', key: 'id' },
  )

  const transformData = (hit) => {
    hit['project_ids'] = hit?.projects
      ?.filter((project) => !!project.project_id)
      .map((project) => project.project_id)
      .join(',')
    hit['unit'] = hit?.unit?.unit_name_with_increment_label || hit?.unit_name

    hit['company_material_vendor_prices']?.forEach((companyMaterialVendorPrice, index) => {
      const fieldNumber = index + 1
      const preferredVendor =
        companyMaterialVendorPrice.company_vendor?.external_vendor_id ||
        companyMaterialVendorPrice.company_vendor?.safe_globalized_vendor_name
      hit[`preferred_vendor_${fieldNumber}`] = preferredVendor
      hit[`preferred_price_${fieldNumber}`] = companyMaterialVendorPrice.price
    })
  }

  const mappedActiveColumns = flatten(
    activeColumns?.map((column) => {
      if (column === 'cost_code.code') {
        return ['cost_code.code', 'cost_code.phase_code', 'cost_code.clazz']
      }
      if (column === 'company_material_vendor_prices') {
        return flatten(
          new Array(5).fill(0).map((_, index) => [`preferred_vendor_${index + 1}`, `preferred_price_${index + 1}`]),
        )
      }
      if (column === 'project_name') {
        return ['project_ids']
      }

      return column
    }),
  )

  return (
    <Download
      filters=""
      store={companyMaterialStore}
      indexName={companyMaterialStore.searchState['sortBy'] || companyMaterialStore.searchKey.index_name}
      headers={headers.filter((header) => [...mappedActiveColumns, 'id'].includes(header.key)).sort(sortColumns)}
      transformData={transformData}
      title={'Material Database'}
      file_name={'material_database'}
    />
  )
})

export default DownloadButton
