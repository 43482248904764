import React, { useEffect, useState } from 'react'

import { InputNumber } from 'antd'
import { InputNumberProps } from 'antd/lib/input-number'

import { SelectType } from 'common/components/InputCurrencyPercentage/Delivery/select_type'
import { PopoverInfo } from 'common/components/InputCurrencyPercentage/popover_info'
import { DeliveryExtraValues } from 'common/components/OrderMaterialsSpreadsheet'
import { inputCurrencyFormatter, inputCurrencyParser } from 'common/helpers/formatters'
import { DeliveryChargesUnit } from 'common/server/deliveries'

type InputCurrencyPercentageProps = {
  inputName: string
  totalCost: number
  roundingPrecision: number
  deliveryChargesUnits?: DeliveryChargesUnit
  onChangeChargesUnit?: (value: DeliveryChargesUnit) => void
} & InputNumberProps

export const InputDeliveryPercentage: React.FC<InputCurrencyPercentageProps> = ({
  totalCost,
  roundingPrecision,
  deliveryChargesUnits,
  onChangeChargesUnit,
  inputName,
  value,
  onChange,
  style,
  ...restProps
}) => {
  const [selectedOption, setSelectedOption] = useState(deliveryChargesUnits?.[inputName])
  const [percentage, setPercentage] = useState<number>(0)
  const [currentValue, setCurrentValue] = useState<number>(0)

  const isPercentage = selectedOption === 'percentage'
  const showCurrencyAmount = selectedOption !== 'decimal'

  const PERCENTAGE_BASE = 100

  const getPercentageValue = (percentage: number) => {
    return (percentage / PERCENTAGE_BASE) * totalCost
  }

  const getReversePercentage = (value: number | null) => {
    if (value == null || totalCost === 0) return null
    return ((value / totalCost) * PERCENTAGE_BASE).toFixed(roundingPrecision)
  }

  const handleInputChange = (newValue: number) => {
    const newCalculatedValue = isPercentage ? getPercentageValue(newValue) : newValue
    setPercentage(isPercentage ? newValue : Number(getReversePercentage(newValue)))
    setCurrentValue(isPercentage ? Number(newCalculatedValue.toFixed(roundingPrecision)) : newValue)

    onChange?.(newCalculatedValue)
  }

  const handleChangeChargeUnit = (value: string) => {
    setSelectedOption(value)
    setPercentage(getReversePercentage(currentValue) || 0)
    onChangeChargesUnit?.({ ...deliveryChargesUnits, [inputName]: value })
  }

  useEffect(() => {
    if (isPercentage) {
      const initialPercentage = Number(getReversePercentage(value))
      setPercentage(initialPercentage)
      setCurrentValue(Number(getPercentageValue(initialPercentage).toFixed(roundingPrecision)))
    } else {
      setCurrentValue(Number(value))
      setPercentage(Number(getReversePercentage(value)))
    }
  }, [value])

  useEffect(() => {
    if (isPercentage) {
      const newValue = getPercentageValue(percentage)
      const roundedNewValue = Number(newValue.toFixed(roundingPrecision))
      if (currentValue !== roundedNewValue) {
        setCurrentValue(roundedNewValue)
        onChange?.(newValue)
      }
    }
  }, [totalCost, percentage, roundingPrecision])

  return (
    <InputNumber
      formatter={(val) => inputCurrencyFormatter(val, false)}
      parser={inputCurrencyParser}
      prefix={inputName === DeliveryExtraValues.Discount ? '-' : '+'}
      inputMode={isPercentage ? 'numeric' : 'decimal'}
      addonBefore={
        <SelectType selectedOption={selectedOption} handleSelectChange={handleChangeChargeUnit} inputName={inputName} />
      }
      addonAfter={showCurrencyAmount && <PopoverInfo inputValue={currentValue} roundingPrecision={roundingPrecision} />}
      aria-autocomplete="none"
      {...restProps}
      value={isPercentage ? percentage : currentValue}
      style={{ width: '100%', ...style }}
      onChange={handleInputChange}
    />
  )
}
