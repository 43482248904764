import React from 'react'

import pluralize from 'pluralize'

import { Button, message, Modal } from 'antd'

import { observer } from 'mobx-react-lite'

import { useStores } from 'contractor/hooks/use-stores'

import { useCostCode } from '../context'

export const BulkEditSave = observer(() => {
  const { costCodeStore, companySettingStore } = useStores()
  const { endBulkEditMode } = useCostCode()

  const costCodeSettings = companySettingStore.otherSettings?.cost_code_settings

  const confirmSave = () => {
    Modal.confirm({
      title: `Updating cost ${pluralize('code', costCodeStore.bulkEditedCostCodes.length, true)}`,
      content: 'Are you sure you want to save these changes?',
      onOk: async () => {
        try {
          await costCodeStore.updateBulk()
          costCodeStore.costCodeListStore.fetchRecords()
          costCodeStore.costCodeListStore.fetchFacets()
          endBulkEditMode()
          message.success('Successfully saved changes')
        } catch (error) {
          console.error(error.response)
          if (error.response.data.error.includes('duplicate key value violates ')) {
            message.error('Unable to save due to duplicate cost code. Make sure all rows are unique.', 7)
          } else {
            message.error('Unable to save, please contact support: ' + error.response.data.error, 7)
          }
        }
      },
    })
  }

  const handleClick = () => {
    try {
      costCodeStore.validateBulkEdit(costCodeSettings.phase_code_enabled, costCodeSettings.class_enabled)
      confirmSave()
    } catch (error) {
      Modal.error({
        title: 'Unable to save',
        content: error.message,
      })
    }
  }

  return (
    <Button type="primary" onClick={handleClick} disabled={!costCodeStore.bulkEditedCostCodes.length}>
      Save
    </Button>
  )
})
