import React, { useState, useImperativeHandle, forwardRef } from 'react'

import { Drawer as AntdDrawer } from 'antd'
import type { DrawerProps as AntdDrawerProps } from 'antd/es/drawer'

import { useTheme } from 'common/hooks/use-theme'
import { useWindowSize } from 'common/hooks/use-window-size'

export type DrawerProps = {
  children: React.ReactNode
  bgGray?: boolean
  overflow?: React.CSSProperties['overflow']
} & Partial<AntdDrawerProps>

export type DrawerRef = {
  show: () => void
  close: () => void
  toggle: () => void
}

export const Drawer = forwardRef<DrawerRef, DrawerProps>(
  ({ bgGray, onClose, width = 736, overflow = 'hidden', ...props }, ref) => {
    const theme = useTheme()

    const windowSize = useWindowSize()

    const isSmallWindow = Number(width) >= windowSize.width

    const [visible, toggleVisible] = useState(false)

    useImperativeHandle(ref, () => ({
      show: () => toggleVisible(true),
      close: () => toggleVisible(false),
      toggle: () => toggleVisible((prev) => !prev),
    }))

    const drawerStyle = { ...(bgGray && { backgroundColor: theme.colors['gray-2'] }), ...props?.drawerStyle }

    return (
      <AntdDrawer
        placement="right"
        width={isSmallWindow ? '90%' : width}
        open={visible}
        onClose={(e) => {
          onClose?.(e)
          toggleVisible(false)
        }}
        bodyStyle={{
          display: 'flex',
          overflow,
          padding: 0,
          height: 'inherit',
        }}
        drawerStyle={drawerStyle}
        push={{ distance: isSmallWindow ? 32 : 180 }}
        {...props}
      />
    )
  },
)
