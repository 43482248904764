import React, { useState, useEffect } from 'react'

import { connectRange } from 'react-instantsearch-dom'
import { useLocation } from 'react-router-dom'

import moment from 'moment'

import styled from '@emotion/styled'

import { Typography, DatePicker } from 'antd'
import type { RangePickerProps } from 'antd/es/date-picker'

import { Box, FlexBoxY, BoxProps } from 'common/components/boxes'
import { useMediaQuery } from 'common/hooks/use-media-query'
import { trackTableFiltering } from 'common/pendo/event_tracking'

type RefineProps = {
  min?: number
  max?: number
}

type ConnectRangeProps = {
  attribute: string
  refine: (nextRefinement: RefineProps) => void
  currentRefinement?: RefineProps
  min?: number
  max?: number
}

type DateRangePickerProps = {
  title: string
} & BoxProps

type Props = DateRangePickerProps & ConnectRangeProps

const WrapperPanel = styled.div`
  @media (max-width: ${({ theme }) => theme.breakpoints[0]}) {
    .ant-picker-panel {
      &:last-child {
        width: 0;
        .ant-picker-header {
          position: absolute;
          left: 114px;
          .ant-picker-header-prev-btn,
          .ant-picker-header-view {
            visibility: hidden;
          }
        }

        .ant-picker-body {
          display: none;
        }
      }
    }
  }
`

const format = 'MM/DD/YYYY'

const DateRangePicker = ({ title, refine, attribute, currentRefinement, ...props }: Props) => {
  const location = useLocation()
  const [value, setValue] = useState<[moment.Moment, moment.Moment]>()

  const isMobileScreen = useMediaQuery('md')

  useEffect(() => {
    if (!currentRefinement?.min && !currentRefinement?.max) {
      setValue(null)
    } else {
      setValue([
        currentRefinement?.min ? moment(currentRefinement?.min) : undefined,
        currentRefinement?.max ? moment(currentRefinement?.max) : undefined,
      ])
    }
  }, [currentRefinement])

  const handleRefine = (startDate: moment.Moment, endDate: moment.Moment) => {
    const minTime = startDate ? startDate.startOf('day').toDate().getTime() : undefined
    const maxTime = endDate ? endDate.endOf('day').toDate().getTime() : undefined
    refine({ min: minTime, max: maxTime })
    trackTableFiltering(location.pathname.slice(1), attribute)
  }

  const handleChange = (value: RangePickerProps['value']) => {
    const [startDate, endDate] = value || []
    handleRefine(startDate, endDate)
  }

  const handleSelectLastQuarter = () => {
    const currentDate = moment()
    const lastQuarterStart = currentDate.clone().subtract(3, 'months').startOf('quarter')
    const lastQuarterEnd = currentDate.clone().subtract(3, 'months').endOf('quarter')
    handleRefine(lastQuarterStart, lastQuarterEnd)
  }

  const handleSelectLastMonth = () => {
    const currentDate = moment()
    const lastMonthStart = currentDate.clone().subtract(1, 'months').startOf('month')
    const lastMonthEnd = currentDate.clone().subtract(1, 'months').endOf('month')
    handleRefine(lastMonthStart, lastMonthEnd)
  }

  const handleSelectLastWeek = () => {
    const currentDate = moment()
    const lastWeekStart = currentDate.clone().subtract(1, 'weeks').startOf('isoWeek')
    const lastWeekEnd = currentDate.clone().subtract(1, 'weeks').endOf('isoWeek')
    handleRefine(lastWeekStart, lastWeekEnd)
  }

  const handleSelectThisQuarter = () => {
    const currentDate = moment()
    const currentQuarterStart = currentDate.clone().startOf('quarter')
    const currentQuarterEnd = currentDate.clone().endOf('quarter')
    handleRefine(currentQuarterStart, currentQuarterEnd)
  }

  const handleSelectThisMonth = () => {
    const currentDate = moment()
    const currentMonthStart = currentDate.clone().startOf('month')
    const currentMonthEnd = currentDate.clone().endOf('month')
    handleRefine(currentMonthStart, currentMonthEnd)
  }

  const handleSelectThisWeek = () => {
    const currentDate = moment()
    const currentWeekStart = currentDate.clone().startOf('isoWeek')
    const currentWeekEnd = currentDate.clone().endOf('isoWeek')
    handleRefine(currentWeekStart, currentWeekEnd)
  }

  return (
    <FlexBoxY width="100%" alignItems="flex-start" {...props}>
      <Typography.Text type="secondary">{title}</Typography.Text>
      <DatePicker.RangePicker
        onChange={handleChange}
        value={value}
        style={{ width: '100%' }}
        format={format}
        panelRender={(panelNode) => <WrapperPanel>{panelNode}</WrapperPanel>}
        // Disable show keyboard when is mobile
        inputReadOnly={isMobileScreen}
        renderExtraFooter={() => (
          <Box display="flex" alignItems="center" lineHeight={1.75} py={8}>
            <Box display="flex" flexDirection="column" flexGrow={1} mr={40}>
              <Typography.Link onClick={handleSelectLastQuarter}>Last quarter</Typography.Link>
              <Typography.Link onClick={handleSelectLastMonth}>Last month</Typography.Link>
              <Typography.Link onClick={handleSelectLastWeek}>Last week</Typography.Link>
            </Box>

            <Box display="flex" flexDirection="column" flexGrow={1}>
              <Typography.Link onClick={handleSelectThisQuarter}>This quarter</Typography.Link>
              <Typography.Link onClick={handleSelectThisMonth}>This month</Typography.Link>
              <Typography.Link onClick={handleSelectThisWeek}>This week</Typography.Link>
            </Box>
          </Box>
        )}
      />
    </FlexBoxY>
  )
}

export default connectRange(DateRangePicker)
