import React from 'react'

import { HistoryOutlined } from '@ant-design/icons'
import { Popover } from 'antd'

import { Events, trackEvent } from 'common/pendo/event_tracking'

import { MaterialPriceDetails } from './material_price_details'
import { UnitCostColumnProps } from './unit_cost'

type AvgPricesProps = Pick<
  UnitCostColumnProps,
  'onChange' | 'companyMaterialDescription' | 'companyVendorId' | 'companyMaterialId' | 'unitCost'
>

export const AvgPrices = ({
  onChange = null,
  companyMaterialDescription = '',
  companyVendorId = '',
  companyMaterialId = '',
  unitCost = null,
}: AvgPricesProps) => {
  return (
    <Popover
      title={companyMaterialDescription}
      content={
        <MaterialPriceDetails
          onChange={onChange}
          companyVendorId={companyVendorId}
          companyMaterialId={companyMaterialId}
        />
      }
      arrowPointAtCenter={false}
      placement="bottomRight"
      onOpenChange={() =>
        trackEvent(Events.HOVER_PRICING_HISTORY, window.location.pathname, {
          material: companyMaterialDescription,
          value: unitCost,
          material_id: companyMaterialId,
          vendor_id: companyVendorId,
        })
      }
    >
      <HistoryOutlined />
    </Popover>
  )
}
