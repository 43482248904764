import React, { useState, useCallback, useEffect } from 'react'

import { DeleteOutlined } from '@ant-design/icons'
import { Button, Form, Input, Select, Row, Col, InputNumber, Popconfirm, Tooltip, Typography } from 'antd'

import { observer } from 'mobx-react-lite'

import { DraggableList, DraggableItem } from 'common/components/DraggableList'
import { Loading } from 'common/components/Loading'
import { useQuery } from 'common/hooks/use-query'
import { InvoicesStates } from 'common/server/server_types'

import { SelectUser } from 'contractor/components/SelectUser'
import { useStores } from 'contractor/hooks/use-stores'

import { ColorInput } from './color_input'
import { useInvoiceSettings } from './context'

const blockedItems = [
  InvoicesStates.UNREVIEWED,
  InvoicesStates.APPROVED,
  InvoicesStates.REJECTED,
  InvoicesStates.POSTED,
]

export const Workflow = observer(() => {
  const { companySettingStore, invoiceStateStore, projectInvoiceAssigneeStore } = useStores()

  useQuery(companySettingStore.indexUsers)
  const { isLoading } = useQuery(invoiceStateStore.getAllInvoiceStates)

  const { form } = useInvoiceSettings()
  const invoiceStatesField = Form.useWatch('invoiceStates', form) || []

  const [canDrag, setCanDrag] = useState(true)

  const moveItem = useCallback((dragIndex: number, hoverIndex: number) => {
    const allInvoiceStates = form
      .getFieldValue('invoiceStates')
      ?.map((state) => {
        if (state?.position === dragIndex) {
          return {
            ...state,
            position: hoverIndex,
          }
        }

        if (state?.position === hoverIndex) {
          return {
            ...state,
            position: dragIndex,
          }
        }

        return state
      })
      .sort((a, b) => a?.position - b?.position)

    const initialState = allInvoiceStates.find((state) => state?.state === InvoicesStates.UNREVIEWED)
    const finalState = allInvoiceStates.find((state) => state?.state === InvoicesStates.POSTED)
    const middleStates = allInvoiceStates.filter(
      (state) => state?.state !== InvoicesStates.UNREVIEWED && state?.state !== InvoicesStates.POSTED,
    )

    form.setFieldsValue({ invoiceStates: [initialState, ...middleStates, finalState] })
  }, [])

  useEffect(() => {
    const allInvoiceStates = [...(invoiceStateStore.invoiceStates || [])].map((setting) => {
      if (setting?.not_change_assignee) {
        return {
          ...setting,
          assigned_to_id: 'not_change_assignee',
        }
      }

      if (setting?.project_invoice_assignee_id) {
        return {
          ...setting,
          assigned_to_id: setting?.project_invoice_assignee_id,
        }
      }

      return setting
    })
    const initialState = allInvoiceStates.find((state) => state?.state === InvoicesStates.UNREVIEWED)
    const finalState = allInvoiceStates.find((state) => state?.state === InvoicesStates.POSTED)
    const middleStates = allInvoiceStates.filter(
      (state) => state?.state !== InvoicesStates.UNREVIEWED && state?.state !== InvoicesStates.POSTED,
    )

    form.setFieldsValue({ invoiceStates: [initialState, ...middleStates, finalState] })
  }, [invoiceStateStore.invoiceStates.length])

  if (isLoading) {
    return <Loading />
  }

  if (!invoiceStateStore.invoiceStates?.length) {
    return null
  }

  const invoiceAssigneeSlotsOptions = (projectInvoiceAssigneeStore.projectInvoiceAssignees || []).map(
    (projectInvoiceAssignee) => ({
      label: `Project ${projectInvoiceAssignee.name}`,
      value: projectInvoiceAssignee.id,
    }),
  )

  return (
    <>
      <Typography.Title level={5}>Workflow</Typography.Title>
      <Typography.Paragraph>Configure which states you&apos;d like to have in your workflow</Typography.Paragraph>

      <Form.List name="invoiceStates">
        {(fields, { add, remove }) => (
          <>
            <DraggableList>
              {fields.map(({ key, name }, index) => {
                const blockedToDelete = blockedItems.includes(invoiceStatesField[index]?.state)

                // Can't drag first and last rows
                const blockedToDrag = index === 0 || fields.length - 1 === index

                const nextStatesGrid = blockedToDelete
                  ? { xs: 24, xxl: 13 }
                  : { xs: 24, sm: 21, md: 22, lg: 22, xl: 23, xxl: 12 }

                return (
                  <DraggableItem
                    key={key}
                    moveItem={moveItem}
                    index={index}
                    id={key}
                    // Initial and final state is blocked to reorder
                    canDrag={canDrag && !blockedToDrag}
                  >
                    <Form.Item name={[name, 'state']} hidden>
                      <Input />
                    </Form.Item>
                    <Form.Item name={[name, 'id']} hidden>
                      <Input />
                    </Form.Item>
                    <Form.Item name={[name, 'position']} hidden>
                      <InputNumber />
                    </Form.Item>

                    <Row key={key} gutter={20} align="middle">
                      <Col xs={24} sm={20} md={8} lg={8} xl={8} xxl={5}>
                        <Form.Item
                          label="Status"
                          name={[name, 'label']}
                          rules={[{ required: true, message: 'Status is required' }]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>

                      <Col xs={24} sm={4} md={2} lg={2} xl={2} xxl={1}>
                        <Form.Item label="Color" name={[name, 'color']}>
                          <ColorInput onChangeState={(isOpen) => setCanDrag(!isOpen)} />
                        </Form.Item>
                      </Col>

                      <Col xs={24} sm={24} md={14} lg={14} xl={14} xxl={5}>
                        <Form.Item
                          label="Assignee"
                          name={[name, 'assigned_to_id']}
                          tooltip="When the invoice reaches this state, it will be automatically assigned to this person."
                        >
                          <SelectUser
                            showAssignToMe={false}
                            concatOptions={(options) => [
                              { label: 'Do not change assignee', value: 'not_change_assignee' },
                              ...invoiceAssigneeSlotsOptions,
                              ...options,
                            ]}
                            loading={isLoading}
                          />
                        </Form.Item>
                      </Col>

                      <Col {...nextStatesGrid}>
                        <Form.Item
                          label="Allowed Next Status"
                          name={[name, 'allowed_next_state_ids']}
                          tooltip="Build your customized invoice workflow and choose allowed next steps."
                        >
                          <Select
                            mode="multiple"
                            allowClear
                            options={[...(invoiceStateStore.invoiceStates || [])].map((setting) => ({
                              value: setting?.id,
                              label: setting?.label,
                            }))}
                          />
                        </Form.Item>
                      </Col>

                      {!blockedToDelete && (
                        <Col xs={24} sm={3} md={2} lg={2} xl={1} xxl={1}>
                          <Popconfirm
                            placement="topRight"
                            title="Are you sure to delete this state?"
                            onConfirm={() => remove(name)}
                          >
                            <Button danger block icon={<DeleteOutlined />} style={{ minWidth: 32, marginTop: 5 }} />
                          </Popconfirm>
                        </Col>
                      )}
                    </Row>
                  </DraggableItem>
                )
              })}
            </DraggableList>

            <Tooltip
              placement="topRight"
              title="When adding a new status remember to click Save Changes. This will allow your new status to be assigned as an 'Allowed Next Status'."
            >
              <Button
                type="primary"
                onClick={() => {
                  const invoiceStates = invoiceStatesField.filter(
                    (state) => state?.state !== InvoicesStates.UNREVIEWED && state?.state !== InvoicesStates.POSTED,
                  )
                  const position = invoiceStates.length + 1
                  add(
                    {
                      label: '',
                      color: 'gray',
                      assigned_to_id: 'not_change_assignee',
                      allowed_next_state_ids: [],
                      position,
                    },
                    position,
                  )
                }}
              >
                Add Status
              </Button>
            </Tooltip>
          </>
        )}
      </Form.List>
    </>
  )
})
