import React from 'react'

import { connectToggleRefinement } from 'react-instantsearch-dom'

import { Switch } from 'antd'

import { Box } from 'common/components/boxes'

const ToggleRefinement = ({ currentRefinement, refine, label }) => {
  return (
    <Box display="inline-flex">
      {label}
      <Switch style={{ marginLeft: 5 }} checked={currentRefinement} onChange={() => refine(!currentRefinement)} />
    </Box>
  )
}

export default connectToggleRefinement(ToggleRefinement)
