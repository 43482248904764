import React from 'react'

import { Select, Typography, Divider } from 'antd'
import { SelectProps, DefaultOptionType } from 'antd/lib/select'

import { observer } from 'mobx-react-lite'

import { Box, BoxProps } from 'common/components/boxes'
import { formatName } from 'common/helpers/formatters'

import { useStores } from 'contractor/hooks/use-stores'

type SelectUserProps = {
  onlyActives?: boolean
  showAssignToMe?: boolean
  showAssignToMeOnDropdown?: boolean
  boxProps?: BoxProps
  concatOptions?: (options: DefaultOptionType[]) => DefaultOptionType[]
} & SelectProps

export const SelectUser = observer<SelectUserProps>(
  ({ onlyActives = false, showAssignToMe = true, boxProps, concatOptions, showAssignToMeOnDropdown, ...props }) => {
    const { companySettingStore, userStore } = useStores()

    const currentUser = userStore.currentUser

    const users = onlyActives
      ? companySettingStore.possibleUsers.filter((user) => user.is_active)
      : companySettingStore.possibleUsers

    const options = users.map((user) => {
      const name = formatName(user?.first_name, user?.last_name)
      return { value: user.id, label: name || user?.email }
    })

    return (
      <Box display="flex" flexDirection="column" width="100%" {...boxProps}>
        <Select
          style={{ width: '100%' }}
          filterOption={(input, option) => {
            const inputValue = input.toLowerCase()
            const label = option?.label as string
            return label?.toLowerCase().includes(inputValue)
          }}
          aria-autocomplete="none"
          showSearch
          allowClear
          options={concatOptions ? concatOptions(options) : options}
          dropdownRender={(menu) => {
            if (showAssignToMeOnDropdown && currentUser.id !== props?.value) {
              return (
                <>
                  <Typography.Link
                    onClick={() =>
                      props?.onChange?.(currentUser?.id, {
                        value: currentUser?.id,
                        label: formatName(currentUser?.first_name, currentUser?.last_name),
                      })
                    }
                    style={{ padding: '0 12px' }}
                  >
                    Assign to me
                  </Typography.Link>
                  <Divider style={{ margin: '8px 0' }} />
                  {menu}
                </>
              )
            }

            return menu
          }}
          {...props}
        />

        {showAssignToMe && currentUser.id !== props?.value && (
          <Typography.Link
            onClick={() =>
              props?.onChange?.(currentUser?.id, {
                value: currentUser?.id,
                label: formatName(currentUser?.first_name, currentUser?.last_name),
              })
            }
          >
            Assign to me
          </Typography.Link>
        )}
      </Box>
    )
  },
)
