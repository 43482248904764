import React from 'react'

import { StyledTag } from 'common/components/AlgoliaTagsFilter/styles'
import { FlexBoxX } from 'common/components/boxes'
import { UserRoles } from 'common/server/server_types'

type UserRibbonsFiltersProps = {
  onChange: (filter: string) => void
  filter: string
}

export const UsersRibbonFilter = ({ onChange, filter }: UserRibbonsFiltersProps) => {
  const roles = [
    { label: 'All', filter: '' },
    { label: 'Admin', filter: UserRoles.ADMIN },
    { label: 'Purchaser', filter: UserRoles.PURCHASER },
    { label: 'User', filter: UserRoles.USER },
    { label: 'Material Requester', filter: UserRoles.MATERIAL_REQUESTER },
  ]

  return (
    <FlexBoxX justifyContent="start" maxWidth="100%">
      {roles?.map((role) => {
        const isChecked = role.filter === filter

        return (
          <StyledTag
            data-cy={`${role.label}-filter`}
            key={role.label}
            checked={isChecked}
            onChange={() => {
              onChange(role.filter)
            }}
          >
            {role.label}
          </StyledTag>
        )
      })}
    </FlexBoxX>
  )
}
