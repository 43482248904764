import React from 'react'

import { Typography } from 'antd'

import { Box } from 'common/components/boxes'

import DownloadMenu from 'contractor/pages/Materials/download'

interface MaterialsDownloadProps {
  filters: string
}

export const MaterialsDownload = (props: MaterialsDownloadProps) => {
  const { filters } = props
  return (
    <Box p={12} display="flex" flexDirection="column">
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography.Title level={5} style={{ marginRight: '24px', marginBottom: 0 }}>
          Download
        </Typography.Title>
        <DownloadMenu filters={filters} />
      </Box>
    </Box>
  )
}
