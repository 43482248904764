import React, { useState } from 'react'

import { PlusOutlined } from '@ant-design/icons'
import { Alert, Button, Divider, Typography } from 'antd'

import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'

import { Box } from 'common/components/boxes'
import { OrderMaterialSpreadsheetFooter } from 'common/components/OrderMaterialsSpreadsheet'
import { ButtonToggleSpreadsheet, OrderMaterials } from 'common/components/OrderMaterialsV2'
import { OrderMaterialTabs } from 'common/components/OrderMaterialTabs'
import { Visibility } from 'common/components/Visibility'
import { formatEnumValue } from 'common/helpers/formatters'
import { calcOrderTaxSplitBulk, roundValue } from 'common/helpers/order'
import { emptyOrderDelivery } from 'common/server/deliveries'
import { OrderStates } from 'common/server/server_types'

import { DeliveryInfo } from 'contractor/components/OrderDeliveries'
import { getDeliveryPickUpTitle } from 'contractor/helpers/get-delivery-pick-up-title'
import { useStores } from 'contractor/hooks/use-stores'

import MaterialsTable from './materials_table'

export const MaterialsAndDeliveries = observer(
  ({ onDirty, isPoLocked }: { isPoLocked?: boolean; onDirty?: () => void }) => {
    const { orderStore, userStore, companySettingStore, costCodeStore, unitsStore } = useStores()

    const [isSpreadsheetMode, toggleSpreadsheetMode] = useState(false)

    const { project_id, deliveries = [], company_vendor, state, commitment_id, project } = orderStore.selectedOrder

    const { company_attributes = [] } = companySettingStore.companyMaterialConfiguration
    const costCodeSettings = companySettingStore.otherSettings?.cost_code_settings
    const requiredOrderFields = companySettingStore.otherSettings?.required_order_fields
    const requiredQuoteFields = companySettingStore.otherSettings?.required_quote_fields
    const orderType = state === OrderStates.QUOTED ? 'RFQ' : 'Order'
    const showDeleteItem =
      orderStore?.selectedOrder?.requested_by_id == userStore?.currentUser?.id || !userStore?.cannotSendAndUpdateOrders

    const editDisabled = userStore.cannotSendAndUpdateOrders || isPoLocked
    const editCostCodeDisabled = (userStore.cannotEditCostCode && userStore.cannotSendAndUpdateOrders) || isPoLocked

    const { costCodePhaseListStore } = costCodeStore

    const handleAddDelivery = () => {
      const newDelivery = emptyOrderDelivery()
      deliveries.push(newDelivery)
      orderStore.addEmptyOrderMaterials(newDelivery.id, 5)
      orderStore.setOrderDirty()
    }

    const handleRemoveDelivery = (index: string) => {
      deliveries.splice(parseInt(index, 10), 1)
      orderStore.setOrderDirty()
    }

    const handleAdd = () => {
      const button = document.getElementsByClassName('aa-DetachedSearchButton')[0] as HTMLElement
      const input = document.getElementsByClassName('aa-Input')[0] as HTMLElement
      button?.click()
      input?.focus()
    }

    let deliveryCount = 0
    let pickUpCount = 0

    return (
      <OrderMaterialTabs
        onDuplicate={(deliveryIndex) => orderStore.duplicateDelivery(deliveryIndex, deliveries)}
        onAdd={handleAddDelivery}
        disabled={editDisabled}
        onRemove={handleRemoveDelivery}
        deliveriesCount={deliveries.length}
        items={deliveries?.map((delivery, index) => {
          delivery.is_pick_up ? pickUpCount++ : deliveryCount++

          const title = getDeliveryPickUpTitle({
            requestedDeliveredAt: delivery.requested_delivered_at,
            isPickUp: delivery.is_pick_up,
            pickUpCount,
            deliveryCount,
          })

          const dataSource = orderStore
            .getOrderMaterialsByDeliveryId(delivery.id)
            .filter((data) => !!data.company_material?.description)

          return {
            title,
            label: <OrderMaterialTabs.TabPaneTitle title={title} isPickUp={delivery.is_pick_up} delivery={delivery} />,
            key: `${index}`,
            closable: deliveries.length > 1,
            children: (
              <>
                {delivery?.has_open_issue && (
                  <Alert
                    closable
                    message={`${formatEnumValue(delivery?.delivery_issue_type || '')}: ${
                      delivery?.delivery_issues || ''
                    }`}
                    type="error"
                    style={{ marginBottom: 12 }}
                  />
                )}
                <DeliveryInfo
                  project_ids={[project_id]}
                  disabled={isPoLocked || !userStore.canEditDeliveryInformation}
                  delivery={delivery}
                  showDetails
                  companyVendorId={company_vendor?.id}
                />
                {isSpreadsheetMode ? (
                  <MaterialsTable
                    delivery_id={delivery.id}
                    costCodeDisabled={editCostCodeDisabled}
                    isPoLocked={isPoLocked}
                  />
                ) : (
                  <>
                    <Box display={{ _: 'flex', xs: 'none' }} width="100%" flexDirection="column">
                      <Box width="100%" display="flex" justifyContent="space-between">
                        <Typography.Title level={5} style={{ margin: 0 }}>
                          Materials
                        </Typography.Title>
                        <Button icon={<PlusOutlined />} onClick={handleAdd} size="small" />
                      </Box>

                      <Divider style={{ margin: '12px 0' }} />
                    </Box>

                    <OrderMaterials
                      showDefaultVendor={company_attributes.includes('preferred_vendor_prices')}
                      projectId={project_id}
                      commitmentId={commitment_id}
                      companyVendorId={company_vendor?.id}
                      orderType={orderType}
                      dataSource={dataSource}
                      onChange={(newOrderMaterials) => {
                        if (
                          newOrderMaterials.some((material) => !!material?.tax_value) &&
                          project?.tax_line_items?.enabled
                        ) {
                          const totalTaxSumFromOrderMaterials = newOrderMaterials.reduce(
                            (acc, material) => acc + (Number(material?.tax_value) || 0),
                            0,
                          )

                          delivery.tax_value = roundValue(totalTaxSumFromOrderMaterials)
                        }

                        orderStore.updateOrderMaterialsByDeliveryId(delivery.id, newOrderMaterials)
                        onDirty?.()
                      }}
                      isSelecting={orderStore.isSplitting}
                      hideCostCode={!company_attributes?.includes('cost_code_id')}
                      hideTax={!project?.tax_line_items?.enabled}
                      deliveryId={delivery.id}
                      disabled={orderStore.isSplitting || editDisabled}
                      showMaterialPriceDetails={userStore.canViewHistoricalPricing}
                      unitCostInput={{ hideDetails: !userStore.canUseHistoricalCost }}
                      roundingPrecision={
                        companySettingStore.otherSettings?.rounding_precision_settings?.order_precision
                      }
                      costCodeInput={{
                        costCodes: toJS(costCodeStore.costCodeListStore.records),
                        projectId: project_id,
                        costCodeSettings,
                      }}
                      vendorResponseInput={{ edit: false }}
                      unitInput={{ units: unitsStore.units, disabled: !userStore.canCreateNewMaterial }}
                      units={unitsStore.units}
                      phaseCodeInput={{
                        phaseCodes: toJS(costCodePhaseListStore.records),
                        costCodeSettings,
                        projectId: project_id,
                      }}
                      requiredFields={{
                        order: requiredOrderFields,
                        quote: requiredQuoteFields,
                      }}
                      costCodeSettings={costCodeSettings}
                      canEditMaterialDatabase={userStore.canEditMaterialDatabase}
                      canViewHistoricalPricing={userStore.canViewHistoricalPricing}
                      canCreateNewMaterial={userStore.canCreateNewMaterial && !commitment_id}
                      showDeleteItem={showDeleteItem}
                      userId={userStore.currentUser?.id}
                      orderState={orderStore?.selectedOrder?.state}
                      costCodeDisabled={editCostCodeDisabled}
                    />
                    <OrderMaterialSpreadsheetFooter
                      index={index}
                      disabled
                      costsDisabled={editDisabled}
                      isPoLocked={isPoLocked}
                      deliveryChargesUnits={delivery.delivery_charges_units}
                      onChangeChargesUnit={(value) => (delivery.delivery_charges_units = value)}
                      hideAddTotal={userStore.cannotSendAndUpdateOrders || editDisabled}
                      deliveryTotalCost={orderStore.deliveryTotalCost({
                        deliveryId: delivery.id,
                        deliveries,
                        precision: companySettingStore.otherSettings?.rounding_precision_settings?.order_precision,
                      })}
                      orderMaterialsTotalCost={orderStore.materialsTotalCost(
                        orderStore.getOrderMaterialsByDeliveryId(delivery.id),
                      )}
                      onChange={(value) => {
                        delivery.discount_value = value.discountValue
                        delivery.shipping_value = value.shippingValue
                        delivery.other_value = value.otherValue
                        delivery.tax_value = value.taxValue

                        if (project?.tax_line_items?.enabled) {
                          const newOrderMaterials = calcOrderTaxSplitBulk({
                            orderMaterials: dataSource,
                            taxAmount: value.taxValue,
                            taxLineItemsEnabled: true,
                            precision: companySettingStore.otherSettings?.rounding_precision_settings?.order_precision,
                          })
                          orderStore.updateOrderMaterialsByDeliveryId(delivery.id, newOrderMaterials)
                        }

                        orderStore.setOrderDirty()
                      }}
                      roundingPrecision={
                        companySettingStore.otherSettings?.rounding_precision_settings?.order_precision
                      }
                      value={{
                        discountValue: delivery?.discount_value,
                        shippingValue: delivery?.shipping_value,
                        otherValue: delivery?.other_value,
                        taxValue: delivery?.tax_value,
                      }}
                    />
                  </>
                )}
                <Visibility.Hidden>
                  <ButtonToggleSpreadsheet
                    disabled={editDisabled}
                    onClick={() => toggleSpreadsheetMode((prev) => !prev)}
                    data-cy={isSpreadsheetMode ? `is-spread-sheet-${index}` : `spread-sheet-${index}`}
                    isSpreadsheetMode={isSpreadsheetMode}
                  />
                </Visibility.Hidden>
              </>
            ),
          }
        })}
      />
    )
  },
)
