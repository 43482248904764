import React from 'react'

import { Empty, Typography } from 'antd'

import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'

import { Box } from 'common/components/boxes'
import { Loading } from 'common/components/Loading'
import { Page } from 'common/components/Page'
import { PsqlTable } from 'common/components/PsqlTable'
import { ResponsiveTable } from 'common/components/ResponsiveTable'
import { RibbonFilter } from 'common/components/RibbonFilter'
import { SearchInput } from 'common/components/SearchInput'
import { usePersistentFilters } from 'common/contexts/persistent_filters'
import { useMaybeOpenIntoNewTab } from 'common/hooks/use-maybe-open-into-new-tab'
import { usePushToOrder } from 'common/hooks/use-push-to-order'
import { useQuery } from 'common/hooks/use-query'
import { OrderStatesFilter, OrderSubStates } from 'common/server/server_types'

import ProjectSelector from 'contractor/components/ProjectSelector/project_selector'
import { useStores } from 'contractor/hooks/use-stores'
import { OrderMaterialMobileItem } from 'contractor/pages/@v2/OrderMaterials/components/order_material_mobile_item'
import { ConsolidatedOrdersMaterials } from 'contractor/server/order_materials'
import { ShowProjectResponse } from 'contractor/server/projects'

import { OrderMaterialsOptions } from './components/Options/order_materials_options'

export function Content() {
  const { orderMaterialStore, userStore, notificationStore, companySettingStore } = useStores()

  const { getUrl } = usePushToOrder()
  const { maybeOpenIntoNewTab } = useMaybeOpenIntoNewTab()
  const persistentFilters = usePersistentFilters()

  function handleChangeProject(project: ShowProjectResponse) {
    persistentFilters.handleChangeFilters({ project_id: project?.id })
  }

  async function init() {
    const initialProjectId =
      new URLSearchParams(location.search).get('project_id') || sessionStorage['selectedProjectId']

    if (initialProjectId && initialProjectId.length > 0) {
      orderMaterialStore.listStore.setFilter('project_id', initialProjectId, true, true)
    }

    return persistentFilters.init()
  }

  useQuery(orderMaterialStore.listStore.fetchFacets)
  const { isLoading = true } = useQuery(() => init(), [])

  function pushToOrder({ row }: { row: ConsolidatedOrdersMaterials.OrderMaterial }) {
    const url = getUrl({
      orderId: row.order_id,
      orderPackageId: row.order_package_id,
      state: row.order_state,
      subState: row.order_sub_state,
    })

    maybeOpenIntoNewTab(url)
  }

  const ribbonOptions = React.useMemo(
    () => [
      { label: 'All', filter: '' },
      { label: 'Requested', filter: OrderStatesFilter.REQUESTED },
      { label: 'Drafted', filter: OrderStatesFilter.DRAFT },
      ...(userStore.canUseCreateRFQ
        ? [
            { label: 'Quote Requested', filter: OrderSubStates.QUOTED_REQUESTED },
            { label: 'Quote Provided', filter: OrderSubStates.QUOTED_RECEIVED },
          ]
        : []),
      { label: 'Order Placed', filter: OrderSubStates.ORDERED_REQUESTED },
      { label: 'Order Confirmed', filter: OrderSubStates.ORDERED_CONFIRMED },
      {
        label: 'Shipped',
        filter: OrderStatesFilter.SHIPPED,
      },
      { label: 'Delivered', filter: OrderStatesFilter.DELIVERED },
      { label: 'Cancelled', filter: OrderStatesFilter.CANCELLED, ignoreWhenCountingAll: true },
    ],
    [userStore],
  )

  function handleSearch(value: string) {
    persistentFilters.handleChangeFilters({ search: value }, false)
  }

  const renderMobileCard = React.useCallback(
    (item: ConsolidatedOrdersMaterials.OrderMaterial) => {
      const notificationCount = notificationStore.notificationCountByObject(item.id)

      return (
        <OrderMaterialMobileItem
          orderId={item.order_id}
          orderPackageId={item.order_package_id}
          orderState={item.order_state}
          orderSubState={item.order_sub_state}
          orderNumber={item.order_number}
          orderName={item.order_name}
          notificationCount={notificationCount}
          companyMaterialDescription={item.company_material_description}
          companyMaterialQuantity={item.quantity}
          companyMaterialProductIdentifier={item.company_material_product_identifier}
          companyMaterialSize={item.company_material_size}
          projectName={item.project_name}
          deliveryIssueType={item.delivery_issue_type}
          deliveryHasOpenIssue={item.delivery_has_open_issue}
        />
      )
    },
    [notificationStore],
  )

  const shouldShowInitialLoading =
    orderMaterialStore.listStore.isFetching &&
    orderMaterialStore.listStore.searchState.page === 1 &&
    orderMaterialStore.listStore.records.length === 0

  if (isLoading) return <Loading />

  return (
    <Page>
      <Page.Header display="grid" gridGap={16}>
        <Box display="flex" gridGap={8} alignItems="center" justifyContent="space-between">
          <Box display="flex" justifyContent="space-between">
            <ProjectSelector showSettings showAddProject onChange={handleChangeProject} />
          </Box>

          <SearchInput value={orderMaterialStore.listStore.searchState.search} onSearch={handleSearch} />

          <OrderMaterialsOptions
            phaseCodeEnabled={true}
            hasShowCostCodeFilter={true}
            facets={orderMaterialStore.listStore.facets}
            values={orderMaterialStore.listStore.searchState.filters}
            fetchAllRecords={orderMaterialStore.listStore.fetchAllRecords}
            companyAttributes={companySettingStore.companyAttributes}
          />
        </Box>

        <Box width="100%" style={{ overflow: 'auto' }}>
          <RibbonFilter
            showCounts
            value={orderMaterialStore.listStore.searchState.filters['order_state'] || []}
            counts={orderMaterialStore.listStore.stateCounts || {}}
            onChange={(value) => persistentFilters.handleChangeFilters({ order_state: value })}
            options={ribbonOptions}
            boxProps={{ overflowX: 'auto' }}
          />
        </Box>
      </Page.Header>

      <Page.Content p={0} px={{ _: 0, md: 12, lg: 16 }} pb={24} pt={{ _: 0, md: 16 }} height="100%">
        {shouldShowInitialLoading ? (
          <Loading />
        ) : orderMaterialStore.listStore.records.length === 0 ? (
          <Box
            px={{ _: 12, lg: 24 }}
            pb={{ _: 12, lg: 24 }}
            height="100%"
            width="100%"
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
          >
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={
                <Typography.Title level={5} style={{ textAlign: 'center' }}>
                  Sorry! No Items were found with these search criteria.
                </Typography.Title>
              }
            />
          </Box>
        ) : (
          <ResponsiveTable
            Table={PsqlTable}
            data={toJS(orderMaterialStore.listStore.records) ?? []}
            tableName="AllOrders"
            hasMore={orderMaterialStore.listStore.hasMore}
            loadMore={orderMaterialStore.listStore.fetchNextPage}
            onLoadMore={orderMaterialStore.listStore.fetchNextPage}
            renderMobileCard={renderMobileCard}
            onClickRow={pushToOrder}
          />
        )}
      </Page.Content>
    </Page>
  )
}

export const OrdersMaterialsContent = observer(Content)
