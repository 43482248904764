import React, { useState, createContext, useContext, useEffect } from 'react'

import { Form, FormInstance } from 'antd'

import { observer } from 'mobx-react-lite'

import { Loading } from 'common/components/Loading'
import { useQuery } from 'common/hooks/use-query'

import { useStores } from 'contractor/hooks/use-stores'
import { CompanyVendorContact } from 'contractor/server/company_vendor_contacts'

const PrivateOrderFormContext = createContext<PrivateOrderFormContextProps>({} as PrivateOrderFormContextProps)

export type FormValues = {
  projectId: string
  orderPackageName: string
  companyVendorContact: {
    label: string
    value: string
    contact: CompanyVendorContact
  }
  address: string
  addressToId: string
  requestedDeliveredAt: string
  watchers: Array<{ value: string }>
}

type PrivateOrderFormContextProps = {
  isSubmitting: boolean
  setSubmitting: React.Dispatch<React.SetStateAction<boolean>>
  projectId: string
  form: FormInstance
}

type PrivateOrderFormProviderProps = {
  children: React.ReactNode
}

export const usePrivateOrderForm = () => useContext(PrivateOrderFormContext)

export const PrivateOrderFormProvider = observer(({ children }: PrivateOrderFormProviderProps) => {
  const { projectStore, orderStore, addressStore, companySettingStore, costCodeStore, unitsStore, addressToStore } =
    useStores()

  const [isSubmitting, setSubmitting] = useState(false)

  const [form] = Form.useForm<FormValues>()
  const projectId = Form.useWatch('projectId', form)

  const { costCodePhaseListStore } = costCodeStore

  const { isLoading } = useQuery(() => {
    return Promise.all([
      projectStore.indexProjects(),
      addressStore.indexAddresses(),
      companySettingStore.indexCompanyMaterialConfiguration(),
      companySettingStore.maybeIndexUsers(),
      costCodeStore.fetchAllActiveCostCodes(),
      costCodePhaseListStore.fetchRecords(),
      unitsStore.maybeUnits(),
      addressToStore.indexAddressesTo(),
    ])
  })

  useEffect(() => {
    orderStore.clearOrder(10)
  }, [])

  if (isLoading) {
    return <Loading />
  }

  return (
    <PrivateOrderFormContext.Provider value={{ isSubmitting, setSubmitting, projectId, form }}>
      {children}
    </PrivateOrderFormContext.Provider>
  )
})

export const withPrivateOrderFormProvider = (Component) => (props) => {
  return (
    <PrivateOrderFormProvider>
      <Component {...props} />
    </PrivateOrderFormProvider>
  )
}
