import React, { useEffect, useState } from 'react'

import { Button, Divider, Typography } from 'antd'

import { Box } from 'common/components/boxes'
import { useTheme } from 'common/hooks/use-theme'

import { useStores } from 'contractor/hooks/use-stores'

export const ClientLogs = ({ showUpdateButton, lastUpdate }) => {
  const theme = useTheme()
  const { integrationStore } = useStores()
  const [lastActions, setLastActions] = useState([])

  useEffect(() => {
    integrationStore.client_last_actions_list().then((res) => {
      setLastActions(Object.entries(res))
    })
  }, [integrationStore.syncingProgress?.init_data_progress, lastUpdate])

  const updateLogs = () => {
    integrationStore.client_last_actions_list().then((res) => {
      setLastActions(Object.entries(res))
    })
  }

  const getIntegrationLogColor = (message) => {
    if (/\bresponse: 4\d{2}\b|\bresponse: 5\d{2}\b|\bstatus 4\d{2}\b|\bstatus 5\d{2}\b|\bError\b/.test(message)) {
      return theme.colors['red-7']
    }
    return theme.colors['green-7']
  }

  const getIsResponse = (message) => {
    return /response:|completed:/.test(message)
  }

  const RequestArrow = () => {
    return (
      <svg width="20" height="20" viewBox="0 0 24 24" fill="none">
        <path d="M4 12L20 12" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M14 6L20 12L14 18" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
    )
  }

  const ResponseArrow = () => {
    return (
      <svg width="20" height="20" viewBox="0 0 24 24" fill="none">
        <path d="M20 12L4 12" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M10 6L4 12L10 18" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
    )
  }

  return (
    <>
      {lastActions.length > 0 && (
        <Box height="350px">
          <Divider />
          <Box width="100%" display="flex" justifyContent="center" alignItems="center">
            <Typography.Title level={5} style={{ margin: 10 }}>
              Last integration activities {lastUpdate ? `${lastUpdate}` : ''}
            </Typography.Title>

            {showUpdateButton && (
              <Button onClick={updateLogs} size="small">
                Update
              </Button>
            )}
          </Box>

          <Box width="100%" display="flex" flexDirection="column" overflowY="auto" height="100%">
            {lastActions.map((entry, index) => (
              <Box key={index} width="100%" display="flex" justifyContent="flex-start">
                <Typography.Text style={{ color: theme.colors['gray-7'], marginRight: 10 }}>{entry[0]}</Typography.Text>

                {getIsResponse(entry[1]) ? <ResponseArrow /> : <RequestArrow />}

                <Typography.Text style={{ color: getIntegrationLogColor(entry[1]), marginLeft: 10 }}>
                  {entry[1]}
                </Typography.Text>
              </Box>
            ))}
          </Box>
        </Box>
      )}
    </>
  )
}
