import React from 'react'

import { QuestionCircleOutlined } from '@ant-design/icons'
import { Popover, Space, Tooltip, Typography } from 'antd'

import { toJS } from 'mobx'

import { Box } from 'common/components/boxes'
import {
  EditableCellSelectCostCode,
  EditableCellSelectPhaseCode,
  EditableTableProps,
} from 'common/components/EditableTable'
import { InputInvoiceMaterialPercentage } from 'common/components/InputCurrencyPercentage/InvoiceMaterial/input_invoice_material_percentage'
import { InvoiceMaterial } from 'common/server/invoice'

import { useFlag } from 'contractor/hooks/use-flag'
import { usePopulateSameCostCode } from 'contractor/hooks/use-populate-same-cost-code'
import { useStores } from 'contractor/hooks/use-stores'

import { useInvoice } from '../context'

export const getExpandablecolumns = () => {
  const { userStore, companySettingStore, costCodeStore, invoiceStore } = useStores()

  const { setSelectedInvoiceDirty, currencyFormatter, calcExtCost, taxLineItemsEnabled } = useInvoice()
  const canUseRetainage = useFlag('retainage_v1')

  const populateSameCostCode = usePopulateSameCostCode<InvoiceMaterial>({
    itemIdentifierKey: 'id',
  })

  const handleApplyCostCodeToAll = (args) => {
    const { index, value, path } = args

    const newMaterials = populateSameCostCode.handleBulkChange({
      triggerIndex: index,
      path,
      value,
      datasource: toJS(invoiceStore.invoice.invoice_materials),
    })

    invoiceStore.updateSelectedInvoice('invoice_materials', newMaterials)
  }

  const getItemByRowIndex = (invoiceMaterialId) => {
    const newInvoiceMaterials = [...invoiceStore.invoice.invoice_materials]
    const index = newInvoiceMaterials.findIndex((invoiceMaterial) => invoiceMaterial.id === invoiceMaterialId)
    const item = newInvoiceMaterials[index]

    return { newInvoiceMaterials, item, index }
  }

  const handleChangeCallback = (newInvoiceMaterials = []) => {
    invoiceStore.updateSelectedInvoice('invoice_materials', newInvoiceMaterials)
    setSelectedInvoiceDirty(true)
  }

  const handleChangeCostCode = (costCodeValue, invoiceMaterial, shouldPropagateValue) => {
    const { newInvoiceMaterials, item, index } = getItemByRowIndex(invoiceMaterial.id)

    const newCostCodeValue = costCodeValue?.costCode || costCodeValue
    newInvoiceMaterials.splice(index, 1, {
      ...item,
      cost_code: newCostCodeValue,
    })

    const path = 'cost_code'

    if (shouldPropagateValue) {
      const newInvoiceMaterialsWithCostCodes = populateSameCostCode.handleBulkChange({
        triggerIndex: index,
        path,
        value: newCostCodeValue,
        datasource: toJS(newInvoiceMaterials),
      })

      handleChangeCallback(newInvoiceMaterialsWithCostCodes)
    } else {
      if (populateSameCostCode.hasItemBeenChangedByAppliedToAll(path, invoiceMaterial.id)) {
        populateSameCostCode.removedByAppliedToAll(path, invoiceMaterial.id)
      }
      handleChangeCallback(newInvoiceMaterials)
    }
  }

  const handleChangePhaseCode = (phaseCodeId, invoiceMaterial, shouldPropagateValue) => {
    const { newInvoiceMaterials, item, index } = getItemByRowIndex(invoiceMaterial.id)
    newInvoiceMaterials.splice(index, 1, {
      ...item,
      cost_code_phase_id: phaseCodeId,
    })

    const path = 'cost_code_phase_id'

    if (shouldPropagateValue) {
      const newInvoiceMaterialsWithCostCodes = populateSameCostCode.handleBulkChange({
        triggerIndex: index,
        path,
        value: phaseCodeId,
        datasource: toJS(newInvoiceMaterials),
      })

      handleChangeCallback(newInvoiceMaterialsWithCostCodes)
    } else {
      if (populateSameCostCode.hasItemBeenChangedByAppliedToAll(path, invoiceMaterial.id)) {
        populateSameCostCode.removedByAppliedToAll(path, invoiceMaterial.id)
      }
      handleChangeCallback(newInvoiceMaterials)
    }
  }

  const handleChangeRetainage = (value, type, percentage, invoiceId) => {
    const { newInvoiceMaterials, item, index } = getItemByRowIndex(invoiceId)
    newInvoiceMaterials.splice(index, 1, {
      ...item,
      retainage_amount: value,
      retainage_type: type,
      retainage_percentage: percentage,
    })
    handleChangeCallback(newInvoiceMaterials)
  }

  const { company_attributes = [] } = companySettingStore.companyMaterialConfiguration
  const costCodeSettings = companySettingStore.otherSettings?.cost_code_settings

  const expandableFixedFirstColumns: EditableTableProps<InvoiceMaterial>['columns'] = [
    {
      dataIndex: 'description',
      render: (description) => {
        return (
          <Typography.Paragraph ellipsis={{ rows: 2 }} style={{ margin: 0, minWidth: 72 }}>
            {description}
          </Typography.Paragraph>
        )
      },
    },
    {
      dataIndex: 'number',
      width: 150,
      render: (invoiceNumber, row) => {
        return (
          <Tooltip title="Invoice Number">
            <Typography.Link href={`/invoice/${row.invoice_id}`} target="_blank">
              #{invoiceNumber}
            </Typography.Link>
          </Tooltip>
        )
      },
    },
  ]

  const expandableColumnsToHide: EditableTableProps<InvoiceMaterial>['columns'] = [
    {
      dataIndex: 'quantity',
      width: 70,
      align: 'right',
      render: (_, row) => row?.quantity_shipped,
    },
    {
      dataIndex: 'unit',
      width: 100,
      render: (_, row) => row?.unit?.name || row?.uom,
    },
    {
      dataIndex: 'unit_cost',
      width: 100,
      align: 'right',
      render: (_, row) => (row?.unit_price ? currencyFormatter(row?.unit_price) : null),
    },
    ...((taxLineItemsEnabled
      ? [
          {
            title: 'Tax',
            dataIndex: 'tax_split',
            width: 100,
            align: 'right',
            render: (taxSplit, row) => {
              // Used for total row
              if (typeof row.tax === 'number') {
                return <Typography.Text>{currencyFormatter(row.tax)}</Typography.Text>
              }

              if (!row?.accepts_tax_split) {
                return <Typography.Text type="secondary">not applied</Typography.Text>
              }

              const extCost = calcExtCost({
                unitCost: row?.unit_price,
                quantity: row?.quantity_shipped,
                multiplier: row?.unit?.multiplier,
                qtyIncrement: row?.unit?.qty_increment,
              })

              const taxAmount = row['tax_amount'] || 0
              const eligibleTaxSplitTotal = row['eligible_tax_split_total'] || 0

              return (
                <Space>
                  <Typography.Text>{currencyFormatter(taxSplit)}</Typography.Text>
                  {Number(taxSplit) !== 0 && (
                    <Popover
                      content={
                        <Space direction="vertical">
                          <Typography.Text>Tax Calculation:</Typography.Text>
                          <Typography.Text strong>
                            ${taxAmount} * (${extCost} / ${eligibleTaxSplitTotal}) = ${taxSplit}
                          </Typography.Text>
                        </Space>
                      }
                    >
                      <QuestionCircleOutlined />
                    </Popover>
                  )}
                </Space>
              )
            },
          },
          {
            title: 'Ext. Cost + Tax',
            dataIndex: 'ext_cost_with_tax',
            width: 120,
            align: 'right',
            render: (extCostWithTax) => <Typography.Text>{currencyFormatter(extCostWithTax)}</Typography.Text>,
          },
        ]
      : []) as EditableTableProps<InvoiceMaterial>['columns']),
    {
      dataIndex: 'ordered',
      width: 100,
    },
    {
      dataIndex: 'invoiced',
      align: 'right',
      width: 100,
      render: (invoiced, row) => {
        if (invoiced) {
          return currencyFormatter(invoiced)
        }

        const extendedCost = calcExtCost({
          unitCost: row?.unit_price,
          quantity: row?.quantity_shipped,
          multiplier: row?.unit?.multiplier,
          qtyIncrement: row?.unit?.qty_increment,
        })

        return currencyFormatter(extendedCost)
      },
    },
    {
      dataIndex: 'remaining',
      align: 'right',
      width: 100,
    },
  ]

  if (userStore.canUseCostCode && company_attributes.includes('cost_code_id')) {
    const canShowPhase = costCodeSettings?.phase_code_enabled && !costCodeSettings.independent_phase_codes_enabled
    const canShowClass = costCodeSettings?.class_enabled

    const firstInvoiceOrder = [...invoiceStore.selectedOrders]?.[0]?.order
    const projectId = firstInvoiceOrder?.project_id || invoiceStore.invoice?.project?.id

    expandableColumnsToHide.push({
      title: 'Cost Code',
      dataIndex: 'cost_code',
      width: 200,
      render: (costCode) => {
        if (!costCode) return null

        return (
          <Typography.Text>
            {[
              canShowPhase ? costCode?.phase_code || 'N/A' : '',
              costCode?.code,
              canShowClass ? costCode?.clazz || 'N/A' : '',
            ]
              .filter((item) => !!item)
              .join('.')}
          </Typography.Text>
        )
      },
      // The user can only edit the cost code for invoice materials from the current invoice
      editable: (record) => {
        return record?.invoice_id === invoiceStore.invoice?.id && !['Tax', 'Total'].includes(record?.description)
      },
      renderEditable: (record, rowIndex) => {
        return (
          <EditableCellSelectCostCode
            onSave={(value, shouldPropagateValue) => handleChangeCostCode(value, record, shouldPropagateValue)}
            inputName="cost_code"
            costCodes={costCodeStore.costCodeListStore.records}
            costCodeSettings={costCodeSettings}
            projectId={projectId}
            autoFocus
            defaultApplyToAllChecked={record['default_cost_code_to_be_applied']}
            onChangeApplyToAll={(args) => {
              handleApplyCostCodeToAll({
                ...args,
                index: rowIndex,
              })
            }}
          />
        )
      },
    })

    if (costCodeSettings?.independent_phase_codes_enabled) {
      expandableColumnsToHide.push({
        title: 'Phase Code',
        dataIndex: 'cost_code_phase_id',
        width: 130,
        render: (costCodePhaseId) => {
          if (!costCodePhaseId) return null

          const costCodePhase = costCodeStore?.costCodePhaseListStore.records?.find(
            (costCodePhase) => costCodePhase.id === costCodePhaseId,
          )

          return <Typography.Text>{costCodePhase?.code}</Typography.Text>
        },
        // The user can only edit the phase code for invoice materials from the current invoice
        editable: (record) => {
          return record?.invoice_id === invoiceStore.invoice?.id && !['Tax', 'Total'].includes(record?.description)
        },
        renderEditable: (record, rowIndex) => {
          return (
            <EditableCellSelectPhaseCode
              onSave={(value, shouldPropagateValue) => handleChangePhaseCode(value, record, shouldPropagateValue)}
              inputName="cost_code_phase_id"
              phaseCodes={costCodeStore.costCodePhaseListStore.records}
              autoFocus
              costCodeSettings={costCodeSettings}
              projectId={projectId}
              placeholder={null}
              style={{ marginTop: 4 }}
              defaultApplyToAllChecked={record['default_cost_code_to_be_applied']}
              onChangeApplyToAll={(args) => {
                handleApplyCostCodeToAll({
                  ...args,
                  index: rowIndex,
                })
              }}
            />
          )
        },
      })
    }
  }

  if (canUseRetainage) {
    expandableColumnsToHide.push({
      dataIndex: 'remaining',
      align: 'right',
      width: 190,
      render: (_, record) => {
        if (record?.retainage_type === 'PERCENTAGE') {
          return (
            <Box style={{ whiteSpace: 'nowrap' }} display="flex" flexDirection="column">
              {record.retainage_percentage}%
            </Box>
          )
        }
        return (
          <Box style={{ whiteSpace: 'nowrap' }} display="flex" flexDirection="column">
            {currencyFormatter(record?.retainage_amount)}
          </Box>
        )
      },
      editable: () => true,
      renderEditable: (record) => {
        return (
          <InputInvoiceMaterialPercentage
            onChange={(value: number, type: string, percentage: number) =>
              handleChangeRetainage(value, type, percentage, record.id)
            }
            totalCost={record.extended_price}
            retainageAmount={record.retainage_amount}
            retainageType={record.retainage_type}
            roundingPrecision={invoiceStore?.invoice?.decimal_precision || 3}
            retainagePercentage={record.retainage_percentage}
          />
        )
      },
    })
  }

  return {
    expandableFixedFirstColumns: expandableFixedFirstColumns.map((column, index) => ({
      ...column,
      tabIndex: index,
    })),
    expandableColumnsToHide: expandableColumnsToHide.map((column, index) => ({
      ...column,
      tabIndex: index,
    })),
  }
}
