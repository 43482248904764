import React, { useState } from 'react'

import { Button, Col, Form, message, Modal, Row } from 'antd'

import { observer } from 'mobx-react-lite'

import { FlexBoxX, FlexBoxY } from 'common/components/boxes'
import { Page } from 'common/components/Page'
import { nextWeekday } from 'common/helpers/other'
import { useTheme } from 'common/hooks/use-theme'
import { OrderDelivery } from 'common/server/deliveries'
import { OrderMaterial } from 'common/server/orders'

import { useStores } from 'contractor/hooks/use-stores'
import { AllProviders } from 'contractor/providers'

import { Attachments } from './attachments'
import { PublicOrderFormProvider, usePublicOrderForm } from './context'
import { InternalComments } from './internal_comments'
import Materials from './materials'
import { MaterialsV2 } from './materials_v2'
import { OrderDetails } from './order_details'
import TopBar from './top_bar'
import { UserInfo } from './user_info'

/*
  At first we tried to make this component not use any of the "stores" and put all info in a React State component
  That works fine except for one quirk of react-spreadsheet: If a cell is active AND we re-render the component it will hijack
  focus from another input. For example, lets say I select a cell and it is active but not yet editing then I click on the address.
  I then start typing in the address and the first letter gets input into the address but then react-spreadsheet forces the address input
  to blur and draws focus to the active cell. This ONLY happens when we re-render Spreadsheet component after editing the address input
  which causes a rerender of everything (since thats what state changes at the top level do). By using a store instead we just
  get around that issue b/c each component is independent
*/

export const PublicOrderFormContent = observer(() => {
  const theme = useTheme()
  const { setSubmitting, form, isSubmitting, user, setUser, setProject, project } = usePublicOrderForm()

  const { publicOrderStore, uploaderStore } = useStores()

  const [isSpreadsheetMode, toggleSpreadsheetMode] = useState(false)

  const { formParams } = publicOrderStore

  const { users: allUsersFromCompany, projects } = formParams

  const validOrderMaterial = () => {
    return {
      message: 'Must specify materials',
      invalid: publicOrderStore.filteredOrderedMaterials().length === 0,
    }
  }

  const validateUploads = () => {
    return {
      message: 'Uploads have not completed yet, please try again',
      invalid: !uploaderStore.checkIfAllUploadsCompleted(),
    }
  }

  const validOrderMaterialCostCode = (projectId: string, materials: OrderMaterial[]) => {
    if (!formParams?.cost_code_settings?.project_filtering_enabled) {
      return {
        message: '',
        invalid: false,
      }
    }

    let errors = ''
    materials.map((material) => {
      if (material?.company_material?.cost_code?.project_ids?.length) {
        const costCodeBelongs = material?.company_material?.cost_code?.project_ids?.some((id) => id === projectId)
        const currentCostCodeValid = material.company_material.cost_code_id !== material.cost_code_id

        // if the cost code doesnt belong to the project, and the cost code was not changed to a new one
        // that belongs, should alert and stop the user
        if (!costCodeBelongs && !currentCostCodeValid) {
          errors = errors + material?.company_material.description + ', '
        }
      }
    })

    return {
      message: `Please make sure that the cost codes belong to the project! The following materials cost codes don't belong to the project. ${errors}`,
      invalid: !!errors.length,
    }
  }

  const validateRequiredFields = () => {
    const materials = publicOrderStore.filteredOrderedMaterials()
    const requiredRequestFields = [
      ...(formParams?.required_request_fields?.quantity
        ? [
            {
              key: 'quantity',
              label: 'Quantity',
            },
          ]
        : []),
      ...(formParams?.required_request_fields?.unit
        ? [
            {
              key: 'unit',
              label: 'Unit',
            },
          ]
        : []),
      ...(formParams?.required_request_fields?.cost_code
        ? [
            {
              key: 'cost_code_id',
              label: 'Cost Code',
            },
          ]
        : []),
    ]

    const errors = publicOrderStore.validateRequiredFields(materials, requiredRequestFields)

    return {
      message: `Please make sure that the following fields are filled out: ${errors}`,
      invalid: !!errors.length,
    }
  }

  const handleFinish = async () => {
    const validations = [
      validOrderMaterial(),
      validOrderMaterialCostCode(form.getFieldValue('projectId'), publicOrderStore.filteredOrderedMaterials()),
      validateUploads(),
      validateRequiredFields(),
    ]

    if (validations.some((validation) => validation.invalid)) {
      const validation = validations.find((validation) => validation.invalid)
      const title = validation?.message
      Modal.error({ title })
      return
    }

    setSubmitting(true)
    try {
      const signed_ids = uploaderStore.signedIds('purchaser_files')
      publicOrderStore.deliveries[0].requested_delivered_at = form.getFieldValue('requestedDeliveredAt')
      publicOrderStore.deliveries[0].address_to = {
        id: form.getFieldValue('addressToId'),
      } as OrderDelivery['address_to']
      publicOrderStore.deliveries[0].address = {
        id: form.getFieldValue('address'),
      } as OrderDelivery['address']

      await publicOrderStore.create({
        project_id: form.getFieldValue('projectId'),
        order_package_name: form.getFieldValue('orderPackageName'),
        user: {
          id: user?.id || '',
          email: form.getFieldValue('email'),
          first_name: form.getFieldValue('first_name'),
          last_name: form.getFieldValue('last_name'),
        },
        purchaser_files_signed_ids: signed_ids,
      })

      message.success('Successfully requested materials')
      publicOrderStore.resetOrderRequest()
      uploaderStore.resetAllUploads()
      const email = user?.email || form.getFieldValue('email')
      const first_name = user?.first_name || form.getFieldValue('first_name')
      const last_name = user?.last_name || form.getFieldValue('last_name')
      form.resetFields()
      // Keep the same project id and user info if we can (maybe you want to place two orders)
      form.setFieldsValue({
        projectId: project?.id,
        email,
        first_name,
        last_name,
      })
    } catch (err) {
      if (err.response?.data?.error) {
        message.error(err.response.data.error)
      } else {
        message.error(`Unable to save the order`)
      }
    } finally {
      setSubmitting(false)
    }
  }

  const onValuesChange = (values) => {
    if (values.email) {
      const foundUser = allUsersFromCompany?.find(({ email }) => email === values.email)
      if (foundUser) {
        setUser(foundUser)
        form.setFieldsValue({
          first_name: foundUser?.first_name || '',
          last_name: foundUser?.last_name || '',
        })
      }
    }
    if (values.projectId) {
      const project = projects.find(({ id }) => id === values.projectId)
      setProject(project)
    }
  }

  return (
    <>
      <TopBar />
      <Form
        form={form}
        layout="vertical"
        onFinish={handleFinish}
        initialValues={{
          requestedDeliveredAt: nextWeekday().toISOString(),
        }}
        onValuesChange={onValuesChange}
        style={{ width: '100%', height: 'calc(100% - 40px)' }}
        scrollToFirstError
      >
        <Page overflowY="auto" display="flex" flexDirection="column">
          <Page.Header p={{ _: 16, sm: 24 }}>
            <FlexBoxX alignItems="flex-start" width="100%" justifyContent="space-between" bg="white">
              <FlexBoxY flexGrow={0}>
                <h3
                  style={{
                    marginBottom: 'auto',
                    color: theme.colors.primary,
                  }}
                >
                  <b>{formParams.company_name}</b>
                </h3>
                <span>Order Form</span>
              </FlexBoxY>

              <Button loading={isSubmitting} type="primary" htmlType="submit">
                Submit
              </Button>
            </FlexBoxX>
          </Page.Header>

          <Page.Content px={{ _: 0, sm: 16 }} py={16}>
            <FlexBoxY width="100%" alignItems="stretch">
              <UserInfo />

              <OrderDetails />

              {isSpreadsheetMode ? (
                <Materials isSpreadsheetMode={isSpreadsheetMode} toggleSpreadsheetMode={toggleSpreadsheetMode} />
              ) : (
                <MaterialsV2 isSpreadsheetMode={isSpreadsheetMode} toggleSpreadsheetMode={toggleSpreadsheetMode} />
              )}

              <Row gutter={[16, 16]}>
                <Col xs={24} md={12}>
                  <Attachments />
                </Col>
                <Col xs={24} md={12}>
                  <InternalComments />
                </Col>
              </Row>

              <FlexBoxY mt={16} width="100%" justifyContent="flex-end" alignItems={{ _: 'center', sm: 'flex-end' }}>
                <Button data-cy="request-materials" loading={isSubmitting} type="primary" htmlType="submit">
                  Submit
                </Button>
              </FlexBoxY>
            </FlexBoxY>
          </Page.Content>
        </Page>
      </Form>
    </>
  )
})

const PublicOrderForm = () => (
  <AllProviders>
    <PublicOrderFormProvider>
      <PublicOrderFormContent />
    </PublicOrderFormProvider>
  </AllProviders>
)

export default PublicOrderForm
