import React, { useEffect } from 'react'

import { connectInfiniteHits } from 'react-instantsearch-dom'

import { Button } from 'antd'

import { observer } from 'mobx-react-lite'

import { FlexBoxY, FlexBoxX } from 'common/components/boxes'

import { useStores } from 'contractor/hooks/use-stores'
import { InvoiceInboxHit } from 'contractor/server/invoices/inbox'

import { StateResults } from '../../state_results'
import { InboxRowAction, ListItemInbox } from './list_item'

interface ListProps {
  hasMore: boolean
  refineNext: () => void
  hits: InvoiceInboxHit[]
  refreshHits: boolean
  onSelectRow: (hit: InvoiceInboxHit) => void
  onSelectAction: (hit: InvoiceInboxHit, action: InboxRowAction) => void
}

const List = observer<ListProps>(({ hits, hasMore, refineNext, onSelectRow, onSelectAction }) => {
  const { invoiceInboxStore } = useStores()

  useEffect(() => {
    invoiceInboxStore.setHits(hits)
  }, [invoiceInboxStore.searchState, hits])

  return (
    <FlexBoxY width="100%">
      {invoiceInboxStore.hits.map((hit) => (
        <ListItemInbox key={`${hit.id}-x-key`} hit={hit} onSelectRow={onSelectRow} onSelectAction={onSelectAction} />
      ))}

      {hasMore && (
        <FlexBoxX width="100%" p={16} bg="white">
          <Button type="link" onClick={refineNext}>
            Load More
          </Button>
        </FlexBoxX>
      )}
    </FlexBoxY>
  )
})

const CustomHits = connectInfiniteHits(List)

export type InvoiceListProps = Pick<ListProps, 'onSelectRow' | 'onSelectAction'>

export const InboxList = observer<InvoiceListProps>((props) => {
  return (
    <StateResults>
      <CustomHits {...props} />
    </StateResults>
  )
})
