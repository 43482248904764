import axios from 'axios'

import { File } from 'common/server/server_types'

export interface VendorList {
  id: string
  name: string
  domain: string
  description?: string
  logo?: File
  vendor_users_count?: number
}

export interface Vendor {
  id: string
  name: string
  domain: string
  description?: string
  logo?: File
  is_public: boolean
  vendor_users: VendorUser[]
  connected_vendor_user_ids: string[]
  company_vendor_id?: string
}

export interface VendorUser {
  id: string
  full_name: string
  email: string
  headline?: string
  vendor?: Vendor
  is_public: boolean
}

export function index() {
  return axios.get<{ vendors: VendorList[] }>('/vendors')
}

export function getVendorById(id: string) {
  return axios.get<{ vendor: Vendor }>(`/vendor/${id}`)
}

export function getVendorsUsers() {
  return axios.get<{ vendor_users: VendorUser[] }>(`/vendor_users`)
}
