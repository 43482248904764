import React from 'react'

import styled from '@emotion/styled'

import { FlexBoxY } from 'common/components/boxes'
import { InputCurrency } from 'common/components/InputCurrency'
import { calcExtCost } from 'common/helpers/order'

import { CommitmentMaterial } from 'contractor/server/commitments'

import { ExtendedCost } from './extended_cost'

const InputCurrencyStyled = styled(InputCurrency)`
  width: 100%;
  min-width: 80px;
  input {
    text-align: end;
    padding-right: 30px;
  }
`

export type UnitCostColumnProps = {
  commitmentMaterial: CommitmentMaterial
  disabled?: boolean
  roundingPrecision: number
  onChange: (value: CommitmentMaterial, changes: CommitmentMaterial) => void
}

export const UnitCostColumn = (props: UnitCostColumnProps) => {
  const { commitmentMaterial, disabled, roundingPrecision, onChange } = props
  const unit = commitmentMaterial?.company_material?.unit
  const unitCost = commitmentMaterial.unit_price || 0
  const extCost = calcExtCost({
    unitCost,
    quantity: Number(commitmentMaterial?.quantity),
    multiplier: unit?.multiplier,
    qtyIncrement: unit?.qty_increment,
  })

  const handleUnitCostChange = (value: string) => {
    onChange(commitmentMaterial, { ...commitmentMaterial, unit_price: value })
  }

  return (
    <FlexBoxY alignItems="flex-end" minWidth="124px">
      <InputCurrencyStyled
        aria-label="material-price"
        value={unitCost}
        onChange={handleUnitCostChange}
        disabled={disabled}
        defaultValue={null}
        data-cy="unit-cost-input"
      />

      <ExtendedCost extCost={extCost} roundingPrecision={roundingPrecision} hideDetails={disabled} />
    </FlexBoxY>
  )
}
