import React, { useState } from 'react'

import { Alert, Button, Form, Input, Checkbox, Typography } from 'antd'

import { Box } from 'common/components/boxes'
import { SUPPORT_EMAIL_ADDRESS } from 'common/helpers/other'
import { passwordConfirmationRules, passwordRules } from 'common/pages/Landing/password_rules'
import { Events, trackEvent } from 'common/pendo/event_tracking'

import { SignInTypes, LandingMethods } from '.'
import { Logo } from './logo'

interface RegisterProps {
  setSignInType: (string) => void
  setResendConfirmationMessage: (string) => void
  termsAndConditionsUrl: string
  register?: LandingMethods['register']
}

const Register = ({ setSignInType, termsAndConditionsUrl, register }: RegisterProps) => {
  const [form] = Form.useForm()
  const [error, setError] = useState('')

  const onFinish = async (values): Promise<void> => {
    try {
      await register(values)
      // TODO: Re-evaluate where we actually want to redirect you to after registering
      window.location.reload()
      // Disabled confirmation functionality
      // setSignInType(SignInTypes.RESEND_CONFIRMATION)
      // setResendConfirmationMessage(
      //   'Please use the confirmation link sent to your email to complete the registration process.\
      //   If you do not see it in your inbox, check your junk mail.',
      // )
    } catch (err) {
      setError(err.response.data['error'] || `Unknown registration error, please contact ${SUPPORT_EMAIL_ADDRESS}`)
    }
  }

  // Update the domain if it doesn't exist and email is updated
  const onValuesChange = (changedValues) => {
    const splitEmail = changedValues['email']?.split('@')

    if (splitEmail && splitEmail.length > 1) {
      form.setFieldsValue({ domain: splitEmail.pop() })
    }

    if (changedValues?.email) form.setFieldsValue({ email: changedValues.email.toLowerCase().trim() })
  }

  return (
    <Box display="flex" flexDirection="column" width="100%" justifyContent="center" alignItems="flex-start">
      <Logo onClick={() => setSignInType(SignInTypes.SIGN_IN)} />

      <Typography.Title level={4} style={{ margin: 0 }}>
        Register
      </Typography.Title>
      <Typography.Title level={5} style={{ marginTop: 0 }}>
        as Contractor
      </Typography.Title>

      <Typography.Text type="secondary">
        Please enter the details below to create your account at SubBase for{' '}
        <Typography.Link>Contractor</Typography.Link>.
      </Typography.Text>

      <Form
        layout="vertical"
        form={form}
        style={{ width: '100%', marginTop: 24 }}
        size="middle"
        onFinish={onFinish}
        onValuesChange={onValuesChange}
      >
        <Form.Item
          name="first_name"
          label="First Name"
          validateTrigger="onBlur"
          rules={[{ required: true, message: 'Please your first name!' }]}
        >
          <Input placeholder="First name" />
        </Form.Item>

        <Form.Item
          name="last_name"
          label="Last Name"
          validateTrigger="onBlur"
          rules={[{ required: true, message: 'Please input your last name!' }]}
        >
          <Input placeholder="Last name" />
        </Form.Item>

        <Form.Item
          name="email"
          label="Email"
          validateTrigger="onBlur"
          rules={[
            { required: true, message: 'Please input your Email!' },
            { type: 'email', message: 'The input is not valid E-mail!' },
          ]}
        >
          <Input placeholder="Email" />
        </Form.Item>

        <Form.Item
          name="company_name"
          label="Company Name"
          validateTrigger="onBlur"
          rules={[{ required: true, message: 'Please input a company name!' }]}
        >
          <Input placeholder="Company name" />
        </Form.Item>

        <Form.Item
          name="domain"
          label="Web Domain"
          validateTrigger="onBlur"
          rules={[{ required: true, message: 'Please input your company website' }]}
        >
          <Input placeholder="Company name" />
        </Form.Item>

        <Form.Item label="Password" name="password" validateTrigger="onBlur" rules={passwordRules}>
          <Input type="password" placeholder="Password" />
        </Form.Item>

        <Form.Item
          name="confirm"
          label="Confirm password"
          validateTrigger="onBlur"
          dependencies={['password']}
          hasFeedback
          rules={passwordConfirmationRules}
        >
          <Input.Password placeholder="Confirm Password" />
        </Form.Item>

        <Form.Item
          name="terms_and_conditions_approved"
          valuePropName="checked"
          rules={[{ validator: (_, value) => (value ? Promise.resolve() : Promise.reject('Should accept agreement')) }]}
        >
          <Checkbox>
            I agree to the{' '}
            <Typography.Link href={termsAndConditionsUrl} target="_blank">
              Terms and Conditions
            </Typography.Link>
          </Checkbox>
        </Form.Item>

        {error && <Alert style={{ marginBottom: '20px' }} message={error} type="error" />}

        <Box display="flex" flexDirection="column">
          <Button block type="primary" htmlType="submit">
            Get Started
          </Button>

          <Box display="flex" justifyContent="center" width="100%" mt={8} mb={20}>
            <Typography.Text>
              Already have an account?{' '}
              <Typography.Link
                onClick={() => {
                  trackEvent(Events.CLICK_LOGIN_CONTRACTOR_REGISTER, location.pathname)
                  setSignInType(SignInTypes.SIGN_IN)
                }}
              >
                Login
              </Typography.Link>
            </Typography.Text>
          </Box>
        </Box>
      </Form>
    </Box>
  )
}

export default Register
