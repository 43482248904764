import React, { useRef, useState, useEffect } from 'react'

import { useLocation } from 'react-router-dom'

import { Button, Row, Col, Alert, Space, Typography, notification, message } from 'antd'

import { observer } from 'mobx-react-lite'

import { Box } from 'common/components/boxes'
import { Drawer, DrawerRef } from 'common/components/Drawer'
import { useQuery } from 'common/hooks/use-query'
import { RelationshipStockStatus } from 'common/server/server_types'

import { AgaveSyncStatusDetailed } from 'contractor/components/AgaveSyncStatusDetailed'
import { SelectAgaveCostCodeClass } from 'contractor/components/SelectAgaveCostCodeClass'
import { SelectAgaveCostCodePhase } from 'contractor/components/SelectAgaveCostCodePhase'
import { SelectAgaveCostCodes } from 'contractor/components/SelectAgaveCostCodes'
import { SelectAgaveMaterials } from 'contractor/components/SelectAgaveMaterials'
import { SelectAgaveProjects } from 'contractor/components/SelectAgaveProjects'
import { SelectAgaveVendors } from 'contractor/components/SelectAgaveVendors'
import { useStores } from 'contractor/hooks/use-stores'
import { useUpdateMappingsHook } from 'contractor/pages/Integrations/Mappings/use_update_mappings_hook'
import { Relationship } from 'contractor/server/integrations'
import { ShowOrderResponse } from 'contractor/server/orders'

const relationshipOption = (relationship: Nullable<Relationship>): { label: string; value: string } =>
  relationship
    ? {
        value: relationship.external_id,
        label: relationship.external_name,
      }
    : undefined

const makeEntity = (id: string, relationship: Nullable<Relationship>) => {
  const external_relationships = relationship ? [relationship] : []
  return {
    id: id,
    external_relationships,
  }
}

type IntegrationSyncProps = {
  order: ShowOrderResponse
}

export const IntegrationSync = observer<IntegrationSyncProps>(({ order }) => {
  const { integrationStore, userStore, orderStore } = useStores()

  const drawerRef = useRef<DrawerRef>()
  const location = useLocation()

  useEffect(() => {
    const params = new URLSearchParams(location.search)

    if (params.get('retry-sync') === 'true') {
      drawerRef.current?.show()
    }
  }, [location.search])

  const [isSubmitting, setSubmitting] = useState(false)

  const integration = order.integration
  const integrationName = integrationStore.getIntegrationName(integration.source)

  const { isLoading: isLoadingRelationships } = useQuery(() => {
    return integrationStore.order_relationships(order.id)
  }, [order.id])

  const handleConfirmSync = async () => {
    try {
      setSubmitting(true)
      await integrationStore.createOrder(orderStore.selectedOrder?.id)
      orderStore.selectOrder(orderStore.selectedOrder?.id)
      drawerRef.current?.close()
      notification.success({
        message: `Syncing the order with ${integrationStore.getIntegrationName()}`,
        description: 'The synchronization can take a time to finish.',
        placement: 'bottomLeft',
      })
    } catch (error) {
      if (error?.response?.data?.message) {
        message.error(error?.response?.data?.message)
      } else {
        message.error(`Unable to sync.`)
      }
    } finally {
      setSubmitting(false)
    }
  }

  const reloadOrder = async () => {
    await integrationStore.order_relationships(order.id)
  }

  const { handleUpdate: handleUpdateProject } = useUpdateMappingsHook({
    update: integrationStore.updateProject,
    reloadCallback: () => reloadOrder(),
  })

  const { handleUpdate: handleUpdateCompanyVendor } = useUpdateMappingsHook({
    update: integrationStore.updateCompanyVendor,
    reloadCallback: () => reloadOrder(),
  })

  const { handleUpdate: handleUpdateCostCodeNumber } = useUpdateMappingsHook({
    update: integrationStore.updateCostCodeNumber,
    reloadCallback: () => reloadOrder(),
  })

  const { handleUpdate: handleUpdateCostCodeClass } = useUpdateMappingsHook({
    update: integrationStore.updateCostCodeClass,
    reloadCallback: () => reloadOrder(),
  })

  const { handleUpdate: handleUpdateCompanyMaterial } = useUpdateMappingsHook({
    update: integrationStore.updateCompanyMaterial,
    reloadCallback: () => reloadOrder(),
  })

  const { handleUpdate: handleUpdateCostCodePhase } = useUpdateMappingsHook({
    update: integrationStore.updateCostCodePhase,
    reloadCallback: () => reloadOrder(),
  })

  const [orderRelationships, setOrderRelationships] = useState(integrationStore.orderRelationships)
  const [projectRelationshipId, setProjectRelationshipId] = useState(orderRelationships?.project?.relationship?.id)

  useEffect(() => {
    setOrderRelationships(integrationStore.orderRelationships)
    setProjectRelationshipId(integrationStore.orderRelationships?.project?.relationship?.id)
  }, [integrationStore.orderRelationships])

  // Project is always the most important piece of information so, we make sure the user selects it first
  // For Procore, we use the project to filter relationships tied to it
  const disabledByMissingProject = integrationStore.enabledProjectsMapping() ? !projectRelationshipId : false

  if (isLoadingRelationships) return null

  return (
    <>
      <AgaveSyncStatusDetailed
        integration={integration}
        integrationName={integrationName}
        onClick={() => userStore.canSyncWithErp && drawerRef.current?.toggle()}
        showAction
        webUrl={integration?.web_url}
      />

      <Drawer ref={drawerRef} title={`${integrationName} Sync Request`}>
        <Box display="flex" flexDirection="column" overflowY="auto" width="100%" height="100%" p={{ _: 12, sm: 20 }}>
          {integration && integration.status === RelationshipStockStatus.FAILED && (
            <Space direction="vertical" style={{ marginBottom: 16, width: '100%' }}>
              {/* get only the last error */}
              {integration?.errors?.length > 0 && (
                <Alert
                  type="error"
                  message={integration?.errors[integration?.errors?.length - 1]?.fail_message}
                  closable
                />
              )}
            </Space>
          )}

          {disabledByMissingProject && (
            <Space direction="vertical" style={{ marginBottom: 16, width: '100%' }}>
              <Alert
                type="warning"
                message="Please select a project first to enable the rest of the fields."
                closable
              />
            </Space>
          )}
          <Row gutter={20}>
            {integrationStore.enabledProjectsMapping() && (
              <>
                <Col xs={15} sm={9}>
                  <Row style={{ padding: 5 }}>
                    <Typography.Text>SubBase Project</Typography.Text>
                  </Row>
                  <Row style={{ padding: 5 }}>
                    <Typography.Text strong>{orderRelationships?.project?.name}</Typography.Text>
                  </Row>
                </Col>

                <Col xs={33} sm={15} style={{ paddingBottom: 20 }}>
                  <Row style={{ padding: 5 }}>
                    <Typography.Text>{`${integrationName} Project`}</Typography.Text>
                  </Row>
                  <Row style={{ padding: 5 }}>
                    <SelectAgaveProjects
                      value={relationshipOption(orderRelationships?.project?.relationship)}
                      onChange={(value) =>
                        handleUpdateProject({
                          option: value,
                          entity: makeEntity(
                            orderRelationships?.project?.id,
                            orderRelationships?.project?.relationship,
                          ),
                        })
                      }
                    />
                  </Row>
                </Col>
              </>
            )}

            {integrationStore.enabledVendorsMapping() && (
              <>
                <Col xs={15} sm={9}>
                  <Row style={{ padding: 5 }}>
                    <Typography.Text>SubBase Vendor</Typography.Text>
                  </Row>
                  <Row style={{ padding: 5 }}>
                    <Typography.Text strong>{orderRelationships?.company_vendor?.vendor_name}</Typography.Text>
                  </Row>
                </Col>

                <Col xs={33} sm={15} style={{ paddingBottom: 20 }}>
                  <Row style={{ padding: 5 }}>
                    <Typography.Text>{`${integrationName} Vendor`}</Typography.Text>
                  </Row>
                  <Row style={{ padding: 5 }}>
                    <SelectAgaveVendors
                      multiToMono
                      disabled={disabledByMissingProject}
                      tiedToId={projectRelationshipId}
                      value={relationshipOption(orderRelationships?.company_vendor?.relationship)}
                      onChange={(value) =>
                        handleUpdateCompanyVendor({
                          option: value,
                          multiToMono: true,
                          entity: makeEntity(
                            orderRelationships?.company_vendor?.id,
                            orderRelationships?.company_vendor?.relationship,
                          ),
                        })
                      }
                    />
                  </Row>
                </Col>
              </>
            )}

            {userStore.canUseCostCode && integrationStore.enabledCostPhaseMapping() && (
              <>
                {orderRelationships?.cost_code_phases?.map((costCodePhase, _) => (
                  <React.Fragment key={costCodePhase.id}>
                    <Col xs={15} sm={9}>
                      <Row style={{ padding: 5 }}>
                        <Typography.Text>SubBase Cost Phase</Typography.Text>
                      </Row>
                      <Row style={{ padding: 5 }}>
                        <Typography.Text strong>{`${costCodePhase.code} : ${
                          costCodePhase.description || ''
                        }`}</Typography.Text>
                      </Row>
                    </Col>
                    <Col xs={33} sm={15} style={{ paddingBottom: 20 }}>
                      <Row style={{ padding: 5 }}>
                        <Typography.Text>{`${integrationName} Cost Phase`}</Typography.Text>
                      </Row>
                      <Row style={{ padding: 5 }}>
                        <SelectAgaveCostCodePhase
                          multiToMono
                          tiedToId={projectRelationshipId}
                          disabled={disabledByMissingProject}
                          value={relationshipOption(costCodePhase.relationship)}
                          onChange={(value) =>
                            handleUpdateCostCodePhase({
                              option: value,
                              multiToMono: true,
                              entity: makeEntity(costCodePhase.id, costCodePhase.relationship),
                            })
                          }
                        />
                      </Row>
                    </Col>
                  </React.Fragment>
                ))}
              </>
            )}

            {userStore.canUseCostCode && integrationStore.enabledCostCodesMapping() && (
              <>
                {orderRelationships?.cost_code_numbers?.map((costCodeNumber, _) => (
                  <React.Fragment key={costCodeNumber.id}>
                    <Col xs={15} sm={9}>
                      <Row style={{ padding: 5 }}>
                        <Typography.Text>SubBase Cost Code</Typography.Text>
                      </Row>
                      <Row style={{ padding: 5 }}>
                        <Typography.Text strong>{`${costCodeNumber.code} : ${
                          costCodeNumber.description || ''
                        }`}</Typography.Text>
                      </Row>
                    </Col>
                    <Col xs={33} sm={15} style={{ paddingBottom: 20 }}>
                      <Row style={{ padding: 5 }}>
                        <Typography.Text>{`${integrationName} Cost Code`}</Typography.Text>
                      </Row>
                      <Row style={{ padding: 5 }}>
                        <SelectAgaveCostCodes
                          multiToMono
                          tiedToId={projectRelationshipId}
                          disabled={disabledByMissingProject}
                          value={relationshipOption(costCodeNumber.relationship)}
                          onChange={(value) =>
                            handleUpdateCostCodeNumber({
                              option: value,
                              multiToMono: true,
                              entity: makeEntity(costCodeNumber.id, costCodeNumber.relationship),
                            })
                          }
                        />
                      </Row>
                    </Col>
                  </React.Fragment>
                ))}
              </>
            )}

            {userStore.canUseCostCode && integrationStore.enabledCostClassesMapping() && (
              <>
                {orderRelationships?.cost_code_classes?.map((costCodeClass, _) => (
                  <React.Fragment key={costCodeClass.id}>
                    <Col xs={15} sm={9}>
                      <Row style={{ padding: 5 }}>
                        <Typography.Text>SubBase Cost Class</Typography.Text>
                      </Row>
                      <Row style={{ padding: 5 }}>
                        <Typography.Text strong>{`${costCodeClass.code} : ${
                          costCodeClass.description || ''
                        }`}</Typography.Text>
                      </Row>
                    </Col>
                    <Col xs={33} sm={15} style={{ paddingBottom: 20 }}>
                      <Row style={{ padding: 5 }}>
                        <Typography.Text>{`${integrationName} Cost Class`}</Typography.Text>
                      </Row>
                      <Row style={{ padding: 5 }}>
                        <SelectAgaveCostCodeClass
                          multiToMono
                          tiedToId={projectRelationshipId}
                          disabled={disabledByMissingProject}
                          value={relationshipOption(costCodeClass.relationship)}
                          onChange={(value) =>
                            handleUpdateCostCodeClass({
                              option: value,
                              entity: makeEntity(costCodeClass.id, costCodeClass.relationship),
                            })
                          }
                        />
                      </Row>
                    </Col>
                  </React.Fragment>
                ))}
              </>
            )}

            {integrationStore.enabledMaterialsMapping() && (
              <>
                {orderRelationships?.company_materials?.map((companyMaterial, _) => (
                  <React.Fragment key={companyMaterial.id}>
                    <Col xs={15} sm={9}>
                      <Row style={{ padding: 5 }}>
                        <Typography.Text>SubBase Material</Typography.Text>
                      </Row>
                      <Row style={{ padding: 5 }}>
                        <Typography.Text strong>{`${companyMaterial.description}`}</Typography.Text>
                      </Row>
                    </Col>
                    <Col xs={33} sm={15} style={{ paddingBottom: 20 }}>
                      <Row style={{ padding: 5 }}>
                        <Typography.Text>{`${integrationName} Material`}</Typography.Text>
                      </Row>
                      <Row style={{ padding: 5 }}>
                        <SelectAgaveMaterials
                          multiToMono
                          tiedToId={projectRelationshipId}
                          disabled={disabledByMissingProject}
                          value={relationshipOption(companyMaterial.relationship)}
                          onChange={(value) =>
                            handleUpdateCompanyMaterial({
                              option: value,
                              entity: makeEntity(companyMaterial.id, companyMaterial.relationship),
                            })
                          }
                        />
                      </Row>
                    </Col>
                  </React.Fragment>
                ))}
              </>
            )}
          </Row>

          <Box display="flex" justifyContent="flex-end" pt={30} pr={10}>
            <Button
              type="primary"
              htmlType="submit"
              loading={isSubmitting}
              onClick={handleConfirmSync}
              disabled={
                disabledByMissingProject ||
                [RelationshipStockStatus.SYNCED, RelationshipStockStatus.SYNCING].includes(integration?.status)
              }
            >
              Confirm Sync
            </Button>
          </Box>
        </Box>
      </Drawer>
    </>
  )
})
