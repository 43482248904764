/** @jsx jsx */

import * as React from 'react'

import { Column, Row } from 'react-table'

import { jsx } from '@emotion/core'

import { Typography } from 'antd'

import { observer } from 'mobx-react-lite'

import { formatDateString } from 'common/helpers/formatters'
import { OrderHit } from 'common/server/orders'
import { OrderStates } from 'common/server/server_types'

interface OrderStateCellProps {
  row: Row
  column: Column
  value: string
}

const OrderStateCell: React.FC<OrderStateCellProps> = observer(({ value, row }) => {
  const { state } = row['original'] as OrderHit

  if (state == OrderStates.QUOTED) {
    return <Typography.Text>{formatDateString(value)}</Typography.Text>
  } else {
    return <Typography.Text type="secondary">{formatDateString(value)}</Typography.Text>
  }
})

export default OrderStateCell
