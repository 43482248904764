import { useStores } from 'contractor/hooks/use-stores'

export const useFlag = (key: string) => {
  const { userStore, publicOrderStore } = useStores()
  const feature_flags = userStore.feature_flags || publicOrderStore.feature_flags
  if (!feature_flags) {
    return false
  }
  return feature_flags[key]?.enabled
}
