import axios from 'axios'

import { Address } from 'common/server/addresses'
import { AddressTo } from 'common/server/addressesTo'
import { CostCode } from 'common/server/cost_codes/cost_codes'
import { OrderDelivery } from 'common/server/deliveries'
import { OrderMaterial } from 'common/server/orders'
import { OrderStates, OrderSubStates, SearchKeysResponse } from 'common/server/server_types'
import { Unit } from 'common/server/units'
import { Flags } from 'common/server/user'

import { ManagedUser } from 'contractor/server/company_settings/manage_users'
import {
  CostCodeSettings,
  OrderFieldsSettings,
  RequiredRequestFields,
} from 'contractor/server/company_settings/other_settings'
import { OrderPermissions } from 'contractor/server/company_settings/permissions'
import { OrderStateChanges } from 'contractor/server/orders'

export interface Project {
  id: string
  name: string
  addresses: Address[]
  project_group_id: string
}

export interface OrderFormParams {
  company_id: string
  company_name: string
  material_requisitions_default_order_type: 'ORDER' | 'RFQ'
  projects: { id: string; name: string; addresses: Address[]; project_group_id: string }[]
  company_addresses: Address[]
  users: ManagedUser[]
  algolia_company_materials_search_key: SearchKeysResponse
  company_attributes: string[]
  public_order_form_url_extension: string
  units: Unit[]
  cost_codes: CostCode[]
  cost_code_phases: CostCode[]
  feature_flags: Flags
  cost_code_settings: CostCodeSettings
  required_request_fields: RequiredRequestFields
  order_fields_settings: OrderFieldsSettings
  addresses_to: AddressTo[]
  order_permissions: OrderPermissions
  public_token: string
  catalog_sources: string[]
}

export interface CreateOrderRequest {
  state_changes?: OrderStateChanges
  project_id: string
  user: CreateOrderRequestUser
  order_package_name: string
  purchaser_files_signed_ids?: string[]
  order_materials: OrderMaterial[]
  deliveries: OrderDelivery[]
  company_vendors?: {
    id: string
    company_vendor_contact_ids: string[]
  }[]
  internal_comment?: string
  watcher_ids?: string[]
}

export interface CreateOrderRequestUser {
  id?: string
  email?: string
  first_name?: string
  last_name?: string
  project_group_ids?: string[]
}
interface OrderCreateResponse {
  id: string
  order_package_id: string
  state: OrderStates
  sub_state: OrderSubStates
}

export function index(public_order_form_url_extension: string) {
  return axios.get<OrderFormParams>(`/order_form/${public_order_form_url_extension}`)
}

export function create(public_order_form_url_extension: string, payload: CreateOrderRequest) {
  return axios.post<{ orders: OrderCreateResponse[] }>(`/order_form/${public_order_form_url_extension}`, payload)
}
