import { findIndex } from 'lodash'
import { BindAll } from 'lodash-decorators'

import { action, observable } from 'mobx'

import { Address } from 'common/server/addresses'

import { create as createAddress, update as updateAddress } from 'contractor/server/addresses'
import {
  create,
  CreateVendorContactRequest,
  index as indexContacts,
  CompanyVendorContact,
  UpdateVendorContactRequest,
  update as updateVendorContact,
  indexByVendorId,
  getById,
} from 'contractor/server/company_vendor_contacts'
import {
  UpdateVendorRequest,
  update as updateVendor,
  index as indexCompanyVendors,
  show as getCompanyVendorById,
  IndexCompanyVendor,
} from 'contractor/server/company_vendors'

@BindAll()
export default class CompanyVendorStore {
  @observable selectedCompanyVendor: Nullable<IndexCompanyVendor> = null
  @observable selectedCompanyVendorContact: Nullable<CompanyVendorContact> = null

  vendorContacts = observable.array<CompanyVendorContact>([])
  companyVendors = observable.array<IndexCompanyVendor>([])

  companyVendorsSelect = observable.array<IndexCompanyVendor>([])

  companyVendorContacts = observable.array<CompanyVendorContact>([])

  updateCompanyVendor(companyVendor: UpdateVendorRequest) {
    return updateVendor(companyVendor)
  }

  async getCompanyVendorById(id: string) {
    this.selectedCompanyVendor = null
    const { data } = await getCompanyVendorById(id)

    this.selectedCompanyVendor = data.company_vendor
  }

  async getCompanyVendorContactById(id: string) {
    this.selectedCompanyVendorContact = null
    const { data } = await getById(id)

    this.selectedCompanyVendorContact = data.vendor_contact
  }

  maybeGetAllCompanyVendorsToSelect() {
    if (!this.companyVendorsSelect.length) {
      return this.getAllCompanyVendorsToSelect()
    }
  }

  async getAllCompanyVendorsToSelect() {
    const { data } = await indexCompanyVendors()
    this.companyVendorsSelect.replace(data.company_vendors)
  }

  maybeGetAllCompanyVendors(isActive?: boolean) {
    if (!this.companyVendors.length) {
      return this.getAllCompanyVendors(isActive)
    }
  }

  async getAllCompanyVendors(isActive?: boolean) {
    const { data } = await indexCompanyVendors(isActive)
    this.companyVendors.replace(data.company_vendors)
  }

  maybeGetAllCompanyVendorContacts() {
    if (!this.vendorContacts.length) {
      return this.getAllCompanyVendorContacts()
    }
  }

  async getAllCompanyVendorContacts() {
    const response = await indexContacts()
    this.vendorContacts.replace(response.data.vendor_contacts)
  }

  // Will also create a vendor if applicable
  @action
  async createVendorContact(
    vendor: CreateVendorContactRequest['vendor'],
    contact: CreateVendorContactRequest['contact'],
  ) {
    // Add the data then reindex our vendors so we have the most up to date info
    const newContact = (await create({ vendor, contact })).data
    if (newContact) {
      this.vendorContacts.push(newContact)
    }
    return newContact
  }

  async updateVendorContact({
    vendor,
    vendorContact,
  }: {
    vendor?: UpdateVendorRequest
    vendorContact?: UpdateVendorContactRequest
  }) {
    const promises = []
    if (vendor) {
      promises.push(updateVendor(vendor))
    }
    if (vendorContact) {
      promises.push(updateVendorContact(vendorContact))
    }
    await Promise.all(promises)
  }

  updateCompanyVendorContact(companyVendorContact?: UpdateVendorContactRequest) {
    return updateVendorContact(companyVendorContact)
  }

  async getCompanyVendorContactsByCompanyVendorId(companyVendorId: string) {
    const { data } = await indexByVendorId(companyVendorId)
    this.companyVendorContacts.replace(data?.vendor_contacts)

    return data?.vendor_contacts
  }

  async connectToVendor({ vendorId, vendorUserId }) {
    const { data } = await create({ vendor: { vendor_id: vendorId }, contact: { vendor_user_id: vendorUserId } })

    this.vendorContacts.push(data)

    return data
  }

  async createAddress(companyVendorId: string, address: Address) {
    const { data } = await createAddress({ company_vendor_id: companyVendorId, address })

    if (this.selectedCompanyVendorContact) {
      this.selectedCompanyVendorContact?.company_vendor.addresses.push(data)
    }

    if (this.selectedCompanyVendor) {
      this.selectedCompanyVendor?.addresses.push(data)
    }
  }

  async updateAddress(address: Address) {
    const { data } = await updateAddress({ id: address.id, address })

    if (this.selectedCompanyVendorContact) {
      const index = findIndex(this.selectedCompanyVendorContact?.company_vendor.addresses, { id: address.id })
      this.selectedCompanyVendorContact.company_vendor.addresses[index] = data
    }

    if (this.selectedCompanyVendor) {
      const index = findIndex(this.selectedCompanyVendor?.addresses, { id: address.id })
      this.selectedCompanyVendor.addresses[index] = data
    }
  }
}
