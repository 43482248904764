import React from 'react'

import { useHistory } from 'react-router-dom'

import PlusOutlined from '@ant-design/icons/PlusOutlined'
import { Button, Popover } from 'antd'

import { Box } from 'common/components/boxes'

interface ActionsProps {
  projectId?: string
}

export const Actions = (props: ActionsProps) => {
  const { projectId } = props
  const history = useHistory()

  return (
    <Box display="flex" gridGap={8} alignItems="center">
      <Popover placement="bottomRight" content="Create Commitment">
        <Button
          data-cy="create-commitment"
          onClick={() => {
            history.push(projectId ? `/commitments/new?project_id=${projectId}` : '/commitments/new')
          }}
          type="primary"
          style={{ height: 32 }}
        >
          <PlusOutlined style={{ fontSize: 16 }} />
        </Button>
      </Popover>
    </Box>
  )
}
