import React, { useRef } from 'react'

import { HotTable, HotTableProps } from '@handsontable/react'

import { observer } from 'mobx-react-lite'

import { Box } from 'common/components/boxes'
import { addStyle } from 'common/helpers/formatters'
import { settings } from 'common/settings/handsontable'

import { useStores } from 'contractor/hooks/use-stores'

// Annoying cell width issue
// https://github.com/handsontable/handsontable/issues/3815
addStyle('body > span { position:absolute; }')

export const BulkEditTable = observer(() => {
  const { costCodeStore, companySettingStore } = useStores()

  const { costCodeListStore } = costCodeStore

  const costCodeSettings = companySettingStore.otherSettings?.cost_code_settings

  const hotTableComponent = useRef(null)

  const handleChange = (changes, source) => {
    if (source == 'loadData') {
      return
    }
    const hot = hotTableComponent.current.hotInstance

    // Need to first convert visual to physical row so we can properly get the object
    // Regardless of filtering and sorting
    changes.forEach((change) => {
      // If the cell didn't change then don't bother updating
      if (change[2] == change[3]) {
        return
      }

      const physicalIndex = hot.toPhysicalRow(change[0])
      const newObject = hot.getSourceDataAtRow(physicalIndex)
      costCodeStore.onChangeBulkEdited(newObject)
    })
  }

  const canShowPhase = costCodeSettings?.phase_code_enabled && !costCodeSettings.independent_phase_codes_enabled

  const headers = [
    ...(canShowPhase ? ['Phase Code', 'Phase Code Description'] : []),
    'Code',
    'Description',
    ...(costCodeSettings?.class_enabled ? ['Class', 'Class Description'] : []),
    ...(costCodeSettings?.project_filtering_enabled ? ['Projects'] : []),
    'Active',
  ]

  const columns: HotTableProps['columns'] = [
    ...(canShowPhase
      ? [
          {
            data: 'phase_code',
          },
          {
            data: 'phase_code_description',
          },
        ]
      : []),
    {
      data: 'code',
    },
    {
      data: 'description',
    },
    ...(costCodeSettings.class_enabled
      ? [
          {
            data: 'clazz',
          },
          {
            data: 'clazz_description',
          },
        ]
      : []),
    ...(costCodeSettings.project_filtering_enabled
      ? [
          {
            data: 'project_names',
            readOnly: true,
          },
        ]
      : []),
    {
      data: 'active',
      type: 'checkbox',
      className: 'htCenter',
    },
  ]

  return (
    <Box width="100%" height="100%">
      <HotTable
        ref={hotTableComponent}
        settings={settings}
        data={costCodeListStore.records.map((costCode) => ({
          ...costCode,
          active: !costCode?.discarded_at,
          project_names: costCode.projects.map((project) => project.name),
        }))}
        colWidths={[0, 0, 50]}
        columns={columns}
        colHeaders={headers}
        rowHeaders={false}
        columnSorting={true}
        height="100%"
        stretchH="all"
        filters={true}
        afterChange={handleChange}
        dropdownMenu={[
          'filter_by_condition',
          'filter_operators',
          'filter_by_condition2',
          'filter_by_value',
          'filter_action_bar',
        ]}
        rowHeights={25}
      />
    </Box>
  )
})
