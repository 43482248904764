import React from 'react'

import { Dictionary, sortBy } from 'lodash'

import { Typography, Divider } from 'antd'

import { Box } from 'common/components/boxes'

import { IndexProject } from 'contractor/server/projects'

import { EmptySearch } from './empty_search'
import ProjectCard from './project_card'

type GridViewProps = {
  userId: string
  projectGroups: string[]
  projectsGroupedByGroup: Dictionary<IndexProject[]>
  onFilterTextProject: (project: IndexProject) => void
}

export const GridView = ({ userId, projectsGroupedByGroup, projectGroups, onFilterTextProject }: GridViewProps) => {
  return (
    <Box p={16} flexGrow={1}>
      {!projectGroups.length && <EmptySearch />}

      {projectGroups.map((projectGroupName, index) => {
        const projects = sortBy(
          projectsGroupedByGroup[projectGroupName],
          ({ default_watcher_user_ids }) => !default_watcher_user_ids?.includes(userId),
        )

        const isNotLastItem = index < projectGroups.length - 1

        return (
          <Box mt={index ? 16 : 0} key={projectGroupName}>
            <Typography.Title level={4}>{projectGroupName}</Typography.Title>

            <Box display="grid" gridGap="25px" gridTemplateColumns="repeat(auto-fill, minmax(300px, 1fr))">
              {projects.filter(onFilterTextProject).map((project) => (
                <ProjectCard key={project.id} {...project} />
              ))}
            </Box>

            {isNotLastItem && <Divider />}
          </Box>
        )
      })}
    </Box>
  )
}
