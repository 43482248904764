import React from 'react'

import { Typography } from 'antd'

import { Box } from 'common/components/boxes'

import { Download } from './download'
import { DownloadInvoices } from './download_invoices'

export const InvoiceDownload = () => {
  return (
    <Box p="12" width="100%">
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography.Title level={5}>Download Invoice Files</Typography.Title>
        <DownloadInvoices />
      </Box>

      <Box mt="16" display="flex" alignItems="center" justifyContent="space-between">
        <Typography.Title level={5}>Download CSV</Typography.Title>
        <Download />
      </Box>
    </Box>
  )
}
