import React from 'react'

import { RelationshipStockStatus } from 'javascript/common/server/server_types'

import { DownloadOutlined } from '@ant-design/icons'
import { Typography } from 'antd'

import { AgaveSyncStatus } from 'common/components/AgaveSyncStatus'
import { OrdersList } from 'common/components/OrdersList'
import { PsqlColumn } from 'common/components/PsqlTable/psql_table_provider'
import { usePushToOrder } from 'common/hooks/use-push-to-order'

import { Cell } from 'contractor/pages/@v2/Orders/components/cells'
import { insertIf } from 'contractor/pages/Invoices/Detail/invoice_detail_extract'
import { ConsolidatedOrders } from 'contractor/server/orders'

interface Props {
  disableLeveling: boolean
  disableProjectName: boolean
  disableInvoices: boolean
  disableExpirationDate: boolean
  commitmentsEnabled: boolean
  statusColumn: {
    getNotificationsCount: (id: string) => number
  }
  deliveryColumn: {
    onSelectDelivery: (id: string) => void
  }
  integrationSyncStatus: {
    disabled: boolean
    header: string
    tcmIntegration: boolean
    foundationIntegration: boolean
  }
  tagsColumn: {
    disabled: boolean
    onChange: (tags: string[], orderId: string) => Promise<void>
  }
  actionsColumn: {
    disabled: boolean
    onQuickComment: (order: ConsolidatedOrders.Order) => void
  }
  invoiceAmountRemainingColumn: {
    disabled: boolean
    onClick: (orderId: string) => void
  }
}

export function columnsFactory(props: Props): Array<PsqlColumn<ConsolidatedOrders.Order & { leveling: string }>> {
  const {
    disableLeveling,
    disableProjectName,
    disableInvoices,
    disableExpirationDate,
    statusColumn,
    deliveryColumn,
    tagsColumn,
    integrationSyncStatus,
    actionsColumn,
    invoiceAmountRemainingColumn,
    commitmentsEnabled,
  } = props
  const { getUrl } = usePushToOrder()

  return [
    {
      id: 'Leveling',
      accessor: 'leveling',
      Cell: Cell.Leveling,
      width: 30,
      disabled: disableLeveling,
      disabledForToggleView: true,
    },
    {
      Header: 'Status',
      accessor: 'state',
      width: 176,
      sortBy: 'state',
      Cell: ({ row }) => (
        <OrdersList.Cell.Status
          state={row.original.state}
          subState={row.original.sub_state}
          deliveries={row.original.deliveries}
          hasOpenDeliveryIssue={row.original.has_open_delivery_issue}
          leadTimeCutoffDateTimestamp={row.original.lead_time_cutoff_date_timestamp}
          notificationsCount={statusColumn.getNotificationsCount(row.original.order_id)}
        />
      ),
    },
    {
      Header: 'Project',
      disabled: disableProjectName,
      width: 200,
      Cell: ({ row, column }) => (
        <OrdersList.Cell.FormattedText
          column={column}
          value={`${row.original.project_number ? `${row.original.project_number}: ` : ''}${row.original.project_name}`}
        />
      ),
    },
    {
      Header: 'Order',
      accessor: 'order_number',
      sortBy: 'order',
      width: 400,
      Cell: ({
        row: {
          original: { order_id, name, order_number, order_package_id, state, sub_state },
        },
      }) => {
        const pathToOpenOrder = getUrl({
          orderId: order_id,
          orderPackageId: order_package_id,
          state,
          subState: sub_state,
        })

        return <Cell.OrderNumber id={order_id} name={name} number={order_number} href={pathToOpenOrder} />
      },
    },
    {
      Header: 'Vendor',
      accessor: 'vendor_name',
      sortBy: 'vendor',
      width: 250,
      Cell: ({ row }) => (
        <Cell.VendorName
          isDraft={row?.original?.is_draft}
          draftVendorNames={row?.original?.draft_vendor_names}
          vendorName={row?.original?.vendor_name}
        />
      ),
    },
    ...insertIf(commitmentsEnabled, {
      Header: 'Commitment',
      accessor: 'commitment_name',
      width: 250,
      Cell: ({ row, _ }) => (
        <Typography.Link href={`/commitment/${row.original.commitment_id}`} target="_blank">
          {row.original.commitment_name}
        </Typography.Link>
      ),
    }),
    {
      Header: 'Expiration Date',
      accessor: 'quote_scheduled_to_expire_at',
      disabled: disableExpirationDate,
      width: 160,
      Cell: ({ row, value }) => <Cell.ExpirationQuote date={value} state={row?.original?.state} />,
    },
    {
      Header: 'Created at',
      id: 'order_created_at',
      accessor: 'order_created_at',
      width: 160,
      sortBy: 'created',
      format: 'date',
      Cell: ({ value, column }) => <OrdersList.Cell.FormattedText value={value} column={column} />,
    },
    {
      Header: 'Grand Total',
      accessor: 'grand_total',
      width: 120,
      format: 'currency',
      Cell: ({ value, column }) => <OrdersList.Cell.FormattedText value={value} column={column} />,
    },
    {
      Header: 'Delivery Info',
      id: 'delivery_info',
      width: 350,
      ellipsis: true,
      sortBy: 'delivery_best_guess_develivered_at',
      Cell: ({ row }) => (
        <OrdersList.Cell.DeliveryInfo
          deliveries={row?.original?.deliveries}
          onSelectDelivery={deliveryColumn.onSelectDelivery}
        />
      ),
    },
    {
      Header: integrationSyncStatus.header,
      accessor: 'integration_sync_status',
      id: 'integration_sync_status',
      width: 150,
      disabled: integrationSyncStatus.disabled,
      Cell: ({ value }) => {
        if (!integrationSyncStatus.tcmIntegration && !integrationSyncStatus.foundationIntegration) {
          return <AgaveSyncStatus status={value as RelationshipStockStatus} />
        }

        const isSynced = value === 'SYNCED'

        return (
          <div>
            <DownloadOutlined style={{ marginRight: 8 }} />
            {isSynced ? (
              <Typography.Text type="secondary" style={{ fontSize: 14, fontWeight: 400 }}>
                Synced
              </Typography.Text>
            ) : (
              <Typography.Text type="danger" style={{ fontSize: 14, fontWeight: 400 }}>
                Not Synced
              </Typography.Text>
            )}
          </div>
        )
      },
    },
    {
      Header: 'Invoices',
      accessor: 'invoices',
      id: 'invoices',
      width: 250,
      disabled: disableInvoices,
      Cell: ({ value = [] }) => <Cell.Invoices invoices={value} />,
    },
    {
      Header: 'Tags',
      accessor: 'tags',
      id: 'tags',
      disabled: tagsColumn.disabled,
      Cell: ({ row, value }) => (
        <Cell.Tags
          tags={row.original.tags}
          orderId={row.original.order_id}
          value={value}
          onChange={(tags) => tagsColumn.onChange(tags, row.original.order_id)}
        />
      ),
    },
    {
      Header: 'Uninvoiced Amount',
      accessor: 'invoice_amount_remaining',
      id: 'invoice_amount_remaining',
      width: 250,
      disabled: invoiceAmountRemainingColumn.disabled,
      Cell: ({ value, row }) => (
        <Cell.InvoiceAmountRemaining
          value={value}
          onClick={(e) => {
            e.stopPropagation()
            invoiceAmountRemainingColumn.onClick(row.original.order_id)
          }}
        />
      ),
    },
    {
      Header: 'Last Action',
      id: 'last_action',
      accessor: 'last_action',
      width: 250,
      Cell: ({ row: { original } }) => (
        <OrdersList.Cell.LastAction lastAction={original.last_action} currentType={['User']} />
      ),
    },
    {
      Header: 'Ordered By',
      id: 'ordered_by',
      width: 250,
      Cell: ({ row: { original: order } }) => (
        <OrdersList.Cell.OrderedBy
          state={order.state}
          orderedBy={order.ordered_by_name}
          quickCreated={order.is_quick_created}
        />
      ),
    },
    {
      Header: 'Ordered At',
      accessor: 'ordered_at',
      width: 160,
      format: 'date',
      Cell: ({ value, column }) => <OrdersList.Cell.FormattedText value={value} column={column} />,
    },
    {
      Header: 'Requested By',
      accessor: 'requested_by_name',
      width: 250,
      Cell: ({ value, column }) => <OrdersList.Cell.FormattedText value={value} column={column} />,
    },
    {
      Header: 'Drafted By',
      accessor: 'drafted_by_name',
      width: 250,
      Cell: ({ value, column }) => <OrdersList.Cell.FormattedText value={value} column={column} />,
    },
    {
      id: 'actions',
      Cell: ({ row: { original } }) => (
        <Cell.Actions orderState={original.state} onQuickComment={() => actionsColumn.onQuickComment(original)} />
      ),
      disabled: actionsColumn.disabled,
      disabledForToggleView: true,
      ellipsis: false,
    },
  ]
}
