import { calcExtCost } from 'common/helpers/order'
import { InvoiceMaterial, InvoiceResponse } from 'common/server/invoice'

export const calculateOrderedTax = (deliveries) => {
  return deliveries?.reduce((acc, delivery) => {
    return acc + Number(delivery.tax_value)
  }, 0)
}

export const calculateOrderedShipping = (deliveries) => {
  return deliveries?.reduce((acc, delivery) => {
    return acc + Number(delivery.shipping_value)
  }, 0)
}

export const calculateOrderedOther = (deliveries) => {
  return deliveries?.reduce((acc, delivery) => {
    return acc + Number(delivery.other_value)
  }, 0)
}

export const calculateOrderedDiscount = (deliveries) => {
  return deliveries?.reduce((acc, delivery) => {
    return acc + Number(delivery.discount_value)
  }, 0)
}

export const calculateInvoiceSubtotal = (invoicesRelatedToCurrentOrder, calcExtCost) => {
  return invoicesRelatedToCurrentOrder.reduce((acc, currentInvoice) => {
    const invoiceSubtotal = currentInvoice?.invoice_materials.reduce((acc, invoiceMaterial) => {
      const extendedCost =
        calcExtCost({
          unitCost: invoiceMaterial?.unit_price,
          quantity: invoiceMaterial?.quantity_shipped,
          multiplier: invoiceMaterial?.unit?.multiplier,
          qtyIncrement: invoiceMaterial?.unit?.qty_increment,
        }) || 0
      return acc + extendedCost
    }, 0)

    return acc + invoiceSubtotal
  }, 0)
}

export const calculateTotalInvoiced = (expandableInvoices) => {
  return expandableInvoices.reduce((acc, currentInvoice) => acc + currentInvoice.invoiced, 0)
}

export const getInvoicesRelatedToOrder = (order, allInvoices) => {
  return allInvoices.filter((currentInvoice) => order.invoices.some(({ id }) => id === currentInvoice.id))
}

export const makeInvoiceTaxRows = (invoicesRelatedToCurrentOrder) => {
  return invoicesRelatedToCurrentOrder
    .filter((currentInvoice) => Number(currentInvoice.tax_amount) !== 0)
    .map((currentInvoice) => ({
      id: currentInvoice.id,
      description: 'Tax',
      invoice_id: currentInvoice.id,
      number: currentInvoice.number,
      invoiced: Number(currentInvoice.tax_amount) || 0,
    }))
}

export const makeInvoiceUnreconciledItemsRows = (invoiceMaterials: InvoiceMaterial[], invoice: InvoiceResponse) => {
  return invoiceMaterials
    ?.filter((invoiceMaterial) => invoiceMaterial?.description)
    ?.map((invoiceMaterial) => ({
      id: invoiceMaterial.id,
      company_material: {
        description: invoiceMaterial.description,
        unit_id: invoiceMaterial.unit?.id,
        unit_name: invoiceMaterial.uom,
      },
      invoice_materials: [{ ...invoiceMaterial, number: invoice.number }],
      invoice_id: invoiceMaterial.invoice_id,
      quantity: invoiceMaterial.quantity_shipped,
      unit_cost: invoiceMaterial.unit_price,
      is_remaining: true,
    }))
}

export const makeInvoiceShippingRows = (invoicesRelatedToCurrentOrder) => {
  return invoicesRelatedToCurrentOrder
    .filter((currentInvoice) => Number(currentInvoice.shipping_cost) !== 0)
    .map((currentInvoice) => ({
      id: currentInvoice.id,
      description: 'Shipping',
      invoice_id: currentInvoice.id,
      number: currentInvoice.number,
      invoiced: Number(currentInvoice.shipping_cost) || 0,
    }))
}

export const makeInvoiceOtherRows = (invoicesRelatedToCurrentOrder) => {
  return invoicesRelatedToCurrentOrder
    .filter((currentInvoice) => Number(currentInvoice.other_costs) !== 0)
    .map((currentInvoice) => ({
      id: currentInvoice.id,
      description: 'Other',
      invoice_id: currentInvoice.id,
      number: currentInvoice.number,
      invoiced: Number(currentInvoice.other_costs) || 0,
    }))
}

export const makeInvoiceDiscountRows = (invoicesRelatedToCurrentOrder) => {
  return invoicesRelatedToCurrentOrder
    .filter((currentInvoice) => Number(currentInvoice.discount_amount) !== 0)
    .map((currentInvoice) => ({
      id: currentInvoice.id,
      description: 'Discount',
      invoice_id: currentInvoice.id,
      number: currentInvoice.number,
      invoiced: Number(currentInvoice.discount_amount) || 0,
    }))
}

export const makeInvoiceTotalRows = (invoicesRelatedToCurrentOrder, allOrderMaterials) => {
  const totalExtCostWithTax = allOrderMaterials.reduce((acc, currentMaterial) => {
    const totalExtCostWithTax = currentMaterial?.invoice_materials.reduce(
      (acc, invoiceMaterial) => acc + Number(invoiceMaterial.ext_cost_with_tax) || 0,
      0,
    )

    return acc + totalExtCostWithTax
  }, 0)

  return invoicesRelatedToCurrentOrder.map((currentInvoice) => ({
    id: currentInvoice.id,
    description: 'Total',
    invoice_id: currentInvoice.id,
    number: currentInvoice.number,
    tax: Number(currentInvoice.tax_amount) || 0,
    invoiced: Number(currentInvoice.invoice_grand_total) || 0,
    ext_cost_with_tax: totalExtCostWithTax,
  }))
}

export const makeOrderTaxRow = ({ order, orderedTax, invoicedTax, expandableInvoicesTaxRows }) => {
  return {
    id: `tax-${order.id}`,
    company_material: { description: 'Tax' },
    order_number: order.order_number,
    ordered: orderedTax,
    invoiced: invoicedTax,
    remaining: orderedTax - invoicedTax,
    invoice_materials: expandableInvoicesTaxRows,
  }
}

export const makeOrderShippingRow = ({ order, orderedShipping, invoicedShipping, expandableInvoicesShippingRows }) => {
  return {
    id: `shipping-${order.id}`,
    company_material: { description: 'Shipping' },
    order_number: order.order_number,
    ordered: orderedShipping,
    invoiced: invoicedShipping,
    remaining: orderedShipping - invoicedShipping,
    invoice_materials: expandableInvoicesShippingRows,
  }
}

export const makeOrderOtherRow = ({ order, orderedOther, invoicedOther, expandableInvoicesOtherRows }) => {
  return {
    id: `other-${order.id}`,
    company_material: { description: 'Other' },
    order_number: order.order_number,
    ordered: orderedOther,
    invoiced: invoicedOther,
    remaining: orderedOther - invoicedOther,
    invoice_materials: expandableInvoicesOtherRows,
  }
}

export const makeOrderDiscountRow = ({ order, orderedDiscount, invoicedDiscount, expandableInvoicesDiscountRows }) => {
  return {
    id: `discount-${order.id}`,
    company_material: { description: 'Discount' },
    order_number: order.order_number,
    ordered: orderedDiscount,
    invoiced: invoicedDiscount,
    remaining: orderedDiscount - invoicedDiscount,
    invoice_materials: expandableInvoicesDiscountRows,
  }
}

export const makeOrderTotalRow = ({ order, tax, invoicedTotal, invoicesTotalRows, remaining, precision }) => {
  const totalExtCostWithTax = order?.order_materials?.reduce((acc, currentMaterial) => {
    const extCost = calcExtCost({
      unitCost: currentMaterial?.unit_cost,
      quantity: currentMaterial?.quantity,
      multiplier: currentMaterial?.unit?.multiplier,
      qtyIncrement: currentMaterial?.unit?.qty_increment,
      precision,
    })

    return acc + currentMaterial?.tax_value + extCost
  }, 0)

  return {
    id: `total-${order.id}`,
    company_material: { description: 'Total' },
    order_number: order.order_number,
    tax_split: tax,
    ext_cost_with_tax: totalExtCostWithTax,
    ordered: Number(order.grand_total),
    invoiced: invoicedTotal,
    remaining,
    invoice_materials: invoicesTotalRows,
  }
}
