import React from 'react'

import { Row } from 'react-table'

import { Tag } from 'antd'

import { FlexBoxX } from 'common/components/boxes'
import { OrderMaterialHit } from 'common/server/order_materials'

import { useStores } from 'contractor/hooks/use-stores'

interface CellCostCodeProps {
  row: Row
}

const style = { padding: '0 4px' }

export const CellCostCode = ({ row }: CellCostCodeProps) => {
  const { companySettingStore } = useStores()

  const material = row['original'] as OrderMaterialHit
  const costCodeSettings = companySettingStore.otherSettings?.cost_code_settings

  const costCode = material?.cost_code

  const phaseEnabled = costCodeSettings?.phase_code_enabled && !costCodeSettings?.independent_phase_codes_enabled

  return (
    <FlexBoxX alignItems="flex-start" justifyContent="flex-start" height="22px">
      {phaseEnabled && !!costCode ? <Tag style={style}>{costCode?.phase_code || 'N/A'}</Tag> : ''}
      {costCode?.code && <Tag style={style}>{costCode?.code}</Tag>}
      {costCodeSettings?.class_enabled && !!costCode ? <Tag style={style}>{costCode?.clazz || 'N/A'}</Tag> : ''}
    </FlexBoxX>
  )
}
