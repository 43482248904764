import axios from 'axios'

import { Address } from 'common/server/addresses'
export interface CompanyVendorContact {
  contact: {
    id: string
    nickname?: string
    first_name: string
    last_name: string
    full_name: string
    email: string
    phone_number?: string
    active: boolean
    vendor_user?: {
      id: string
      first_name: string
      last_name: string
      full_name: string
      email: string
      headline?: string
      is_public: boolean
    }
  }
  company_vendor: {
    id: string
    vendor_name: string
    vendor?: {
      id: string
      name: string
    }
    external_vendor_id?: string
    addresses: Address[]
  }
}

export interface CreateVendorContactRequest {
  vendor: {
    id?: string
    vendor_name?: string
    vendor_id?: string
    external_vendor_id?: string
  }
  contact: {
    nickname?: string
    first_name?: string
    last_name?: string
    email?: string
    phone_number?: string
    vendor_user_id?: string
  }
}

export interface UploadVendorContactRequest {
  vendor_name: string
  first_name: string
  last_name: string
  email: string
  phone_number: string
  active?: boolean
}

export interface UpdateVendorContactRequest {
  id: string
  first_name?: string
  last_name?: string
  phone_number?: string
  active: boolean
}

export function index() {
  return axios.get<{ vendor_contacts: CompanyVendorContact[] }>('/company_vendor_contact')
}

export function getById(id: string) {
  return axios.get<{ vendor_contact: CompanyVendorContact }>(`/company_vendor_contact/${id}`)
}

export function create(payload: CreateVendorContactRequest) {
  return axios.post<CompanyVendorContact>('/company_vendor_contact', payload)
}

export function update(payload: UpdateVendorContactRequest) {
  return axios.patch<CompanyVendorContact>(`/company_vendor_contact/${payload.id}`, payload)
}

export function indexByVendorId(vendorId: string) {
  return axios.get<{ vendor_contacts: CompanyVendorContact[] }>(`/company_vendors/${vendorId}/company_vendor_contacts`)
}
