import React, { useCallback, useState } from 'react'

import { Configure, InstantSearch, SearchBox } from 'react-instantsearch-dom'

import { observer } from 'mobx-react-lite'

import { AlgoliaTagsFilter } from 'common/components/AlgoliaTagsFilter'
import { Box } from 'common/components/boxes'
import { Loading } from 'common/components/Loading'
import { Page } from 'common/components/Page'
import { useAlgoliaSyncUrl } from 'common/hooks/use-algolia-sync-url'
import { useInterval } from 'common/hooks/use-interval'
import { useQuery } from 'common/hooks/use-query'
import { InvoicesStates } from 'common/server/server_types'

import { useStores } from 'contractor/hooks/use-stores'

import { ListFilters } from '../ListFilters'
import { InvoiceProjectSelector } from '../project_selector'
import InvoiceTable from './table'

export const ToReview = observer(() => {
  const { invoiceStore, projectStore, userStore, invoiceStateStore, integrationStore } = useStores()

  // Reload invoices every 10 seconds
  useInterval(() => invoiceStore.forceRefreshHits(false))

  const { isLoading } = useQuery(() => {
    return Promise.all([
      projectStore.maybeIndexProjects(),
      invoiceStore.loadSearchKey(),
      invoiceStateStore.getAllInvoiceStates(),
    ])
  })
  useQuery(() => {
    if (userStore.canUseIntegrations) {
      return integrationStore.accountingInfo()
    }
  })

  useQuery(() => {
    return Promise.all([
      invoiceStore.getInvoicesLastUpdated(
        projectStore.selectedProject?.id,
        invoiceStore.selectedRibbonFilter,
        true,
        projectStore.projects?.map((p) => p.id),
      ),
    ])
  }, [projectStore.selectedProject?.id, invoiceStore.selectedRibbonFilter, projectStore.projects?.length])

  useQuery(() => {
    invoiceStateStore.maybeGetAllInvoiceStates()
  }, [invoiceStateStore.invoiceStates?.length])

  const algoliaSyncUrl = useAlgoliaSyncUrl(invoiceStore)

  const [tableColumns, setTableColumns] = useState([])

  const maybeSetTableColumns = useCallback((columns = []) => {
    setTableColumns(columns)
  }, [])

  if (isLoading) return <Loading />

  if (!invoiceStore.searchKey.application_id) return null

  const projectId = projectStore.selectedProject?.id
  const finalState = invoiceStateStore.invoiceStates?.find((state) => state.state === InvoicesStates.POSTED)
  let filters = `assigned_to_id:${userStore.currentUser?.id} AND NOT state.label:"${finalState.label}"`
  if (projectId) {
    filters += ` AND projects.id:${projectId}`
  }

  return (
    <InstantSearch
      key={invoiceStore.isSorted ? invoiceStore.searchKey.replica_index_name : invoiceStore.searchKey.index_name}
      searchClient={invoiceStore.searchClient}
      indexName={invoiceStore.searchKey.index_name}
      refresh={invoiceStore.refreshHits}
      {...algoliaSyncUrl}
    >
      <Configure filters={filters} hitsPerPage={50} />
      <Page.Header>
        <Box width="100%" display="flex" alignItems="initial" flexDirection={{ _: 'column', md: 'row' }}>
          <Box mb={{ _: 8, md: 0 }} mr={8}>
            <InvoiceProjectSelector />
          </Box>

          <Box width="100%" display="flex" alignItems="center" flexGrow={1}>
            <Box mr={8} flexGrow={1}>
              <SearchBox searchAsYouType={false} showLoadingIndicator />
            </Box>

            <ListFilters filters={filters} tableColumns={tableColumns} />
          </Box>
        </Box>

        <Box mt={16} width="100%">
          <AlgoliaTagsFilter
            boxProps={{
              mt: { _: 8, lg: 0 },
            }}
            attribute="state.label"
            onChange={(label) => (invoiceStore.selectedRibbonFilter = label)}
            options={[
              { label: 'All', filter: '' },
              ...(invoiceStateStore.invoiceStates || [])
                .filter((state) => state.state !== InvoicesStates.POSTED)
                .map((setting) => ({
                  label: setting?.label,
                  filter: setting?.label,
                })),
            ]}
          />
        </Box>
      </Page.Header>

      <Page.Content p={0} px={{ _: 0, md: 16 }} py={16} height="100%">
        <InvoiceTable maybeSetTableColumns={maybeSetTableColumns} />
      </Page.Content>
    </InstantSearch>
  )
})
