import React from 'react'

import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons'
import { Button, Switch, Typography } from 'antd'

import { Box } from 'common/components/boxes'

import { ListItem } from 'contractor/pages/@v2/Orders/components/Filters/list_item'
import { useOrderMaterials } from 'contractor/pages/Materials/context'

export const MaterialsColumns = () => {
  const { tableColumns } = useOrderMaterials()
  return (
    <Box p={12} display="flex" flexDirection="column">
      {tableColumns.map((column) => {
        return (
          <ListItem
            key={column.id}
            mb={4}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            onClick={() => column.toggleHidden(column.isVisible)}
            px={4}
            borderRadius="sm"
            style={{ cursor: 'pointer' }}
            backgroundColor={column.isVisible ? 'transparent' : 'gray-2'}
          >
            <Typography.Text>{column.Header}</Typography.Text>
            <Switch
              size="small"
              checked={column.isVisible}
              checkedChildren={<EyeOutlined />}
              unCheckedChildren={<EyeInvisibleOutlined />}
            />
          </ListItem>
        )
      })}

      <Button
        type="primary"
        style={{ marginTop: 16, width: '100%' }}
        onClick={() => tableColumns.forEach((column) => column.toggleHidden(false))}
        disabled={tableColumns.every((column) => column.isVisible)}
      >
        Show All
      </Button>
    </Box>
  )
}
