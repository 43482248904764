import React from 'react'

import { ToggleRefinement } from 'react-instantsearch-dom'
import { ColumnInstance } from 'react-table'

import { Typography } from 'antd'

import { observer } from 'mobx-react-lite'

import { Box } from 'common/components/boxes'
import { DrawerTableFilters, ColumnsSelection } from 'common/components/DrawerTableFilters'
import { ClearFiltersButton, RefinementListDropdown } from 'common/components/table/filters'
import { Visibility } from 'common/components/Visibility'

import { useStores } from 'contractor/hooks/use-stores'

import DownloadMenu from './download'

type FiltersProps = {
  tableColumns: ColumnInstance[]
}

const sortCostCodeFilter = (items = [], parameter = '') => {
  return items.sort((a, b) => {
    const aLabel = a?.label?.toLowerCase()
    const bLabel = b?.label?.toLowerCase()
    if (aLabel === parameter && bLabel !== parameter) return -1
    if (aLabel !== parameter && bLabel === parameter) return 1
    return 0
  })
}

export const Filters = observer<FiltersProps>(({ tableColumns }) => {
  const { companySettingStore } = useStores()
  const { company_attributes } = companySettingStore.companyMaterialConfiguration

  const costCodeSettings = companySettingStore.otherSettings?.cost_code_settings

  const hasShowCostCodeFilter = company_attributes?.includes('cost_code_id')

  return (
    <DrawerTableFilters title="Material Database Options">
      <Box mb={16} display="flex" justifyContent="space-between" alignItems="center">
        <Typography.Title level={5} style={{ margin: 0 }}>
          Filters
        </Typography.Title>
        <ClearFiltersButton />
      </Box>
      <Box mb={16} width="100%" display="flex" flexDirection="column">
        <Typography.Text type="secondary">Group</Typography.Text>
        <RefinementListDropdown attribute="group" placeholder="Select group" isSearcheable />
      </Box>
      <Box mb={16} width="100%" display="flex" flexDirection="column">
        <Typography.Text type="secondary">Sub group</Typography.Text>
        <RefinementListDropdown attribute="sub_group" placeholder="Select sub group" isSearcheable />
      </Box>
      {companySettingStore.companyMaterialConfiguration.company_attributes.includes('project_ids') && (
        <Box mb={16} width="100%" display="flex" flexDirection="column">
          <Typography.Text type="secondary">Project</Typography.Text>
          <RefinementListDropdown attribute="project_name" placeholder="Select projects" isSearcheable />
        </Box>
      )}

      {hasShowCostCodeFilter && costCodeSettings?.phase_code_enabled && (
        <Box mb={16} width="100%" display="flex" flexDirection="column">
          <Typography.Text type="secondary">Phase Code</Typography.Text>
          <RefinementListDropdown
            attribute="phase_code_filter"
            placeholder="Select phase code"
            isSearcheable
            formatter={(label) => label}
            sortItems={(items) => sortCostCodeFilter(items, 'missing phase code')}
          />
        </Box>
      )}
      {hasShowCostCodeFilter && (
        <Box mb={16} width="100%" display="flex" flexDirection="column">
          <Typography.Text type="secondary">Cost Code</Typography.Text>
          <RefinementListDropdown
            attribute="cost_code_filter"
            placeholder="Select cost code"
            isSearcheable
            formatter={(label) => label}
            sortItems={(items) => sortCostCodeFilter(items, 'missing cost code')}
          />
        </Box>
      )}
      {hasShowCostCodeFilter && costCodeSettings?.class_enabled && (
        <Box mb={16} width="100%" display="flex" flexDirection="column">
          <Typography.Text type="secondary">Class</Typography.Text>
          <RefinementListDropdown
            attribute="clazz_code_filter"
            placeholder="Select class"
            isSearcheable
            formatter={(label) => label}
            sortItems={(items) => sortCostCodeFilter(items, 'missing class')}
          />
        </Box>
      )}

      <Box mb={16} width="100%" display="flex" flexDirection="column">
        <Typography.Text type="secondary">Tags</Typography.Text>
        <RefinementListDropdown attribute="tags" placeholder="Select tag" isSearcheable />
      </Box>

      <Box width="100%" display="flex" flexDirection="column" mb={24}>
        <ToggleRefinement attribute="active" label=" Actives only" value={true} defaultRefinement={true} />
      </Box>

      <Visibility.Hidden breakpoint="md">
        <ColumnsSelection columns={tableColumns} />
      </Visibility.Hidden>

      <Visibility.Hidden breakpoint="md">
        <Box display="flex" alignItems="center" justifyContent="space-between" mt={24}>
          <Typography.Title level={5} style={{ marginRight: '24px', marginBottom: 0 }}>
            Download
          </Typography.Title>
          <DownloadMenu activeColumns={tableColumns.filter((column) => column.isVisible).map((column) => column.id)} />
        </Box>
      </Visibility.Hidden>
    </DrawerTableFilters>
  )
})
