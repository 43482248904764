import React from 'react'

import { Link } from 'react-router-dom'

import { OrderDetailPopover } from 'contractor/components/OrderDetailPopover'

interface Props {
  id: string
  name: string
  number: string
  href: string
}

export function CellOrderNumber(props: Props) {
  const { id, name, number, href } = props

  return (
    <OrderDetailPopover orderId={id}>
      <Link to={href} onClick={(e) => e.stopPropagation()}>
        {number}: {name}
      </Link>
    </OrderDetailPopover>
  )
}
