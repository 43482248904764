import React from 'react'

import { Tag } from 'antd'

import { Box } from 'common/components/boxes'
import CheckBoxCell from 'common/components/table/cells/checkbox'
import UnitCostCell from 'common/components/table/cells/unit_cost'
import { formatEnumValue } from 'common/helpers/formatters'
import { currencyFormatter } from 'common/helpers/formatters'

import { useStores } from 'contractor/hooks/use-stores'

import { CostCodeCell } from './cost_code_cell'

export default function Columns() {
  const { companySettingStore, userStore } = useStores()
  const { company_attributes } = companySettingStore.companyMaterialConfiguration

  const columns = []
  const size = company_attributes.length

  company_attributes.forEach((column_name) => {
    switch (column_name) {
      case 'cost_code_id':
        columns.push({
          Header: 'Cost Code',
          accessor: 'cost_code.code',
          minWidth: 80,
          maxWidth: 180,
          Cell: ({ row }) => <CostCodeCell costCode={row.original?.cost_code} />,
        })
        break
      case 'preferred_vendor_prices':
        columns.push({
          Header: 'Preferred Vendors',
          accessor: 'company_material_vendor_prices',
          minWidth: 100,
          maxWidth: 220,
          Cell: ({ value = [] }) => (
            <Box style={{ gap: 4 }} display="flex" flexWrap="wrap">
              {value?.map(({ id, company_vendor, price }) => (
                <Tag key={id} style={{ margin: 0 }}>{`${
                  company_vendor?.safe_globalized_vendor_name
                }: ${currencyFormatter(price)}`}</Tag>
              ))}
            </Box>
          ),
        })
        break
      case 'product_identifier':
        columns.push({
          Header: size < 10 ? 'Product ID' : 'Prod. ID',
          accessor: 'product_identifier',
          minWidth: 80,
          maxWidth: 180,
        })
        break
      case 'unit':
        columns.push({
          Header: 'Unit',
          accessor: 'unit',
          minWidth: 60,
          width: 60,
          Cell: ({ row }) => {
            const companyMaterial = row.original
            if (companyMaterial?.unit_id) {
              return companyMaterial?.unit?.unit_name_with_increment_label
            }

            if (companyMaterial?.unit_name) {
              return companyMaterial?.unit_name
            }

            return null
          },
        })
        break
      case 'description':
        columns.push({
          Header: 'Description',
          accessor: 'description',
          minWidth: 300,
        })
        break
      case 'connection_type':
        columns.push({
          Header: 'Conn. Type',
          accessor: 'connection_type',
          minWidth: 120,
          maxWidth: 180,
        })
        break
      case 'sub_group':
        columns.push({
          Header: size < 10 ? 'Sub Group' : 'SG',
          accessor: 'sub_group',
          minWidth: 90,
          maxWidth: 140,
        })
        break
      case 'project_ids':
        columns.push({
          Header: 'Projects',
          accessor: 'project_name',
          minWidth: 120,
          maxWidth: 250,
        })
        break
      default:
        columns.push({
          Header: formatEnumValue(column_name),
          accessor: column_name,
          width: '10%',
        })
    }
  })

  columns.push({
    Header: 'Tags',
    accessor: 'tags',
    id: 'tags',
    minWidth: 150,
    Cell: ({ value = [] }) => (
      <Box style={{ gap: 4 }} display="flex" flexWrap="wrap">
        {value?.map((tag) => (
          <Tag key={`company-material-tag-${tag}`} style={{ margin: 0 }}>
            {tag}
          </Tag>
        ))}
      </Box>
    ),
  })

  if (userStore.canUseHistoricalCost) {
    columns.push(
      {
        Header: 'Last Price',
        accessor: 'cached_last_price',
        minWidth: 80,
        maxWidth: 120,
        Cell: UnitCostCell,
      },
      {
        Header: 'Avg. Price',
        accessor: 'cached_average_price',
        minWidth: 80,
        maxWidth: 120,
        Cell: (props) => <UnitCostCell {...props} maximumFractionDigits={2} />,
      },
      {
        Header: 'Lwst. Price',
        accessor: 'cached_lowest_price',
        minWidth: 80,
        maxWidth: 120,
        Cell: UnitCostCell,
      },
    )
  }

  columns.push({
    Header: 'Active',
    accessor: 'active',
    width: 50,
    Cell: CheckBoxCell,
  })

  return columns
}
