import React, { useEffect, useMemo, useState } from 'react'

import { useHistory } from 'react-router-dom'

import { useTheme } from '@emotion/react'

import { Alert, Button, Divider, Modal, notification, Space, Tooltip, Typography } from 'antd'

import { observer } from 'mobx-react-lite'

import { Box } from 'common/components/boxes'
import { Drawer, DrawerRef } from 'common/components/Drawer'
import { InvoiceMailDetail } from 'common/components/InvoiceMail/invoice_mail_detail'
import { Loading } from 'common/components/Loading'
import { TextArea } from 'common/components/TextArea'
import { formatDateString } from 'common/helpers/formatters'
import { useQuery } from 'common/hooks/use-query'
import { invoiceMailToInvoiceHit, invoiceUploadToInvoiceHit, ShortInvoiceResponse } from 'common/server/invoice'
import { InvoiceInboxFilesStates, InvoiceInboxSubStates } from 'common/server/server_types'

import { useStores } from 'contractor/hooks/use-stores'
import { InboxRowAction } from 'contractor/pages/Invoices/Inbox/List/list_item'
import { InvoiceInboxFileHit, InvoiceInboxHit } from 'contractor/server/invoices/inbox'

import { InboxState } from '../inbox_state'

type InboxDetailProps = {
  invoiceInboxId: string
  invoiceInboxSourceType: string
  onSelectAction: (hit: InvoiceInboxHit, action: InboxRowAction) => void
}

type DetailProps = {
  children: React.ReactNode
  hit: InvoiceInboxHit
  invoices?: ShortInvoiceResponse[]
  onSelectAction: (hit: InvoiceInboxHit, action: InboxRowAction) => void
}

enum ModalType {
  TIP = 'TIP',
  ISSUE = 'ISSUE',
}

const FailedPdfDetails = ({ files }: { files: InvoiceInboxFileHit[] }) => {
  return (
    <Typography.Paragraph style={{ paddingTop: 10 }}>
      {files
        .filter((file) => file.state === InvoiceInboxFilesStates.FAILED)
        .map((file) => {
          // This is a temporary fix, should be removed when the backend is fixed
          const railsHashJson = file.fail_message ? file.fail_message.replace(/=>/g, ':') : '[]'
          const messages = JSON.parse(railsHashJson)
          return (
            <Typography.Paragraph key={`${file.filename}-key`}>
              <a href={file.url} target="_blank" rel="noreferrer">
                {file.filename}
              </a>
              &nbsp;has failed
              {messages && messages.length > 0 && (
                <ul>
                  {messages.map(({ reason, page }, idx) => (
                    <li key={`fail-message-${idx}`}>
                      {page > 0 && <b>{`Page ${page}: `}</b>}
                      {reason}
                    </li>
                  ))}
                </ul>
              )}
            </Typography.Paragraph>
          )
        })}
    </Typography.Paragraph>
  )
}

const IgnoredPdfDetails = ({ files }: { files: InvoiceInboxFileHit[] }) => {
  return files.some((file) => file.state === InvoiceInboxFilesStates.AUTO_IGNORED) ? (
    <Typography.Paragraph style={{ paddingTop: 10 }}>
      {files
        .filter((file) => file.state === InvoiceInboxFilesStates.AUTO_IGNORED)
        .map((file) => (
          <Typography.Paragraph key={`${file.filename}-key`}>
            <a href={file.url} target="_blank" rel="noreferrer">
              {file.filename}
            </a>
            :
            {file.auto_ignored_message && (
              <ul>
                <li key="auto-ignored-message">{file.auto_ignored_message}</li>
              </ul>
            )}
          </Typography.Paragraph>
        ))}
    </Typography.Paragraph>
  ) : (
    <Typography.Paragraph style={{ paddingTop: 10 }}>
      <ul>
        <li key="auto-ignored-message">This email has no attachments</li>
      </ul>
    </Typography.Paragraph>
  )
}

const Detail = ({ children, hit, onSelectAction, invoices = [] }: DetailProps) => {
  const theme = useTheme()
  const history = useHistory()
  const { invoiceInboxStore } = useStores()

  const [forceClose, setForceClose] = useState(false)

  const [openIssueModal, setOpenIssueModal] = useState(false)
  const [issueMessage, setIssueMessage] = useState('')

  const [openTipModal, setOpenTipModal] = useState(false)
  const [tipMessage, setTipMessage] = useState('')

  const someFilesAreIgnored = hit?.files.some(
    (file) => file.state === InvoiceInboxFilesStates.AUTO_IGNORED || hit?.files.length === 0,
  )

  const showAlert =
    hit?.sub_state === InvoiceInboxSubStates.FAILED &&
    hit?.files?.some((file) => file.state === InvoiceInboxFilesStates.FAILED)

  const showWarn = hit?.sub_state === InvoiceInboxSubStates.AUTO_IGNORED || someFilesAreIgnored

  const alertMessage = hit?.fail_reason || 'Oops, something went wrong'

  const warnMessage =
    showAlert || hit?.files.some((file) => file.state === InvoiceInboxFilesStates.SUCCESS)
      ? 'Some files has been auto marked as Ignored'
      : 'This inbox has been auto marked as Ignored'

  const notifyModalError = () => {
    notification.error({
      message: 'Error while sending the issue, try again later',
      placement: 'bottomLeft',
    })
  }

  const notifyModalSucess = (modalType: ModalType) => {
    const message =
      modalType === ModalType.TIP
        ? 'Thanks for helping us improve!\n Our team will evaluate you request ASAP.'
        : 'Thanks for your issue report!\n Our team will take care of it ASAP.'
    notification.success({
      message,
      placement: 'bottomLeft',
    })
  }

  const handleSendTip = async () => {
    try {
      await invoiceInboxStore.reportTip(hit?.id, { tip_message: tipMessage })
      setTipMessage('')
      notifyModalSucess(ModalType.TIP)
    } catch {
      notifyModalError()
    } finally {
      setOpenTipModal(false)
    }
  }

  const handleSendIssue = async () => {
    try {
      await invoiceInboxStore.reportIssue(hit?.id, { issue_message: issueMessage })
      setIssueMessage('')
      notifyModalSucess(ModalType.ISSUE)
    } catch {
      notifyModalError()
    } finally {
      setOpenIssueModal(false)
    }
  }

  if (!hit) {
    return <></>
  }

  return (
    <>
      <Modal
        title="Describe the issue here and hit OK"
        open={openIssueModal}
        onCancel={() => setOpenIssueModal(false)}
        okButtonProps={{ onClick: handleSendIssue, disabled: issueMessage.length === 0 }}
      >
        <TextArea
          value={issueMessage}
          onChange={(e) => setIssueMessage(e.target.value)}
          showCount
          maxLength={255}
          style={{ height: 120, resize: 'none' }}
        />
      </Modal>
      <Modal
        title="How can we improve? Describe it here and hit OK"
        open={openTipModal}
        onCancel={() => setOpenTipModal(false)}
        okButtonProps={{ onClick: handleSendTip, disabled: tipMessage.length === 0 }}
      >
        <TextArea
          value={tipMessage}
          onChange={(e) => setTipMessage(e.target.value)}
          showCount
          maxLength={255}
          style={{ height: 120, resize: 'none' }}
        />
      </Modal>

      <Box overflow="auto">
        {showAlert && !forceClose && (
          <Alert
            message={alertMessage}
            showIcon
            closable
            description={<FailedPdfDetails files={hit?.files || []} />}
            type="error"
            style={{ margin: 20 }}
            action={
              <Space direction="vertical">
                <Button
                  size="small"
                  style={{ minWidth: 120 }}
                  onClick={() => {
                    onSelectAction(hit, InboxRowAction.CREATE)
                    setForceClose(true)
                  }}
                >
                  Create Invoices
                </Button>
                <Button
                  size="small"
                  onClick={() => {
                    onSelectAction(hit, InboxRowAction.IGNORE)
                    setForceClose(true)
                  }}
                >
                  Mark as ignored
                </Button>
              </Space>
            }
          ></Alert>
        )}
        {showWarn && !forceClose && (
          <Alert
            message={warnMessage}
            showIcon
            closable
            description={<IgnoredPdfDetails files={hit?.files || []} />}
            type="warning"
            style={{ margin: 20 }}
            action={
              <Space direction="vertical">
                <Button
                  size="small"
                  style={{ minWidth: 120 }}
                  onClick={() => {
                    setOpenIssueModal(true)
                  }}
                >
                  Something is wrong?
                </Button>
                <Button
                  size="small"
                  style={{ minWidth: 151 }}
                  onClick={() => {
                    setOpenTipModal(true)
                  }}
                >
                  Help us improve
                </Button>
              </Space>
            }
          ></Alert>
        )}

        <Box flexGrow={1} width="100%" p={16}>
          {children}
        </Box>

        {invoices?.length > 0 && (
          <>
            <Divider style={{ margin: 0 }} />

            <Box width="100%" display="flex" flexDirection="column" p={16} alignItems="flex-start">
              <Typography.Text strong>Created Invoices</Typography.Text>
              <Box mt={8}>
                {invoices.map((invoice, index) => {
                  return invoice.discarded_at ? (
                    <Tooltip title="This invoice has been deleted by the user">
                      <Typography.Link key={invoice.id} style={{ color: theme.colors['red-6'] }}>
                        {(index ? ', ' : '') + `#${invoice.number}`}
                      </Typography.Link>
                    </Tooltip>
                  ) : (
                    <Typography.Link key={invoice.id} onClick={() => history.push(`/invoice/${invoice.id}`)}>
                      {(index ? ', ' : '') + `#${invoice.number}`}
                    </Typography.Link>
                  )
                })}
              </Box>
            </Box>
          </>
        )}
      </Box>
    </>
  )
}

const ItemRow = ({ title, children }) => (
  <Box mt={16} mb={8} alignItems="flex-start" width="100%">
    <Typography.Text strong>{title}</Typography.Text>
    {children}
  </Box>
)

export const InboxDetail = observer<InboxDetailProps, DrawerRef>(
  ({ invoiceInboxId, invoiceInboxSourceType, onSelectAction }, ref) => {
    const { invoiceInboxStore } = useStores()

    const [hit, setHit] = useState<InvoiceInboxHit>()
    const [invoiceMail, setInvoiceMail] = useState(invoiceInboxStore.selectedInvoiceMail)
    const [invoiceUpload, setInvoiceUpload] = useState(invoiceInboxStore.selectedInvoiceUpload)

    const { isLoading } = useQuery(() => {
      if (!invoiceInboxId) return

      return invoiceInboxSourceType === 'InvoiceMail'
        ? invoiceInboxStore
            .selectInvoiceMail(invoiceInboxId)
            .then((invoiceMail) => setHit(invoiceMailToInvoiceHit(invoiceMail)))
        : invoiceInboxStore
            .selectInvoiceUpload(invoiceInboxId)
            .then((invoiceUpload) => setHit(invoiceUploadToInvoiceHit(invoiceUpload)))
    }, [invoiceInboxSourceType, invoiceInboxId])

    useEffect(() => {
      setInvoiceMail(invoiceInboxStore.selectedInvoiceMail)
      setInvoiceUpload(invoiceInboxStore.selectedInvoiceUpload)
      if (invoiceInboxSourceType === 'InvoiceMail') {
        setHit(invoiceMailToInvoiceHit(invoiceInboxStore.selectedInvoiceMail))
      } else {
        setHit(invoiceUploadToInvoiceHit(invoiceInboxStore.selectedInvoiceUpload))
      }
    }, [invoiceInboxStore.selectedInvoiceMail, invoiceInboxStore.selectedInvoiceUpload])

    const content = useMemo(() => {
      if (isLoading) {
        return <Loading />
      }

      if (invoiceInboxSourceType === 'InvoiceMail') {
        return (
          <Detail hit={hit} invoices={invoiceMail?.invoices} onSelectAction={onSelectAction}>
            <InvoiceMailDetail invoiceMail={invoiceMail} />
          </Detail>
        )
      }

      return (
        <Detail hit={hit} invoices={invoiceUpload?.invoices} onSelectAction={onSelectAction}>
          <ItemRow title="Uploaded by">
            <Typography.Paragraph>{invoiceUpload?.uploaded_by?.name}</Typography.Paragraph>
          </ItemRow>
          <ItemRow title="Uploaded At">
            <Typography.Paragraph>{formatDateString(invoiceUpload?.created_at)}</Typography.Paragraph>
          </ItemRow>
        </Detail>
      )
    }, [isLoading, invoiceInboxSourceType, invoiceUpload, invoiceMail, hit, onSelectAction])

    return (
      <Drawer
        ref={ref}
        title={
          <Box display="flex" justifyContent="space-between">
            <Typography.Text>Inbox Detail</Typography.Text>
            {hit && (
              <Box display="inline-flex" justifyContent="flex-end">
                <InboxState state={hit?.state} sub_state={hit?.sub_state} count={hit?.invoice_count} />
              </Box>
            )}
          </Box>
        }
      >
        <Box display="flex" flexDirection="column" width="100%">
          {content}
        </Box>
      </Drawer>
    )
  },
  { forwardRef: true },
)
