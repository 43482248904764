import React from 'react'

import { Configure, InstantSearch, SearchBox } from 'react-instantsearch-dom'

import styled from '@emotion/styled'

import { Button, notification } from 'antd'

import { observer } from 'mobx-react-lite'

import { Box, FlexBoxX, FlexBoxY } from 'common/components/boxes'
import { Page } from 'common/components/Page'
import { Visibility } from 'common/components/Visibility'
import { useAlgoliaSyncUrl } from 'common/hooks/use-algolia-sync-url'
import { useQuery } from 'common/hooks/use-query'
import { Events, trackEvent } from 'common/pendo/event_tracking'
import { OrderStatesFilter, OrderSubStates } from 'common/server/server_types'

import { AlgoliaTagsFilter } from 'contractor/components/AlgoliaTagsFilter'
import ProjectSelector from 'contractor/components/ProjectSelector'
import { useStores } from 'contractor/hooks/use-stores'
import { Actions } from 'contractor/pages/@v2/Orders/components/Actions/actions'
import { Filters } from 'contractor/pages/Orders/Filters/filters'
import OrderTable from 'contractor/pages/Orders/table'

import { withOrdersProvider } from './context'

const { success } = notification

const CustomSearchBox = styled(SearchBox)`
  input {
    height: 32px;
  }
`

const OrderWrapper = observer(() => {
  const { orderStore, projectStore, userStore, companySettingStore } = useStores()

  const algoliaSyncUrl = useAlgoliaSyncUrl(orderStore)

  const projectId = projectStore.selectedProject?.id
  let filters = 'project_active:true'

  if (!userStore.canSeeOrdersFromAllGroups && !projectId) {
    filters += ` AND project_id:${projectStore.projects.map((p) => p.id).join(' OR project_id:')}`
  }
  if (projectId) {
    filters = `project_id:${projectId}`
  }

  useQuery(() => orderStore.userSubscribe(userStore.currentUser?.id))

  orderStore.downloadPOPdfNotification = (orderNumber, fileUrl) => {
    success({
      message: 'Updated order',
      description: (
        <span>
          PO#<b>{orderNumber}</b> PDF has been generated and is ready to download
        </span>
      ),
      duration: 10,
      placement: 'bottomLeft',
      key: `download-po-pdf-${orderNumber}`,
      btn: (
        <FlexBoxX>
          <Button
            type="primary"
            style={{ marginLeft: '10px' }}
            size="small"
            onClick={() => {
              trackEvent(Events.ORDER_NOTIFICATION_CLICKED_DOWNLOAD_PO, window.location.pathname)
              window.open(fileUrl, '_blank')
            }}
          >
            Download PO
          </Button>
        </FlexBoxX>
      ),
    })
  }

  orderStore.downloadRFQPdfNotification = (orderNumber, fileUrl) => {
    success({
      message: 'Updated order',
      description: (
        <span>
          Request For Quote #<b>{orderNumber}</b> PDF has been generated and is ready to download
        </span>
      ),
      duration: 10,
      placement: 'bottomLeft',
      key: `download-po-pdf-${orderNumber}`,
      btn: (
        <FlexBoxX>
          <Button
            type="primary"
            style={{ marginLeft: '10px' }}
            size="small"
            onClick={() => window.open(fileUrl, '_blank')}
          >
            Download Request For Quote
          </Button>
        </FlexBoxX>
      ),
    })
  }

  return (
    <InstantSearch
      key={orderStore.searchKey.index_name}
      searchClient={orderStore.searchClient}
      indexName={orderStore.searchKey.index_name}
      refresh={orderStore.refreshHits}
      {...algoliaSyncUrl}
    >
      <Configure filters={filters} hitsPerPage={50} />
      <Page>
        <Page.Header>
          <FlexBoxY>
            <Visibility.Show breakpoint="md">
              <Box mt="8" display="flex" gridGap={8} alignItems="center" justifyContent="space-between" width="100%">
                <ProjectSelector showSettings showAddProject />
                <Actions
                  canSendAndUpdateOrders={userStore?.canSendAndUpdateOrders}
                  canSendAndUpdateRFQs={userStore?.canSendAndUpdateRfqs}
                  publicOrderFormUrl={companySettingStore?.otherSettings?.public_order_form_url_extension}
                  projectId={projectStore?.selectedProject?.id}
                />
              </Box>
              <Box mt="8" display="flex" gridGap={8} alignItems="center" justifyContent="space-between" width="100%">
                <Box flex="1">
                  <CustomSearchBox searchAsYouType={false} showLoadingIndicator width="100%" />
                </Box>
                <Filters filters={filters} />
              </Box>
            </Visibility.Show>

            <Visibility.Hidden breakpoint="md">
              <Box mt="8" display="flex" gridGap={8} alignItems="center" justifyContent="space-between" width="100%">
                <ProjectSelector showSettings showAddProject />
                <Box flex="1">
                  <CustomSearchBox searchAsYouType={false} showLoadingIndicator width="100%" />
                </Box>
                <Filters filters={filters} />
                <Actions
                  canSendAndUpdateOrders={userStore?.canSendAndUpdateOrders}
                  canSendAndUpdateRFQs={userStore?.canSendAndUpdateRfqs}
                  publicOrderFormUrl={companySettingStore?.otherSettings?.public_order_form_url_extension}
                  projectId={projectStore?.selectedProject?.id}
                />
              </Box>
            </Visibility.Hidden>

            <FlexBoxX mt={16} justifyContent="space-between" flexGrow={0} width="100%" alignItems="flex-start">
              <AlgoliaTagsFilter
                onChange={(filter) => (orderStore.selectedRibbonFilter = filter)}
                isOrderTable
                attribute="contractor_orders_filter"
                options={[
                  { label: 'All', filter: '' },
                  { label: 'Requested', filter: OrderStatesFilter.REQUESTED },
                  ...(userStore.cannotSendAndUpdateOrders
                    ? [{ label: 'Drafted Requests', filter: OrderSubStates.DRAFTED_REQUEST }]
                    : [{ label: 'Drafted', filter: OrderStatesFilter.DRAFT }]),
                  ...(companySettingStore?.otherSettings?.approval_state_enabled
                    ? [{ label: 'Approved', filter: OrderStatesFilter.APPROVED }]
                    : []),
                  ...(userStore.canUseCreateRFQ
                    ? [{ label: 'Quote Requested', filter: OrderSubStates.QUOTED_REQUESTED }]
                    : []),
                  ...(userStore.canUseCreateRFQ
                    ? [{ label: 'Quote Provided', filter: OrderSubStates.QUOTED_RECEIVED }]
                    : []),
                  { label: 'Order Placed', filter: OrderSubStates.ORDERED_REQUESTED },
                  { label: 'Order Confirmed', filter: OrderSubStates.ORDERED_CONFIRMED },
                  {
                    label: 'Shipped',
                    filter: OrderStatesFilter.SHIPPED,
                  },
                  { label: 'Delivered', filter: OrderStatesFilter.DELIVERED },
                  { label: 'Cancelled', filter: OrderStatesFilter.CANCELLED },
                ]}
              />
            </FlexBoxX>
          </FlexBoxY>
        </Page.Header>

        <Page.Content p={0} px={{ _: 0, md: 16 }} py={16} height="inherit">
          <OrderTable />
        </Page.Content>
      </Page>
    </InstantSearch>
  )
})

export default withOrdersProvider(OrderWrapper)
