import axios from 'axios'

import { StandardIndexRequest, StandardIndexResponse } from 'common/server/server_types'

export interface CostCode {
  id: string
  code: string
  description?: string
  phase_code?: string
  phase_code_description?: string
  clazz?: string
  clazz_description?: string
  project_ids?: string[]
  projects?: {
    id: string
    name: string
    project_id: string
  }[]
  discarded_at?: string
  cost_code_phase_id?: string
  cost_code_class_id?: string
  cost_code_number_id?: string
  cost_code_phase: CostCodeSubClass
  cost_code_class: CostCodeSubClass
  cost_code_number: CostCodeSubClass
}

export interface CostCodeSubClass {
  id: string
  code: string
  description: string
}

export interface UpsertCostCodeParam {
  id?: string
  cost_code_number?: string
  cost_code_number_description?: string
  cost_code_phase?: string
  cost_code_phase_description?: string
  cost_code_class?: string
  cost_code_class_description?: string
  cost_code_phase_id?: string
  cost_code_class_id?: string
  cost_code_number_id?: string
  project_ids?: string
  project_ids_relation?: string[]
  active: boolean
}

export interface UpsertPhaseCodeParam {
  code?: string
  description?: string
}

export function upsert(costCodes: UpsertCostCodeParam[]) {
  return axios.post<{ cost_codes: CostCode[] }>('/cost_codes', { cost_codes: costCodes })
}

export function index(params: StandardIndexRequest) {
  return axios.get<StandardIndexResponse<CostCode[]>>('/cost_codes', { params })
}

export function facets() {
  return axios.get('/cost_codes/facets')
}
