import React from 'react'

import styled from '@emotion/styled'

import { DeleteOutlined, HistoryOutlined } from '@ant-design/icons'
import { Button, Divider, Tooltip, Form, Input, Popover, Typography } from 'antd'
import { FormInstance } from 'antd/es/form/Form'

import { Box } from 'common/components/boxes'
import { InputCurrency } from 'common/components/InputCurrency'
import OrderState from 'common/components/statuses/order_state'
import { formatDateString } from 'common/helpers/formatters'
import { CompanyMaterialVendorPrice } from 'common/server/company_materials'

import { SelectCompanyVendor } from 'contractor/components/SelectCompanyVendor'
import { IndexCompanyVendor } from 'contractor/server/company_vendors'

type FieldCompanyVendorsProps = {
  parentForm?: FormInstance
  disabled?: boolean
  preferredPrices?: CompanyMaterialVendorPrice[]
}

const Wrapper = styled(Box)`
  .ant-col {
    min-height: auto;
  }
`

const PopoverContent = ({ order }) => {
  return (
    <a
      href={`/order/${order.id}`}
      target="_blank"
      style={{ gap: 20, cursor: 'pointer', display: 'flex', alignItems: 'center' }}
      rel="noreferrer"
    >
      <Box display="flex" flexDirection="column" alignItems="flex-start">
        <Typography.Text>{formatDateString(order.quote_provided_at || order.quoted_at)}</Typography.Text>
        <Typography.Text type="secondary" style={{ fontSize: 12 }}>
          {order.company_vendor?.safe_globalized_vendor_name}
        </Typography.Text>
      </Box>

      <Box display="flex" flexDirection="column" alignItems="flex-start">
        <Typography.Text>{order.project.name}</Typography.Text>
        <OrderState size="small" state={order.state} />
      </Box>
    </a>
  )
}

export function FieldCompanyVendors({ parentForm, disabled, preferredPrices = [] }: FieldCompanyVendorsProps) {
  const [form] = Form.useForm()

  const companyMaterialVendorPricesAttributesField =
    Form.useWatch('company_material_vendor_prices_attributes', parentForm) || []
  const companyVendor = Form.useWatch('company_vendor_id', form)
  const price = Form.useWatch('price', form)
  const preferredVendorPricesAddBtnEnabled = companyVendor || price

  const selectedCompanyVendorIds = companyMaterialVendorPricesAttributesField.map((field) => field?.company_vendor_id)

  const handleAdd = (formValues) => {
    const defaultVendors = parentForm.getFieldValue('company_material_vendor_prices_attributes') || []
    parentForm.setFieldValue('company_material_vendor_prices_attributes', [...defaultVendors, formValues])
    form.resetFields()
  }

  const handleMakeOption = (companyVendor: IndexCompanyVendor) => {
    const commonOption = {
      label: companyVendor?.vendor?.name || companyVendor?.vendor_name,
      value: companyVendor.id,
    }
    if (selectedCompanyVendorIds.includes(companyVendor.id)) {
      return { ...commonOption, disabled: true }
    }
    return commonOption
  }

  return (
    <Wrapper width="100%" display="inline-block">
      <Divider orientation="left">Preferred vendor prices</Divider>

      <Form.List name="company_material_vendor_prices_attributes">
        {(fields, { remove }) => (
          <>
            {fields.map(({ key, name, ...restField }) => {
              const preferredPriceId = parentForm.getFieldValue([
                'company_material_vendor_prices_attributes',
                name,
                'id',
              ])
              const preferredPrice = preferredPrices.find((price) => price.id === preferredPriceId)

              return (
                <Box key={key} width="100%" display="flex" alignItems="flexStart" style={{ gap: 16 }}>
                  <Form.Item {...restField} name={[name, 'id']} hidden>
                    <Input />
                  </Form.Item>

                  <Box style={{ width: '70%', gap: 16 }} display="flex">
                    {preferredPrice?.order && (
                      <Popover
                        content={<PopoverContent order={preferredPrice?.order} />}
                        title="Created from vendor quote"
                      >
                        <Button icon={<HistoryOutlined />} type="text" />
                      </Popover>
                    )}
                    <Form.Item
                      {...restField}
                      name={[name, 'company_vendor_id']}
                      rules={[{ required: true, message: 'You must select the vendor.' }]}
                      style={{ width: '100%' }}
                    >
                      <SelectCompanyVendor disabled={disabled} onMakeOption={handleMakeOption} />
                    </Form.Item>
                  </Box>

                  <Form.Item
                    {...restField}
                    name={[name, 'price']}
                    rules={[{ required: true, message: 'Please add the price.' }]}
                    style={{ width: '30%' }}
                  >
                    <InputCurrency disabled={disabled} />
                  </Form.Item>
                  <Tooltip title="Remove vendor and price" placement="bottomRight">
                    <Button
                      disabled={disabled}
                      danger
                      icon={<DeleteOutlined />}
                      onClick={() => remove(name)}
                      style={{ minWidth: 32 }}
                    />
                  </Tooltip>
                </Box>
              )
            })}
          </>
        )}
      </Form.List>

      <div />

      <Form form={form} style={{ width: '100%' }} onFinish={handleAdd} disabled={disabled}>
        <Box width="100%" display="flex" alignItems="center" style={{ gap: 16 }}>
          <Form.Item
            name="company_vendor_id"
            rules={[{ required: true, message: 'You must select the vendor.' }]}
            style={{ width: '70%' }}
          >
            <SelectCompanyVendor placeholder="Select the vendor" onMakeOption={handleMakeOption} />
          </Form.Item>
          <Form.Item
            name="price"
            dependencies={['company_vendor_id']}
            rules={[
              ({ getFieldValue }) => ({
                validator(_rule, value) {
                  if (getFieldValue('company_vendor_id') && !value) {
                    return Promise.reject('Please add the price.')
                  }
                  return Promise.resolve()
                },
              }),
            ]}
            style={{ width: '30%' }}
          >
            <InputCurrency />
          </Form.Item>
          <Tooltip
            title="Add vendor and price"
            placement="bottomRight"
            overlayStyle={{ visibility: preferredVendorPricesAddBtnEnabled ? 'visible' : 'hidden' }}
          >
            <Form.Item>
              <Button
                htmlType="submit"
                style={{ minWidth: 32, visibility: preferredVendorPricesAddBtnEnabled ? 'visible' : 'hidden' }}
              >
                Add
              </Button>
            </Form.Item>
          </Tooltip>
        </Box>
      </Form>
    </Wrapper>
  )
}
