import React from 'react'

import { Button } from 'antd'

import { Events, trackEvent } from 'common/pendo/event_tracking'

type PlaceOrderButtonProps = {
  disabled: boolean
  handleOpenNewOrder: () => void
}

export const PlaceOrder = (props: PlaceOrderButtonProps) => {
  const { disabled, handleOpenNewOrder } = props
  const source = window.location.pathname

  return (
    <Button
      data-cy="place-new-order-from-commitment"
      type="ghost"
      onClick={() => {
        trackEvent(Events.CLICK_PLACE_ORDER, source)
        handleOpenNewOrder()
      }}
      disabled={disabled}
      style={{ marginLeft: 8 }}
    >
      Place Order
    </Button>
  )
}
