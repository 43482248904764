import React from 'react'

import { CalendarOutlined } from '@ant-design/icons'
import { Space, Tooltip, Typography } from 'antd'

import { observer } from 'mobx-react-lite'

import { CustomEditableContent } from 'common/components/CustomEditableContent'
import DateTimePicker from 'common/components/date_time_picker'
import { formatDateStringShort } from 'common/helpers/formatters'

import { useStores } from 'contractor/hooks/use-stores'

import { useInvoice } from '../context'

interface DocumentDateProps {
  disabled?: boolean
}

export const DocumentDate = observer((props: DocumentDateProps) => {
  const { invoiceStore } = useStores()

  const { setSelectedInvoiceDirty } = useInvoice()
  const ref = React.useRef()

  const { invoice } = invoiceStore

  return (
    <CustomEditableContent
      boxProps={{ mt: 0 }}
      refs={[ref]}
      input={
        <DateTimePicker
          pickerRef={ref}
          value={invoice?.document_date}
          disabled={props?.disabled}
          onChange={(date) => {
            setSelectedInvoiceDirty(true)
            invoiceStore.updateSelectedInvoice('document_date', date)
          }}
          wrapperProps={{ width: 150 }}
        />
      }
    >
      <Tooltip title="Invoice Date" placement="bottom">
        <Space>
          <CalendarOutlined />
          <Typography.Text type="secondary">
            {formatDateStringShort(invoice.document_date) || 'Invoice Date'}
          </Typography.Text>
        </Space>
      </Tooltip>
    </CustomEditableContent>
  )
})
