import React from 'react'

import { Select } from 'antd'

type TagProps = {
  value?: string[]
  onChange?: (tags: string[]) => void
  disabled?: boolean
  tags: string[]
}

export const Tags = ({ value, onChange, disabled, tags = [] }: TagProps) => {
  return (
    <Select
      aria-label="tags-select"
      data-cy="tags_select"
      mode="tags"
      disabled={disabled}
      style={{ width: '100%' }}
      placeholder="Tags"
      onChange={onChange}
      value={value}
    >
      {tags.map((tag) => (
        <Select.Option key={tag} value={tag}>
          {tag}
        </Select.Option>
      ))}
    </Select>
  )
}

export default Tags
