import React, { useEffect, useState } from 'react'

import { connectInfiniteHits } from 'react-instantsearch-dom'
import { useHistory, Link } from 'react-router-dom'
import { ColumnInstance } from 'react-table'

import { uniqBy } from 'lodash'

import { BorderlessTableOutlined, DollarOutlined, ShopOutlined } from '@ant-design/icons'
import { Typography, Space } from 'antd'

import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'

import { Box } from 'common/components/boxes'
import { ResponsiveTable } from 'common/components/ResponsiveTable'
import { currencyFormatter, formatDateString } from 'common/helpers/formatters'

import { InvoiceStatusTag } from 'contractor/components/Invoices/invoice_status_tag'
import { useStores } from 'contractor/hooks/use-stores'
import { getInvoiceColumns, UnknownText } from 'contractor/pages/Invoices/List/invoice_table_columns'
import { InvoiceHit } from 'contractor/server/invoices/invoice'

import { StateResults } from '../state_results'

interface InvoiceTableComponentProps {
  hasMore: boolean
  refineNext: () => void
  hits: InvoiceHit[]
  refreshHits: boolean
  maybeSetTableColumns: (columns: ColumnInstance) => void
}

const InvoiceTableComponent = observer<InvoiceTableComponentProps>(
  ({ hits, hasMore, refineNext, maybeSetTableColumns }) => {
    const { invoiceStore } = useStores()
    const history = useHistory()

    const [data, setData] = useState<InvoiceHit[]>([])

    useEffect(() => {
      invoiceStore.setHits(hits)
      const newData = () => {
        const isSorted = invoiceStore.isSorted

        if (isSorted) return invoiceStore.hits

        return uniqBy([...invoiceStore.invoicesLastUpdated, ...invoiceStore.hits], 'id')
      }

      setData(newData)
    }, [invoiceStore.searchState, hits, invoiceStore.invoicesLastUpdated])

    return (
      <ResponsiveTable
        data={toJS(data)}
        columns={getInvoiceColumns() as never[]}
        tableName="AllInvoices"
        hasMore={hasMore}
        loadMore={refineNext}
        onClickRow={({ row }) => {
          history.push(`/invoice/${row.id}`)
        }}
        key={invoiceStore.isSorted ? invoiceStore.searchKey.replica_index_name : invoiceStore.searchKey.index_name}
        searchClient={invoiceStore.searchClient}
        indexName={invoiceStore.searchKey.index_name}
        replicas={invoiceStore.replicas}
        setTableColumns={maybeSetTableColumns}
        renderMobileCard={(row: InvoiceHit) => {
          return (
            <Space style={{ width: '100%' }} direction="vertical">
              <Box display="flex" alignItems="center" justifyContent="space-between">
                <Link onClick={(e) => e.stopPropagation()} to={`/invoice/${row.id}`}>
                  {row.number}
                </Link>
                <Box>
                  <InvoiceStatusTag state={row?.state} rejectionReason={row?.rejection_reason} />
                </Box>
              </Box>

              {!!row.orders?.length && (
                <Space>
                  <BorderlessTableOutlined />
                  <Box>
                    {row.orders.map((order, index) => (
                      <Typography.Text key={order.id}>{(index ? ', ' : '') + order.name}</Typography.Text>
                    ))}
                  </Box>
                </Space>
              )}

              <Space>
                <ShopOutlined />
                {row.company_vendor_name ? (
                  <Typography.Text>{row.company_vendor_name}</Typography.Text>
                ) : (
                  <UnknownText />
                )}
              </Space>

              <Space>
                <DollarOutlined />
                {row.total_amount ? (
                  <Typography.Text>{currencyFormatter(row.total_amount, 2)}</Typography.Text>
                ) : (
                  <UnknownText />
                )}
              </Space>

              <Space>
                <Typography.Text type="secondary" style={{ fontSize: 12 }}>
                  DUE DATE
                </Typography.Text>
                {row.due_date ? <Typography.Text>{formatDateString(row.due_date)}</Typography.Text> : <UnknownText />}
              </Space>
            </Space>
          )
        }}
      />
    )
  },
)

const CustomHits = connectInfiniteHits(InvoiceTableComponent)

type InvoiceTableProps = Pick<InvoiceTableComponentProps, 'maybeSetTableColumns'>

const InvoiceTable = observer<InvoiceTableProps>((props) => {
  return (
    <StateResults>
      <CustomHits {...props} />
    </StateResults>
  )
})

export default InvoiceTable
