import React from 'react'

interface CellQuantityProps {
  value: string | number
  isMissing?: boolean
}

export const CellQuantity = ({ value, isMissing = false }: CellQuantityProps) => {
  let qty = null

  if (value) {
    // If it's an integer drop the decimals
    qty = Number(value) % 1 == 0 ? Number(value).toFixed(0) : Number(value).toFixed(2)
  }

  return <span style={{ color: isMissing ? 'red' : 'black' }}>{qty > 0 ? qty : null}</span>
}
