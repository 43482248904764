import React from 'react'

import { Typography } from 'antd'

import { observer } from 'mobx-react-lite'

import { Box } from 'common/components/boxes'
import { formatEnumValue } from 'common/helpers/formatters'

import { DownloadButton } from 'contractor/components/DownloadButton'
import { useStores } from 'contractor/hooks/use-stores'
import { ConsolidatedCompanyMaterials } from 'contractor/server/company_materials'

export const CompanyMaterialsDownload = observer(() => {
  const {
    companyMaterialStore: { listStore },
    companySettingStore,
  } = useStores()

  const { company_attributes } = companySettingStore.companyMaterialConfiguration

  const costCodeSettings = companySettingStore.otherSettings?.cost_code_settings

  const phaseEnabled = costCodeSettings?.phase_code_enabled && !costCodeSettings?.independent_phase_codes_enabled
  const classEnabled = costCodeSettings?.class_enabled

  const headers = []
  company_attributes.forEach((column_name) => {
    switch (column_name) {
      case 'project_ids':
        headers.push({ label: 'Project Ids', key: 'project_ids' })
        break
      case 'cost_code_id':
        headers.push({ label: 'Cost Code', key: 'cost_code.code' })

        if (phaseEnabled) {
          headers.push({ label: 'Phase Code ', key: 'cost_code.phase_code' })
        }
        if (classEnabled) {
          headers.push({ label: 'Class Code', key: 'cost_code.clazz' })
        }
        break
      case 'preferred_vendor_prices':
        new Array(5).fill(0).forEach((_, index) => {
          const fieldNumber = index + 1
          headers.push({ label: `Preferred vendor ${fieldNumber}`, key: `preferred_vendor_${fieldNumber}` })
          headers.push({ label: `Preferred price ${fieldNumber}`, key: `preferred_price_${fieldNumber}` })
        })
        break
      default:
        headers.push({ label: formatEnumValue(column_name), key: column_name })
    }
  })

  headers.push(
    { label: 'Image URL', key: 'image_url' },
    { label: 'Active', key: 'active' },
    { label: 'Last Price', key: 'cached_last_price' },
    { label: 'Average Price', key: 'cached_average_price' },
    { label: 'Lowest Price', key: 'cached_lowest_price' },
    { label: 'SubBase ID', key: 'id' },
    { label: 'Existing Tags', key: 'tags' },
    { label: 'New tags', key: 'new_tags' },
  )

  const dataMapper = (companyMaterial: ConsolidatedCompanyMaterials.CompanyMaterial) => {
    const companyMaterialVendorPrices = {}
    companyMaterial.preferred_vendor_prices?.forEach((preferredVendorPrice, index) => {
      const fieldNumber = index + 1
      const preferredVendor =
        preferredVendorPrice.company_vendor?.external_vendor_id ||
        preferredVendorPrice.company_vendor?.safe_globalized_vendor_name

      companyMaterialVendorPrices[`preferred_vendor_${fieldNumber}`] = preferredVendor
      companyMaterialVendorPrices[`preferred_price_${fieldNumber}`] = preferredVendorPrice.price
    })

    return {
      ...companyMaterial,
      unit: companyMaterial?.unit?.unit_name_with_increment_label || companyMaterial?.unit_name,
      project_ids: companyMaterial?.projects
        ?.filter((project) => !!project.project_id)
        .map((project) => project.project_id)
        .join(','),
      ...companyMaterialVendorPrices,
      image_url: companyMaterial.image_url,
      cached_last_price: companyMaterial.cached_last_price
        ? Number(companyMaterial.cached_last_price).toFixed(2)
        : undefined,
      cached_average_price: companyMaterial.cached_average_price
        ? Number(companyMaterial.cached_average_price).toFixed(2)
        : undefined,
      cached_lowest_price: companyMaterial.cached_lowest_price
        ? Number(companyMaterial.cached_lowest_price).toFixed(2)
        : undefined,
    }
  }

  return (
    <Box p={12} display="flex" alignItems="center" justifyContent="space-between">
      <Typography.Title level={5}>Download</Typography.Title>
      <DownloadButton
        onLoadData={listStore.fetchAllRecords}
        headers={headers}
        dataMapper={dataMapper}
        title="Material Database"
        filename="material_database"
      />
    </Box>
  )
})
