import React from 'react'

import { DownloadButton } from 'contractor/components/DownloadButton'
import { Commitment } from 'contractor/server/commitments'

interface MapData {
  project_name: string
  project_number: string
  order_number: string
  name: string
  created_at: string
  updated_at: string
  delivery_state: Array<string>
  delivery_date: Array<string>
  vendor_name: string
  state: string
  sub_state: string
  ordered_by_name: string
  requested_by_name: string
  ordered_at: string
  grand_total: string
}

interface Props {
  onLoadData: () => Promise<Array<Commitment>>
}
export function Download(props: Props) {
  function dataMapper(commitment: Commitment) {
    const result = {
      ...commitment,
    }

    return result
  }

  const headers = [
    { label: 'Project Name', key: 'project_name' },
    { label: 'Project ID', key: 'project_number' },
    { label: 'Order Number', key: 'order_number' },
    { label: 'Order Name', key: 'name' },
    { label: 'Created At', key: 'created_at' },
    { label: 'Updated At', key: 'updated_at' },
    { label: 'Delivery State', key: 'delivery_state' },
    { label: 'Delivery Date', key: 'delivery_date' },
    { label: 'Vendor', key: 'vendor_name' },
    { label: 'Status', key: 'state' },
    { label: 'Sub Status', key: 'sub_state' },
    { label: 'Ordered By', key: 'ordered_by_name' },
    { label: 'Requested by', key: 'requested_by_name' },
    { label: 'Ordered At', key: 'ordered_at' },
    { label: 'Grand Total', key: 'grand_total' },
  ]

  return (
    <DownloadButton<Commitment, MapData>
      dataMapper={dataMapper}
      title="Orders"
      filename="orders"
      headers={headers}
      onLoadData={props.onLoadData}
    />
  )
}
