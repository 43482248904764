import React from 'react'

import styled from '@emotion/styled'

import { Input, InputRef } from 'antd'
import { TextAreaProps as AntdTextAreaProps } from 'antd/lib/input'

const WrapperTextArea = styled.div`
  width: 100%;
  &:after {
    float: right;
    color: #00000073;
    white-space: nowrap;
    content: attr(data-count);
    pointer-events: none;
  }
`

type TextAreaProps = { showCount?: boolean } & AntdTextAreaProps

const Element = ({ showCount, ...props }: TextAreaProps, ref: React.RefObject<InputRef>) => {
  if (showCount) {
    const count = props?.value ? String(props?.value).length : 0
    const dataCount = `${count}${props?.maxLength ? ` / ${props?.maxLength}` : ''}`

    return (
      <WrapperTextArea data-count={dataCount}>
        <Input.TextArea ref={ref} {...props} aria-autocomplete="none" />
      </WrapperTextArea>
    )
  }

  return <Input.TextArea {...props} />
}

export const TextArea = React.forwardRef(Element)
