import { useEffect, useRef } from 'react'

import { useLocation, useHistory } from 'react-router-dom'

import { toJS } from 'mobx'

import { createURL, searchStateToUrl, urlToSearchState } from 'common/helpers/algolia'

const DEBOUNCE_TIME = 300

export const useAlgoliaSyncUrl = (store) => {
  const location = useLocation()
  const history = useHistory()

  const setStateId = useRef(null)

  useEffect(() => {
    const nextSearchState = urlToSearchState(location)

    if (JSON.stringify(toJS(store.searchState)) !== JSON.stringify(nextSearchState)) {
      store.onSearchStateChange(nextSearchState)
    }
  }, [location])

  const onSearchStateChange = (nextSearchState) => {
    clearTimeout(setStateId.current)

    setStateId.current = setTimeout(() => {
      history.push(searchStateToUrl(location, nextSearchState), nextSearchState)
    }, DEBOUNCE_TIME)

    store.onSearchStateChange(nextSearchState)
  }

  return { onSearchStateChange, createURL, searchState: toJS(store.searchState) }
}
