import React from 'react'

import { message, Select } from 'antd'

import { observer } from 'mobx-react-lite'

import { formatEnumValue } from 'common/helpers/formatters'
import { UserRoles } from 'common/server/server_types'

import { useStores } from 'contractor/hooks/use-stores'
import { ManagedUser } from 'contractor/server/company_settings/manage_users'

const roleOptions = Object.values(UserRoles)

type RoleSelectProps = ManagedUser

export const RoleSelect = observer<RoleSelectProps>(({ id, first_name, last_name, role, is_active }) => {
  const { companySettingStore, userStore } = useStores()
  const fullName = `${first_name} ${last_name}`

  const handleMenuClick = async (selectedRole): Promise<void> => {
    try {
      await companySettingStore.updateUser({ user_id: id, role: selectedRole })
      message.success(`Changed role to: ${formatEnumValue(selectedRole)}`)
    } catch (error) {
      if (error?.response?.data?.error) {
        message.error(error?.response?.data?.error)
      } else {
        message.error(`Unable to modify ${fullName}'s role`)
      }
    }
  }

  return userStore.canManageCompanySettings ? (
    <Select onChange={handleMenuClick} value={role} style={{ width: '180px' }} disabled={!is_active}>
      {roleOptions.map((role) => (
        <Select.Option key={role} value={role}>
          {formatEnumValue(role)}
        </Select.Option>
      ))}
    </Select>
  ) : (
    <span>{formatEnumValue(role)}</span>
  )
})
