import React from 'react'

import { useHistory, useLocation } from 'react-router'

import { DrawerTableFilters } from 'common/components/DrawerTableFilters'
import { Page } from 'common/components/Page'
import { usePersistentFilters } from 'common/contexts/persistent_filters'

import { ConsolidatedOrdersMaterials } from 'contractor/server/order_materials'

import { OrderMaterialsColumns } from './order_materials_columns'
import { OrderMaterialsDownload } from './order_materials_download'
import { OrderMaterialsFilters } from './order_materials_filters'

interface Props {
  phaseCodeEnabled: boolean
  hasShowCostCodeFilter: boolean
  facets: { [key: string]: Array<string> }
  values?: { [key: string]: string }
  fetchAllRecords: () => Promise<ConsolidatedOrdersMaterials.OrderMaterial[]>
  companyAttributes: Array<string>
}

export function OrderMaterialsOptions(props: Props) {
  const { fetchAllRecords, companyAttributes, phaseCodeEnabled, hasShowCostCodeFilter, facets, values } = props

  const history = useHistory()
  const location = useLocation()

  const persistentFilters = usePersistentFilters()
  const activeKey = new URLSearchParams(location.search).get('filter_tab')

  const tabItems = [
    {
      label: 'Filters',
      key: 'filters',
      children: (
        <OrderMaterialsFilters
          facets={facets}
          values={values}
          phaseCodeEnabled={phaseCodeEnabled}
          hasShowCostCodeFilter={hasShowCostCodeFilter}
        />
      ),
    },
    { label: 'Columns', key: 'columns', children: <OrderMaterialsColumns /> },
    {
      label: 'Download',
      key: 'download',
      children: <OrderMaterialsDownload onLoadData={fetchAllRecords} companyAttributes={companyAttributes} />,
    },
  ]

  return (
    <DrawerTableFilters
      title="Material Database Options"
      useAlgolia={false}
      appliedFiltersCounter={persistentFilters.appliedFiltersCount}
      padding={0}
    >
      <Page>
        <Page.Tabs
          activeKey={activeKey || 'filters'}
          onChange={(key: string) => {
            const searchParams = new URLSearchParams(history.location.search)
            searchParams.set('filter_tab', key)
            history.push({ search: searchParams.toString() })
          }}
          items={tabItems}
        />
      </Page>
    </DrawerTableFilters>
  )
}
