import React, { useState } from 'react'

import { CloseOutlined } from '@ant-design/icons'
import { Button, message, notification } from 'antd'
import { columnsFactory } from 'contractor/pages/@v2/Orders/constants/columns_definition'

import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'

import { Box } from 'common/components/boxes'
import { DrawerRef } from 'common/components/Drawer'
import { PsqlTableProvider } from 'common/components/PsqlTable/psql_table_provider'
import { PersistentFiltersProvider } from 'common/contexts/persistent_filters'
import { useQuery } from 'common/hooks/use-query'
import { Events, trackEvent } from 'common/pendo/event_tracking'
import theme from 'common/styles/theme'

import { DeliveryDetail } from 'contractor/components/DeliveryDetail'
import { InvoiceOrderMaterial } from 'contractor/components/InvoiceOrderMaterial'
import { OrderQuickCommentModal } from 'contractor/components/OrderQuickCommentModal/order_quick_comment_modal'
import { useFlag } from 'contractor/hooks/use-flag'
import { useStores } from 'contractor/hooks/use-stores'
import { OrdersContent } from 'contractor/pages/@v2/Orders/orders_content'
import { ConsolidatedOrders, request_update, update } from 'contractor/server/orders'

function Content() {
  const [quickCommentOrder, setQuickCommentOrder] = React.useState<Nullable<ConsolidatedOrders.Order>>(null)

  const { notificationStore, deliveryStore, userStore, projectStore, integrationStore, orderStore } = useStores()

  const { listStore } = orderStore

  const tcmCsvV2DownloadEnabled = useFlag('tcm_csv_v2_download')
  const foundationCsvDownloadEnabled = useFlag('foundation_csv_download')
  const extractedInvoiceDataEnabled = useFlag('extracted_invoice_data')
  const commitmentsEnabled = useFlag('commitments')

  const deliveryDetailRef = React.useRef<DrawerRef>()
  const invoiceOrderMaterialDrawerRef = React.useRef<DrawerRef>()

  const [order, setOrder] = useState(null)

  const disableExpirationDate =
    listStore.searchState.filters['state']?.some((s: string) => s?.length > 0) &&
    !listStore.searchState.filters['state']?.some((s: string) => s.includes('QUOTED'))

  useQuery(() => {
    if (userStore.canUseIntegrations) {
      return integrationStore.accountingInfo()
    }
  })

  useQuery(() => {
    return Promise.all([orderStore.getOrderSettings(), orderStore.selectOrder(null)])
  })

  async function handleSelectDelivery(id = '') {
    try {
      await deliveryStore.selectDelivery(id)
      deliveryDetailRef.current?.show()
    } catch {
      message.error('Unable to load the delivery.')
    }
  }

  function handleCloseDeliveryDetail() {
    deliveryDetailRef.current?.close()
    deliveryStore.selectDelivery(null)
  }

  async function handleUpdateDelivery(delivery) {
    try {
      await deliveryStore.updateDelivery(delivery)
      deliveryDetailRef.current?.close()
      deliveryStore.selectDelivery(null)
      listStore.fetchRecords()
    } catch {
      message.error('Unable to update the delivery.')
    }
  }

  async function handleChangeTags(tags: Array<string>, orderId: string) {
    try {
      // @ts-ignore
      await update({ id: orderId, tags: tags, silent_update: true })
      listStore.fetchRecords()
      message.success('Updated Tags')
    } catch {
      message.error('Failed to update tags')
    }
  }

  function handleOpenQuickComment(order: ConsolidatedOrders.Order) {
    setQuickCommentOrder(order)
  }

  function handleCloseQuickComment() {
    setQuickCommentOrder(null)
  }

  async function handleClickOnInvoiceAmountRemaining(orderId: string) {
    try {
      const response = await orderStore.getOrderById(orderId)
      setOrder(response)
      invoiceOrderMaterialDrawerRef.current?.show()
    } catch {
      message.error('Unable to load order.')
    }
  }

  useQuery(() => orderStore.userSubscribe(userStore.currentUser?.id))

  orderStore.downloadPOPdfNotification = (orderNumber, fileUrl) => {
    notification.success({
      message: (
        <span>
          PO#<b>{orderNumber}</b> ready
        </span>
      ),
      duration: 10,
      placement: 'bottomLeft',
      key: `download-po-pdf-${orderNumber}`,
      className: 'use-notify-order',
      closeIcon: (
        <Box display="flex" gridGap={1}>
          <Button
            type="primary"
            style={{ marginLeft: '10px' }}
            size="small"
            onClick={() => {
              trackEvent(Events.ORDER_NOTIFICATION_CLICKED_DOWNLOAD_PO, window.location.pathname)
              window.open(fileUrl, '_blank')
            }}
          >
            Download PO
          </Button>
          <Button
            type="text"
            icon={<CloseOutlined style={{ color: theme.colors['gray-7'] }} />}
            size="small"
            onClick={() => {
              notification.close(`download-po-pdf-${orderNumber}`)
            }}
          />
        </Box>
      ),
    })
  }

  orderStore.downloadRFQPdfNotification = (orderNumber, fileUrl) => {
    notification.success({
      message: (
        <span>
          PO#<b>{orderNumber}</b> ready
        </span>
      ),
      duration: 10,
      placement: 'bottomLeft',
      key: `download-po-pdf-${orderNumber}`,
      className: 'use-notify-order',
      closeIcon: (
        <Box display="flex" gridGap={1}>
          <Button
            type="primary"
            style={{ marginLeft: '10px' }}
            size="small"
            onClick={() => window.open(fileUrl, '_blank')}
          >
            Download Request For Quote
          </Button>
          <Button
            type="text"
            icon={<CloseOutlined style={{ color: theme.colors['gray-7'] }} />}
            size="small"
            ghost
            onClick={() => {
              notification.close(`download-po-pdf-${orderNumber}`)
            }}
          />
        </Box>
      ),
    })
  }

  return (
    <PersistentFiltersProvider
      ignoreOnFilterCounter={['state', 'project_id', 'view', 'tab']}
      ignoreQueryParams={['refresh']}
      listStore={listStore}
    >
      <PsqlTableProvider
        options={{
          data: toJS(listStore.records),
          sort: {
            sortFromPersistentFilter: true,
            field: listStore.searchState.sort,
            direction: listStore.searchState.sort_direction,
          },
          columns: columnsFactory({
            disableExpirationDate: disableExpirationDate,
            disableLeveling: !userStore.canUseRfqLeveling && userStore.isMaterialRequester,
            disableProjectName: !!projectStore.selectedProject,
            disableInvoices: !userStore.canUseInvoices,
            commitmentsEnabled: commitmentsEnabled,
            statusColumn: {
              getNotificationsCount: (id) => notificationStore.notificationCountByObject(id),
            },
            deliveryColumn: {
              onSelectDelivery: userStore.canEditDeliveryInformation ? handleSelectDelivery : undefined,
            },
            integrationSyncStatus: {
              disabled:
                !tcmCsvV2DownloadEnabled &&
                !foundationCsvDownloadEnabled &&
                (!userStore.canUseIntegrations || !integrationStore.purchaseOrderSyncEnabled),
              header: tcmCsvV2DownloadEnabled
                ? 'TCM Sync Status'
                : foundationCsvDownloadEnabled
                ? 'Foundation Sync Status'
                : 'Sync status',
              tcmIntegration: tcmCsvV2DownloadEnabled,
              foundationIntegration: foundationCsvDownloadEnabled,
            },
            tagsColumn: {
              disabled: !userStore.canManageOrders,
              onChange: handleChangeTags,
            },
            actionsColumn: {
              disabled: !userStore.canManageOrders && !userStore.canSendAndUpdateOrders,
              onQuickComment: handleOpenQuickComment,
            },
            invoiceAmountRemainingColumn: {
              disabled: !userStore.canUseInvoices || !extractedInvoiceDataEnabled,
              onClick: handleClickOnInvoiceAmountRemaining,
            },
          }),
        }}
        tableName="AllOrders"
      >
        <OrdersContent
          canSelectDelivery={userStore.canManageOrders || !userStore.isMaterialRequester}
          handleSelectDelivery={handleSelectDelivery}
        />

        <DeliveryDetail
          ref={deliveryDetailRef}
          onClose={handleCloseDeliveryDetail}
          orderDelivery={deliveryStore.selectedDelivery?.delivery}
          onUpdate={handleUpdateDelivery}
          project_ids={[deliveryStore.selectedDelivery?.order.project_id]}
          orderInfo={deliveryStore.selectedDelivery?.order}
        />

        <OrderQuickCommentModal
          visible={!!quickCommentOrder}
          onCancel={handleCloseQuickComment}
          orderId={quickCommentOrder?.order_id}
          orderState={quickCommentOrder?.state}
          vendorName={quickCommentOrder?.vendor_name}
          onSubmit={async (params) => {
            await request_update(params)
          }}
          hasDefaultMessage
        />

        <InvoiceOrderMaterial order={order} ref={invoiceOrderMaterialDrawerRef} />
      </PsqlTableProvider>
    </PersistentFiltersProvider>
  )
}

export const OrdersPage = observer(Content)
