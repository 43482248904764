import { BindAll } from 'lodash-decorators'

import { action, computed, observable } from 'mobx'

import { ClientLogMessage, subscribeClientLogChannel } from 'common/data-access/client_log_websocket'
import { subscribeChannel, TYPE_CONTRACTOR } from 'common/data-access/integration_store_websocket'
import {
  IntegrationsEntities,
  IntegrationsSourceSystem,
  MappingDirections,
  WebSocketMessage,
} from 'common/server/server_types'

import {
  company_materials,
  company_materials_count,
  company_materials_relationships,
  CompanyMaterial,
  resync_company_materials,
  update_company_material,
  UpdateCompanyMaterial,
} from 'contractor/server/integrations/company_materials'
import {
  company_vendors,
  company_vendors_count,
  company_vendors_relationships,
  CompanyVendor,
  resync_company_vendors,
  update_company_vendor,
  UpdateCompanyVendor,
} from 'contractor/server/integrations/company_vendors'
import {
  cost_code_classes,
  cost_code_classes_count,
  cost_code_classes_relationships,
  CostCodeClass,
  resync_cost_code_classes,
  update_cost_code_class,
  UpdateCostCodeClass,
} from 'contractor/server/integrations/cost_code_classes'
import {
  cost_code_numbers,
  cost_code_numbers_count,
  cost_code_numbers_relationships,
  CostCodeNumber,
  resync_cost_code_numbers,
  update_cost_code_number,
  UpdateCostCodeNumber,
} from 'contractor/server/integrations/cost_code_numbers'
import {
  cost_code_phases,
  cost_code_phases_count,
  cost_code_phases_relationships,
  CostCodePhase,
  import_sub_jobs,
  resync_cost_code_phases,
  update_cost_code_phase,
  UpdateCostCodePhase,
} from 'contractor/server/integrations/cost_code_phases'
import { resync_wbs_procore, auto_crate_and_map_phase_codes } from 'contractor/server/integrations/extras'
import {
  accounting,
  accounting_config,
  accounting_info,
  accounting_token,
  AccountingConfig,
  AccountingIntegration,
  AccountingIntegrationToken,
  approve_suggestions,
  change_status,
  client_last_actions,
  ClientLastAction,
  ListParams,
  logout,
  MappingSuggestion,
  reject_suggestions,
  Relationship,
  SearchParams,
  SuggestionAction,
  SyncingProgress,
} from 'contractor/server/integrations/integration'
import {
  create_invoice,
  CreateInvoice,
  invoice_suggestions,
  InvoiceSuggestions,
} from 'contractor/server/integrations/invoices'
import { create_order, order_relationships, OrderRelationships } from 'contractor/server/integrations/orders'
import {
  Project,
  projects,
  projects_count,
  projects_relationships,
  resync_projects,
  update_project,
  UpdateProject,
} from 'contractor/server/integrations/projects'

export interface Paginated<TData> {
  currentPage: number
  pageItems: number
  totalCount: number
  data: TData[]
}

const defaultCount = { all: 0, mapped: 0, unmapped: 0 }

const getPagination = (data): Paginated<never> => {
  const headers = data?.headers
  const currentPage = Number(headers['current-page'])
  const pageItems = Number(headers['page-items'])
  const totalCount = Number(headers['total-count'])

  return {
    currentPage,
    pageItems,
    totalCount,
    data: data?.data,
  }
}

@BindAll()
export default class IntegrationStore {
  @observable accountingIntegration: Nullable<AccountingIntegration> = null
  @observable linkToken: Nullable<string> = null
  @observable mappingDirection: MappingDirections = MappingDirections.INSIDE_OUT

  @observable orderRelationships: Nullable<OrderRelationships> = null

  @observable projects: Nullable<Paginated<Project>> = null
  @observable projectsRelationships: Nullable<Paginated<Relationship>> = null

  @observable companyVendors: Nullable<Paginated<CompanyVendor>> = null
  @observable companyVendorsRelationships: Nullable<Paginated<Relationship>> = null

  @observable companyMaterials: Nullable<Paginated<CompanyMaterial>> = null
  @observable companyMaterialsRelationships: Nullable<Paginated<Relationship>> = null

  @observable costCodeNumbers: Nullable<Paginated<CostCodeNumber>> = null
  @observable costCodeNumbersRelationships: Nullable<Paginated<Relationship>> = null

  @observable costCodeClasses: Nullable<Paginated<CostCodeClass>> = null
  @observable costCodeClassRelationships: Nullable<Paginated<Relationship>> = null

  @observable costCodePhases: Nullable<Paginated<CostCodePhase>> = null
  @observable costCodePhasesRelationships: Nullable<Paginated<Relationship>> = null

  @observable invoiceSuggestions: Nullable<InvoiceSuggestions> = null

  @observable clientLastActions: Nullable<ClientLastAction> = null
  @observable client_log_updated_at = null

  @observable projectsCount: { [key: string]: number } = defaultCount
  @observable companyVendorsCount: { [key: string]: number } = defaultCount
  @observable companyMaterialsCount: { [key: string]: number } = defaultCount
  @observable costCodeNumbersCount: { [key: string]: number } = defaultCount
  @observable costCodeClassesCount: { [key: string]: number } = defaultCount
  @observable costCodePhasesCount: { [key: string]: number } = defaultCount

  @computed get connected(): boolean {
    return !!this.accountingIntegration?.integrations
  }

  @computed get invoiceSyncEnabled() {
    return this.accountingIntegration?.invoice_sync === 'enabled'
  }

  @computed get purchaseOrderSyncEnabled() {
    return this.accountingIntegration?.purchase_order_sync === 'enabled'
  }

  integrationTypes = {
    [IntegrationsSourceSystem.PROCORE]: 'Procore',
    [IntegrationsSourceSystem.QBD]: 'QuickBooks Desktop',
    [IntegrationsSourceSystem.QBO]: 'QuickBooks Online',
    [IntegrationsSourceSystem.FOUNDATION_HOSTED]: 'Foundation',
    [IntegrationsSourceSystem.CMIC]: 'CMIC',
    [IntegrationsSourceSystem.SPECTRUM]: 'Spectrum',
    [IntegrationsSourceSystem.ACUMATICA]: 'Acumatica',
  }

  switchMappingDirection() {
    this.mappingDirection =
      this.mappingDirection === MappingDirections.INSIDE_OUT
        ? MappingDirections.OUTSIDE_IN
        : MappingDirections.INSIDE_OUT
  }

  loadSyncingProgress(data) {
    this.syncingProgress = {
      syncing_init_data: data['syncing_init_data'],
      init_data_progress: data['init_data_progress'],
      init_data_failed: data['init_data_failed'],
      init_data_failed_message: data['init_data_failed_message'],
      step_description: data['step_description'],
    }
  }

  async accountingInfo(): Promise<AccountingIntegration> {
    const { data } = await accounting_info()
    this.accountingIntegration = data
    this.loadSyncingProgress(data)
    return data
  }

  online(): boolean {
    return !this.accountingIntegration?.status || this.accountingIntegration.status === 'online'
  }

  async accounting(): Promise<AccountingIntegrationToken> {
    const { data } = await accounting()
    if (data.link_token) this.linkToken = data.link_token

    if (data.integrations) {
      this.accountingIntegration = data
      this.loadSyncingProgress(data)
    }

    return data
  }

  async client_last_actions_list(): Promise<ClientLastAction> {
    const { data } = await client_last_actions()
    this.clientLastActions = data
    return data
  }

  async changeStatus() {
    const status = this.online() ? 'offline' : 'online'
    const { data } = await change_status(status)
    this.accountingIntegration = data
    return data
  }

  async connect(publicToken: string) {
    const { data } = await accounting_token(publicToken)
    this.accountingIntegration = data
    return data
  }

  async logout() {
    const { data } = await logout()
    return data
  }

  async updateAccountingConfig(params: AccountingConfig) {
    const { data } = await accounting_config(params)
    this.accountingIntegration = data
    return data
  }

  isQBO(): boolean {
    return this.accountingIntegration?.integrations === IntegrationsSourceSystem.QBO
  }

  isQBD(): boolean {
    return this.accountingIntegration?.integrations === IntegrationsSourceSystem.QBD
  }

  isProcore(): boolean {
    return this.accountingIntegration?.integrations === IntegrationsSourceSystem.PROCORE
  }

  isFoundationHosted(): boolean {
    return this.accountingIntegration?.integrations === IntegrationsSourceSystem.FOUNDATION_HOSTED
  }

  isViewpointSpectrum(): boolean {
    return this.accountingIntegration?.integrations === IntegrationsSourceSystem.SPECTRUM
  }

  isAcumatica(): boolean {
    return this.accountingIntegration?.integrations === IntegrationsSourceSystem.ACUMATICA
  }

  title(): string {
    switch (this.accountingIntegration?.integrations) {
      case IntegrationsSourceSystem.QBD:
      case IntegrationsSourceSystem.QBO:
        return 'QuickBooks'
      case IntegrationsSourceSystem.PROCORE:
        return 'Procore'
      case IntegrationsSourceSystem.FOUNDATION_HOSTED:
        return 'Foundation'
      default:
        return 'Accounting'
    }
  }

  invoiceSyncTypeExpense(): boolean {
    return this.accountingIntegration?.invoice_sync_type === 'expense'
  }

  allowMultiMapping(entity): boolean {
    return (
      this.accountingIntegration?.allow_multi_mapping === 'enabled' &&
      this.accountingIntegration?.multi_mapping_entities?.includes(entity)
    )
  }

  enabledCostClassesMapping(): boolean {
    return this.accountingIntegration?.mappings?.cost_classes === 'enabled'
  }

  enabledCostClassesMultiMapping(): boolean {
    return this.allowMultiMapping(IntegrationsEntities.COST_TYPE)
  }

  enabledCostPhaseMapping(): boolean {
    return this.accountingIntegration?.mappings?.cost_phase === 'enabled'
  }

  enabledCostPhaseMultiMapping(): boolean {
    return this.allowMultiMapping(IntegrationsEntities.SUB_JOB)
  }

  enabledCostCodesMapping(): boolean {
    return this.accountingIntegration?.mappings?.cost_codes === 'enabled'
  }

  enabledCostCodesMultiMapping(): boolean {
    return this.allowMultiMapping(IntegrationsEntities.COST_CODE)
  }

  enabledMaterialsMapping(): boolean {
    return this.accountingIntegration?.mappings?.materials === 'enabled'
  }

  enabledMaterialsMultiMapping(): boolean {
    return this.allowMultiMapping(IntegrationsEntities.ITEM)
  }

  enabledProjectsMapping(): boolean {
    return this.accountingIntegration?.mappings?.projects === 'enabled'
  }

  enabledProjectsMultiMapping(): boolean {
    return this.allowMultiMapping(IntegrationsEntities.PROJECT) || this.allowMultiMapping(IntegrationsEntities.CUSTOMER)
  }

  enabledVendorsMapping(): boolean {
    return this.accountingIntegration?.mappings?.vendors === 'enabled'
  }

  enabledVendorsMultiMapping(): boolean {
    return this.allowMultiMapping(IntegrationsEntities.VENDOR)
  }

  showProjectsMapping(): boolean {
    return this.enabledProjectsMapping() && this.accountingIntegration?.init_data_progress === 100
  }

  showVendorsMapping(): boolean {
    return this.enabledVendorsMapping() && this.accountingIntegration?.init_data_progress === 100
  }

  showMaterialsMapping(): boolean {
    return this.enabledMaterialsMapping() && this.accountingIntegration?.init_data_progress === 100
  }

  showCostCodesMapping(): boolean {
    return this.enabledCostCodesMapping() && this.accountingIntegration?.init_data_progress === 100
  }

  showCostClassesMapping(): boolean {
    return this.enabledCostClassesMapping() && this.accountingIntegration?.init_data_progress === 100
  }

  showCostPhasesMapping(): boolean {
    return this.enabledCostPhaseMapping() && this.accountingIntegration?.init_data_progress === 100
  }

  getIntegrationType() {
    return this.integrationTypes[this.accountingIntegration?.integrations] || 'Unknown'
  }

  getIntegrationName(type?: IntegrationsSourceSystem) {
    let integrationName = this.integrationTypes[type]

    if (!integrationName) {
      integrationName = this.getIntegrationType()
    }

    return integrationName
  }

  getQueryParams(direction: MappingDirections, params?: ListParams) {
    // Only apply list params if the mapping direction corresponds
    return this.mappingDirection === direction ? params : undefined
  }

  // Start Projects
  updateProject(params: UpdateProject) {
    return update_project(params)
  }

  resyncProcoreWbs() {
    return resync_wbs_procore()
  }

  autoCreateAndMapPhaseCodes() {
    return auto_crate_and_map_phase_codes()
  }

  resyncProjects() {
    return resync_projects()
  }

  showProjectsSuggestions() {
    return this.projectsCount && this.projectsCount.suggestions > 0
  }

  showCostCodeNumbersSuggestions() {
    return this.costCodeNumbersCount && this.costCodeNumbersCount.suggestions > 0
  }

  showCostCodeClassesSuggestions() {
    return this.costCodeClassesCount && this.costCodeClassesCount.suggestions > 0
  }

  showCostCodePhasesSuggestions() {
    return this.costCodePhasesCount && this.costCodePhasesCount.suggestions > 0
  }

  showCompanyVendorsSuggestions() {
    return this.companyVendorsCount && this.companyVendorsCount.suggestions > 0
  }

  showCompanyMaterialsSuggestions() {
    return this.companyMaterialsCount && this.companyMaterialsCount.suggestions > 0
  }

  async getProjects(params?: ListParams, forceParams = false) {
    const queryParams = forceParams ? params : this.getQueryParams(MappingDirections.INSIDE_OUT, params)
    const data = await projects(queryParams).then(getPagination)

    this.projects = data
    return data
  }

  async getProjectsCount(params?: SearchParams) {
    const data = await projects_count(this.mappingDirection, params)
    this.projectsCount = data?.data
    return data?.data
  }

  async getProjectsRelationships(params?: ListParams, forceParams = false) {
    const queryParams = forceParams ? params : this.getQueryParams(MappingDirections.OUTSIDE_IN, params)
    const data = await projects_relationships(queryParams).then(getPagination)

    this.projectsRelationships = data
    return data
  }
  // End Projects

  // Start Company Vendors
  updateCompanyVendor(params: UpdateCompanyVendor) {
    return update_company_vendor(params)
  }

  async getCompanyVendorsCount(params?: SearchParams) {
    const data = await company_vendors_count(this.mappingDirection, params)
    this.companyVendorsCount = data?.data
    return data?.data
  }

  resyncCompanyVendors() {
    return resync_company_vendors()
  }

  async getCompanyVendors(params?: ListParams, forceParams = false) {
    const queryParams = forceParams ? params : this.getQueryParams(MappingDirections.INSIDE_OUT, params)
    const data = await company_vendors(queryParams).then(getPagination)

    this.companyVendors = data
    return data
  }

  async getCompanyVendorsRelationships(params?: ListParams, forceParams = false) {
    const queryParams = forceParams ? params : this.getQueryParams(MappingDirections.OUTSIDE_IN, params)
    const data = await company_vendors_relationships(queryParams).then(getPagination)

    this.companyVendorsRelationships = data
    return data
  }
  // End Company Vendors

  // Start Company Materials
  updateCompanyMaterial(params: UpdateCompanyMaterial) {
    return update_company_material(params)
  }

  resyncCompanyMaterials() {
    return resync_company_materials()
  }

  async getCompanyMaterialsCount(params?: SearchParams) {
    const data = await company_materials_count(this.mappingDirection, params)
    this.companyMaterialsCount = data?.data
    return data?.data
  }

  async getCompanyMaterials(params?: ListParams, forceParams = false) {
    const queryParams = forceParams ? params : this.getQueryParams(MappingDirections.INSIDE_OUT, params)
    const data = await company_materials(queryParams).then(getPagination)

    this.companyMaterials = data
    return data
  }

  async getCompanyMaterialsRelationships(params?: ListParams, forceParams = false) {
    const queryParams = forceParams ? params : this.getQueryParams(MappingDirections.OUTSIDE_IN, params)
    const data = await company_materials_relationships(queryParams).then(getPagination)

    this.companyMaterialsRelationships = data
    return data
  }
  // End Company Materials

  // Start Cost Code Numbers
  updateCostCodeNumber(params: UpdateCostCodeNumber) {
    return update_cost_code_number(params)
  }

  approveRejectSuggestions(type: SuggestionAction, suggestions: MappingSuggestion[]) {
    const approveRejectSuggestions = { suggestion_ids: suggestions.map((s) => s.suggestion_id) }
    if (type === SuggestionAction.APPROVED) return approve_suggestions(approveRejectSuggestions)
    return reject_suggestions(approveRejectSuggestions)
  }

  resyncCostCodeNumbers() {
    return resync_cost_code_numbers()
  }

  async getCostCodeNumbersCount(params?: SearchParams) {
    const data = await cost_code_numbers_count(this.mappingDirection, params)
    this.costCodeNumbersCount = data?.data
    return data?.data
  }

  async getCostCodeNumbers(params?: ListParams, forceParams = false) {
    const queryParams = forceParams ? params : this.getQueryParams(MappingDirections.INSIDE_OUT, params)
    const data = await cost_code_numbers(queryParams).then(getPagination)

    this.costCodeNumbers = data
    return data
  }

  async getCostCodeNumbersRelationships(params?: ListParams, forceParams = false) {
    const queryParams = forceParams ? params : this.getQueryParams(MappingDirections.OUTSIDE_IN, params)
    const data = await cost_code_numbers_relationships(queryParams).then(getPagination)

    this.costCodeNumbersRelationships = data
    return data
  }
  // End Cost Code Numbers

  // Start Cost Code Class
  updateCostCodeClass(params: UpdateCostCodeClass) {
    return update_cost_code_class(params)
  }

  resyncCostCodeClasses() {
    return resync_cost_code_classes()
  }

  async getCostCodeClassesCount(params?: SearchParams) {
    const data = await cost_code_classes_count(this.mappingDirection, params)
    this.costCodeClassesCount = data?.data
    return data?.data
  }

  async getCostCodeClasses(params?: ListParams, forceParams = false) {
    const queryParams = forceParams ? params : this.getQueryParams(MappingDirections.INSIDE_OUT, params)
    const data = await cost_code_classes(queryParams).then(getPagination)

    this.costCodeClasses = data
    return data
  }

  async getCostCodeClassesRelationships(params?: ListParams, forceParams = false) {
    const queryParams = forceParams ? params : this.getQueryParams(MappingDirections.OUTSIDE_IN, params)
    const data = await cost_code_classes_relationships(queryParams).then(getPagination)

    this.costCodeClassRelationships = data
    return data
  }
  // End Cost Code Class

  // Start Cost Code Phases
  updateCostCodePhase(params: UpdateCostCodePhase) {
    return update_cost_code_phase(params)
  }

  resyncCostCodePhases() {
    return resync_cost_code_phases()
  }

  async importSubJobs() {
    if (this.isProcore()) return await import_sub_jobs()
    else throw new Error('This feature is only available for Procore')
  }

  async getCostCodePhasesCount(params?: SearchParams) {
    const data = await cost_code_phases_count(this.mappingDirection, params)
    this.costCodePhasesCount = data?.data
    return data?.data
  }

  async getCostCodePhases(params?: ListParams, forceParams = false) {
    const queryParams = forceParams ? params : this.getQueryParams(MappingDirections.INSIDE_OUT, params)
    const data = await cost_code_phases(queryParams).then(getPagination)

    this.costCodePhases = data
    return data
  }

  async getCostCodePhasesRelationships(params?: ListParams, forceParams = false) {
    const queryParams = forceParams ? params : this.getQueryParams(MappingDirections.OUTSIDE_IN, params)
    const data = await cost_code_phases_relationships(queryParams).then(getPagination)

    this.costCodePhasesRelationships = data
    return data
  }
  // End Cost Code Class

  // Start Orders
  async createOrder(orderId) {
    try {
      await create_order(orderId)
    } catch (error) {
      console.log(error, { entry: 'sync-order-integration' })
    }
  }

  async order_relationships(orderId) {
    const { data } = await order_relationships(orderId)
    this.orderRelationships = data
    return data
  }

  // End Orders

  // Start Invoices
  async createInvoice(params: CreateInvoice) {
    try {
      await create_invoice(params)
    } catch (error) {
      console.log(error, { entry: 'sync-invoice-integration' })
    }
  }

  async createInvoiceThrowError(params: CreateInvoice) {
    try {
      await create_invoice(params)
    } catch (error) {
      console.log(error, { entry: 'sync-invoice-integration' })
      throw error
    }
  }

  async getInvoiceSuggestions(invoiceId) {
    // Procore does not need suggestions
    if (this.isProcore() || this.isFoundationHosted()) return Promise.resolve()

    const data = await invoice_suggestions(invoiceId)

    this.invoiceSuggestions = data?.data
    return data
  }
  // End Invoices

  // Websocket
  @observable syncingProgress: SyncingProgress = {
    syncing_init_data: false,
    init_data_progress: 0,
    init_data_failed: false,
    init_data_failed_message: null,
    step_description: null,
  }

  @action
  async handleWebSocketMessage(message: WebSocketMessage) {
    if (message.entity_name === 'IntegrationStore' && message.entity_id === this.accountingIntegration?.id) {
      this.loadSyncingProgress(message.data)
    }
  }

  @observable subscriptions = []
  subscribe() {
    if (!this.accountingIntegration) {
      return
    }
    if (this.subscriptions.includes(this.accountingIntegration.id)) {
      // Do not subscribe twice to the same entity
      return
    }
    this.subscriptions.push(this.accountingIntegration.id)
    return subscribeChannel(this.handleWebSocketMessage, TYPE_CONTRACTOR, this.accountingIntegration.id)
  }

  @action
  async handleWebSocketClientLogMessage(message: WebSocketMessage<ClientLogMessage>) {
    if (message.entity_name === 'IntegrationService::AgaveClientLog') {
      this.client_log_updated_at = message.data.updated
    }
  }

  subscribeClientLog() {
    return subscribeClientLogChannel(this.handleWebSocketClientLogMessage, TYPE_CONTRACTOR, 'ClientLog')
  }
}
