import React from 'react'

import { Button, Typography } from 'antd'

import { observer } from 'mobx-react-lite'

import { Box } from 'common/components/boxes'
import { DrawerTableFilters } from 'common/components/DrawerTableFilters'
import { SelectFilter } from 'common/components/table/postgres_filters'
import { usePersistentFilters } from 'common/contexts/persistent_filters'

import { useStores } from 'contractor/hooks/use-stores'

import { DownloadCostCodes } from './download'

export const Filters = observer(() => {
  const { costCodeStore, companySettingStore } = useStores()
  const persistentFilters = usePersistentFilters()

  const costCodeSettings = companySettingStore.otherSettings?.cost_code_settings

  const { costCodeListStore } = costCodeStore

  return (
    <DrawerTableFilters title="Cost Code Options" useAlgolia={false}>
      <Box mb={16} display="flex" justifyContent="space-between" alignItems="center">
        <Typography.Title level={5} style={{ margin: 0 }}>
          Filters
        </Typography.Title>
        <Button
          type="link"
          onClick={() => persistentFilters.handleClearFilters(['active'])}
          disabled={persistentFilters.appliedFiltersCount === 0}
        >
          Clear All
        </Button>
      </Box>

      {costCodeSettings?.project_filtering_enabled && (
        <Box width="100%" display="flex" flexDirection="column">
          <Typography.Paragraph type="secondary">Project</Typography.Paragraph>
          <SelectFilter
            items={
              costCodeListStore.facets['projects']?.map((project) => ({
                value: project.label,
                label: project.label,
              })) || []
            }
            selected={costCodeListStore.searchState.filters['projects']}
            onChange={(projects) => persistentFilters.handleChangeFilters({ projects })}
            attribute="projects"
            placeholder="Select Projects"
            isSearchable
          />
        </Box>
      )}

      <DownloadCostCodes />
    </DrawerTableFilters>
  )
})
