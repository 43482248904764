import React from 'react'

import { Typography, Button, List as AntdList, message, Divider } from 'antd'

import { observer } from 'mobx-react-lite'

import { FlexBoxY, FlexBoxX } from 'common/components/boxes'
import { Loading } from 'common/components/Loading'
import { useQuery } from 'common/hooks/use-query'

import { filterTextByFields } from 'contractor/helpers/filter-text-by-fields'
import { useStores } from 'contractor/hooks/use-stores'
import { IndexCompanyVendor, CompanyVendorContact } from 'contractor/server/company_vendors'

import { useMyVendors } from '../context'
import { CompanyVendorContactCard } from './company_vendor_contact_card'
import { EmptyVendors } from './empty_vendors'

type ListProps = {
  onCreate: () => void
  goToNetWork: () => void
  onOpenVendorProfile: (vendorId: string, companyVendorId: string) => void
  onOpenCompanyVendorContactUpdate: (companyVendorContactId: string) => void
  onOpenCompanyVendorUpdate: (companyVendorId: string) => void
}

export const List = observer<ListProps>(
  ({ onCreate, goToNetWork, onOpenVendorProfile, onOpenCompanyVendorUpdate, onOpenCompanyVendorContactUpdate }) => {
    const { companyVendorStore } = useStores()

    const { onlyActives, searchText } = useMyVendors()

    const { isLoading } = useQuery(() => companyVendorStore.getAllCompanyVendors(onlyActives), [onlyActives])

    const handeFilterTextCompanyVendor = (companyVendor: IndexCompanyVendor) => {
      const vendorName = companyVendor?.vendor?.name || companyVendor?.vendor_name
      const hasCompanyVendor = filterTextByFields({
        fields: ['vendorName'],
        obj: { vendorName },
        searchText,
      })

      const hasCompanyVendorContact = companyVendor.company_vendor_contacts.some((companyVendorContact) => {
        return filterTextByFields({
          fields: ['full_name', 'email', 'vendorName'],
          obj: companyVendorContact,
          searchText,
        })
      })

      return hasCompanyVendor || hasCompanyVendorContact
    }

    const handeFilterTextCompanyVendorContact = (companyVendorContact: CompanyVendorContact) => {
      return filterTextByFields({ fields: ['full_name', 'email', 'vendorName'], obj: companyVendorContact, searchText })
    }

    const handleChangeActive = async (active: boolean, companyVendorId: string, companyVendorContactId: string) => {
      try {
        await companyVendorStore.updateCompanyVendorContact({
          id: companyVendorContactId,
          active,
        })
        companyVendorStore.getAllCompanyVendors(onlyActives)

        message.success(`Successfully changed contact to ${active ? 'Active' : 'Inactive'}`)
      } catch {
        message.error(`Unable to ${active ? 'ACTIVE' : 'INACTIVE'} contact`)
      }
    }

    if (isLoading) {
      return <Loading />
    }

    if (!companyVendorStore.companyVendors.length) {
      return <EmptyVendors mt={32} onCreate={onCreate} onFind={goToNetWork} />
    }

    return (
      <>
        {companyVendorStore.companyVendors
          .map((companyVendor) => {
            return {
              ...companyVendor,
              company_vendor_contacts: companyVendor.company_vendor_contacts.map((companyVendorContact) => ({
                ...companyVendorContact,
                vendorName: companyVendor?.vendor?.name || companyVendor?.vendor_name,
              })),
            }
          })
          .filter(handeFilterTextCompanyVendor)
          .map((companyVendor, index) => (
            <React.Fragment key={companyVendor.id}>
              <FlexBoxY width="100%" alignItems="flex-start" mb="10px">
                <FlexBoxX justifyContent="space-between" width="100%" mb="8px">
                  <FlexBoxX flexGrow={0}>
                    <Typography.Title style={{ marginBottom: 0 }} level={3}>
                      {companyVendor?.vendor?.name || companyVendor.vendor_name}
                    </Typography.Title>
                  </FlexBoxX>

                  {companyVendor?.vendor?.is_public && (
                    <Button
                      style={{ width: 120 }}
                      onClick={() => onOpenVendorProfile(companyVendor?.vendor?.id, companyVendor.id)}
                    >
                      More
                    </Button>
                  )}

                  {!companyVendor?.company_vendor_contacts?.length && (
                    <Button style={{ width: 120 }} onClick={() => onOpenCompanyVendorUpdate(companyVendor?.id)}>
                      Edit
                    </Button>
                  )}
                </FlexBoxX>

                {companyVendor?.vendor?.description && (
                  <Typography.Paragraph type="secondary" style={{ marginBottom: 0 }}>
                    {companyVendor.vendor.description}
                  </Typography.Paragraph>
                )}
              </FlexBoxY>

              <AntdList
                grid={{
                  gutter: 20,
                  xs: 1,
                  sm: 1,
                  md: 2,
                  lg: 2,
                  xl: 2,
                  xxl: 3,
                }}
                locale={{ emptyText: 'No contacts' }}
                dataSource={companyVendor.company_vendor_contacts.filter(handeFilterTextCompanyVendorContact)}
                renderItem={(companyVendorContact) => (
                  <AntdList.Item>
                    <CompanyVendorContactCard
                      name={
                        companyVendorContact.vendor_user
                          ? companyVendorContact.vendor_user.full_name
                          : companyVendorContact.full_name
                      }
                      email={
                        companyVendorContact.vendor_user
                          ? companyVendorContact.vendor_user.email
                          : companyVendorContact.email
                      }
                      nickname={companyVendorContact.nickname}
                      phone={companyVendorContact?.phone_number}
                      headline={companyVendorContact?.vendor_user?.headline}
                      isActive={companyVendorContact.active}
                      id={companyVendorContact.id}
                      onChange={(isActive) => handleChangeActive(isActive, companyVendor.id, companyVendorContact.id)}
                      onClick={onOpenCompanyVendorContactUpdate}
                    />
                  </AntdList.Item>
                )}
              />

              {companyVendorStore.companyVendors.length - 1 !== index && <Divider />}
            </React.Fragment>
          ))}
      </>
    )
  },
)
