import React, { useState } from 'react'

import { LocalShipping, Store } from '@styled-icons/material'

import FlagTwoTone from '@ant-design/icons/FlagTwoTone'
import { Popover, Typography } from 'antd'

import { FlexBoxX, FlexBoxY } from 'common/components/boxes'
import DeliveryState from 'common/components/statuses/delivery_state'
import { formatDateString } from 'common/helpers/formatters'
import { useMediaQuery } from 'common/hooks/use-media-query'
import { Deliveries } from 'common/server/deliveries'

import { useStores } from 'contractor/hooks/use-stores'

import { useCalendar } from './context'

type DeliveryEventProps = {
  delivery: Deliveries.Index
}

const formatted = (date) => (date ? formatDateString(date) : 'Unknown')

export const DeliveryEvent = ({ delivery }: DeliveryEventProps) => {
  const { deliveryStore } = useStores()

  const [open, toggleOpen] = useState(false)

  const { deliveryDetailRef } = useCalendar()

  const isMobileScreen = useMediaQuery('md')

  const { id, requested_delivered_at, estimated_delivered_at, actual_delivered_at } = delivery

  const content = (
    <FlexBoxY width="300px" alignItems="flex-start" justifyContent="flex-start">
      <FlexBoxX alignItems="flex-start" mb={4}>
        <Typography.Text strong style={{ marginRight: '5px' }}>
          PO Number:
        </Typography.Text>
        <span>{delivery?.order_number}</span>
      </FlexBoxX>
      <FlexBoxX alignItems="flex-start" mb={4}>
        <Typography.Text strong style={{ marginRight: '5px' }}>
          Project:
        </Typography.Text>
        <span>{delivery?.project_name}</span>
      </FlexBoxX>
      <FlexBoxX alignItems="flex-start" mb={4}>
        <Typography.Text strong style={{ marginRight: '5px' }}>
          Vendor:
        </Typography.Text>
        <span>{delivery?.company_vendor_name}</span>
      </FlexBoxX>
      <FlexBoxX alignItems="flex-start" mb={4}>
        <Typography.Text strong style={{ marginRight: '5px' }}>
          Requested At:
        </Typography.Text>
        <span>{formatted(requested_delivered_at)}</span>
      </FlexBoxX>
      <FlexBoxX alignItems="flex-start" mb={4}>
        <Typography.Text strong style={{ marginRight: '5px' }}>
          {delivery.is_pick_up ? 'Ready for Pick Up by:' : 'Estimated delivery:'}
        </Typography.Text>
        <span>{formatted(estimated_delivered_at)}</span>
      </FlexBoxX>
      <FlexBoxX alignItems="flex-start" mb={10}>
        <Typography.Text strong style={{ marginRight: '5px' }}>
          {delivery.is_pick_up ? 'Pick Up date:' : 'Delivery date:'}
        </Typography.Text>
        <span>{formatted(actual_delivered_at)}</span>
      </FlexBoxX>
    </FlexBoxY>
  )

  const event = (
    <FlexBoxX
      width="100%"
      justifyContent="flex-start"
      onClick={() => {
        toggleOpen(false)
        deliveryStore.selectDelivery(id).then(() => deliveryDetailRef.current?.show())
      }}
    >
      <Typography.Text ellipsis style={{ fontSize: '13px' }}>
        {delivery.is_pick_up ? (
          <Store size="20" style={{ marginRight: '10px' }} />
        ) : (
          <LocalShipping size="20" style={{ marginRight: '10px' }} />
        )}
        {delivery.has_open_issue && <FlagTwoTone twoToneColor="#eb2f96" style={{ margin: '0 5px' }} />}
        {delivery.order_name || delivery.order.order_number}
      </Typography.Text>
    </FlexBoxX>
  )

  if (isMobileScreen) {
    return event
  }

  return (
    <Popover
      content={content}
      title={
        <FlexBoxX width="300px" justifyContent="space-between">
          <Typography.Text strong ellipsis>
            {delivery.order_name || delivery.order.order_number}
          </Typography.Text>
          <DeliveryState
            state={delivery.state}
            showIssue={delivery.has_open_issue}
            deliveryIssue={delivery.delivery_issue_type}
          />
        </FlexBoxX>
      }
      trigger="hover"
      open={open}
      onOpenChange={toggleOpen}
    >
      {event}
    </Popover>
  )
}
