import React from 'react'

import { useHistory, useLocation } from 'react-router-dom'

import { DrawerTableFilters } from 'common/components/DrawerTableFilters'
import { Page } from 'common/components/Page'

import { MaterialsColumns } from 'contractor/pages/Materials/Filters/materials_columns'
import { MaterialsDownload } from 'contractor/pages/Materials/Filters/materials_download'
import { MaterialsFilters } from 'contractor/pages/Materials/Filters/materials_filters'

interface FiltersProps {
  filters: string
}

export const Filters = (props: FiltersProps) => {
  const { filters } = props
  const history = useHistory()
  const location = useLocation()

  const activeKey = new URLSearchParams(location.search).get('tab')

  const tabItems = [
    { label: 'Filters', key: 'filters', children: <MaterialsFilters /> },
    { label: 'Columns', key: 'columns', children: <MaterialsColumns /> },
    { label: 'Download', key: 'download', children: <MaterialsDownload filters={filters} /> },
  ]

  return (
    <DrawerTableFilters padding={0} title="Materials Options">
      <Page>
        <Page.Tabs
          activeKey={activeKey || 'filters'}
          onChange={(key) => {
            const searchParams = new URLSearchParams(history.location.search)
            searchParams.set('tab', key)
            history.push({ search: searchParams.toString() })
          }}
          items={tabItems}
        />
      </Page>
    </DrawerTableFilters>
  )
}
