import React, { useState } from 'react'

import { Modal } from 'antd'

import { TextArea } from 'common/components/TextArea'

interface Props {
  invoiceInboxId: string
  openIssueModal: boolean
  onCancelIssue: () => void
  onSendIssue: (id: string, text: string) => Promise<void>
}

export function InvoiceInboxDetailIssueModal(props: Props) {
  const { invoiceInboxId, openIssueModal, onCancelIssue, onSendIssue } = props
  const [issueMessage, setIssueMessage] = useState('')

  const handleOk = async () => {
    try {
      await onSendIssue(invoiceInboxId, issueMessage)
    } finally {
      onCancelIssue()
    }
  }

  return (
    <Modal
      title="Describe the issue here and hit OK"
      open={openIssueModal}
      onCancel={onCancelIssue}
      okButtonProps={{
        onClick: handleOk,
        disabled: issueMessage.length === 0,
      }}
    >
      <TextArea
        value={issueMessage}
        onChange={(e) => setIssueMessage(e.target.value)}
        showCount
        maxLength={255}
        style={{ height: 120, resize: 'none' }}
      />
    </Modal>
  )
}
