import { Location } from 'history'
import _ from 'lodash'
import qs from 'qs'

interface Filter {
  filterName: string
  value: string
}

export function andFilter(filters: Filter[]) {
  return _.filter(filters, ({ value }) => value !== undefined)
    .map((f) => `${f.filterName}:${f.value}`)
    .join(' AND ')
}

//www.algolia.com/doc/guides/building-search-ui/going-further/routing-urls/react/
export const urlToSearchState = (location: Location) => {
  /*
    Remove first letter of search params:
    ?query=&page=1 => query=&page=1
  */
  return qs.parse(location.search.slice(1))
}

export const createURL = (state) => `?${qs.stringify(state)}`

export const searchStateToUrl = (location, searchState) =>
  searchState ? `${location.pathname}${createURL(searchState)}` : ''
