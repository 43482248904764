import { toJS } from 'mobx'

import { CostCode } from 'common/server/cost_codes/cost_codes'

import { CostCodeSettings } from 'contractor/server/company_settings/other_settings'

interface CostCodeMap {
  subbase_id: string
  code?: string
  code_description?: string
  phase_code?: string
  phase_code_description?: string
  class_code?: string
  class_code_description?: string
  existent_project_names?: string
  existent_project_ids?: string
  add_project_ids?: string
  active: boolean
}

type CostCodeDownloadProps = {
  costCodes: CostCode[]
  settings: CostCodeSettings
  codeEnabled: boolean
}

export const makeDownloadCostCodeFile = ({ costCodes, settings, codeEnabled }: CostCodeDownloadProps) => {
  return costCodes?.map((costCode) => {
    let costCodeMap: CostCodeMap = {
      subbase_id: costCode?.id,
      active: !costCode?.discarded_at,
    }

    if (codeEnabled) {
      costCodeMap = {
        ...costCodeMap,
        code: costCode?.code,
        code_description: costCode?.description,
      }
    }

    if (settings?.phase_code_enabled) {
      costCodeMap = {
        ...costCodeMap,
        phase_code: costCode?.cost_code_phase?.code,
        phase_code_description: costCode?.cost_code_phase?.description,
      }
    }

    if (settings?.class_enabled) {
      costCodeMap = {
        ...costCodeMap,
        class_code: costCode?.cost_code_class?.code,
        class_code_description: costCode?.cost_code_class?.description,
      }
    }

    if (settings?.project_filtering_enabled) {
      const data = toJS(costCode?.projects)
      const names = data?.map((project) => project?.name)
      const ids = data?.map((project) => project?.project_id || 'missing_project_id')
      costCodeMap = {
        ...costCodeMap,
        existent_project_names: names.join(', '),
        existent_project_ids: ids.join(', '),
        add_project_ids: '',
      }
    }

    return costCodeMap
  })
}
