import React from 'react'

import { PlusOutlined } from '@ant-design/icons'
import { Button } from 'antd'

import { observer } from 'mobx-react-lite'

import { FlexBoxY } from 'common/components/boxes'
import { Card } from 'common/components/Card'
import { ButtonToggleSpreadsheet, OrderMaterials } from 'common/components/OrderMaterialsV2'
import { useMediaQuery } from 'common/hooks/use-media-query'

import { useStores } from 'contractor/hooks/use-stores'

import { usePublicOrderForm } from './context'

type MaterialsV2Props = {
  isSpreadsheetMode: boolean
  toggleSpreadsheetMode: React.Dispatch<React.SetStateAction<boolean>>
}

const mappedOrderType = {
  RFQ: 'RFQ',
  ORDER: 'Order',
}

export const MaterialsV2 = observer<MaterialsV2Props>(({ toggleSpreadsheetMode, isSpreadsheetMode }) => {
  const { publicOrderStore } = useStores()
  const { formParams, deliveries } = publicOrderStore

  const isMobileScreen = useMediaQuery('md')

  const { project, user } = usePublicOrderForm()

  const deliveryId = deliveries[0].id

  const canCreateNewMaterial = formParams?.order_permissions?.MATERIAL_REQUESTER?.can_create_new_material

  const orderType = (mappedOrderType[formParams?.material_requisitions_default_order_type] || 'Order') as OrderType

  const currentRequiredFields = formParams?.required_request_fields

  const handleAdd = () => {
    const button = document.getElementsByClassName('aa-DetachedSearchButton')[0] as HTMLElement
    const input = document.getElementsByClassName('aa-Input')[0] as HTMLElement
    button?.click()
    input?.focus()
  }

  const handleChange = (newOrderMaterials) => {
    if (currentRequiredFields?.quantity) {
      const mappedOrderMaterials = newOrderMaterials.map((newOrderMaterial) => ({
        ...newOrderMaterial,
        quantity: newOrderMaterial?.quantity || 1,
      }))
      publicOrderStore.updateOrderMaterialsByDeliveryId(deliveryId, mappedOrderMaterials)
    } else {
      publicOrderStore.updateOrderMaterialsByDeliveryId(deliveryId, newOrderMaterials)
    }
  }

  return (
    <Card title="Materials" extra={<Button icon={<PlusOutlined />} onClick={handleAdd} size="small" />} mt={16}>
      <FlexBoxY justifyContent="flex-start" width="100%" alignItems="flex-start">
        <OrderMaterials
          orderType={orderType}
          dataSource={publicOrderStore
            .getOrderMaterialsByDeliveryId(deliveryId)
            .filter((data) => !!data.company_material?.description)}
          onChange={handleChange}
          hideCostCode={!formParams?.company_attributes?.includes('cost_code_id')}
          hideVendorResponse
          hideUnitCost
          hideTax
          projectId={project?.id}
          isRequest
          deliveryId={deliveryId}
          unitInput={{ units: formParams?.units, disabled: !canCreateNewMaterial }}
          costCodeInput={{
            costCodes: formParams.cost_codes,
            projectId: project?.id,
            costCodeSettings: formParams.cost_code_settings,
          }}
          phaseCodeInput={{ phaseCodes: formParams?.cost_code_phases }}
          publicOrderFormUrlExtension={formParams?.public_order_form_url_extension}
          companyAttributes={formParams?.company_attributes}
          units={formParams?.units}
          requiredFields={{
            request: formParams?.required_request_fields,
          }}
          costCodeSettings={formParams?.cost_code_settings}
          canCreateNewMaterial={canCreateNewMaterial}
          userId={user?.id}
          disabled={!user?.id}
        />

        {!isMobileScreen && (
          <ButtonToggleSpreadsheet
            onClick={() => toggleSpreadsheetMode((prev) => !prev)}
            data-cy={isSpreadsheetMode ? `is-spread-sheet-0` : `spread-sheet-0`}
            isSpreadsheetMode={isSpreadsheetMode}
          />
        )}
      </FlexBoxY>
    </Card>
  )
})
