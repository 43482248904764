import axios from 'axios'

export interface UploadResponse {
  guestLink: string
}

export function open_upload_company_materials() {
  return axios.get<UploadResponse>('/flatfile/company_materials')
}

export function open_upload_cost_codes() {
  return axios.get<UploadResponse>('/flatfile/cost_codes')
}

export function open_upload_projects() {
  return axios.get<UploadResponse>('/flatfile/projects')
}

export function open_upload_commitment() {
  return axios.get<UploadResponse>('/flatfile/commitment')
}

export function open_upload_phase_codes() {
  return axios.get<UploadResponse>('/flatfile/phase_codes')
}

export function open_upload_company_vendors() {
  return axios.get<UploadResponse>('/flatfile/company_vendors')
}
